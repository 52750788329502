import TransactionTable from "components/common/TransactionTable";
import StoreItemizedAccount from "components/store/StoreItemizedAccount";
import { IRootState } from "modules";
import {
  getFranchiseSpecificationsListAction,
  patchSujungRequestAction,
  postFranchiseSpecificationsConfirmAction,
} from "modules/store/store";
import { FranchiseGetList } from "modules/store/types";
import {
  getBoardAlarmAction,
  getDeliveryAlarmAction,
  getExcelDataAction,
  getLaundryCompleteAlarmAction,
  getLaundryRequestAlarmAction,
  getNoticeAlarmAction,
  getSpecificationsDetailAction,
  getStatementAlarmAction,
  getTransExcelDataAction,
} from "modules/user/user";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import DateFormatUtils from "utils/DateFormatUtils";
import RoleUtils from "utils/RoleUtils";

interface StoreItemizedAccountContainerProps {
  match: any;
}

const StoreItemizedAccountContainer: React.FC<StoreItemizedAccountContainerProps> =
  ({ match }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [selectType, setSelectType] = useState<string>("list");
    const [selectDetailTitle, setSelectDetailTitle] = useState({
      title: "",
      name: "",
      date: "",
      chartType: "",
    });
    const [searchData, setSearchData] = useState<FranchiseGetList | null>({
      query: "",
      dropBox: "",
      startDate: "",
      endDate: "",
      size: 10,
    });
    const [searchPage, setSearchPage] = useState<number>(1);
    const [searchId, setSearchId] = useState<number | null>(null);

    const role = useSelector((state: IRootState) => state.user.profile.type);
    const franchiseSpecificationsList = useSelector(
      (state: IRootState) => state.store.franchiseSpecificationsData
    );
    const specificationsDetailData = useSelector(
      (state: IRootState) => state.user.specificationsDetail
    );
    // const excelData = useSelector((state: IRootState) => state.user.excelData);

    const query = window.location.pathname;

    useEffect(() => {
      if (query === `/store/itemizedAccount/${match.params.id}`)
        setSelectType("detail");
      if (query === `/store/itemizedAccount`) setSelectType("list");
    }, [query]);

    useEffect(() => {
      dispatch(getFranchiseSpecificationsListAction(null, 1, null));
    }, [dispatch]);

    const specificationsListSearch = (
      id: number | null,
      page: number,
      data: FranchiseGetList | null
    ) => {
      setSearchId(id);
      setSearchData(data ?? null);
      setSearchPage(page);
      dispatch(getFranchiseSpecificationsListAction(id, page, data));
    };

    const specificationsDetail = (
      id: number,
      title: string,
      name: string,
      date: Date,
      chartType: string
    ) => {
      const selectDate = DateFormatUtils.dateToFormat(date);
      setSelectDetailTitle({ title, name, date: selectDate, chartType });

      dispatch(getSpecificationsDetailAction(id));
    };

    const onConfirm = (id: number) => {
      dispatch(
        postFranchiseSpecificationsConfirmAction(
          id,
          searchId ?? null,
          searchPage,
          searchData
        )
      );
    };

    useEffect(() => {
      history.push(`/store/itemizedAccount`);
      setSelectType("list");
    }, []);

    // useEffect(() => {
    //   if (match.params.id && Number(match.params.id)) {
    //     setSearchId(match.params.id);
    //     setSelectType("detail");
    //     dispatch(getSpecificationsDetailAction(match.params.id));
    //     dispatch(getExcelDataAction(match.params.id));
    //   } else {
    //     setSelectType("list");
    //     // history.push(match.path);
    //   }
    // }, [match.params.id]);

    const patchSujungRequest = (id: number) => {
      dispatch(patchSujungRequestAction(id));
    };

    useEffect(() => {
      dispatch(getNoticeAlarmAction());
      dispatch(getBoardAlarmAction());

      if (RoleUtils.isFactory(role)) {
        dispatch(getDeliveryAlarmAction());
        dispatch(getLaundryRequestAlarmAction());
      }
      if (RoleUtils.isStore(role)) {
        dispatch(getStatementAlarmAction());
        dispatch(getLaundryCompleteAlarmAction());
      }
    }, [dispatch, role]);

    const historyBack = () => {
      setSelectDetailTitle({
        title: "",
        name: "",
        date: "",
        chartType: "",
      });
      setSelectType("list");
    };

    const excelFile = useSelector((state: IRootState) => state.user.excelData);
    const transExcelFile = useSelector(
      (state: IRootState) => state.user.transExcelData
    );

    const getExcelData = () => {
      dispatch(getExcelDataAction(match.params.id));
    };
    const getTransExcelData = () => {
      dispatch(getTransExcelDataAction(match.params.id));
    };

    return (
      <>
        {selectType === "list" && (
          <StoreItemizedAccount
            franchiseSpecificationsList={franchiseSpecificationsList}
            specificationsListSearch={specificationsListSearch}
            role={role}
            specificationsDetail={specificationsDetail}
            setSelectType={setSelectType}
          />
        )}
        {selectType === "detail" && (
          <TransactionTable
            role={role}
            onConfirm={onConfirm}
            selectDetailTitle={selectDetailTitle}
            specificationsDetailData={specificationsDetailData}
            patchSujungRequest={patchSujungRequest}
            historyBack={historyBack}
            excelFile={excelFile}
            transExcelFile={transExcelFile}
            getExcelData={getExcelData}
            getTransExcelData={getTransExcelData}
          />
        )}
      </>
    );
  };

export default StoreItemizedAccountContainer;
