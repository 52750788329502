import React, { HTMLProps } from "react";
import styled, { css } from "styled-components";
import { buttonColorMap, ThemeColor } from "styles/palette";

type ButtonSizeType = "s" | "m" | "l";

interface ButtonProps extends Omit<HTMLProps<HTMLButtonElement>, "to"> {
  theme: ThemeColor;
  buttonSize: ButtonSizeType;
  notUseCousor?: boolean;
}

type ButtonBlockProps = {
  theme: ThemeColor;
  buttonSize: ButtonSizeType;
  notUseCousor?: boolean;
};

const ButtonBlock = styled.div<ButtonBlockProps>`
  display: inline-block;
  width: 75px;
  padding: 0.5rem 0;
  text-align: center;
  font-size: 14px;
  color: ${(props) => buttonColorMap[props.theme as ThemeColor].color};
  background-color: ${(props) => buttonColorMap[props.theme as ThemeColor].backgroundColor};
  border: 1px solid ${(props) => buttonColorMap[props.theme as ThemeColor].border};
  border-radius: 5px;
  cursor: ${(props) => (props.notUseCousor ? "default" : "pointer")};

  ${(props) =>
    props.buttonSize === "s" &&
    css`
      padding: 0.5rem 0;
      width: 75px;
      font-size: 12px;
    `}
  ${(props) =>
    props.buttonSize === "l" &&
    css`
      font-size: 1rem;
      width: 80px;
      padding: 0.5rem 0;
    `}

  & + & {
    margin-left: 0.5rem;
  }
`;

const Button: React.FC<ButtonProps> = ({ theme, buttonSize = "m", children, ...rest }) => {
  const htmlProps = rest as any;
  return (
    <ButtonBlock theme={theme} buttonSize={buttonSize} {...htmlProps}>
      {children}
    </ButtonBlock>
  );
};

export default Button;
