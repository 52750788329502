import React, { HTMLProps, useEffect, useState } from "react";
import styled from "styled-components";
import { Palette } from "styles/palette";
import { useCallback } from "react";

interface PagingProps extends Omit<HTMLProps<HTMLDivElement>, "to"> {
  listAction: (page: number) => void;
  listCount: number;
  currentPage: number | undefined | null;
  totalCount?: number;
  size?: number;
}

const Paging: React.FC<PagingProps> = ({
  listAction,
  listCount,
  currentPage,
  totalCount,
  size,
}) => {
  const [currentPageNum, setCurrentPageNum] = useState<number | null>(1);
  const [pageList, setPageList] = useState<Array<number> | null>(null);
  const [maxPage, setMaxPage] = useState<number>(0);
  const [currentPageData, setCurrentPageData] = useState({
    currentFirst: 0,
    currentLast: 0,
  });

  useEffect(() => {
    setCurrentPageNum(currentPage!);
    const curPage = Math.floor((currentPageNum! - 1) / 10) * 10;
    const curPages: Array<number> = [];
    for (let i = curPage; i <= curPage + 9; i++) {
      curPages.push(i + 1);
    }

    // if (pageList !== curPages) setPageList(curPages);
    if (JSON.stringify(pageList) !== JSON.stringify(curPages)) {
      setPageList(curPages);
    }
    const max = Math.ceil(listCount || 0 / 10);
    setMaxPage(max);
  }, [currentPage, currentPageNum, pageList, listCount]);

  useEffect(() => {
    let first = 1 + (currentPageNum! - 1) * (size ?? 10);
    let last = currentPageNum! * (size ?? 10);
    if (currentPageNum! * (size ?? 10) > totalCount!) last = totalCount!;
    setCurrentPageData({
      currentFirst: first,
      currentLast: last,
    });
  }, [currentPageNum, totalCount, size]);

  const pageMove = useCallback(
    (page: number) => {
      if (page < 1) return;

      if (page <= maxPage) listAction(page);
      // setCurrentPageNum(page);
    },
    [listAction, maxPage]
  );

  // const movePrevPage = useCallback(() => {
  //   const prev = Math.floor(currentPageNum! * 0.1) * 10 + 1;
  //   pageMove(prev);
  // }, [currentPageNum, pageMove]);

  // const moveNextPage = useCallback(() => {
  //   const next = (Math.floor(currentPageNum! * 0.1) + 1) * 10 + 1;
  //   if (next > maxPage) return pageMove(maxPage);

  //   pageMove(next);
  // }, [currentPageNum, pageMove, maxPage]);

  return (
    <PagingBlock>
      <div className="pagingInfoNumber">
        Showing {currentPageData.currentFirst} to {currentPageData.currentLast}{" "}
        of {totalCount} entries
      </div>
      <div className="pagingWrapper">
        <div className={currentPageNum! <= 1 ? "leftBtn off" : "leftBtn on"}>
          {/* <div className="doubleArrow" onClick={movePrevPage}>
            <MdFirstPage size={24} color={"#828282"} />
          </div> */}
          <div
            className="oneArrow"
            onClick={() => {
              pageMove(currentPageNum! - 1);
            }}
          >
            Previous
            {/* <MdNavigateBefore size={24} color={"#828282"} /> */}
          </div>
        </div>
        <div className="centerNum">
          {pageList?.map((val: number, index) => (
            <React.Fragment key={index}>
              {val <= (maxPage > 1 ? maxPage : 1) && (
                <div
                  className={val === currentPageNum ? "onNumber" : ""}
                  onClick={() => pageMove(val)}
                >
                  {val}
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
        <div
          className={
            currentPageNum! >= maxPage ? "rightBtn off" : "rightBtn on"
          }
        >
          <div
            className="oneArrow"
            onClick={() => {
              pageMove(currentPageNum! + 1);
            }}
          >
            Next
            {/* <MdNavigateNext size={24} color={"#828282"} /> */}
          </div>
          {/* <div className="doubleArrow" onClick={moveNextPage}>
            <MdLastPage size={24} color={"#828282"} />
          </div> */}
        </div>
      </div>
    </PagingBlock>
  );
};

export default Paging;

const PagingBlock = styled.div`
  display: flex;
  justify-content: space-between;
  .pagingInfoNumber {
    color: #999;
    font-size: 14px;
  }
  .pagingWrapper {
    width: auto;
    height: 36px;
    /* margin: 0 auto; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & > div {
      height: 100%;
      display: inline-block;
    }
    & > .leftBtn,
    .rightBtn {
      width: 100px;
      /* display: grid; */
      /* grid-template-columns: 1fr 1fr; */
      border: 1px solid #dee2e6;
      text-align: center;
      line-height: 36px;
      font-size: 14px;
      color: ${Palette.pagingNumColor};
      & > div {
        width: 100%;
        height: 100%;
      }
      /* & > .oneArrow,
      .doubleArrow {
        display: flex;
        justify-content: center;
        align-items: center;

        & > img {
          width: 12px;
          cursor: pointer;
        }
      } */
    }
    & > .off {
      opacity: 0.3;
      color: #333;
    }
    & > .on:hover {
      background-color: #eee;
      cursor: pointer;
    }
    & > .centerNum {
      width: auto;
      /* flex: 1; */
      display: flex;
      justify-content: center;
      align-items: center;
      /* padding: 0 1rem; */

      & > div {
        padding: 0.5rem 0.7rem;
        cursor: pointer;
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        color: ${Palette.pagingNumColor};
      }
      & > div:hover {
        background-color: rgba(200, 200, 200, 0.3);
      }
      & > .onNumber {
        background-color: ${Palette.pagingNumColor};
        color: #fff;
      }
    }
  }
`;
