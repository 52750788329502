import React, { useState } from "react";
import styled from "styled-components";
import { ThemeColor } from "styles/palette";
import RoleUtils from "utils/RoleUtils";
import Button from "./Button";
import { LaundrySearchBlock } from "./LaundrySearch";

interface InventoryStaffPropertySearchProps {
  role: string;
  onSearch: (query: string, type: string) => void;
  size?: number | null;
}

const InventoryStaffPropertySearch: React.FC<InventoryStaffPropertySearchProps> =
  (props) => {
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [searchType, setSearchType] = useState<string>("asset");

    const selectHandleChange = (e: string) => {
      setSearchType(e);
    };

    const onSearch = (e: any) => {
      e.preventDefault();
      props.onSearch(searchQuery, searchType);
    };

    return (
      <InventoryStaffPropertySearchBlock onSubmit={onSearch}>
        <select
          className="selectBox"
          value={searchType!}
          onChange={(e) => {
            selectHandleChange(e.target.value);
          }}
        >
          <option value="asset">품명</option>
          <option value="department">부서명</option>
          <option value="user">이름</option>
        </select>

        <input
          className="textBox"
          value={searchQuery ?? ""}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        <Button
          theme={ThemeColor.primary}
          buttonSize="m"
          onClick={onSearch}
          className="buttonSize"
        >
          검색
        </Button>
      </InventoryStaffPropertySearchBlock>
    );
  };

export default InventoryStaffPropertySearch;

const InventoryStaffPropertySearchBlock = styled(LaundrySearchBlock)``;
