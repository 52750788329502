import { FactoryGetList } from "modules/factory/types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ThemeColor } from "styles/palette";
import RoleUtils from "utils/RoleUtils";
import Button from "./Button";
import { LaundrySearchBlock } from "./LaundrySearch";
import "date-fns";

import DateFormatUtils from "utils/DateFormatUtils";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

interface PaymentSearchProps {
  role: string;
  onSearch: (page: number, data: FactoryGetList) => void;
  size?: number | null;
  type?: string;
}

const PaymentSearch: React.FC<PaymentSearchProps> = ({
  role,
  onSearch,
  size,
  type,
}) => {
  const [searchQuery, setSearchQuery] = useState<string | null>("");
  const [searchType, setSearchType] = useState<string | null>("READY");
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date()
  );

  // const handleDateChange = (date: Date | null) => {
  //   setSelectedDate(date);
  // };

  // const useStyles = makeStyles((theme: Theme) =>
  //   createStyles({
  //     formControl: {
  //       margin: theme.spacing(1),
  //       minWidth: 120,
  //     },
  //     selectEmpty: {
  //       marginTop: theme.spacing(2),
  //     },
  //   })
  // );

  // const useStylesTextField = makeStyles((theme: Theme) =>
  //   createStyles({
  //     root: {
  //       "& > *": {
  //         // margin: theme.spacing(1),
  //         width: "300px",
  //         // marginRight: "30px",
  //         paddingBottom: "9px",
  //       },
  //     },
  //   })
  // );

  // const classes = useStyles();
  // const classesTextField = useStylesTextField();

  // const handleStartDateChange = (date: Date | null) => {
  //   setStartDate(date);
  // };

  // const handleEndDateChange = (date: Date | null) => {
  //   setEndDate(date);
  // };

  const submitSearch = (e: any) => {
    e.preventDefault();
    const data = {
      query: searchQuery,
      dropBox: searchType,
      startDate:
        type && type === "factoryPayment"
          ? "2020-01-01"
          : DateFormatUtils.dateToFormat(startDate),
      endDate:
        type && type === "factoryPayment"
          ? DateFormatUtils.dateToFormat(new Date())
          : DateFormatUtils.dateToFormat(endDate),
      size: size ?? 10,
    };
    onSearch(1, data);
  };

  const selectHandleChange = (e: string) => {
    setSearchType(e);
  };

  // const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   setSearchType(event.target.value as string);
  // };

  useEffect(() => {
    if (RoleUtils.isFactory(role) || RoleUtils.isMaster(role)) {
      selectHandleChange("franchiseName");
    }
    if (RoleUtils.isStore(role)) {
      selectHandleChange("factoryName");
    }
  }, []);

  return (
    <PaymentSearchBlock
      onSubmit={(e) => {
        submitSearch(e);
      }}
    >
      {/* <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Select</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={searchType}
          style={{ fontSize: "15px" }}
          onChange={handleChange}
        >
          <MenuItem value="READY">정산대기</MenuItem>
          <MenuItem value="COMPLETED">정산완료</MenuItem>
          {RoleUtils.isMasterAdmin(role) && (
            <MenuItem value="brandName">브랜드명</MenuItem>
          )}
          {!RoleUtils.isStore(role) && (
            <MenuItem value="franchiseName">지점명</MenuItem>
          )}
          {!RoleUtils.isFactory(role) && (
            <MenuItem value="factoryName">세탁공장명</MenuItem>
          )}
        </Select>
      </FormControl> */}

      <select
        className="selectBox"
        value={searchType!}
        onChange={(e) => {
          selectHandleChange(e.target.value);
        }}
        disabled
      >
        {/* <option value="READY">정산대기</option>
        <option value="COMPLETED">정산완료</option> */}
        {RoleUtils.isMasterAdmin(role) && (
          <option value="brandName">브랜드명</option>
        )}
        {!RoleUtils.isStore(role) && (
          <option value="franchiseName">지점명</option>
        )}
        {!RoleUtils.isFactory(role) && (
          <option value="factoryName">세탁공장명</option>
        )}
      </select>

      <input
        className="textBox"
        value={searchQuery ?? ""}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justifyContent="space-around">
          <KeyboardDatePicker
            className="interval"
            disableToolbar
            variant="inline"
            format="yyyy / MM / dd"
            margin="normal"
            id="date-picker-inline"
            label="Start Date"
            value={startDate}
            onChange={handleStartDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="yyyy / MM / dd"
            margin="normal"
            id="date-picker-inline"
            label="End Date"
            value={endDate}
            onChange={handleEndDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider> */}

      {/* <TextField
        id="standard-basic"
        label="Search"
        className="textField"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      /> */}

      <Button
        theme={ThemeColor.primary}
        buttonSize="m"
        onClick={submitSearch}
        className="buttonSize"
      >
        검색
      </Button>
    </PaymentSearchBlock>
  );
};

export default PaymentSearch;

export const PaymentSearchBlock = styled(LaundrySearchBlock)``;
