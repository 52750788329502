import React, { FormEvent } from "react";
import styled from "styled-components";
import logo from "assets/logo/main_logo1.svg";
import main_img from "assets/image/login_image.png";
import { ILoginForm } from "modules/user/types";
import { Palette, ThemeColor } from "styles/palette";
import RoleUtils from "utils/RoleUtils";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";

interface LoginFormProps {
  inputs: ILoginForm;
  onChange: () => void;
  onLogin: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ inputs, onChange, onLogin }) => {
  const history = useHistory();
  const [loginTypeCheck, setLoginTypeCheck] = useState<string>("PRO");
  const handleLoginSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (loginTypeCheck === "PRO") {
      onLogin();
    } else {
      alert(`등록되지 않은 아이디 입니다. 사용 버전을 다시 확인해주시기 바랍니다.`);
    }
  };

  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (RoleUtils.checkKeepLogin()) setCheck(true);
  }, [check]);

  const goToProVersion = () => {
    alert("서비스 개발중 입니다");
  };

  const keepLoginCheck = () => {
    if (RoleUtils.checkKeepLogin()) {
      setCheck(false);
      localStorage.removeItem("KEEP_LOGIN");
    } else {
      setCheck(true);
      localStorage.setItem("KEEP_LOGIN", "true");
    }
  };

  return (
    <LoginFormBlock>
      <figure className={"logo"}>{/* <img src={logo} alt="" /> */}</figure>
      <div className={"alignWrap"}>
        <section>
          <header>
            {/* <h3 className={"on"}>LITE</h3>
            <h3>
              <a href="http://admin.leadgo.oig.kr/">BASIC</a>
            </h3> */}
            <h3
              onClick={() => setLoginTypeCheck("LITE")}
              className={loginTypeCheck === "LITE" ? "on" : ""}
            >
              LITE
            </h3>
            <h3
              onClick={() => setLoginTypeCheck("BASIC")}
              className={loginTypeCheck === "BASIC" ? "on" : ""}
            >
              BASIC
            </h3>
            <h3
              onClick={() => setLoginTypeCheck("PRO")}
              className={loginTypeCheck === "PRO" ? "on" : ""}
            >
              PRO
            </h3>
          </header>
          <div className="loginTypeInfo">
            {loginTypeCheck === "LITE" && <div>Digital Laundry Solution</div>}
            {loginTypeCheck === "BASIC" && <div>RFID Laundry Solution</div>}
            {loginTypeCheck === "PRO" && <div>RFID + Laundry Solution</div>}
          </div>
          <div className={"text"}>
            <h2>
              {/* <span>
                함께하는 <span>세상</span>
              </span>
              <br />
              B2B 세탁 인트라넷 */}
              린넨서울
            </h2>
            <p>No.1 B2B Laundry Solution</p>
            <p>We Change the standard of B2B laundry</p>
          </div>
          <form onSubmit={(e) => handleLoginSubmit(e)}>
            <div className={"top"}>
              <div className={"inputWrap"}>
                <div>
                  <span>ID</span>
                  <input
                    type="text"
                    name="username"
                    value={inputs.username}
                    onChange={onChange}
                    placeholder="아이디를 입력해주세요"
                  />
                </div>
                <div>
                  <span>Password</span>
                  <input
                    type="password"
                    name="password"
                    value={inputs.password}
                    onChange={onChange}
                    placeholder="비밀번호를 입력해주세요"
                  />
                </div>
              </div>
            </div>
            <div className={"bottom"}>
              <div>
                <button type="submit">로그인</button>
              </div>
            </div>
          </form>
        </section>
        <figure>
          <img src={main_img} alt="" />
        </figure>
      </div>
      <footer>Copyright ⓒ LEADGO. All Rights Reserved.</footer>
    </LoginFormBlock>
  );
};

export default LoginForm;

const LoginFormBlock = styled.div`
  width: 100%;
  height: 100%;
  * {
    margin: 0;
    font-family: "Montserrat", sans-serif, serif-apple-system, BlinkMacSystemFont;
  }
  & > .logo {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 230px;
    /* background-color: lightblue; */
    & > img {
      width: 100%;
      object-fit: contain;
    }
  }
  & > .alignWrap {
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    & > figure {
      ${"" /* width: 520px; */}
      height: 560px;
      margin: 0 auto;
      & > img {
        height: 100%;
        object-fit: contain;
      }
    }
    & > section {
      width: 520px;
      margin: 0 auto;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 40px 0;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      & > header {
        display: flex;
        justify-content: flex-end;
        width: 90%;
        margin: 0 auto;
        & > h3 {
          font-size: 24px;
          text-indent: 10px;
          line-height: 30px;
          color: #cccccc;
          font-weight: bold;
          cursor: pointer;
          & > a {
            color: #cccccc;
          }
          & > a:hover {
            color: #333333;
            text-decoration: underline;
          }
          &.on {
            color: #333333;
            /* text-decoration: underline; */
          }
          &:first-child ~ * {
            margin-left: 20px;
          }
        }
      }
      & > .loginTypeInfo {
        width: 100%;
        height: 30px;
        /* background-color: red; */
        & > div {
          width: 38%;
          height: 25px;
          background-color: #888;
          float: right;
          text-align: center;
          line-height: 27px;
          color: #fff;
          font-weight: bold;
          border-radius: 5px;
          margin-top: 8px;
          margin-right: 27px;
        }
      }
      & > .text {
        width: 90%;
        height: 100px;
        margin: 0 auto;
        margin-top: 20px;
        & > h2 {
          color: #1b1b1b;
          font-size: 26px;
          font-weight: bold;
          /* line-height: 80px; */
          margin-bottom: 10px;
          & > span {
            color: #1b1b1b;
            font-size: 24px;
            font-weight: 500;
            & > span {
              color: #2b41d8;
              font-weight: 700;
            }
          }
        }
        & > p {
          font-size: 16px;
          color: #ccc;
        }
      }
      & > form {
        margin-top: 40px;
        & > .top {
          width: 90%;
          margin: 0 auto;
          & > .inputWrap {
            width: 100%;
            & > div {
              border: 1px solid #ccc;
              padding: 10px;
              border-radius: 10px;
              background-color: #f8f7f7;
              & > span {
                display: block;
                line-height: 20px;
                font-size: 12px;
              }
              & > input {
                display: block;
                width: 100%;
                height: 30px;
                border: 0;
                background-color: #f8f7f7;
              }
              &:nth-child(2) {
                margin-top: 20px;
              }
            }
          }
        }
        & > .bottom {
          width: 90%;
          margin: 40px auto 0;
          & > div {
            text-align: center;
            overflow: hidden;
            margin: 10px auto 0;
            & > button {
              display: block;
              width: 100%;
              height: 40px;
              border: 0;
              background-color: ${Palette.primary};
              color: #fff;
              border-radius: 10px;
              margin: 0 auto;
              font-weight: bold;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  & > footer {
    position: absolute;
    bottom: 40px;
    width: 100%;
    font-size: 14px;
    text-align: center;
  }
  @media (max-width: 768px) {
    padding: 20px;
    & > .logo {
      position: static;
      margin: 0 auto;
    }
    & > .alignWrap {
      position: static;
      flex-direction: column-reverse;
      flex-wrap: nowrap;
      width: 100%;
      & > figure {
        width: 160px;
        height: auto;
        margin: 0 auto;
        margin-top: 10px;
        & > img {
          width: 100%;
          height: auto;
        }
      }
      & > section {
        max-width: 520px;
        width: 100%;
        margin-top: 20px;
        padding: 20px 0;
        & > header {
          & > h3 {
            font-size: 18px;
          }
        }
        & > .text {
          margin-top: 0;
          & > h2 {
            font-size: 22px;
          }
          & > p {
            font-size: 12px;
          }
        }
        & > form {
          margin-top: 20px;
        }
      }
    }
    & > footer {
      position: static;
      margin-top: 40px;
    }
  }
`;
