import Button from "components/common/Button";
import Paging from "components/common/Paging";
import StoreSearch from "components/common/StoreSearch";
import {
  AdminFranchiseContract,
  AdminGetList,
  AdminMatchingData,
  AdminMatchingList,
  AdminStoreDetail,
  AdminStoreList,
  StoreContract,
} from "modules/admin/types";
import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { Palette, ThemeColor } from "styles/palette";
import DateFormatUtils from "utils/DateFormatUtils";
import NumberUtils from "utils/NumberUtils";
import AdminMatchingField from "./AdminMatchingField";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import AdminStoreContract from "./AdminStoreContract";
import { Link } from "react-router-dom";

interface AdminStoreStatusProps {
  role: string;
  storeListPageMove: (page: number, data: AdminGetList) => void;
  adminStoreList: AdminStoreList | null;
  adminStoreDetail: AdminStoreDetail | null;
  matchingList: () => void;
  storeDetail: (id: number) => void;
  adminMatchingList: AdminMatchingList[] | null;
  matchingFactory: (payload: AdminMatchingData) => void;
  postPWInitialization: (id: number) => void;
  modifyStoreUserName: (
    id: number,
    userName: string,
    page: number,
    data: AdminGetList | null
  ) => void;
  onSelectDetailId: (id: number) => void;
  postfranchiseContract: (payload: AdminFranchiseContract) => void;
}

const AdminStore: React.FC<AdminStoreStatusProps> = ({
  role,
  storeListPageMove,
  adminStoreList,
  adminStoreDetail,
  matchingList,
  storeDetail,
  adminMatchingList,
  matchingFactory,
  modifyStoreUserName,
  postPWInitialization,
  onSelectDetailId,
  postfranchiseContract,
}) => {
  const [matchingFieldEdit, setMatchingFieldEdit] = useState(false);
  const [selectStore, setSelectStore] = useState<number | null>(null);
  const [matchingFactoryId, setMatchingFactoryId] = useState<number | null>(null);
  const [selectId, setSelectId] = useState<number | null>(null);
  const [contractModal, setContractModal] = useState<boolean>(false);
  // const [detail, setDetail] = useState(false);
  const [searchData, setSearchData] = useState<AdminGetList>({
    query: "",
    dropBox: "",
    startDate: "",
    endDate: "",
    size: 10,
  });
  // const [currentPage, setCurrentPage] = useState<number>(1);
  const [contractData, setContractData] = useState<StoreContract | null>(null);

  const factoryMatching = (factoryId: number) => {
    const payload = {
      franchiseId: selectStore!,
      factoryId: factoryId!,
    };
    matchingFactory(payload);
  };

  const onMatching = (id: number, factoryId: number) => {
    matchingList();
    setSelectStore(id);
    setMatchingFactoryId(factoryId);
    setMatchingFieldEdit(true);
  };

  const onClose = () => {
    // setDetail(false);
    setMatchingFieldEdit(false);
  };

  // const onDetail = (id: number) => {
  //   setDetail(true);
  //   storeDetail(id);
  // };

  const onSearch = (page: number, data: AdminGetList) => {
    setSearchData(data);
    // setCurrentPage(page);
    storeListPageMove(page, data);
  };

  const pageMove = (page: number) => {
    // setCurrentPage(page);
    storeListPageMove(page, searchData ?? null);
  };

  const selectShowEntries = (size: number) => {
    searchData.size = size;
    storeListPageMove(1, searchData ?? null);
  };

  const [listNumber, setListNumber] = useState<number[]>([]);
  useEffect(() => {
    setListNumber(
      NumberUtils.numberToList(
        adminStoreList?.currentPage ?? 0,
        adminStoreList?.totalCount ?? 0,
        searchData.size ?? 10
      )
    );
  }, [adminStoreList, searchData]);

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      fontWeight: 700,
    },
  });
  const classes = useStyles();

  // const onModifyStoreUserName = (id: number, userName: string) => {
  //   modifyStoreUserName(id, userName, currentPage, searchData ?? null);
  // };

  const onContractModal = (id: number, contract: StoreContract) => {
    setSelectId(id);
    setContractModal(true);
    setContractData(contract);
  };

  return (
    <AdminStoreBlock>
      {matchingFieldEdit && (
        <AdminMatchingField
          matchingFactoryId={matchingFactoryId ?? 0}
          factoryMatching={factoryMatching}
          adminMatchingList={adminMatchingList}
          onClose={onClose}
        />
      )}
      {contractModal && (
        <AdminStoreContract
          storeContract={contractData}
          detailId={selectId}
          setContractModal={setContractModal}
          postfranchiseContract={postfranchiseContract}
        />
      )}
      {/* {detail && (
        <AdminStoreStatusDetail
          postPWInitialization={postPWInitialization}
          adminStoreDetail={adminStoreDetail}
          onClose={onClose}
          onModifyStoreUserName={onModifyStoreUserName}
        />
      )} */}
      <div className="headers">
        <h3>지점 관리</h3>
        {/* <div className="topWrapper">
          <StoreSearch role={role} onSearch={onSearch} />
        </div> */}
      </div>
      <div className="tableSelect">
        <div>
          <span>Show </span>
          <select onChange={(e) => selectShowEntries(parseInt(e.target.value, 10))}>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
          <span> entries</span>
        </div>
        <div className="topWrapper">
          <span>Search: </span>
          <StoreSearch role={role} onSearch={onSearch} />
        </div>
      </div>
      <div className="tableWrapper">
        <TableContainer component={Paper} className="tables">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: 900, width: "70px" }}>
                  순번
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900 }}>
                  브랜드명
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900, width: "15%" }}>
                  지점명
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900, width: "15%" }}>
                  연락처
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900, width: "15%" }}>
                  가입일
                </TableCell>
                {/* <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "15%" }}
                >
                  누적 세탁신청
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "10%" }}
                >
                  최근 신청일
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "15%" }}
                >
                  총 결제금액(원)
                </TableCell> */}
                <TableCell align="center" style={{ fontWeight: 900, width: "27%" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="tableBody">
              {adminStoreList?.list.map((store, index) => (
                <TableRow key={index} className="tableHover">
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                    onClick={() => onSelectDetailId(store.id)}
                  >
                    {listNumber[index]}
                  </TableCell>
                  <TableCell align="center" onClick={() => onSelectDetailId(store.id)}>
                    {store.brandName ?? "-"}
                  </TableCell>
                  <TableCell align="center" onClick={() => onSelectDetailId(store.id)}>
                    {store.companyName}
                  </TableCell>
                  <TableCell align="center" onClick={() => onSelectDetailId(store.id)}>
                    {store.companyPhone}
                  </TableCell>
                  <TableCell align="center" onClick={() => onSelectDetailId(store.id)}>
                    {DateFormatUtils.dateToFormat(store.joinDate)}
                  </TableCell>
                  {/* <TableCell
                    align="center"
                    onClick={() => onSelectDetailId(store.id)}
                  >
                    {NumberUtils.numberToComma(store.totalLaundryCount ?? 0)}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => onSelectDetailId(store.id)}
                  >
                    {DateFormatUtils.dateToFormat(store.lastOrderDate)}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => onSelectDetailId(store.id)}
                  >
                    {NumberUtils.numberToComma(store.totalPrice ?? 0)}
                  </TableCell> */}
                  <TableCell align="center" className="tableBtnBox">
                    <Button
                      theme={ThemeColor.primary}
                      buttonSize="s"
                      onClick={() => onContractModal(store.id, store.contract)}
                    >
                      계약설정
                    </Button>
                    <Button
                      theme={ThemeColor.primary}
                      buttonSize="s"
                      onClick={() => onMatching(store.id, store.matchedFactoryId)}
                    >
                      공장매칭
                    </Button>
                    <Link to={`/admin/store/item/${store.id}`}>
                      <Button theme={ThemeColor.primary} buttonSize="s">
                        자산정보/관리
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Paging
          listAction={pageMove}
          listCount={adminStoreList?.totalPage ?? 1}
          currentPage={adminStoreList?.currentPage}
          totalCount={adminStoreList?.totalCount ?? 1}
          size={searchData.size ?? 10}
        ></Paging>
      </div>
    </AdminStoreBlock>
  );
};

const AdminStoreBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  .tables {
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .tableHover > td {
    /* padding: 10px; */
  }
  .tableHover {
    & > .tableBtnBox {
      padding: 0;

      & > div,
      a {
        margin: 0;
        margin-right: 5px;
        /* height: 35px; */
      }
    }
  }
  .tableHover:hover {
    background-color: #eee;
    cursor: pointer;
  }
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  .tableSelect {
    height: 70px;
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: space-between;
    & > div {
      color: #999;
      height: 40px;
      /* background-color: red; */
      overflow: hidden;
      & > span {
        font-size: 14px;
        line-height: 37px;
      }
      & > select {
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        transition: ease-in-out;
        transition-duration: 0.1s;
        border-radius: 3px;
      }
      & > select:hover {
        border-bottom: 2px solid ${Palette.primary};
      }
    }
  }
  .topWrapper {
    display: flex;
    justify-content: flex-end;
    & > span {
      font-size: 14px;
      line-height: 37px;
      margin-right: 5px;
    }
  }
  .tableWrapper {
    min-width: 51rem;
    padding-bottom: 1.5rem;
  }
  .tableBody {
    tr {
      height: 53px !important;
      td {
        padding: 0;
      }
    }
  }
`;

export default AdminStore;
