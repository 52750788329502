import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import DateFormatUtils from "utils/DateFormatUtils";
import NumberUtils from "utils/NumberUtils";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";
// import dummy from "./dummy.json";
import { FactoryData } from "modules/factory/types";

import { ProfileDetail } from "modules/user/types";

interface LaundryTableProps {
  startDate: string;
  endDate: string;
  dataList: dataList[];
  chartType?: string;
  franchiseName?: string;
  factory?: FactoryData | null;
  factoryProfileDetail?: ProfileDetail | null;
  contractType: string;
  contractPrice: number;
  taxIncludedCheck: boolean;
}

interface dataList {
  isOrder: number;
  title: string;
  list: laundryList[];
  price: number;
}

interface laundryList {
  name: string;
  date: string;
  count: number;
}

const LaundryTable: React.FC<LaundryTableProps> = (props) => {
  const [startDate, setStartDate] = useState<moment.Moment>(() => moment());
  const [endDate, setEndDate] = useState<moment.Moment>(() => moment());
  const [dateArr, setDateArr] = useState<string[]>([]);
  const [sumCount, setSumCount] = useState<number[]>([]);
  const [sumPrice, setSumPrice] = useState<number[]>([]);
  const [dateSum, setDateSum] = useState<number[]>([]);
  const [price, setPrice] = useState<number>(0);
  const [count, setCount] = useState<number>(0);

  // console.log("testesets");
  // console.log(props.startDate);
  // console.log(props.endDate);

  useEffect(() => {
    setDateSum([]);
    setPrice(0);
    setCount(0);
    dateCreate(props.startDate, props.endDate);
    if (props.dataList.length > 0) {
      let dataList = props.dataList;
      let sumList = [];
      let priceSumList = [];
      let countList = [];
      let price = 0;
      let count = 0;
      for (let i = 0; i < dataList.length; i++) {
        let sum = 0;

        for (let n = 0; n < dataList[i].list.length; n++) {
          sum = sum + dataList[i].list[n].count;
        }
        sumList.push(sum);
        priceSumList.push(dataList[i].price * sum);
        price = price + dataList[i].price * sum;
        console.log(sum);
        count = count + sum;
      }
      for (let n = 0; n < dataList[0].list.length; n++) {
        let dayCount = 0;
        for (let i = 0; i < dataList.length; i++) {
          dayCount = dayCount + dataList[i].list[n].count;
        }
        countList.push(dayCount);
      }
      setDateSum(countList);
      setCount(count);
      setPrice(price);
      setSumCount(sumList);
      setSumPrice(priceSumList);
    }
  }, [props.dataList]);

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      fontWeight: 700,
    },
  });
  const classes = useStyles();

  const submitSearch = () => {
    let term = DateFormatUtils.dateTerm(startDate, endDate);

    if (term > 31) {
      alert("1개월을 초과하여 선택 할 수 없습니다");
      return;
    }
  };

  const dateCreate = (sd?: string, ed?: string) => {
    if (!sd) sd = "2021-08-01";
    if (!ed) ed = "2021-08-30";
    var regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
    if (!(regex.test(sd) && regex.test(ed))) return "Not Date Format";
    var result = [];
    var curDate = new Date(sd);
    while (curDate <= new Date(ed)) {
      result.push(curDate.toISOString().split("T")[0]);
      curDate.setDate(curDate.getDate() + 1);
    }

    setDateArr(result);
  };

  return (
    <Wrapper>
      <div className="headers">
        <h3 onClick={() => dateCreate()}>세탁집계표</h3>
      </div>
      <div>
        <div>
          <h4>{props.franchiseName} 貴中</h4>
          <h5>
            세탁기간 : {props.startDate} ~ {props.endDate}
          </h5>
        </div>
        <div className="stampWrap">
          <div className="taxAmount">
            <div>
              {props.taxIncludedCheck && (
                <>
                  <span>
                    공급가액:{" "}
                    {props.contractType === "UNIT_PRICE"
                      ? NumberUtils.numberToComma(price)
                      : NumberUtils.numberToComma(props.contractPrice)}
                  </span>
                  <span>/</span>
                  <span>
                    세액:{" "}
                    {props.contractType === "UNIT_PRICE"
                      ? NumberUtils.numberToComma(Number(price) * 0.1)
                      : NumberUtils.numberToComma(Number(props.contractPrice) * 0.1)}{" "}
                    원
                  </span>
                </>
              )}
            </div>
            <div>
              {props.taxIncludedCheck ? "합계금액" : "공급가액"}:{" "}
              {props.taxIncludedCheck ? (
                <span>
                  {props.contractType === "UNIT_PRICE"
                    ? NumberUtils.numberToComma(Number(price) + Number(price) * 0.1)
                    : NumberUtils.numberToComma(
                        Number(props.contractPrice) + Number(props.contractPrice) * 0.1
                      )}{" "}
                  원
                </span>
              ) : (
                <span>
                  {props.contractType === "UNIT_PRICE"
                    ? NumberUtils.numberToComma(price)
                    : NumberUtils.numberToComma(props.contractPrice)}{" "}
                  원
                </span>
              )}
            </div>
          </div>

          <div className="stampBox">
            <div>
              <span>인수자</span>
            </div>
            <div>
              <span>인계자</span>
            </div>
          </div>
        </div>
      </div>
      <LaundryTableBlock>
        <table>
          <thead>
            {dateSum.length > 0 && (
              <>
                <tr className={"title"}>
                  <th>No.</th>
                  <th>품목</th>
                  {dateArr.map((date, index) => (
                    <th key={index}>{moment(date).format("M/D")}</th>
                  ))}
                  <th>총 수량</th>
                  <th>단가</th>
                  <th>총 발생액</th>
                </tr>
                <tr>
                  <th colSpan={2}>합계</th>
                  {dateSum.map((val, index) => (
                    <th key={index}>{val ? NumberUtils.numberToComma(val) : "-"}</th>
                  ))}
                  <th className={"price"}>{NumberUtils.numberToComma(count)}</th>
                  <th></th>
                  <th className={"price"}>
                    {props.contractType === "UNIT_PRICE"
                      ? NumberUtils.numberToComma(price)
                      : NumberUtils.numberToComma(props.contractPrice)}
                  </th>
                </tr>
              </>
            )}
          </thead>
          <tbody>
            {props.dataList.map((val, index) => (
              <tr key={index}>
                <td className="frontPlace">{index + 1}</td>
                <td className={"title frontPlace"}>{val.title}</td>
                {val.list.map((item, idx) => (
                  <td key={item.date}>
                    {item.count ? NumberUtils.numberToComma(item.count) : "-"}
                  </td>
                ))}
                <td className={"price"}>{NumberUtils.numberToComma(sumCount[index])}</td>
                <td className={"price"}>
                  {props.contractType === "UNIT_PRICE" ? NumberUtils.numberToComma(val.price) : "-"}
                </td>
                <td className={"price lastPlace"}>
                  {sumPrice[index] && props.contractType === "UNIT_PRICE"
                    ? NumberUtils.numberToComma(sumPrice[index])
                    : "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={"footer"}>
          공급자 {props.factoryProfileDetail?.companyName} | (대표자){" "}
          {props.factoryProfileDetail?.representative} | (등록번호)
          {props.factoryProfileDetail?.registrationNumber} | (주소){" "}
          {props.factoryProfileDetail?.companyAddress.street} | (TEL){" "}
          {props.factoryProfileDetail?.companyPhone} | (FAX)
          {props.factoryProfileDetail?.fax}
        </div>
      </LaundryTableBlock>
    </Wrapper>
  );
};

export default LaundryTable;

const Wrapper = styled.div`
  width: auto;
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  height: auto;

  & > .headers {
    width: 1414px;
    margin: 0 auto;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    align-items: center;
  }
  & > div:nth-child(2) {
    margin: 0 auto;
    width: 1414px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    & > div:nth-child(1) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      & > h4,
      h5 {
        margin: 0;
      }
    }
    .stampWrap {
      /* position: absolute;
      top: 0;
      right: 0; */
      width: auto;
      height: auto;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      & > .taxAmount {
        width: auto;
        height: auto;
        margin-right: 16px;
        min-width: 174px;

        & > div:nth-child(1) {
          font-size: 11px;
          margin-bottom: 5px;
          text-align: right;
          & > span:nth-child(2) {
            margin: 0 2px;
          }
        }
        & > div:nth-child(2) {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          & > span {
            text-align: right;
            font-weight: bold;
          }
        }
      }
      & > .stampBox {
        width: 200px;
        height: 96px;
        & > div {
          display: inline-block;
          width: calc(100% / 2);
          height: 100%;
          border: 1px solid #333;
          box-sizing: border-box;
          &:nth-child(1) {
            border-right: 0;
          }
          & > span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 20px;
            font-size: 14px;
            border-bottom: 1px solid #333;
          }
        }
      }
    }
  }
`;

export const LaundryTableBlock = styled.div`
  & > table {
    margin: 0 auto;
    width: 1414px;
    /* height: 1200px; */
    height: auto;
    border-collapse: collapse;
    & > thead {
      & > tr {
        background-color: #fce4d6;
        height: 23px;
        & > th {
          border: 1px solid #000;
          font-size: 12px;
        }
        &.title {
          background-color: #ddebf7;
        }
      }
    }
    & > tbody {
      & > tr {
        height: 23px;
        & > td {
          border: 1px solid #000;
          text-align: center;
          font-size: 12px;
          /* &.title {
            text-align: left;
          } */
          /* &.price {
            text-align: right;
          } */
        }
        & > .frontPlace {
          background-color: #ededed;
        }
        & > .lastPlace {
          background-color: #fff2cc;
        }
      }
    }
  }
  & > .footer {
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
    line-height: 40px;
  }
`;
