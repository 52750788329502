import React, { useState } from "react";
import styled from "styled-components";
import { ThemeColor } from "styles/palette";
import Button from "./Button";
import { LaundrySearchBlock } from "./LaundrySearch";

import "date-fns";

interface BoardSearchProps {
  role: string;
  onSearch: (page: number, data: any) => void;
  size?: number;
}

const BoardSearch: React.FC<BoardSearchProps> = ({ onSearch, size }) => {
  const [searchQuery, setSearchQuery] = useState<string | null>("");
  const [searchType, setSearchType] = useState<string | null>("writer");

  const submitSearch = (e: any) => {
    e.preventDefault();
    const data = {
      query: searchQuery,
      dropBox: searchType,
      size: size ?? 10,
    };
    onSearch(1, data);
  };

  const selectHandleChange = (e: string) => {
    setSearchType(e);
  };

  return (
    <BoardSearchBlock onSubmit={(e) => submitSearch(e)}>
      <select
        className="selectBox"
        value={searchType!}
        onChange={(e) => {
          selectHandleChange(e.target.value);
        }}
      >
        <option value="writer">작성자</option>
        <option value="title">제목</option>
      </select>
      <input
        className="textBox"
        value={searchQuery ?? ""}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <Button
        theme={ThemeColor.primary}
        buttonSize="m"
        onClick={submitSearch}
        className="buttonSize"
      >
        검색
      </Button>
    </BoardSearchBlock>
  );
};

export default BoardSearch;

export const BoardSearchBlock = styled(LaundrySearchBlock)``;
