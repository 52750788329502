import Button from "components/common/Button";
import ItemizedAccountSearch from "components/common/ItemizedAccountSearch";
import moment, { invalid } from "moment";
import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { Palette, ThemeColor } from "styles/palette";
import NumberUtils from "utils/NumberUtils";
import {
  FactoryItemizedAccountSearch,
  FactoryItemizedAccountPDF,
  FactoryMatchingFranchiseList,
  FactoryItemizedAccountExcel,
  FactoryGetList,
  Subcategory,
  LaundryDataList,
  FactoryExcelMonth,
  LaundryDataListDetail,
} from "modules/factory/types";
import { useEffect } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import logo from "assets/logo/main_logo1.svg";
import LaundryTable from "components/common/Table/LaundryTable";
import { useHistory } from "react-router";
import { ProfileDetail } from "modules/user/types";
import info from "assets/icons/info.png";
import LaundryTableYear from "components/common/Table/LaundryTableYear";

interface FactoryItemizedAccountProps {
  name: string;
  onSearch: (payload: FactoryItemizedAccountSearch) => void;
  onDeliverySearch: (id: number, data: FactoryGetList | null) => void;
  factoryItemizedAccountExcel: FactoryItemizedAccountExcel | null;
  factoryItemizedAccount: FactoryItemizedAccountPDF | null;
  factoryMatchingFranchiseList: FactoryMatchingFranchiseList | null;
  getFactoryLaundryTableYearSearch: (data: FactoryExcelMonth) => void;
  getFactoryLaundryTable: (id: number, data: FactoryGetList | null, chartType: string) => void;
  laundryTableData: LaundryDataList | null;
  laundryTableYearData: LaundryDataListDetail[] | null;
  postFactoryTableShare: (
    image: string,
    frId: number,
    title: string,
    startDate: string,
    endDate: string,
    monthData: string,
    chartType: string
  ) => void;
  franchiseId: number;
  selectType: string;
  onSelectType: (type: string) => void;
  factoryProfileDetail: ProfileDetail | null;
  layoutHeightData: number;
  searchQuery: any;
}

type FactoryItemizedAccountBlockType = {
  selectChartType: string;
  layoutHeight: number;
};

const FactoryItemizedAccount: React.FC<FactoryItemizedAccountProps> = ({
  name,
  onSearch,
  onDeliverySearch,
  getFactoryLaundryTableYearSearch,
  getFactoryLaundryTable,
  factoryItemizedAccount,
  factoryMatchingFranchiseList,
  factoryItemizedAccountExcel,
  laundryTableData,
  laundryTableYearData,
  postFactoryTableShare,
  franchiseId,
  selectType,
  onSelectType,
  factoryProfileDetail,
  layoutHeightData,
  searchQuery,
}) => {
  const history = useHistory();
  const week = ["일", "월", "화", "수", "목", "금", "토"];
  const [searchDate, setSearchDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [quantity, setQuantity] = useState<number>(0);
  const [weight, setWeight] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalLength, setTotalLength] = useState<number>(0);

  const [itemizedArray, setItemizedArray] = useState<any[]>([]);
  const [itemizedImageArray, setItemizedImageArray] = useState<any[]>([]);
  const [setDate, setSetDate] = useState<string | null>(null);
  const [chartType, setChartType] = useState<string | null>("");
  const [franchiseName, setFranchiseName] = useState<string>("");
  const [contractType, setContractType] = useState<string | null>();
  const [contractPrice, setContractPrice] = useState<number | null>();
  const [monthData, setMonthData] = useState<string>("");
  const [searchMonth, setSearchMonth] = useState({
    startMonth: "",
    endMonth: "",
  });
  const [contractDate, setContractDate] = useState({
    startDate: "",
    endDate: "",
  });

  const [testCheck, setTestCheck] = useState<boolean>(false);

  /** 리스트 납품수량 0인값 노출: true 미노출: false */
  const [quantityNullAllowed, setQuantityNullAllowed] = useState<boolean>(true);

  /** 공급가 + 세액 여부 체크 / 공급가 + 세액: true 공급가만: false */
  const [taxIncludedCheck, setTaxIncludedCheck] = useState<boolean>(false);

  const [searchCheck, setSearchCheck] = useState<boolean>(true);

  useEffect(() => {
    if (Object.keys(searchQuery).length > 0) {
      let id = searchQuery.franchiseId ?? "";
      let date = searchQuery.accountDate ?? "";
      let type = searchQuery.accountType ?? "";
      let name = searchQuery.franchiseName ?? "";

      setSearchDate({
        ...searchDate, startDate : moment(date).format("YYYY-MM-DD")
      });
      setSetDate(moment(date).format("YYYY년 MM월 DD일"));
      setChartType(String(type));
      setFranchiseName(name);
      setTestCheck(true);
    }
  }, [searchQuery]);

  const dateSelect = (startDate: string, endDate: string, franchiseName: string) => {
    setSearchDate({
      startDate,
      endDate,
    });
    setFranchiseName(franchiseName);
  };

  const monthSelect = (startMonth: string, endMonth: string, franchiseName: string) => {
    setSearchMonth({
      startMonth,
      endMonth,
    });
    setFranchiseName(franchiseName);
  };

  const contractDateData = (start: string, end: string) => {
    if (Number(start) === 1) {
      end = String(moment(monthData, "YYYY-MM").daysInMonth());
    } else {
      if (start.length === 1) start = `0${start}`;
      if (end.length === 1) end = `0${end}`;
    }
    setContractDate({
      startDate: start,
      endDate: end,
    });
  };

  const monthSelector = (month: string, start: string, end: string) => {
    if (Number(start) === 1) {
      end = String(moment(month, "YYYY-MM").daysInMonth());
      setSearchCheck(false);
    } else {
      if (end && end.length === 1) end = `0${end}`;
    }
    if (start && start.length === 1) start = `0${start}`;
    setMonthData(month);
    setContractDate({
      startDate: start,
      endDate: end,
    });
  };

  useEffect(() => {
    setSearchDate({
      startDate: "",
      endDate: "",
    });
    setQuantity(0);
    setWeight(0);
    setTotalPrice(0);
    setItemizedArray([]);
    setItemizedImageArray([]);
    setContractType(null);
    if (Object.keys(searchQuery).length <= 0) {
      setTestCheck(false);
    }
  }, []);

  useEffect(() => {
    if (factoryItemizedAccountExcel) {
      let dataArray = [];
      let listNumber = 1;
      for (let i = 0; i < factoryItemizedAccountExcel.assetDetailsList.length; i++) {
        const largeCategoryTitle = factoryItemizedAccountExcel.assetDetailsList[i].largeCategory;
        const deliveryCount = factoryItemizedAccountExcel.assetDetailsList[i].count;
        const deliveryWeight = factoryItemizedAccountExcel.assetDetailsList[i].deliveryWeight;
        const weight = factoryItemizedAccountExcel.assetDetailsList[i].weight;
        const reLaundry = factoryItemizedAccountExcel.assetDetailsList[i].reLaundry;
        const price = factoryItemizedAccountExcel.assetDetailsList[i].price;

        let data = {
          largeCategoryTitle,
          amount: deliveryCount,
          deliveryWeight,
          weight,
          reLaundry,
          price,
        };

        for (
          let j = 0;
          j < factoryItemizedAccountExcel.assetDetailsList[i].subcategory.length;
          j++
        ) {
          if (
            !quantityNullAllowed &&
            (!factoryItemizedAccountExcel.assetDetailsList[i].subcategory[j].amount ||
              factoryItemizedAccountExcel.assetDetailsList[i].subcategory[j].amount! <= 0)
          )
            continue;
          factoryItemizedAccountExcel.assetDetailsList[i].subcategory[j].number = listNumber;
          factoryItemizedAccountExcel.assetDetailsList[i].subcategory[j].largeCategory =
            largeCategoryTitle;
          dataArray.push(factoryItemizedAccountExcel.assetDetailsList[i].subcategory[j]);
          listNumber++;
        }

        dataArray.push(data);
      }

      setItemizedImageArray(dataArray); // 명세표 pdf 전송용

      const array = dataArray;

      let listArray = [];
      let t = 0; // 시작값
      let j = 37; // 시작값부터 가져올 갯수

      for (let i = 0; i <= Math.ceil(array.length / 38) - 1; i++) {
        const item = array.slice();
        const res = item.splice(t, j);

        if (i === 0) {
          j = 38;
          t += 37;
        } else {
          t += 38;
        }

        if (res.length <= 0 && i <= 1) continue;
        listArray.push(res);
      }

      setTotalLength(listArray.length);
      setItemizedArray(listArray);
    }
  }, [factoryItemizedAccountExcel, quantityNullAllowed]);

  const handleSave = async () => {
    const pages: any = document.querySelectorAll(".pdfWrapper");

    let canvases: any[] = [];
    for (let i = 0; i < pages.length; i++) {
      const canvas = await html2canvas(pages[i], {
        scale: 2,
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
      });
      canvases.push(canvas);
    }

    let pdf = new jsPDF({
      orientation:
        chartType === "aggregation1" || chartType === "aggregation2" || chartType === "aggregation3"
          ? "landscape"
          : "portrait",
      unit: "mm",
      format: "a4",
    });
    const margin = 0;
    const position = 0;
    const width =
      chartType === "aggregation1" || chartType === "aggregation2" || chartType === "aggregation3"
        ? 297
        : 210;
    const height =
      chartType === "aggregation1" || chartType === "aggregation2" || chartType === "aggregation3"
        ? 210
        : 297;

    for (let i = 0; i < canvases.length; i++) {
      const imgData = canvases[i].toDataURL("image/jpeg");
      pdf.addImage(imgData, "png", margin, position, width, height, undefined, "FAST");
      i < canvases.length - 1 && pdf.addPage();
    }

    let saveKind = "거래명세표";
    if (
      chartType === "aggregation1" ||
      chartType === "aggregation2" ||
      chartType === "aggregation3"
    )
      saveKind = "세탁집계표";

    let saveName = `${searchDate.startDate}~${searchDate.endDate}.pdf`;
    if (chartType === "aggregation1") {
      saveName = `${moment(searchDate.startDate).format("YYYY-MM")}`;
    }
    if (chartType === "specifi1") {
      saveName = searchDate.startDate;
    }
    if (chartType === "specifi3") {
      saveName = `금액포함_${searchDate.startDate}`;
    }

    pdf.save(`${saveKind}_${franchiseName}_${saveName}`);
  };

  const sendTable = async () => {
    // setTestCheck(true);
    if (window.confirm("전송하시겠습니까?")) {
      let pages: any;
      if (chartType === "specifi1" || chartType === "specifi2" || chartType === "specifi3") {
        pages = document.querySelectorAll(".pdfWrapper2");
      } else {
        pages = document.querySelectorAll(".pdfWrapper");
      }

      let canvases: any[] = [];
      for (let i = 0; i < pages.length; i++) {
        const canvas = await html2canvas(pages[i], {
          scale: 2,
          scrollX: -window.scrollX,
          scrollY: -window.scrollY,
        });
        canvases.push(canvas);
      }
      let imgData;
      for (let i = 0; i < canvases.length; i++) {
        imgData = canvases[i].toDataURL("image/jpeg");
      }
      let type = "";
      if (chartType === "specifi1" || chartType === "specifi2" || chartType === "specifi3") {
        if (chartType === "specifi2") {
          type = "거래명세표 (" + searchDate.startDate + " ~ " + searchDate.endDate + ")";
        } else {
          type = "거래명세표 (" + searchDate.startDate + ")";
        }
      } else {
        if (chartType === "aggregation1") {
          type = `세탁집계표 (${monthData}-${contractDate.startDate} ~ ${String(
            searchCheck
              ? moment(new Date(`${monthData}-${contractDate.endDate}`).getTime())
                  .add("1", "M")
                  .format("YYYY-MM-DD")
              : `${monthData}-${contractDate.endDate}`
          )})`;
        } else {
          type = "세탁집계표 (" + searchDate.startDate + " ~ " + searchDate.endDate + ")";
        }
      }

      postFactoryTableShare(
        imgData,
        franchiseId,
        type,
        searchDate.startDate,
        searchDate.endDate,
        monthData,
        chartType!
      );
      // onSelect();
      if (chartType === "specifi1" || chartType === "specifi2" || chartType === "specifi3") {
        alert("거래명세표를 전송하였습니다.");
      } else {
        alert("세탁집계표를 전송하였습니다.");
      }
    }
    // setTestCheck(false);
  };

  const onSelect = () => {
    onSelectType("list");
    history.push(`/factory/itemizedAccount/list`);
  };

  useEffect(() => {
    let date = `${moment(searchDate.startDate).format("YYYY년 MM월 DD일")}`;
    if (chartType === "specifi2") {
      date = `${moment(searchDate.startDate).format("YYYY년 MM월 DD일")} ~ ${moment(
        searchDate.endDate
      ).format("YYYY년 MM월 DD일")}`;
    }
    if (chartType === "aggregation1") {
      date = `${moment(searchDate.startDate).format("YYYY년 MM월")}`;
    }
    setSetDate(date);
  }, [searchDate, chartType]);

  const searchRequestCheck = (data: boolean) => {
    setTestCheck(data);
  };

  return (
    <FactoryItemizedAccountBlock selectChartType={chartType!} layoutHeight={layoutHeightData}>
      {/* <div>
        <DateApp />
      </div> */}
      <div>
        <div className="headers">
          <h3>거래명세표</h3>
          <div>
            <span className="selectClick on">조회</span>
            <span>|</span>
            <span className="selectClick" onClick={onSelect}>
              목록
            </span>
          </div>
          <div className="infoBox">
            <div className="info">
              <span>서비스가이드</span>
              <span>
                <img src={info} alt="" />
              </span>
            </div>
            <div className="infoGuide">
              확인 및 수정요청을 진행하지 않더라도 전송일을 기준으로
              <br />
              아래의 기한이 지나면 납품확인 완료로 자동 변경됩니다.
              <br />
              거래명세표: 전송 2일 후 / 세탁집계표: 전송 10일 후
            </div>
          </div>
        </div>
        <div className="tableSelect">
          {chartType !== "" && chartType !== "specifi1" && chartType !== "specifi2" && (
            <label>
              <input
                type="checkbox"
                checked={taxIncludedCheck}
                onChange={(e) => setTaxIncludedCheck(e.target.checked)}
              />
              세액 포함
            </label>
          )}
          {(chartType === "specifi1" || chartType === "specifi2" || chartType === "specifi3") && (
            <label>
              <input
                type="checkbox"
                checked={quantityNullAllowed}
                onChange={(e) => setQuantityNullAllowed(e.target.checked)}
              />{" "}
              품목 고정
            </label>
          )}
          <span>Search: </span>
          <ItemizedAccountSearch
            searchRequestCheck={searchRequestCheck}
            setItemizedArray={setItemizedArray}
            monthSelector={monthSelector}
            setMonthData={setMonthData}
            role={"FACTORY"}
            setContractType={setContractType}
            setContractPrice={setContractPrice}
            setChartType={setChartType}
            searchDate={dateSelect}
            searchMonth={monthSelect}
            getFactoryLaundryTableSearch={getFactoryLaundryTable}
            getFactoryLaundryTableYearSearch={getFactoryLaundryTableYearSearch}
            onSearch={onDeliverySearch}
            factoryMatchingFranchiseList={factoryMatchingFranchiseList}
            contractDateData={contractDateData}
            searchQuery={searchQuery}
          />
        </div>
      </div>
      <div className="tableWrapper">
        <div className="pdf_header">
          {(itemizedArray.length !== 0 || laundryTableData) && testCheck && (
            <div className="downloadBtn">
              <div>
                <Button
                  theme={ThemeColor.primary}
                  buttonSize="m"
                  onClick={handleSave}
                  className="buttonSize"
                >
                  PDF로 저장
                </Button>
                <Button
                  theme={ThemeColor.primary}
                  buttonSize="m"
                  className="buttonSize"
                  onClick={sendTable}
                >
                  전송하기
                </Button>
              </div>
            </div>
          )}
        </div>

        <div>
          {!testCheck && (
            <div className="search_announcement">
              조회할 종류와 지점명, 세탁 완료 기간을 선택해주세요.
            </div>
          )}
          {itemizedArray.length === 0 && testCheck && (
            <div className="search_announcement">데이터가 없습니다.</div>
          )}

          <div id="pdf_download" className="pdf_place">
            {itemizedArray.length > 0 &&
              (chartType === "specifi1" || chartType === "specifi2" || chartType === "specifi3") &&
              itemizedArray.map((items, index) => (
                <div className="pdfWrapper" key={index}>
                  <>
                    {index === 0 && (
                      <>
                        <div className="itemized_top">
                          <div className="logo">
                            <img src={logo} alt="" />
                          </div>
                          <div className="doc_title">
                            <h3>거래명세표</h3>
                          </div>
                          <div className="franchise_info">
                            <span>
                              {franchiseName} <span>貴中</span>
                            </span>
                            <span>납품일 : {setDate}</span>
                          </div>

                          <table className="factoryInfoTable">
                            <thead>
                              <tr>
                                <th colSpan={4}>공급자</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>상호</td>
                                <td>{factoryItemizedAccountExcel?.factory.companyName ?? "-"}</td>
                                <td>대표자</td>
                                <td>
                                  {factoryItemizedAccountExcel?.factory.representative ?? "-"}
                                </td>
                              </tr>
                              <tr>
                                <td>TEL</td>
                                <td>{factoryItemizedAccountExcel?.factory.companyPhone ?? "-"}</td>
                                <td>등록번호</td>
                                <td>
                                  {factoryItemizedAccountExcel?.factory.registrationNumber ?? "-"}
                                </td>
                              </tr>
                              <tr>
                                <td>FAX</td>
                                <td>
                                  {factoryItemizedAccountExcel?.factory.fax &&
                                  factoryItemizedAccountExcel?.factory.fax !== ""
                                    ? factoryItemizedAccountExcel?.factory.fax
                                    : "-"}
                                </td>
                                <td>주소</td>
                                <td>
                                  {factoryItemizedAccountExcel?.factory.companyAddress.street ??
                                    "-"}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          {/* <div className="factory_info">
                            <div className="factory_info_name">공급자</div>
                            <div className="table_row">
                              <span>상호</span>
                              <span>{factoryItemizedAccountExcel?.factory.companyName ?? "-"}</span>
                              <span>대표자</span>
                              <span>
                                {factoryItemizedAccountExcel?.factory.representative ?? "-"}
                              </span>
                            </div>
                            <div className="table_row">
                              <span>TEL</span>
                              <span>
                                {factoryItemizedAccountExcel?.factory.companyPhone ?? "-"}
                              </span>
                              <span>등록번호</span>
                              <span>
                                {factoryItemizedAccountExcel?.factory.registrationNumber ?? "-"}
                              </span>
                            </div>
                            <div className="table_row">
                              <span>FAX</span>
                              <span>
                                {factoryItemizedAccountExcel?.factory.fax &&
                                factoryItemizedAccountExcel?.factory.fax !== ""
                                  ? factoryItemizedAccountExcel?.factory.fax
                                  : "-"}
                              </span>
                              <span>주소</span>
                              <span>
                                {factoryItemizedAccountExcel?.factory.companyAddress.street ?? "-"}
                              </span>
                            </div>
                          </div> */}
                          <div className="stampWrap">
                            {chartType !== "specifi1" && chartType !== "specifi2" && (
                              <div className="taxAmount">
                                <div>
                                  {taxIncludedCheck && (
                                    <>
                                      <span>
                                        공급가액:{" "}
                                        {NumberUtils.numberToComma(
                                          factoryItemizedAccountExcel?.totalPrice ?? 0
                                        )}{" "}
                                        원
                                      </span>
                                      <span>/</span>
                                      <span>
                                        세액:{" "}
                                        {NumberUtils.numberToComma(
                                          factoryItemizedAccountExcel?.totalPrice
                                            ? Math.ceil(
                                                factoryItemizedAccountExcel?.totalPrice * 0.1
                                              )
                                            : 0
                                        )}{" "}
                                        원
                                      </span>
                                    </>
                                  )}
                                </div>
                                <div>
                                  {taxIncludedCheck ? "합계금액" : "공급가액"}:{" "}
                                  {taxIncludedCheck ? (
                                    <span>
                                      {NumberUtils.numberToComma(
                                        factoryItemizedAccountExcel?.totalPrice
                                          ? factoryItemizedAccountExcel?.totalPrice +
                                              Math.ceil(
                                                factoryItemizedAccountExcel?.totalPrice * 0.1
                                              )
                                          : 0
                                      )}{" "}
                                      원
                                    </span>
                                  ) : (
                                    <span>
                                      {NumberUtils.numberToComma(
                                        factoryItemizedAccountExcel?.totalPrice ?? 0
                                      )}{" "}
                                      원
                                    </span>
                                  )}
                                </div>
                              </div>
                            )}
                            <div className="stampBox">
                              <div>
                                <span>인수자</span>
                              </div>
                              <div>
                                <span>인계자</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <table className="itemizedDataTable">
                      <thead>
                        <tr>
                          <th>NO.</th>
                          <th>대분류</th>
                          <th>품목명</th>
                          {chartType !== "specifi3" && <th>중량</th>}
                          <th>납품 수량</th>
                          <th>납품 중량</th>
                          <th>비고</th>
                          {chartType === "specifi3" && <th>세탁비</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {items.map((data: Subcategory, index: number) => (
                          <>
                            {data.number ? (
                              <tr>
                                <td className="textAlignCenter">{data.number}</td>
                                <td className="textAlignCenter">{data.largeCategory}</td>
                                <td className="textAlignCenter">{data.name}</td>
                                {chartType !== "specifi3" && (
                                  <td>{NumberUtils.numberToComma(data.weight ?? 0)} Kg</td>
                                )}
                                <td>{NumberUtils.numberToComma(data.amount ?? 0)} 개</td>
                                <td>{NumberUtils.numberToComma(data.deliveryWeight ?? 0)} Kg</td>
                                <td>{NumberUtils.numberToComma(data.reLaundry ?? 0)} 개</td>
                                {chartType === "specifi3" && (
                                  <td>{NumberUtils.numberToComma(data.price ?? 0)} 원</td>
                                )}
                              </tr>
                            ) : (
                              <tr className="semiTotalLine">
                                <td colSpan={2} className="textAlignCenter">
                                  {data.largeCategoryTitle}
                                </td>
                                <td className="textAlignCenter">소계</td>
                                {chartType !== "specifi3" && (
                                  <td>{NumberUtils.numberToComma(data.weight ?? 0)} Kg</td>
                                )}
                                <td>{NumberUtils.numberToComma(data.amount ?? 0)} 개</td>
                                <td>{NumberUtils.numberToComma(data.deliveryWeight ?? 0)} Kg</td>
                                <td>{NumberUtils.numberToComma(data.reLaundry ?? 0)} 개</td>
                                {chartType === "specifi3" && (
                                  <td>{NumberUtils.numberToComma(data.price ?? 0)} 원</td>
                                )}
                              </tr>
                            )}
                          </>
                        ))}
                        {index + 1 === totalLength && (
                          <>
                            <tr className="anouder">
                              <td colSpan={3} className="textAlignCenter">
                                총 합계
                              </td>
                              {chartType !== "specifi3" && <td></td>}
                              <td>
                                {NumberUtils.numberToComma(
                                  factoryItemizedAccountExcel?.totalCount ?? 0
                                )}{" "}
                                개
                              </td>
                              <td>
                                {NumberUtils.numberToComma(
                                  factoryItemizedAccountExcel?.totalDeliveryWeight ?? 0
                                )}
                                Kg
                              </td>
                              <td>
                                {NumberUtils.numberToComma(
                                  factoryItemizedAccountExcel?.totalReLaundry ?? 0
                                )}{" "}
                                개
                              </td>
                              {chartType === "specifi3" && (
                                <td>
                                  {NumberUtils.numberToComma(
                                    factoryItemizedAccountExcel?.totalPrice ?? 0
                                  )}{" "}
                                  원
                                </td>
                              )}
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>

                    {/* <div className="itemized_data">
                      {index === 0 && (
                        <>
                          <div className="table_head">
                            <span className="table_span">NO.</span>
                            <span className="table_span">대분류</span>
                            <span className="table_span">품목명</span>
                            {chartType !== "specifi3" && <span className="table_span">중량</span>}
                            <span className="table_span">납품 수량</span>
                            <span className="table_span">납품 중량</span>
                            <span className="table_span">비고</span>
                            {chartType === "specifi3" && <span className="table_span">세탁비</span>}
                          </div>
                        </>
                      )}

                      <div className="table_body">
                        {items.map((data: Subcategory, index: number) => (
                          <>
                            {data.number ? (
                              <div key={data.number}>
                                <span className="table_span">{data.number}</span>
                                <span className="table_span">{data.largeCategory}</span>
                                <span className="table_span">{data.name}</span>
                                {chartType !== "specifi3" && (
                                  <span className="table_span sorting_right">
                                    {NumberUtils.numberToComma(data.weight ?? 0)} Kg
                                  </span>
                                )}
                                <span className="table_span sorting_right">
                                  {NumberUtils.numberToComma(data.amount ?? 0)} 개
                                </span>
                                <span className="table_span sorting_right">
                                  {NumberUtils.numberToComma(data.deliveryWeight ?? 0)} Kg
                                </span>
                                <span className="table_span sorting_right">
                                  {NumberUtils.numberToComma(data.reLaundry ?? 0)} 개
                                </span>
                                {chartType === "specifi3" && (
                                  <span className="table_span sorting_right">
                                    {NumberUtils.numberToComma(data.price ?? 0)} 원
                                  </span>
                                )}
                              </div>
                            ) : (
                              <div key={data.number} className="semi_total_line">
                                <span className="table_span semi_total">
                                  {data.largeCategoryTitle}
                                </span>
                                <span className="table_span">소계</span>
                                {chartType !== "specifi3" && (
                                  <span className="table_span sorting_right"></span>
                                )}
                                <span className="table_span sorting_right">
                                  {NumberUtils.numberToComma(data.amount ?? 0)} 개
                                </span>
                                <span className="table_span sorting_right">
                                  {NumberUtils.numberToComma(data.deliveryWeight ?? 0)} Kg
                                </span>
                                <span className="table_span sorting_right">
                                  {NumberUtils.numberToComma(data.reLaundry ?? 0)} 개
                                </span>
                                {chartType === "specifi3" && (
                                  <span className="table_span sorting_right">
                                    {NumberUtils.numberToComma(data.price ?? 0)} 원
                                  </span>
                                )}
                              </div>
                            )}
                          </>
                        ))}

                        {index + 1 === totalLength && (
                          <div className="anouder">
                            <span className="table_span all_total">총 합계</span>
                            {chartType !== "specifi3" && (
                              <span className="table_span sorting_right"></span>
                            )}
                            <span className="table_span sorting_right">
                              {NumberUtils.numberToComma(
                                factoryItemizedAccountExcel?.totalCount ?? 0
                              )}{" "}
                              개
                            </span>
                            <span className="table_span sorting_right">
                              {NumberUtils.numberToComma(
                                factoryItemizedAccountExcel?.totalDeliveryWeight ?? 0
                              )}
                              Kg
                            </span>
                            <span className="table_span sorting_right">
                              {NumberUtils.numberToComma(
                                factoryItemizedAccountExcel?.totalReLaundry ?? 0
                              )}{" "}
                              개
                            </span>
                            {chartType === "specifi3" && (
                              <span className="table_span sorting_right">
                                {NumberUtils.numberToComma(
                                  factoryItemizedAccountExcel?.totalPrice ?? 0
                                )}{" "}
                                원
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    </div> */}
                    <div className="pageNumber">- {index + 1} -</div>
                  </>
                </div>
              ))}
            {(chartType === "aggregation1" || chartType === "aggregation2") && (
              <div className="pdfWrapper">
                <LaundryTable
                  taxIncludedCheck={taxIncludedCheck}
                  contractType={contractType!}
                  contractPrice={contractPrice!}
                  startDate={
                    chartType !== "aggregation1"
                      ? searchDate.startDate
                      : `${monthData}-${contractDate.startDate}`
                  }
                  endDate={
                    chartType !== "aggregation1"
                      ? searchDate.endDate
                      : searchCheck
                      ? moment(new Date(`${monthData}-${contractDate.endDate}`).getTime())
                          .add("1", "M")
                          .format("YYYY-MM-DD")
                      : `${monthData}-${contractDate.endDate}`
                  }
                  dataList={laundryTableData?.list ?? []}
                  chartType={chartType}
                  franchiseName={franchiseName}
                  factory={factoryItemizedAccountExcel?.factory}
                  factoryProfileDetail={factoryProfileDetail}
                />
              </div>
            )}
            {chartType === "aggregation3" && (
              <div className="pdfWrapper">
                <LaundryTableYear
                  contractType={contractType!}
                  contractPrice={contractPrice!}
                  startDate={searchMonth.startMonth}
                  endDate={searchMonth.endMonth}
                  dataList={laundryTableYearData ?? []}
                  chartType={chartType}
                  franchiseName={franchiseName}
                  factory={factoryItemizedAccountExcel?.factory}
                  factoryProfileDetail={factoryProfileDetail}
                />
              </div>
            )}
            {testCheck && (
              <div className="pdfWrapper2">
                <div className="itemized_top">
                  <div className="logo">
                    <img src={logo} alt="" />
                  </div>
                  <div className="doc_title">
                    <h3>거래명세표</h3>
                  </div>
                  <div className="franchise_info">
                    <span>
                      {franchiseName} <span>貴中</span>
                    </span>
                    <span>납품일 : {setDate}</span>
                  </div>

                  <table className="factoryInfoTable">
                    <thead>
                      <tr>
                        <th colSpan={4}>공급자</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>상호</td>
                        <td>{factoryItemizedAccountExcel?.factory.companyName ?? "-"}</td>
                        <td>대표자</td>
                        <td>{factoryItemizedAccountExcel?.factory.representative ?? "-"}</td>
                      </tr>
                      <tr>
                        <td>TEL</td>
                        <td>{factoryItemizedAccountExcel?.factory.companyPhone ?? "-"}</td>
                        <td>등록번호</td>
                        <td>{factoryItemizedAccountExcel?.factory.registrationNumber ?? "-"}</td>
                      </tr>
                      <tr>
                        <td>FAX</td>
                        <td>
                          {factoryItemizedAccountExcel?.factory.fax &&
                          factoryItemizedAccountExcel?.factory.fax !== ""
                            ? factoryItemizedAccountExcel?.factory.fax
                            : "-"}
                        </td>
                        <td>주소</td>
                        <td>{factoryItemizedAccountExcel?.factory.companyAddress.street ?? "-"}</td>
                      </tr>
                    </tbody>
                  </table>
                  {/* <div className="factory_info">
                    <div className="factory_info_name">공급자</div>
                    <div className="table_row">
                      <span>상호</span>
                      <span>{factoryItemizedAccountExcel?.factory.companyName ?? "-"}</span>
                      <span>대표자</span>
                      <span>{factoryItemizedAccountExcel?.factory.representative ?? "-"}</span>
                    </div>
                    <div className="table_row">
                      <span>TEL</span>
                      <span>{factoryItemizedAccountExcel?.factory.companyPhone ?? "-"}</span>
                      <span>등록번호</span>
                      <span>{factoryItemizedAccountExcel?.factory.registrationNumber ?? "-"}</span>
                    </div>
                    <div className="table_row">
                      <span>FAX</span>
                      <span>
                        {factoryItemizedAccountExcel?.factory.fax &&
                        factoryItemizedAccountExcel?.factory.fax !== ""
                          ? factoryItemizedAccountExcel?.factory.fax
                          : "-"}
                      </span>
                      <span>주소</span>
                      <span>
                        {factoryItemizedAccountExcel?.factory.companyAddress.street ?? "-"}
                      </span>
                    </div>
                  </div> */}
                  <div className="stampWrap">
                    <div className="taxAmount">
                      {chartType !== "specifi1" && chartType !== "specifi2" && (
                        <div className="taxAmount">
                          <div>
                            {taxIncludedCheck && (
                              <>
                                <span>
                                  공급가액:{" "}
                                  {NumberUtils.numberToComma(
                                    factoryItemizedAccountExcel?.totalPrice ?? 0
                                  )}{" "}
                                  원
                                </span>
                                <span>/</span>
                                <span>
                                  세액:{" "}
                                  {NumberUtils.numberToComma(
                                    factoryItemizedAccountExcel?.totalPrice
                                      ? Math.ceil(factoryItemizedAccountExcel?.totalPrice * 0.1)
                                      : 0
                                  )}{" "}
                                  원
                                </span>
                              </>
                            )}
                          </div>
                          <div>
                            {taxIncludedCheck ? "합계금액" : "공급가액"}:{" "}
                            {taxIncludedCheck ? (
                              <span>
                                {NumberUtils.numberToComma(
                                  factoryItemizedAccountExcel?.totalPrice
                                    ? factoryItemizedAccountExcel?.totalPrice +
                                        Math.ceil(factoryItemizedAccountExcel?.totalPrice * 0.1)
                                    : 0
                                )}{" "}
                                원
                              </span>
                            ) : (
                              <span>
                                {NumberUtils.numberToComma(
                                  factoryItemizedAccountExcel?.totalPrice ?? 0
                                )}{" "}
                                원
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                      {/* <div>
                        <span>
                          공급가액:{" "}
                          {NumberUtils.numberToComma(factoryItemizedAccountExcel?.totalPrice ?? 0)}{" "}
                          원
                        </span>
                        <span>/</span>
                        <span>
                          세액:{" "}
                          {NumberUtils.numberToComma(
                            factoryItemizedAccountExcel?.totalPrice
                              ? Math.ceil(factoryItemizedAccountExcel?.totalPrice * 0.1)
                              : 0
                          )}{" "}
                          원
                        </span>
                      </div>
                      <div>
                        합계금액:{" "}
                        <span>
                          {NumberUtils.numberToComma(
                            factoryItemizedAccountExcel?.totalPrice
                              ? factoryItemizedAccountExcel?.totalPrice +
                                  Math.ceil(factoryItemizedAccountExcel?.totalPrice * 0.1)
                              : 0
                          )}{" "}
                          원
                        </span>
                      </div> */}
                    </div>
                    <div className="stampBox">
                      <div>
                        <span>인수자</span>
                      </div>
                      <div>
                        <span>인계자</span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="itemized_data">
                  <div className="table_head">
                    <span className="table_span">NO.</span>
                    <span className="table_span">대분류</span>
                    <span className="table_span">품목명</span>
                    {chartType !== "specifi3" && <span className="table_span">중량</span>}
                    <span className="table_span">납품 수량</span>
                    <span className="table_span">납품 중량</span>
                    <span className="table_span">비고</span>
                    {chartType === "specifi3" && <span className="table_span">세탁비</span>}
                  </div>

                  <div className="table_body">
                    {itemizedImageArray.map((data: Subcategory, index: number) => (
                      <>
                        {data.number ? (
                          <div key={index}>
                            <span className="table_span">{data.number}</span>
                            <span className="table_span">{data.largeCategory}</span>
                            <span className="table_span">{data.name}</span>
                            {chartType !== "specifi3" && (
                              <span className="table_span sorting_right">
                                {NumberUtils.numberToComma(data.weight ?? 0)} Kg
                              </span>
                            )}
                            <span className="table_span sorting_right">
                              {NumberUtils.numberToComma(data.amount ?? 0)} 개
                            </span>
                            <span className="table_span sorting_right">
                              {NumberUtils.numberToComma(data.deliveryWeight ?? 0)} Kg
                            </span>
                            <span className="table_span sorting_right">
                              {NumberUtils.numberToComma(data.reLaundry ?? 0)} 개
                            </span>
                            {chartType === "specifi3" && (
                              <span className="table_span sorting_right">
                                {NumberUtils.numberToComma(data.price ?? 0)} 원
                              </span>
                            )}
                          </div>
                        ) : (
                          <div key={index} className="semi_total_line">
                            <span className="table_span semi_total">{data.largeCategoryTitle}</span>
                            <span className="table_span">소계</span>
                            {chartType !== "specifi3" && (
                              <span className="table_span sorting_right"></span>
                            )}
                            <span className="table_span sorting_right">
                              {NumberUtils.numberToComma(data.amount ?? 0)} 개
                            </span>
                            <span className="table_span sorting_right">
                              {NumberUtils.numberToComma(data.deliveryWeight ?? 0)} Kg
                            </span>
                            <span className="table_span sorting_right">
                              {NumberUtils.numberToComma(data.reLaundry ?? 0)} 개
                            </span>
                            {chartType === "specifi3" && (
                              <span className="table_span sorting_right">
                                {NumberUtils.numberToComma(data.price ?? 0)} 원
                              </span>
                            )}
                          </div>
                        )}
                      </>
                    ))}

                    <div className="anouder">
                      <span className="table_span all_total">총 합계</span>
                      {chartType !== "specifi3" && (
                        <span className="table_span sorting_right"></span>
                      )}
                      <span className="table_span sorting_right">
                        {NumberUtils.numberToComma(factoryItemizedAccountExcel?.totalCount ?? 0)} 개
                      </span>
                      <span className="table_span sorting_right">
                        {NumberUtils.numberToComma(
                          factoryItemizedAccountExcel?.totalDeliveryWeight ?? 0
                        )}
                        Kg
                      </span>
                      <span className="table_span sorting_right">
                        {NumberUtils.numberToComma(
                          factoryItemizedAccountExcel?.totalReLaundry ?? 0
                        )}{" "}
                        개
                      </span>
                      {chartType === "specifi3" && (
                        <span className="table_span sorting_right">
                          {NumberUtils.numberToComma(factoryItemizedAccountExcel?.totalPrice ?? 0)}{" "}
                          원
                        </span>
                      )}
                    </div>
                  </div>
                </div> */}

                <table className="itemizedDataTable">
                  <thead>
                    <tr>
                      <th>NO.</th>
                      <th>대분류</th>
                      <th>품목명</th>
                      {chartType !== "specifi3" && <th>중량</th>}
                      <th>납품 수량</th>
                      <th>납품 중량</th>
                      <th>비고</th>
                      {chartType === "specifi3" && <th>세탁비</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {itemizedImageArray.map((data: Subcategory, index: number) => (
                      <>
                        {data.number ? (
                          <tr>
                            <td className="textAlignCenter">{data.number}</td>
                            <td className="textAlignCenter">{data.largeCategory}</td>
                            <td className="textAlignCenter">{data.name}</td>
                            {chartType !== "specifi3" && (
                              <td>{NumberUtils.numberToComma(data.weight ?? 0)} Kg</td>
                            )}
                            <td>{NumberUtils.numberToComma(data.amount ?? 0)} 개</td>
                            <td>{NumberUtils.numberToComma(data.deliveryWeight ?? 0)} Kg</td>
                            <td>{NumberUtils.numberToComma(data.reLaundry ?? 0)} 개</td>
                            {chartType === "specifi3" && (
                              <td>{NumberUtils.numberToComma(data.price ?? 0)} 원</td>
                            )}
                          </tr>
                        ) : (
                          <tr className="semiTotalLine">
                            <td colSpan={2} className="textAlignCenter">
                              {data.largeCategoryTitle}
                            </td>
                            <td className="textAlignCenter">소계</td>
                            {chartType !== "specifi3" && (
                              <td>{NumberUtils.numberToComma(data.weight ?? 0)} Kg</td>
                            )}
                            <td>{NumberUtils.numberToComma(data.amount ?? 0)} 개</td>
                            <td>{NumberUtils.numberToComma(data.deliveryWeight ?? 0)} Kg</td>
                            <td>{NumberUtils.numberToComma(data.reLaundry ?? 0)} 개</td>
                            {chartType === "specifi3" && (
                              <td>{NumberUtils.numberToComma(data.price ?? 0)} 원</td>
                            )}
                          </tr>
                        )}
                      </>
                    ))}

                    <tr className="anouder">
                      <td colSpan={3} className="textAlignCenter">
                        총 합계
                      </td>
                      {chartType !== "specifi3" && <td></td>}
                      <td>
                        {NumberUtils.numberToComma(factoryItemizedAccountExcel?.totalCount ?? 0)} 개
                      </td>
                      <td>
                        {NumberUtils.numberToComma(
                          factoryItemizedAccountExcel?.totalDeliveryWeight ?? 0
                        )}
                        Kg
                      </td>
                      <td>
                        {NumberUtils.numberToComma(
                          factoryItemizedAccountExcel?.totalReLaundry ?? 0
                        )}{" "}
                        개
                      </td>
                      {chartType === "specifi3" && (
                        <td>
                          {NumberUtils.numberToComma(factoryItemizedAccountExcel?.totalPrice ?? 0)}{" "}
                          원
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>

          <div className="pdf_footer"></div>
        </div>
      </div>
    </FactoryItemizedAccountBlock>
  );
};

export default FactoryItemizedAccount;

const FactoryItemizedAccountBlock = styled.div<FactoryItemizedAccountBlockType>`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  height: ${(props) =>
    props.selectChartType === "aggregation1" ||
    props.selectChartType === "aggregation2" ||
    props.selectChartType === "aggregation3"
      ? `${props.layoutHeight - 85}px`
      : "100%"};
  min-height: ${(props) => `${props.layoutHeight - 85}px`};
  /* max-height: ${(props) =>
    props.selectChartType === "aggregation1" || props.selectChartType === "aggregation2"
      ? "1200px"
      : "none"}; */

  overflow-x: ${(props) =>
    props.selectChartType === "aggregation1" ||
    props.selectChartType === "aggregation2" ||
    props.selectChartType === "aggregation3"
      ? "scroll"
      : "none"};
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);

  & > div {
    width: 100%;

    /* height: 100%; */
    /* display: flex; */
    & > .headers {
      height: 70px;
      justify-content: flex-start;
      display: flex;
      align-items: center;
      border-bottom: 1px solid black;
      position: relative;

      & > div {
        width: auto;
        height: 20px;
        display: flex;
        justify-content: space-between;
        margin-left: 20px;
        padding-top: 5px;

        & > span {
          color: #dee2e6;
        }
        & > .selectClick {
          cursor: pointer;
          font-size: 14px;
        }
        & > .on {
          color: ${Palette.primary};
          font-weight: bold;
        }
        & > .selectClick:hover {
          color: ${Palette.primary};
          font-weight: bold;
        }
        & > span:nth-child(2) {
          font-size: 10px;
          margin: 0 10px;
          color: #333;
        }
      }
    }
    .tableSelect {
      height: 70px;
      display: flex;
      padding-top: 12px;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      z-index: 2;

      & > label {
        font-size: 14px;
        font-weight: 600;
        color: #333;
        margin-right: 1rem;
        display: flex;
        align-items: center;
        & > input {
          margin-right: 6px;
        }
      }
      & > span {
        font-size: 14px;
        line-height: 37px;
        margin-right: 5px;
        color: #999;
      }
      & > div {
        color: #999;
        height: 40px;
        /* background-color: red; */
        overflow: hidden;
        & > select {
          border: 1px solid #dee2e6;
          box-sizing: border-box;
          transition: ease-in-out;
          transition-duration: 0.1s;
        }
        & > select:hover {
          border-bottom: 2px solid ${Palette.primary};
        }
      }
    }
  }
  .tableWrapper {
    /* min-width: 51rem; */
    width: 70rem;
    height: auto;
    margin: 0 auto;
    margin-top: 20px;
    position: relative;

    & > .pdf_header {
      position: absolute;
      width: 100%;
      height: 300px;
      right: -5px;
      width: 218px;
      z-index: 1;

      & > div {
        padding: 0;
        margin: 0;
        position: absolute;
        right: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > div {
          width: auto;

          & > div {
            width: 100px;
            margin-right: 5px;
            position: relative;
            z-index: 2;
          }
        }
        & > div:nth-child(2) {
          display: flex;

          align-items: center;
          margin-top: 10px;
          & > span {
            font-size: 14px;
            margin-right: 5px;
          }
        }
      }
    }
    & > div {
      max-width: ${(props) =>
        props.selectChartType === "aggregation1" ||
        props.selectChartType === "aggregation2" ||
        props.selectChartType === "aggregation3"
          ? "auto"
          : "999px"};
      /* width: 85%; */
      height: 100%;
      /* max-height: 500px; */
      margin: 0 auto;
      position: relative;
      & > .search_announcement {
        width: 1000px;
        padding: 1rem;
        text-align: center;
        font-size: 1.7rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: ${Palette.gray};
      }
      & > .pdf_footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      & > .pdf_footer {
        height: 6rem;
      }

      & > .pdf_place {
        /* height: auto; */
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        /* border: 1px solid black; */
        /* box-shadow: 0px 4px 9px 3px rgba(0, 0, 0, 0.25); */
        & > .pdfWrapper:nth-child(1) {
          margin-top: 6rem;
        }
        & > .pdfWrapper {
          /* border: 1px solid #333 !important; */
          display: none;
        }
        & > .pdfWrapper,
        & > .pdfWrapper2 {
          /* min-width: 1000px; */
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          width: ${(props) =>
            props.selectChartType === "aggregation1" ||
            props.selectChartType === "aggregation2" ||
            props.selectChartType === "aggregation3"
              ? "1550px"
              : "100%"};
          padding: ${(props) =>
            props.selectChartType === "aggregation1" ||
            props.selectChartType === "aggregation2" ||
            props.selectChartType === "aggregation3"
              ? "0"
              : "5rem"};
          /* border: 1px solid black; */
          box-sizing: border-box;
          /* margin: 0 auto; */

          height: auto;
          min-height: ${(props) =>
            props.selectChartType === "aggregation1" ||
            props.selectChartType === "aggregation2" ||
            props.selectChartType === "aggregation3"
              ? "auto"
              : "1290px"};
          /* border: 1px solid red; */
          /* height: auto;
          min-height: 90rem; */

          position: relative;
          /* border-bottom: 1px solid #dee2e6; */

          & > .itemized_top {
            width: 100%;
            height: auto;
            /* background-color: skyblue; */
            position: relative;

            & > .logo {
              position: absolute;
              width: 180px;
              height: auto;
              top: -30px;
              left: -30px;
              z-index: 10;
              & > img {
                width: 100%;
                height: auto;
              }
            }

            & > .doc_title {
              width: 100%;
              text-align: center;
              font-size: 20px;
              & > h3 {
                margin: 0;
                margin-bottom: 20px;
              }
            }
            & > .franchise_info {
              width: 100%;
              & > span {
                display: block;
              }
              & > span:nth-child(1) {
                font-size: 20px;
                font-weight: 900;
                margin-bottom: 5px;
                & > span {
                  font-weight: 400;
                }
              }
              & > span:nth-child(2) {
                margin-bottom: 12px;
              }
            }
            & > .factory_info {
              width: 100%;
              height: auto;
              border-bottom: 1px solid black;
              border-right: 1px solid black;

              & > .factory_info_name {
                width: 100%;
                height: 26px;
                background-color: #ddebf7;
                border-top: 1px solid black;
                border-left: 1px solid black;
                text-align: center;
                line-height: 30px;
                font-weight: 900;
                font-size: 14px;
              }
              & > .table_row {
                width: 100%;

                & > span {
                  display: inline-block;
                  border-top: 1px solid black;
                  border-left: 1px solid black;
                  height: 25px;
                  line-height: 25px;
                  padding-left: 10px;
                  font-size: 12px;
                }
                & > span:nth-child(1) {
                  width: 14.3%;
                  text-align: center;
                }
                & > span:nth-child(2) {
                  width: 28.5%;
                }
                & > span:nth-child(3) {
                  width: 14.3%;
                  text-align: center;
                }
                & > span:nth-child(4) {
                  width: 42.9%;
                }
              }
            }

            & > .factoryInfoTable {
              width: 100%;
              border-collapse: collapse;
              td,
              th {
                border: 1px solid black;
                padding: 4px;
                font-size: 13px;
              }

              th {
                background-color: #ddebf7;
              }

              & > tbody {
                & > tr {
                  & > td:nth-child(1),
                  td:nth-child(3) {
                    text-align: center;
                    width: 70px;
                  }
                  & > td:nth-child(2),
                  td:nth-child(4) {
                    padding-left: 6px;
                  }
                }
              }
            }

            & > .stampWrap {
              position: absolute;
              top: 0;
              right: 0;
              width: auto;
              height: auto;
              display: flex;
              justify-content: flex-end;
              align-items: flex-end;
              & > .taxAmount {
                width: auto;
                height: auto;
                margin-right: 16px;
                min-width: 174px;

                & > div:nth-child(1) {
                  font-size: 11px;
                  margin-bottom: 5px;
                  text-align: right;
                  & > span:nth-child(2) {
                    margin: 0 2px;
                  }
                }
                & > div:nth-child(2) {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  & > span {
                    text-align: right;
                    font-weight: bold;
                  }
                }
              }
              & > .stampBox {
                width: 200px;
                height: 96px;
                & > div {
                  display: inline-block;
                  width: calc(100% / 2);
                  height: 100%;
                  border: 1px solid #333;
                  box-sizing: border-box;
                  &:nth-child(1) {
                    border-right: 0;
                  }
                  & > span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 20px;
                    font-size: 14px;
                    border-bottom: 1px solid #333;
                  }
                }
              }
            }
          }

          & > .itemizedDataTable {
            width: 100%;
            border-collapse: collapse;
            margin-top: 1rem;

            tr {
              border: 0;
            }
            th,
            td {
              font-size: 13px;
              border: 1px solid black;
              padding: 4px;
              border-spacing: 0px;
            }
            th {
              background-color: #ddebf7;
              &:nth-child(1),
              &:nth-child(4),
              &:nth-child(6) {
                width: 70px;
              }
              &:nth-child(5) {
                width: 80px;
              }
              &:nth-child(7) {
                width: 120px;
              }
            }
            td {
              text-align: right;
            }
            .textAlignCenter {
              text-align: center;
            }
            .semiTotalLine {
              background-color: #ededed;
              font-weight: bold;
            }
            .anouder {
              background-color: #fff2cc;
              font-weight: bold;
            }
          }

          & > .itemized_data {
            /* flex: 1; */
            margin-top: 1rem;
            border-right: 1px solid black;
            border-bottom: 1px solid black;
            & > .table_head {
              width: 100%;
              height: 25px;
              background-color: #ddebf7;
              display: flex;
              & > span {
                font-weight: 900;
              }
            }
            & > .table_body {
              width: 100%;
              height: auto;
              & > div {
                display: flex;
                height: 25px;
                & > .semi_total {
                  width: 30%;
                }
              }
              & > .semi_total_line {
                background-color: #ededed;
                font-weight: bold;
              }
              & > .anouder {
                width: 100%;
                background-color: #fff2cc;
                font-weight: bold;

                & > .all_total {
                  width: 45%;
                }
              }
            }
          }
          .table_span {
            width: 15%;
            display: inline-block;
            text-align: center;
            line-height: 25px;
            border-top: 1px solid black;
            border-left: 1px solid black;
            overflow: hidden;
            font-size: 12px;
          }
          .sorting_right {
            text-align: right;
            padding-right: 10px;
          }

          & > .pageNumber {
            position: absolute;
            left: 0;
            bottom: 10px;
            width: 100%;
            text-align: center;
            /* background-color: red; */
          }
        }

        & > .pdfWrapper:last-child {
          border-bottom: 0;
        }
        & > .pdfWrapper2 {
          height: auto;
          position: absolute;
          /* min-height: 90rem; */
          top: 0;
          left: 0;
          z-index: -1;
        }
      }
    }
  }
  .infoBox {
    width: auto !important;
    height: 16px;
    position: absolute;
    top: 15px;
    z-index: 2;
    right: 0px;
    & > .info {
      width: 90px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: default;
      & > span:nth-child(1) {
        font-size: 12px;
        color: ${Palette.primary};
      }
      & > span:nth-child(2) {
        width: 16px;
        height: 16px;
        & > img {
          width: 100%;
          height: 100%;
        }
      }
    }
    & > .infoGuide {
      position: absolute;
      width: 320px;
      height: auto;
      background-color: #ffffcc;
      border: 1px solid black;
      border-radius: 7px;
      font-size: 12px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 10px;
      top: 20px;
      right: 0;
      display: none;
    }
    & > .infoCenter {
      width: 240px;
      line-height: 30px;
    }
  }
  .infoBox:hover {
    & > .infoGuide {
      display: block;
    }
  }
`;

const DatePickerBlock = styled(DatePicker)`
  width: 140px;
  height: 100%;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  transition: ease-in-out;
  transition-duration: 0.1s;
  margin-right: 5px;
  cursor: default;
  position: relative;
  z-index: 1;
  &:hover {
    border-bottom: 2px solid ${Palette.primary};
  }
`;
