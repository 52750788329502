import LoginFormContainer from "containers/login/LoginFormContainer";
import React from "react";
import styled from "styled-components";

interface LoginPageProps {}

const LoginPageBlock = styled.div``;

const LoginPage: React.FC<LoginPageProps> = () => {
  return (
    <LoginPageBlock>
      <LoginFormContainer />
    </LoginPageBlock>
  );
};

export default LoginPage;
