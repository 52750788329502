import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
@import url(http://fonts.googleapis.com/earlyaccess/nanumgothic.css);

  body {
    min-width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    color: #333;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
    /* overflow: hidden; */
  }

  * {
    box-sizing: inherit;
    outline: none;
    list-style: none;
    text-decoration: none;
    font-family: inherit;
  }

  input {
    padding: .6rem 1rem;
    border-radius: 5px;
    border: 1px solid #666;
  }
  select {
    padding: .55rem 1rem;
    border-radius: 5px;
    border: 1px solid #666;
  }
`;

export default GlobalStyle;
