import { useEffect, useRef, RefObject } from "react";

export function useOnClickOutside(
  ref: RefObject<HTMLElement | undefined>,
  callback: (event: Event) => void
) {
  const callbackRef = useRef<Function>();
  callbackRef.current = callback;

  useEffect(() => {
    const listener = (event: Event) => {
      if (
        !ref.current ||
        ref.current.contains(event.target as Node) ||
        typeof callbackRef.current !== "function"
      ) {
        return;
      }
      callback(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, callback]);
}
