import AdminPaymentStatus from "components/admin/AdminPaymentStatus";
import { IRootState } from "modules";
import {
  getAdminLaundryTableAction,
  getAdminPaymentBrandListDataAction,
  getAdminPaymentDetailAction,
  getAdminPaymentDetailBrandDataAction,
  getAdminPaymentDetailFactoryDataAction,
  getAdminPaymentFactoryListDataAction,
  getAdminPaymentListAction,
  getAdminPaymentListDataAction,
} from "modules/admin/admin";
import { AdminGetList } from "modules/admin/types";
import { getSpecificationsFranchiseListAction } from "modules/user/user";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

interface AdminPaymentContainerProps {
  match: any;
}

const AdminPaymentContainer: React.FC<AdminPaymentContainerProps> = ({
  match,
}) => {
  const dispatch = useDispatch();

  const adminPaymentList = useSelector(
    (state: IRootState) => state.admin.adminPaymentList
  );
  const adminPaymentDetail = useSelector(
    (state: IRootState) => state.admin.adminPaymentDetail
  );
  const laundryTable = useSelector(
    (state: IRootState) => state.admin.adminLaundryTableData
  );
  const type = useSelector((state: IRootState) => state.user.profile?.type);
  const [franchiseId, setFranchiseId] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [searchData, setSearchData] = useState<AdminGetList | null>();

  // useEffect(() => {
  //   setInterval(() => {
  //     dispatch(getAdminPaymentListAction(page, searchData ?? null));
  //   }, 5 * 60 * 1000);
  // }, []);

  useEffect(() => {
    dispatch(getAdminPaymentListAction(1, null));
    dispatch(getAdminPaymentFactoryListDataAction(1, null));
    dispatch(getAdminPaymentBrandListDataAction(1, null));
  }, [dispatch]);

  const paymentList = (page: number, data: AdminGetList | null) => {
    setPage(page);
    setSearchData(data);
    dispatch(getAdminPaymentListAction(page, data));
  };
  const paymentDetail = (id: number) => {
    dispatch(getAdminPaymentDetailAction(id));
  };

  const adminPaymentData = useSelector(
    (state: IRootState) => state.admin.adminPaymentData
  );

  const adminPaymentDeatilBrandData = useSelector(
    (state: IRootState) => state.admin.adminPaymentDeatilBrandData
  );
  const adminPaymentDeatilFactoryData = useSelector(
    (state: IRootState) => state.admin.adminPaymentDeatilFactoryData
  );

  const specificationsFranchiseList = useSelector(
    (state: IRootState) => state.user.specificationsFranchiseList
  );

  const adminPaymentFactoryListData = useSelector(
    (state: IRootState) => state.admin.adminPaymentFactoryData
  );
  const adminPaymentBrandListData = useSelector(
    (state: IRootState) => state.admin.adminPaymentBrandData
  );

  const getAdminLaundryTable = (id: number, data: AdminGetList | null) => {
    let startDate = data?.startDate;
    let endDate = data?.endDate;
    setFranchiseId(id);
    dispatch(getAdminLaundryTableAction(id, startDate!, endDate!));
  };

  const paymentDeatilBrand = (id: number, data: AdminGetList | null) => {
    dispatch(getAdminPaymentDetailBrandDataAction(id, data));
  };
  const paymentDeatilFactory = (id: number, data: AdminGetList | null) => {
    dispatch(getAdminPaymentDetailFactoryDataAction(id, data));
  };
  const getPaymentListData = (data: AdminGetList | null) => {
    dispatch(getAdminPaymentListDataAction(data));
    dispatch(getAdminPaymentFactoryListDataAction(1, data));
    dispatch(getAdminPaymentBrandListDataAction(1, data));
  };

  const getPaymentBrandListData = (page: number, data: AdminGetList | null) => {
    dispatch(getAdminPaymentBrandListDataAction(page, data));
  };
  const getPaymentFactoryListData = (
    page: number,
    data: AdminGetList | null
  ) => {
    dispatch(getAdminPaymentFactoryListDataAction(page, data));
  };

  useEffect(() => {
    dispatch(getAdminPaymentListDataAction(null));
    dispatch(getSpecificationsFranchiseListAction());
  }, [dispatch]);
  // console.log(match.params.id);

  return (
    <AdminPaymentStatus
      match={match}
      role={type}
      adminPaymentList={adminPaymentList}
      adminPaymentDetail={adminPaymentDetail}
      paymentList={paymentList}
      paymentDetail={paymentDetail}
      paymentDeatilBrand={paymentDeatilBrand}
      paymentDeatilFactory={paymentDeatilFactory}
      getAdminLaundryTable={getAdminLaundryTable}
      franchiseId={franchiseId}
      laundryTable={laundryTable}
      specificationsFranchiseList={specificationsFranchiseList}
      adminPaymentData={adminPaymentData}
      adminPaymentBrandListData={adminPaymentBrandListData}
      adminPaymentFactoryListData={adminPaymentFactoryListData}
      adminPaymentDeatilBrandData={adminPaymentDeatilBrandData}
      adminPaymentDeatilFactoryData={adminPaymentDeatilFactoryData}
      getPaymentListData={getPaymentListData}
      getPaymentBrandListData={getPaymentBrandListData}
      getPaymentFactoryListData={getPaymentFactoryListData}
    />
  );
};

export default AdminPaymentContainer;
