import DummyUtils from "utils/DummyUtils";
import RoleUtils from "utils/RoleUtils";
import { AxiosResponse } from "axios";
import {
  ILoginForm,
  IUserState,
  ILoginResponse,
  ProfileDetail,
  ProfileModify,
  PasswordChangeForm,
  BoardList,
  BoardPostData,
  BoardDetailData,
  BoardGetList,
  NoticeList,
  NoticeAlarm,
  LaundryCompleteAlarm,
  BoardAlarm,
  LaundryRequestAlarm,
  SpecificationsDetail,
  SpecificationsFranchiseList,
  AlarmData,
  IExcelData,
  ITransExcelData,
  BoardToUserList,
} from "./types";
import * as userApi from "api/userApi";
import * as storeApi from "api/storeApi";
import * as factoryApi from "api/factoryApi";
import * as masterApi from "api/masterApi";
import * as adminApi from "api/adminApi";
import { call, put, takeEvery } from "@redux-saga/core/effects";
import history from "utils/HistoryUtils";
import axios from "api/defaultClient";

// 액션 타입

const LAYOUT_HEIGHT = "LAYOUT_HEIGHT" as const;
const LAYOUT_HEIGHT_SUCCESS = "LAYOUT_HEIGHT_SUCCESS" as const;

const USER_LOGIN = "USER_LOGIN" as const;
const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS" as const;

const GET_LOGIN_KEEP = "GET_LOGIN_KEEP" as const;
const GET_LOGIN_KEEP_SUCCESS = "GET_LOGIN_KEEP_SUCCESS" as const;

const GET_PROFILE = "GET_PROFILE" as const;
const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS" as const;

const GET_PROFILE_DETAIL = "GET_PROFILE_DETAIL" as const;
const GET_PROFILE_DETAIL_SUCCESS = "GET_PROFILE_DETAIL_SUCCESS" as const;

const PATCH_PASSWORD_CHANGE = "PATCH_PASSWORD_CHANGE" as const;
const PATCH_PASSWORD_CHANGE_SUCCESS = "PATCH_PASSWORD_CHANGE_SUCCESS" as const;

const PATCH_PROFILE_FACTORY_EDIT = "PATCH_PROFILE_FACTORY_EDIT" as const;
const PATCH_PROFILE_FACTORY_EDIT_SUCCESS =
  "PATCH_PROFILE_FACTORY_EDIT_SUCCESS" as const;
const PATCH_PROFILE_STORE_EDIT = "PATCH_PROFILE_STORE_EDIT" as const;
const PATCH_PROFILE_STORE_EDIT_SUCCESS =
  "PATCH_PROFILE_STORE_EDIT_SUCCESS" as const;
const PATCH_PROFILE_BRAND_EDIT = "PATCH_PROFILE_BRAND_EDIT" as const;
const PATCH_PROFILE_BRAND_EDIT_SUCCESS =
  "PATCH_PROFILE_BRAND_EDIT_SUCCESS" as const;

const PATCH_PROFILE_ADMIN_EDIT = "PATCH_PROFILE_ADMIN_EDIT" as const;
const PATCH_PROFILE_ADMIN_EDIT_SUCCESS =
  "PATCH_PROFILE_ADMIN_EDIT_SUCCESS" as const;

const SET_ROLE = "SET_ROLE" as const;
const GET_LAUNDRY_LIST = "GET_LAUNDRY_LIST" as const;
const GET_STORE_LIST = "GET_STORE_LIST" as const;
const GET_INVENTORY_LIST = "GET_INVENTORY_LIST" as const;
const GET_FACTORY_LIST = "GET_FACTORY_LIST" as const;

const USER_LOGOUT = "USER_LOGOUT" as const;

//게시판
const GET_BOARD_LIST = "GET_BOARD_LIST" as const;
const GET_BOARD_LIST_SUCCESS = "GET_BOARD_LIST_SUCCESS" as const;

const GET_BOARD_DETAIL_DATA = "GET_BOARD_DETAIL_DATA" as const;
const GET_BOARD_DETAIL_DATA_SUCCESS = "GET_BOARD_DETAIL_DATA_SUCCESS" as const;

const GET_BOARD_TO_USERLIST = "GET_BOARD_TO_USERLIST" as const;
const GET_BOARD_TO_USERLIST_SUCCESS = "GET_BOARD_TO_USERLIST_SUCCESS" as const;

const POST_BOARD_DATA = "POST_BOARD_DATA" as const;
const POST_BOARD_DATA_SUCCESS = "POST_BOARD_DATA_SUCCESS" as const;

const PATCH_BOARD_DATA = "PATCH_BOARD_DATA" as const;
const PATCH_BOARD_DATA_SUCCESS = "PATCH_BOARD_DATA_SUCCESS" as const;

const DELETE_BOARD_DATA = "DELETE_BOARD_DATA" as const;
const DELETE_BOARD_DATA_SUCCESS = "DELETE_BOARD_DATA_SUCCESS" as const;

const POST_BOARD_COMMENT = "POST_BOARD_COMMENT" as const;
const POST_BOARD_COMMENT_SUCCESS = "POST_BOARD_COMMENT_SUCCESS" as const;

const PATCH_BOARD_COMMENT = "PATCH_BOARD_COMMENT" as const;
const PATCH_BOARD_COMMENT_SUCCESS = "PATCH_BOARD_COMMENT_SUCCESS" as const;

const DELETE_BOARD_COMMENT = "DELETE_BOARD_COMMENT" as const;
const DELETE_BOARD_COMMENT_SUCCESS = "DELETE_BOARD_COMMENT_SUCCESS" as const;

// 공지사항
const GET_NOTICE_LIST = "GET_NOTICE_LIST" as const;
const GET_NOTICE_LIST_SUCCESS = "GET_NOTICE_LIST_SUCCESS" as const;

const GET_NOTICE_DETAIL_DATA = "GET_NOTICE_DETAIL_DATA" as const;
const GET_NOTICE_DETAIL_DATA_SUCCESS =
  "GET_NOTICE_DETAIL_DATA_SUCCESS" as const;

const POST_NOTICE_DATA = "POST_NOTICE_DATA" as const;
const POST_NOTICE_DATA_SUCCESS = "POST_NOTICE_DATA_SUCCESS" as const;

const PATCH_NOTICE_DATA = "PATCH_NOTICE_DATA" as const;
const PATCH_NOTICE_DATA_SUCCESS = "PATCH_NOTICE_DATA_SUCCESS" as const;

const DELETE_NOTICE_DATA = "DELETE_NOTICE_DATA" as const;
const DELETE_NOTICE_DATA_SUCCESS = "DELETE_NOTICE_DATA_SUCCESS" as const;

const GET_NOTICE_ALARM = "GET_NOTICE_ALARM" as const;
const GET_NOTICE_ALARM_SUCCESS = "GET_NOTICE_ALARM_SUCCESS" as const;

const GET_LAUNDRY_COMPLETE_ALARM = "GET_LAUNDRY_COMPLETE_ALARM" as const;
const GET_LAUNDRY_COMPLETE_ALARM_SUCCESS =
  "GET_LAUNDRY_COMPLETE_ALARM_SUCCESS" as const;

const GET_BOARD_ALARM = "GET_BOARD_ALARM" as const;
const GET_BOARD_ALARM_SUCCESS = "GET_BOARD_ALARM_SUCCESS" as const;

const GET_LAUNDRY_REQUEST_ALARM = "GET_LAUNDRY_REQUEST_ALARM" as const;
const GET_LAUNDRY_REQUEST_ALARM_SUCCESS =
  "GET_LAUNDRY_REQUEST_ALARM_SUCCESS" as const;

const GET_SPECIFICATIONS_DETAIL = "GET_SPECIFICATIONS_DETAIL" as const;
const GET_SPECIFICATIONS_DETAIL_SUCCESS =
  "GET_SPECIFICATIONS_DETAIL_SUCCESS" as const;

const GET_SPECIFICATIONS_FRANCHISE_LIST =
  "GET_SPECIFICATIONS_FRANCHISE_LIST" as const;
const GET_SPECIFICATIONS_FRANCHISE_LIST_SUCCESS =
  "GET_SPECIFICATIONS_FRANCHISE_LIST_SUCCESS" as const;

const GET_STATEMENT_ALARM = "GET_STATEMENT_ALARM" as const;
const GET_STATEMENT_ALARM_SUCCESS = "GET_STATEMENT_ALARM_SUCCESS" as const;

const GET_DELIVERY_ALARM = "GET_DELIVERY_ALARM" as const;
const GET_DELIVERY_ALARM_SUCCESS = "GET_DELIVERY_ALARM_SUCCESS" as const;

const GET_BRAND_LAUNDRY_ALARM = "GET_BRAND_LAUNDRY_ALARM" as const;
const GET_BRAND_LAUNDRY_ALARM_SUCCESS =
  "GET_BRAND_LAUNDRY_ALARM_SUCCESS" as const;

const GET_BRAND_HISTORY_ALARM = "GET_BRAND_HISTORY_ALARM" as const;
const GET_BRAND_HISTORY_ALARM_SUCCESS =
  "GET_BRAND_HISTORY_ALARM_SUCCESS" as const;

const GET_ADMIN_LAUNDRY_ALARM = "GET_ADMIN_LAUNDRY_ALARM" as const;
const GET_ADMIN_LAUNDRY_ALARM_SUCCESS =
  "GET_ADMIN_LAUNDRY_ALARM_SUCCESS" as const;

const GET_ADMIN_HISTORY_ALARM = "GET_ADMIN_HISTORY_ALARM" as const;
const GET_ADMIN_HISTORY_ALARM_SUCCESS =
  "GET_ADMIN_HISTORY_ALARM_SUCCESS" as const;

const GET_ALARM_ALL_CHECK = "GET_ALARM_ALL_CHECK" as const;

//엑셀용 데이터 파일
const GET_EXCEL_DATA = "GET_EXCEL_DATA" as const;
const GET_EXCEL_DATA_SUCCESS = "GET_EXCEL_DATA_SUCCESS" as const;

//거래명세서 전용 엑셀 데이터 파일
const GET_TRANS_EXCEL_DATA = "GET_TRANS_EXCEL_DATA" as const;
const GET_TRANS_EXCEL_DATA_SUCCESS = "GET_TRANS_EXCEL_DATA_SUCCESS" as const;

// 액션 생성 함수 정의
export const layoutHeightAction = (data: number) => ({
  type: LAYOUT_HEIGHT,
  data,
});
export const layoutHeightSuccessAction = (layoutHeight: number) => ({
  type: LAYOUT_HEIGHT_SUCCESS,
  layoutHeight,
});

export const loginAction = (loginForm: ILoginForm) => ({
  type: USER_LOGIN,
  loginForm,
});
export const loginSuccessAction = (loginResponse: ILoginResponse) => ({
  type: USER_LOGIN_SUCCESS,
  loginResponse,
});

export const getLoginKeepAction = () => ({
  type: GET_LOGIN_KEEP,
});

export const getLoginKeepSuccessAction = (loginResponse: ILoginResponse) => ({
  type: GET_LOGIN_KEEP_SUCCESS,
  loginResponse,
});

export const getProfileAction = () => ({ type: GET_PROFILE });
export const getProfileSuccessAction = (loginResponse: ILoginResponse) => ({
  type: GET_PROFILE_SUCCESS,
  loginResponse,
});
export const getProfileDetailAction = (role: string) => ({
  type: GET_PROFILE_DETAIL,
  role,
});
export const getProfileDetailSuccessAction = (
  profileDetail: ProfileDetail
) => ({
  type: GET_PROFILE_DETAIL_SUCCESS,
  profileDetail,
});

export const patchProfileFactoryEditAction = (payload: ProfileModify) => ({
  type: PATCH_PROFILE_FACTORY_EDIT,
  payload,
});
export const patchProfileFactoryEditSuccessAction = () => ({
  type: PATCH_PROFILE_FACTORY_EDIT_SUCCESS,
});

export const patchProfileStoreEditAction = (payload: ProfileModify) => ({
  type: PATCH_PROFILE_STORE_EDIT,
  payload,
});
export const patchProfileStoreEditSuccessAction = () => ({
  type: PATCH_PROFILE_STORE_EDIT_SUCCESS,
});

export const patchProfileBrandEditAction = (payload: ProfileModify) => ({
  type: PATCH_PROFILE_BRAND_EDIT,
  payload,
});
export const patchProfileBrandEditSuccessAction = () => ({
  type: PATCH_PROFILE_BRAND_EDIT_SUCCESS,
});

export const patchProfileAdminEditAction = (payload: ProfileModify) => ({
  type: PATCH_PROFILE_ADMIN_EDIT,
  payload,
});
export const patchProfileAdminEditSuccessAction = () => ({
  type: PATCH_PROFILE_ADMIN_EDIT_SUCCESS,
});

export const logoutAction = () => ({ type: USER_LOGOUT });

export const setRoleAction = (role: string) => ({ type: SET_ROLE, role });
export const getLaundryListAcrion = () => ({ type: GET_LAUNDRY_LIST });
export const getStoreListAction = () => ({ type: GET_STORE_LIST });
export const getInventoryListAction = () => ({ type: GET_INVENTORY_LIST });
export const getFactoryListAction = () => ({ type: GET_FACTORY_LIST });

export const patchPasswordChangeAction = (payload: PasswordChangeForm) => ({
  type: PATCH_PASSWORD_CHANGE,
  payload,
});
export const patchPasswordChangeSuccessAction = () => ({
  type: PATCH_PASSWORD_CHANGE_SUCCESS,
});

// Board 게시판

export const getBoardListAction = (
  page: number,
  data: BoardGetList | null
) => ({
  type: GET_BOARD_LIST,
  page,
  data,
});
export const getBoardListSuccessAction = (boardList: BoardList) => ({
  type: GET_BOARD_LIST_SUCCESS,
  boardList,
});
export const getBoardDetailDataAction = (id: number) => ({
  type: GET_BOARD_DETAIL_DATA,
  id,
});
export const getBoardDetailDataSuccessAction = (
  boardDetail: BoardDetailData
) => ({
  type: GET_BOARD_DETAIL_DATA_SUCCESS,
  boardDetail,
});

export const getBoardToUserListAction = () => ({
  type: GET_BOARD_TO_USERLIST,
});
export const getBoardToUserListSuccessAction = (
  boardToUserList: BoardToUserList
) => ({
  type: GET_BOARD_TO_USERLIST_SUCCESS,
  boardToUserList,
});

export const postBoardDataAction = (boardCommand: BoardPostData) => ({
  type: POST_BOARD_DATA,
  boardCommand,
});
export const postBoardDataSuccessAction = () => ({
  type: POST_BOARD_DATA_SUCCESS,
});

export const patchBoardDataAction = (
  boardCommand: BoardPostData,
  id: number,
  page: number,
  data: BoardGetList | null
) => ({
  type: PATCH_BOARD_DATA,
  boardCommand,
  id,
  page,
  data,
});
export const patchBoardDataSuccessAction = () => ({
  type: PATCH_BOARD_DATA_SUCCESS,
});

export const deleteBoardDataAction = (id: number) => ({
  type: DELETE_BOARD_DATA,
  id,
});
export const deleteBoardDataSuccessAction = () => ({
  type: DELETE_BOARD_DATA_SUCCESS,
});

export const postBoardCommentAction = (data: any, detailId: number) => ({
  type: POST_BOARD_COMMENT,
  data,
  detailId,
});
export const postBoardCommentSuccessAction = () => ({
  type: POST_BOARD_COMMENT_SUCCESS,
});

export const patchBoardCommentAction = (
  data: any,
  commentId: number,
  detailId: number
) => ({
  type: PATCH_BOARD_COMMENT,
  data,
  commentId,
  detailId,
});
export const patchBoardCommentSuccessAction = () => ({
  type: PATCH_BOARD_COMMENT_SUCCESS,
});

export const deleteBoardCommentAction = (id: number, detailId: number) => ({
  type: DELETE_BOARD_COMMENT,
  id,
  detailId,
});
export const deleteBoardCommentSuccessAction = () => ({
  type: DELETE_BOARD_COMMENT_SUCCESS,
});

// 공지사항
export const getNoticeListAction = (
  page: number,
  data: BoardGetList | null
) => ({
  type: GET_NOTICE_LIST,
  page,
  data,
});
export const getNoticeListSuccessAction = (noticeList: NoticeList) => ({
  type: GET_NOTICE_LIST_SUCCESS,
  noticeList,
});

export const getNoticeDetailDataAction = (id: number) => ({
  type: GET_NOTICE_DETAIL_DATA,
  id,
});
export const getNoticeDetailDataSuccessAction = (
  noticeDetail: BoardDetailData
) => ({
  type: GET_NOTICE_DETAIL_DATA_SUCCESS,
  noticeDetail,
});

export const postNoticeDataAction = (
  noticeCommand: BoardPostData,
  data: BoardGetList | null
) => ({
  type: POST_NOTICE_DATA,
  noticeCommand,
  data,
});
export const postNoticeDataSuccessAction = () => ({
  type: POST_NOTICE_DATA_SUCCESS,
});

export const patchNoticeDataAction = (
  noticeCommand: BoardPostData,
  id: number,
  page: number,
  data: BoardGetList | null
) => ({
  type: PATCH_NOTICE_DATA,
  noticeCommand,
  id,
  page,
  data,
});
export const patchNoticeDataSuccessAction = () => ({
  type: PATCH_NOTICE_DATA_SUCCESS,
});

export const deleteNoticeDataAction = (
  id: number,
  data: BoardGetList | null
) => ({
  type: DELETE_NOTICE_DATA,
  id,
  data,
});
export const deleteNoticeDataSuccessAction = () => ({
  type: DELETE_NOTICE_DATA_SUCCESS,
});

export const getNoticeAlarmAction = () => ({
  type: GET_NOTICE_ALARM,
});
export const getNoticeAlarmSuccessAction = (noticeAlarm: NoticeAlarm) => ({
  type: GET_NOTICE_ALARM_SUCCESS,
  noticeAlarm,
});

export const getBoardAlarmAction = () => ({
  type: GET_BOARD_ALARM,
});
export const getBoardAlarmSuccessAction = (boardAlarm: BoardAlarm) => ({
  type: GET_BOARD_ALARM_SUCCESS,
  boardAlarm,
});

export const getLaundryRequestAlarmAction = () => ({
  type: GET_LAUNDRY_REQUEST_ALARM,
});
export const getLAundryRequestAlarmSuccessAction = (
  laundryRequestAlarm: LaundryRequestAlarm
) => ({
  type: GET_LAUNDRY_REQUEST_ALARM_SUCCESS,
  laundryRequestAlarm,
});

export const getSpecificationsDetailAction = (id: number) => ({
  type: GET_SPECIFICATIONS_DETAIL,
  id,
});
export const getSpecificationsDetailSuccessAction = (
  specificationsDetail: SpecificationsDetail
) => ({
  type: GET_SPECIFICATIONS_DETAIL_SUCCESS,
  specificationsDetail,
});

export const getSpecificationsFranchiseListAction = () => ({
  type: GET_SPECIFICATIONS_FRANCHISE_LIST,
});
export const getSpecificationsFranchiseListSuccessAction = (
  specificationsFranchiseList: SpecificationsFranchiseList
) => ({
  type: GET_SPECIFICATIONS_FRANCHISE_LIST_SUCCESS,
  specificationsFranchiseList,
});

export const getLaundryCompleteAlarmAction = () => ({
  type: GET_LAUNDRY_COMPLETE_ALARM,
});
export const getLaundryCompleteAlarmSuccessAction = (
  laundryCompleteAlarm: LaundryCompleteAlarm
) => ({
  type: GET_LAUNDRY_COMPLETE_ALARM_SUCCESS,
  laundryCompleteAlarm,
});

export const getStatementAlarmAction = () => ({
  type: GET_STATEMENT_ALARM,
});
export const getStatementAlarmSuccessAction = (
  statementAlarm: AlarmData[] | null
) => ({
  type: GET_STATEMENT_ALARM_SUCCESS,
  statementAlarm,
});

export const getBrandLaundryAlarmAction = () => ({
  type: GET_BRAND_LAUNDRY_ALARM,
});
export const getBrandLaundryAlarmSuccessAction = (
  laundryCompleteAlarm: LaundryCompleteAlarm
) => ({
  type: GET_BRAND_LAUNDRY_ALARM_SUCCESS,
  laundryCompleteAlarm,
});

export const getBrandHistoryAlarmAction = () => ({
  type: GET_BRAND_HISTORY_ALARM,
});
export const getBrandHistoryAlarmSuccessAction = (
  statementAlarm: AlarmData[] | null
) => ({
  type: GET_BRAND_HISTORY_ALARM_SUCCESS,
  statementAlarm,
});

export const getAdminLaundryAlarmAction = () => ({
  type: GET_ADMIN_LAUNDRY_ALARM,
});
export const getAdminLaundryAlarmSuccessAction = (
  laundryCompleteAlarm: LaundryCompleteAlarm
) => ({
  type: GET_ADMIN_LAUNDRY_ALARM_SUCCESS,
  laundryCompleteAlarm,
});

export const getAdminHistoryAlarmAction = () => ({
  type: GET_ADMIN_HISTORY_ALARM,
});
export const getAdminHistoryAlarmSuccessAction = (
  statementAlarm: AlarmData[] | null
) => ({
  type: GET_ADMIN_HISTORY_ALARM_SUCCESS,
  statementAlarm,
});

export const getDeliveryAlarmAction = () => ({
  type: GET_DELIVERY_ALARM,
});
export const getDeliveryAlarmSuccessAction = (
  deliveryAlarm: AlarmData[] | null
) => ({
  type: GET_DELIVERY_ALARM_SUCCESS,
  deliveryAlarm,
});

export const getAlarmAllCheckAction = (role: string) => ({
  type: GET_ALARM_ALL_CHECK,
  role,
});

export const getExcelDataAction = (id: number) => ({
  type: GET_EXCEL_DATA,
  id,
});

export const getExcelDataSuccessAction = (payload: IExcelData) => ({
  type: GET_EXCEL_DATA_SUCCESS,
  payload,
});

export const getTransExcelDataAction = (id: number) => ({
  type: GET_TRANS_EXCEL_DATA,
  id,
});

export const getTransExcelDataSuccessAction = (payload: ITransExcelData) => ({
  type: GET_TRANS_EXCEL_DATA_SUCCESS,
  payload,
});

// saga 함수

function* layoutHeightSaga(action: ReturnType<typeof layoutHeightAction>) {
  yield put(layoutHeightSuccessAction(action.data));
}

function* loginSaga(action: ReturnType<typeof loginAction>) {
  try {
    if (localStorage.getItem("CURRENT_USER"))
      localStorage.removeItem("CURRENT_USER");

    const {
      data: { status, data },
    }: AxiosResponse = yield call(userApi.login, action.loginForm);
    if (status !== "OK") return alert("서버 에러");

    axios.defaults.headers["Authorization"] = data.token;
    localStorage.setItem("CURRENT_USER", data.token);

    delete data.token;
    yield put(loginSuccessAction(data.user));

    if (RoleUtils.isStore(data.user.type)) {
      return history.push("/store/dashboard");
    }
    if (RoleUtils.isFactory(data.user.type)) {
      return history.push("/factory/dashboard");
    }
    if (RoleUtils.isMaster(data.user.type)) {
      return history.push("/master/dashboard");
    }
    if (RoleUtils.isMasterAdmin(data.user.type)) {
      return history.push("/admin/dashboard");
    }
  } catch (e: any) {
    alert(e.response.data.message);
    localStorage.removeItem("CURRENT_USER");
    history.push(`/`);
  }
}

function* getProfileSaga() {
  try {
    const token = localStorage.getItem("CURRENT_USER");
    axios.defaults.headers["Authorization"] = token;

    const {
      data: { status, data },
    }: AxiosResponse = yield call(userApi.getProfile);
    // status 코드 확인
    if (status !== "OK") return alert("서버 에러");
    // 토큰저장
    axios.defaults.headers["Authorization"] = data.token;
    localStorage.setItem("CURRENT_USER", data.token);

    // getProfileSuccessAction 호출
    yield put(getProfileSuccessAction(data.user));
    // 호출해서 새로고침한 데이터 initialState에 저장
  } catch (e) {}
}

function* getLoginKeepSaga() {
  try {
    const token = localStorage.getItem("CURRENT_USER");
    axios.defaults.headers["Authorization"] = token;

    const {
      data: { status, data },
    }: AxiosResponse = yield call(userApi.getProfile);
    // status 코드 확인

    if (status !== "OK") return alert("서버 에러");
    // 토큰저장
    axios.defaults.headers["Authorization"] = data.token;
    localStorage.setItem("CURRENT_USER", data.token);

    if (RoleUtils.isStore(data.user.type)) {
      return history.push("/store/dashboard");
    }
    if (RoleUtils.isFactory(data.user.type)) {
      return history.push("/factory/dashboard");
    }
    if (RoleUtils.isMaster(data.user.type)) {
      return history.push("/master/dashboard");
    }
    if (RoleUtils.isMasterAdmin(data.user.type)) {
      return history.push("/admin/dashboard");
    }

    // getProfileSuccessAction 호출
    yield put(getLoginKeepSuccessAction(data.user));
    // 호출해서 새로고침한 데이터 initialState에 저장
  } catch (e) {}
}

function* getProfileDetailSaga(
  action: ReturnType<typeof getProfileDetailAction>
) {
  try {
    let res: AxiosResponse | null = null;

    if (RoleUtils.isStore(action.role))
      res = yield call(storeApi.getFranchiseMe);
    if (RoleUtils.isFactory(action.role))
      res = yield call(factoryApi.getFactoryProfile);
    if (RoleUtils.isMaster(action.role))
      res = yield call(masterApi.getMasterProfileData);
    if (RoleUtils.isMasterAdmin(action.role))
      res = yield call(adminApi.getAdminProfile);

    const {
      data: { data },
    } = res!;

    // if (status !== "OK") return alert("error");
    // TODO: dispatch action
    yield put(getProfileDetailSuccessAction(data));
  } catch (error) {
    // alert(error.response.data.message);
  }
}

function* patchProfileFactoryEditSaga(
  action: ReturnType<typeof patchProfileFactoryEditAction>
) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(
      factoryApi.patchFactoryProfileModify,
      action.payload
    );
    if (status !== "OK") return alert("error");
    yield put(getProfileDetailAction("ROLE_FACTORY"));
    yield put(getProfileAction());
    alert("성공적으로 변경하였습니다");
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* patchProfileStoreEditSaga(
  action: ReturnType<typeof patchProfileStoreEditAction>
) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(
      storeApi.patchFranchiseProfileModify,
      action.payload
    );
    if (status !== "OK") return alert("error");
    yield put(getProfileDetailAction("ROLE_FRANCHISE"));
    yield put(getProfileAction());
    alert("성공적으로 변경하였습니다");
  } catch (error) {
    // alert(error.response.data.message);
  }
}

function* patchProfileBrandEditSaga(
  action: ReturnType<typeof patchProfileBrandEditAction>
) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(
      masterApi.patchMasterProfileModify,
      action.payload
    );
    if (status !== "OK") return alert("error");
    yield put(getProfileDetailAction("ROLE_BRAND"));
    yield put(getProfileAction());
    alert("성공적으로 변경하였습니다");
  } catch (error) {
    // alert(error.response.data.message);
  }
}

function* patchProfileAdminEditSaga(
  action: ReturnType<typeof patchProfileAdminEditAction>
) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(
      adminApi.patchAdminProfileModify,
      action.payload
    );
    if (status !== "OK") return alert("error");
    yield put(getProfileDetailAction("ROLE_MASTER_ADMIN"));
    yield put(getProfileAction());
    alert("성공적으로 변경하였습니다");
  } catch (error) {
    // alert(error.response.data.message);
  }
}

function* patchPasswordChangeSaga(
  action: ReturnType<typeof patchPasswordChangeAction>
) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(userApi.patchPasswordChange, action.payload);
    if (status !== "OK") return alert("error");
    yield put(patchPasswordChangeSuccessAction());
    alert("성공적으로 변경하였습니다");
  } catch (error) {
    // alert(error.response.data.message);
  }
}

// Board 게시판
function* getBoardListSaga(action: ReturnType<typeof getBoardListAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      userApi.getBoardList,
      action.page,
      action.data
    );
    if (status !== "OK") return alert("error");
    yield put(getBoardListSuccessAction(data));
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* getBoardDetailDataSaga(
  action: ReturnType<typeof getBoardDetailDataAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(userApi.getBoardDetail, action.id);
    if (status !== "OK") return alert("error");
    yield put(getBoardDetailDataSuccessAction(data));
    yield put(getBoardAlarmAction());
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* getBoardToUserListSaga() {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(userApi.getBoardToUserList);
    if (status !== "OK") return alert("error");
    yield put(getBoardToUserListSuccessAction(data));
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* postBoardDataSaga(action: ReturnType<typeof postBoardDataAction>) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(
      userApi.postBoardContent,
      action.boardCommand
    );
    if (status !== "CREATED") return alert("error");
    yield put(postBoardDataSuccessAction());
    yield put(getBoardListAction(1, null));
    yield alert("게시글이 등록되었습니다");
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* patchBoardDataSaga(action: ReturnType<typeof patchBoardDataAction>) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(
      userApi.patchBoardContent,
      action.boardCommand,
      action.id
    );
    if (status !== "OK") return alert("error");
    yield put(patchBoardDataSuccessAction());
    yield put(getBoardDetailDataAction(action.id));
    yield put(getBoardListAction(action.page, action.data));
    yield alert("수정되었습니다");
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* deleteBoardDataSaga(
  action: ReturnType<typeof deleteBoardDataAction>
) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(userApi.deleteBoardContent, action.id);
    if (status !== "OK") return alert("error");
    yield put(deleteBoardDataSuccessAction());
    yield put(getBoardListAction(1, null));
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* postBoardCommentSaga(
  action: ReturnType<typeof postBoardCommentAction>
) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(
      userApi.postBoardComment,
      action.data,
      action.detailId
    );
    if (status !== "CREATED") return alert("error");
    yield put(postBoardCommentSuccessAction());
    yield put(getBoardDetailDataAction(action.detailId));
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* patchBoardCommentSaga(
  action: ReturnType<typeof patchBoardCommentAction>
) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(
      userApi.patchBoardComment,
      action.data,
      action.commentId
    );
    if (status !== "OK") return alert("error");
    yield put(patchBoardCommentSuccessAction());
    yield put(getBoardDetailDataAction(action.detailId));
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* deleteBoardCommentSaga(
  action: ReturnType<typeof deleteBoardCommentAction>
) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(userApi.deleteBoardCommnet, action.id);
    if (status !== "OK") return alert("error");
    yield put(deleteBoardCommentSuccessAction());
    yield put(getBoardDetailDataAction(action.detailId));
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

// 공지사항 Saga

function* getNoticeListSaga(action: ReturnType<typeof getNoticeListAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      userApi.getNoticeList,
      action.page,
      action.data
    );
    if (status !== "OK") return alert("error");
    yield put(getNoticeListSuccessAction(data));
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* getNoticeDetailDataSaga(
  action: ReturnType<typeof getNoticeDetailDataAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(userApi.getNoticeDetail, action.id);
    if (status !== "OK") return alert("error");
    yield put(getNoticeDetailDataSuccessAction(data));
    yield put(getNoticeAlarmAction());
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* postNoticeDataSaga(action: ReturnType<typeof postNoticeDataAction>) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(
      userApi.postNoticeContent,
      action.noticeCommand
    );
    if (status !== "CREATED") return alert("error");
    yield put(postNoticeDataSuccessAction());
    yield put(getNoticeListAction(1, action.data));
    yield alert("등록되었습니다");
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* patchNoticeDataSaga(
  action: ReturnType<typeof patchNoticeDataAction>
) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(
      userApi.patchNoticeContent,
      action.noticeCommand,
      action.id
    );
    if (status !== "OK") return alert("error");
    yield put(patchNoticeDataSuccessAction());
    yield put(getNoticeDetailDataAction(action.id));
    yield put(getNoticeListAction(action.page, action.data));
    yield alert("수정되었습니다");
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* deleteNoticeDataSaga(
  action: ReturnType<typeof deleteNoticeDataAction>
) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(userApi.deleteNoticeContent, action.id);
    if (status !== "OK") return alert("error");
    yield put(deleteNoticeDataSuccessAction());
    yield put(getNoticeListAction(1, action.data));
    yield alert("삭제되었습니다");
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* getNoticeAlarmSaga() {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(userApi.getNoticeAlarm);
    if (status !== "OK") return alert("error");
    yield put(getNoticeAlarmSuccessAction(data));
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* getLaundryCompleteAlarmSaga() {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(userApi.getLaundryCompleteAlarm);
    if (status !== "OK") return alert("error");
    yield put(getLaundryCompleteAlarmSuccessAction(data));
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* getBoardAlarmSaga() {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(userApi.getBoardAlarm);
    if (status !== "OK") return alert("error");
    yield put(getBoardAlarmSuccessAction(data));
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* getLaundryRequestAlarmSaga() {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(userApi.getLaundryRequestAlarm);
    if (status !== "OK") return alert("error");
    yield put(getLAundryRequestAlarmSuccessAction(data));
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* getSpecificationsDetailSaga(
  action: ReturnType<typeof getSpecificationsDetailAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(userApi.getSpecificationsDetail, action.id);
    if (status !== "OK") return alert("error");
    yield put(getSpecificationsDetailSuccessAction(data));
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* getSpecificationsFranchiseListSaga() {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(userApi.getSpecificationsFranchiseList);
    if (status !== "OK") return alert("error");
    yield put(getSpecificationsFranchiseListSuccessAction(data));
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* getStatementAlarmSaga() {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(userApi.getStatementAlarm);
    if (status !== "OK") return alert("error");

    yield put(getStatementAlarmSuccessAction(data));
  } catch (error: any) {
    alert(error.response.data.message);
  }
}
function* getDeliveryAlarmSaga() {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(userApi.getDeliveryAlarm);
    if (status !== "OK") return alert("error");

    yield put(getDeliveryAlarmSuccessAction(data));
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* getAlarmAllCheckSaga(
  action: ReturnType<typeof getAlarmAllCheckAction>
) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(userApi.getAlarmAllCheck);
    if (status !== "OK") return alert("error");
    yield put(getNoticeAlarmAction());
    yield put(getBoardAlarmAction());
    if (RoleUtils.isFactory(action.role)) {
      yield put(getDeliveryAlarmAction());
      yield put(getLaundryRequestAlarmAction());
    }
    if (RoleUtils.isStore(action.role)) {
      yield put(getStatementAlarmAction());
      yield put(getLaundryCompleteAlarmAction());
    }
    if (RoleUtils.isMaster(action.role)) {
      yield put(getBrandHistoryAlarmAction());
    }
    if (RoleUtils.isMasterAdmin(action.role)) {
      yield put(getAdminHistoryAlarmAction());
    }
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* getExcelDataSaga(action: ReturnType<typeof getExcelDataAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(userApi.getExcelData, action.id);
    if (status !== "OK") return alert("error");
    yield put(getExcelDataSuccessAction(data));
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* getTransExcelDataSaga(
  action: ReturnType<typeof getTransExcelDataAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(userApi.getExcelData2, action.id);
    if (status !== "OK") return alert("error");
    yield put(getTransExcelDataSuccessAction(data));
  } catch (e: any) {
    // console.log(e);
  }
}

function* getBrandLaundryAlarmSaga() {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(userApi.getBrandLaundryAlarm);
    if (status !== "OK") return alert("error");
    yield put(getBrandLaundryAlarmSuccessAction(data));
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* getBrandHistoryAlarmSaga() {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(userApi.getBrandHistoryAlarm);
    if (status !== "OK") return alert("error");
    yield put(getBrandHistoryAlarmSuccessAction(data));
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* getAdminLaundryAlarmSaga() {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(userApi.getAdminLaundryAlarm);
    if (status !== "OK") return alert("error");
    yield put(getAdminLaundryAlarmSuccessAction(data));
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* getAdminHistoryAlarmSaga() {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(userApi.getAdminHistoryAlarm);
    if (status !== "OK") return alert("error");
    yield put(getAdminHistoryAlarmSuccessAction(data));
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

const initialState: IUserState = {
  profile: {
    type: "GUEST",
  },
  profileDetail: null,
  boardList: null,
  boardDetail: null,
  noticeList: null,
  noticeDetail: null,
  noticeAlarm: null,
  boardAlarm: null,
  laundryCompleteAlarm: null,
  laundryRequestAlarm: null,
  specificationsDetail: null,
  specificationsFranchiseList: null,
  statementAlarm: null,
  deliveryAlarm: null,
  excelData: null,
  layoutHeight: null,
  transExcelData: null,
  boardToUserList: null,
};

export function* userSaga() {
  yield takeEvery(LAYOUT_HEIGHT, layoutHeightSaga);
  yield takeEvery(USER_LOGIN, loginSaga);
  yield takeEvery(GET_PROFILE, getProfileSaga);
  yield takeEvery(GET_PROFILE_DETAIL, getProfileDetailSaga);
  yield takeEvery(PATCH_PROFILE_FACTORY_EDIT, patchProfileFactoryEditSaga);
  yield takeEvery(PATCH_PASSWORD_CHANGE, patchPasswordChangeSaga);
  yield takeEvery(PATCH_PROFILE_STORE_EDIT, patchProfileStoreEditSaga);
  yield takeEvery(PATCH_PROFILE_BRAND_EDIT, patchProfileBrandEditSaga);
  yield takeEvery(PATCH_PROFILE_ADMIN_EDIT, patchProfileAdminEditSaga);
  yield takeEvery(GET_LOGIN_KEEP, getLoginKeepSaga);
  yield takeEvery(GET_BOARD_LIST, getBoardListSaga);
  yield takeEvery(GET_BOARD_DETAIL_DATA, getBoardDetailDataSaga);
  yield takeEvery(GET_BOARD_TO_USERLIST, getBoardToUserListSaga);
  yield takeEvery(POST_BOARD_DATA, postBoardDataSaga);
  yield takeEvery(PATCH_BOARD_DATA, patchBoardDataSaga);
  yield takeEvery(DELETE_BOARD_DATA, deleteBoardDataSaga);
  yield takeEvery(POST_BOARD_COMMENT, postBoardCommentSaga);
  yield takeEvery(PATCH_BOARD_COMMENT, patchBoardCommentSaga);
  yield takeEvery(DELETE_BOARD_COMMENT, deleteBoardCommentSaga);
  yield takeEvery(GET_NOTICE_LIST, getNoticeListSaga);
  yield takeEvery(GET_NOTICE_DETAIL_DATA, getNoticeDetailDataSaga);
  yield takeEvery(POST_NOTICE_DATA, postNoticeDataSaga);
  yield takeEvery(PATCH_NOTICE_DATA, patchNoticeDataSaga);
  yield takeEvery(DELETE_NOTICE_DATA, deleteNoticeDataSaga);
  yield takeEvery(GET_NOTICE_ALARM, getNoticeAlarmSaga);
  yield takeEvery(GET_BOARD_ALARM, getBoardAlarmSaga);
  yield takeEvery(GET_LAUNDRY_COMPLETE_ALARM, getLaundryCompleteAlarmSaga);
  yield takeEvery(GET_LAUNDRY_REQUEST_ALARM, getLaundryRequestAlarmSaga);
  yield takeEvery(GET_SPECIFICATIONS_DETAIL, getSpecificationsDetailSaga);
  yield takeEvery(
    GET_SPECIFICATIONS_FRANCHISE_LIST,
    getSpecificationsFranchiseListSaga
  );
  yield takeEvery(GET_STATEMENT_ALARM, getStatementAlarmSaga);
  yield takeEvery(GET_DELIVERY_ALARM, getDeliveryAlarmSaga);
  yield takeEvery(GET_ALARM_ALL_CHECK, getAlarmAllCheckSaga);
  yield takeEvery(GET_EXCEL_DATA, getExcelDataSaga);
  yield takeEvery(GET_TRANS_EXCEL_DATA, getTransExcelDataSaga);
  yield takeEvery(GET_BRAND_LAUNDRY_ALARM, getBrandLaundryAlarmSaga);
  yield takeEvery(GET_BRAND_HISTORY_ALARM, getBrandHistoryAlarmSaga);
  yield takeEvery(GET_ADMIN_LAUNDRY_ALARM, getAdminLaundryAlarmSaga);
  yield takeEvery(GET_ADMIN_HISTORY_ALARM, getAdminHistoryAlarmSaga);
}

function user(state: IUserState = initialState, action: any) {
  switch (action.type) {
    case LAYOUT_HEIGHT_SUCCESS:
      return { ...state, layoutHeight: action.layoutHeight };
    case USER_LOGIN_SUCCESS:
    case GET_PROFILE_SUCCESS:
      // console.log(action.loginResponse);
      return { ...state, profile: { ...action.loginResponse } };
    case USER_LOGOUT: {
      axios.defaults.headers["Authorization"] = null;
      localStorage.removeItem("CURRENT_USER");
      history.push("/");
      return initialState;
    }
    case GET_LOGIN_KEEP_SUCCESS:
      return { ...state, profile: { ...action.loginResponse } };

    case GET_LAUNDRY_LIST:
      return { ...state, laundryList: DummyUtils.getLaundryList() };
    case GET_STORE_LIST:
      return { ...state, storeList: DummyUtils.getStoreList() };
    case GET_INVENTORY_LIST:
      return { ...state, inventoryList: DummyUtils.getInventoryList() };
    case GET_FACTORY_LIST:
      return { ...state, factoryList: DummyUtils.getFactoryList() };
    case GET_PROFILE_DETAIL_SUCCESS:
      return { ...state, profileDetail: action.profileDetail };
    case PATCH_PROFILE_FACTORY_EDIT_SUCCESS:
      return { ...state };
    case PATCH_PROFILE_STORE_EDIT_SUCCESS:
      return { ...state };
    case PATCH_PROFILE_BRAND_EDIT_SUCCESS:
      return { ...state };
    case PATCH_PROFILE_ADMIN_EDIT_SUCCESS:
      return { ...state };
    case PATCH_PASSWORD_CHANGE_SUCCESS:
      return { ...state };
    case GET_BOARD_LIST_SUCCESS:
      return { ...state, boardList: action.boardList };
    case GET_BOARD_DETAIL_DATA_SUCCESS:
      return { ...state, boardDetail: action.boardDetail };
    case GET_BOARD_TO_USERLIST_SUCCESS:
      return { ...state, boardToUserList: action.boardToUserList };
    case POST_BOARD_DATA_SUCCESS:
      return { ...state };
    case PATCH_BOARD_DATA_SUCCESS:
      return { ...state };
    case DELETE_BOARD_DATA_SUCCESS:
      return { ...state };
    case POST_BOARD_COMMENT_SUCCESS:
      return { ...state };
    case PATCH_BOARD_COMMENT_SUCCESS:
      return { ...state };
    case DELETE_BOARD_COMMENT_SUCCESS:
      return { ...state };
    case GET_NOTICE_LIST_SUCCESS:
      return { ...state, noticeList: action.noticeList };
    case GET_NOTICE_DETAIL_DATA_SUCCESS:
      return { ...state, noticeDetail: action.noticeDetail };
    case POST_NOTICE_DATA_SUCCESS:
      return { ...state };
    case PATCH_NOTICE_DATA_SUCCESS:
      return { ...state };
    case DELETE_NOTICE_DATA_SUCCESS:
      return { ...state };
    case GET_NOTICE_ALARM_SUCCESS:
      return { ...state, noticeAlarm: action.noticeAlarm };
    case GET_BOARD_ALARM_SUCCESS:
      return { ...state, boardAlarm: action.boardAlarm };
    case GET_LAUNDRY_COMPLETE_ALARM_SUCCESS:
      return { ...state, laundryCompleteAlarm: action.laundryCompleteAlarm };
    case GET_LAUNDRY_REQUEST_ALARM_SUCCESS:
      return { ...state, laundryRequestAlarm: action.laundryRequestAlarm };
    case GET_SPECIFICATIONS_DETAIL_SUCCESS:
      return { ...state, specificationsDetail: action.specificationsDetail };
    case GET_SPECIFICATIONS_FRANCHISE_LIST_SUCCESS:
      return {
        ...state,
        specificationsFranchiseList: action.specificationsFranchiseList,
      };
    case GET_STATEMENT_ALARM_SUCCESS:
      return { ...state, statementAlarm: action.statementAlarm };
    case GET_DELIVERY_ALARM_SUCCESS:
      return { ...state, deliveryAlarm: action.deliveryAlarm };
    case GET_EXCEL_DATA_SUCCESS:
      return { ...state, excelData: action.payload };
    case GET_TRANS_EXCEL_DATA_SUCCESS:
      return { ...state, transExcelData: action.payload };
    case GET_BRAND_LAUNDRY_ALARM_SUCCESS:
      return { ...state, laundryCompleteAlarm: action.laundryCompleteAlarm };
    case GET_BRAND_HISTORY_ALARM_SUCCESS:
      return { ...state, statementAlarm: action.statementAlarm };
    case GET_ADMIN_LAUNDRY_ALARM_SUCCESS:
      return { ...state, laundryCompleteAlarm: action.laundryCompleteAlarm };
    case GET_ADMIN_HISTORY_ALARM_SUCCESS:
      return { ...state, statementAlarm: action.statementAlarm };
    default:
      return state;
  }
}

export default user;
