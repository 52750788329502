import moment from "moment";

class DateFormatUtils {
  dateToFormat(date: Date | null | undefined) {
    return date ? moment(date).format("YYYY-MM-DD") : "-";
  }

  dateTerm(startDate: any, endDate: any) {
    let sd = moment(startDate).format("YYYY-MM-DD");
    let ed = moment(endDate).format("YYYY-MM-DD");
    let sdt = new Date(sd);
    let edt = new Date(ed);
    let dateDiff = Math.ceil(
      (edt.getTime() - sdt.getTime()) / (1000 * 3600 * 24)
    );

    return dateDiff;
  }

  dateSimple(date: string) {
    let month = date.slice(5, 6) === "0" ? date.slice(6, 7) : date.slice(5, 7);
    let day = date.slice(8, 9) === "0" ? date.slice(9, 10) : date.slice(8, 10);
    let answer = month + "/" + day;

    return answer;
  }

  dateToFormat2(date: string) {
    const item = date
      .split("-")
      .map((item) => (item.length < 2 ? `0${item}` : item));

    return `${item[0]}-${item[1]}-${item[2]}`;
  }

  // dateContract(date: string) {
  //   let data = date.split("~");
  //   if (data[1] === "말일") return `이달 ${data[0]} ~ ${data[1]}`;
  //   return `이달 ${data[0]} ~ 익월 ${data[1]}`;
  // }
}

export default new DateFormatUtils();
