// import axios from "api/defaultClient";

class RoleUtils {
  isMasterAdmin(role: string) {
    return role === "ROLE_MASTER_ADMIN";
  }
  isMaster(role: string) {
    return role === "ROLE_MASTER";
  }
  isStore(role: string) {
    return role === "ROLE_FRANCHISE";
  }
  isFactory(role: string) {
    return role === "ROLE_FACTORY";
  }
  isGuest(role: string) {
    return role === "GUEST";
  }
  checkLogin(): boolean {
    const token = localStorage.getItem("CURRENT_USER");
    return token ? true : false;
  }
  checkKeepLogin(): boolean {
    const keepLogin = localStorage.getItem("KEEP_LOGIN");
    return keepLogin ? true : false;
  }
}

export default new RoleUtils();
