import { Palette } from "styles/palette";

class TypeUtils {
  boardType(type: string) {
    // if (type === "DISCONTENT") return "불만 사항";
    if (type === "INQUIRY") return "문의사항";
    if (type === "SUJUNG") return "수정요청";
    if (type === "ETC") return "기타문의";
  }

  statusType(type: string) {
    if (type) {
      return (
        <span
          className="success"
          style={{ color: `${Palette.primary}`, fontWeight: "bold" }}
        >
          완료
        </span>
      );
    } else {
      return (
        <span className="waiting" style={{ color: `red`, fontWeight: "bold" }}>
          대기
        </span>
      );
    }
  }

  statusTypeText(type: string) {
    if (type === "READY") {
      return (
        <span className="waiting" style={{ color: `red`, fontWeight: "bold" }}>
          대기
        </span>
      );
    }
    if (type === "COMPLETED") {
      return (
        <span
          className="success"
          style={{ color: `${Palette.primary}`, fontWeight: "bold" }}
        >
          완료
        </span>
      );
    }
    if (type === "SUJUNG") {
      return (
        <span
          className="success"
          style={{ color: `orange`, fontWeight: "bold" }}
        >
          수정 요청
        </span>
      );
    }
  }

  statusTypeDeliveryText(type: string) {
    if (type === "READY") {
      return "대기";
    }
    if (type === "COMPLETED") {
      return "완료";
    }
  }

  payManagementContactType(type: string) {
    if (type === "UNIT_PRICE") return "단가제";
    if (type === "MONTHLY") return "월정액";
    return "-";
  }

  payManagementSubType(type: string) {
    if (type === "QUANTITY") return "수량";
    if (type === "WEIGHT") return "중량";
    if (type === "BAG") return "포대/카트";
    return "-";
  }

  laundryRequestType(type: string) {
    if (type === "RFID")
      return (
        <span
          style={{
            fontWeight: 700,
            backgroundColor: `${Palette.tertiary}`,
            color: "#fff",
            display: "block",
            margin: "0 auto",
            lineHeight: "24px",
            borderRadius: "5px",
            width: "40px",
            height: "24px",
          }}
        >
          스캔
        </span>
      );
    if (type === "NORMAL")
      return (
        <span
          style={{
            fontWeight: 700,
            backgroundColor: `${Palette.primary}`,
            color: "#fff",
            display: "block",
            margin: "0 auto",
            lineHeight: "24px",
            borderRadius: "5px",
            width: "40px",
            height: "24px",
          }}
        >
          수기
        </span>
      );
  }

  TableReaderStatus(type: string) {
    if (type === "RFID") return "스캔 입력";
    if (type === "WRITE") return "수기 입력";
    if (type === "APPLICATION") return "세탁 신청";
    if (type === "COMPLETE") return "세탁 완료";
    if (type === "RELAUNDRY") return "재세탁";
    if (type === "INQUIRY") return "태그조회";
  }
}

export default new TypeUtils();
