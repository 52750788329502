import AdminPayManagementCompleteStatus from "components/admin/AdminPayManagementCompleteStatus";
import AdminPayManagementStatus from "components/admin/AdminPayManagementStatus";
import { IRootState } from "modules";
import {
  getAdminPayManagementListAction,
  patchAdminPaymanagementDataAction,
} from "modules/admin/admin";
import { AdminGetList } from "modules/admin/types";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

interface AdminPayManagementContainerProps {}

const AdminPayManagementContainer: React.FC<AdminPayManagementContainerProps> =
  () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [completeCheck, setCompleteCheck] = useState<string>("notComplete");
    const [drop, setDrop] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [searchData, setSearchData] = useState<AdminGetList>({
      query: "",
      dropBox: "",
      startDate: "",
      endDate: "",
      size: 10,
    });

    const adminPayManagementList = useSelector(
      (state: IRootState) => state.admin.adminPayManagementList
    );

    useEffect(() => {
      setCompleteCheck("notComplete");
      history.push(`/admin/paymanagement/`);
    }, []);

    const query = window.location.pathname;
    useEffect(() => {
      if (query === `/admin/paymanagement/complete`)
        setCompleteCheck("complete");
      if (query === `/admin/paymanagement/`) setCompleteCheck("notComplete");
    }, [query]);

    useEffect(() => {
      dispatch(getAdminPayManagementListAction(1, null, completeCheck, drop));
    }, [dispatch]);

    // useEffect(() => {
    //   setCurrentPage(1);
    //   setSearchData({
    //     query: "",
    //     dropBox: "",
    //     startDate: "",
    //     endDate: "",
    //     size: 10,
    //   });
    // }, [completeCheck, dispatch]);

    const getAdminPayManagementList = (
      page: number,
      data: AdminGetList | null
    ) => {
      setCurrentPage(page);
      if (data) setSearchData(data);
      dispatch(
        getAdminPayManagementListAction(page, data, completeCheck, drop)
      );
    };

    const listType = (type: string) => {
      setCompleteCheck(type);
      setCurrentPage(1);
      setSearchData({
        query: "",
        dropBox: "",
        startDate: "",
        endDate: "",
        size: 10,
      });
      dispatch(getAdminPayManagementListAction(1, null, type, ""));
      if (type === "complete") history.push(`/admin/paymanagement/complete`);
      if (type === "notComplete") history.push(`/admin/paymanagement/`);
    };

    const patchAdminPayManagementData = (id: number, status: string) => {
      dispatch(
        patchAdminPaymanagementDataAction(
          id,
          status,
          currentPage,
          searchData,
          completeCheck,
          drop
        )
      );
    };

    return (
      <>
        {completeCheck === "notComplete" && (
          <AdminPayManagementStatus
            drop={drop}
            setDrop={setDrop}
            completeCheck={completeCheck}
            searchData={searchData}
            setCurrentPage={setCurrentPage}
            listType={listType}
            adminPayManagementList={adminPayManagementList}
            getAdminPayManagementList={getAdminPayManagementList}
            patchAdminPayManagementData={patchAdminPayManagementData}
          />
        )}
        {completeCheck === "complete" && (
          <AdminPayManagementCompleteStatus
            drop={drop}
            setDrop={setDrop}
            completeCheck={completeCheck}
            searchData={searchData}
            setCurrentPage={setCurrentPage}
            listType={listType}
            adminPayManagementList={adminPayManagementList}
            getAdminPayManagementList={getAdminPayManagementList}
            patchAdminPayManagementData={patchAdminPayManagementData}
          />
        )}
      </>
    );
  };

export default AdminPayManagementContainer;
