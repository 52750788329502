import {
  FranchiseBilling,
  FranchiseBillingPage,
  FranchiseDetailBilling,
  FranchiseGetList,
} from "modules/store/types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Palette, ThemeColor } from "styles/palette";
import PriceUtils from "utils/PriceUtils";
import PaymentStatusDetail from "components/common/PaymentStatusDetail";
import Button from "components/common/Button";
import Paging from "components/common/Paging";
import DateFormatUtils from "utils/DateFormatUtils";
import PaymentSearch from "components/common/PaymentSearch";
import NumberUtils from "utils/NumberUtils";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

interface StorePaymentStatusProps {
  role: string;
  franchiseBillingPage: FranchiseBillingPage;
  franchiseDetailBilling: FranchiseDetailBilling | null;
  onDetail: (id: number) => void;
  billingList: (page: number, data: FranchiseGetList | null) => void;
}

const StorePaymentStatus: React.FC<StorePaymentStatusProps> = ({
  role,
  franchiseBillingPage,
  franchiseDetailBilling,
  onDetail,
  billingList,
}) => {
  const [searchData, setSearchData] = useState<FranchiseGetList>({
    query: "",
    dropBox: "",
    startDate: "",
    endDate: "",
    size: 10,
  });

  const onSearch = (page: number, data: FranchiseGetList) => {
    setSearchData(data);
    billingList(page, data);
  };

  const selectShowEntries = (size: number) => {
    searchData.size = size;
    billingList(1, searchData ?? null);
  };

  const [listNumber, setListNumber] = useState<number[]>([]);
  useEffect(() => {
    setListNumber(
      NumberUtils.numberToList(
        franchiseBillingPage?.currentPage ?? 0,
        franchiseBillingPage?.totalCount ?? 0,
        searchData.size ?? 10
      )
    );
  }, [franchiseBillingPage, searchData]);

  const pageMove = (page: number) => {
    billingList(page, searchData ?? null);
  };

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      fontWeight: 700,
    },
  });
  const classes = useStyles();

  const [paymentDetailItems, setPaymentDetailItems] = useState<
    FranchiseBilling[]
  >([]);

  useEffect(() => {
    setPaymentDetailItems(franchiseBillingPage.list ?? []);
  }, [franchiseBillingPage]);

  return (
    <StorePaymentStatusBlock>
      {/* {toggle && (
        <PaymentStatusDetail
          role={role}
          franchiseDetailBilling={franchiseDetailBilling}
          onToggle={onToggle}
          onDetail={onDetail}
        />
      )} */}
      <div className="headers">
        <h3>정산 현황</h3>
      </div>
      <div className="tableSelect">
        <div>
          <span>Show </span>
          <select
            onChange={(e) => selectShowEntries(parseInt(e.target.value, 10))}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
          <span> entries</span>
        </div>
        <div className="topWrapper">
          <span>Search: </span>
          <PaymentSearch role={role} onSearch={onSearch} />
        </div>
      </div>
      <div className="tableWrapper">
        <TableContainer component={Paper} className="tables">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "70px" }}
                >
                  순번
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900 }}>
                  세탁공장명
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "15%" }}
                >
                  세탁신청일
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "15%" }}
                >
                  납품일
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "13%" }}
                >
                  세탁물 총 수량(개)
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "15%" }}
                >
                  결제금액(원)
                </TableCell>
                {/* <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "10%" }}
                >
                  결제상태
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentDetailItems.map((billing, index) => (
                <TableRow
                  key={index}
                  className="tableHover"
                  onClick={() => {
                    onDetail(billing.id);
                  }}
                >
                  <TableCell component="th" scope="row" align="center">
                    {listNumber[index]}
                  </TableCell>
                  <TableCell align="center">
                    {billing.factoryCompanyName}
                  </TableCell>
                  <TableCell align="center">
                    {DateFormatUtils.dateToFormat(billing.applicationDate)}
                  </TableCell>
                  <TableCell align="center">
                    {DateFormatUtils.dateToFormat(billing.completedDate)}
                  </TableCell>
                  <TableCell align="center">
                    {NumberUtils.numberToComma(billing.totalAmount ?? 0)}
                  </TableCell>
                  <TableCell align="center">
                    {NumberUtils.numberToComma(billing.price ?? 0)}
                  </TableCell>
                  {/* <TableCell align="center">
                    {PriceUtils.priceStatusToStringColor(billing.billingStatus)}
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Paging
          listAction={pageMove}
          listCount={franchiseBillingPage?.totalPage ?? 0}
          currentPage={franchiseBillingPage.currentPage}
          totalCount={franchiseBillingPage?.totalCount}
          size={searchData.size ?? 10}
        ></Paging>
      </div>
    </StorePaymentStatusBlock>
  );
};

export default StorePaymentStatus;

const StorePaymentStatusBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);

  .tables {
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  .tableSelect {
    height: 70px;
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: space-between;
    & > div {
      color: #999;
      height: 40px;
      /* background-color: red; */
      overflow: hidden;
      & > span {
        font-size: 14px;
        line-height: 37px;
      }
      & > select {
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        transition: ease-in-out;
        transition-duration: 0.1s;
      }
      & > select:hover {
        border-bottom: 2px solid ${Palette.primary};
      }
    }
  }
  .topWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    & > span {
      font-size: 14px;
      line-height: 37px;
      margin-right: 5px;
    }
    & > div {
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div {
        margin: 0;
        margin-left: 1rem;
      }
    }
  }
  .tableWrapper {
    min-width: 51rem;
    padding-bottom: 1.5rem;
  }
  .tableHover:hover {
    background-color: #eee;
    cursor: pointer;
  }
  .paymentInfo {
    min-width: 51rem;
    display: flex;
    justify-content: flex-end;
    p {
      span + span {
        margin-left: 3rem;
      }
      strong {
        font-weight: normal;
        text-decoration: underline;
      }
    }
    & > div {
      align-self: flex-end;
    }
  }
`;
