import { getBrandHistoryAlarm } from "api/userApi";
import Header from "components/common/header/Header";
import { stat } from "fs";
import { IRootState } from "modules";
import {
  getAdminHistoryAlarmAction,
  getAdminLaundryAlarmAction,
  getAlarmAllCheckAction,
  getBoardAlarmAction,
  getBrandHistoryAlarmAction,
  getBrandLaundryAlarmAction,
  getDeliveryAlarmAction,
  getLaundryCompleteAlarmAction,
  getLaundryListAcrion,
  getLaundryRequestAlarmAction,
  getNoticeAlarmAction,
  getStatementAlarmAction,
  logoutAction,
} from "modules/user/user";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RoleUtils from "utils/RoleUtils";

interface HeaderContainerProps {
  sizeButton: () => void;
  userInfoBtn: () => void;
  userInfo: boolean;
  alarmInfoBtn: () => void;
  alarmInfo: boolean;
}

const HeaderContainer: React.FC<HeaderContainerProps> = ({
  sizeButton,
  userInfoBtn,
  userInfo,
  alarmInfoBtn,
  alarmInfo,
}) => {
  const dispatch = useDispatch();
  const username = useSelector(
    (state: IRootState) => state.user.profile.nickname
  );
  const type = useSelector((state: IRootState) => state.user.profile.type);

  const profile = useSelector((state: IRootState) => state.user.profile);
  const noticeAlarm = useSelector(
    (state: IRootState) => state.user.noticeAlarm
  );
  const boardAlarm = useSelector((state: IRootState) => state.user.boardAlarm);
  const laundryCompleteAlarm = useSelector(
    (state: IRootState) => state.user.laundryCompleteAlarm
  );
  const laundryRequestAlarm = useSelector(
    (state: IRootState) => state.user.laundryRequestAlarm
  );

  const statementAlarm = useSelector(
    (state: IRootState) => state.user.statementAlarm
  );
  const deliveryAlarm = useSelector(
    (state: IRootState) => state.user.deliveryAlarm
  );

  useEffect(() => {
    dispatch(getNoticeAlarmAction());
    dispatch(getBoardAlarmAction());

    if (RoleUtils.isFactory(type)) {
      dispatch(getDeliveryAlarmAction());
      dispatch(getLaundryRequestAlarmAction());
    }
    if (RoleUtils.isStore(type)) {
      dispatch(getStatementAlarmAction());
      dispatch(getLaundryCompleteAlarmAction());
    }
    if (RoleUtils.isMaster(type)) {
      dispatch(getBrandHistoryAlarmAction());
    }
    if (RoleUtils.isMasterAdmin(type)) {
      dispatch(getAdminHistoryAlarmAction());
    }
  }, [dispatch, type]);

  const alarmAllCheck = () => {
    dispatch(getAlarmAllCheckAction(type));
  };

  const alarmReflash = () => {
    dispatch(getNoticeAlarmAction());
    dispatch(getBoardAlarmAction());

    if (RoleUtils.isFactory(type)) {
      dispatch(getDeliveryAlarmAction());
      dispatch(getLaundryRequestAlarmAction());
    }
    if (RoleUtils.isStore(type)) {
      dispatch(getStatementAlarmAction());
      dispatch(getLaundryCompleteAlarmAction());
    }
    if (RoleUtils.isMaster(type)) {
      dispatch(getBrandHistoryAlarmAction());
    }
    if (RoleUtils.isMasterAdmin(type)) {
      dispatch(getAdminHistoryAlarmAction());
    }
  };

  // useEffect(() => {}, []);

  const onLogout = () => {
    dispatch(logoutAction());
  };

  return (
    <Header
      profile={profile}
      role={type}
      username={username!}
      onLogout={onLogout}
      sizeButton={sizeButton}
      userInfoBtn={userInfoBtn}
      userInfo={userInfo}
      alarmInfoBtn={alarmInfoBtn}
      alarmInfo={alarmInfo}
      noticeAlarm={noticeAlarm}
      boardAlarm={boardAlarm}
      laundryCompleteAlarm={laundryCompleteAlarm}
      laundryRequestAlarm={laundryRequestAlarm}
      statementAlarm={statementAlarm}
      deliveryAlarm={deliveryAlarm}
      alarmAllCheck={alarmAllCheck}
      alarmReflash={alarmReflash}
    />
  );
};

export default HeaderContainer;
