import React from "react";
import styled from "styled-components";

interface DashBoardTitleWrapProps {}

const DashBoardTitleWrap: React.FC<DashBoardTitleWrapProps> = (props) => {
  return <DashBoardTitleWrapBlock>{props.children}</DashBoardTitleWrapBlock>;
};

export default DashBoardTitleWrap;

const DashBoardTitleWrapBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  /* padding-bottom: 22px; */
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  margin-bottom: 23px;
  .tables {
    margin-top: 8px;
    /* margin-bottom: 20px; */
  }
  .tableHover > td {
    padding: 10px;
  }
  .tableHover:hover {
    background-color: #eee;
    cursor: pointer;
  }
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    /* border-bottom: 1px solid black; */
  }
`;
