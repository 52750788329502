import document from "assets/icons/document.png";
import document_sel from "assets/icons/document_sel.png";
import dashboard from "assets/icons/dashboard.png";
import dashboard_sel from "assets/icons/dashboard_sel.png";
import laundry from "assets/icons/laundry.png";
import laundry_sel from "assets/icons/laundry_sel.png";
import inventory from "assets/icons/inventory.png";
import inventory_sel from "assets/icons/inventory_sel.png";
import payment from "assets/icons/payment.png";
import payment_sel from "assets/icons/payment_sel.png";
import user from "assets/icons/user.png";
import user_sel from "assets/icons/user_sel.png";
import factory from "assets/icons/factory.png";
import factory_sel from "assets/icons/factory_sel.png";
import franchise from "assets/icons/franchise.png";
import franchise_sel from "assets/icons/franchise_sel.png";
import brand from "assets/icons/brand.png";
import brand_sel from "assets/icons/brand_sel.png";
import notice from "assets/icons/notice_off.png";
import notice_sel from "assets/icons/notice_on.png";
import board from "assets/icons/board_off.png";
import board_sel from "assets/icons/board_on.png";
import location from "assets/icons/location.png";
import location_sel from "assets/icons/location_sel.png";
import access from "assets/icons/access_off.png";
import access_sel from "assets/icons/access_on.png";

export interface ISubNavigation {
  icon: string;
  icon_sel: string;
  title: string;
  path: string;
  exact: boolean;
  newTab?: boolean;
}

export const masterAdminSubNavigationList: ISubNavigation[] = [
  {
    icon: dashboard,
    icon_sel: dashboard_sel,
    title: "대시보드",
    path: "/admin/dashboard",
    exact: true,
  },
  {
    icon: notice,
    icon_sel: notice_sel,
    title: "공지사항",
    path: "/admin/notice",
    exact: false,
  },
  {
    icon: board,
    icon_sel: board_sel,
    title: "세탁 게시판",
    path: "/admin/board",
    exact: false,
  },
  {
    icon: laundry,
    icon_sel: laundry_sel,
    title: "세탁 현황",
    path: "/admin/laundry",
    exact: false,
  },
  {
    icon: payment,
    icon_sel: payment_sel,
    title: "정산 현황",
    path: "/admin/payment",
    exact: false,
  },
  {
    icon: payment,
    icon_sel: payment_sel,
    title: "정산 관리",
    path: "/admin/calculate",
    exact: false,
  },
  // {
  //   icon: payment,
  //   icon_sel: payment_sel,
  //   title: "결제 관리",
  //   path: "/admin/paymanagement/",
  //   exact: false,
  // },
  {
    icon: brand,
    icon_sel: brand_sel,
    title: "브랜드 관리",
    path: "/admin/brand",
    exact: false,
  },
  {
    icon: franchise,
    icon_sel: franchise_sel,
    title: "지점 관리",
    path: "/admin/store",
    exact: false,
  },
  {
    icon: factory,
    icon_sel: factory_sel,
    title: "공장 관리",
    path: "/admin/factory",
    exact: false,
  },
  {
    icon: document,
    icon_sel: document_sel,
    title: "거래명세표",
    path: "/admin/itemizedAccount",
    exact: false,
  },
  {
    icon: access,
    icon_sel: access_sel,
    title: "접속 기록",
    path: "/admin/accessHistory",
    exact: true,
  },

  {
    icon: location,
    icon_sel: location_sel,
    title: "위치확인",
    path: "/admin/checkLocation",
    exact: true,
    newTab: true,
  },

  // {
  //   icon: user,
  //   icon_sel: user_sel,
  //   title: "admin",
  //   path: "/admin/profile",
  //   exact: true,
  // },
];

export const masterSubNavigationList: ISubNavigation[] = [
  {
    icon: dashboard,
    icon_sel: dashboard_sel,
    title: "대시보드",
    path: "/master/dashboard",
    exact: true,
  },
  {
    icon: notice,
    icon_sel: notice_sel,
    title: "공지사항",
    path: "/master/notice",
    exact: false,
  },
  {
    icon: board,
    icon_sel: board_sel,
    title: "세탁 게시판",
    path: "/master/board",
    exact: false,
  },
  {
    icon: laundry,
    icon_sel: laundry_sel,
    title: "세탁 현황",
    path: "/master/laundry",
    exact: false,
  },
  {
    icon: payment,
    icon_sel: payment_sel,
    title: "정산 현황",
    path: "/master/payment",
    exact: false,
  },
  {
    icon: franchise,
    icon_sel: franchise_sel,
    title: "지점 관리",
    path: "/master/store",
    exact: false,
  },
  {
    icon: factory,
    icon_sel: factory_sel,
    title: "공장 관리",
    path: "/master/factory",
    exact: false,
  },
  {
    icon: inventory,
    icon_sel: inventory_sel,
    title: "자산 관리",
    path: "/master/inventory",
    exact: false,
  },
  {
    icon: document,
    icon_sel: document_sel,
    title: "거래명세표",
    path: "/master/itemizedAccount",
    exact: false,
  },
  {
    icon: location,
    icon_sel: location_sel,
    title: "위치확인",
    path: "/master/checkLocation",
    exact: true,
    newTab: true,
  },
  // {
  //   icon: user,
  //   icon_sel: user_sel,
  //   title: "admin",
  //   path: "/master/profile",
  //   exact: false,
  // },
];

export const storeSubNavigationList: ISubNavigation[] = [
  {
    icon: dashboard,
    icon_sel: dashboard_sel,
    title: "대시보드",
    path: "/store/dashboard",
    exact: true,
  },
  {
    icon: notice,
    icon_sel: notice_sel,
    title: "공지사항",
    path: "/store/notice",
    exact: false,
  },
  {
    icon: board,
    icon_sel: board_sel,
    title: "세탁 게시판",
    path: "/store/board",
    exact: false,
  },
  {
    icon: laundry,
    icon_sel: laundry_sel,
    title: "세탁 현황",
    path: "/store/laundry",
    exact: false,
  },
  {
    icon: payment,
    icon_sel: payment_sel,
    title: "정산 현황",
    path: "/store/payment",
    exact: false,
  },
  {
    icon: inventory,
    icon_sel: inventory_sel,
    title: "자산 관리",
    path: "/store/inventory",
    exact: false,
  },
  {
    icon: document,
    icon_sel: document_sel,
    title: "거래명세표",
    path: "/store/itemizedAccount",
    exact: false,
  },
  {
    icon: location,
    icon_sel: location_sel,
    title: "위치확인",
    path: "/store/checkLocation",
    exact: true,
    newTab: true,
  },
  // {
  //   icon: user,
  //   icon_sel: user_sel,
  //   title: "admin",
  //   path: "/store/profile",
  //   exact: false,
  // },
];

export const factorySubNavigationList: ISubNavigation[] = [
  {
    icon: dashboard,
    icon_sel: dashboard_sel,
    title: "대시보드",
    path: "/factory/dashboard",
    exact: true,
  },
  {
    icon: notice,
    icon_sel: notice_sel,
    title: "공지사항",
    path: "/factory/notice",
    exact: false,
  },
  {
    icon: board,
    icon_sel: board_sel,
    title: "세탁 게시판",
    path: "/factory/board",
    exact: false,
  },
  {
    icon: laundry,
    icon_sel: laundry_sel,
    title: "세탁 신청",
    path: "/factory/self",
    exact: true,
  },
  {
    icon: laundry,
    icon_sel: laundry_sel,
    title: "테이블리더기",
    path: "/factory/tablereader",
    exact: false,
  },
  {
    icon: laundry,
    icon_sel: laundry_sel,
    title: "세탁 현황",
    path: "/factory/laundry",
    exact: false,
  },
  {
    icon: payment,
    icon_sel: payment_sel,
    title: "정산 현황",
    path: "/factory/payment",
    exact: false,
  },
  {
    icon: document,
    icon_sel: document_sel,
    title: "라벨 출력",
    path: "/factory/label",
    exact: false,
  },
  {
    icon: document,
    icon_sel: document_sel,
    title: "거래명세표",
    path: "/factory/itemizedAccount",
    exact: false,
  },
  {
    icon: location,
    icon_sel: location_sel,
    title: "위치확인",
    path: "/factory/checkLocation",
    exact: true,
    newTab: true,
  },
  // {
  //   icon: user,
  //   icon_sel: user_sel,
  //   title: "admin",
  //   path: "/factory/profile",
  //   exact: false,
  // },
];
