import Button from "components/common/Button";
import { AdminStoreDetail } from "modules/admin/types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DateFormatUtils from "utils/DateFormatUtils";
import NumberUtils from "utils/NumberUtils";

import { Palette, ThemeColor } from "styles/palette";
import { CenterAligned } from "components/common/layouts/CenterAligned";
import DaumPostCode from "components/common/DaumPostCode";
import { ProfileModify } from "modules/user/types";
import TypeUtils from "utils/TypeUtils";

interface AdminStoreStatusDetailProps {
  onClose: () => void;
  adminStoreDetail: AdminStoreDetail | null;
  onModifyStoreUserName: (id: number, userName: string) => void;
  postPWInitialization: (id: number) => void;
  historyBack: () => void;
  franchiseInfoModify: (payload: ProfileModify) => void;
  adminIdDuplicateCheck: string | null;
  duplicateCheckRequest: (id: string) => void;
  duplicateCheckCancel: () => void;
  onDelete: () => void;
}

const AdminStoreStatusDetail: React.FC<AdminStoreStatusDetailProps> = ({
  adminStoreDetail,
  onClose,
  onModifyStoreUserName,
  postPWInitialization,
  historyBack,
  franchiseInfoModify,
  adminIdDuplicateCheck,
  duplicateCheckRequest,
  duplicateCheckCancel,
  onDelete,
}) => {
  const AdminStoreStatusDetailHeader: string[] = [
    "대분류",
    "소분류",
    "품명",
    "사이즈",
    "비고",
    "수량(개)",
  ];
  // const [payMethod, setPayMethod] = useState<string>("unit");

  const [duplicateCheck, setDuplicateCheck] = useState<string>("N");

  // const [storeDetailItems, setStoreDetailItems] = useState<Asset[]>([]);
  // const [onModi, setOnModi] = useState<boolean>(false);

  const [onInfoModi, setOnInfoModi] = useState<boolean>(false);
  const [postCodeCheck, setPostCodeCheck] = useState(false);

  const [companyName, setCompanyName] = useState<string>("");
  const [companyPhone, setCompanyPhone] = useState<string>();
  const [companyStreetDetail, setCompanyStreetDetail] = useState<string>("");
  const [companyManagerName, setCompanyManagerName] = useState<string>("");
  const [companyManagerPhone, setCompanyManagerPhone] = useState<string>("");

  const [zoneCode, setZoneCode] = useState<string>("");
  const [fullAddress, setFullAddress] = useState<string>("");

  const [defaultState, setDefaultState] = useState({
    newUserName: "",
    companyName: "",
    companyPhone: "",
    zoneCode: "",
    fullAddress: "",
    companyStreetDetail: "",
    companyManagerName: "",
    companyManagerPhone: "",
  });

  const onAddress = (zoneCode: string, fullAddress: string) => {
    setZoneCode(zoneCode);
    setFullAddress(fullAddress);
    setPostCodeCheck(false);
  };

  // useEffect(() => {
  //   setStoreDetailItems(adminStoreDetail?.assetResponseList ?? []);
  // }, [adminStoreDetail]);

  useEffect(() => {
    setNewUserName(adminStoreDetail?.franchiseResponse.manager.username ?? "-");
    setCompanyName(adminStoreDetail?.franchiseResponse.companyName ?? "-");
    setCompanyPhone(adminStoreDetail?.franchiseResponse.companyPhone ?? "-");
    setZoneCode(adminStoreDetail?.franchiseResponse.companyAddress?.postCode ?? "-");
    setFullAddress(adminStoreDetail?.franchiseResponse.companyAddress?.street ?? "-");
    setCompanyStreetDetail(
      adminStoreDetail?.franchiseResponse.companyAddress?.streetDetails ?? "-"
    );
    setCompanyManagerName(adminStoreDetail?.franchiseResponse.manager?.nickname ?? "-");
    setCompanyManagerPhone(adminStoreDetail?.franchiseResponse.manager?.phone ?? "-");
    if (adminStoreDetail)
      // setPayMethod(adminStoreDetail.franchiseResponse.payment);
      setDefaultState({
        newUserName: adminStoreDetail?.franchiseResponse.manager.username ?? "",
        companyName: adminStoreDetail?.franchiseResponse.companyName ?? "",
        companyPhone: adminStoreDetail?.franchiseResponse.companyPhone ?? "",
        zoneCode: adminStoreDetail?.franchiseResponse.companyAddress.postCode ?? "",
        fullAddress: adminStoreDetail?.franchiseResponse.companyAddress.street ?? "",
        companyStreetDetail: adminStoreDetail?.franchiseResponse.companyAddress.streetDetails ?? "",
        companyManagerName: adminStoreDetail?.franchiseResponse.manager.nickname ?? "",
        companyManagerPhone: adminStoreDetail?.franchiseResponse.manager.phone ?? "",
      });
  }, [adminStoreDetail]);

  const [newUserName, setNewUserName] = useState<string | null>(null);

  // const onSubmit = (id: number) => {
  //   if (!newUserName) return alert("빈값은 입력할 수 없습니다");
  //   if (window.confirm("아이디를 변경하싰겠습니까?")) {
  //     onModifyStoreUserName(id, newUserName!);
  //     setOnModi(false);
  //     onClose();
  //   }
  // };

  const onCloseModify = () => {
    setOnInfoModi(false);
    setNewUserName(defaultState.newUserName);
    setCompanyName(defaultState.companyName);
    setCompanyPhone(defaultState.companyPhone);
    setZoneCode(defaultState.zoneCode);
    setFullAddress(defaultState.fullAddress);
    setCompanyStreetDetail(defaultState.companyStreetDetail);
    setCompanyManagerName(defaultState.companyManagerName);
    setCompanyManagerPhone(defaultState.companyManagerPhone);
  };

  const modifySubmit = () => {
    if (defaultState.newUserName !== newUserName && duplicateCheck === "N")
      return alert("중복된 아이디 입니다. 확인해주세요.");
    let address = {
      postCode: zoneCode!,
      street: fullAddress!,
      streetDetails: companyStreetDetail!,
      isLoadName: true,
    };

    let payload = {
      id: newUserName!,
      companyName: companyName!,
      phone: companyPhone!,
      address: address!,
      managerName: companyManagerName!,
      managerPhone: companyManagerPhone!,
    };
    if (window.confirm("정보를 수정하시겠습니까?")) {
      franchiseInfoModify(payload);
      setOnInfoModi(false);
    }
  };

  useEffect(() => {
    duplicateCheckCancel();
  }, []);

  useEffect(() => {
    setDuplicateCheck(adminIdDuplicateCheck ?? "N");
  }, [adminIdDuplicateCheck]);

  const onStoreDelete = () => {
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      onDelete();
    }
  };

  return (
    <AdminStoreStatusDetailBlock>
      {postCodeCheck && (
        <div className="postCode">
          <div>
            <DaumPostCode onAddress={onAddress} />
            <CenterAligned>
              <Button
                theme={ThemeColor.tertiary}
                buttonSize="m"
                onClick={() => setPostCodeCheck(false)}
                className="postCodeCloseBtn"
              >
                닫기
              </Button>
            </CenterAligned>
          </div>
        </div>
      )}
      <div className="headers">
        <h3>지점 상세</h3>
        <Button theme={ThemeColor.primary} buttonSize="m" onClick={historyBack}>
          뒤로가기
        </Button>
      </div>

      <div className="contentWrapper">
        <div className="detailInfo">
          <div className="field">
            <span>아이디</span>
            <div>
              <input
                value={newUserName ?? ""}
                onChange={(e) => setNewUserName(e.target.value)}
                className="changeId"
                disabled={!onInfoModi}
              />
              {onInfoModi && (
                <Button
                  theme={ThemeColor.primary}
                  buttonSize="m"
                  className="changeIdBtn"
                  onClick={() => duplicateCheckRequest(newUserName!)}
                >
                  중복체크
                </Button>
              )}
            </div>
          </div>
          <div className="field">
            <span>{""}</span>
            <Button
              theme={ThemeColor.primary}
              buttonSize="m"
              className="PWBtn"
              onClick={() =>
                postPWInitialization(Number(adminStoreDetail!.franchiseResponse.manager.id))
              }
            >
              비밀번호초기화
            </Button>
          </div>
          <div className="field">
            <span>지점명</span>
            {onInfoModi ? (
              <input value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
            ) : (
              <p>{adminStoreDetail?.franchiseResponse.companyName}</p>
            )}
          </div>
          <div className="field">
            <span>연락처</span>
            {onInfoModi ? (
              <input value={companyPhone} onChange={(e) => setCompanyPhone(e.target.value)} />
            ) : (
              <p>{adminStoreDetail?.franchiseResponse.companyPhone}</p>
            )}
          </div>
          <div className="field secoundField">
            <span>주소</span>
            {onInfoModi ? (
              <>
                <input value={zoneCode} disabled />
                <Button
                  theme={ThemeColor.primary}
                  buttonSize="m"
                  onClick={() => setPostCodeCheck(true)}
                >
                  찾기
                </Button>
              </>
            ) : (
              <p>{adminStoreDetail?.franchiseResponse.companyAddress.postCode}</p>
            )}
          </div>
          <div className="field">
            <span></span>
            {onInfoModi ? (
              <input value={fullAddress} disabled />
            ) : (
              <p>{adminStoreDetail?.franchiseResponse.companyAddress.street}</p>
            )}
          </div>
          <div className="field">
            <span></span>
            {onInfoModi ? (
              <input
                value={companyStreetDetail}
                onChange={(e) => setCompanyStreetDetail(e.target.value)}
              />
            ) : (
              <p>{adminStoreDetail?.franchiseResponse.companyAddress.streetDetails}</p>
            )}
          </div>

          {/* <div className="field">
            <span>최근 신청일</span>
            <p>
              {DateFormatUtils.dateToFormat(
                adminStoreDetail?.franchiseResponse.lastOrderDate
              )}
            </p>
          </div>
          <div className="field">
            <span>누적 세탁신청</span>
            <p>
              {NumberUtils.numberToComma(
                adminStoreDetail?.franchiseResponse.totalLaundryCount ?? 0
              )}
            </p>
          </div> */}
          {/* <div className="paymentMethod field">
            <span>결제방식</span>
            <div>
              <label className={payMethod === "unit" ? "active" : ""}>
                <input
                  type="radio"
                  value="unit"
                  name="paymentMethod"
                  checked={payMethod === "unit"}
                  onChange={(e) => {
                    // setPayMethod(e.target.value);
                  }}
                />
                <span>단가제</span>
              </label>
              <label className={payMethod === "month" ? "active" : ""}>
                <input
                  type="radio"
                  value="month"
                  name="paymentMethod"
                  checked={payMethod === "month"}
                  onChange={(e) => {
                    // setPayMethod(e.target.value);
                  }}
                />
                <span>월정액</span>
              </label>
            </div>
          </div> */}
          {/* <div className="field">
            <span>총 결제금액(원)</span>
            <p>
              {adminStoreDetail?.franchiseResponse.totalPrice
                ? NumberUtils.numberToComma(
                    adminStoreDetail.franchiseResponse.totalPrice ?? 0
                  )
                : "-"}
            </p>
          </div> */}
          <div className="field">
            <span>담당자명</span>
            {onInfoModi ? (
              <input
                value={companyManagerName}
                onChange={(e) => setCompanyManagerName(e.target.value)}
              />
            ) : (
              <p>{adminStoreDetail?.franchiseResponse.manager.nickname}</p>
            )}
          </div>
          <div className="field">
            <span>담당자연락처</span>
            {onInfoModi ? (
              <input
                value={companyManagerPhone}
                onChange={(e) => setCompanyManagerPhone(e.target.value)}
              />
            ) : (
              <p>{adminStoreDetail?.franchiseResponse.manager.phone}</p>
            )}
          </div>
          <div className="field">
            <span>계약기준</span>
            <p>
              {adminStoreDetail?.franchiseResponse.contract
                ? TypeUtils.payManagementContactType(
                    adminStoreDetail?.franchiseResponse.contract.basis
                  )
                : "-"}
            </p>
          </div>
          {adminStoreDetail?.franchiseResponse.contract &&
          adminStoreDetail?.franchiseResponse.contract.basis === "UNIT_PRICE" ? (
            <div className="field">
              <span>산출방식</span>
              <p>
                {adminStoreDetail?.franchiseResponse.contract
                  ? TypeUtils.payManagementSubType(
                      adminStoreDetail?.franchiseResponse.contract.type
                    )
                  : "-"}
              </p>
            </div>
          ) : (
            <div className="field">
              <span>계약금액(원)</span>
              <p>
                {adminStoreDetail?.franchiseResponse.contract
                  ? NumberUtils.numberToComma(adminStoreDetail?.franchiseResponse.contract.price)
                  : "-"}
              </p>
            </div>
          )}

          <div className="field">
            <span>정산기간</span>
            <p>
              {adminStoreDetail?.franchiseResponse.contract
                ? `${adminStoreDetail?.franchiseResponse.contract.startDay} 일 ~ ${
                    adminStoreDetail?.franchiseResponse.contract.lastDay !== 0
                      ? "익월 " + adminStoreDetail?.franchiseResponse.contract.lastDay
                      : "이달 말"
                  } 일`
                : "-"}
            </p>
          </div>
          <div className="field">
            <span>가입일</span>
            <p>{DateFormatUtils.dateToFormat(adminStoreDetail?.franchiseResponse.joinDate)}</p>
          </div>
          <div className="buttonWrapper">
            {!onInfoModi ? (
              <>
                <Button theme={ThemeColor.warning} buttonSize="m" onClick={onStoreDelete}>
                  삭제
                </Button>
                <Button
                  theme={ThemeColor.primary}
                  buttonSize="m"
                  onClick={() => setOnInfoModi(true)}
                >
                  수정
                </Button>
              </>
            ) : (
              <>
                <Button theme={ThemeColor.primary} buttonSize="m" onClick={modifySubmit}>
                  저장
                </Button>
                <Button theme={ThemeColor.primary} buttonSize="m" onClick={onCloseModify}>
                  취소
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="stockStatus">
          <div>
            <h3>재고현황</h3>
            <div className="stockInField">
              <div>
                <div className="stockHeader">
                  {AdminStoreStatusDetailHeader.map((item, key) => (
                    <span key={key}>{item}</span>
                  ))}
                </div>
                <div className="stockList">
                  {adminStoreDetail?.assetResponseList.map((item) => (
                    <div className="stockField" key={item.id}>
                      <span>{item.details.largeCategory}</span>
                      <span>{item.details.subCategory}</span>
                      <span>{item.details.name}</span>
                      <span>{item.details.size ?? "-"}</span>
                      <span>{item.details.content ?? "-"}</span>
                      <span>{NumberUtils.numberToComma(item.assetAmount ?? 0)}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminStoreStatusDetailBlock>
  );
};

export default AdminStoreStatusDetail;

const AdminStoreStatusDetailBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  & > .contentWrapper {
    width: 100%;
    height: 830px;

    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    & > div {
      width: 35%;
    }
    & > div:nth-child(2) {
      width: 65%;
      border-left: 1px solid black;
    }
    & > .detailInfo {
      width: auto;
      margin: 0 auto;

      & > .field {
        width: 100%;
        min-height: 40px;

        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
        & > span {
          width: 140px;
        }
        & > p,
        input {
          border: 1px solid #ddd;
          font-size: 14px;
          border-radius: 5px;
          height: 100%;
          width: 260px;
          line-height: 35px;
          margin: 0;
          padding-left: 10px;
        }
        & > input {
          height: 37px;
        }
        & > div {
          & > .changeId {
            width: 179px;
            border: 1px solid #ddd;
            margin-right: 5px;
            height: 37px;
          }
        }
        & > .PWBtn {
          width: 260px;
        }
      }
      & > .secoundField {
        & > p,
        input {
          width: 174px;
          margin-right: 10px;
        }
      }
      & > .buttonWrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 50px;
      }
    }
    & > .stockStatus {
      height: 760px;
      & > div {
        width: 90%;
        min-width: 690px;
        height: auto;
        margin: 0 auto;
        & > h3 {
          margin: 0;
        }
        & > .stockInField {
          margin-top: 20px;
          & > div {
            width: 100%;
            height: 710px;
            border-radius: 10px;
            background-color: #ecf0f5;
            padding: 20px 5px;
            overflow: hidden;
            & > .stockHeader {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-right: 15px;
              & > span {
                width: calc(100% / 6);
                text-align: center;
                font-size: 13px;
                word-wrap: break-word;
              }
            }
            & > .stockList {
              overflow-y: scroll;
              height: 95%;
              margin-top: 20px;
              & > div {
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #fff;
                padding: 2px 0;
                & > span {
                  width: calc(100% / 6);
                  text-align: center;
                  font-size: 13px;
                  word-wrap: break-word;
                }
              }
            }
          }
        }
      }
    }
  }
  .postCode {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    & > div {
      width: 30rem;
      & > div {
        & > .postCodeCloseBtn {
          margin-top: 1rem;
        }
      }
    }
  }
  /* .stockStatus {
        height: 140px;
        margin-top: 130px;
        display: grid;
        grid-template-columns: 6rem 1fr;
        column-gap: 1rem;
        .stockInCategory {
            background-color: #fff;
            overflow-y: scroll;
            padding: 10px;

            .categoryField {
                height: 30px;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                align-items: center;
                text-align: center;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        .stockInField {
            width: 100%;
            min-height: 140px;
            background-color: ${Palette.white};

            .stockHeader {
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                text-align: center;
                align-items: center;
                padding: 5px;
                border-bottom: 1px solid #eeeeee;
            }

            .stockList {
                overflow-y: scroll;
                height: 110px;
            }
            .stockField {
                display: grid;
                padding: 5px 0;
                grid-template-columns: repeat(6, 1fr);
                text-align: center;
                align-items: center;
            }
            .stockField:nth-child(2n) {
                background-color: #eee;
            }
        }
    }
    .paymentMethod {
        display: grid;
        grid-template-columns: 8rem 1fr;
        & > div {
            display: flex;
            justify-content: space-between;
            & > label {
                width: 45%;
                padding: 0 0.5rem;
                margin: 0;
                border-radius: 5px;
                border: 1px solid #ccc;
                color: #000000;
                background-color: #f0f0f0;
                & > input {
                    display: none;
                }
                & > span {
                    display: block;
                    text-align: center;
                    line-height: 2.1rem;
                }
                &.active {
                    color: #ffffff;
                    background-color: #296043;
                }
            }
        }
    }
    .changeId {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid #bebebe;
    }
    .changeIdBtn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding: 8.7px 0;
    }
  }
  .changeId {


    border: 1px solid #bebebe;
  }
  .changeIdBtn {
    width: 50px;

    padding: 8.7px 0;
  } */
`;
