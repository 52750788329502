// you were trying to do the controlled component
// gaps and invalid dates cause problems

import React, {
  forwardRef,
  useReducer,
  useRef,
  useLayoutEffect,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { DAY, isIsoDateValid, getLanguage } from "./util";

// increments and decrements date
function delta(delta: any, { value, min, max }: any) {
  const sum = value === undefined && max > 2000 ? new Date().getFullYear() : (value || 0) + delta;
  return sum > max ? min : sum < min ? max : sum;
}

function onDigit(value: any, digit: any, max: any) {
  // bigger than max return max
  if (parseInt(`${value || ""}${digit}`, 10) > max) return [max, true];
  return [
    parseInt(`${value || ""}${digit}`.substr(-`${max}`.length), 10),
    parseInt(`${value || ""}${digit}0`, 10) > max,
  ];
}

//just formatting
function leftPad(value: any, length: any) {
  let out = "" + value;
  while (out.length < length) {
    out = "0" + out;
  }
  return out;
}

function renderItem({ value, placeholder }: any) {
  return value === undefined ? placeholder : leftPad(value, placeholder.length);
}

const dd = { type: "date", placeholder: "--", min: 1, max: 31, pad: 2 };
const mm = { type: "month", placeholder: "--", min: 1, max: 12, pad: 2 };
const yyyy = {
  type: "year",
  placeholder: "----",
  min: 2016,
  max: 3000,
  pad: 4,
};

const initalState = {
  items: getLanguage() === "en-US" ? [mm, dd, yyyy] : [dd, mm, yyyy],
};

//use controlled state as init state if it exists and is valid
function valueOrState(state: any, value: any) {
  if (value !== undefined && /\d{1,6}-\d\d-\d\d/.test(value) && isIsoDateValid(value)) {
    // const _date: any = new Date(
    //   value.split("-")
    //   // Date.UTC(
    //   //   ...value.split("-").map((x: any, i: any) => (i === 1 ? x - 1 : x))
    //   // )
    // );

    const _date: any = new Date(value);
    const _lookup: any = {
      year: _date.getUTCFullYear(),
      month: _date.getUTCMonth() + 1,
      date: _date.getUTCDate(),
    };

    return {
      ...state,
      items: state.items.map((item: any) => ({
        ...item,
        value: _lookup[item.type],
      })),
    };
  }
  return state;
}

export const SpinButtons = forwardRef((props: any, ref: any) => {
  const elRef = useRef<any>();
  const onChangeQueue = useRef<any>([]);
  const { setIsOpen, onChange, value, defaultValue }: any = props;
  // if defaultValue pass that on to initial state
  // if value do not dispatch actions just onChange
  // show value as value
  // const isUs = getLanguage() === "en-US";

  const [state, dispatch] = useReducer(reducer, valueOrState(initalState, value || defaultValue));

  function change(state: any, action: any) {
    const next = reducer(state, action);
    dispatch(action);
    const before = state.items
      .map(({ value, type }: any) => (type === "month" ? value + 1 : value))
      .join("-");
    const after = next.items.map(({ value, type }: any) => (type === "month" ? value + 1 : value));

    if (before !== after) {
      const evt = next.items.reduce((acc: any, item: any) => {
        acc[item.type] = item.value;
        return acc;
      }, {});
      onChangeQueue.current.push(evt);
    }
  }

  useEffect(() => {
    if (onChangeQueue.current.length) {
      onChangeQueue.current.forEach((e: any) => onChange(e));
    }
    onChangeQueue.current = [];
  });

  useLayoutEffect(() => {
    //  console.log((new Date(value).getTime()) / DAY, value,new Date(value).getTime() / DAY)

    const _date = new Date(value);
    // console.log("value", new Date(value).getTime() / DAY);
    if (
      value !== undefined &&
      !isNaN(_date.getTime()) &&
      _date.toISOString().replace(/T.*/, "") === value
    ) {
      dispatch({
        type: "setFromDayNumber",
        dayNumber: new Date(value).getTime() / DAY,
      });
    }
  }, [value]);

  function onKeyDown(e: any) {
    switch (e.keyCode) {
      case 13: {
        e.preventDefault();
        setIsOpen(true);
        break;
      }
      case 40: {
        e.preventDefault();
        const action = { type: "delta", value: -1 };
        change(state, action);
        break;
      }
      case 38: {
        e.preventDefault();
        const action = { type: "delta", value: 1 };
        change(state, action);
        break;
      }
      case 37: {
        e.preventDefault();
        dispatch({
          type: "select",
          index: Math.max(state.selectedIndex - 1, 0),
        });
        break;
      }
      case 9: {
        if (e.shiftKey) {
          e.preventDefault();
          dispatch({
            type: "select",
            index: Math.max(state.selectedIndex - 1, 0),
          });
        } else {
          //tab out
          if (state.selectedIndex < state.items.length - 1) {
            e.preventDefault();
          }
          dispatch({
            type: "select",
            index: Math.min(state.selectedIndex + 1, state.items.length - 1),
          });
        }
        break;
      }
      case 39: {
        e.preventDefault();
        dispatch({
          type: "select",
          index: Math.min(state.selectedIndex + 1, state.items.length - 1),
        });
        break;
      }
      case 48:
      case 49:
      case 50:
      case 51:
      case 52:
      case 53:
      case 54:
      case 55:
      case 56:
      case 57: {
        const digit = e.keyCode - 48;
        e.preventDefault();
        const action = {
          type: "digit",
          digit,
        };
        change(state, action);
        break;
      }
      case 8:
      case 46:
        e.preventDefault();
        change(state, { type: "clear" });
        break;
      default:
    }
  }

  function reducer(state: any, action: any) {
    switch (action.type) {
      case "setFromDayNumber": {
        const _date = new Date(action.dayNumber * DAY);
        return {
          ...state,
          items: state.items.map((item: any) => {
            return {
              ...item,
              value:
                item.type === "date"
                  ? _date.getUTCDate()
                  : item.type === "month"
                  ? _date.getUTCMonth() + 1
                  : _date.getUTCFullYear(),
            };
          }),
        };
      }

      case "select":
        return {
          ...state,
          selectedIndex: action.index,
          items: state.items.map((item: any, i: any) => {
            //const [min, max] = getMinMax(state.items, item.type)
            return i === state.selectedIndex
              ? {
                  ...item,
                  retain: false,
                }
              : item;
          }),
        };

      case "delta": {
        return {
          ...state,
          items: state.items.map((item: any, i: any) =>
            i === state.selectedIndex
              ? {
                  ...item,
                  value: delta(action.value, item),
                  retain: false,
                }
              : item
          ),
        };
      }
      case "digit": {
        let _selectNext = false;
        return {
          ...state,
          items: state.items.map((item: any, i: any) => {
            if (i === state.selectedIndex) {
              const [nextValue, selectNext] = onDigit(
                item.retain ? item.value : undefined,
                action.digit,
                item.max
                // false
              );
              // careful mutable value set in a map
              _selectNext = _selectNext || selectNext;
              return {
                ...item,
                value: nextValue,
                retain: !selectNext,
              };
            } else return item;
          }),
          selectedIndex: _selectNext
            ? Math.min(state.selectedIndex + 1, state.items.length - 1)
            : state.selectedIndex,
        };
      }

      case "clear": {
        return {
          ...state,
          items: state.items.map((item: any, i: any) => {
            if (i === state.selectedIndex) {
              return {
                ...item,
                value: undefined,
              };
            } else return item;
          }),
        };
      }
      default:
        return state;
    }
  }

  useImperativeHandle(ref, () => ({
    setDayNumber: (dayNumber: any) => {
      dispatch({ type: "setFromDayNumber", dayNumber });
    },
    focus: () => elRef.current.focus(),
  }));

  //   const [useDate, setUseDate] = useState<string>("");

  useEffect(() => {
    let day = state.items[0].value;
    let month = state.items[1].value;
    let year = state.items[2].value;
    let data = `${year}-${month}-${day}`;
    props.setSearchDate(data);
  }, [state.items]);

  function spinItem(index: any) {
    return (
      <span
        onClick={() => dispatch({ type: "select", index })}
        // style={state.selectedIndex === index ? { background: "#ccc" } : {}}
      >
        {renderItem(state.items[index])}
      </span>
    );
  }

  return (
    <div
      tabIndex={3}
      className="test13"
      style={{
        position: "relative",
        border: "1px solid #dee2e6",
        width: 150,
        height: "100%",
        padding: "8.8px 6px",
        fontSize: `14px`,
        borderRadius: "3px",
        cursor: "pointer",
      }}
      onKeyDown={onKeyDown}
      onClick={(e) => {
        // 데이트픽커 여는 부분
        if (props.clickCheck && props.clickCheck === "check")
          setIsOpen(
            e.target === elRef.current || e.currentTarget.childNodes === elRef.current.childNodes
          );
        if (!props.clickCheck)
          setIsOpen(
            e.target === elRef.current || e.currentTarget.childNodes === elRef.current.childNodes
          );
      }}
      onFocus={() =>
        dispatch({
          type: "select",
          index: 0,
        })
      }
      ref={elRef}
    >
      {spinItem(2)} 년 {spinItem(1)} 월 {spinItem(0)} 일
    </div>
  );
});
