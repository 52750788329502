import ProfileStatusContainer from "containers/common/ProfileStatusContainer";
import React from "react";
import styled from "styled-components";

interface AdminProfileProps {}

const AdminProfile: React.FC<AdminProfileProps> = () => {
  return (
    <AdminProfileBlock>
      <ProfileStatusContainer />
    </AdminProfileBlock>
  );
};

const AdminProfileBlock = styled.div``;

export default AdminProfile;
