import LaundrySearch from "components/common/LaundrySearch";
import Button from "components/common/Button";
import {
  MasterGetList,
  MasterLaundryDetail,
  MasterLaundryList,
} from "modules/master/types";
import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { Palette, ThemeColor } from "styles/palette";
import DatePicker from "react-datepicker";
import DateFormatUtils from "utils/DateFormatUtils";
import NumberUtils from "utils/NumberUtils";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import moment from "moment";
import dummy from "./dummy.json";

interface MasterLaundryStatusProps {
  role: string;
}

const MasterLaundryStatus: React.FC<MasterLaundryStatusProps> = ({ role }) => {
  const [franchiseName, setFranchiseName] = useState<string>("");
  const [startDate, setStartDate] = useState<moment.Moment>(() => moment());
  const [endDate, setEndDate] = useState<moment.Moment>(() => moment());
  const [dateArr, setDateArr] = useState<string[]>([]);
  const [sumCount, setSumCount] = useState<number[]>([]);
  const [sumPrice, setSumPrice] = useState<number[]>([]);

  useEffect(() => {
    dateCreate();

    let dataList = dummy.data;
    let sumList = [];
    let priceSumList = [];
    for (let i = 0; i < dataList.length; i++) {
      let sum = 0;

      for (let n = 0; n < dataList[i].list.length; n++) {
        sum = sum + dataList[i].list[n].count;
      }
      sumList.push(sum);
      priceSumList.push(dataList[i].price * sum);
    }
    setSumCount(sumList);
    setSumPrice(priceSumList);
  }, []);

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      fontWeight: 700,
    },
  });
  const classes = useStyles();

  const submitSearch = () => {
    let term = DateFormatUtils.dateTerm(startDate, endDate);

    if (term > 31) {
      alert("1개월을 초과하여 선택 할 수 없습니다");
      return;
    }
  };

  const dateCreate = (sd?: string, ed?: string) => {
    if (!sd) sd = "2021-08-01";
    if (!ed) ed = "2021-08-30";
    var regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
    if (!(regex.test(sd) && regex.test(ed))) return "Not Date Format";
    var result = [];
    var curDate = new Date(sd);
    while (curDate <= new Date(ed)) {
      result.push(curDate.toISOString().split("T")[0]);
      curDate.setDate(curDate.getDate() + 1);
    }

    setDateArr(result);
  };

  return (
    <MasterPaymentStatusBlock>
      <div className="headers">
        <h3 onClick={() => dateCreate()}>세탁집계표</h3>
      </div>
      <div>
        <h4>예스병원 貴中</h4>
        <h5>세탁기간 :</h5>
      </div>
      <LaundryTable>
        <table>
          <thead>
            <tr className={"title"}>
              <th>No.</th>
              <th>품목</th>
              {dateArr.map((date, index) => (
                <th key={index}>{moment(date).format("M/D")}</th>
              ))}
              <th>총 수량</th>
              <th>단가</th>
              <th>총 발생액</th>
            </tr>
            <tr>
              <th colSpan={2}>합계</th>
              {dateArr.map((date, index) => (
                <th key={index}>{moment(date).format("D")}</th>
              ))}
              <th>0</th>
              <th></th>
              <th>0</th>
            </tr>
          </thead>
          <tbody>
            {dummy.data.map((val, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td className={"title"}>{val.title}</td>
                {val.list.map((item, idx) => (
                  <td key={item.date}>
                    {item.count ? NumberUtils.numberToComma(item.count) : "-"}
                  </td>
                ))}
                <td>{sumCount[index]}</td>
                <td>{val.price}</td>
                <td>
                  {sumPrice[index]
                    ? NumberUtils.numberToComma(sumPrice[index])
                    : "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={"footer"}>
          공급자 ㈜크린코리아 | (대표자) 김웅기 | (등록번호) 812-86-01992 |
          (주소) 경기도 김포시 하성면 원산로 45-19 | (TEL) 031-997-9090 | (FAX)
          031-997-9030
        </div>
      </LaundryTable>
    </MasterPaymentStatusBlock>
  );
};

export default MasterLaundryStatus;

const MasterPaymentStatusBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);

  .tables {
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .tableSelect {
    height: 70px;
    display: flex;
    padding-top: 12px;
    align-items: center;
    justify-content: flex-end;
    & > div {
      color: #999;
      height: 40px;
      /* background-color: red; */
      overflow: hidden;
      & > select {
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        transition: ease-in-out;
        transition-duration: 0.1s;
      }
      & > select:hover {
        border-bottom: 2px solid ${Palette.primary};
      }
    }
  }
  .topWrapper {
    display: flex;
    justify-content: flex-end;
    & > div {
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div {
        margin: 0;
        margin-left: 1rem;
      }
    }
  }
  .tableWrapper {
    min-width: 51rem;
    max-width: 100%;
    overflow-x: scroll;
    padding-bottom: 1.5rem;
  }
  .tableHover:hover {
    background-color: #eee;
  }
`;

const LaundryTable = styled.div`
  & > table {
    width: 1414px;
    height: 1000px;
    border-collapse: collapse;
    & > thead {
      & > tr {
        background-color: #ffbe26;
        & > th {
          border: 1px solid #000;
        }
        &.title {
          background-color: #26d4ff;
        }
      }
    }
    & > tbody {
      & > tr {
        & > td {
          border: 1px solid #000;
          text-align: center;
          &.title {
            text-align: left;
          }
        }
      }
    }
  }
  & > .footer {
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
    line-height: 40px;
  }
`;

const PaymentSearchBlock = styled.form`
  display: inline-block;
  height: auto;
  & > span {
    color: #999;
  }
  & > select {
    border: 0;
    height: 100%;
    margin-left: 10px;
  }
  & > input {
    border: 0;
    height: 100%;
  }

  & > .selectBox,
  .textBox {
    border: 1px solid #dee2e6;
    box-sizing: border-box;
    transition: ease-in-out;
    transition-duration: 0.1s;
    margin-right: 5px;
    border-radius: 3px;
  }
  & > .selectBox:hover,
  .textBox:hover {
    border-bottom: 2px solid ${Palette.primary};
  }
  & > .datePicker {
    display: inline-block;
  }
`;
const DatePickerBlock = styled(DatePicker)`
  width: 140px;
  height: 100%;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  transition: ease-in-out;
  transition-duration: 0.1s;
  margin-right: 5px;
  cursor: default;
  &:hover {
    border-bottom: 2px solid ${Palette.primary};
  }
`;
