import React, { useEffect } from "react";
import styled from "styled-components";

import Button from "./Button";
import { StatusDetailBlock } from "./LaundaryStatusDetail";
import { useState } from "react";
import RoleUtils from "utils/RoleUtils";
import { FranchiseDetailBilling } from "modules/store/types";
import { ThemeColor } from "styles/palette";
import { CenterAligned } from "./layouts/CenterAligned";
import ScrollUtils from "utils/ScrollUtils";
import DateFormatUtils from "utils/DateFormatUtils";
import NumberUtils from "utils/NumberUtils";
import PriceUtils from "utils/PriceUtils";

interface PaymentStatusDetailProps {
  role: string;
  franchiseDetailBilling: FranchiseDetailBilling | null;
  onClose: () => void;
}

const PaymentStatusDetail: React.FC<PaymentStatusDetailProps> = ({
  role,
  franchiseDetailBilling,
  onClose,
}) => {
  const [modifyCheck, setModifyCheck] = useState(false);
  useEffect(() => {
    ScrollUtils.onPopupScrollStop(window.scrollY);
    return () => {
      const scrollY = document.body.style.top;
      ScrollUtils.closePopupScrollRun(scrollY);
    };
  }, []);

  return (
    <PaymentStatusDetailBlock>
      <div className="headers">
        <h3>정산현황 상세정보</h3>
        <Button theme={ThemeColor.primary} buttonSize="m" onClick={onClose}>
          뒤로가기
        </Button>
      </div>
      <div className="contentWrapper">
        <div className="detailInfo">
          <div className="field">
            <span>지점명</span>
            <p>{franchiseDetailBilling?.franchiseName}</p>
          </div>
          <div className="field">
            <span>세탁공장</span>
            <p>{franchiseDetailBilling?.factoryName}</p>
          </div>
          <div className="field">
            <span>세탁 신청일</span>
            <p>
              {DateFormatUtils.dateToFormat(
                franchiseDetailBilling?.applicationDate
              )}
            </p>
          </div>
          <div className="field">
            <span>납품일</span>
            <p>
              {DateFormatUtils.dateToFormat(
                franchiseDetailBilling?.completedDate
              )}
            </p>
          </div>
          <div className="field">
            <span>입고 수량 (포대/카트)</span>
            <p>
              {NumberUtils.numberToComma(franchiseDetailBilling?.bagCount ?? 0)}{" "}
            </p>
          </div>
          <div className="field">
            <span>세탁 수량 (개)</span>
            <p>
              {NumberUtils.numberToComma(
                franchiseDetailBilling?.totalAmount ?? 0
              )}
            </p>
          </div>
          <div className="field">
            <span>결제금액 (원)</span>
            {modifyCheck ? (
              <input value={franchiseDetailBilling?.price} />
            ) : (
              <p>
                {NumberUtils.numberToComma(franchiseDetailBilling?.price ?? 0)}{" "}
              </p>
            )}
          </div>
          {/* <div className="field">
            <span>정산상태</span>
            {modifyCheck ? (
              <select>
                <option
                  value="대기"
                  selected={
                    PriceUtils.priceStatusToString(
                      franchiseDetailBilling?.status ?? "-"
                    ) === "대기"
                  }
                >
                  대기
                </option>
                <option
                  value="완료"
                  selected={
                    PriceUtils.priceStatusToString(
                      franchiseDetailBilling?.status ?? "-"
                    ) === "완료"
                  }
                >
                  완료
                </option>
                <option
                  value="취소"
                  selected={
                    PriceUtils.priceStatusToString(
                      franchiseDetailBilling?.status ?? "-"
                    ) === "취소"
                  }
                >
                  취소
                </option>
              </select>
            ) : (
              <p>
                {PriceUtils.priceStatusToString(
                  franchiseDetailBilling?.status ?? "-"
                )}
              </p>
            )}
          </div> */}
          <div style={{ float: "right" }}>
            {RoleUtils.isFactory(role) && (
              <Button
                theme={ThemeColor.primary}
                buttonSize="m"
                onClick={() => setModifyCheck(!modifyCheck)}
              >
                {modifyCheck ? "취소" : "수정"}
              </Button>
            )}
            <Button theme={ThemeColor.primary} buttonSize="m" onClick={onClose}>
              {modifyCheck ? "수정" : "확인"}
            </Button>
          </div>
        </div>
        <div className="stockStatus">
          <div>
            <h3>세탁물 종류</h3>
            <div className="stockInField">
              <div>
                <div className="stockHeader">
                  <span>대분류</span>
                  <span>소분류</span>
                  <span>품명</span>
                  <span>사이즈</span>
                  <span>비고</span>
                  <span>세탁신청(개)</span>
                  <span>세탁완료(개)</span>
                  <span>재세탁(반품)</span>
                  <span>기타(미납,수선)</span>
                </div>
                <div className="stockList">
                  {franchiseDetailBilling?.items.map((category, idx) => (
                    <div className="field" key={idx}>
                      <span>{category.asset.details.largeCategory}</span>
                      <span>{category.asset.details.subCategory}</span>
                      <span>{category.asset.details.name}</span>
                      <span>{category.asset.details.size}</span>
                      <span>{category.asset.details.content}</span>
                      <span>
                        {category.beforeAmount
                          ? NumberUtils.numberToComma(
                              category.beforeAmount ?? 0
                            )
                          : "-"}
                      </span>
                      <span>
                        {category.amount
                          ? NumberUtils.numberToComma(category.amount ?? 0)
                          : "-"}
                      </span>
                      <span>
                        {" "}
                        {category.relaundering
                          ? NumberUtils.numberToComma(category.relaundering)
                          : "-"}
                      </span>
                      <span>-</span>
                    </div>
                  ))}
                  <div className="field">
                    <span>포대/카트</span>
                    <span>-</span>
                    <span>-</span>
                    <span>-</span>
                    <span>-</span>
                    <span>
                      {NumberUtils.numberToComma(
                        franchiseDetailBilling?.bagCount ?? 0
                      )}
                    </span>
                    <span>
                      {NumberUtils.numberToComma(
                        franchiseDetailBilling?.outBagCount ?? 0
                      )}
                    </span>
                    <span>-</span>
                    <span>-</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PaymentStatusDetailBlock>
  );
};

export default PaymentStatusDetail;

const PaymentStatusDetailBlock = styled(StatusDetailBlock)``;
