import Button from "components/common/Button";
import {
  FactoryGetList,
  FactoryLaundryDetail,
  FactoryLaundryList,
  LaundryClassification,
} from "modules/factory/types";
import React, { useEffect } from "react";
import styled from "styled-components";
import { Palette, ThemeColor } from "styles/palette";
import FactoryLaundaryStatusDetail from "./FactoryLaundryStatusDetail";
import FactoryLaundryStatusUpload from "./FactoryLaundryStatusUpload";
import Paging from "components/common/Paging";
import DateFormatUtils from "utils/DateFormatUtils";
import LaundrySearch from "components/common/LaundrySearch";
import { useState } from "react";
import NumberUtils from "utils/NumberUtils";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TypeUtils from "utils/TypeUtils";

import nonSelect from "assets/arrow/non_select.svg";
import downSelect from "assets/arrow/down_select.svg";
import upSelect from "assets/arrow/up_select.svg";
import { useHistory } from "react-router";
import hi from "date-fns/esm/locale/hi/index.js";

interface FactoryLaundryStatusProps {
  role: string;
  toggle: boolean;
  onToggle: () => void;
  setDetailId: (id: number) => void;
  enrollToggle: boolean;
  onEnrollToggle: (type: boolean) => void;
  factoryLaundryList: FactoryLaundryList | null;
  factoryLaundryDetail: FactoryLaundryDetail | null;
  laundryDetail: (id: number, page: number, data: FactoryGetList | null) => void;
  laundryDetailLite: (id: number, page: number, data: FactoryGetList | null, sort?: string) => void;
  laundryClassification: (
    payload: LaundryClassification,
    page: number,
    data: FactoryGetList | null,
    sort?: string
  ) => void;
  modifyLaundryClassification: (
    payload: LaundryClassification,
    page: number,
    data: FactoryGetList | null,
    sort?: string
  ) => void;
  lanudryList: (page: number, data: FactoryGetList | null, sort: string) => void;
}

type itemizedAccountModalType = {
  franchiseId: string;
  franchiseName: string;
  accountDate: string;
  accountType: string;
  modalCheck: boolean;
};

const FactoryLaundryStatus: React.FC<FactoryLaundryStatusProps> = ({
  role,
  toggle,
  onToggle,
  setDetailId,
  enrollToggle,
  onEnrollToggle,
  factoryLaundryList,
  factoryLaundryDetail,
  laundryDetail,
  laundryDetailLite,
  laundryClassification,
  modifyLaundryClassification,
  lanudryList,
}) => {
  const history = useHistory();
  const [searchData, setSearchData] = useState<FactoryGetList>({
    query: "",
    dropBox: "",
    startDate: "",
    endDate: "",
    size: 10,
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [detailType, setDetailType] = useState<boolean>(false);
  const [sortCheck, setSortCheck] = useState<string>("");
  const [moveSortType, setMoveSortType] = useState<string>("");
  const [account, setAccount] = useState<itemizedAccountModalType>({
    franchiseId: "",
    franchiseName: "",
    accountDate: "",
    accountType: "specifi3",
    modalCheck: false,
  });

  const enrollLaundry = (id: number, detailType: boolean) => {
    onEnrollToggle(true);
    setDetailType(detailType);
    setDetailId(id);
    laundryDetailLite(id, currentPage, searchData, moveSortType);
  };

  const detailLaundry = (id: number) => {
    // onToggle();
    laundryDetail(id, currentPage, searchData);
  };

  // const onDeleteLaundryData = (id: number) => {
  //   deleteLaundryData(id, currentPage, searchData);
  // };

  const params = new URLSearchParams(window.location.search);
  const urlParams = new URL(window.location.href);
  const query = window.location;

  const pageMove = (page: number) => {
    setCurrentPage(page);
    lanudryList(page, searchData ?? null, moveSortType);
    if (urlParams.searchParams.get("page")) {
      urlParams.searchParams.set("page", String(page));
      history.push(`${query.pathname}${urlParams.search}`);
    } else {
      if (urlParams.search === "") {
        history.push(`${query.pathname}?page=${page}`);
      } else {
        history.push(`${query.pathname}${urlParams.search}&page=${page}`);
      }
    }
  };

  const selectShowEntries = (size: number) => {
    searchData.size = size;
    if (urlParams.searchParams.get("size")) {
      urlParams.searchParams.set("size", String(size));
      history.push(`${query.pathname}${urlParams.search}`);
    } else {
      if (urlParams.search === "") {
        history.push(`${query.pathname}?size=${size}`);
      } else {
        history.push(`${query.pathname}${urlParams.search}&size=${size}`);
      }
    }

    lanudryList(1, searchData ?? null, moveSortType);
  };

  useEffect(() => {
    if (urlParams.search !== "") {
      if (params.get("sort")) {
        setMoveSortType(String(params.get("sort")));
        if (params.get("sort") === "frasc") setSortCheck("ASC");
        if (params.get("sort") === "frdesc") setSortCheck("DESC");
      }

      setSearchData({
        query: params.get("query") ?? "",
        dropBox: params.get("dropBox") ?? "",
        startDate: params.get("startDate") ?? "",
        endDate: params.get("endDate") ?? "",
        size: params.get("size") ? Number(params.get("size")) : 10,
      });

      if (params.get("page")) {
        setCurrentPage(Number(params.get("page")));
      }
    }
  }, []);

  const onSortList = (sortType: string, sortCheck1: string) => {
    let typeCheck = "";

    if (sortCheck1 === "" || sortCheck1 === "DESC") {
      setSortCheck("ASC");
      typeCheck = "ASC";
    }
    if (sortCheck1 === "ASC") {
      setSortCheck("DESC");
      typeCheck = "DESC";
    }

    let sort = "";

    if (typeCheck === "") {
      sort = `${sortType}asc`;
    } else {
      sort = typeCheck === "DESC" ? `${sortType}desc` : `${sortType}asc`;
    }

    if (urlParams.search === "") {
      history.push(`${query.pathname}?sort=${sort}`);
    } else {
      if (urlParams.searchParams.get("sort")) {
        urlParams.searchParams.set("sort", sort);
        history.push(`${query.pathname}${urlParams.search}`);
      } else {
        history.push(`${query.pathname}${urlParams.search}&sort=${sort}`);
      }
    }

    setMoveSortType(sort);
    lanudryList(currentPage, searchData, sort);
  };

  const onSearch = (page: number, data: FactoryGetList) => {
    if (urlParams.search === "") {
      if (data.dropBox === "applicationDate" || data.dropBox === "completeDate") {
        history.push(
          `${query.pathname}?startDate=${data.startDate}&endDate=${data.endDate}&dropBox=${data.dropBox}`
        );
      }
      if (data.dropBox === "franchiseName" || data.dropBox === "factoryName") {
        history.push(`${query.pathname}?query=${data.query}&dropBox=${data.dropBox}`);
      }
    }

    if (urlParams.search !== "") {
      let searchParams = "";

      if (data.dropBox === "franchiseName" || data.dropBox === "factoryName") {
        urlParams.searchParams.delete("startDate");
        urlParams.searchParams.delete("endDate");
        if (urlParams.searchParams.get("query")) {
          urlParams.searchParams.set("query", data.query ?? "");
        } else {
          searchParams += `&query=${data.query}`;
        }
      }

      if (data.dropBox === "applicationDate" || data.dropBox === "completeDate") {
        urlParams.searchParams.delete("query");
        if (urlParams.searchParams.get("startDate")) {
          urlParams.searchParams.set("startDate", data.startDate ?? "");
        } else {
          searchParams += `&startDate=${data.startDate}`;
        }

        if (urlParams.searchParams.get("endDate")) {
          urlParams.searchParams.set("endDate", data.endDate ?? "");
        } else {
          searchParams += `&endDate=${data.endDate}`;
        }
      }

      if (urlParams.searchParams.get("dropBox")) {
        urlParams.searchParams.set("dropBox", data.dropBox ?? "");
      } else {
        searchParams += `&dropBox=${data.dropBox}`;
      }

      if (urlParams.searchParams.get("page")) {
        urlParams.searchParams.delete("page");
      }

      history.push(`${query.pathname}${urlParams.search}${searchParams}`);
    }

    setCurrentPage(1);
    setSearchData(data);
    lanudryList(1, data, moveSortType);
  };

  const [listNumber, setListNumber] = useState<number[]>([]);
  useEffect(() => {
    setListNumber(
      NumberUtils.numberToList(
        factoryLaundryList?.currentPage ?? 0,
        factoryLaundryList?.totalCount ?? 0,
        searchData.size ?? 10
      )
    );
  }, [factoryLaundryList, searchData]);

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      fontWeight: 700,
    },
  });
  const classes = useStyles();

  const classificationLaundry = (payload: LaundryClassification) => {
    laundryClassification(payload, currentPage, searchData, moveSortType);
  };

  const modiftClassificationLaundry = (payload: LaundryClassification) => {
    modifyLaundryClassification(payload, currentPage, searchData, moveSortType);
  };

  const goPage = () => {
    history.push(
      `/factory/itemizedAccount?franchiseId=${account.franchiseId}&franchiseName=${account.franchiseName}&accountDate=${account.accountDate}&accountType=${account.accountType}`
    );
  };

  const onAccountModal = (id: number, name: string, date: string) => {
    setAccount({
      ...account,
      franchiseId: String(id),
      franchiseName: name,
      accountDate: date,
      modalCheck: true,
    });
  };

  const onAccountTypeCheck = (type: string) => {
    setAccount({ ...account, accountType: type });
  };

  const onAccountModalReset = () => {
    setAccount({
      franchiseId: "",
      franchiseName: "",
      accountDate: "",
      accountType: "specifi3",
      modalCheck: false,
    });
  };

  console.log(account);

  return (
    <>
      <FactoryLaundryStatusBlock>
        {/* {toggle && (
        <FactoryLaundaryStatusDetail
          onToggle={onToggle}
          factoryLaundryDetail={factoryLaundryDetail}
          onDeleteLaundryData={onDeleteLaundryData}
        />
      )} */}
        {enrollToggle && (
          <FactoryLaundryStatusUpload
            onEnrollToggle={onEnrollToggle}
            factoryLaundryDetail={factoryLaundryDetail}
            laundryClassification={classificationLaundry}
            detailType={detailType!}
            modifyLaundryClassification={modiftClassificationLaundry}
          />
        )}
        <div className="headers">
          <h3>세탁 현황</h3>
        </div>
        <div className="tableSelect">
          <div>
            <span>Show </span>
            <select
              value={searchData.size ?? 10}
              onChange={(e) => selectShowEntries(parseInt(e.target.value, 10))}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
            <span> entries</span>
          </div>
          <div className="topWrapper">
            <span>Search: </span>
            <div>
              <LaundrySearch role={role} onSearch={onSearch} size={searchData.size ?? 10} />
            </div>
          </div>
        </div>
        <div className="tableWrapper">
          <TableContainer component={Paper} className="tables">
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 900,
                      width: "70px",
                    }}
                  >
                    순번
                  </TableCell>
                  {/* <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "10%" }}
                >
                  구분
                </TableCell> */}
                  <TableCell align="center" style={{ fontWeight: 900, width: "70px" }}></TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: 900 }}
                    className="sortList"
                    onClick={() => onSortList("fr", sortCheck)}
                  >
                    지점명{" "}
                    <img
                      className="sortSelect"
                      src={
                        sortCheck === "" ? nonSelect : sortCheck === "ASC" ? downSelect : upSelect
                      }
                      alt=""
                    />
                    {/* {sortCheck === "ASC" && <span className="sortDown">▼</span>}
                  {sortCheck === "DESC" && <span className="sortUp">▲</span>} */}
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 900, width: "20%" }}>
                    세탁 신청일
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 900, width: "20%" }}>
                    납품일
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 900, width: "20%" }}>
                    신청 포대/카트
                  </TableCell>
                  <TableCell style={{ fontWeight: 900, width: "10%" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="tableBody">
                {factoryLaundryList?.list.map((laundry, index) => (
                  <TableRow key={index} className="tableHover">
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      onClick={() => detailLaundry(laundry.id)}
                    >
                      {listNumber[index]}
                    </TableCell>
                    {/* <TableCell
                    align="center"
                    onClick={() => detailLaundry(laundry.id)}
                  >
                    {TypeUtils.laundryRequestType(laundry.type)}
                  </TableCell> */}
                    <TableCell align="center" onClick={() => detailLaundry(laundry.id)}>
                      {/* <span className="newIcons">New</span> */}
                      {laundry.new === true && <span className="newIcons">New</span>}
                    </TableCell>
                    <TableCell align="center" onClick={() => detailLaundry(laundry.id)}>
                      {laundry.franchise.companyName}
                    </TableCell>
                    <TableCell align="center" onClick={() => detailLaundry(laundry.id)}>
                      {DateFormatUtils.dateToFormat(laundry.applicationDate)}
                    </TableCell>
                    <TableCell align="center" onClick={() => detailLaundry(laundry.id)}>
                      {DateFormatUtils.dateToFormat(laundry.completedDate)}
                    </TableCell>
                    <TableCell align="center" onClick={() => detailLaundry(laundry.id)}>
                      {NumberUtils.numberToComma(laundry.bagCount ?? 0)} 포대/카트
                    </TableCell>
                    <TableCell align="center" className="buttonBox">
                      <div>
                        <Button
                          theme={laundry.completedDate ? ThemeColor.tertiary : ThemeColor.quadratic}
                          buttonSize="s"
                          notUseCousor={!laundry.completedDate}
                          onClick={() => {
                            if (laundry.completedDate)
                              onAccountModal(
                                laundry.franchise.id,
                                laundry.franchise.companyName,
                                DateFormatUtils.dateToFormat(laundry.completedDate)
                              );
                          }}
                        >
                          거래명세표
                        </Button>
                        <Button
                          theme={ThemeColor.tertiary}
                          buttonSize="s"
                          onClick={() =>
                            enrollLaundry(laundry.id, laundry.completedDate ? true : false)
                          }
                        >
                          {!laundry.completedDate ? "등록" : "수정"}
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Paging
            listAction={pageMove}
            listCount={factoryLaundryList?.totalPage ?? 0}
            currentPage={factoryLaundryList?.currentPage}
            totalCount={factoryLaundryList?.totalCount}
            size={searchData.size ?? 10}
          ></Paging>
        </div>
        {/* <LaundryStatusListContainer /> */}
      </FactoryLaundryStatusBlock>
      {account.modalCheck && (
        <AccountModalBlock>
          <section>
            <h3>거래명세표 타입 선택</h3>
            <div className="selectWrap">
              <label>
                <input
                  type="radio"
                  name="accountType"
                  value="specifi1"
                  checked={account.accountType === "specifi1"}
                  onClick={() => onAccountTypeCheck("specifi1")}
                />{" "}
                금액미포함
              </label>
              <label>
                <input
                  type="radio"
                  name="accountType"
                  value="specifi3"
                  checked={account.accountType === "specifi3"}
                  onClick={() => onAccountTypeCheck("specifi3")}
                />{" "}
                금액포함
              </label>
            </div>
            <div className="btnWrap">
              <Button theme={ThemeColor.quadratic} buttonSize="s" onClick={onAccountModalReset}>
                취소
              </Button>
              <Button theme={ThemeColor.tertiary} buttonSize="s" onClick={goPage}>
                이동
              </Button>
            </div>
          </section>
        </AccountModalBlock>
      )}
    </>
  );
};

export default FactoryLaundryStatus;

const FactoryLaundryStatusBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);

  .tables {
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  .tableHover:hover {
    background-color: #eee;
    cursor: pointer;
  }
  .topWrapper {
    display: flex;
    justify-content: flex-end;
    & > span {
      font-size: 14px;
      line-height: 37px;
      margin-right: 5px;
    }
    & > div {
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div {
        margin: 0;
        margin-left: 1rem;
      }
    }
  }
  .tableSelect {
    height: 70px;
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: space-between;
    & > div {
      color: #999;
      height: 40px;
      /* overflow: hidden; */
      & > span {
        font-size: 14px;
        line-height: 37px;
      }
      & > select {
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        transition: ease-in-out;
        transition-duration: 0.1s;
        border-radius: 3px;
      }
      & > select:hover {
        border-bottom: 2px solid ${Palette.primary};
      }
    }
  }
  .tableWrapper {
    min-width: 51rem;
    padding-bottom: 1.5rem;
    .newIcons {
      padding: 2px 7px;
      background-color: red;
      margin-left: 7px;
      color: #fff;
      border-radius: 2px;
      font-size: 12px;
    }
  }
  .tableBody {
    tr {
      height: 53px !important;
      td {
        padding: 0;
      }
    }
    .buttonBox {
      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .sortList {
    cursor: pointer;
  }
  .sortSelect {
    width: 13px;
    height: 13px;
    margin-left: 5px;
    position: relative;
    top: 2px;
  }
  .sortUp {
    color: red;
  }
  .sortDown {
    color: blue;
  }
`;

const AccountModalBlock = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  & > section {
    width: 300px;
    height: 250px;
    background-color: ${Palette.white};
    border-radius: 17px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    & > h3 {
      width: 100%;
      padding: 0;
      margin: 0;
    }
    & > .selectWrap {
      width: 50%;
      display: flex;
      flex-direction: column;
      /* background-color: green; */
      & > label:nth-child(1) {
        margin-bottom: 10px;
      }
    }
  }
`;
