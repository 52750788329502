import FactoryLabel from "components/factory/FactoryLabel";
import { IRootState } from "modules";
import { getLabelExportDataAction } from "modules/factory/factory";
import { LabelSearchType } from "modules/factory/types";
import { getSpecificationsFranchiseListAction } from "modules/user/user";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const FactoryLabelContainer = () => {
  const dispatch = useDispatch();

  const [currentPageState, setCurrentPageState] = useState<LabelSearchType>({
    page: 1,
    limit: 10,
    query: "",
    sort: "",
    type: "frame",
  });

  useEffect(() => {
    dispatch(getLabelExportDataAction(currentPageState));
  }, [dispatch, currentPageState]);

  useEffect(() => {
    dispatch(getSpecificationsFranchiseListAction());
  }, [dispatch]);

  const specificationsFranchiseList = useSelector(
    (state: IRootState) => state.user.specificationsFranchiseList
  );

  const labelExportDataList = useSelector(
    (state: IRootState) => state.factory.labelExportDataListType
  );

  const onSearch = (data: LabelSearchType) => {
    console.log(data);
    setCurrentPageState(data);
  };

  const onPageMove = (page: number) => {
    setCurrentPageState((currentPageState) => ({
      ...currentPageState,
      page,
    }));
  };

  const onSortList = (sort: string) => {
    setCurrentPageState((currentPageState) => ({
      ...currentPageState,
      sort,
    }));
  };

  const selectShowEntries = (limit: number) => {
    // console.log("size", limit);
    setCurrentPageState((currentPageState) => ({
      ...currentPageState,
      limit,
      page: 1,
    }));
  };

  // console.log("currentPageState", currentPageState);

  return (
    <FactoryLabel
      labelExportDataList={labelExportDataList}
      specificationsFranchiseList={specificationsFranchiseList}
      selectShowEntries={selectShowEntries}
      onSearch={onSearch}
      onPageMove={onPageMove}
      onSortList={onSortList}
      currentPageState={currentPageState}
    />
  );
};

export default FactoryLabelContainer;
