import React, { useEffect, useState } from "react";
import AdminAccessHistory from "components/admin/AdminAccessHistory";
import { useDispatch, useSelector } from "react-redux";
import { getAdminUserAccessHistoryAction } from "modules/admin/admin";
import { IRootState } from "modules";

interface AdminAccessHistoryContainerProps {}

const AdminAccessHistoryContainer: React.FC<AdminAccessHistoryContainerProps> =
  () => {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [searchData, setSearchData] = useState({
      query: "",
      size: 10,
    });

    const adminUserAccessHistory = useSelector(
      (state: IRootState) => state.admin.adminUserAccessHistory
    );

    useEffect(() => {
      dispatch(getAdminUserAccessHistoryAction(1, "", 10));
    }, [dispatch]);

    const onSearch = (query: string) => {
      setSearchData({ ...searchData, query });
      dispatch(getAdminUserAccessHistoryAction(1, query, searchData.size));
    };

    const accessHistoryListPageMove = (page: number) => {
      setCurrentPage(page);
      dispatch(
        getAdminUserAccessHistoryAction(page, searchData.query, searchData.size)
      );
    };

    const showSize = (size: number) => {
      setSearchData({ ...searchData, size });
      dispatch(
        getAdminUserAccessHistoryAction(currentPage, searchData.query, size)
      );
    };

    return (
      <AdminAccessHistory
        adminUserAccessHistory={adminUserAccessHistory}
        onSearch={onSearch}
        pageMove={accessHistoryListPageMove}
        selectSize={showSize}
        searchData={searchData}
      />
    );
  };

export default AdminAccessHistoryContainer;
