import { combineReducers } from "redux";
import { IUserState } from "./user/types";
import { IStoreState } from "./store/types";
import store, { storeSaga } from "./store/store";
import user, { userSaga } from "./user/user";
import factory, { factorySaga } from "./factory/factory";
import master, { masterSaga } from "./master/master";
import admin, { adminSaga } from "./admin/admin";
import { all } from "redux-saga/effects";
import { IFactoryState } from "./factory/types";
import { IMasterState } from "./master/types";
import { IAdminState } from "./admin/types";

export interface IRootState {
  admin: IAdminState;
  master: IMasterState;
  factory: IFactoryState;
  store: IStoreState;
  user: IUserState;
}

const rootStore = combineReducers<IRootState>({
  user,
  store,
  factory,
  master,
  admin,
});

export default rootStore;

export function* rootSaga() {
  yield all([
    userSaga(),
    storeSaga(),
    factorySaga(),
    masterSaga(),
    adminSaga(),
  ]);
}
