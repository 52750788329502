import React from "react";
import styled from "styled-components";
import ProfileStatusContainer from "containers/common/ProfileStatusContainer";

interface MasterProfileProps {}

const MasterProfileBlock = styled.div`
  /* height: 100%;
  padding: 3rem; */
`;

const MasterProfile: React.FC<MasterProfileProps> = () => {
  return (
    <MasterProfileBlock>
      <ProfileStatusContainer />
    </MasterProfileBlock>
  );
};

export default MasterProfile;
