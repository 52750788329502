class NumberOnlyUtils {
  firstNotZero(data: string) {
    if (data === "") return 0;
    return parseInt(data.replace(/(^0+)/, ""), 10);
  }
  numberOnlyToString(data: string) {
    const regExp = /[^0-9]/g;
    return data.replace(regExp, "");
  }
  dountchartContrastBoolean(data: number) {
    const type = data
      .toString()
      .split("")
      .some((symbol) => symbol === "-");
    if (data === 0) return "normal";
    if (type) return "minus";
    if (!type) return "plus";
  }
  dountchartContrastIcon(data: number) {
    const type = data
      .toString()
      .split("")
      .some((symbol) => symbol === "-");
    if (data === 0) return "-";
    if (type) return "▼";
    if (!type) return "▲";
  }
  onlyNumber(data: number) {
    if (data === 0) return "";
    const number = data.toString().replace("-", "");
    return Number(number);
  }
  numberToComma(data: number | string) {
    if (!data) return 0;
    const comma = data.toLocaleString("en-US");
    return comma;
  }
  numberToList(page: number, count: number, size?: number) {
    if (!size) size = 10;
    if (page <= 0) page = 1;
    const currentPage = page - 1;
    const totalCount = count;
    const listNumber = [];
    for (let i = 0; i < totalCount; i++) {
      listNumber.push(i + 1);
    }
    listNumber.reverse();
    return listNumber.slice(currentPage * size, currentPage * size + size);
  }
}

export default new NumberOnlyUtils();
