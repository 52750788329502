import Button from "components/common/Button";
import PopupSmallLayout from "components/common/PopupSmallLayout";
import { Details, MasterItemDataModify } from "modules/master/types";
import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { ThemeColor } from "styles/palette";

interface MasterItemModifyProps {
  item: Details | null;
  onClose: () => void;
  masterItemModify: (payload: MasterItemDataModify) => void;
}

const MasterItemModify: React.FC<MasterItemModifyProps> = ({
  item,
  onClose,
  masterItemModify,
}) => {
  const [id, setId] = useState<number | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [content, setContent] = useState<string | null>(null);
  const [largeCategory, setLargeCategory] = useState<string | null>(null);
  const [subCategory, setSubCategory] = useState<string | null>(null);
  const [unitPrice, setUnitPrice] = useState<number | null>(null);
  const [size, setSize] = useState<string | null>(null);

  useEffect(() => {
    setId(item!.detailsId);
    setName(item?.name ?? null);
    setContent(item?.content ?? null);
    setLargeCategory(item?.largeCategory ?? null);
    setSubCategory(item?.subCategory ?? null);
    setUnitPrice(item?.unitPrice ?? null);
    setSize(item?.size ?? null);
  }, [item]);

  const itemModify = () => {
    if (!name || !content) return alert("값을 입력해주세요");
    const payload = {
      id: id!,
      largeCategory: largeCategory,
      subCategory: subCategory,
      name: name,
      unitPrice: unitPrice,
      size: size,
      content: content,
    };
    masterItemModify(payload);
    onClose();
  };

  return (
    <MasterItemModifyBlock>
      <PopupSmallLayout>
        <h2>재고목록 수정</h2>
        <article className="popupInput">
          <div className="inputField">
            <div className="field">
              <span>대분류</span>
              <input
                value={largeCategory ?? ""}
                onChange={(e) => setLargeCategory(e.target.value)}
              />
            </div>
            <div className="field">
              <span>소분류</span>
              <input
                value={subCategory ?? ""}
                onChange={(e) => setSubCategory(e.target.value)}
              />
            </div>
            <div className="field">
              <span>품목</span>
              <input
                value={name ?? ""}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="field">
              <span>사이즈</span>
              <input
                value={size ?? ""}
                onChange={(e) => setSize(e.target.value)}
              />
            </div>
            <div className="field">
              <span>비고</span>
              <input
                value={content ?? ""}
                onChange={(e) => setContent(e.target.value)}
              />
            </div>
          </div>
        </article>
        <div className="buttonWrapper">
          <Button theme={ThemeColor.primary} buttonSize="m" onClick={onClose}>
            취소
          </Button>
          <Button
            theme={ThemeColor.primary}
            buttonSize="m"
            onClick={itemModify}
          >
            수정
          </Button>
        </div>
      </PopupSmallLayout>
    </MasterItemModifyBlock>
  );
};

export default MasterItemModify;

const MasterItemModifyBlock = styled.div`
  position: relative;
  z-index: 3;
  article {
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: space-between;
    h1 {
      margin: 0;
      font-size: 1.5rem;
    }
    .topButtonWrapper {
      margin-top: 2rem;
      height: 2rem;
    }
  }
  h2 {
    margin-top: 2.5rem;
    margin-left: 3rem;
    color: #016241;
  }
  .popupInput {
    flex: 1;
    width: 21.5rem;
    margin: 0 auto;
    .inputField {
      width: 100%;
      margin-top: 10rem;
      .field {
        /* height: 2.1rem; */
        display: grid;
        grid-template-columns: 8rem 1fr;
        align-items: center;
        select,
        input {
          height: 2.1rem;
        }
      }
      .field + .field {
        margin-top: 0.9rem;
      }
    }
  }
  .buttonWrapper {
    margin: 0 auto;
    padding: 2rem;
  }
`;
