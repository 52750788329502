import React from "react";
import styled from "styled-components";
import Button from "./Button";
import { useState } from "react";
import PasswordChangeContainer from "containers/common/PasswordChangeContainer";
import RoleUtils from "utils/RoleUtils";
import {
  ILoginResponse,
  PasswordChangeForm,
  ProfileDetail,
  ProfileModify,
} from "modules/user/types";
import { Palette, ThemeColor } from "styles/palette";
import DaumPostCode from "./DaumPostCode";
import { useEffect } from "react";
import { CenterAligned } from "./layouts/CenterAligned";
import FactoryUtils from "utils/FactoryUtils";
import DateFormatUtils from "utils/DateFormatUtils";
import NumberUtils from "utils/NumberUtils";
import TypeUtils from "utils/TypeUtils";

interface ProfileStatusProps {
  role: string;
  user: ILoginResponse;
  profileDetail: ProfileDetail | null;
  patchProfileEdit: (payload: ProfileModify) => void;
  patchPasswordChange: (payload: PasswordChangeForm) => void;
}

const ProfileStatus: React.FC<ProfileStatusProps> = ({
  role,
  user,
  profileDetail,
  patchProfileEdit,
  patchPasswordChange,
}) => {
  const [edit, setEdit] = useState(false);
  const [passwordChange, setPasswordChange] = useState(false);
  const [postCodeCheck, setPostCodeCheck] = useState(false);

  const [addressTrueCheck, setAddressTureCheck] = useState(false);
  const [zoneCode, setZoneCode] = useState<string | null>(null);
  const [fullAddress, setFullAddress] = useState<string | null>(null);
  const [extraAddress, setExtraAddress] = useState<string | null>(null);

  const [phone, setPhone] = useState<string | null>(null);
  const [companyName, setCompanyName] = useState<string | null>(null);
  const [managerName, setManagerName] = useState<string | null>(null);
  const [managerPhone, setManagerPhone] = useState<string | null>(null);
  const [factoryStatus, setFactoryStatus] = useState<string | null>(
    profileDetail?.status ?? null
  );
  const [payMethod, setPayMethod] = useState<string>("unit");
  const [fax, setFax] = useState<string>("");
  const [representative, setRepresentative] = useState<string>("");
  const [registrationNumber, setRegistrationNumber] = useState<string>("");

  const onAddress = (zoneCode: string, fullAddress: string) => {
    setAddressTureCheck(true);
    setZoneCode(zoneCode);
    setFullAddress(fullAddress);
    setExtraAddress("");
    setPostCodeCheck(false);
    setPasswordChange(false);
  };

  const onClose = () => {
    if (profileDetail?.companyAddress) {
      setZoneCode(profileDetail?.companyAddress.postCode ?? "");
      setFullAddress(profileDetail?.companyAddress.street ?? "");
      setExtraAddress(profileDetail?.companyAddress.streetDetails ?? "");
    }
    setPostCodeCheck(false);
    setPasswordChange(false);
    setEdit(false);
  };

  useEffect(() => {
    setPhone(profileDetail?.companyPhone ?? "");
    setCompanyName(profileDetail?.companyName ?? "");
    if (profileDetail?.companyAddress) {
      setZoneCode(profileDetail?.companyAddress.postCode ?? "");
      setFullAddress(profileDetail?.companyAddress.street ?? "");
      setExtraAddress(profileDetail?.companyAddress.streetDetails ?? "");
    }

    if (profileDetail?.manager) {
      setManagerName(profileDetail?.manager.nickname ?? "");
      setManagerPhone(profileDetail?.manager.phone ?? "");
    }
    if (RoleUtils.isFactory(role)) {
      setFax(profileDetail?.fax ?? "-");
      setRepresentative(profileDetail?.representative ?? "-");
      setRegistrationNumber(profileDetail?.registrationNumber ?? "-");
    }
  }, [profileDetail, role]);

  const editProfile = () => {
    let payload;
    if (RoleUtils.isMasterAdmin(role)) {
      payload = {
        nickName: managerName ?? "",
        userPhone: managerPhone ?? "",
      };
      patchProfileEdit(payload);
    } else {
      const addressData = {
        postCode: zoneCode!,
        street: fullAddress!,
        streetDetails: extraAddress!,
        isLoadName: addressTrueCheck,
      };

      if (RoleUtils.isStore(role)) {
        payload = {
          phone: phone!,
          address: addressData!,
          companyName: companyName!,
          managerName: managerName!,
          managerPhone: managerPhone!,
          factoryStatus: factoryStatus!,
          payment: payMethod!,
        };
      } else if (RoleUtils.isFactory(role)) {
        payload = {
          phone: phone!,
          address: addressData!,
          companyName: companyName!,
          managerName: managerName!,
          managerPhone: managerPhone!,
          fax: fax,
          representative: representative,
          registrationNumber: registrationNumber,
        };
      } else {
        payload = {
          phone: phone!,
          address: addressData!,
          companyName: companyName!,
          managerName: managerName!,
          managerPhone: managerPhone!,
          factoryStatus: factoryStatus!,
        };
      }
      patchProfileEdit(payload);
    }
    onClose();
  };

  useEffect(() => {
    if (profileDetail?.companyAddresss) {
      const data = profileDetail?.companyAddresss.split(",");
      setFullAddress(data[0]);
      setExtraAddress(data[1]);
    }
  }, [profileDetail]);

  return (
    <ProfileStatusBlock>
      {passwordChange && (
        <PasswordChangeContainer
          patchPasswordChange={patchPasswordChange}
          onClose={() => {
            setPasswordChange(!passwordChange);
          }}
        />
      )}
      {postCodeCheck && (
        <div className="postCode">
          <div>
            <DaumPostCode onAddress={onAddress} />
            <CenterAligned>
              <Button
                theme={ThemeColor.tertiary}
                buttonSize="m"
                onClick={() => setPostCodeCheck(false)}
                className="postCodeCloseBtn"
              >
                닫기
              </Button>
            </CenterAligned>
          </div>
        </div>
      )}
      <div className="headers">
        <h3>관리자 정보</h3>
      </div>
      <div className="contentWrap">
        <section>
          <article>
            <div className="field">
              <span>아이디</span>
              <p>{user.username ?? "-"}</p>
            </div>
            {edit && (
              <div className="field">
                <span>비밀번호</span>
                <Button
                  theme={ThemeColor.primary}
                  buttonSize="m"
                  className="pwChange"
                  onClick={() => {
                    setPasswordChange(!passwordChange);
                  }}
                >
                  비밀번호 변경
                </Button>
              </div>
            )}
            <div className="field">
              <span>
                {RoleUtils.isFactory(role) && "공장명"}
                {RoleUtils.isStore(role) && "지점명"}
                {RoleUtils.isMaster(role) && "브랜드명"}
                {RoleUtils.isMasterAdmin(role) && "본사명"}
              </span>
              {RoleUtils.isMasterAdmin(role) ? (
                <>
                  {edit ? (
                    <input
                      value={managerName!}
                      onChange={(e) => setManagerName(e.target.value)}
                    />
                  ) : (
                    <p>{managerName ?? "-"}</p>
                  )}
                </>
              ) : (
                <>
                  {edit ? (
                    <input
                      value={companyName ?? "-"}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  ) : (
                    <p>{companyName ?? "-"}</p>
                  )}
                </>
              )}
            </div>
            <div className="field">
              <span>연락처</span>
              {edit ? (
                <input
                  type="text"
                  value={phone!}
                  onChange={(e) =>
                    setPhone(NumberUtils.numberOnlyToString(e.target.value))
                  }
                  disabled={RoleUtils.isMasterAdmin(role) === true}
                  className={
                    RoleUtils.isMasterAdmin(role) === true ? "adminClass" : ""
                  }
                />
              ) : (
                <p>{phone ?? "-"}</p>
              )}
            </div>
            <div className="field postalCode">
              <span>주소</span>
              {edit ? (
                <input type="text" value={zoneCode!} disabled />
              ) : (
                <>
                  {RoleUtils.isMasterAdmin(role) ? (
                    <p>08377</p>
                  ) : (
                    <p>{zoneCode ?? "-"}</p>
                  )}
                </>
              )}
              {edit && !RoleUtils.isMasterAdmin(role) && (
                <Button
                  theme={ThemeColor.primary}
                  buttonSize="m"
                  className="postalCodeBtn"
                  onClick={() => setPostCodeCheck(true)}
                >
                  찾기
                </Button>
              )}
            </div>
            <div className="field">
              <span></span>
              {edit ? (
                <input type="text" value={fullAddress!} disabled />
              ) : (
                <p>{fullAddress ?? "-"}</p>
              )}
            </div>
            <div className="field">
              <span></span>
              {edit ? (
                <input
                  type="text"
                  value={extraAddress!}
                  onChange={(e) => setExtraAddress(e.target.value)}
                  disabled={RoleUtils.isMasterAdmin(role)}
                />
              ) : (
                <p>{extraAddress ?? "-"}</p>
              )}
            </div>
          </article>
          <article>
            {/* {RoleUtils.isStore(role) && (
              <div className="field">
                <span>가맹점명</span>
                <p>{companyName ?? "-"}</p>
              </div>
            )} */}
            {RoleUtils.isFactory(role) && (
              <>
                <div className="field">
                  <span>대표자</span>
                  {edit ? (
                    <input
                      value={representative}
                      onChange={(e) => setRepresentative(e.target.value)}
                    />
                  ) : (
                    <p>{representative ?? "-"}</p>
                  )}
                </div>
                <div className="field">
                  <span>등록번호</span>
                  {edit ? (
                    <input
                      value={registrationNumber}
                      onChange={(e) => setRegistrationNumber(e.target.value)}
                    />
                  ) : (
                    <p>{registrationNumber ?? "-"}</p>
                  )}
                </div>
                <div className="field">
                  <span>FAX</span>
                  {edit ? (
                    <input
                      value={fax}
                      onChange={(e) => setFax(e.target.value)}
                    />
                  ) : (
                    <p>{fax ?? "-"}</p>
                  )}
                </div>
              </>
            )}
            {!RoleUtils.isMasterAdmin(role) && (
              <div className="field">
                <span>담당자명</span>
                {edit ? (
                  <input
                    type="text"
                    value={managerName!}
                    onChange={(e) => setManagerName(e.target.value)}
                  />
                ) : (
                  <p>{managerName ?? "-"}</p>
                )}
              </div>
            )}
            <div className="field">
              <span>담당자연락처</span>
              {edit ? (
                <input
                  type="text"
                  value={managerPhone!}
                  onChange={(e) =>
                    setManagerPhone(
                      NumberUtils.numberOnlyToString(e.target.value)
                    )
                  }
                />
              ) : (
                <p>{managerPhone ?? "-"}</p>
              )}
            </div>

            {/* {RoleUtils.isStore(role) && (
              <div className="paymentMethod field">
                <span>결제방식</span>
                <div>
                  <label className={payMethod === "unit" ? "active" : ""}>
                    <input
                      type="radio"
                      value="unit"
                      name="paymentMethod"
                      checked={payMethod === "unit"}
                      onChange={(e) => {
                        if (edit) setPayMethod(e.target.value);
                      }}
                    />
                    <span>단가제</span>
                  </label>
                  <label className={payMethod === "month" ? "active" : ""}>
                    <input
                      type="radio"
                      value="month"
                      name="paymentMethod"
                      checked={payMethod === "month"}
                      onChange={(e) => {
                        if (edit) setPayMethod(e.target.value);
                      }}
                    />
                    <span>월정액</span>
                  </label>
                </div>
              </div>
            )} */}
            {RoleUtils.isStore(role) && (
              <>
                <div className="field">
                  <span>계약기준</span>
                  <p>
                    {profileDetail?.contract
                      ? TypeUtils.payManagementContactType(
                          profileDetail.contract.basis
                        )
                      : "-"}
                  </p>
                </div>
                {profileDetail?.contract?.basis === "UNIT_PRICE" ? (
                  <div className="field">
                    <span>산출방식</span>
                    <p>
                      {profileDetail?.contract
                        ? TypeUtils.payManagementSubType(
                            profileDetail.contract.type
                          )
                        : "-"}
                    </p>
                  </div>
                ) : (
                  <div className="field">
                    <span>계약금액</span>
                    <p>
                      {profileDetail?.contract &&
                      profileDetail.contract.price !== 0
                        ? NumberUtils.numberToComma(
                            profileDetail.contract.price
                          )
                        : "-"}
                    </p>
                  </div>
                )}

                <div className="field">
                  <span>정신기간</span>
                  <p>
                    {profileDetail?.contract
                      ? `${profileDetail.contract.startDay} 일 ~ ${
                          profileDetail.contract.lastDay !== 0
                            ? "익월 " + profileDetail.contract.lastDay
                            : "이달 말"
                        } 일`
                      : "-"}
                  </p>
                </div>
              </>
            )}
            {!edit && (
              <>
                <div className="field">
                  <span>가입일</span>
                  <p>{DateFormatUtils.dateToFormat(profileDetail?.joinDate)}</p>
                </div>
              </>
            )}
            {/* {RoleUtils.isFactory(role) && (
              <div className="field">
                <span>상태</span>
                {edit ? (
                  <select onChange={(e) => setFactoryStatus(e.target.value)}>
                    <option
                      value="OPEN"
                      selected={profileDetail?.status === "OPEN"}
                    >
                      운영중
                    </option>
                    <option
                      value="CLOSED"
                      selected={profileDetail?.status === "CLOSED"}
                    >
                      운영중단
                    </option>
                  </select>
                ) : (
                  <p>
                    {profileDetail?.status
                      ? FactoryUtils.operationStatusToString(
                          profileDetail?.status
                        )
                      : "-"}
                  </p>
                )}
              </div>
            )} */}
          </article>
        </section>
        <div className="buttonWrapper">
          {!edit ? (
            <Button
              theme={ThemeColor.secondary}
              buttonSize="m"
              onClick={() => setEdit(true)}
            >
              수정
            </Button>
          ) : (
            <>
              <Button
                theme={ThemeColor.secondary}
                buttonSize="m"
                onClick={editProfile}
              >
                수정
              </Button>
              <Button
                theme={ThemeColor.secondary}
                buttonSize="m"
                onClick={onClose}
              >
                취소
              </Button>
            </>
          )}
        </div>
      </div>
    </ProfileStatusBlock>
  );
};

export default ProfileStatus;

const ProfileStatusBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  & > .contentWrap {
    height: auto;

    background-color: ${Palette.white};
    padding: 7rem 2rem;
    /* border-radius: 0.5rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
    section {
      /* flex: 1; */
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      /* margin-bottom: 2rem; */
      width: 90%;
      height: auto;

      article {
        width: 30rem;
        padding-left: 2rem;
        .field {
          display: grid;
          grid-template-columns: 8rem 1fr;
          align-items: center;
          line-height: 20px;
          p {
            background-color: ${Palette.white};
            /* padding: 0.5rem 1rem; */
            height: auto;
            min-height: 35px;
            line-height: 35px;
            margin: 0;
            border-radius: 5px;
            border: 1px solid #eee;
            padding-left: 5px;
          }
          input {
            padding: 0;
            padding-left: 5px;
            min-height: 35px;
            height: auto;
          }
          .pwChange {
            width: 140px;
          }
          .adminClass {
            border: 1px solid #eee;
            background-color: #fff;
          }
        }
        .postalCode {
          grid-template-columns: 8rem 1fr 1fr;
          .postalCodeBtn {
            width: 70px;
            margin-left: 10px;
          }
        }
        .paymentMethod {
          display: grid;
          grid-template-columns: 8rem 1fr;
          & > div {
            display: flex;
            justify-content: space-between;
            & > label {
              width: 45%;
              padding: 0.5rem 1rem;
              margin: 0;
              border-radius: 5px;
              border: 1px solid #eee;
              color: #000000;
              background-color: #f0f0f0;
              & > input {
                display: none;
              }
              & > span {
                display: block;
                text-align: center;
              }
              &.active {
                color: #ffffff;
                background-color: ${Palette.primary};
              }
            }
          }
        }

        .field + .field {
          margin-top: 1rem;
        }
      }
    }

    .buttonWrapper {
      text-align: center;
      margin-top: 3rem;
      /* padding: 1rem; */
    }
  }
  .postCode {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    & > div {
      width: 30rem;
      & > div {
        & > .postCodeCloseBtn {
          margin-top: 1rem;
        }
      }
    }
  }
`;
