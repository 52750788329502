import AdminStore from "components/admin/AdminStore";
import AdminStoreStatusDetail from "components/admin/AdminStoreStatusDetail";
import { IRootState } from "modules";
import {
  deleteAdminFranchiseAction,
  getAdminMatchingListAction,
  getAdminStoreDetailAction,
  getAdminStoreListAction,
  getIdDuplicateCheckAction,
  getIdDuplicationCheckCancelAction,
  patchAdminMatchingFactoryAction,
  patchAdminUserNameModifyAction,
  patchFranchiseInfoAction,
  postAdminFranchiseContractAction,
  postPWInitializationAction,
} from "modules/admin/admin";
import { AdminFranchiseContract, AdminGetList, AdminMatchingData } from "modules/admin/types";
import { ProfileModify } from "modules/user/types";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import RoleUtils from "utils/RoleUtils";

interface AdminStoreContainerProps {
  match: any;
}

const AdminStoreContainer: React.FC<AdminStoreContainerProps> = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectType, setSelectType] = useState<string>("list");
  const [detailId, setDetailId] = useState<number | null>(null);
  const [auth, setAuth] = useState<string>("");
  const [searchData, setSearchData] = useState<AdminGetList>({
    query: "",
    dropBox: "",
    startDate: "",
    endDate: "",
    size: 10,
  });

  const role = useSelector((state: IRootState) => state.user.profile.type);

  const adminStoreList = useSelector((state: IRootState) => state.admin.adminStoreList);
  const adminStoreDetail = useSelector((state: IRootState) => state.admin.adminStoreDetail);
  const adminMatchingList = useSelector((state: IRootState) => state.admin.adminMatchingList);
  const adminIdDuplicateCheck = useSelector(
    (state: IRootState) => state.admin.adminIdDuplicateCheck
  );

  const storeListPageMove = (page: number, data: AdminGetList) => {
    setCurrentPage(page);
    setSearchData(data);
    dispatch(getAdminStoreListAction(page, data));
  };

  const storeDetail = (id: number) => {
    dispatch(getAdminStoreDetailAction(id));
  };

  const matchingList = () => {
    dispatch(getAdminMatchingListAction());
  };

  const matchingFactory = (payload: AdminMatchingData) => {
    dispatch(patchAdminMatchingFactoryAction(payload, currentPage!));
  };

  useEffect(() => {
    if (match.params.id && Number(match.params.id)) {
      setDetailId(match.params.id);
      setSelectType("detail");
      dispatch(getAdminStoreDetailAction(match.params.id));
    } else {
      setSelectType("list");
    }
  }, [match.params.id]);

  useEffect(() => {
    let authority = "";
    if (RoleUtils.isMasterAdmin(role)) authority = "admin";
    if (RoleUtils.isMaster(role)) authority = "master";
    if (RoleUtils.isStore(role)) authority = "store";
    if (RoleUtils.isFactory(role)) authority = "factory";
    setAuth(authority);
  }, [role]);

  const onSelectDetailId = (id: number) => {
    setDetailId(id);
    dispatch(getAdminStoreDetailAction(id));
    setSelectType("detail");
    history.push(`/admin/store/${id}`);
  };

  const query = window.location.pathname;

  useEffect(() => {
    if (query === `/${auth}/store/${match.params.id}`) setSelectType("detail");
    if (query === `/${auth}/store`) setSelectType("list");
  }, [query, auth]);

  useEffect(() => {
    dispatch(getAdminStoreListAction(1, null));
  }, [dispatch]);

  const modifyStoreUserName = (id: number, userName: string) => {
    dispatch(patchAdminUserNameModifyAction(id, userName, currentPage, searchData, "store"));
  };

  const postPWInitialization = (id: number) => {
    if (window.confirm("비밀번호를 초기화 하시겠습니까?")) dispatch(postPWInitializationAction(id));
  };

  const historyBack = () => {
    setDetailId(null);
    history.push(`/admin/store`);
  };

  const franchiseInfoModify = (payload: ProfileModify) => {
    dispatch(patchFranchiseInfoAction(detailId!, payload, currentPage, searchData));
  };

  const duplicateCheck = (id: string) => {
    dispatch(getIdDuplicateCheckAction(id));
  };

  const duplicateCheckCancel = () => {
    dispatch(getIdDuplicationCheckCancelAction());
  };

  const postfranchiseContract = (payload: AdminFranchiseContract) => {
    dispatch(postAdminFranchiseContractAction(payload, currentPage, searchData));
  };

  /** 지점 삭제 */
  const onDelete = () => {
    if (detailId) dispatch(deleteAdminFranchiseAction(detailId, currentPage, searchData));
    history.goBack();
  };

  return (
    <>
      {selectType === "list" && (
        <AdminStore
          role={role}
          adminStoreList={adminStoreList}
          adminStoreDetail={adminStoreDetail}
          storeListPageMove={storeListPageMove}
          storeDetail={storeDetail}
          matchingList={matchingList}
          adminMatchingList={adminMatchingList}
          matchingFactory={matchingFactory}
          modifyStoreUserName={modifyStoreUserName}
          postPWInitialization={postPWInitialization}
          onSelectDetailId={onSelectDetailId}
          postfranchiseContract={postfranchiseContract}
        />
      )}
      {selectType === "detail" && (
        <AdminStoreStatusDetail
          postPWInitialization={postPWInitialization}
          adminStoreDetail={adminStoreDetail}
          onModifyStoreUserName={modifyStoreUserName}
          onClose={() => console.log("close")}
          historyBack={historyBack}
          franchiseInfoModify={franchiseInfoModify}
          adminIdDuplicateCheck={adminIdDuplicateCheck}
          duplicateCheckRequest={duplicateCheck}
          duplicateCheckCancel={duplicateCheckCancel}
          onDelete={onDelete}
        />
      )}
    </>
  );
};

export default AdminStoreContainer;
