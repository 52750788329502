import React, { HTMLProps } from "react";
import styled from "styled-components";

interface PopupLargeLayoutProps extends Omit<HTMLProps<HTMLElement>, "to"> {}

const PopupLargeLayout: React.FC<PopupLargeLayoutProps> = ({ children }) => {
  return (
    <PopupLargeLayoutBlock>
      <section className="popupLargeLayout">{children}</section>
    </PopupLargeLayoutBlock>
  );
};

export default PopupLargeLayout;

const PopupLargeLayoutBlock = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 3;
  .popupLargeLayout {
    width: 60rem;
    height: 35rem;
    background-color: #f7f8f9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    padding: 3rem;
    overflow: hidden;
  }
`;
