import StoreStaffInventory from "components/store/StoreStaffInventory";
import { IRootState } from "modules";
import {
  getLabelStaffDepartmentListAction,
  getStaffDepartmentMemberAction,
  getStoreStaffDepartmentNameAction,
  patchLabelStaffDepartmentAction,
} from "modules/store/store";
import {
  LabelStaffDepartmentRequest,
  StoreStaffDepartmentListRequest,
} from "modules/store/types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const StoreStaffInventoryContainer = () => {
  const dispatch = useDispatch();

  const [currentPageState, setCurrentPageState] =
    useState<StoreStaffDepartmentListRequest>({
      page: 1,
      limit: 10,
      query: "",
      type: "",
    });

  useEffect(() => {
    dispatch(getStoreStaffDepartmentNameAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getLabelStaffDepartmentListAction(currentPageState));
  }, [dispatch, currentPageState]);

  const storeStaffDepartmentName = useSelector(
    (state: IRootState) => state.store.storeStaffDepartmentName
  );

  const storeStaffDepartmentUserName = useSelector(
    (state: IRootState) => state.store.storeStaffDepartmentUserName
  );

  const labelStaffDepartmentList = useSelector(
    (state: IRootState) => state.store.labelStaffDepartmentList
  );

  const getStoreStaffDepartmentUserName = (id: number) => {
    dispatch(getStaffDepartmentMemberAction(id));
  };

  const staffDepartmentListSearch = (data: StoreStaffDepartmentListRequest) => {
    setCurrentPageState(data);
  };

  const onPageMove = (page: number) => {
    setCurrentPageState((currentPageState) => ({
      ...currentPageState,
      page,
    }));
  };

  const selectShowEntries = (limit: number) => {
    setCurrentPageState((currentPageState) => ({
      ...currentPageState,
      limit,
      page: 1,
    }));
  };

  const patchLabelStaffDepartment = (data: LabelStaffDepartmentRequest) => {
    dispatch(patchLabelStaffDepartmentAction(data, currentPageState));
  };

  return (
    <StoreStaffInventory
      storeStaffDepartmentName={storeStaffDepartmentName}
      storeStaffDepartmentUserName={storeStaffDepartmentUserName}
      getStoreStaffDepartmentUserName={getStoreStaffDepartmentUserName}
      labelStaffDepartmentList={labelStaffDepartmentList}
      selectShowEntries={selectShowEntries}
      staffDepartmentListSearch={staffDepartmentListSearch}
      onPageMove={onPageMove}
      patchLabelStaffDepartment={patchLabelStaffDepartment}
    />
  );
};

export default StoreStaffInventoryContainer;
