import React, { useState } from "react";
import styled from "styled-components";
import Button from "./Button";
import { Palette, ThemeColor } from "styles/palette";
import { FranchiseGetList } from "modules/store/types";

import "date-fns";

import DateFormatUtils from "utils/DateFormatUtils";
import "react-datepicker/dist/react-datepicker.css";

interface LaundrySignUpSearchProps {
  role: string;
  onSearch: (page: number, data: FranchiseGetList) => void;
  size?: number;
}

const LaundrySignUpSearch: React.FC<LaundrySignUpSearchProps> = ({
  role,
  onSearch,
  size,
}) => {
  const [searchQuery, setSearchQuery] = useState<string | null>("");
  const [searchType, setSearchType] = useState<string | null>("franchiseName");
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const submitSearch = (e: any) => {
    e.preventDefault();
    const data = {
      query: searchQuery,
      dropBox: searchType,
      startDate: DateFormatUtils.dateToFormat(startDate),
      endDate: DateFormatUtils.dateToFormat(endDate),
      size: size ?? 10,
    };
    onSearch(1, data);
  };

  const selectHandleChange = (e: string) => {
    setSearchType(e);
  };

  //   const startDateSelector = (date: string) => {
  //     setStartDate(new Date(date));
  //   };

  //   const endDateSelector = (date: string) => {
  //     setEndDate(new Date(date));
  //   };

  return (
    <LaundrySignUpSearchBlock
      onSubmit={(e) => {
        submitSearch(e);
      }}
    >
      <select
        className="selectBox"
        value={searchType!}
        onChange={(e) => {
          selectHandleChange(e.target.value);
        }}
        disabled
      >
        <option value="franchiseName">지점명</option>
      </select>
      <input
        className="textBox"
        value={searchQuery ?? ""}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <Button
        theme={ThemeColor.primary}
        buttonSize="m"
        onClick={submitSearch}
        className="buttonSize"
      >
        검색
      </Button>
    </LaundrySignUpSearchBlock>
  );
};

export default LaundrySignUpSearch;

const LaundrySignUpSearchBlock = styled.form`
  display: inline-block;
  height: auto;

  & > select {
    border: 0;
    height: 100%;
  }
  & > input {
    border: 0;
    height: 100%;
  }

  & > .selectBox,
  .textBox {
    border: 1px solid #dee2e6;
    box-sizing: border-box;
    transition: ease-in-out;
    transition-duration: 0.1s;
    margin-right: 5px;
    border-radius: 3px;
  }
  & > .datePicker {
    display: inline-block;
    margin-right: 5px;
    color: black;
    cursor: default;
    /* box-sizing: border-box;
    transition: ease-in-out;
    transition-duration: 0.1s; */
  }
  & > .selectBox:hover,
  .textBox:hover {
    border-bottom: 2px solid ${Palette.primary};
  }

  & > .monthPicker {
    display: inline-block;
    margin-right: 5px;
    & > input {
      height: 37.5px;
      border-radius: 3px;
      border: 1px solid #dee2e6;
    }
  }
`;
// const DatePickerBlock = styled(DatePicker)`
//   width: 140px;
//   height: 100%;
//   border: 1px solid #dee2e6;
//   border-radius: 3px;
//   transition: ease-in-out;
//   transition-duration: 0.1s;
//   margin-right: 5px;
//   cursor: default;
//   &:hover {
//     border-bottom: 2px solid ${Palette.primary};
//   }
// `;
