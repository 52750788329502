import React, { useState } from "react";
import styled from "styled-components";
import Button from "./Button";
import RoleUtile from "utils/RoleUtils";
import { Palette, ThemeColor } from "styles/palette";

import "date-fns";

import DateFormatUtils from "utils/DateFormatUtils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RoleUtils from "utils/RoleUtils";
import { AdminGetList } from "modules/admin/types";

interface PaymentBrandSearchProps {
  role: string;
  onSearch: (data: AdminGetList) => void;
  size?: number;
}

const PaymentBrandSearch: React.FC<PaymentBrandSearchProps> = ({
  role,
  onSearch,
  size,
}) => {
  const [searchQuery, setSearchQuery] = useState<string | null>("");
  const [searchType, setSearchType] = useState<string | null>(
    "applicationDate"
  );
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const submitSearch = (e: any) => {
    e.preventDefault();
    const data = {
      query: searchQuery,
      dropBox: searchType,
      startDate: DateFormatUtils.dateToFormat(startDate),
      endDate: DateFormatUtils.dateToFormat(endDate),
      size: size ?? 10,
    };
    onSearch(data);
  };

  const selectHandleChange = (e: string) => {
    setSearchType(e);
  };

  return (
    <PaymentBrandSearchBlock
      onSubmit={(e) => {
        submitSearch(e);
      }}
    >
      {/* <select
        className="selectBox"
        value={searchType!}
        onChange={(e) => {
          selectHandleChange(e.target.value);
        }}
      >
        <option value="applicationDate">세탁신청일</option>
        <option value="completeDate">납품일</option>
        {!RoleUtils.isStore(role) && (
          <option value="franchiseName">지점명</option>
        )}
        {!RoleUtils.isFactory(role) && !RoleUtile.isMasterAdmin(role) && (
          <option value="factoryName">세탁공장명</option>
        )}
      </select>
      <div className="datePicker">
        <DatePickerBlock
          selected={startDate}
          dateFormat="yyyy년 MM월 dd일"
          onChange={(e: any) => {
            setStartDate(e);
          }}
        />
      </div>
      ~{" "}
      <div className="datePicker">
        <DatePickerBlock
          selected={endDate}
          dateFormat="yyyy년 MM월 dd일"
          onChange={(e: any) => {
            setEndDate(e);
          }}
        />
      </div> */}
      <input
        className="textBox"
        value={searchQuery ?? ""}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <Button
        theme={ThemeColor.primary}
        buttonSize="m"
        onClick={submitSearch}
        className="buttonSize"
      >
        검색
      </Button>
    </PaymentBrandSearchBlock>
  );
};

export default PaymentBrandSearch;

const PaymentBrandSearchBlock = styled.form`
  display: inline-block;
  height: auto;

  & > select {
    border: 0;
    height: 100%;
  }
  & > input {
    border: 0;
    height: 100%;
  }

  & > .selectBox,
  .textBox {
    border: 1px solid #dee2e6;
    box-sizing: border-box;
    transition: ease-in-out;
    transition-duration: 0.1s;
    margin-right: 5px;
    border-radius: 3px;
  }
  & > .selectBox:hover,
  .textBox:hover {
    border-bottom: 2px solid ${Palette.primary};
  }
  & > .datePicker {
    display: inline-block;
  }
`;
const DatePickerBlock = styled(DatePicker)`
  width: 140px;
  height: 100%;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  transition: ease-in-out;
  transition-duration: 0.1s;
  margin-right: 5px;
  cursor: default;
  &:hover {
    border-bottom: 2px solid ${Palette.primary};
  }
`;
