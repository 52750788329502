import AdminCalculateDetail from "components/admin/AdminCalculateDetail";
import { IRootState } from "modules";
import {
  getAdminCalculateDetailAction,
  getAdminCalculatePurchaseDetailAction,
  patchCalculateAction,
  patchCalculatePurchaseAction,
} from "modules/admin/admin";
import { AdminCalculateDetailResType } from "modules/admin/types";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import NumberUtils from "utils/NumberUtils";

interface AdminCalculateDetailContainerTypes {
  match: any;
}

function AdminCalculateDetailContainer(props: AdminCalculateDetailContainerTypes) {
  const history = useHistory();
  const dispatch = useDispatch();
  const query = window.location.pathname;

  const adminCalculateDetail = useSelector((state: IRootState) => state.admin.adminCalculateDetail);

  const [pathname, setPathname] = useState<string>("sales");
  const [detailId, setDetailId] = useState<string>("");

  /**
   * @amount 정산금액
   * @billingMonth 정산월
   * @billingStatus 정산상태
   * @completedDate 정산완료일시
   * @contractBasis 계약방식
   * @createdDate 등록일시
   * @endDate 정산기같에서 끝나는날짜
   * @estimatedAmount 예상 정산금액
   * @franchiseName 지점명
   * @factoryName 세탁공장명
   * @fee 정산비율
   * @id 아이디
   * @startDate 정산기간에서 시작하는 날짜
   * @taxCompletedDate 세금계산서 발행일시
   * @taxStatus 세금계산서 발행유무
   * @salesTotalAmount 매출합계
   */
  const [state, setState] = useState<AdminCalculateDetailResType>({
    amount: 0,
    billingMonth: "",
    billingStatus: "미완료",
    completedDate: "",
    contractBasis: "",
    createdDate: "",
    endDate: "",
    estimatedAmount: 0,
    franchiseName: "",
    factoryName: "",
    fee: 0,
    id: 0,
    startDate: "",
    taxCompletedDate: "",
    taxStatus: "미발행",
    salesTotalAmount: 0,
  });

  useEffect(() => {
    let id = props.match.params.id;

    if (query.includes("/sales")) {
      setPathname("sales");
      dispatch(getAdminCalculateDetailAction(id));
    }
    if (query.includes("/purchase")) {
      setPathname("purchase");
      dispatch(getAdminCalculatePurchaseDetailAction(id));
    }
    setDetailId(id);
  }, [query, props.match.params.id]);

  useEffect(() => {
    if (adminCalculateDetail) {
      let data = { ...adminCalculateDetail };

      setState(data);
    }
  }, [adminCalculateDetail]);

  /** state 데이터 변경 */
  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    let data: string | number = value;

    if (name === "amount") {
      data = Number(NumberUtils.numberOnlyToString(value));
    }

    setState({ ...state, [name]: data });
  };

  /** onChange 날짜 선택 (event로 데이터를 못받음) */
  const onDateSelector = (date: string, name: string) => {
    setState({ ...state, [name]: date });
  };

  /** 정보 변경 */
  const onModifySubmit = () => {
    if (window.confirm("반영하시겠습니까?")) {
      let billingStatus = "";
      let taxStatus = "";

      if (state.billingStatus === "정산완료") billingStatus = "Completion";
      if (state.billingStatus === "미완료") billingStatus = "UnCompletion";
      if (state.taxStatus === "발행완료") taxStatus = "Publish";
      if (state.taxStatus === "미발행") taxStatus = "UnPublish";

      if (state.taxStatus === "발행완료" && !state.taxCompletedDate)
        return alert("세금계산서 발행일시를 입력해주세요.");
      if (state.billingStatus === "정산완료" && !state.completedDate)
        return alert("정산완료 일시를 입력해주세요.");

      let req = {
        amount: state.amount,
        billingStatus,
        completedDate: state.completedDate
          ? moment(state.completedDate).format("YYYY-MM-DD")
          : null,
        id: state.id,
        taxCompletedDate: state.taxCompletedDate
          ? moment(state.taxCompletedDate).format("YYYY-MM-DD")
          : null,
        taxStatus,
      };

      if (pathname === "sales") dispatch(patchCalculateAction(req));
      if (pathname === "purchase") dispatch(patchCalculatePurchaseAction(req));
    }
  };

  /** 뒤로가기 */
  const goBack = () => {
    history.goBack();
  };

  return (
    <AdminCalculateDetail
      pathname={pathname}
      state={state ?? null}
      onChange={onChange}
      onDateSelector={onDateSelector}
      onModifySubmit={onModifySubmit}
      goBack={goBack}
    />
  );
}

export default AdminCalculateDetailContainer;
