import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "./Button";
import RoleUtile from "utils/RoleUtils";
import { Palette, ThemeColor } from "styles/palette";
import { FranchiseGetList } from "modules/store/types";

import "date-fns";

import DateFormatUtils from "utils/DateFormatUtils";

import "react-datepicker/dist/react-datepicker.css";
import RoleUtils from "utils/RoleUtils";
import DateApp from "./datePickerTest";

interface LaundrySearchProps {
  role: string;
  onSearch: (page: number, data: FranchiseGetList) => void;
  size?: number;
}

const LaundrySearch: React.FC<LaundrySearchProps> = ({ role, onSearch, size }) => {
  const params = new URLSearchParams(window.location.search);
  const urlParams = new URL(window.location.href);

  const [searchQuery, setSearchQuery] = useState<string | null>("");
  const [searchType, setSearchType] = useState<string | null>("applicationDate");
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  useEffect(() => {
    if (urlParams.searchParams.get("dropBox")) {
      setSearchType(urlParams.searchParams.get("dropBox"));
    }
    if (urlParams.searchParams.get("query")) {
      setSearchQuery(urlParams.searchParams.get("query"));
    }
    if (urlParams.searchParams.get("startDate") && urlParams.searchParams.get("endDate")) {
      setStartDate(new Date(String(urlParams.searchParams.get("startDate"))));
      setEndDate(new Date(String(urlParams.searchParams.get("endDate"))));
    }
  }, [urlParams.search]);

  const submitSearch = (e: any) => {
    e.preventDefault();
    const data = {
      query: searchQuery,
      dropBox: searchType,
      startDate: startDate ? DateFormatUtils.dateToFormat(startDate) : "",
      endDate: endDate ? DateFormatUtils.dateToFormat(endDate) : "",
      size: size ?? 10,
    };
    onSearch(1, data);
  };

  const selectHandleChange = (e: string) => {
    setSearchQuery("");
    if (searchType === "franchiseName" || searchType === "factoryName") {
      setStartDate(new Date());
      setEndDate(new Date());
    }
    setSearchType(e);
  };

  const startDateSelector = (date: string) => {
    setStartDate(new Date(date));
  };

  const endDateSelector = (date: string) => {
    setEndDate(new Date(date));
  };

  return (
    <LaundrySearchBlock
      onSubmit={(e) => {
        submitSearch(e);
      }}
    >
      <select
        className="selectBox"
        value={searchType!}
        onChange={(e) => {
          selectHandleChange(e.target.value);
        }}
      >
        <option value="applicationDate">세탁신청일</option>
        <option value="completeDate">납품일</option>
        {!RoleUtils.isStore(role) && <option value="franchiseName">지점명</option>}
        {!RoleUtils.isFactory(role) && !RoleUtile.isMasterAdmin(role) && (
          <option value="factoryName">세탁공장명</option>
        )}
      </select>
      {(searchType === "applicationDate" || searchType === "completeDate") && (
        <>
          <div className="datePicker">
            {/* <DatePickerBlock
          selected={startDate}
          dateFormat="yyyy년 MM월 dd일"
          onChange={(e: any) => {
            setStartDate(e);
          }}
        /> */}
            <DateApp
              dateItem={startDateSelector}
              today={
                urlParams.searchParams.get("startDate")
                  ? String(urlParams.searchParams.get("startDate"))
                  : DateFormatUtils.dateToFormat(startDate)
              }
            />
          </div>
          ~{" "}
          <div className="datePicker">
            {/* <DatePickerBlock
          selected={endDate}
          dateFormat="yyyy년 MM월 dd일"
          onChange={(e: any) => {
            setEndDate(e);
          }}
        /> */}
            <DateApp
              dateItem={endDateSelector}
              today={
                urlParams.searchParams.get("endDate")
                  ? String(urlParams.searchParams.get("endDate"))
                  : DateFormatUtils.dateToFormat(endDate)
              }
            />
          </div>
        </>
      )}
      {(searchType === "franchiseName" || searchType === "factoryName") && (
        <input
          className="textBox"
          value={searchQuery ?? ""}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      )}
      <Button
        theme={ThemeColor.primary}
        buttonSize="m"
        onClick={submitSearch}
        className="buttonSize"
      >
        검색
      </Button>
    </LaundrySearchBlock>
  );
};

export default LaundrySearch;

export const LaundrySearchBlock = styled.form`
  display: inline-block;
  height: auto;

  select {
    border: 0;
    height: 100%;
  }
  input {
    border: 0;
    height: 100%;
  }

  .selectBox,
  .textBox {
    border: 1px solid #dee2e6;
    box-sizing: border-box;
    transition: ease-in-out;
    transition-duration: 0.1s;
    margin-right: 5px;
    border-radius: 3px;
    height: 36.5px;
  }
  & > .datePicker {
    display: inline-block;
    margin-right: 5px;
    color: black;
    cursor: default;
    /* box-sizing: border-box;
    transition: ease-in-out;
    transition-duration: 0.1s; */
  }
  .selectBox:hover,
  .textBox:hover {
    border-bottom: 2px solid ${Palette.primary};
  }

  & > .monthPicker {
    display: inline-block;
    margin-right: 5px;
    & > input {
      height: 37.5px;
      border-radius: 3px;
      border: 1px solid #dee2e6;
    }
  }
`;
// const DatePickerBlock = styled(DatePicker)`
//   width: 140px;
//   height: 100%;
//   border: 1px solid #dee2e6;
//   border-radius: 3px;
//   transition: ease-in-out;
//   transition-duration: 0.1s;
//   margin-right: 5px;
//   cursor: default;
//   &:hover {
//     border-bottom: 2px solid ${Palette.primary};
//   }
// `;
