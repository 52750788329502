import ProfileStatusContainer from "containers/common/ProfileStatusContainer";
import React from "react";
import styled from "styled-components";

interface StoreProfileProps {}

const StoreProfileBlock = styled.div`
  /* height: 100%;
  padding: 3rem; */
`;

const StoreProfile: React.FC<StoreProfileProps> = () => {
  return (
    <StoreProfileBlock>
      <ProfileStatusContainer />
    </StoreProfileBlock>
  );
};

export default StoreProfile;
