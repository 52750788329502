import BrandSearch from "components/common/BrandSearch";
import Button from "components/common/Button";
import Paging from "components/common/Paging";
import {
  AdminBrandDetail,
  AdminBrandList,
  AdminGetList,
  AdminRegistration,
} from "modules/admin/types";
import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { Palette, ThemeColor } from "styles/palette";
import DateFormatUtils from "utils/DateFormatUtils";
import NumberUtils from "utils/NumberUtils";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

interface AdminBrandStatusProps {
  role: string;
  brandRegistration: (payload: AdminRegistration, data: AdminGetList) => void;
  adminBrandList: AdminBrandList | null;
  adminBrandDetail: AdminBrandDetail | null;
  brandListPageMove: (page: number, data: AdminGetList | null) => void;
  brandDetailStatus: (id: number) => void;
  modifyBrandUserName: (
    id: number,
    userName: string,
    page: number,
    data: AdminGetList | null
  ) => void;
  postPWInitialization: (id: number) => void;
  brandRegistrationStatus: () => void;
}

const AdminBrand: React.FC<AdminBrandStatusProps> = ({
  role,
  brandRegistration,
  adminBrandList,
  adminBrandDetail,
  brandListPageMove,
  brandDetailStatus,
  modifyBrandUserName,
  postPWInitialization,
  brandRegistrationStatus,
}) => {
  // const [registration, setRegistration] = useState(false);
  const [totalPage, setTotalPage] = useState<number | null | undefined>(null);
  // const [detail, setDetail] = useState(false);
  const [searchData, setSearchData] = useState<AdminGetList>({
    query: "",
    dropBox: "",
    startDate: "",
    endDate: "",
    size: 10,
  });
  // const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    setTotalPage(adminBrandList?.totalPage);
  }, [adminBrandList]);

  // const onClose = () => {
  //   setDetail(false);
  //   setRegistration(false);
  // };

  const onSearch = (page: number, data: AdminGetList) => {
    setSearchData(data);
    brandListPageMove(page, data);
  };

  const onDetail = (id: number) => {
    brandDetailStatus(id);
    // setDetail(true);
  };

  const pageMove = (page: number) => {
    // setCurrentPage(page);
    brandListPageMove(page, searchData ?? null);
  };

  const selectShowEntries = (size: number) => {
    searchData.size = size;
    brandListPageMove(1, searchData ?? null);
  };

  // const adminBrandRegistration = (payload: AdminRegistration) => {
  //   brandRegistration(payload, searchData ?? null);
  // };

  const [listNumber, setListNumber] = useState<number[]>([]);
  useEffect(() => {
    setListNumber(
      NumberUtils.numberToList(
        adminBrandList?.currentPage ?? 0,
        adminBrandList?.totalCount ?? 0,
        searchData.size ?? 10
      )
    );
  }, [adminBrandList, searchData]);

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      fontWeight: 700,
    },
  });
  const classes = useStyles();

  // const onModifyBrandUserName = (id: number, userName: string) => {
  //   modifyBrandUserName(id, userName, currentPage, searchData ?? null);
  // };

  return (
    <AdminBrandBlock>
      {/* {registration && (
        <AdminRegistrationLayout
          onClose={onClose}
          brandRegistration={adminBrandRegistration}
        />
      )} */}
      {/* {detail && (
        <AdminBrandStatusDetail
          adminBrandDetail={adminBrandDetail}
          onClose={onClose}
          // modifyBrandUserName={onModifyBrandUserName}
          postPWInitialization={postPWInitialization}
        />
      )} */}
      <div className="headers">
        <h3>브랜드 관리</h3>
      </div>
      <div className="tableSelect">
        <div>
          <span>Show </span>
          <select
            onChange={(e) => selectShowEntries(parseInt(e.target.value, 10))}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
          <span> entries</span>
        </div>
        <div className="topWrapper">
          <span>Search: </span>
          <div>
            <BrandSearch role={role} onSearch={onSearch} />
            <Button
              theme={ThemeColor.tertiary}
              buttonSize="m"
              onClick={brandRegistrationStatus}
            >
              등록
            </Button>
          </div>
        </div>
      </div>
      <div className="tableWrapper">
        <TableContainer component={Paper} className="tables">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "70px" }}
                >
                  순번
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900 }}>
                  아이디
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "35%" }}
                >
                  브랜드명
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "20%" }}
                >
                  연락처
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "20%" }}
                >
                  가입일
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {adminBrandList?.list.map((brand, index) => (
                <TableRow
                  key={index}
                  className="tableHover"
                  onClick={() => onDetail(brand.id)}
                >
                  <TableCell component="th" scope="row" align="center">
                    {listNumber[index]}
                  </TableCell>
                  <TableCell align="center">{brand.manager.username}</TableCell>
                  <TableCell align="center">{brand.companyName}</TableCell>
                  <TableCell align="center">{brand.companyPhone}</TableCell>
                  <TableCell align="center">
                    {DateFormatUtils.dateToFormat(brand.joinDate)}
                  </TableCell>
                  {/* <TableCell align="center">
                    <Button
                      theme={ThemeColor.primary}
                      buttonSize="s"
                      onClick={() => onDetail(brand.id)}
                    >
                      상세보기
                    </Button>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Paging
          listAction={pageMove}
          listCount={totalPage ?? 1}
          currentPage={adminBrandList?.currentPage}
          totalCount={adminBrandList?.totalCount ?? 1}
          size={searchData.size ?? 10}
        ></Paging>
      </div>
    </AdminBrandBlock>
  );
};

const AdminBrandBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  .tables {
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .tableHover > td {
    padding: 10px;
  }
  .tableHover:hover {
    background-color: #eee;
    cursor: pointer;
  }
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  .tableSelect {
    height: 70px;
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: space-between;
    & > div {
      color: #999;
      height: 40px;
      overflow: hidden;
      & > span {
        font-size: 14px;
        line-height: 37px;
      }
      & > select {
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        transition: ease-in-out;
        transition-duration: 0.1s;
        border-radius: 3px;
      }
      & > select:hover {
        border-bottom: 2px solid ${Palette.primary};
      }
    }
  }
  .topWrapper {
    display: flex;
    justify-content: flex-end;
    & > span {
      font-size: 14px;
      line-height: 37px;
      margin-right: 5px;
    }
    & > div {
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div {
        margin: 0;
        margin-left: 5px;
      }
    }
  }
  .tableWrapper {
    min-width: 51rem;
    padding-bottom: 1.5rem;
  }
`;

export default AdminBrand;
