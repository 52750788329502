import TransactionTable from "components/common/TransactionTable";
import MasterItemizedAccount from "components/master/MasterItemizedAccount";
import { IRootState } from "modules";
import { getBrandSpecificationsListAction } from "modules/master/master";
import { MasterGetList } from "modules/master/types";
import {
  getExcelDataAction,
  getSpecificationsDetailAction,
  getSpecificationsFranchiseListAction,
  getTransExcelDataAction,
} from "modules/user/user";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import DateFormatUtils from "utils/DateFormatUtils";

interface MasterItemizedAccountContainerProps {
  match: any;
}

const MasterItemizedAccountContainer: React.FC<MasterItemizedAccountContainerProps> =
  ({ match }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const query = window.location.pathname;

    const [selectType, setSelectType] = useState<string>("list");
    const [selectDetailTitle, setSelectDetailTitle] = useState({
      title: "",
      name: "",
      date: "",
      chartType: "",
    });

    // const [searchData, setSearchData] = useState<MasterGetList | null>({
    //   query: "",
    //   dropBox: "",
    //   startDate: "",
    //   endDate: "",
    //   size: 10,
    // });
    // const [searchPage, setSearchPage] = useState<number>(1);
    // const [searchId, setSearchId] = useState<number | null>(null);

    const role = useSelector((state: IRootState) => state.user.profile.type);
    const brandSpecificationsList = useSelector(
      (state: IRootState) => state.master.brandSpecificationsData
    );
    const specificationsDetailData = useSelector(
      (state: IRootState) => state.user.specificationsDetail
    );
    const specificationsFranchiseList = useSelector(
      (state: IRootState) => state.user.specificationsFranchiseList
    );

    useEffect(() => {
      if (query === `/master/itemizedAccount/${match.params.id}`)
        setSelectType("detail");
      if (query === `/master/itemizedAccount`) setSelectType("list");
    }, [query]);

    useEffect(() => {
      dispatch(getSpecificationsFranchiseListAction());
      dispatch(getBrandSpecificationsListAction(null, 1, null));
    }, [dispatch]);

    const specificationsListSearch = (
      id: number | null,
      page: number,
      data: MasterGetList | null
    ) => {
      // setSearchId(id);
      // setSearchData(data ?? null);
      // setSearchPage(page);
      dispatch(getBrandSpecificationsListAction(id, page, data));
    };

    const specificationsDetail = (
      id: number,
      title: string,
      name: string,
      date: Date,
      chartType: string
    ) => {
      const selectDate = DateFormatUtils.dateToFormat(date);
      setSelectDetailTitle({ title, name, date: selectDate, chartType });
      dispatch(getSpecificationsDetailAction(id));
    };

    useEffect(() => {
      if (match.params.id && Number(match.params.id)) {
        // setSearchId(match.params.id);
        setSelectType("detail");
        dispatch(getSpecificationsDetailAction(match.params.id));
        dispatch(getExcelDataAction(match.params.id));
      } else {
        setSelectType("list");
        // history.push(match.path);
      }
    }, [match.params.id]);

    useEffect(() => {
      history.push(`/master/itemizedAccount`);
      setSelectType("list");
    }, []);

    const historyBack = () => {
      setSelectDetailTitle({
        title: "",
        name: "",
        date: "",
        chartType: "",
      });
      setSelectType("list");
    };

    const excelFile = useSelector((state: IRootState) => state.user.excelData);
    const transExcelFile = useSelector(
      (state: IRootState) => state.user.transExcelData
    );

    const getExcelData = () => {
      dispatch(getExcelDataAction(match.params.id));
    };
    const getTransExcelData = () => {
      dispatch(getTransExcelDataAction(match.params.id));
    };

    return (
      <>
        {selectType === "list" && (
          <MasterItemizedAccount
            role={role}
            specificationsDetail={specificationsDetail}
            setSelectType={setSelectType}
            brandSpecificationsList={brandSpecificationsList}
            specificationsListSearch={specificationsListSearch}
            specificationsFranchiseList={specificationsFranchiseList}
          />
        )}
        {selectType === "detail" && (
          <TransactionTable
            role={role}
            selectDetailTitle={selectDetailTitle}
            specificationsDetailData={specificationsDetailData}
            historyBack={historyBack}
            excelFile={excelFile}
            transExcelFile={transExcelFile}
            getExcelData={getExcelData}
            getTransExcelData={getTransExcelData}
          />
        )}
      </>
    );
  };

export default MasterItemizedAccountContainer;
