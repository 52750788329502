import FactoryTableReader from "components/factory/FactoryTableReader";
import { IRootState } from "modules";
import {
  activationTableReaderServerAction,
  getFactoryFranchiseLaundryCompleteDateAction,
  getFactoryFranchiseLaundryDateAction,
  getFactoryLaundryDetailAction,
  getFactoryMacAddressAction,
  getFactoryMatchinsgFranchiseListAction,
  getFactoryTagDataAction,
  getFranchiseProgressListAction,
  postDirectAmountCompleteAction,
  postDirectReLaundryAction,
  postFactoryLaundryTagRequestAction,
  postFactoryMacAddressAction,
  postFactoryTagLaundryCompleteAction,
  postReLaundryTagRequestAction,
} from "modules/factory/factory";
import {
  FactoryGetTagData,
  FactoryTableDirectData,
  FactoryTableReadingData,
  FactoryTableRequest,
} from "modules/factory/types";
import { getProfileDetailAction } from "modules/user/user";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

interface FactoryTableReaderContainerProps {}

const FactoryTableReaderContainer: React.FC<FactoryTableReaderContainerProps> =
  () => {
    const dispatch = useDispatch();

    const layoutHeight = useSelector(
      (state: IRootState) => state.user.layoutHeight
    );
    const factoryMatchingFranchiseList = useSelector(
      (state: IRootState) => state.factory.factoryMatchingFranchiseList
    );

    const franchiseProgressList = useSelector(
      (state: IRootState) => state.factory.factoryFranchiseProgressList
    );

    const factoryMacAddress = useSelector(
      (state: IRootState) => state.factory.factoryMacAddress
    );

    const responseTagData = useSelector(
      (state: IRootState) => state.factory.factoryGetTagResponseData
    );

    const franchiseLaundryDate = useSelector(
      (state: IRootState) => state.factory.factoryFranchiseLaundryDate
    );

    const franchiseLaundryCompleteDate = useSelector(
      (state: IRootState) => state.factory.factoryFranchiseLaundryCompleteDate
    );

    const user = useSelector((state: IRootState) => state.user.profile);

    const userDetail = useSelector(
      (state: IRootState) => state.user.profileDetail
    );

    useEffect(() => {
      dispatch(getProfileDetailAction(user.type));
      dispatch(getFactoryMatchinsgFranchiseListAction());
    }, [dispatch]);

    useEffect(() => {
      if (userDetail) dispatch(getFactoryMacAddressAction(userDetail.id));
    }, [userDetail]);

    const getProgress = (id: number) => {
      dispatch(getFranchiseProgressListAction(id));
    };

    const postFactoryMacAddress = (macAdd: string) => {
      dispatch(postFactoryMacAddressAction(Number(userDetail?.id), macAdd));
    };

    const postFactoryLaundrayTagRequest = (data: FactoryTableRequest) => {
      dispatch(postFactoryLaundryTagRequestAction(data));
    };

    const postFactoryTagLaundryComplete = (data: FactoryTableReadingData) => {
      dispatch(postFactoryTagLaundryCompleteAction(data));
    };

    const getTagData = (data: FactoryGetTagData) => {
      dispatch(getFactoryTagDataAction(data));
    };

    const postReLaundryTagRequest = (data: FactoryTableReadingData) => {
      dispatch(postReLaundryTagRequestAction(data));
    };

    const postDirectAmountComplete = (data: FactoryTableDirectData) => {
      dispatch(postDirectAmountCompleteAction(data));
    };

    const postDirectReLaundry = (data: FactoryTableDirectData) => {
      dispatch(postDirectReLaundryAction(data));
    };

    const getFranchiseLaundryDate = async (id: number) => {
      await dispatch(getFactoryFranchiseLaundryDateAction(id));
      await dispatch(getFactoryFranchiseLaundryCompleteDateAction(id));
    };

    const activationTableReaderServer = () => {
      dispatch(activationTableReaderServerAction());
    };

    return (
      <FactoryTableReader
        layoutHeight={layoutHeight!}
        user={user}
        factoryMatchingFranchiseList={factoryMatchingFranchiseList}
        getProgressList={getProgress}
        franchiseProgressList={franchiseProgressList}
        factoryMacAddress={factoryMacAddress}
        postFactoryMacAddress={postFactoryMacAddress}
        postFactoryTagLaundryComplete={postFactoryTagLaundryComplete}
        postFactoryLaundrayTagRequest={postFactoryLaundrayTagRequest}
        getTagData={getTagData}
        responseTagData={responseTagData}
        postReLaundryTagRequest={postReLaundryTagRequest}
        postDirectAmountComplete={postDirectAmountComplete}
        postDirectReLaundry={postDirectReLaundry}
        getFranchiseLaundryDate={getFranchiseLaundryDate}
        franchiseLaundryDate={franchiseLaundryDate ? franchiseLaundryDate : []}
        franchiseLaundryCompleteDate={
          franchiseLaundryCompleteDate ? franchiseLaundryCompleteDate : []
        }
        activationTableReaderServer={activationTableReaderServer}
      />
    );
  };

export default FactoryTableReaderContainer;
