import { MasterGetList } from "modules/master/types";
import React, { useState } from "react";
import styled from "styled-components";
import { ThemeColor } from "styles/palette";
import Button from "./Button";
import { LaundrySearchBlock } from "./LaundrySearch";

import "date-fns";

import DateFormatUtils from "utils/DateFormatUtils";
import { BoardGetList } from "modules/user/types";

interface NoticeSearchProps {
  role: string;
  onSearch: (page: number, data: BoardGetList) => void;
  size?: number;
}

const NoticeSearch: React.FC<NoticeSearchProps> = ({ onSearch, size }) => {
  const [searchQuery, setSearchQuery] = useState<string | null>("");
  const [searchType, setSearchType] = useState<string | null>("title");

  const submitSearch = (e: any) => {
    e.preventDefault();
    const data = {
      query: searchQuery,
      dropBox: searchType,
      size: size ?? 10,
    };
    onSearch(1, data);
  };

  // const selectHandleChange = (e: string) => {
  //   setSearchType(e);
  // };

  return (
    <NoticeSearchBlock onSubmit={(e) => submitSearch(e)}>
      {/* <select
        className="selectBox"
        value={searchType!}
        onChange={(e) => {
          selectHandleChange(e.target.value);
        }}
      >
        <option value="title">제목</option>
        <option value="writer">작성자</option>
      </select> */}
      <input
        className="textBox"
        value={searchQuery ?? ""}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <Button
        theme={ThemeColor.primary}
        buttonSize="m"
        onClick={submitSearch}
        className="buttonSize"
      >
        검색
      </Button>
    </NoticeSearchBlock>
  );
};

export default NoticeSearch;

export const NoticeSearchBlock = styled(LaundrySearchBlock)``;
