import MasterFactoryStatusDetail from "components/master/MasterFactoryDetail";
import MasterFactoryStatus from "components/master/MasterFactoryStatus";
import { IRootState } from "modules";
import {
  getMasterFactoryDetailAction,
  getMasterFactoryListAction,
} from "modules/master/master";
import { MasterGetList } from "modules/master/types";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

interface MasterFactoryStatusContainerProps {
  match: any;
}

const MasterFactoryStatusContainer: React.FC<MasterFactoryStatusContainerProps> =
  ({ match }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const role = useSelector((state: IRootState) => state.user.profile.type);
    const masterFactoryList = useSelector(
      (state: IRootState) => state.master.masterFactoryList
    );
    const masterFactoryDetail = useSelector(
      (state: IRootState) => state.master.masterFactoryDetail
    );

    // const [detailId, setDetailId] = useState<number | null>(null);
    const [selectType, setSelectType] = useState<string>("list");

    useEffect(() => {
      dispatch(getMasterFactoryListAction(1, null));
    }, [dispatch]);

    useEffect(() => {
      if (match.params.id && Number(match.params.id)) {
        // setDetailId(match.params.id);
        setSelectType("detail");
        dispatch(getMasterFactoryDetailAction(match.params.id));
      } else {
        setSelectType("list");
      }
    }, [match.params.id]);

    const query = window.location.pathname;

    useEffect(() => {
      if (query === `/master/factory`) {
        setSelectType("list");
        // setDetailId(null);
      } else {
        setSelectType("detail");
      }
    }, [query]);

    const factoryList = (page: number, data: MasterGetList | null) => {
      dispatch(getMasterFactoryListAction(page, data));
    };
    const factoryDetail = (id: number) => {
      // setDetailId(id);
      dispatch(getMasterFactoryDetailAction(id));
      setSelectType("detail");
      history.push(`/master/factory/${id}`);
    };

    const onClose = () => {
      // setDetailId(null);
      setSelectType("list");
      history.push(`/master/factory`);
    };
    // if (!masterFactoryList) return <div>로딩중</div>;
    return (
      <>
        {selectType === "list" && (
          <MasterFactoryStatus
            role={role}
            masterFactoryList={masterFactoryList}
            factoryList={factoryList}
            masterFactoryDetail={masterFactoryDetail}
            factoryDetail={factoryDetail}
          />
        )}
        {selectType === "detail" && (
          <MasterFactoryStatusDetail
            onClose={onClose}
            masterFactoryDetail={masterFactoryDetail}
          />
        )}
      </>
    );
  };

export default MasterFactoryStatusContainer;
