import CheckLocation from 'components/common/CheckLocation';
import React from 'react';

interface CheckLocationContainerProps {}

const CheckLocationContainer: React.FC<CheckLocationContainerProps> = () => {
  return (
    <CheckLocation />
  );
}

export default CheckLocationContainer;