import React from "react";
import styled from "styled-components";

import { useState } from "react";

import { StatusDetailBlock } from "components/common/LaundaryStatusDetail";
import Button from "components/common/Button";
import { FactoryBillingDetail, Item } from "modules/factory/types";
import { ThemeColor } from "styles/palette";
import { CenterAligned } from "components/common/layouts/CenterAligned";
import { useEffect } from "react";
import PriceUtils from "utils/PriceUtils";
import ScrollUtils from "utils/ScrollUtils";
import DateFormatUtils from "utils/DateFormatUtils";
import NumberUtils from "utils/NumberUtils";

interface FactoryPaymentStatusDetailProps {
  onClose: () => void;
  factoryBillingDetail: FactoryBillingDetail | null;
  patchFactoryLaundryData: (id: number, price: number, status: string) => void;
}

const FactoryPaymentStatusDetail: React.FC<FactoryPaymentStatusDetailProps> = ({
  onClose,
  factoryBillingDetail,
  patchFactoryLaundryData,
}) => {
  const [modifyCheck, setModifyCheck] = useState(false);
  const [price, setPrice] = useState<number>(0);
  const [status, setStatus] = useState<string>("");
  useEffect(() => {
    setPrice(factoryBillingDetail?.price ? factoryBillingDetail.price : 0);
    setStatus(factoryBillingDetail?.status ? factoryBillingDetail?.status : "");
  }, [factoryBillingDetail]);

  useEffect(() => {
    ScrollUtils.onPopupScrollStop(window.scrollY);
    return () => {
      const scrollY = document.body.style.top;
      ScrollUtils.closePopupScrollRun(scrollY);
    };
  }, []);

  const modifyConfirm = (id: number) => {
    patchFactoryLaundryData(id, price, status);
    setModifyCheck(!modifyCheck);
  };

  const onCancel = () => {
    setPrice(factoryBillingDetail?.price ? factoryBillingDetail.price : 0);
    setStatus(factoryBillingDetail?.status ? factoryBillingDetail?.status : "");
    setModifyCheck(false);
  };

  const [paymentDetailItems, setPaymentDetailItems] = useState<Item[]>([]);

  useEffect(() => {
    setPaymentDetailItems(factoryBillingDetail?.items ?? []);
  }, []);

  if (!factoryBillingDetail) return null;
  return (
    <FactoryPaymentStatusDetailBlock>
      <div className="headers">
        <h3>정산현황 상세정보</h3>
        <Button theme={ThemeColor.primary} buttonSize="m" onClick={onClose}>
          뒤로가기
        </Button>
      </div>
      <div className="contentWrapper">
        <div className="detailInfo">
          <div className="field">
            <span>지점명</span>
            <p>{factoryBillingDetail?.franchiseName}</p>
          </div>
          <div className="field">
            <span>세탁공장</span>
            <p>{factoryBillingDetail?.factoryName}</p>
          </div>
          <div className="field">
            <span>세탁 신청일</span>
            <p>
              {DateFormatUtils.dateToFormat(
                factoryBillingDetail?.applicationDate
              )}
            </p>
          </div>
          <div className="field">
            <span>납품일</span>
            <p>
              {DateFormatUtils.dateToFormat(
                factoryBillingDetail?.completedDate
              )}
            </p>
          </div>
          <div className="field">
            <span>입고 수량(포대/카트)</span>
            <p>
              {NumberUtils.numberToComma(factoryBillingDetail?.bagCount ?? 0)}
            </p>
          </div>
          <div className="field">
            <span>세탁 수량(갯수)</span>
            <p>
              {NumberUtils.numberToComma(
                factoryBillingDetail?.totalAmount ?? 0
              )}
            </p>
          </div>
          <div className="field">
            <span>결제금액(원)</span>
            <p>{NumberUtils.numberToComma(price)}</p>
            {/* <input
              type="number"
              name="price"
              value={price}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setPrice(parseInt(e.target.value, 10));
              }}
            /> */}
          </div>
          {/* <div className="field">
            <span>정산상태</span>
            <p>
              {PriceUtils.priceStatusToString(factoryBillingDetail?.status)}
            </p>
          </div> */}
          {/* <div style={{ float: "right" }}>
            <>
              <Button
                theme={ThemeColor.primary}
                buttonSize="m"
                onClick={() => {
                  onCancel();
                }}
              >
                취소
              </Button>
              <Button
                theme={ThemeColor.primary}
                buttonSize="m"
                onClick={() => {
                  modifyConfirm(factoryBillingDetail.billingId);
                }}
              >
                수정
              </Button>
            </>
          </div> */}
        </div>
        <div className="stockStatus">
          <div>
            <h3>세탁물 종류</h3>
            <div className="stockInField">
              <div>
                <div className="stockHeader">
                  <span>대분류</span>
                  <span>소분류</span>
                  <span>품명</span>
                  <span>사이즈</span>
                  <span>비고</span>
                  <span>세탁신청(개)</span>
                  <span>세탁완료(개)</span>
                  <span>재세탁(반품)</span>
                  <span>기타(미납,수선)</span>
                </div>
                <div className="stockList">
                  {factoryBillingDetail?.items.map((category, idx) => (
                    <div className="field" key={idx}>
                      <span>{category.asset.details.largeCategory}</span>
                      <span>{category.asset.details.subCategory}</span>
                      <span>{category.asset.details.name}</span>
                      <span>{category.asset.details.size}</span>
                      <span>{category.asset.details.content}</span>
                      <span>
                        {category.beforeAmount
                          ? NumberUtils.numberToComma(
                              category.beforeAmount ?? 0
                            )
                          : "-"}
                      </span>
                      <span>
                        {category.amount
                          ? NumberUtils.numberToComma(category.amount ?? 0)
                          : "-"}
                      </span>
                      <span>
                        {category.relaundering
                          ? NumberUtils.numberToComma(category.relaundering)
                          : "-"}
                      </span>
                      <span>-</span>
                    </div>
                  ))}

                  <div className="field">
                    <span>포대/카트</span>
                    <span>-</span>
                    <span>-</span>
                    <span>-</span>
                    <span>-</span>
                    <span>
                      {NumberUtils.numberToComma(
                        factoryBillingDetail?.bagCount ?? 0
                      )}
                    </span>
                    <span>
                      {NumberUtils.numberToComma(
                        factoryBillingDetail.outBagCount ?? 0
                      )}
                    </span>
                    <span>-</span>
                    <span>-</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FactoryPaymentStatusDetailBlock>
  );
};

export default FactoryPaymentStatusDetail;

const FactoryPaymentStatusDetailBlock = styled(StatusDetailBlock)`
  p {
    width: 15rem;
  }
  .field {
    & > input,
    select {
      width: 240px;
    }
    & > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;
