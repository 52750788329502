import StoreProfile from 'components/store/StoreProfile';
import React from 'react';

interface StoreProfileContainerProps {}

const StoreProfileContainer: React.FC<StoreProfileContainerProps> = () => {
  return <StoreProfile />;
};

export default StoreProfileContainer;
