import Button from "components/common/Button";
import PopupSmallLayout from "components/common/PopupSmallLayout";
import { MasterItemRegistration } from "modules/master/types";
import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { ThemeColor } from "styles/palette";
import { Inventory } from "utils/DummyUtils";
import ScrollUtils from "utils/ScrollUtils";

interface MasterItemAddProps {
  type: string;
  onClose: () => void;
  inventory?: Inventory;
  masterItemRegistration: (data: MasterItemRegistration) => void;
}

const MasterItemAdd: React.FC<MasterItemAddProps> = ({
  type,
  onClose,
  inventory,
  masterItemRegistration,
}) => {
  const [large, setLarge] = useState<string>("");
  const [sub, setSub] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [size, setSize] = useState<string>("");
  const [content, setContent] = useState<string>("");

  const ItemRegistration = () => {
    if (!large) return `대분류를 입력해주세요.`;
    if (!sub) return `소분류를 입력해주세요`;
    if (!name) return `품명을 입력해주세요`;
    if (!content) return `비고를 입력해주세요`;
    if (!size) return `사이즈를 입력해주세요.`;

    const data = {
      largeCategory: large,
      subCategory: sub,
      name: name,
      size: size,
      content: content,
    };

    masterItemRegistration(data);
    onClose();
  };

  useEffect(() => {
    ScrollUtils.onPopupScrollStop(window.scrollY);
    return () => {
      const scrollY = document.body.style.top;
      ScrollUtils.closePopupScrollRun(scrollY);
    };
  }, []);
  return (
    <MasterItemAddBlock>
      <PopupSmallLayout>
        <h2>
          {type === "enroll" && "재고 등록"}
          {type === "modify" && "재고 수정"}
        </h2>
        <article className="popupInput">
          <div className="inputField">
            <div className="field">
              <span>대분류</span>
              <input value={large} onChange={(e) => setLarge(e.target.value)} />
            </div>
            <div className="field">
              <span>소분류</span>
              <input value={sub} onChange={(e) => setSub(e.target.value)} />
            </div>
            <div className="field">
              <span>품명</span>
              <input value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="field">
              <span>사이즈</span>
              <input value={size} onChange={(e) => setSize(e.target.value)} />
            </div>
            <div className="field">
              <span>비고</span>
              <input
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </div>
          </div>
        </article>
        <div className="buttonWrapper">
          <Button theme={ThemeColor.primary} buttonSize="m" onClick={onClose}>
            취소
          </Button>
          <Button
            theme={ThemeColor.primary}
            buttonSize="m"
            onClick={ItemRegistration}
          >
            저장
          </Button>
        </div>
      </PopupSmallLayout>
    </MasterItemAddBlock>
  );
};

export default MasterItemAdd;

const MasterItemAddBlock = styled.div`
  position: relative;
  z-index: 3;
  article {
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: space-between;
    h1 {
      margin: 0;
      font-size: 1.5rem;
    }
    .topButtonWrapper {
      margin-top: 2rem;
      height: 2rem;
    }
  }
  h2 {
    margin-top: 2.5rem;
    margin-left: 3rem;
    color: #016241;
  }
  .popupInput {
    flex: 1;
    width: 21.5rem;
    margin: 0 auto;
    .inputField {
      width: 100%;
      margin-top: 10rem;
      .field {
        /* height: 2.1rem; */
        display: grid;
        grid-template-columns: 8rem 1fr;
        align-items: center;
        select,
        input {
          height: 2.1rem;
        }
      }
      .field + .field {
        margin-top: 0.9rem;
      }
    }
  }
  .buttonWrapper {
    margin: 0 auto;
    padding: 2rem;
  }
`;
