import React from "react";
import styled from "styled-components";

interface PreparedProps {}

const PreparedBlock = styled.div`
  text-align: center;
  padding: 6rem 0;
  font-size: 2rem;
  font-weight: bold;
  color: #333;
`;

const Prepared: React.FC<PreparedProps> = () => {
  return <PreparedBlock>404 잘못된 경로입니다.</PreparedBlock>;
};

export default Prepared;
