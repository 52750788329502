import AdminCalculateManagement from "components/admin/AdminCalculateManagement";
import { CalculateListSearchTypes } from "interface/Admin/CalculateListTypes";
import { IRootState } from "modules";
import {
  getAdminCalculateListAction,
  getAdminCalculatePurchaseListAction,
  getAdminPayManagementListAction,
  patchAdminPaymanagementDataAction,
  resetCalculateListAction,
} from "modules/admin/admin";
import { AdminCalculateListType, AdminGetList } from "modules/admin/types";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import queryString from "query-string";

interface AdminCalculateManagementProps {}

const AdminCalculateManagementContainer: React.FC<AdminCalculateManagementProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  /** 매출: sales, 매입: purchase */
  const [pathname, setPathname] = useState<string>("sales");

  const [searchData, setSearchData] = useState<CalculateListSearchTypes>({
    billingStatus: "",
    taxStatus: "",
    limit: 10,
    page: 1,
    query: "",
  });

  const [pageInfo, setPageInfo] = useState({
    totalCount: 0,
    totalPage: 0,
  });

  const [calculateList, setCalculateList] = useState<AdminCalculateListType[]>([]);

  const query = window.location.pathname;

  const adminCalculateList = useSelector((state: IRootState) => state.admin.adminCalculateList);

  let queryItem = queryString.parse(history.location.search);

  useEffect(() => {
    let data: CalculateListSearchTypes = {
      billingStatus: String(queryItem.billingStatus ?? ""),
      taxStatus: String(queryItem.taxStatus ?? ""),
      limit: Number(queryItem.limit ?? 10),
      page: Number(queryItem.page ?? 1),
      query: String(queryItem.query ?? ""),
    };

    setTimeout(() => {
      setSearchData(data);

      if (query.includes("/purchase")) {
        listType("purchase", history.location.search);
        dispatch(getAdminCalculatePurchaseListAction(data));
      } else {
        listType("sales", history.location.search);
        dispatch(getAdminCalculateListAction(data));
      }
    }, 10);
  }, [history.location.search, query]);

  useEffect(() => {
    if (adminCalculateList) {
      setPageInfo({
        totalCount: adminCalculateList.totalCount,
        totalPage: adminCalculateList.totalPage,
      });
      setCalculateList(adminCalculateList.list);
      setSearchData({ ...searchData, page: adminCalculateList.currentPage });
    }
  }, [adminCalculateList]);

  /** 정산 액션 호출 */
  const onSubmit = (data?: CalculateListSearchTypes) => {
    let item = { ...searchData };

    if (data) item = data;

    setSearchData(item);

    history.push(
      `${history.location.pathname}?page=${item.page}&limit=${item.limit}&billingStatus=${item.billingStatus}&taxStatus=${item.taxStatus}&query=${item.query}`
    );

    if (pathname === "sales") dispatch(getAdminCalculateListAction(item));
    if (pathname === "purchase") dispatch(getAdminCalculatePurchaseListAction(item));
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    setSearchData({ ...searchData, [name]: value });
  };

  /** 매출, 매입선택
   * @type 매출: sales, 매입: purchase
   */
  const listType = (type: string, search?: string) => {
    dispatch(resetCalculateListAction());
    if (type !== pathname) {
      setPathname(type);
      history.push(`/admin/calculate/${type}`);
    }

    if (search && type === pathname) {
      history.push(`/admin/calculate/${type}${search}`);
    }
  };

  /** 상세 페이지 이동 */
  const goPage = (id: number) => {
    history.push(`/admin/calculate/${pathname}/${id}`);
  };

  return (
    <>
      <AdminCalculateManagement
        calculateList={calculateList}
        pageInfo={pageInfo}
        pathname={pathname}
        searchData={searchData}
        listType={listType}
        onSubmit={onSubmit}
        onChange={onChange}
        goPage={goPage}
      />
    </>
  );
};

export default AdminCalculateManagementContainer;
