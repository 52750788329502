import React, {
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
  forwardRef,
  memo,
} from "react";
import { VariableSizeList as List } from "react-window";
import { useRaf } from "./useRaf";
import { SIZE } from "./util";

const TITLE_HEIGHT = 24;
const BODY_HEIGHT = 32 * 3;
const DURATION = 300;

const monthFormatters: any = {};

function getMonthFormatter(locale: any) {
  if (!monthFormatters[locale]) {
    monthFormatters[locale] = Intl.DateTimeFormat(locale, { month: "short" });
  }
  return monthFormatters[locale];
}

function today() {
  const date = new Date();
  return date.getFullYear() * 12 + date.getMonth();
}

export const MonthYearAccordian = forwardRef(
  ({ defaultValue, onChange, autoFocus }: any, ref: any) => {
    const listRef = useRef<any>();
    const scrollRef = useRef<any>(0);
    const scrollAnimRef = useRef<any>();
    const itemsRef = useRef<any>({ list: [], lookup: {} });
    const valueRef = useRef<any>(defaultValue || today());
    const elRef = useRef<any>();
    const callbacksRef = useRef<any>({});

    // function getTop(index: any) {
    //   let top = index * TITLE_HEIGHT;
    //   for (let item in itemsRef.current) {
    //     if (item.index < index) {
    //       //  top +=  TITLE_HEIGHT
    //     }
    //   }
    //   return top;
    // }

    function setOpen({ list, lookup }: any, index: any, instant: any) {
      if (lookup[index] && lookup[index].open) return { list, lookup };
      const _list = (lookup[index] ? list : [...list, { index }])
        .map((item: any) =>
          item.index === index
            ? {
                ...item,
                start: instant ? 0 : performance.now(),
                open: true,
                delta: BODY_HEIGHT,
                duration: DURATION,
              }
            : {
                ...item,
                start: item.open ? performance.now() : item.start,
                open: false,
                delta: item.open
                  ? getHeight(item.index) - TITLE_HEIGHT
                  : item.delta,
                duration: DURATION,
              }
        )
        .filter(
          (item: any) =>
            item.open || performance.now() - item.start < 1000 + item.duration
        );
      const _lookup = _list.reduce((acc: any, item: any) => {
        acc[item.index] = item;
        return acc;
      }, {});

      return { list: _list, lookup: _lookup };
    }

    function pause() {
      // console.log(itemsRef.current.list.filter(({start}) => performance.now() - start > 1000).length !== 0)
      return (
        itemsRef.current.list.filter(
          ({ start, duration }: any) =>
            performance.now() - start < duration + 1500
        ).length === 0
      );
    }

    function getHeight(index: any) {
      const item = itemsRef.current.lookup[index];
      if (!item) return TITLE_HEIGHT;
      const now = performance.now();
      if (now - item.start >= item.duration) {
        return item.open ? TITLE_HEIGHT + item.delta : TITLE_HEIGHT;
      }
      const frac = (now - item.start) / item.duration;
      const height = TITLE_HEIGHT + (item.open ? frac : 1 - frac) * item.delta;
      return height;
    }

    function update(index: any) {
      itemsRef.current = setOpen(itemsRef.current, index, null);
      valueRef.current = index * 12 + (valueRef.current % 12);
      //  listRef.current.scrollTo(index * 20);
      scrollAnimRef.current = {
        from: scrollRef.current,
        to: index * TITLE_HEIGHT,
        start: performance.now(),
        duration: DURATION,
      };
      forceUpdate();
    }

    const [selected, select] = useState({ index: 2, time: performance.now() });
    const [, _forceUpdate] = useState({});
    function forceUpdate() {
      _forceUpdate({});
    }

    callbacksRef.current.forceUpdate = forceUpdate;
    callbacksRef.current.onChange = onChange;
    useLayoutEffect(() => {
      if (elRef.current && autoFocus) {
        elRef.current.focus();
      }
    }, []);

    useRaf(() => {
      // console.log(1)
      const now = performance.now();
      if (!scrollAnimRef.current) return;
      const { start, duration } = scrollAnimRef.current;
      if (now - start > duration) {
        listRef.current.scrollTo(scrollAnimRef.current.to);
        scrollRef.current = scrollAnimRef.current.to;
        scrollAnimRef.current = undefined;
      } else {
        scrollRef.current =
          ((now - start) / duration) *
            (scrollAnimRef.current.to - scrollAnimRef.current.from) +
          scrollAnimRef.current.from;
        listRef.current.scrollTo(scrollRef.current);
      }
      !pause() && listRef.current.resetAfterIndex(0);
    }, "");

    function deltaMonth(delta: any) {
      const value = valueRef.current;
      let next: number = Math.min(Math.max(1, value + delta), 99999);
      if (value / 12 || 0 !== next / 12 || 0) {
        update((next / 12) | 0);
      }
      valueRef.current = next;
    }

    function onKeyDown(e: any) {
      switch (e.keyCode) {
        //up
        case 38:
          e.preventDefault();
          deltaMonth(-4);
          break;
        //down
        case 40:
          e.preventDefault();
          deltaMonth(4);
          break;
        //left
        case 37:
          e.preventDefault();
          deltaMonth(-1);
          break;
        //right
        case 39:
          e.preventDefault();
          deltaMonth(1);
          break;
        //page up
        case 33:
          e.preventDefault();
          deltaMonth(-12);
          break;
        //page down
        case 34:
          e.preventDefault();
          deltaMonth(12);
          break;
        case 13:
          e.preventDefault();
          if (typeof onChange === "function") {
            onChange(valueRef.current);
          }
          break;
        default:
      }
    }

    useLayoutEffect(() => {
      itemsRef.current = setOpen(
        itemsRef.current,
        (valueRef.current / 12) | 0,
        true
      );
      listRef.current.scrollTo(((valueRef.current / 12) | 0) * TITLE_HEIGHT);
    }, []);
    const _today = today();

    const itemLength = 3000;
    // const minLength = 1970;

    // const [test, setTest] = useState<number[]>([]);

    // useEffect(() => {
    //   let array = [];
    //   for (let i = 1970; i < 3000; i++) {
    //     array.push(i);
    //   }
    // }, []);

    return (
      <div
        onKeyDown={onKeyDown}
        tabIndex={0}
        style={{ outline: "none", userSelect: "none", marginTop: 12 }}
        ref={elRef}
      >
        <List
          ref={listRef}
          className="List"
          itemCount={itemLength}
          onScroll={({ scrollOffset }: any) =>
            (scrollRef.current = scrollOffset)
          }
          itemSize={getHeight}
          width={SIZE * 7}
          height={SIZE * 6}
        >
          {({ index, style }: any) => {
            const _x = [0, 1, 2, 3];
            const _y = [0, 1, 2];
            return (
              <div
                style={{
                  ...style,
                  background: "white",
                  cursor: "default",
                  //   display: "none",
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    background: "#F0F5F7",
                    borderBottom: "1px solid #D3E1E8",
                    lineHeight: "23px",
                  }}
                  onClick={() => {
                    update(index);
                  }}
                >
                  {index}
                </div>
                {itemsRef.current.lookup[index] && (
                  <div>
                    {_y.map((y) => (
                      <div style={{ display: "flex" }} key={y + "line"}>
                        {_x.map((x) => {
                          const value = y * _x.length + x + index * 12;
                          return (
                            <Month
                              key={y * _x.length + x}
                              {...{
                                x,
                                y,
                                valueRef,
                                index,
                                callbacksRef,
                                xLength: _x.length,
                                today: _today,
                              }}
                            />
                          );
                        })}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          }}
        </List>
        {false && <pre>{JSON.stringify(itemsRef.current, null, 2)}</pre>}
      </div>
    );
  }
);

const Month = memo(
  ({ x, y, valueRef, index, callbacksRef, xLength, today }: any) => {
    const { onChange, forceUpdate } = callbacksRef.current;
    const value = y * 4 + x + index * 12;
    return (
      <div
        onClick={() => {
          valueRef.current = value;
          if (typeof onChange === "function") {
            onChange(value);
          } else {
            forceUpdate();
          }
        }}
        style={{
          border: "none",
          flex: 1,
          fontSize: 12,
          fontWeight: value === today ? "bold" : "normal",
          //   background: "#fff",
          textAlign: "center",
          lineHeight: "32px",
          background: `${value === valueRef.current ? "#E6EEF2" : "white"}`,
        }}
      >
        {getMonthFormatter(navigator).format(
          new Date(0, 1 + x + y * xLength, 0)
        )}
      </div>
    );
  }
);
