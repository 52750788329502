import axios from "api/defaultClient";
import {
  BrandInventoryPatchData,
  MasterGetList,
  MasterItemDataModify,
  MasterItemRegistration,
  MasterStoreRegistration,
} from "modules/master/types";
import { ProfileModify } from "modules/user/types";

export const getDashBoard = (day: string) => {
  return axios.get(`/brand/dashboard?date=${day}`);
};
export const getMasterDashBoardLaundryDountChart = () => {
  return axios.get(`/brand/dashboard/laundry`);
};
export const getMasterDashBoardItemDountChart = () => {
  return axios.get(`/brand/dashboard/asset`);
};
export const getMasterDashBoardBagCountLineChart = () => {
  return axios.get(`/brand/dashboard/bagcount`);
};
export const getMasterDashBoardLaundryLineChart = () => {
  return axios.get(`/brand/dashboard/franchiselaundry`);
};

export const getMasterLaundryList = (page: number, data: MasterGetList | null) => {
  if (data)
    return axios.get(
      `/brand/laundry?page=${page}&query=${data?.query}&startDate=${data?.startDate}&endDate=${data?.endDate}&dropBox=${data?.dropBox}&size=${data.size}`
    );
  if (!data) return axios.get(`/brand/laundry?page=${page}`);
};

export const getMasterLaundryDetail = (id: number) => {
  return axios.get(`/brand/laundry/${id}`);
};

export const getMasterBillingList = (page: number, data: MasterGetList | null) => {
  if (data)
    return axios.get(
      `/brand/billing?page=${page}&query=${data?.query}&dropBox=${data?.dropBox}&size=${data.size}`
    );
  if (!data) return axios.get(`/brand/billing?page=${page}`);
};

export const getMasterBillingDetail = (id: number) => {
  return axios.get(`/brand/billing/${id}`);
};

export const getMasterStoreList = (page: number, data: MasterGetList | null) => {
  if (data)
    return axios.get(
      `/brand/franchise?page=${page}&query=${data?.query}&dropBox=${data?.dropBox}&size=${data.size}`
    );
  if (!data) return axios.get(`/brand/franchise?page=${page}`);
};

export const getMasterStoreDetail = (id: number) => {
  return axios.get(`/brand/franchise/${id}`);
};

export const postMasterStoreRegistration = (payload: MasterStoreRegistration) => {
  return axios.post(`/brand/franchise`, payload);
};

export const deleteMasterStore = (id: number) => {
  return axios.delete(`/brand/${id}`);
};

export const getMasterFactoryList = (page: number, data: MasterGetList | null) => {
  if (data)
    return axios.get(
      `/brand/factory?page=${page}&query=${data?.query}&dropBox=${data?.dropBox}&size=${data.size}`
    );
  if (!data) return axios.get(`/brand/factory?page=${page}`);
};

export const getMasterFactoryDetail = (id: number) => {
  return axios.get(`/brand/factory/${id}`);
};

export const getMasterItemList = (page: number, size: number) => {
  return axios.get(`/brand/asset-details?page=${page}&size=${size}`);
};
export const getMasterItemListAll = () => {
  return axios.get(`/brand/asset-details/list`);
};
export const patchMasterItemModify = (payload: MasterItemDataModify) => {
  return axios.patch(`/brand/asset-details/${payload.id}`, {
    largeCategory: payload.largeCategory,
    subCategory: payload.subCategory,
    name: payload.name,
    unitPrice: payload.unitPrice,
    content: payload.content,
    size: payload.size,
  });
};

export const postMasterItemRegistration = (payload: MasterItemRegistration) => {
  return axios.post(`/brand/assetDetails`, {
    largeCategory: payload.largeCategory,
    subCategory: payload.subCategory,
    name: payload.name,
    size: payload.size,
    content: payload.content,
    unitPrice: 500,
  });
};

export const postMasterItemRegistrationExcel = (payload: MasterItemRegistration[]) => {
  return axios.post(`/brand/assetDetails/excel`, {
    assetDetailsCommandList: payload,
  });
};

export const getMasterProfileData = () => {
  return axios.get(`/brand/me`);
};
export const patchMasterProfileModify = (payload: ProfileModify) => {
  return axios.patch(`/brand/updateme`, payload);
};

export const getBrandSpecificationsList = (
  id: number | null,
  page: number,
  data: MasterGetList | null
) => {
  if (data) {
    return axios.get(
      `/brand/history?page=${page}&limit=${data.size}&startDate=${data.startDate}&endDate=${
        data.endDate
      }&frId=${id ?? ""}`
    );
  }
  return axios.get(`/brand/history?page=${page}&limit=10`);
};

export const getBrandItemListAll = () => {
  return axios.get(`/brand/asset-details/list`);
};

export const patchBrandItemListAll = (data: BrandInventoryPatchData[]) => {
  return axios.patch(`/brand/isorder`, data);
};

/** 자산 삭제 */
export const deleteAssetDataApi = (id: number) => {
  return axios.delete(`/asset/${id}`);
};
