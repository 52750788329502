import React from "react";
import DaumPostCode from "react-daum-postcode";

interface DaumPostStatusProps {
  onAddress: (zoneCode: string, fullAddress: string) => void;
}

const DaumPost: React.FC<DaumPostStatusProps> = ({ onAddress }) => {
  const handleComplete = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = "";
    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    //fullAddress -> 전체 주소반환
    onAddress(data.zonecode, fullAddress);
  };
  return <DaumPostCode onComplete={handleComplete} />;
};
export default DaumPost;
