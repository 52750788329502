import { BoardDetailData, ILoginResponse } from "modules/user/types";
import React, { useCallback } from "react";
import { useState } from "react";
import { useRef } from "react";
import styled from "styled-components";
import { Palette, ThemeColor } from "styles/palette";

import Button from "./Button";
import ReactHtmlParser from "react-html-parser";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import imgPlus from "assets/icons/image_plus.png";
import { useEffect } from "react";
import DateFormatUtils from "utils/DateFormatUtils";

interface BoardStatusDetailProps {
  role: string;
  auth: string;
  user: ILoginResponse;
  detailId: number | null;
  boardDetail: BoardDetailData | null;
  selectBoardType: (type: string) => void;
  deleteContent: (id: number) => void;
  boardCommentSubmit: (data: any) => void;
  boardCommentModify: (data: any, id: number) => void;
  deleteBoardComment: (id: number) => void;
}

const BoardStatusDetail: React.FC<BoardStatusDetailProps> = ({
  role,
  auth,
  user,
  detailId,
  boardDetail,
  selectBoardType,
  deleteContent,
  boardCommentSubmit,
  boardCommentModify,
  deleteBoardComment,
}) => {
  const textareaHeightRef = useRef<HTMLTextAreaElement>(null);
  const modifyCommnetHeightRef = useRef<HTMLTextAreaElement>(null);
  const [onCommentText, setOnCommentText] = useState<string>("");
  const [modifyComment, setModifyComment] = useState<string | null>("");
  const [modifyId, setModifyId] = useState<number | null>();
  const [file, setFile] = useState<any[]>([]);
  const [enrollFile, setEnrollFile] = useState<any[]>([]);

  const [imageList, setImageList] = useState<any[]>([]);
  const [newImageList, setNewImageList] = useState<any[]>([]);
  const [deleteImageList, setDeleteImageList] = useState<any[]>([]);
  const [fullSizeImageData, setFullSizeImageData] = useState<any>(null);

  let history = useHistory();
  let url_data = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    let array = [];
    if (boardDetail?.comments) {
      for (let i = 0; i < boardDetail?.comments.length ?? 0; i++) {
        // 1. 해당 코멘트에 이미지 리스트가 있을 경우 진행 조건
        let inArray: any[] = [];
        if (boardDetail!.comments[i].imageList) {
          // 2. 해당 코멘트 안에서 리스트를 추가
          for (let j = 0; j < boardDetail!.comments[i].imageList.length; j++) {
            inArray.push(boardDetail!.comments[i].imageList[j]);
          }
          array.push(inArray);
        }
      }
    }
    setImageList(array);
  }, [boardDetail]);

  const onNewImageAdd = (e: any, idx: number) => {
    if (!imageList[idx]) return alert("에러");
    if (imageList[idx].length > 4) return alert("최대 등록갯수 초과");
    e.preventDefault();
    const reader = new FileReader();
    setFile([...file, e.target.files[0]]);

    if (reader !== undefined && file !== undefined) {
      reader.onloadend = () => {
        setImageList(
          imageList.map((item, index) =>
            index === idx
              ? item.concat({ id: null, path: reader.result })
              : item
          )
        );
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onEnrollImageAdd = (e: any) => {
    if (newImageList.length > 4) return alert("최대 등록갯수 초과");
    e.preventDefault();
    const reader = new FileReader();
    setEnrollFile([...file, e.target.files[0]]);
    if (reader !== undefined && file !== undefined) {
      reader.onloadend = () => {
        setNewImageList(
          newImageList.concat({
            id: newImageList.length ?? 0,
            path: reader.result,
          })
        );
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onImageRemove = (idx: number, itemNum: number, id: number) => {
    let testData = (list: any[], tem: number) => {
      list.splice(tem, 1);
      return list;
    };
    setImageList(
      imageList.map((item, index) =>
        index === idx ? testData(item, itemNum) : item
      )
    );
    setFile(file.map(() => testData(file, itemNum)));
    onDeleteImage(id);
  };

  const onEnrollImageRemove = (index: number, id: number) => {
    let testData = (list: any[], tem: number) => {
      list.splice(tem, 1);
      return list;
    };
    setNewImageList(newImageList.filter((item) => item.id !== id));
    setEnrollFile(testData(enrollFile, index));
  };

  const uploadResult = () => {
    const formData = new FormData();
    for (let i = 0; i < file.length; i++) {
      formData.append(`imageList`, file[i]);
    }
    let dto = JSON.stringify({
      commentId: modifyId,
      content: modifyComment,
      deleteImage: deleteImageList,
    });
    // 조건 추가
    formData.append("content", new Blob([dto], { type: "application/json" }));
    boardCommentModify(formData, modifyId!);
  };

  const enrollResult = () => {
    const formData = new FormData();
    for (let i = 0; i < enrollFile.length; i++) {
      formData.append(`imageList`, enrollFile[i]);
    }
    let dto = JSON.stringify({
      content: onCommentText,
    });
    // 조건 추가
    setEnrollFile([]);
    setOnCommentText("");
    setNewImageList([]);
    formData.append("content", new Blob([dto], { type: "application/json" }));
    boardCommentSubmit(formData);
  };

  const onReversePage = () => {
    selectBoardType("list");
    history.push(`/${auth}/board`);
  };

  const onDelete = () => {
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      deleteContent(detailId!);
      onReversePage();
    }
  };

  const onDeleteComment = (id: number) => {
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      deleteBoardComment(id);
      setModifyId(null);
    }
  };

  const onComment = () => {
    if (onCommentText === "") return;
    // boardCommentSubmit(detailId!, onCommentText);
    enrollResult();
  };

  const commentModifyData = (comment: string, id: number) => {
    // 1. 기존 리스트 다시 저장

    // 2. file 스테이트 비우기
    setFile([]);
    setModifyId(id);
    setModifyComment(comment);
  };

  const onCommentModify = () => {
    uploadResult();
    setModifyId(null);
  };

  const handleResizeHeight = useCallback(() => {
    if (textareaHeightRef === null || textareaHeightRef.current === null) {
      return;
    }
    setOnCommentText(textareaHeightRef.current?.value ?? "");
    textareaHeightRef.current.style.height = "38px";
    textareaHeightRef.current.style.height =
      textareaHeightRef.current.scrollHeight + "px";
  }, []);

  const handleResizeHeightModify = useCallback(() => {
    if (
      modifyCommnetHeightRef === null ||
      modifyCommnetHeightRef.current === null
    ) {
      return;
    }
    setModifyComment(modifyCommnetHeightRef.current?.value ?? "");
    modifyCommnetHeightRef.current.style.height = "38px";
    modifyCommnetHeightRef.current.style.height =
      modifyCommnetHeightRef.current.scrollHeight + "px";
  }, []);

  const options = {
    decodeEntities: true,
  };

  const fullSizeImage = (data: any) => {
    let imageItem = data.path.includes("/images/comment")
      ? `${url_data}resource/image?path=${data.path}`
      : `${data.path}`;
    setFullSizeImageData(imageItem);
  };

  const onDeleteImage = (id: number) => {
    setDeleteImageList(deleteImageList.concat(id));
  };

  const onCloseImage = () => {
    setFullSizeImageData(null);
  };

  return (
    <>
      <BoardStatusDetailBlock>
        <div className="headers">
          <h3>게시판</h3>
          <div>
            {boardDetail?.writer.id === user.id && (
              <>
                <Link to="#">
                  <Button
                    theme={ThemeColor.primary}
                    buttonSize="s"
                    onClick={onDelete}
                  >
                    삭제
                  </Button>
                </Link>
                <Link to={`/${auth}/board/modify/${detailId}`}>
                  <Button
                    theme={ThemeColor.primary}
                    buttonSize="s"
                    onClick={() => selectBoardType("modify")}
                  >
                    수정
                  </Button>
                </Link>
              </>
            )}
            <Link to={`/${auth}/board`}>
              <Button
                theme={ThemeColor.primary}
                buttonSize="s"
                onClick={onReversePage}
              >
                뒤로가기
              </Button>
            </Link>
          </div>
        </div>
        <div className="submitBtnWrapper">
          <div>
            <div>
              <label>
                <span>구분: </span>
                <select value={boardDetail?.boardType} disabled>
                  <option value="default">선택해주세요</option>
                  <option value="INQUIRY">문의사항</option>
                  {/* <option value="DISCONTENT">불만사항</option> */}
                  <option value="SUJUNG">수정요청</option>
                  <option value="ETC">기타</option>
                </select>
              </label>
              <label>
                <span>발송대상: </span>
                <input
                  className="receiver"
                  value={
                    boardDetail?.receiver ? boardDetail.receiver.name : "전체"
                  }
                  disabled
                />
              </label>
              <label>
                <span>제목: </span>
                <input
                  className="titleInput"
                  value={boardDetail?.title}
                  disabled
                />
              </label>
            </div>
            <div>
              <label>
                <span>작성자: </span>
                <input
                  className="writerName"
                  value={boardDetail?.writer.nickname}
                  disabled
                />
              </label>
              <label>
                <span>작성일시: </span>
                <input
                  value={DateFormatUtils.dateToFormat(boardDetail?.createdDate)}
                  disabled
                />
              </label>
            </div>
          </div>
        </div>
        <div className="textWrapper">
          {ReactHtmlParser(boardDetail?.content ?? "", options)}
        </div>
        <div className="commentBox">
          <h3>
            댓글{" "}
            <span className="commentLength">
              {boardDetail?.comments && boardDetail.comments.length}
            </span>
          </h3>
          <ul>
            {boardDetail?.comments &&
              boardDetail?.comments.map((comment, index) => (
                <li key={index}>
                  <span>
                    {comment.writer}{" "}
                    <span className="writeDate">
                      {moment(comment.createdDate).format(
                        "yyyy-MM-DD HH:mm:ss"
                      )}
                    </span>
                  </span>
                  {imageList[index] && imageList[index].length > 0 && (
                    <div className="imgArea">
                      {imageList[index].map((item: any, itemNum: number) => (
                        <div key={itemNum} className="imgBox">
                          <>
                            {comment.commentId === modifyId && (
                              <div
                                className="imgDelete"
                                onClick={() =>
                                  onImageRemove(index, itemNum, item.id)
                                }
                              >
                                X
                              </div>
                            )}
                          </>
                          <img
                            src={
                              item.path && item.path.includes("/images/comment")
                                ? `${url_data}resource/image?path=${item.path}`
                                : `${item.path}`
                            }
                            onClick={() => fullSizeImage(item)}
                            alt=""
                          />
                        </div>
                      ))}
                    </div>
                  )}
                  {comment.commentId === modifyId ? (
                    <>
                      <div>
                        <textarea
                          ref={modifyCommnetHeightRef}
                          value={modifyComment ?? ""}
                          onChange={handleResizeHeightModify}
                        ></textarea>
                      </div>
                      <div className="modifyCommentBtnBox">
                        <label>
                          <input
                            type="file"
                            name="avatar"
                            accept=".jpef, .png, .jpg"
                            onChange={(e) => {
                              onNewImageAdd(e, index);
                              e.target.value = "";
                            }}
                          />
                          <span>
                            <img src={imgPlus} alt="" />
                          </span>
                        </label>

                        <button
                          type="button"
                          className="btn"
                          onClick={onCommentModify}
                        >
                          수정
                        </button>
                        <button type="button" className="btn">
                          취소
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <span className="textArea">{comment.content}</span>
                      {comment.writerId === user.id && (
                        <div className="commentBtnBox">
                          <button
                            type="button"
                            className="btn"
                            onClick={() =>
                              commentModifyData(
                                comment.content,
                                comment.commentId
                              )
                            }
                          >
                            수정
                          </button>
                          <button
                            type="button"
                            className="btn"
                            onClick={() => onDeleteComment(comment.commentId)}
                          >
                            삭제
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </li>
              ))}
          </ul>
          <div className="commentWriteBox">
            <div className="writerName">{user.nickname}</div>

            <div className="testAreaBox">
              <textarea
                ref={textareaHeightRef}
                placeholder="댓글을 입력해주세요."
                value={onCommentText}
                onChange={handleResizeHeight}
              />
              {newImageList.length > 0 && (
                <div className="imgArea">
                  {newImageList.map((item: any, index: number) => (
                    <div key={index}>
                      <div onClick={() => onEnrollImageRemove(index, item.id)}>
                        X
                      </div>
                      <img
                        src={
                          item.path && item.path.includes("/images/comment")
                            ? `${url_data}resource/image?path=${item.path}`
                            : `${item.path}`
                        }
                        alt=""
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="btnBox">
              <label>
                <input
                  type="file"
                  name="avatar"
                  accept=".jpef, .png, .jpg"
                  onChange={(e) => {
                    onEnrollImageAdd(e);
                    e.target.value = "";
                  }}
                />
                <span>
                  <img src={imgPlus} alt="" />
                </span>
              </label>
              <button
                type="button"
                className={onCommentText !== "" ? "on" : ""}
                onClick={onComment}
              >
                등록
              </button>
            </div>
          </div>
        </div>
      </BoardStatusDetailBlock>
      {fullSizeImageData && (
        <FullSizeImageBlock>
          <div>
            <img src={fullSizeImageData} alt="" />
            <div onClick={onCloseImage}>X</div>
          </div>
        </FullSizeImageBlock>
      )}
    </>
  );
};

export default BoardStatusDetail;

const BoardStatusDetailBlock = styled.div`
  height: auto;
  min-height: 500px;
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  padding-bottom: 20px;
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
    & > div {
      width: auto;
      & > a {
        margin-left: 5px;
      }
    }
  }

  .submitBtnWrapper {
    width: 100%;
    height: 70px;
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: space-between;
    & > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > div {
        & > label {
          margin-left: 10px;
          & > .receiver {
            color: #999;
          }
          & > span {
            color: #999;
            font-size: 14px;
            line-height: 37px;
          }
          & > input,
          select {
            border-radius: 3px;
            border: 1px solid #dee2e6;
            box-sizing: border-box;
            background-color: #fff;
          }
          & > select {
            width: 110px;
            padding-left: 10px;
          }
          & > input {
            width: 110px;
          }
          & > .writerName {
            width: 130px;
          }

          & > .titleInput {
            width: 90%;
          }
        }
      }
      & > div:nth-child(1) {
        width: 65%;
        display: flex;
        justify-content: space-between;
        min-width: 650px;
        & > label:nth-child(3) {
          flex: 1;
        }
      }
      & > div:nth-child(2) {
        min-width: 355px;
      }
    }
    & > div {
      & > a {
        margin-left: 5px;
      }
    }
  }
  .textWrapper {
    width: 100%;
    max-width: 1920px;
    height: auto;
    min-height: 450px;
    margin-top: 8px;
    border-radius: 5px;
    padding: 20px;
    border: 1px solid #dee2e6;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .commentBox {
    width: 100%;
    min-height: 250px;
    margin-top: 20px;
    & > h3 {
      padding: 0;
      margin: 0;
    }
    & > ul {
      width: 100%;
      height: auto;
      margin: 0 auto;
      padding: 0;
      margin-top: 15px;
      & > li {
        padding: 12px 0;
        width: 100%;
        /* min-height: 70px; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: 1px solid #dee2e6;
        cursor: default;
        position: relative;

        & > .textArea {
          color: #aaa;
          font-size: 15px;
        }
        & > span {
          display: block;
          & > .writeDate {
            color: #aaa;
            font-size: 12px;
            float: right;
          }
        }
        & > span:nth-child(1) {
          margin-bottom: 5px;
        }
        & > span:nth-child(2) {
          font-size: 14px;
          color: #aaa;
        }
        & > div {
          /* border: 1px solid #dee2e6; */
          border-radius: 3px;

          & > textarea {
            background-color: #eee;
            width: 100%;
            height: auto;
            min-height: 50px;
            /* margin-top: 10px; */
            border: 0;
            resize: none;
          }
        }
        & > .commentBtnBox {
          position: absolute;
          right: 0;
          bottom: 0;
        }
        & > .modifyCommentBtnBox {
          align-self: flex-end;
          margin-top: 10px;

          & > label {
            display: inline-block;
            width: 18px;
            /* height: 18px; */
            /* background-color: red; */
            margin-right: 12px;
            cursor: pointer;

            & > input {
              display: none;
            }
            & > span {
              /* width: 10px;
          height: 10px; */

              & > img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
      & > li:last-child {
        border-bottom: 0;
      }
    }
  }
  .imgArea {
    width: auto;
    height: 70px;
    border: 0;
    margin-bottom: 10px;
    margin-top: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & > div {
      width: 70px;
      height: 70px;
      background-color: lightblue;
      border-radius: 5px;
      margin-right: 5px;
      position: relative;
      & > div {
        width: 18px;
        height: 18px;
        border-radius: 9px;
        position: absolute;
        border: 1px solid #fff;
        top: 3px;
        right: 3px;
        font-size: 5px;
        text-align: center;
        line-height: 16px;
        background-color: red;
        color: #fff;
        cursor: pointer;
      }
      & > img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .commentWriteBox {
    width: 100%;
    height: auto;
    min-height: 150px;
    border-radius: 5px;
    border: 1px solid #dee2e6;
    margin-top: 10px;
    position: relative;
    padding: 30px 25px 50px 25px;
    & > h3 {
      & > .commentLength {
        font-size: 12px;
      }
    }
    & > .writerName {
      font-weight: 700;
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid #dee2e6;
    }
    & > .testAreaBox {
      background-color: #eee;
      padding: 10px;
      border-radius: 5px;
      & > textarea {
        width: 100%;
        height: auto;
        min-height: 50px;
        margin-top: 10px;
        border: 0;
        overflow-y: none;
        background-color: #eee;
        resize: none;
      }
    }

    & > .btnBox {
      position: absolute;
      right: 25px;
      bottom: 20px;
      display: flex;
      text-align: center;

      & > label {
        display: block;
        width: 18px;
        /* height: 18px; */
        /* background-color: red; */
        margin-right: 12px;
        padding-top: 3px;
        cursor: pointer;
        & > input {
          display: none;
        }
        & > span {
          /* width: 10px;
          height: 10px; */
          & > img {
            width: 100%;
          }
        }
      }
      & > button {
        border: 0;
        background-color: transparent;
        color: #aaa;
      }
      & > .on {
        padding: 3px 7px;
        border-radius: 3px;
        background-color: ${Palette.primary};
        color: #fff;
        cursor: pointer;
      }
    }
  }
  .imgBox {
    .imgDelete {
      display: none;
    }
  }
  .imgBox:hover {
    .imgDelete {
      display: block;
    }
  }
  .btn {
    background-color: #fff;
    border: 0;
    text-align: center;
    padding: 3px 7px;
    /* line-height: 18px; */
    color: #aaa;
    border-radius: 3px;
    /* margin-left: 5px; */
  }
  .btn:hover {
    background-color: ${Palette.primary};
    font-weight: bold;
    cursor: pointer;
    color: #fff;
  }
`;

const FullSizeImageBlock = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  & > div {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      max-width: 80%;
      object-fit: unset;
      border: 2px solid #fff;
    }
    & > div {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      position: absolute;
      top: -3%;
      right: 7%;
      background-color: ${Palette.primary};
      border: 2px solid #fff;
      text-align: center;
      line-height: 30px;
      color: #fff;
      cursor: pointer;
    }
  }
`;
