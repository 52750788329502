import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Paging from "components/common/Paging";
import Button from "components/common/Button";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Palette, ThemeColor } from "styles/palette";
import NumberUtils from "utils/NumberUtils";
import { Link, useHistory } from "react-router-dom";
import { BoardGetList, BoardList } from "modules/user/types";
import DateFormatUtils from "utils/DateFormatUtils";
import BoardSearch from "./BoardSearch";
import TypeUtils from "utils/TypeUtils";

interface BoardStatusProps {
  role: string;
  auth: string;
  boardList: BoardList | null;
  boardListData: (page: number, data: BoardGetList | null) => void;
  selectBoardType: (type: string) => void;
  detailSelect: (id: number) => void;
}

const BoardStatus: React.FC<BoardStatusProps> = ({
  role,
  auth,
  boardList,
  boardListData,
  selectBoardType,
  detailSelect,
}) => {
  let history = useHistory();

  const [searchData, setSearchData] = useState<BoardGetList>({
    query: "",
    dropBox: "",
    startDate: "",
    endDate: "",
    size: 10,
  });

  const pageMove = (page: number) => {
    boardListData(page, searchData ?? null);
  };

  const onDetail = (id: number) => {
    detailSelect(id);
    history.push(`/${auth}/board/${id}`);
  };

  const onSearch = (page: number, data: BoardGetList) => {
    setSearchData(data);
    boardListData(page, data);
  };

  const selectShowEntries = (size: number) => {
    searchData.size = size;
    boardListData(1, searchData ?? null);
  };

  const [listNumber, setListNumber] = useState<number[]>([]);
  useEffect(() => {
    setListNumber(
      NumberUtils.numberToList(
        boardList?.currentPage ?? 0,
        boardList?.totalCount ?? 0,
        searchData.size ?? 10
      )
    );
  }, [boardList, searchData]);

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      fontWeight: 700,
    },
  });
  const classes = useStyles();

  return (
    <BoardStatusBlock>
      <div className="headers">
        <h3>게시판</h3>
      </div>
      <div className="tableSelect">
        <div>
          <span>Show </span>
          <select onChange={(e) => selectShowEntries(parseInt(e.target.value, 10))}>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
          <span> entries</span>
        </div>
        <div className="topWrapper">
          <span>Search: </span>
          <div>
            <BoardSearch
              role={"ROLE_MASTER_ADMIN"}
              onSearch={onSearch}
              size={searchData.size ?? 10}
            />
            <Link to={`/${auth}/board/add`}>
              <Button
                theme={ThemeColor.tertiary}
                buttonSize="m"
                onClick={() => selectBoardType("write")}
              >
                등록
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <div className="tableWrapper">
        <TableContainer component={Paper} className="tables">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: 900, width: "70px" }}>
                  순번
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: 900,
                    width: "15%",
                  }}
                >
                  구분
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900 }}>
                  제목
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: 900,
                    width: "15%",
                  }}
                >
                  작성자
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: 900,
                    width: "20%",
                  }}
                >
                  작성일자
                </TableCell>
                {/* <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "200px" }}
                ></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {boardList?.list.map((board, index) => (
                <TableRow className="tableHover" key={index} onClick={() => onDetail(board.id)}>
                  <TableCell component="th" scope="row" align="center">
                    {listNumber[index]}
                  </TableCell>
                  <TableCell align="center">{TypeUtils.boardType(board.boardType ?? "")}</TableCell>
                  <TableCell align="center">
                    <span>{board.title}</span>
                    <span className="commentNum">[{board.commentAmount}]</span>
                    {board.isNew && <span className="newIcon">N</span>}
                  </TableCell>
                  <TableCell align="center">{board.companyName}</TableCell>
                  <TableCell align="center">
                    {DateFormatUtils.dateToFormat(board.createdAt)}
                  </TableCell>
                  {/* <TableCell align="center">
                    <Link to={`/${auth}/board/detail`}>
                      <Button
                        theme={ThemeColor.primary}
                        buttonSize="s"
                        onClick={() => onDetail(board.id)}
                      >
                        상세보기
                      </Button>
                    </Link>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Paging
          listAction={pageMove}
          listCount={boardList?.totalPage ?? 1}
          currentPage={boardList?.currentPage}
          totalCount={boardList?.totalCount ?? 1}
          size={searchData.size ?? 10}
        ></Paging>
      </div>
    </BoardStatusBlock>
  );
};

export default BoardStatus;

const BoardStatusBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  .tables {
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .tableHover > td {
    padding: 10px;
  }
  .tableHover:hover {
    background-color: #eee;
    cursor: pointer;
  }
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  .tableSelect {
    height: 70px;
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: space-between;
    & > div {
      color: #999;
      height: 40px;
      overflow: hidden;
      & > span {
        font-size: 14px;
        line-height: 37px;
      }
      & > select {
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        transition: ease-in-out;
        transition-duration: 0.1s;
        border-radius: 5px;
      }
      & > select:hover {
        border-bottom: 2px solid ${Palette.primary};
      }
    }
  }
  .topWrapper {
    display: flex;
    justify-content: flex-end;
    & > span {
      font-size: 14px;
      line-height: 37px;
      margin-right: 5px;
    }
    & > div {
      /* width: auto; */
      /* display: inline-block; */
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div {
        margin: 0;
        color: #fff;
      }
      & > a {
        margin-left: 5px;
      }
    }
  }
  .tableWrapper {
    min-width: 51rem;
    padding-bottom: 1.5rem;

    .commentNum {
      display: inline-block;
      color: #ff3300;
      /* font-size: 12px; */
      margin-left: 7px;
    }
    .newIcon {
      color: #fff;
      display: inline-block;
      width: 16px;
      height: 16px;
      background-color: #ff3300;
      text-align: center;
      line-height: 16px;
      border-radius: 3px;
      font-size: 12px;
      margin-left: 7px;
      margin-top: -2px;
    }
  }
`;
