import { useEffect, useRef } from "react";

export function useRaf(callback: any, pause: any) {
  const { requestAnimationFrame, cancelAnimationFrame } = window;

  const savedCallback = useRef<any>();
  const savedRaf = useRef<any>();
  const savedPause = useRef<any>();

  savedPause.current = pause;
  savedCallback.current = callback;

  useEffect(() => {
    function tick(...args: any) {
      if (!savedPause.current && savedCallback.current) {
        savedCallback.current(...args);
        if (savedRaf.current) cancelAnimationFrame(savedRaf.current);
        savedRaf.current = requestAnimationFrame(tick);
      }
    }

    if (!pause) tick();

    return () => {
      if (savedRaf.current) cancelAnimationFrame(savedRaf.current);
    };
  }, [
    pause,
    savedPause,
    savedRaf,
    cancelAnimationFrame,
    requestAnimationFrame,
  ]);
}
