import React, { useState } from "react";
import styled from "styled-components";
import { Palette } from "styles/palette";

interface LaundryTagSearchProps {
  onSearch: (query: string | null, sort: string | null) => void;
  size: number;
}

const LaundryTagSearch: React.FC<LaundryTagSearchProps> = (props) => {
  const [lineUp, setLineUp] = useState<string>("DESC");
  // const [searchQuery, setSearchQuery] = useState<string>("");

  const onSearch = (line: string) => {
    setLineUp(line);
    props.onSearch(null, lineUp);
  };

  return (
    <LaundryTagSearchBlock>
      <div>
        <span>누적스캔횟수: </span>
        <select
          className="selectBox"
          onChange={(e) => onSearch(e.target.value)}
        >
          <option value="DESC">오름차순</option>
          <option value="ASC">내림차순</option>
        </select>
      </div>
      {/* <div>
        <span>폼목명: </span>
        <input
          className="textBox"
          placeholder="품목명을 입력하세요"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <Button theme={ThemeColor.primary} buttonSize="m" onClick={onSearch}>
        검색
      </Button> */}
    </LaundryTagSearchBlock>
  );
};

export default LaundryTagSearch;

const LaundryTagSearchBlock = styled.form`
  height: auto;
  width: auto;
  display: flex;
  justify-content: space-between;
  & > div {
    & > select {
      border: 0;
      height: 100%;
    }
    & > span {
      font-size: 14px;
      color: #888;
    }
    & > input {
      border: 0;
      height: 100%;
      /* padding: 0; */
    }
  }

  .selectBox,
  .textBox {
    border: 1px solid #dee2e6;
    box-sizing: border-box;
    transition: ease-in-out;
    transition-duration: 0.1s;
    margin-right: 5px;
    border-radius: 3px;
  }
  .selectBox:hover,
  .textBox:hover {
    border-bottom: 2px solid ${Palette.primary};
  }
`;
