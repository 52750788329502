import { PasswordChangeForm } from "modules/user/types";
import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { ThemeColor } from "styles/palette";
import Button from "./Button";

interface PasswordChangeProps {
  patchPasswordChange: (payload: PasswordChangeForm) => void;
  onClose: () => void;
}

const PasswordChange: React.FC<PasswordChangeProps> = ({
  onClose,
  patchPasswordChange,
}) => {
  const [currentPassword, setCurrentPassword] = useState<string | null>(null);
  const [newPassword, setNewPassword] = useState<string | null>(null);
  const [checkNewPassword, setCheckNewPassword] = useState<string | null>(null);

  const passwordChange = () => {
    if (!currentPassword || !newPassword || !checkNewPassword)
      return alert("빈값을 입력해주세요.");
    if (newPassword !== checkNewPassword)
      return alert("변경할 비밀번호가 서로 다릅니다.");
    const payload = {
      currentPassword: currentPassword!,
      newPassword: newPassword!,
      checkNewPassword: checkNewPassword!,
    };
    patchPasswordChange(payload!);
    onClose();
  };
  return (
    <PasswordChangeBlock>
      <section className="passwordChangeSection">
        <h2>비밀번호 변경</h2>
        <article className="passwordChangeArticle">
          <div className="inputField">
            <div className="field">
              <span>현재 비밀번호</span>
              <input
                type="password"
                value={currentPassword ?? ""}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </div>
            <div className="field">
              <span>신규 비밀번호</span>
              <input
                type="password"
                value={newPassword ?? ""}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="field">
              <span>신규 비밀번호 확인</span>
              <input
                type="password"
                value={checkNewPassword ?? ""}
                onChange={(e) => setCheckNewPassword(e.target.value)}
              />
            </div>
          </div>
        </article>
        <div className="buttonWrapper">
          <Button
            theme={ThemeColor.primary}
            buttonSize="m"
            onClick={passwordChange}
          >
            저장
          </Button>
          <Button theme={ThemeColor.primary} buttonSize="m" onClick={onClose}>
            취소
          </Button>
        </div>
      </section>
    </PasswordChangeBlock>
  );
};

export default PasswordChange;

const PasswordChangeBlock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  .passwordChangeSection {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 39rem;
    height: 25.6rem;
    background-color: #eee;
    display: flex;
    flex-direction: column;
    border-radius: 2.6rem;
    padding: 0;
    h2 {
      margin-top: 2.5rem;
      margin-left: 3rem;
      color: #016241;
    }
    .passwordChangeArticle {
      flex: 1;
      width: 24.4rem;
      height: 8rem;
      margin: 0 auto;
      padding: 0;
      .inputField {
        margin-top: 3rem;
        .field {
          height: 2.1rem;
          display: grid;
          grid-template-columns: 10.1rem 1fr;
          align-items: center;
          span {
            color: #888;
          }
          input {
            width: 14.3rem;
            height: 2.1rem;
            border: 0;
          }
        }
        .field + .field {
          margin-top: 1rem;
        }
      }
    }
    .buttonWrapper {
      margin: 0 auto;
      padding: 2.5rem;
    }
  }
`;
