import { AxiosResponse } from "axios";
import { call, takeEvery, put } from "redux-saga/effects";
import * as masterApi from "api/masterApi";
import {
  IMasterState,
  MasterDashBoardData,
  MasterAction,
  MasterBillingDetail,
  MasterBillingList,
  MasterFactoryDetail,
  MasterFactoryList,
  MasterItemList,
  MasterItemDataModify,
  MasterItemRegistration,
  MasterLaundryDetail,
  MasterLaundryList,
  MasterStoreDetail,
  MasterStoreList,
  MasterStoreRegistration,
  MasterGetList,
  Details,
  BrandSpecificationsData,
  BrandInventoryDataItem,
  BrandInventoryPatchData,
} from "./types";
import { getBrandHistoryAlarmAction } from "modules/user/user";

const GET_MASTER_DASHBOARD = "GET_MASTER_DASHBOARD" as const;
const GET_MASTER_DASHBOARD_SUCCESS = "GET_MASTER_DASHBOARD_SUCCESS" as const;

const GET_MASTER_LAUNDRY_LIST = "GET_MASTER_LAUNDRY_LIST" as const;
const GET_MASTER_LAUNDRY_LIST_SUCCESS = "GET_MASTER_LAUNDRY_LIST_SUCCESS" as const;

const GET_MASTER_LAUNDRY_DETAIL = "GET_MASTER_LAUNDRY_DETAIL" as const;
const GET_MASTER_LAUNDRY_DETAIL_SUCCESS = "GET_MASTER_LAUNDRY_DETAIL_SUCCESS" as const;

const GET_MASTER_STORE_LIST = "GET_MASTER_STORE_LIST" as const;
const GET_MASTER_STORE_LIST_SUCCESS = "GET_MASTER_STORE_LIST_SUCCESS" as const;

const GET_MASTER_STORE_DETAIL = "GET_MASTER_STORE_DETAIL" as const;
const GET_MASTER_STORE_DETAIL_SUCCESS = "GET_MASTER_STORE_DETAIL_SUCCESS" as const;

const POST_MASTER_STORE_REGISTRATION = "POST_MASTER_STORE_REGISTRATION" as const;
const POST_MASTER_STORE_REGISTRATION_SUCCESS = "POST_MASTER_STORE_REGISTRATION" as const;

const DELETE_MASTER_STORE = "DELETE_MASTER_STORE" as const;
const DELETE_MASTER_STORE_SUCCESS = "DELETE_MASTER_STORE_SUCCESS" as const;

const GET_MASTER_FACTORY_LIST = "GET_MASTER_FACTORY_LIST" as const;
const GET_MASTER_FACTORY_LIST_SUCCESS = "GET_MASTER_FACTORY_LIST_SUCCESS" as const;

const GET_MASTER_FACTORY_DETAIL = "GET_MASTER_FACTORY_DETAIL" as const;
const GET_MASTER_FACTORY_DETAIL_SUCCESS = "GET_MASTER_FACTORY_DETAIL_SUCCESS" as const;

const GET_MASTER_ITEM_LIST = "GET_MASTER_ITEM_LIST" as const;
const GET_MASTER_ITEM_LIST_SUCCESS = "GET_MASTER_ITEM_LIST_SUCCESS" as const;

const GET_MASTER_ITEM_LIST_ALL = "GET_MASTER_ITEM_LIST_ALL" as const;
const GET_MASTER_ITEM_LIST_ALL_SUCCESS = "GET_MASTER_ITEM_LIST_ALL_SUCCESS" as const;

const POST_MASTER_ITEM_REGISTRATION = "POST_MASTER_ITEM_REGISTRATION" as const;
const POST_MASTER_ITEM_REGISTRATION_SUCCESS = "POST_MASTER_ITEM_REGISTRATION_SUCCESS" as const;

const POST_MASTER_ITEM_REGISTRATION_EXCEL = "POST_MASTER_ITEM_REGISTRATION_EXCEL" as const;
const POST_MASTER_ITEM_REGISTRATION_EXCEL_SUCCESS =
  "POST_MASTER_ITEM_REGISTRATION_EXCEL_SUCCESS" as const;

const PATCH_MASTER_ITEM_MODIFY = "PATCH_MASTER_ITEM_MODIFY" as const;
const PATCH_MASTER_ITEM_MODIFY_SUCCESS = "PATCH_MASTER_ITEM_MODIFY_SUCCESS" as const;

const GET_MASTER_BILLING_LIST = "GET_MASTER_BILLING_LIST" as const;
const GET_MASTER_BILLING_LIST_SUCCESS = "GET_MASTER_BILLING_LIST_SUCCESS" as const;

const GET_MASTER_BILLING_DETAIL = "GET_MASTER_BILLING_DETAIL" as const;
const GET_MASTER_BILLING_DETAIL_SUCCESS = "GET_MASTER_BILLING_DETAIL_SUCCESS" as const;

const GET_BRAND_SPECIFICATIONS_LIST = "GET_BRAND_SPECIFICATIONS_LIST" as const;
const GET_BRAND_SPECIFICATIONS_LIST_SUCCESS = "GET_BRAND_SPECIFICATIONS_LIST_SUCCESS" as const;

const GET_BRAND_INVENTORY_LIST_ALL = "GET_BRAND_INVENTORY_LIST_ALL" as const;
const GET_BRAND_INVENTORY_LIST_ALL_SUCCESS = "GET_BRAND_INVENTORY_LIST_ALL_SUCCESS" as const;

const PATCH_BRAND_INVENTORY_LIST_ALL = "PATCH_BRAND_INVENTORY_LIST_ALL" as const;
const PATCH_BRAND_INVENTORY_LIST_ALL_SUCCESS = "PATCH_BRAND_INVENTORY_LIST_ALL_SUCCESS" as const;

/** 브랜드 자산 삭제 */
const DELETE_MASTER_ITEM = "DELETE_MASTER_ITEM" as const;

export const getMasterDashboardAction = (day: string) => ({
  type: GET_MASTER_DASHBOARD,
  day,
});
export const getMasterDashboardSuccessAction = (masterDashBoard: MasterDashBoardData) => ({
  type: GET_MASTER_DASHBOARD_SUCCESS,
  masterDashBoard,
});
export const getMasterLaundryListAction = (page: number, data: MasterGetList | null) => ({
  type: GET_MASTER_LAUNDRY_LIST,
  page,
  data,
});
export const getMasterLaundryListSuccessAction = (masterLaundryList: MasterLaundryList) => ({
  type: GET_MASTER_LAUNDRY_LIST_SUCCESS,
  masterLaundryList,
});
export const getMasterLaundryDetailAction = (id: number) => ({
  type: GET_MASTER_LAUNDRY_DETAIL,
  id,
});
export const getMasterLaundryDetailSuccessAction = (masterLaundryDetail: MasterLaundryDetail) => ({
  type: GET_MASTER_LAUNDRY_DETAIL_SUCCESS,
  masterLaundryDetail,
});

export const getMasterBillingListAction = (page: number, data: MasterGetList | null) => ({
  type: GET_MASTER_BILLING_LIST,
  page,
  data,
});

export const getMasterBillingListSuccessAction = (masterBillingList: MasterBillingList) => ({
  type: GET_MASTER_BILLING_LIST_SUCCESS,
  masterBillingList,
});

export const getMasterBillingDetailAction = (id: number) => ({
  type: GET_MASTER_BILLING_DETAIL,
  id,
});

export const getMasterBillingDetailSuccessAction = (masterBillingDetail: MasterBillingDetail) => ({
  type: GET_MASTER_BILLING_DETAIL_SUCCESS,
  masterBillingDetail,
});

export const getMasterStoreListAction = (page: number, data: MasterGetList | null) => ({
  type: GET_MASTER_STORE_LIST,
  page,
  data,
});

export const getMasterStoreListSuccessAction = (masterStoreList: MasterStoreList) => ({
  type: GET_MASTER_STORE_LIST_SUCCESS,
  masterStoreList,
});

export const getMasterStoreDetailAction = (id: number) => ({
  type: GET_MASTER_STORE_DETAIL,
  id,
});

export const getMasterStoreDetailSuccessAction = (masterStoreDetail: MasterStoreDetail) => ({
  type: GET_MASTER_STORE_DETAIL_SUCCESS,
  masterStoreDetail,
});

export const postMasterStoreRegistrationAction = (payload: MasterStoreRegistration) => ({
  type: POST_MASTER_STORE_REGISTRATION,
  payload,
});
export const deleteMasterStoreAction = (id: number) => ({
  type: DELETE_MASTER_STORE,
  id,
});
export const deleteMasterStoreSuccessAction = () => ({
  type: DELETE_MASTER_STORE_SUCCESS,
});

export const postMasterStoreRegistrationSuccessAction = () => ({
  type: POST_MASTER_STORE_REGISTRATION_SUCCESS,
});

export const getMasterFactoryListAction = (page: number, data: MasterGetList | null) => ({
  type: GET_MASTER_FACTORY_LIST,
  page,
  data,
});

export const getMasterFactoryListSuccessAction = (masterFactoryList: MasterFactoryList) => ({
  type: GET_MASTER_FACTORY_LIST_SUCCESS,
  masterFactoryList,
});

export const getMasterFactoryDetailAction = (id: number) => ({
  type: GET_MASTER_FACTORY_DETAIL,
  id,
});

export const getMasterFactoryDetailSuccessAction = (masterFactoryDetail: MasterFactoryDetail) => ({
  type: GET_MASTER_FACTORY_DETAIL_SUCCESS,
  masterFactoryDetail,
});

export const getMasterItemListAction = (page: number, size: number) => ({
  type: GET_MASTER_ITEM_LIST,
  page,
  size,
});
export const getMasterItemListSuccessAction = (masterItemList: MasterItemList) => ({
  type: GET_MASTER_ITEM_LIST_SUCCESS,
  masterItemList,
});

export const getMasterItemListAllAction = () => ({
  type: GET_MASTER_ITEM_LIST_ALL,
});
export const getMasterItemListAllSuccessAction = (masterItemListAll: Details[]) => ({
  type: GET_MASTER_ITEM_LIST_ALL_SUCCESS,
  masterItemListAll,
});

export const postMasterItemRegistrationAction = (
  payload: MasterItemRegistration,
  size: number
) => ({
  type: POST_MASTER_ITEM_REGISTRATION,
  payload,
  size,
});

export const postMasterItemRegistrationSuccessAction = () => ({
  type: POST_MASTER_ITEM_REGISTRATION_SUCCESS,
});

export const postMasterItemRegistrationExcelAction = (
  payload: MasterItemRegistration[],
  size: number
) => ({
  type: POST_MASTER_ITEM_REGISTRATION_EXCEL,
  payload,
  size,
});

export const postMasterItemRegistrationExcelSuccessAction = () => ({
  type: POST_MASTER_ITEM_REGISTRATION_EXCEL_SUCCESS,
});

export const patchMasterItemModifyAction = (
  payload: MasterItemDataModify,
  page: number,
  size: number
) => ({
  type: PATCH_MASTER_ITEM_MODIFY,
  payload,
  page,
  size,
});
export const patchMasterItemModifySuccessAction = () => ({
  type: PATCH_MASTER_ITEM_MODIFY_SUCCESS,
});

export const getBrandSpecificationsListAction = (
  id: number | null,
  page: number,
  data: MasterGetList | null
) => ({
  type: GET_BRAND_SPECIFICATIONS_LIST,
  id,
  page,
  data,
});
export const getBrandSpecificationsListSuccessAction = (
  brandSpecificationsData: BrandSpecificationsData
) => ({
  type: GET_BRAND_SPECIFICATIONS_LIST_SUCCESS,
  brandSpecificationsData,
});

export const getBrandInventoryListAllAction = () => ({
  type: GET_BRAND_INVENTORY_LIST_ALL,
});

export const getBrandInventoryListAllSuccessAction = (payload: BrandInventoryDataItem[]) => ({
  type: GET_BRAND_INVENTORY_LIST_ALL_SUCCESS,
  payload,
});

export const patchBrandInventoryListAllAction = (
  data: BrandInventoryPatchData[],
  page: number,
  size: number
) => ({
  type: PATCH_BRAND_INVENTORY_LIST_ALL,
  data,
  page,
  size,
});

export const patchBrandInventoryListAllSuccessAction = () => ({
  type: PATCH_BRAND_INVENTORY_LIST_ALL_SUCCESS,
});

/** 자산 삭제 */
export const deleteBrandItemAction = (id: number, page: number, size: number) => ({
  type: DELETE_MASTER_ITEM,
  id,
  page,
  size,
});

//사가
function* getMasterDashboardSaga(action: ReturnType<typeof getMasterDashboardAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(masterApi.getDashBoard, action.day);
    if (status !== "OK") return alert("에러");
    yield put(getMasterDashboardSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getMasterLaundryListSaga(action: ReturnType<typeof getMasterLaundryListAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(masterApi.getMasterLaundryList, action.page, action.data);
    if (status !== "OK") return alert("에러");

    yield put(getMasterLaundryListSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getMasterLaundryDetailSaga(action: ReturnType<typeof getMasterLaundryDetailAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(masterApi.getMasterLaundryDetail, action.id);
    if (status !== "OK") return alert("에러");
    yield put(getMasterLaundryDetailSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getMasterBillingListSaga(action: ReturnType<typeof getMasterBillingListAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(masterApi.getMasterBillingList, action.page, action.data);
    if (status !== "OK") return alert("에러");
    yield put(getMasterBillingListSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getMasterBillingDetailSaga(action: ReturnType<typeof getMasterBillingDetailAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(masterApi.getMasterBillingDetail, action.id);
    if (status !== "OK") return alert("에러");
    yield put(getMasterBillingDetailSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getMasterStoreListSaga(action: ReturnType<typeof getMasterStoreListAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(masterApi.getMasterStoreList, action.page, action.data);
    if (status !== "OK") return alert("에러");
    yield put(getMasterStoreListSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getMasterStoreDetailSaga(action: ReturnType<typeof getMasterStoreDetailAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(masterApi.getMasterStoreDetail, action.id);
    if (status !== "OK") return alert("에러");
    yield put(getMasterStoreDetailSuccessAction(data));
    yield put(getBrandHistoryAlarmAction());
    // yield put(getLaundryCompleteAlarmAction());
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* postMasterStoreRegistrationSaga(
  action: ReturnType<typeof postMasterStoreRegistrationAction>
) {
  if (!action.payload) return;
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(masterApi.postMasterStoreRegistration, action.payload);
    if (status !== "CREATED") return alert("에러");
    yield put(postMasterStoreRegistrationSuccessAction());
    yield alert(`${action.payload.companyName} 지점이 등록되었습니다.`);
    yield put(getMasterStoreListAction(1, null));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* deleteMasterStoreSaga(action: ReturnType<typeof deleteMasterStoreAction>) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(masterApi.deleteMasterStore, action.id);
    if (status !== "OK") return alert("에러");
    yield put(deleteMasterStoreSuccessAction());
    // yield put(getMasterStoreListAction(1, null));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getMasterFactoryListSaga(action: ReturnType<typeof getMasterFactoryListAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(masterApi.getMasterFactoryList, action.page, action.data);
    if (status !== "OK") return alert("에러");
    yield put(getMasterFactoryListSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getMasterFactoryDetailSaga(action: ReturnType<typeof getMasterFactoryDetailAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(masterApi.getMasterFactoryDetail, action.id);
    if (status !== "OK") return alert("에러");
    // console.log(data);
    yield put(getMasterFactoryDetailSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getMasterItemListSaga(action: ReturnType<typeof getMasterItemListAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(masterApi.getMasterItemList, action.page, action.size);

    if (status !== "OK") return alert("에러");

    yield put(getMasterItemListSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getMasterItemListAllSaga(action: ReturnType<typeof getMasterItemListAllAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(masterApi.getMasterItemListAll);

    if (status !== "OK") return alert("에러");

    yield put(getMasterItemListAllSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* postMasterItemRegistrationSaga(
  action: ReturnType<typeof postMasterItemRegistrationAction>
) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(masterApi.postMasterItemRegistration, action.payload);
    if (status !== "CREATED") return alert("에러");
    if (status === "CREATED") alert("재고가 등록되었습니다.");
    yield put(getMasterItemListAction(1, action.size));
    yield put(postMasterItemRegistrationSuccessAction());
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* postMasterItemRegistrationExcelSaga(
  action: ReturnType<typeof postMasterItemRegistrationExcelAction>
) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(masterApi.postMasterItemRegistrationExcel, action.payload);

    if (status !== "OK") return alert("에러");
    if (status === "OK") alert("등록되었습니다.");
    yield put(getMasterItemListAction(1, action.size));
    yield put(postMasterItemRegistrationExcelSuccessAction());
    yield put(getMasterItemListAllAction());
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* patchMasterItemModifySaga(action: ReturnType<typeof patchMasterItemModifyAction>) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(masterApi.patchMasterItemModify, action.payload);
    if (status !== "OK") return alert("에러");
    alert("수정되었습니다.");
    yield put(patchMasterItemModifySuccessAction());
    yield put(getMasterItemListAction(action.page, action.size));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getBrandSpecificationsListSaga(
  action: ReturnType<typeof getBrandSpecificationsListAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      masterApi.getBrandSpecificationsList,
      action.id,
      action.page,
      action.data
    );
    if (status !== "OK") return alert("에러");

    yield put(getBrandSpecificationsListSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getBrandInventoryListAllSaga(action: ReturnType<typeof getBrandInventoryListAllAction>) {
  try {
    const { status, data }: AxiosResponse = yield call(masterApi.getBrandItemListAll);
    if (status !== 200) return alert("에러");
    yield put(getBrandInventoryListAllSuccessAction(data.data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* patchBrandInventoryListAllSaga(
  action: ReturnType<typeof patchBrandInventoryListAllAction>
) {
  try {
    const { status }: AxiosResponse = yield call(masterApi.patchBrandItemListAll, action.data);
    if (status !== 200) return alert("에러 발생");
    yield put(patchBrandInventoryListAllSuccessAction());
    yield put(getBrandInventoryListAllAction());
    yield put(getMasterItemListAction(action.page, action.size));
    yield alert("자산 순서를 변경하였습니다");
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* deleteBrandItemSaga(action: ReturnType<typeof deleteBrandItemAction>) {
  try {
    const { status }: AxiosResponse = yield call(masterApi.deleteAssetDataApi, action.id);
    if (status !== 200) return alert("에러 발생");
    yield put(getMasterItemListAction(action.page, action.size));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

export function* masterSaga() {
  yield takeEvery(GET_MASTER_DASHBOARD, getMasterDashboardSaga);
  yield takeEvery(GET_MASTER_LAUNDRY_LIST, getMasterLaundryListSaga);
  yield takeEvery(GET_MASTER_LAUNDRY_DETAIL, getMasterLaundryDetailSaga);
  yield takeEvery(GET_MASTER_BILLING_LIST, getMasterBillingListSaga);
  yield takeEvery(GET_MASTER_BILLING_DETAIL, getMasterBillingDetailSaga);
  yield takeEvery(GET_MASTER_STORE_LIST, getMasterStoreListSaga);
  yield takeEvery(GET_MASTER_STORE_DETAIL, getMasterStoreDetailSaga);
  yield takeEvery(POST_MASTER_STORE_REGISTRATION, postMasterStoreRegistrationSaga);
  yield takeEvery(DELETE_MASTER_STORE, deleteMasterStoreSaga);
  yield takeEvery(GET_MASTER_FACTORY_LIST, getMasterFactoryListSaga);
  yield takeEvery(GET_MASTER_FACTORY_DETAIL, getMasterFactoryDetailSaga);
  yield takeEvery(GET_MASTER_ITEM_LIST, getMasterItemListSaga);
  yield takeEvery(GET_MASTER_ITEM_LIST_ALL, getMasterItemListAllSaga);
  yield takeEvery(POST_MASTER_ITEM_REGISTRATION, postMasterItemRegistrationSaga);
  yield takeEvery(POST_MASTER_ITEM_REGISTRATION_EXCEL, postMasterItemRegistrationExcelSaga);
  yield takeEvery(PATCH_MASTER_ITEM_MODIFY, patchMasterItemModifySaga);
  yield takeEvery(GET_BRAND_SPECIFICATIONS_LIST, getBrandSpecificationsListSaga);
  yield takeEvery(GET_BRAND_INVENTORY_LIST_ALL, getBrandInventoryListAllSaga);
  yield takeEvery(PATCH_BRAND_INVENTORY_LIST_ALL, patchBrandInventoryListAllSaga);
  yield takeEvery(DELETE_MASTER_ITEM, deleteBrandItemSaga);
}

const initialState: IMasterState = {
  masterDashBoard: null,
  masterLaundryList: null,
  masterLaundryDetail: null,
  masterItemList: null,
  masterItemListAll: null,
  masterBillingList: null,
  masterBillingDetail: null,
  masterStoreList: null,
  masterFactoryList: null,
  masterStoreDetail: null,
  masterFactoryDetail: null,
  brandSpecificationsData: null,
  masterInventoryListAll: null,
};

function master(state = initialState, action: MasterAction) {
  switch (action.type) {
    case GET_MASTER_DASHBOARD_SUCCESS:
      return { ...state, masterDashBoard: action.masterDashBoard };
    case GET_MASTER_LAUNDRY_LIST_SUCCESS:
      return { ...state, masterLaundryList: action.masterLaundryList };
    case GET_MASTER_LAUNDRY_DETAIL_SUCCESS:
      return {
        ...state,
        masterLaundryDetail: action.masterLaundryDetail,
      };
    case GET_MASTER_BILLING_LIST_SUCCESS:
      return { ...state, masterBillingList: action.masterBillingList };
    case GET_MASTER_BILLING_DETAIL_SUCCESS:
      return {
        ...state,
        masterBillingDetail: action.masterBillingDetail,
      };
    case GET_MASTER_STORE_LIST_SUCCESS:
      return { ...state, masterStoreList: action.masterStoreList };
    case GET_MASTER_STORE_DETAIL_SUCCESS:
      return { ...state, masterStoreDetail: action.masterStoreDetail };
    case POST_MASTER_STORE_REGISTRATION_SUCCESS:
      return { ...state };
    case DELETE_MASTER_STORE_SUCCESS:
      return { ...state };
    case GET_MASTER_FACTORY_LIST_SUCCESS:
      return { ...state, masterFactoryList: action.masterFactoryList };
    case GET_MASTER_FACTORY_DETAIL_SUCCESS:
      return {
        ...state,
        masterFactoryDetail: action.masterFactoryDetail,
      };
    case GET_MASTER_ITEM_LIST_SUCCESS:
      return { ...state, masterItemList: action.masterItemList };
    case GET_MASTER_ITEM_LIST_ALL_SUCCESS:
      return { ...state, masterItemListAll: action.masterItemListAll };
    case POST_MASTER_ITEM_REGISTRATION_SUCCESS:
      return { ...state };
    case POST_MASTER_ITEM_REGISTRATION_EXCEL_SUCCESS:
      return { ...state };
    case PATCH_MASTER_ITEM_MODIFY_SUCCESS:
      return { ...state };
    case GET_BRAND_SPECIFICATIONS_LIST_SUCCESS:
      return {
        ...state,
        brandSpecificationsData: action.brandSpecificationsData,
      };
    case GET_BRAND_INVENTORY_LIST_ALL_SUCCESS:
      return {
        ...state,
        masterInventoryListAll: action.payload,
      };
    default:
      return state;
  }
}

export default master;
