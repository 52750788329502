import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Palette } from "styles/palette";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Paging from "components/common/Paging";
import AccessHistorySearch from "components/common/AccessHistorySearch";
import { AdminUserAccessHistory } from "modules/admin/types";
import NumberUtils from "utils/NumberUtils";
import moment from "moment";

interface AdminAccessHistoryProps {
  adminUserAccessHistory: AdminUserAccessHistory | null;
  onSearch: (query: string) => void;
  pageMove: (page: number) => void;
  selectSize: (size: number) => void;
  searchData: Search;
}

type Search = {
  query: string;
  size: number;
};

const AdminAccessHistory: React.FC<AdminAccessHistoryProps> = (props) => {
  const [listNumber, setListNumber] = useState<number[]>([]);
  useEffect(() => {
    setListNumber(
      NumberUtils.numberToList(
        props.adminUserAccessHistory?.currentPage ?? 0,
        props.adminUserAccessHistory?.totalCount ?? 0,
        props.searchData.size ?? 10
      )
    );
  }, [props.adminUserAccessHistory, props.searchData]);

  const selectShowEntries = (size: number) => {
    props.selectSize(size);
  };

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      fontWeight: 700,
    },
  });
  const classes = useStyles();

  return (
    <AdminAccessHistoryBlock>
      <div className="headers">
        <h3>접속 기록</h3>
      </div>
      <div className="tableSelect">
        <div>
          <span>Show </span>
          <select
            onChange={(e) => selectShowEntries(parseInt(e.target.value, 10))}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
          <span> entries</span>
        </div>
        <div className="topWrapper">
          <span>Search: </span>
          <div>
            <AccessHistorySearch onSearch={props.onSearch} />
          </div>
        </div>
      </div>
      <div className="tableWrapper">
        <TableContainer component={Paper} className="tables">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "70px" }}
                >
                  순번
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900 }}>
                  아이디
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "35%" }}
                >
                  이름
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "20%" }}
                >
                  최근접속일
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "20%" }}
                >
                  아이피
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.adminUserAccessHistory?.list.map((item, index) => (
                <TableRow className="tableHover" key={listNumber[index]}>
                  <TableCell component="th" scope="row" align="center">
                    {listNumber[index]}
                  </TableCell>
                  <TableCell align="center">{item.userId}</TableCell>
                  <TableCell align="center">{item.companyName}</TableCell>
                  <TableCell align="center">
                    {moment(item.userLoginDate).format("YYYY-MM-DD HH:mm:ss")}
                  </TableCell>
                  <TableCell align="center">{item.ip}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Paging
          listAction={props.pageMove}
          listCount={props.adminUserAccessHistory?.totalPage ?? 1}
          currentPage={props.adminUserAccessHistory?.currentPage}
          totalCount={props.adminUserAccessHistory?.totalCount ?? 1}
          size={props.searchData.size ?? 10}
        ></Paging>
      </div>
    </AdminAccessHistoryBlock>
  );
};

export default AdminAccessHistory;

const AdminAccessHistoryBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  .tables {
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .tableHover > td {
    padding: 10px;
  }
  .tableHover:hover {
    background-color: #eee;
    cursor: pointer;
  }
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  .tableSelect {
    height: 70px;
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: space-between;
    & > div {
      color: #999;
      height: 40px;
      overflow: hidden;
      & > span {
        font-size: 14px;
        line-height: 37px;
      }
      & > select {
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        transition: ease-in-out;
        transition-duration: 0.1s;
        border-radius: 3px;
      }
      & > select:hover {
        border-bottom: 2px solid ${Palette.primary};
      }
    }
  }
  .topWrapper {
    display: flex;
    justify-content: flex-end;
    & > span {
      font-size: 14px;
      line-height: 37px;
      margin-right: 5px;
    }
    & > div {
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div {
        margin: 0;
        margin-left: 5px;
      }
    }
  }
  .tableWrapper {
    min-width: 51rem;
    padding-bottom: 1.5rem;
  }
`;
