import { IRootState } from "modules";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoticeStatus from "components/common/NoticeStatus";
import RoleUtils from "utils/RoleUtils";
import NoticeStatusDetail from "components/common/NoticeStatusDetail";
import NoticeWrite from "components/common/NoticeWrite";
import { useEffect } from "react";
import {
  deleteNoticeDataAction,
  getBoardAlarmAction,
  getDeliveryAlarmAction,
  getLaundryCompleteAlarmAction,
  getLaundryRequestAlarmAction,
  getNoticeAlarmAction,
  getNoticeDetailDataAction,
  getNoticeListAction,
  getStatementAlarmAction,
  patchNoticeDataAction,
  postNoticeDataAction,
} from "modules/user/user";
import {
  BoardDetailData,
  BoardGetList,
  BoardPostData,
  NoticeList,
} from "modules/user/types";
import { useHistory } from "react-router";

interface NoticeContainerProps {
  match: any;
}

const NoticeContainer: React.FC<NoticeContainerProps> = ({ match }) => {
  const history = useHistory();
  const profile = useSelector((state: IRootState) => state.user.profile);
  const [noticeType, setNoticeType] = useState<string>("list");
  const [noticeDetailId, setNoticeDetailId] = useState<number>(); // 선택한 디테일 페이지 아이디 저장
  const [noticeDetailStatus, setNoitceDetailStatus] =
    useState<BoardDetailData | null>(null);
  const [auth, setAuth] = useState<string>("");
  const [searchData, setSearchData] = useState<BoardGetList | null>({
    query: "",
    dropBox: "",
    startDate: "",
    endDate: "",
    size: 10,
  });
  const [page, setPage] = useState<number | null>(1);
  const [noticeListContent, setNoticeListContent] =
    useState<NoticeList | null>();

  const noticeList = useSelector((state: IRootState) => state.user.noticeList);
  const noticeDetail = useSelector(
    (state: IRootState) => state.user.noticeDetail
  );
  const role = useSelector((state: IRootState) => state.user.profile.type);

  useEffect(() => {
    if (match.params.id && Number(match.params.id)) {
      setNoticeDetailId(match.params.id);
      setNoticeType("detail");
      dispatch(getNoticeDetailDataAction(match.params.id));
    } else {
      setNoticeType("list");
    }
  }, [match.params.id]);

  useEffect(() => {
    let authority = "";
    if (RoleUtils.isMasterAdmin(profile.type)) authority = "admin";
    if (RoleUtils.isMaster(profile.type)) authority = "master";
    if (RoleUtils.isStore(profile.type)) authority = "store";
    if (RoleUtils.isFactory(profile.type)) authority = "factory";
    setAuth(authority);
  }, [profile.type]);

  const query = window.location.pathname;

  useEffect(() => {
    if (query === `/${auth}/notice/detail`) setNoticeType("detail");
    if (query === `/${auth}/notice/modify`) setNoticeType("modify");
    if (query === `/${auth}/notice/add`) setNoticeType("write");
    if (query === `/${auth}/notice`) setNoticeType("list");
  }, [query, auth]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNoticeListAction(1, null));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getNoticeListAction(page!, searchData));
  }, [noticeType, dispatch, page, searchData]);

  useEffect(() => {
    setNoitceDetailStatus(noticeDetail ?? null);
  }, [dispatch, noticeDetail]);

  useEffect(() => {
    setNoticeListContent(noticeList);
  }, [dispatch, noticeList]);

  const noticeListData = (page: number, data: BoardGetList | null) => {
    setSearchData(data);
    setPage(page);
    dispatch(getNoticeListAction(page, data));
  };

  const selectNoticeType = (type: string) => {
    setNoticeType(type);
  };

  const detailSelect = (id: number) => {
    setNoitceDetailStatus(null);
    setNoticeDetailId(id);
    dispatch(getNoticeDetailDataAction(id));
    selectNoticeType("detail");
  };

  const deleteNotice = (id: number) => {
    dispatch(deleteNoticeDataAction(id, searchData));
  };

  const noticeDataSubmit = (noticeCommand: BoardPostData) => {
    dispatch(postNoticeDataAction(noticeCommand, searchData));
  };

  const noticeDataModify = (noticeCommand: BoardPostData) => {
    dispatch(
      patchNoticeDataAction(noticeCommand, noticeDetailId!, page!, searchData)
    );
  };

  useEffect(() => {
    dispatch(getNoticeAlarmAction());
    dispatch(getBoardAlarmAction());

    if (RoleUtils.isFactory(role)) {
      dispatch(getDeliveryAlarmAction());
      dispatch(getLaundryRequestAlarmAction());
    }
    if (RoleUtils.isStore(role)) {
      dispatch(getStatementAlarmAction());
      dispatch(getLaundryCompleteAlarmAction());
    }
  }, [dispatch, role]);

  return (
    <>
      {noticeType === "list" && (
        <NoticeStatus
          role={profile.type}
          noticeList={noticeListContent!}
          selectNoticeType={selectNoticeType}
          noticeListData={noticeListData}
          detailSelect={detailSelect}
          auth={auth}
        />
      )}
      {noticeType === "detail" && (
        <NoticeStatusDetail
          role={profile.type}
          noticeDetailId={noticeDetailId!}
          noticeDetail={noticeDetailStatus}
          selectNoticeType={selectNoticeType}
          deleteNotice={deleteNotice}
          auth={auth}
        />
      )}
      {noticeType === "write" && (
        <NoticeWrite
          selectNoticeType={selectNoticeType}
          noticeDataSubmit={noticeDataSubmit}
          type="write"
          auth="admin"
          role={role}
        />
      )}
      {noticeType === "modify" && (
        <NoticeWrite
          detailId={noticeDetailId}
          selectNoticeType={selectNoticeType}
          type="modify"
          content={noticeDetail}
          noticeDataSubmit={noticeDataModify}
          auth="admin"
          role={role}
        />
      )}
    </>
  );
};

export default NoticeContainer;
