import React, { useEffect } from "react";
import styled from "styled-components";
import Button from "components/common/Button";
import { Palette, ThemeColor } from "styles/palette";
import { Address, MasterStoreRegistration } from "modules/master/types";
import { useState } from "react";
import ScrollUtils from "utils/ScrollUtils";
import DaumPostCode from "components/common/DaumPostCode";
import { CenterAligned } from "components/common/layouts/CenterAligned";
import NumberUtils from "utils/NumberUtils";

interface RegistrationLayoutProps {
  duplicateCheckRequest: (id: string) => void;
  onClose: () => void;
  masterStoreRegistration: (payload: MasterStoreRegistration) => void;
}

const RegistrationLayout: React.FC<RegistrationLayoutProps> = ({
  duplicateCheckRequest,
  onClose,
  masterStoreRegistration,
}) => {
  const [storeId, setStoreId] = useState("");
  const [storePassword, setStorePassword] = useState("");
  const [storeName, setStoreName] = useState("");
  const [storePhone, setStorePhone] = useState("");
  const [storeManagerName, setStoreManagerName] = useState("");
  const [storeManagerPhone, setStoreManagerPhone] = useState<string | null>(
    null
  );

  const [postCodeCheck, setPostCodeCheck] = useState(false);
  const [addressTrueCheck, setAddressTureCheck] = useState(false);
  const [zoneCode, setZoneCode] = useState<string | null>(null);
  const [fullAddress, setFullAddress] = useState<string | null>(null);
  const [extraAddress, setExtraAddress] = useState<string | null>(null);
  const [payMethod, setPayMethod] = useState<string>("unit");

  const storeRegistration = () => {
    if (
      !storeId ||
      !storePassword ||
      !storeName ||
      !storePhone ||
      !storeManagerName ||
      !storeManagerPhone
    )
      return alert("빈값을 입력해주세요");
    if (!addressTrueCheck) return alert("주소를 입력해주세요.");
    if (!extraAddress) return alert("주소를 입력해주세요.");

    const payloadAddress: Address = {
      postCode: zoneCode!,
      street: fullAddress!,
      streetDetails: extraAddress!,
      isLoadName: addressTrueCheck,
    };
    const payload: MasterStoreRegistration = {
      username: storeId,
      password: storePassword,
      phone: storePhone,
      address: payloadAddress,
      companyName: storeName,
      managerName: storeManagerName,
      managerPhone: storeManagerPhone,
      payment: payMethod,
    };

    masterStoreRegistration(payload);
    onClose();
  };

  const onAddress = (zoneCode: string, fullAddress: string) => {
    setAddressTureCheck(true);
    setZoneCode(zoneCode);
    setFullAddress(fullAddress);
    setExtraAddress("");
    setPostCodeCheck(false);
  };

  useEffect(() => {
    ScrollUtils.onPopupScrollStop(window.scrollY);
    return () => {
      const scrollY = document.body.style.top;
      ScrollUtils.closePopupScrollRun(scrollY);
    };
  }, []);
  return (
    <MasterRegistrationLayoutBlock>
      {postCodeCheck && (
        <div className="postCode">
          <div>
            <DaumPostCode onAddress={onAddress} />
            <CenterAligned>
              <Button
                theme={ThemeColor.tertiary}
                buttonSize="m"
                onClick={() => setPostCodeCheck(false)}
                className="postCodeCloseBtn"
              >
                닫기
              </Button>
            </CenterAligned>
          </div>
        </div>
      )}
      <div className="headers">
        <h3>지점 등록</h3>
      </div>
      <div className="btnBox">
        <Button
          theme={ThemeColor.primary}
          buttonSize="m"
          onClick={storeRegistration}
        >
          저장
        </Button>
        <Button theme={ThemeColor.primary} buttonSize="m" onClick={onClose}>
          취소
        </Button>
      </div>
      <section className="contentWrapper">
        <div className="inputField">
          <div className="field">
            <span>아이디</span>
            <input
              name="id"
              className="userId"
              value={storeId}
              onChange={(e) => setStoreId(e.target.value)}
            />
            <Button
              theme={ThemeColor.primary}
              buttonSize="m"
              className="changeIdBtn"
              onClick={() => duplicateCheckRequest(storeId!)}
            >
              중복체크
            </Button>
          </div>
          <div className="field">
            <span>비밀번호</span>
            <input
              name="password"
              value={storePassword}
              onChange={(e) => setStorePassword(e.target.value)}
            />
          </div>
          <div className="field">
            <span>지점명</span>
            <input
              name="name"
              value={storeName}
              onChange={(e) => setStoreName(e.target.value)}
            />
          </div>
          <div className="field">
            <span>연락처</span>
            <input
              name="phone"
              value={storePhone}
              onChange={(e) =>
                setStorePhone(NumberUtils.numberOnlyToString(e.target.value))
              }
            />
          </div>
          <div className="field secoundField">
            <span>주소</span>
            <div>
              <input value={zoneCode ?? ""} disabled />
              <Button
                theme={ThemeColor.primary}
                buttonSize="s"
                className="postalCodeBtn"
                onClick={() => setPostCodeCheck(true)}
              >
                찾기
              </Button>
            </div>
          </div>
          <div className="field">
            <span></span>
            <input name="address" value={fullAddress ?? ""} disabled />
          </div>
          <div className="field">
            <span></span>
            <input
              value={extraAddress ?? ""}
              onChange={(e) => setExtraAddress(e.target.value)}
            />
          </div>
        </div>
        <div className="inputField">
          <div className="field">
            <span>담당자명</span>
            <input
              name="managerName"
              value={storeManagerName ?? ""}
              onChange={(e) => setStoreManagerName(e.target.value)}
            />
          </div>
          <div className="field">
            <span>담당자연락처</span>
            <input
              name="managerPhone"
              value={storeManagerPhone ?? ""}
              onChange={(e) =>
                setStoreManagerPhone(
                  NumberUtils.numberOnlyToString(e.target.value)
                )
              }
            />
          </div>
          {/* <div className="paymentMethod field">
            <span>결제방식</span>
            <div>
              <label className={payMethod === "unit" ? "active" : ""}>
                <input
                  type="radio"
                  value="unit"
                  name="paymentMethod"
                  checked={payMethod === "unit"}
                  onChange={(e) => {
                    setPayMethod(e.target.value);
                  }}
                />
                <span>단가제</span>
              </label>
              <label className={payMethod === "month" ? "active" : ""}>
                <input
                  type="radio"
                  value="month"
                  name="paymentMethod"
                  checked={payMethod === "month"}
                  onChange={(e) => {
                    setPayMethod(e.target.value);
                  }}
                />
                <span>월정액</span>
              </label>
            </div>
          </div> */}
        </div>
      </section>
    </MasterRegistrationLayoutBlock>
  );
};

export default RegistrationLayout;

const MasterRegistrationLayoutBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  & > .btnBox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
  }
  & > .contentWrapper {
    width: 100%;
    height: 690px;

    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    & > div {
      width: calc(100% / 2);
      /* border: 1px solid black; */
    }
    & > div:nth-child(2) {
      /* border-left: 1px solid black; */
      padding-left: 70px;
    }
    & > .inputField {
      width: auto;
      margin: 0 auto;

      & > .field {
        width: 100%;
        min-height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
        & > span {
          width: 140px;
        }
        & > p,
        input {
          border: 1px solid #ddd;
          border-radius: 5px;
          height: 100%;
          height: 37px;
          width: 260px;
          line-height: 35px;
          margin: 0;
          padding-left: 10px;
        }
        & > .userId {
          width: 179px;

          margin-right: 5px;
        }
        & > div {
          & > input {
            border: 1px solid #ddd;
            margin-right: 5px;
          }
        }
        & > .PWBtn {
          width: 260px;
        }
      }
      & > .secoundField {
        & > div {
          & > input {
            width: 174px;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .postCode {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    & > div {
      width: 30rem;
      & > div {
        & > .postCodeCloseBtn {
          margin-top: 1rem;
        }
      }
    }
  }
`;

export const RegistrationLayoutBlock = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 3;
  .registrationLayout {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    padding: 3rem;
    border-radius: 2.6rem;
    h2 {
      color: #016241;
    }
    .content {
      flex: 1;
      width: 800px;
      margin: 0 auto;
      margin-top: 28px;
      display: flex;
      justify-content: space-between;
      .inputField {
        width: 330px;
        height: auto;
        .field {
          /* height: 2.1rem; */
          display: grid;
          grid-template-columns: 6.5rem 1fr;
          line-height: 2.1rem;
          input {
            height: 2.1rem;
          }
        }
        .paymentMethod {
          display: grid;
          & > div {
            display: flex;
            justify-content: space-between;
            & > label {
              width: 45%;
              padding: 0 0.5rem;
              margin: 0;
              border-radius: 5px;
              border: 1px solid #ccc;
              color: #000000;
              background-color: #f0f0f0;
              & > input {
                display: none;
              }
              & > span {
                display: block;
                text-align: center;
                line-height: 2.1rem;
              }
              &.active {
                color: #ffffff;
                background-color: ${Palette.primary};
              }
            }
          }
        }
        .field + .field {
          margin-top: 10px;
        }
        .secoundField {
          height: 2.1rem;
          & > div {
            display: grid;
            grid-template-columns: 6.5rem 1fr 1fr;
            input {
              width: 100px;
            }
            .postalCodeBtn {
              /* width: 1; */
              height: 2.1rem;
              /* line-height: 2.1rem; */
              /* vertical-align: center; */
              margin-left: 10px;
              padding: 0;
            }
          }
        }
      }
    }
    .buttonWrapper {
      margin: 0 auto;
      padding: 1rem;
    }
  }
  .postCode {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    & > div {
      width: 30rem;
      & > div {
        & > .postCodeCloseBtn {
          margin-top: 1rem;
        }
      }
    }
  }
`;
