import { ISubNavigation } from "constants/subNavigation";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import RoleUtils from "utils/RoleUtils";
import { NavLink, useHistory } from "react-router-dom";
import styled from "styled-components";
import right_arrow from "assets/icons/right_arrow.png";
import right_arrow_sel from "assets/icons/right_arrow_sel.png";
import main_logo from "assets/logo/main_logo.png";
import { Palette, ThemeColor } from "styles/palette";

interface SidebarProps {
  username: string;
  testType: string;
  role: string;
  onLogout: () => void;
  navigationList: ISubNavigation[];
  name?: string;
}

type SidebarBlockProps = {
  testType: string;
};

const Sidebar: React.FC<SidebarProps> = ({
  username,
  testType,
  role,
  onLogout,
  navigationList,
  name,
}) => {
  const history = useHistory();
  const [type, setType] = useState<string>("");
  useEffect(() => {
    if (RoleUtils.isMasterAdmin(role)) return setType("ADMIN PAGE");
    if (RoleUtils.isMaster(role)) return setType("BRAND PAGE");
    if (RoleUtils.isStore(role)) return setType("FRANCHISE PAGE");
    if (RoleUtils.isFactory(role)) return setType("LAUNDRY FACTORY PAGE");
  }, [role]);

  // console.log(history.location.pathname.match("/admin/payment") && "test");

  return (
    <SidebarBlock testType={testType}>
      <div className="sidebarWrapper">
        <span>Navigation</span>
        <ul>
          {navigationList.map((nav) => (
            <>
              {!nav.newTab ? (
                <>
                  <NavLink to={nav.path} exact={nav.exact} activeClassName="active" key={nav.path}>
                    <li>
                      <img
                        src={history.location.pathname.match(nav.path) ? nav.icon_sel : nav.icon}
                        alt="icon"
                      />
                      <span>{nav.title}</span>
                      <img
                        className="arrow"
                        src={
                          history.location.pathname.match(nav.path) ? right_arrow_sel : right_arrow
                        }
                        alt="icon"
                      />
                    </li>
                  </NavLink>
                </>
              ) : (
                <a
                  href="https://cds.carbeast.co.kr/biz/index.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <li>
                    <img
                      src={history.location.pathname.match(nav.path) ? nav.icon_sel : nav.icon}
                      alt="icon"
                    />
                    <span>{nav.title}</span>
                    <img
                      className="arrow"
                      src={
                        history.location.pathname.match(nav.path) ? right_arrow_sel : right_arrow
                      }
                      alt="icon"
                    />
                  </li>
                </a>
              )}
            </>
          ))}
        </ul>

        <div className="loginInfo">
          <div>
            <span>{RoleUtils.isMasterAdmin(role) ? "관리자" : name ?? "-"}</span>
            <span>세탁관리플랫폼</span>
          </div>

          {type}
        </div>
      </div>
    </SidebarBlock>
  );
};

export default Sidebar;

const SidebarBlock = styled.div<SidebarBlockProps>`
  width: auto;
  height: inherit;
  position: relative;
  z-index: 2;
  color: ${Palette.white};

  .sidebarWrapper {
    /* position: fixed; */
    height: calc(100% - 60px);
    width: ${(props) => (props.testType === "small" ? "60px" : "230px")};
    background-color: #fff;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3);
    padding-top: 20px;
    margin-top: 60px;
    transition: ease-in-out;
    transition-duration: 0.2s;
    border-top-right-radius: 7px;
    & > span {
      width: 86%;
      display: block;
      margin: 0 auto;
      color: ${Palette.primary};
      font-size: 13px;
      margin-bottom: 12px;
      font-weight: 600;
      display: ${(props) => props.testType === "small" && "none"};
    }
    & > ul {
      margin: 0;
      padding: 0;
      width: ${(props) => (props.testType === "small" ? "80%" : "100%")};
      margin: 0 auto;
      & > a {
        display: inline-block;
        width: 100%;

        & > li {
          width: 80%;
          height: 42px;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 7px;
          transition: ease-in-out;
          transition-duration: 0.2s;
          padding: ${(props) => (props.testType === "small" ? "0.6rem .7rem" : "0.6rem 1rem")};
          color: #333;
          & > * {
            display: block;
          }
          & > span {
            width: 85px;
            font-weight: bold;
            font-size: 13px;
            font-family: "Nanum Gothic", sans-serif;
            overflow: hidden;
            display: ${(props) => props.testType === "small" && "none"};
          }
          & > img {
            width: 1rem;
            height: 1rem;
            &.arrow {
              width: 0.25rem;
              height: 0.6rem;
              display: ${(props) => props.testType === "small" && "none"};
            }
          }
        }
      }
      .active {
        & > li {
          background-color: ${Palette.primary};
          transition: ease-in-out;
          transition-duration: 0.2s;
          box-shadow: ${(props) => props.testType !== "small" && "2px 1px 4px rgba(0, 0, 0, 0.3)"};
          width: 90%;
          padding: ${(props) => (props.testType === "small" ? "0.7rem 10px" : ".7rem 15px")};
          & > span {
            font-weight: 700;
            font-size: 15px;
            color: #fff;
          }
          img {
            width: 1.5rem;
            height: 1.5rem;
            &.arrow {
              width: 0.3rem;
              height: 0.6.5rem;
            }
          }
        }
      }
    }
    .loginInfo {
      width: 100%;
      height: 40px;
      position: absolute;
      bottom: 100px;
      text-align: center;
      /* line-height: 40px; */
      font-size: 14px;
      font-weight: bold;
      color: ${Palette.primary};
      transition: ease-in-out;
      transition-duration: 0.2s;
      opacity: ${(props) => (props.testType === "small" ? 0 : 1)};
      display: ${(props) => props.testType === "small" && "none"};
      & > div {
        margin-bottom: 30px;
        & > span {
          display: block;
          margin: 0;
          padding: 0;
        }
        & > span:nth-child(1) {
          font-size: 18px;
          margin-bottom: 4px;
          font-weight: 900;
        }
        & > span:nth-child(2) {
          font-size: 18px;
        }
      }
    }
  }
`;
