import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
//import { DateInput } from "./DateInput";
//import { SpinButtons } from "./SpinButtons";
import { Dates } from "./Dates";
import { MonthYearAccordian } from "./MonthYearAccordian";
import { Pane } from "./Pane";
import { DatePicker } from "./DatePicker";
import DateFormatUtils from "utils/DateFormatUtils";

//import "./styles.css";
interface DateAppProps {
  dateItem: (date: string) => void;
  today?: string;
  clickCheck?: string;
  notDefault?: boolean;
}

const DateApp: React.FC<DateAppProps> = (props) => {
  const today = new Date();

  const [date, setDate] = useState("");

  useEffect(() => {
    setDate("");

    setTimeout(() => {
      if (date === "") setDate(props.today ? props.today : "");
    }, 300);
  }, [props.today]);

  return (
    <div className="DateApp">
      <DatePicker
        defaultValue={!date ? "" : date}
        onChange={setDate}
        check={props.today ? true : false}
        setSearchDate={props.dateItem}
        clickCheck={props.clickCheck}
      />
      {/* <div style={{ height: 200, backgroundColor: "yellow" }} />
      {false && (
        <>
          <MonthYearAccordian />
          <Dates />
          <Pane />
        </>
      )} */}
    </div>
  );
};

export default DateApp;
