import axios from "api/defaultClient";
import {
  BoardGetList,
  BoardPostData,
  ILoginForm,
  PasswordChangeForm,
} from "modules/user/types";

export const login = (loginForm: ILoginForm) => {
  return axios.post("/auth/login", loginForm);
};

export const getProfile = () => {
  return axios.get("/user/me");
};

export const patchPasswordChange = (payload: PasswordChangeForm) => {
  return axios.patch(`/user/password`, payload);
};

export const getBoardList = (page: number, data: BoardGetList | null) => {
  if (data) {
    return axios.get(
      `/board?page=${page}&dropBox=${data.dropBox}&query=${data.query}&size=${data.size}`
    );
  } else {
    return axios.get(`/board?page=${page}`);
  }
};
export const getBoardDetail = (id: number) => {
  return axios.get(`/board/${id}`);
};
export const getBoardToUserList = () => {
  return axios.get(`/user/mine`);
};
export const postBoardContent = (boardCommand: BoardPostData) => {
  return axios.post(`/board`, boardCommand);
};
export const patchBoardContent = (boardCommand: BoardPostData, id: number) => {
  return axios.patch(`/board/${id}`, boardCommand);
};
export const deleteBoardContent = (id: number) => {
  return axios.delete(`/board/${id}`);
};
export const postBoardComment = (data: any, id: number) => {
  return axios.post(`/board/comment/${id}`, data);
};
export const patchBoardComment = (data: any, id: number) => {
  return axios.patch(`/board/comment/${id}`, data);
};
export const deleteBoardCommnet = (id: number) => {
  return axios.delete(`/board/comment/${id}`);
};
//세탁집계표 데이터
export const getExcelData = (id: number) => {
  return axios.get(`/user/excel/${id}`);
};
//거래명세서 데이터
export const getExcelData2 = (id: number) => {
  return axios.get(`/user/billing/excel/${id}`);
};

// NOTICE

export const getNoticeList = (page: number, data: BoardGetList | null) => {
  if (data) {
    return axios.get(
      `/notice?page=${page}&dropBox=${data.dropBox}&query=${data.query}&size=${data.size}`
    );
  } else {
    return axios.get(`/notice?page=${page}`);
  }
};

export const getNoticeDetail = (id: number) => {
  return axios.get(`/notice/${id}`);
};

export const postNoticeContent = (noticeCommand: BoardPostData) => {
  return axios.post(`/notice/save`, noticeCommand);
};

export const patchNoticeContent = (
  noticeCommand: BoardPostData,
  id: number
) => {
  return axios.patch(`/notice/${id}`, noticeCommand);
};

export const deleteNoticeContent = (id: number) => {
  return axios.delete(`/notice/${id}`);
};

export const getNoticeAlarm = () => {
  return axios.get(`/notice/new`);
};

export const getBoardAlarm = () => {
  return axios.get(`/board/alarm/new`);
};

export const getLaundryCompleteAlarm = () => {
  return axios.get(`/franchise/laundry/alarm/new`);
};

export const getStatementAlarm = () => {
  return axios.get(`/franchise/history/alarm/new`);
};

export const getLaundryRequestAlarm = () => {
  return axios.get(`/factory/laundry/alarm/new`);
};
export const getDeliveryAlarm = () => {
  return axios.get(`/factory/history/alarm/new`);
};

export const getBrandLaundryAlarm = () => {
  return axios.get(`/brand/laundry/alarm/new`);
};
export const getBrandHistoryAlarm = () => {
  return axios.get(`/brand/history/alarm/new`);
};

export const getAdminLaundryAlarm = () => {
  return axios.get(`/admin/laundry/alarm/new`);
};
export const getAdminHistoryAlarm = () => {
  return axios.get(`/admin/history/alarm/new`);
};

export const getSpecificationsDetail = (id: number) => {
  return axios.get(`/history/${id}`);
};

export const getSpecificationsFranchiseList = () => {
  return axios.get(`/user/franchise/simple`);
};

export const getAlarmAllCheck = () => {
  return axios.post(`/user/all-check`);
};
