import React from "react";
import styled from "styled-components";
import { ThemeColor } from "styles/palette";

import { AdminPaymentDeatilData } from "modules/admin/types";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "components/common/Button";
import NumberUtils from "utils/NumberUtils";

interface AdminPaymentDetailListProps {
  onClose: () => void;
  detailData: AdminPaymentDeatilData | null;
}

const AdminPaymentDetailList: React.FC<AdminPaymentDetailListProps> = ({
  onClose,
  detailData,
}) => {
  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      fontWeight: 700,
    },
  });

  const classes = useStyles();

  return (
    <AdminPaymentDetailListBlock>
      <div className="headers"></div>
      <div className="tableSelect">
        <TableContainer component={Paper} className="tables">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "10%" }}
                >
                  순번
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "37.5%" }}
                >
                  이름
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "37.5%" }}
                >
                  세탁물 수량(개)
                </TableCell>
                <TableCell
                  align="right"
                  style={{ fontWeight: 900, width: "15%" }}
                >
                  금액(원)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {detailData?.list.map((data, index) => (
                <TableRow className="tableHover" key={index}>
                  <TableCell component="th" scope="row" align="center">
                    {index + 1}
                  </TableCell>
                  <TableCell align="center">{data.name}</TableCell>
                  <TableCell align="center">
                    {data.amount ? NumberUtils.numberToComma(data.amount) : "-"}
                  </TableCell>
                  <TableCell align="right" style={{ paddingRight: "14px" }}>
                    {data.price ? NumberUtils.numberToComma(data.price) : "-"}
                  </TableCell>
                </TableRow>
              ))}

              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                ></TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, color: "#000" }}
                >
                  합계
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, color: "#000" }}
                >
                  {detailData?.totalAmount
                    ? NumberUtils.numberToComma(detailData?.totalAmount)
                    : "-"}
                </TableCell>
                <TableCell
                  align="right"
                  style={{ fontWeight: 900, color: "#000" }}
                >
                  {detailData?.totalPrice
                    ? NumberUtils.numberToComma(detailData?.totalPrice)
                    : "-"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div className="btnBox">
          <Button theme={ThemeColor.primary} buttonSize="m" onClick={onClose}>
            뒤로가기
          </Button>
        </div>
      </div>
    </AdminPaymentDetailListBlock>
  );
};

export default AdminPaymentDetailList;

const AdminPaymentDetailListBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  margin-bottom: 16px;
  .tableSelect {
    & > .btnBox {
      margin-top: 15px;
      display: flex;
      justify-content: flex-end;
    }
  }
`;
