import { AxiosResponse } from "axios";
import { call, takeEvery, put } from "redux-saga/effects";
import {
  IStoreState,
  StoreAction,
  FranchiseDashBoardData,
  FranchiseLaundryPage,
  FranchiseDetailLaundry,
  FranchiseBillingPage,
  FranchiseDetailBilling,
  FranchiseAddBagCount,
  FranchiseAddItem,
  FranchiseItem,
  FranchiseLaundryFactory,
  FranchiseItemList,
  FranchiseItemModify,
  FranchiseGetList,
  FranchiseGetItem,
  FranchiseSpecificationsData,
  itemList,
  StoreItemExcelUpload,
  StoreStaffInfoExcelUpload,
  PersonalInfoList,
  StoreStaffDepartmentName,
  StoreStaffDepartmentUserName,
  StoreStaffDepartmentList,
  StoreStaffDepartmentListRequest,
  LabelStaffDepartmentList,
  LabelStaffDepartmentRequest,
  DeleteStaffInfoData,
} from "./types";
import * as storeApi from "api/storeApi";
import { getFactoryBillingListAction } from "modules/factory/factory";
import { getSpecificationsDetailAction } from "modules/user/user";

const GET_FRANCHISE_DASHBOARD = "GET_FRANCHISE_DASHBOARD" as const;
const GET_FRANCHISE_DASHBOARD_SUCCESS = "GET_FRANCHISE_DASHBOARD_SUCCESS" as const;

const GET_FRANCHISE_BILLING_LIST = "GET_FRANCHISE_BILLING_LIST" as const;
const GET_FRANCHISE_BILLING_LIST_SUCCESS = "GET_FRANCHISE_BILLING_LIST_SUCCESS" as const;

const GET_FRANCHISE_BILLING_DETAIL = "GET_FRANCHISE_BILLING_DETAIL" as const;
const GET_FRANCHISE_BILLING_DETAIL_SUCCESS = "GET_FRANCHISE_BILLING_DETAIL_SUCCESS" as const;

const GET_FRANCHISE_LAUNDRY_LIST = "GET_FRANCHISE_LAUNDRY_LIST" as const;
const GET_FRANCHISE_LAUNDRY_LIST_SUCCESS = "GET_FRANCHISE_LAUNDRY_LIST_SUCCESS" as const;

const GET_FRANCHISE_DETAIL_LAUNDRY = "GET_FRANCHISE_DETAIL_LAUNDRY" as const;
const GET_FRANCHISE_DETAIL_LAUNDRY_SUCCESS = "GET_FRANCHISE_DETAIL_LAUNDRY_SUCCESS" as const;

const GET_FRANCHISE_LAUNDRY_FACTORY = "GET_FRANCHISE_LAUNDRY_FACTORY" as const;
const GET_FRANCHISE_LAUNDRY_FACTORY_SUCCESS = "GET_FRANCHISE_LAUNDRY_FACTORY_SUCCESS" as const;

const ADD_FRANCHISE_LAUNDRY_ORDER = "ADD_FRANCHISE_LAUNDRY_ORDER" as const;
const ADD_FRANCHISE_LAUNDRY_ORDER_SUCCESS = "ADD_FRANCHISE_LAUNDRY_ORDER_SUCCESS" as const;

const GET_FRANCHISE_ITEM_LIST = "GET_FRANCHISE_ITEM_LIST" as const;
const GET_FRANCHISE_ITEM_LIST_SUCCESS = "GET_FRANCHISE_ITEM_LIST_SUCCESS" as const;

const GET_FRANCHISE_ITEM_DATA = "GET_FRANCHISE_ITEM_DATA" as const;
const GET_FRANCHISE_ITEM_DATA_SUCCESS = "GET_FRANCHISE_ITEM_DATA_SUCCESS" as const;

const GET_FRANCHISE_ITEM_DATA_ALL = "GET_FRANCHISE_ITEM_DATA_ALL" as const;
const GET_FRANCHISE_ITEM_DATA_ALL_SUCCESS = "GET_FRANCHISE_ITEM_DATA_ALL_SUCCESS" as const;

const PATCH_FRANCHISE_ITEM_MODIFY = "PATCH_FRANCHISE_ITEM_MODIFY" as const;
const PATCH_FRANCHISE_ITEM_MODIFY_SUCCESS = "PATCH_FRANCHISE_ITEM_MODIFY_SUCCESS" as const;

const DELETE_FRANCHISE_ITEM = "DELETE_FRANCHISE_ITEM" as const;
const DELETE_FRANCHISE_ITEM_SUCCESS = "DELETE_FRANCHISE_ITEM_SUCCESS" as const;

const ADD_FRANCHISE_ITEM = "ADD_FRANCHISE_ITEM" as const;
const ADD_FRANCHISE_ITEM_SUCCESS = "ADD_FRANCHISE_ITEM_SUCCESS" as const;

const GET_FRANCHISE_SPECIFICATIONS_LIST = "GET_FRANCHISE_SPECIFICATIONS_LIST" as const;
const GET_FRANCHISE_SPECIFICATIONS_LIST_SUCCESS =
  "GET_FRANCHISE_SPECIFICATIONS_LIST_SUCCESS" as const;

const POST_FRANCHISE_SPECIFICATIONS_CONFIRM = "POST_FRANCHISE_SPECIFICATIONS_CONFIRM" as const;
const POST_FRANCHISE_SPECIFICATIONS_CONFIRM_SUCCESS =
  "POST_FRANCHISE_SPECIFICATIONS_CONFIRM_SUCCESS" as const;

const PATCH_SUJUNG_REQUEST = "PATCH_SUJUNG_REQUEST" as const;
const PATCH_AMOUNT_EXCEL = "PATCH_AMOUNT_EXCEL" as const;

// 직원 정보 관리 엑셀등록
const POST_STORE_STAFF_INFO_EXCEL = "POST_STORE_STAFF_INFO_EXCEL" as const;

// 직원 정보 관리 등록
const POST_STORE_STAFF_INFO = "POST_STORE_STAFF_INFO" as const;

// 직원 정보 리스트 가져오기
const GET_STORE_STAFF_DEPARTMENT_LIST = "GET_STORE_STAFF_DEPARTMENT_LIST" as const;
const GET_STORE_STAFF_DEPARTMENT_LIST_SUCCESS = "GET_STORE_STAFF_DEPARTMENT_LIST_SUCCESS" as const;

// 직원 정보 관리 삭제
const DELETE_STORE_STAFF_DEPARTMENT_DATA = "DELETE_STORE_STAFF_DEPARTMENT_DATA" as const;

// 라벨 관계자 자산 부서명 정보
const GET_STORE_STAFF_DEPARTMENT_NAME = "GET_STORE_STAFF_DEPARTMENT_NAME" as const;
const GET_STORE_STAFF_DEPARTMENT_NAME_SUCCESS = "GET_STORE_STAFF_DEPARTMENT_NAME_SUCCESS" as const;

// 라벨 관계자 자산 부서 소속 직원 정보
const GET_STORE_STAFF_DEPARTMENT_USER_NAME = "GET_STORE_STAFF_DEPARTMENT_USER_NAME" as const;
const GET_STORE_STAFF_DEPARTMENT_USER_NAME_SUCCESS =
  "GET_STORE_STAFF_DEPARTMENT_USER_NAME_SUCCESS" as const;

// 라벨 관계자 자산 리스트
const GET_LABEL_STAFF_DEPARTMENT_LIST = "GET_LABEL_STAFF_DEPARTMENT_LIST" as const;
const GET_LABEL_STAFF_DEPARTMENT_LIST_SUCCESS = "GET_LABEL_STAFF_DEPARTMENT_LIST_SUCCESS" as const;

// 라벨 관계자 자산 정보 변경하기
const PATCH_LABEL_STAFF_DEPARTMENT = "PATCH_LABEL_STAFF_DEPARTMENT" as const;

// 자산 삭제하기
const DELETE_ASSET_DATA = "DELETE_ASSET_DATA" as const;

export const getFranchiseDashboardAction = (day: string) => ({
  type: GET_FRANCHISE_DASHBOARD,
  day,
});
export const getFranchiseDashboardSuccessAction = (franchiseDashBoard: FranchiseDashBoardData) => ({
  type: GET_FRANCHISE_DASHBOARD_SUCCESS,
  franchiseDashBoard,
});
export const getFranchiseBillingListAction = (page: number, data: FranchiseGetList | null) => ({
  type: GET_FRANCHISE_BILLING_LIST,
  page,
  data,
});
export const getFranchiseBillingListSuccessAction = (
  franchiseBillingPage: FranchiseBillingPage
) => ({
  type: GET_FRANCHISE_BILLING_LIST_SUCCESS,
  franchiseBillingPage,
});

export const getFranchiseBillingDetailAction = (id: number) => ({
  type: GET_FRANCHISE_BILLING_DETAIL,
  id,
});

export const getFranchiseBillingDetailSuccessAction = (
  franchiseDetailBilling: FranchiseDetailBilling
) => ({
  type: GET_FRANCHISE_BILLING_DETAIL_SUCCESS,
  franchiseDetailBilling,
});

export const getFranchiseLaundryList = (page: number, data: FranchiseGetList | null) => ({
  type: GET_FRANCHISE_LAUNDRY_LIST,
  page,
  data,
});
export const getFranchiseLaundryListSuccess = (franchiseLaundryPage: FranchiseLaundryPage) => ({
  type: GET_FRANCHISE_LAUNDRY_LIST_SUCCESS,
  franchiseLaundryPage,
});

export const getFranchiseLaundryFactoryAction = () => ({
  type: GET_FRANCHISE_LAUNDRY_FACTORY,
});

export const getFranchiseLaundryFactorySuccessAction = (
  franchiseLaundryFactory: FranchiseLaundryFactory
) => ({
  type: GET_FRANCHISE_LAUNDRY_FACTORY_SUCCESS,
  franchiseLaundryFactory,
});

export const addFranchiseLaundryOrderAction = (bagCount: FranchiseAddBagCount) => ({
  type: ADD_FRANCHISE_LAUNDRY_ORDER,
  bagCount,
});
export const addFranchiseLaundryOrderSuccessAction = () => ({
  type: ADD_FRANCHISE_LAUNDRY_ORDER_SUCCESS,
});

export const getFranchiseDetailLaundryAction = (id: number) => ({
  type: GET_FRANCHISE_DETAIL_LAUNDRY,
  id,
});
export const getFranchiseDetailLaundrySuccessAction = (
  franchiseDetailLaundry: FranchiseDetailLaundry
) => ({
  type: GET_FRANCHISE_DETAIL_LAUNDRY_SUCCESS,
  franchiseDetailLaundry,
});

export const getFranchiseItemListAction = (page: number, size: number) => ({
  type: GET_FRANCHISE_ITEM_LIST,
  page,
  size,
});

export const getFranchiseItemListSuccessAction = (franchiseItemList: FranchiseItemList) => ({
  type: GET_FRANCHISE_ITEM_LIST_SUCCESS,
  franchiseItemList,
});

export const getFranchiseItemDataAction = (payload: FranchiseGetItem) => ({
  type: GET_FRANCHISE_ITEM_DATA,
  payload,
});

export const getFranchiseItemDataSuccessAction = (franchiseItemData: FranchiseItem[] | null) => ({
  type: GET_FRANCHISE_ITEM_DATA_SUCCESS,
  franchiseItemData,
});

export const getFranchiseItemDataAllAction = (item: number) => ({
  type: GET_FRANCHISE_ITEM_DATA_ALL,
  item,
});

export const getFranchiseItemDataAllSuccessAction = (payload: itemList[] | null) => ({
  type: GET_FRANCHISE_ITEM_DATA_ALL_SUCCESS,
  payload,
});

export const patchFranchiseItemModifyAction = (
  payload: FranchiseItemModify,
  page: number,
  size: number
) => ({
  type: PATCH_FRANCHISE_ITEM_MODIFY,
  payload,
  page,
  size,
});

export const patchFranchiseItemSuccessModifyAction = () => ({
  type: PATCH_FRANCHISE_ITEM_MODIFY_SUCCESS,
});

export const deleteFranchiseItemAction = (id: number) => ({
  type: DELETE_FRANCHISE_ITEM,
  id,
});
export const deleteFranchiseItemSuccessAction = () => ({
  type: DELETE_FRANCHISE_ITEM_SUCCESS,
});

export const addFranchiseItemAction = (franchiseAddItem: FranchiseAddItem, size: number) => ({
  type: ADD_FRANCHISE_ITEM,
  franchiseAddItem,
  size,
});

export const addFranchiseItemSuccessAction = () => ({
  type: ADD_FRANCHISE_ITEM_SUCCESS,
});

export const getFranchiseSpecificationsListAction = (
  id: number | null,
  page: number,
  data: FranchiseGetList | null
) => ({
  type: GET_FRANCHISE_SPECIFICATIONS_LIST,
  id,
  page,
  data,
});
export const getFranchiseSpecificationsListSuccessAction = (
  franchiseSpecificationsData: FranchiseSpecificationsData
) => ({
  type: GET_FRANCHISE_SPECIFICATIONS_LIST_SUCCESS,
  franchiseSpecificationsData,
});

export const postFranchiseSpecificationsConfirmAction = (
  id: number,
  searchId: number | null,
  page: number,
  data: FranchiseGetList | null
) => ({
  type: POST_FRANCHISE_SPECIFICATIONS_CONFIRM,
  id,
  searchId,
  page,
  data,
});
export const postFranchiseSpecificationsConfirmSuccessAction = () => ({
  type: POST_FRANCHISE_SPECIFICATIONS_CONFIRM_SUCCESS,
});

export const patchSujungRequestAction = (id: number) => ({
  type: PATCH_SUJUNG_REQUEST,
  id,
});

export const patchAmountExcelAction = (
  payload: StoreItemExcelUpload[],
  page: number,
  size: number
) => ({
  type: PATCH_AMOUNT_EXCEL,
  payload,
  page,
  size,
});

export const postStoreStaffInfoExcelAction = (
  data: StoreStaffInfoExcelUpload,
  item: StoreStaffDepartmentListRequest
) => ({
  type: POST_STORE_STAFF_INFO_EXCEL,
  data,
  item,
});

export const postStoreStaffInfoAction = (
  data: PersonalInfoList,
  item: StoreStaffDepartmentListRequest
) => ({
  type: POST_STORE_STAFF_INFO,
  data,
  item,
});

// 직원 정보 리스트 가져오기
export const getStaffDepartmentListAction = (data: StoreStaffDepartmentListRequest) => ({
  type: GET_STORE_STAFF_DEPARTMENT_LIST,
  data,
});
export const getStaffDepartmentListSuccessAction = (
  storeStaffDepartmentList: StoreStaffDepartmentList
) => ({
  type: GET_STORE_STAFF_DEPARTMENT_LIST_SUCCESS,
  storeStaffDepartmentList,
});

// 직원 정보 삭제
export const deleteStaffDepartmentDataAction = (
  data: DeleteStaffInfoData,
  item: StoreStaffDepartmentListRequest
) => ({
  type: DELETE_STORE_STAFF_DEPARTMENT_DATA,
  data,
  item,
});

// 라벨
export const getStoreStaffDepartmentNameAction = () => ({
  type: GET_STORE_STAFF_DEPARTMENT_NAME,
});
export const getStoreStaffDepartmentNameSuccessAction = (
  storeStaffDepartmentName: StoreStaffDepartmentName[]
) => ({
  type: GET_STORE_STAFF_DEPARTMENT_NAME_SUCCESS,
  storeStaffDepartmentName,
});

// 라벨
export const getStaffDepartmentMemberAction = (id: number) => ({
  type: GET_STORE_STAFF_DEPARTMENT_USER_NAME,
  id,
});
export const getStaffDepartmentMemberSuccessAction = (
  storeStaffDepartmentUserName: StoreStaffDepartmentUserName[]
) => ({
  type: GET_STORE_STAFF_DEPARTMENT_USER_NAME_SUCCESS,
  storeStaffDepartmentUserName,
});

// 라벨 관계자 자산 리스트 가져오기
export const getLabelStaffDepartmentListAction = (data: StoreStaffDepartmentListRequest) => ({
  type: GET_LABEL_STAFF_DEPARTMENT_LIST,
  data,
});
export const getLabelStaffDepartmentListSuccessAction = (
  labelStaffDepartmentList: LabelStaffDepartmentList
) => ({
  type: GET_LABEL_STAFF_DEPARTMENT_LIST_SUCCESS,
  labelStaffDepartmentList,
});

// 라벨 관계자 자산 정보 변경하기
export const patchLabelStaffDepartmentAction = (
  data: LabelStaffDepartmentRequest,
  item: StoreStaffDepartmentListRequest
) => ({
  type: PATCH_LABEL_STAFF_DEPARTMENT,
  data,
  item,
});

export const deleteAssetDataAction = (id: number, page: number, size: number) => ({
  type: DELETE_ASSET_DATA,
  id,
  page,
  size,
});

function* getFranchiseDashboardSaga(action: ReturnType<typeof getFranchiseDashboardAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(storeApi.getDashBoard, action.day);
    if (status !== "OK") return alert("에러");
    yield put(getFranchiseDashboardSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getFranchiseBillingListSaga(action: ReturnType<typeof getFactoryBillingListAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(storeApi.billingList, action.page, action.data);
    if (status !== "OK") return alert("서버에러");
    yield put(getFranchiseBillingListSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getFranchiseBillingDetailSaga(
  action: ReturnType<typeof getFranchiseBillingDetailAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(storeApi.getBillingDetail, action.id);
    if (status !== "OK") return alert("서버에러");
    yield put(getFranchiseBillingDetailSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getFranchiseLaundryListSaga(action: ReturnType<typeof getFranchiseLaundryList>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(storeApi.laundryList, action.page, action.data);
    if (status !== "OK") return alert("서버 에러");

    yield put(getFranchiseLaundryListSuccess(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getFranchiseDetailLaundrySaga(
  action: ReturnType<typeof getFranchiseDetailLaundryAction>
) {
  try {
    const {
      data: { data, status },
    }: AxiosResponse = yield call(storeApi.getFranchiseDetailLaundry, action.id);
    if (status !== "OK") return alert("error");
    yield put(getFranchiseDetailLaundrySuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getFranchiseLaundryFactorySaga() {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(storeApi.getFranchiseLaundryFactory);
    if (status !== "OK") return alert("error");
    yield put(getFranchiseLaundryFactorySuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* addFranchiseLaundryOrderSaga(action: ReturnType<typeof addFranchiseLaundryOrderAction>) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(storeApi.addFranchiseLaundryOrder, action.bagCount);
    if (status !== "CREATED") return alert("신청 실패");
    if (status === "CREATED") alert("신청되었습니다.");
    yield put(getFranchiseLaundryList(1, null));
    yield put(addFranchiseLaundryOrderSuccessAction());
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getFranchiseItemListSaga(action: ReturnType<typeof getFranchiseItemListAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(storeApi.getFranchiseItemList, action.page, action.size);
    if (status !== "OK") return alert("서버 에러");
    yield put(getFranchiseItemListSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getFranchiseItemDataSaga(action: ReturnType<typeof getFranchiseItemDataAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(storeApi.getFranchiseItemData, action.payload);
    if (status !== "OK") return alert("서버 에러");
    yield put(getFranchiseItemDataSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getFranchiseItemDataAllSaga(action: ReturnType<typeof getFranchiseItemDataAllAction>) {
  try {
    const {
      data: { data, status },
    }: AxiosResponse = yield call(storeApi.getFranchiseItemList, 1, action.item);
    if (status !== "OK") return alert("서버 에러");
    yield put(getFranchiseItemDataAllSuccessAction(data.list));
  } catch (e) {
    // console.log(e);
  }
}

function* addFranchiseItemSaga(action: ReturnType<typeof addFranchiseItemAction>) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(storeApi.addFranchiseItem, action.franchiseAddItem);
    if (status !== "CREATED") return alert("신청 실패");
    if (status === "CREATED") alert("재고가 등록되었습니다.");
    yield put(getFranchiseItemListAction(1, action.size));
    yield put(addFranchiseItemSuccessAction());
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* patchFranchiseItemModifySaga(action: ReturnType<typeof patchFranchiseItemModifyAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(storeApi.patchFranchiseItemModify, action.payload);

    if (status !== "OK") return alert("서버 에러");
    if (data.minusAmount > 0) {
      yield alert(`저장된 태그 갯수를 제외한 ${data.minusAmount}개가 폐기되었습니다.`);
    } else {
      yield alert("재고가 수정되었습니다.");
    }
    yield put(patchFranchiseItemSuccessModifyAction());

    yield put(getFranchiseItemListAction(action.page, action.size));
  } catch (error: any) {
    return alert(error.response.data.message);
  }
}

function* deleteFranchiseItemSaga(action: ReturnType<typeof deleteFranchiseItemAction>) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(storeApi.deleteFranchiseItem, action.id);
    if (status !== "OK") return alert("서버 에러");
    yield put(deleteFranchiseItemSuccessAction());
    yield put(getFranchiseItemListAction(1, 10));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getFranchiseSpecificationsListSaga(
  action: ReturnType<typeof getFranchiseSpecificationsListAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      storeApi.getFranchiseSpecificationsList,
      action.id,
      action.page,
      action.data
    );
    if (status !== "OK") return alert("에러");

    yield put(getFranchiseSpecificationsListSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* postFranchiseSpecificationsConfirmSaga(
  action: ReturnType<typeof postFranchiseSpecificationsConfirmAction>
) {
  try {
    const {
      data: { status },
    } = yield call(storeApi.postFranchiseSpecificationsConfirm, action.id);
    if (status !== "OK") return alert("에러");
    yield put(getSpecificationsDetailAction(action.id));
    yield put(getFranchiseSpecificationsListAction(action.searchId, action.page, action.data));
    yield put(postFranchiseSpecificationsConfirmSuccessAction());
    yield alert("확인되었습니다");
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* patchSujungRequestSaga(action: ReturnType<typeof patchSujungRequestAction>) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(storeApi.patchSujungRequest, action.id);
    if (status !== "OK") return alert("에러");
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* patchAmountExcelSaga(action: ReturnType<typeof patchAmountExcelAction>) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(storeApi.patchAmountExcel, action.payload);
    if (status !== "OK") return alert("에러");
    yield put(getFranchiseItemListAction(action.page, action.size));
    yield alert("등록되었습니다");
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* postStoreStaffInfoExcelSaga(action: ReturnType<typeof postStoreStaffInfoExcelAction>) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(storeApi.postStaffInfoDataExcel, action.data);
    if (status !== "OK") return alert("에러");
    yield alert("등록되었습니다");
    yield put(getStaffDepartmentListAction(action.item));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* postStoreStaffInfoSaga(action: ReturnType<typeof postStoreStaffInfoAction>) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(storeApi.postStaffInfoData, action.data);
    if (status !== "OK") return alert("에러");
    yield alert(`${!action.data.id ? "등록" : "수정"}되었습니다.`);

    yield put(getStaffDepartmentListAction(action.item));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getStoreStaffDepartmentNameSaga() {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(storeApi.getStaffDepartment);
    if (status !== "OK") return alert("에러");
    yield put(getStoreStaffDepartmentNameSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getStaffDepartmentMemberSaga(action: ReturnType<typeof getStaffDepartmentMemberAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(storeApi.getStaffDepartmentMember, action.id);
    if (status !== "OK") return alert("에러");
    yield put(getStaffDepartmentMemberSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getStaffDepartmentListSaga(action: ReturnType<typeof getStaffDepartmentListAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(storeApi.getStaffDepartmentList, action.data);
    if (status !== "OK") return alert("에러");
    yield put(getStaffDepartmentListSuccessAction(data));
  } catch (error: any) {
    return alert(error.response.data.message);
  }
}

// 직원 정보 삭제
function* deleteStaffDepartmentDataSaga(
  action: ReturnType<typeof deleteStaffDepartmentDataAction>
) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(storeApi.deleteStaffInfoData, action.data);
    if (status !== "OK") return alert("에러");
    yield alert("삭제되었습니다");
    yield put(getStaffDepartmentListAction(action.item));
  } catch (error: any) {
    return alert(error.response.data.message);
  }
}

function* getLabelStaffDepartmentListSaga(
  action: ReturnType<typeof getLabelStaffDepartmentListAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(storeApi.getLabelStaffDepartmentList, action.data);
    if (status !== "OK") return alert("에러");
    yield put(getLabelStaffDepartmentListSuccessAction(data));
  } catch (error: any) {
    return alert(error.response.data.message);
  }
}

function* patchLabelStaffDepartmentSaga(
  action: ReturnType<typeof patchLabelStaffDepartmentAction>
) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(storeApi.patchLabelStaffDepartment, action.data);
    if (status !== "OK") return alert("에러");
    yield alert("수정되었습니다");
    yield put(getLabelStaffDepartmentListAction(action.item));
  } catch (error: any) {
    return alert(error.response.data.message);
  }
}

function* deleteAssetDataSaga(action: ReturnType<typeof deleteAssetDataAction>) {
  try {
    const {
      data: { status },
    } = yield call(storeApi.deleteAssetDataApi, action.id);
    if (status !== "OK") return;
    yield put(getFranchiseItemListAction(action.page, action.size));
  } catch (error: any) {
    return alert(error.response.data.message);
  }
}

export function* storeSaga() {
  yield takeEvery(GET_FRANCHISE_DASHBOARD, getFranchiseDashboardSaga);
  yield takeEvery(GET_FRANCHISE_BILLING_LIST, getFranchiseBillingListSaga);
  yield takeEvery(GET_FRANCHISE_BILLING_DETAIL, getFranchiseBillingDetailSaga);
  yield takeEvery(GET_FRANCHISE_LAUNDRY_LIST, getFranchiseLaundryListSaga);
  yield takeEvery(GET_FRANCHISE_DETAIL_LAUNDRY, getFranchiseDetailLaundrySaga);
  yield takeEvery(ADD_FRANCHISE_LAUNDRY_ORDER, addFranchiseLaundryOrderSaga);
  yield takeEvery(GET_FRANCHISE_ITEM_LIST, getFranchiseItemListSaga);
  yield takeEvery(ADD_FRANCHISE_ITEM, addFranchiseItemSaga);
  yield takeEvery(GET_FRANCHISE_ITEM_DATA, getFranchiseItemDataSaga);
  yield takeEvery(GET_FRANCHISE_ITEM_DATA_ALL, getFranchiseItemDataAllSaga);
  yield takeEvery(PATCH_FRANCHISE_ITEM_MODIFY, patchFranchiseItemModifySaga);
  yield takeEvery(DELETE_FRANCHISE_ITEM, deleteFranchiseItemSaga);
  yield takeEvery(GET_FRANCHISE_LAUNDRY_FACTORY, getFranchiseLaundryFactorySaga);
  yield takeEvery(GET_FRANCHISE_SPECIFICATIONS_LIST, getFranchiseSpecificationsListSaga);
  yield takeEvery(POST_FRANCHISE_SPECIFICATIONS_CONFIRM, postFranchiseSpecificationsConfirmSaga);
  yield takeEvery(PATCH_SUJUNG_REQUEST, patchSujungRequestSaga);
  yield takeEvery(PATCH_AMOUNT_EXCEL, patchAmountExcelSaga);
  yield takeEvery(POST_STORE_STAFF_INFO_EXCEL, postStoreStaffInfoExcelSaga);
  yield takeEvery(POST_STORE_STAFF_INFO, postStoreStaffInfoSaga);
  yield takeEvery(GET_STORE_STAFF_DEPARTMENT_NAME, getStoreStaffDepartmentNameSaga);
  yield takeEvery(GET_STORE_STAFF_DEPARTMENT_USER_NAME, getStaffDepartmentMemberSaga);
  yield takeEvery(GET_STORE_STAFF_DEPARTMENT_LIST, getStaffDepartmentListSaga);
  yield takeEvery(GET_LABEL_STAFF_DEPARTMENT_LIST, getLabelStaffDepartmentListSaga);
  yield takeEvery(PATCH_LABEL_STAFF_DEPARTMENT, patchLabelStaffDepartmentSaga);
  yield takeEvery(DELETE_STORE_STAFF_DEPARTMENT_DATA, deleteStaffDepartmentDataSaga);
  yield takeEvery(DELETE_ASSET_DATA, deleteAssetDataSaga);
}

const initialState: IStoreState = {
  franchiseDashBoard: null,
  franchiseLaundryPage: null,
  franchiseDetailLaundry: null,
  franchiseBillingPage: null,
  franchiseDetailBilling: null,
  franchiseAddBagCount: null,
  franchiseAddItem: null,
  franchiseItemList: null,
  franchiseItemData: null,
  franchiseItemDataAll: null,
  franchiseLaundryFactory: null,
  franchiseSpecificationsData: null,
  storeStaffDepartmentName: null,
  storeStaffDepartmentUserName: null,
  storeStaffDepartmentList: null,
  labelStaffDepartmentList: null,
};

function store(state = initialState, action: StoreAction) {
  switch (action.type) {
    case GET_FRANCHISE_DASHBOARD_SUCCESS:
      return { ...state, franchiseDashBoard: action.franchiseDashBoard };
    case GET_FRANCHISE_BILLING_LIST_SUCCESS:
      return {
        ...state,
        franchiseBillingPage: action.franchiseBillingPage,
      };
    case GET_FRANCHISE_BILLING_DETAIL_SUCCESS:
      return {
        ...state,
        franchiseDetailBilling: action.franchiseDetailBilling,
      };
    case GET_FRANCHISE_LAUNDRY_LIST_SUCCESS:
      return {
        ...state,
        franchiseLaundryPage: action.franchiseLaundryPage,
      };
    case GET_FRANCHISE_DETAIL_LAUNDRY_SUCCESS:
      return {
        ...state,
        franchiseDetailLaundry: action.franchiseDetailLaundry,
      };
    case GET_FRANCHISE_LAUNDRY_FACTORY_SUCCESS:
      return {
        ...state,
        franchiseLaundryFactory: action.franchiseLaundryFactory,
      };
    case ADD_FRANCHISE_LAUNDRY_ORDER_SUCCESS:
      return { ...state };
    case GET_FRANCHISE_ITEM_LIST_SUCCESS:
      return { ...state, franchiseItemList: action.franchiseItemList };
    case GET_FRANCHISE_ITEM_DATA_SUCCESS:
      return { ...state, franchiseItemData: action.franchiseItemData };
    case GET_FRANCHISE_ITEM_DATA_ALL_SUCCESS:
      return { ...state, franchiseItemDataAll: action.payload };
    case ADD_FRANCHISE_ITEM_SUCCESS:
      return { ...state };
    case PATCH_FRANCHISE_ITEM_MODIFY_SUCCESS:
      return { ...state };
    case DELETE_FRANCHISE_ITEM_SUCCESS:
      return { ...state };
    case GET_FRANCHISE_SPECIFICATIONS_LIST_SUCCESS:
      return {
        ...state,
        franchiseSpecificationsData: action.franchiseSpecificationsData,
      };
    case POST_FRANCHISE_SPECIFICATIONS_CONFIRM_SUCCESS:
      return { ...state };
    case GET_STORE_STAFF_DEPARTMENT_NAME_SUCCESS:
      return {
        ...state,
        storeStaffDepartmentName: action.storeStaffDepartmentName,
      };
    case GET_STORE_STAFF_DEPARTMENT_USER_NAME_SUCCESS:
      return {
        ...state,
        storeStaffDepartmentUserName: action.storeStaffDepartmentUserName,
      };
    case GET_STORE_STAFF_DEPARTMENT_LIST_SUCCESS:
      return {
        ...state,
        storeStaffDepartmentList: action.storeStaffDepartmentList,
      };
    case GET_LABEL_STAFF_DEPARTMENT_LIST_SUCCESS:
      return {
        ...state,
        labelStaffDepartmentList: action.labelStaffDepartmentList,
      };
    default:
      return state;
  }
}

export default store;
