import React from "react";
import PasswordChange from "components/common/PasswordChange";
import { PasswordChangeForm } from "modules/user/types";

interface PasswordChangeContainerProps {
  onClose: () => void;
  patchPasswordChange: (payload: PasswordChangeForm) => void;
}

const PasswordChangeContainer: React.FC<PasswordChangeContainerProps> = ({
  patchPasswordChange,
  onClose,
}) => {
  return (
    <PasswordChange
      onClose={onClose}
      patchPasswordChange={patchPasswordChange}
    />
  );
};

export default PasswordChangeContainer;
