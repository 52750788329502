import AdminFactory from "components/admin/AdminFactory";
import AdminFactoryRegistration from "components/admin/AdminFactoryRegistration";
import AdminFactoryStatusDetail from "components/admin/AdminFactoryStatusDetail";
import { IRootState } from "modules";
import {
  getAdminFactoryDetailAction,
  getAdminFactoryListAction,
  getIdDuplicateCheckAction,
  getIdDuplicationCheckCancelAction,
  patchAdminUserNameModifyAction,
  patchFactoryInfoAction,
  postAdminFactoryRegistrationAction,
  postPWInitializationAction,
} from "modules/admin/admin";
import { AdminGetList, AdminRegistration } from "modules/admin/types";
import { ProfileModify } from "modules/user/types";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import RoleUtils from "utils/RoleUtils";

interface AdminFactoryContainerProps {
  match: any;
}

const AdminFactoryContainer: React.FC<AdminFactoryContainerProps> = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [detailId, setDetailId] = useState<number | null>(null);
  const [selectType, setSelectType] = useState<string>("list");
  const [auth, setAuth] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchData, setSearchData] = useState<AdminGetList>({
    query: "",
    dropBox: "",
    startDate: "",
    endDate: "",
    size: 10,
  });

  const role = useSelector((state: IRootState) => state.user.profile.type);
  const adminFactoryList = useSelector((state: IRootState) => state.admin.adminFactoryList);
  const adminFactoryDetail = useSelector((state: IRootState) => state.admin.adminFactoryDetail);
  const adminIdDuplicateCheck = useSelector(
    (state: IRootState) => state.admin.adminIdDuplicateCheck
  );

  useEffect(() => {
    let authority = "";
    if (RoleUtils.isMasterAdmin(role)) authority = "admin";
    if (RoleUtils.isMaster(role)) authority = "master";
    if (RoleUtils.isStore(role)) authority = "store";
    if (RoleUtils.isFactory(role)) authority = "factory";
    setAuth(authority);
  }, [role]);

  const query = window.location.pathname;

  useEffect(() => {
    if (query === `/${auth}/factory/${match.params.id}`) setSelectType("detail");
    if (query === `/${auth}/factory`) setSelectType("list");
  }, [query, auth]);

  // match.params가 안잡히는 문제 toDO
  useEffect(() => {
    if (match.params.id && Number(match.params.id)) {
      setDetailId(match.params.id);
      setSelectType("detail");
      dispatch(getAdminFactoryDetailAction(match.params.id));
    } else {
      setSelectType("list");
    }
  }, [match.params.id]);

  useEffect(() => {
    dispatch(getAdminFactoryListAction(1, null));
  }, []);

  const factoryRegistration = (payload: AdminRegistration) => {
    dispatch(postAdminFactoryRegistrationAction(payload));
  };

  const factoryRegistrationStatus = () => {
    setSelectType("add");
    history.push(`/admin/factory/add`);
  };

  const factoryDetail = (id: number) => {
    setDetailId(id);
    setSelectType("detail");
    dispatch(getAdminFactoryDetailAction(id));
    history.push(`/admin/factory/${id}`);
  };

  const factoryListPageMove = (page: number, data: AdminGetList) => {
    setCurrentPage(page);
    setSearchData(data);
    dispatch(getAdminFactoryListAction(page, data));
  };

  const modifyFactoryUserName = (id: number, userName: string) => {
    dispatch(
      patchAdminUserNameModifyAction(id, userName, currentPage ?? 1, searchData ?? null, "factory")
    );
  };

  const historyBack = () => {
    setDetailId(null);
    // setSelectType("list");
    history.push(`/admin/factory`);
  };

  const postPWInitialization = (id: number) => {
    if (window.confirm("비밀번호를 초기화 하시겠습니까?")) dispatch(postPWInitializationAction(id));
  };

  const factoryInfoModify = (payload: ProfileModify) => {
    dispatch(patchFactoryInfoAction(detailId!, payload, currentPage, searchData));
  };

  const duplicateCheck = (id: string) => {
    dispatch(getIdDuplicateCheckAction(id));
  };

  const duplicateCheckCancel = () => {
    dispatch(getIdDuplicationCheckCancelAction());
  };

  return (
    <>
      {selectType === "list" && (
        <AdminFactory
          role={role}
          factoryRegistration={factoryRegistration}
          factoryListPageMove={factoryListPageMove}
          factoryDetail={factoryDetail}
          adminFactoryList={adminFactoryList}
          adminFactoryDetail={adminFactoryDetail}
          modifyFactoryUserName={modifyFactoryUserName}
          postPWInitialization={postPWInitialization}
          factoryRegistrationStatus={factoryRegistrationStatus}
        />
      )}
      {selectType === "detail" && (
        <AdminFactoryStatusDetail
          role={role}
          historyBack={historyBack}
          adminFactoryDetail={adminFactoryDetail}
          modifyFactoryUserName={modifyFactoryUserName}
          postPWInitialization={postPWInitialization}
          factoryInfoModify={factoryInfoModify}
          adminIdDuplicateCheck={adminIdDuplicateCheck}
          duplicateCheckRequest={duplicateCheck}
          duplicateCheckCancel={duplicateCheckCancel}
        />
      )}
      {selectType === "add" && (
        <AdminFactoryRegistration
          factoryRegistration={factoryRegistration}
          historyBack={historyBack}
          adminIdDuplicateCheck={adminIdDuplicateCheck}
          duplicateCheckRequest={duplicateCheck}
          duplicateCheckCancel={duplicateCheckCancel}
        />
      )}
    </>
  );
};

export default AdminFactoryContainer;
