import { FC } from "react";
import RoleUtils from "utils/RoleUtils";
import UnAuthorized from "components/common/UnAuthorized";

function withAuthorization(Component: FC, type: string, onlyType: string) {
  const AuthorizationCheck = () => {
    if (RoleUtils.isGuest(type))
      return <UnAuthorized title="로그인이 필요합니다." />;

    if (type === onlyType) return <Component />;

    return <UnAuthorized title="권한이 없습니다." />;
  };
  return AuthorizationCheck;
}

export default withAuthorization;
