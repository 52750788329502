import LaundryTagSearch from "components/common/LaundryTagSearch";
import Paging from "components/common/Paging";
import { AdminLaundryTagList } from "modules/admin/types";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { Palette } from "styles/palette";

interface AdminLaundryRfidCountModalProps {
  adminLaundryTagList: AdminLaundryTagList;
  onClose: () => void;
  searchPageMove: (
    page: number,
    query?: string | null,
    sort?: string | null
  ) => void;
}

const AdminLaundryRfidCountModal: React.FC<AdminLaundryRfidCountModalProps> = (
  props
) => {
  // const [currentPage, setCurrentPage] = useState<number>(1);

  const onPageMove = (page: number) => {
    // setCurrentPage(page);
    props.searchPageMove(page);
  };

  const onTagSearch = (query: string | null, sort: string | null) => {
    props.searchPageMove(1, query, sort);
  };

  return (
    <AdminLaundryRfidCountModalBlock>
      <div className="baseLayout">
        <div className="closeBtn" onClick={props.onClose}>
          X
        </div>
        <h3>등록 TAG 확인</h3>
        <div className="searchBox">
          <LaundryTagSearch onSearch={onTagSearch} size={10} />
        </div>

        <div className="tableWrapper">
          <div className="tHead">
            <div>
              <span>TagID</span>
              <span>품목명</span>
              <span>등록일</span>
              <span>누적 스캔 횟수</span>
            </div>
          </div>
          <div className="tBody">
            {props.adminLaundryTagList.list.map((item, index) => (
              <div key={index}>
                <span>{item.code}</span>
                <span>{item.name}</span>
                <span>
                  {moment(item.createdDate).format("YYYY-MM-DD HH:mm:ss")}
                </span>
                <span>{item.scanCount}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="pagingBox">
          <Paging
            listAction={onPageMove}
            listCount={props.adminLaundryTagList.totalPage ?? 1}
            currentPage={props.adminLaundryTagList.currentPage}
            totalCount={props.adminLaundryTagList.totalCount ?? 1}
            size={10}
          ></Paging>
        </div>
      </div>
    </AdminLaundryRfidCountModalBlock>
  );
};

export default AdminLaundryRfidCountModal;

const AdminLaundryRfidCountModalBlock = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  & > .baseLayout {
    width: 1000px;
    height: 600px;
    background-color: #ececec;
    border-radius: 15px;
    padding: 10px 25px;
    position: relative;
    & > .closeBtn {
      position: absolute;
      top: 25px;
      right: 25px;
      cursor: pointer;
    }
    & > h3 {
      color: ${Palette.primary};
    }
    & > div {
      margin-bottom: 10px;
    }
    & > .searchBox {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: flex-end;
    }
    & > .tableWrapper {
      width: 100%;
      height: 385px;
      background-color: ${Palette.primary};
      border-radius: 10px;
      overflow: hidden;
      & > .tHead,
      .tBody {
        width: 100%;
        margin: 0 auto;
        & > div {
          height: 35px;
          & > span {
            display: inline-block;
            text-align: center;
            line-height: 35px;
            border-bottom: 1px solid #ccc;
          }
          & > span:nth-child(1) {
            width: 350px;
          }
          & > span:nth-child(2) {
            width: 200px;
          }
          & > span:nth-child(3) {
            width: 200px;
          }
          & > span:nth-child(4) {
            width: 200px;
          }
        }
      }
      & > .tHead {
        & > div {
          & > span {
            color: #fff;
            border: 0;
          }
        }
      }
      & > .tBody {
        height: 365px;
        background-color: #fff;
        /* overflow: hidden;
        overflow-y: scroll; */
        & > div:hover {
          background-color: #ddd;
          cursor: default;
        }
      }
    }
    & > .pagingBox {
      width: 100%;
      margin: 0 auto;
    }
  }
`;
