import withAuthorization from "hoc/Authorization";
import { IRootState } from "modules";
import { getProfileAction } from "modules/user/user";
import FactoryPage from "pages/FactoryPage";
import LoginPage from "pages/LoginPage";
import MasterPage from "pages/MasterPage";
import StorePage from "pages/StorePage";
import MasterAdminPage from "pages/AdminPage";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router";
import RoleUtils from "utils/RoleUtils";

function App() {
  const dispatch = useDispatch();
  const type = useSelector((state: IRootState) => state.user.profile.type);

  useEffect(() => {
    if (RoleUtils.checkLogin()) dispatch(getProfileAction());
  }, [dispatch]);
  return (
    <>
      <Switch>
        <Route
          path="/admin"
          component={withAuthorization(
            MasterAdminPage,
            type,
            "ROLE_MASTER_ADMIN"
          )}
        />
        <Route
          path="/master"
          component={withAuthorization(MasterPage, type, "ROLE_MASTER")}
        />
        <Route
          path="/store"
          component={withAuthorization(StorePage, type, "ROLE_FRANCHISE")}
        />
        <Route
          path="/factory"
          component={withAuthorization(FactoryPage, type, "ROLE_FACTORY")}
        />
        <Route path="/" component={LoginPage} />
      </Switch>
    </>
  );
}

export default App;
