import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ThemeColor } from "styles/palette";
import ScrollUtils from "utils/ScrollUtils";
import { StatusDetailBlock } from "components/common/LaundaryStatusDetail";
import Button from "components/common/Button";
import { CenterAligned } from "components/common/layouts/CenterAligned";
import { Item, MasterBillingDetail } from "modules/master/types";
import PriceUtils from "utils/PriceUtils";
import DateFormatUtils from "utils/DateFormatUtils";
import NumberUtils from "utils/NumberUtils";

interface MasterPaymentStatusDetailProps {
  masterBillingDetail: MasterBillingDetail | null;
  onClose: () => void;
}

const MasterPaymentDetail: React.FC<MasterPaymentStatusDetailProps> = ({
  masterBillingDetail,
  onClose,
}) => {
  useEffect(() => {
    ScrollUtils.onPopupScrollStop(window.scrollY);
    return () => {
      const scrollY = document.body.style.top;
      ScrollUtils.closePopupScrollRun(scrollY);
    };
  }, []);

  const [paymentDetailItems, setPaymentDetailItems] = useState<Item[]>([]);

  useEffect(() => {
    setPaymentDetailItems(masterBillingDetail?.items ?? []);
  }, []);

  return (
    <MasterPaymentStatusDetailBlock>
      <div className="headers">
        <h3>정산현황 상세정보</h3>
        <Button theme={ThemeColor.primary} buttonSize="m" onClick={onClose}>
          뒤로가기
        </Button>
      </div>
      <div className="contentWrapper">
        <div className="detailInfo">
          <div className="field">
            <span>지점명</span>
            <p>{masterBillingDetail?.franchiseName ?? "-"}</p>
          </div>
          <div className="field">
            <span>세탁공장</span>
            <p>{masterBillingDetail?.factoryName ?? "-"}</p>
          </div>
          <div className="field">
            <span>세탁 신청일</span>
            <p>
              {DateFormatUtils.dateToFormat(
                masterBillingDetail?.applicationDate
              )}
            </p>
          </div>
          <div className="field">
            <span>납품일</span>
            <p>
              {DateFormatUtils.dateToFormat(masterBillingDetail?.completedDate)}
            </p>
          </div>
          <div className="field">
            <span>입고 수량(포대/카트)</span>
            <p>
              {NumberUtils.numberToComma(masterBillingDetail?.bagCount ?? 0)}
            </p>
          </div>
          <div className="field">
            <span>세탁 수량(갯수)</span>
            <p>
              {NumberUtils.numberToComma(masterBillingDetail?.totalAmount ?? 0)}
            </p>
          </div>
          <div className="field">
            <span>결제금액(원)</span>
            <p>{NumberUtils.numberToComma(masterBillingDetail?.price ?? 0)}</p>
          </div>
          {/* <div className="field">
            <span>정산상태</span>
            <p>
              {masterBillingDetail?.status
                ? PriceUtils.priceStatusToString(masterBillingDetail?.status)
                : "-"}
            </p>
          </div> */}

          <Button
            style={{ float: "right" }}
            theme={ThemeColor.primary}
            buttonSize="m"
            onClick={onClose}
          >
            확인
          </Button>
        </div>
        <div className="stockStatus">
          <div>
            <h3>세탁물 종류</h3>
            <div className="stockInField">
              <div>
                <div className="stockHeader">
                  <span>대분류</span>
                  <span>소분류</span>
                  <span>품명</span>
                  <span>사이즈</span>
                  <span>비고</span>
                  <span>세탁신청(개)</span>
                  <span>세탁완료(개)</span>
                  <span>재세탁(반품)</span>
                  <span>기타(미납,수선)</span>
                </div>
                <div className="stockList">
                  {masterBillingDetail?.items.map((category, idx) => (
                    <div className="field" key={idx}>
                      <span>{category.asset.details.largeCategory}</span>
                      <span>{category.asset.details.subCategory}</span>
                      <span>{category.asset.details.name}</span>
                      <span>{category.asset.details.size}</span>
                      <span>{category.asset.details.content}</span>
                      <span>
                        {category.beforeAmount
                          ? NumberUtils.numberToComma(
                              category.beforeAmount ?? 0
                            )
                          : "-"}
                      </span>
                      <span>
                        {category.amount
                          ? NumberUtils.numberToComma(category.amount ?? 0)
                          : "-"}
                      </span>
                      <span>
                        {category.relaundering
                          ? NumberUtils.numberToComma(category.relaundering)
                          : "-"}
                      </span>
                      <span>-</span>
                    </div>
                  ))}
                  <div className="field">
                    <span>포대/카트</span>
                    <span>-</span>
                    <span>-</span>
                    <span>-</span>
                    <span>-</span>
                    <span>
                      {NumberUtils.numberToComma(
                        masterBillingDetail?.bagCount ?? 0
                      )}
                    </span>
                    <span>
                      {NumberUtils.numberToComma(
                        masterBillingDetail?.outBagCount ?? 0
                      )}
                    </span>
                    <span>-</span>
                    <span>-</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MasterPaymentStatusDetailBlock>
  );
};

export default MasterPaymentDetail;

const MasterPaymentStatusDetailBlock = styled(StatusDetailBlock)``;
