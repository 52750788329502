import Button from "components/common/Button";
import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { Palette, ThemeColor } from "styles/palette";
import DateFormatUtils from "utils/DateFormatUtils";
import NumberUtils from "utils/NumberUtils";

import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";
import dummy from "./dummy2.json";
import { FactoryData, LaundryDataListDetail } from "modules/factory/types";
import factory from "modules/factory/factory";
import { ProfileDetail } from "modules/user/types";

interface LaundryTableProps {
  startDate: string;
  endDate: string;
  dataList: dataList[];
  chartType?: string;
  franchiseName?: string;
  factory?: FactoryData | null;
  factoryProfileDetail?: ProfileDetail | null;
  contractType: string;
  contractPrice: number;
}

interface dataList {
  isOrder: number;
  title: string;
  list: laundryList[];
  price: number;
}

interface laundryList {
  name: string;
  date: string;
  count: number;
}

const LaundryTableYear: React.FC<LaundryTableProps> = (props) => {
  const [startDate, setStartDate] = useState<moment.Moment>(() => moment());
  const [endDate, setEndDate] = useState<moment.Moment>(() => moment());
  const [dateArr, setDateArr] = useState<string[]>([]);
  const [sumCount, setSumCount] = useState<number[]>([]);
  const [sumPrice, setSumPrice] = useState<number[]>([]);
  const [dateSum, setDateSum] = useState<number[]>([]);
  const [price, setPrice] = useState<number>(0);
  const [count, setCount] = useState<number>(0);

  console.log(sumPrice);

  useEffect(() => {
    setDateSum([]);
    setPrice(0);
    setCount(0);
    // dateCreate();
    if (props.dataList.length > 0) {
      let dataList = props.dataList;
      let sumList = [];
      let priceSumList = [];
      let countList = [];
      let price = 0;
      let count = 0;
      let dateArray: string[] = [];
      for (let i = 0; i < dataList.length; i++) {
        let sum = 0;
        let name = dataList[i].list[0].name;
        for (let n = 0; n < 12; n++) {
          let data = {
            name: name,
            count: 0,
            date: "-",
          };
          if (!dataList[i].list[n]) {
            dataList[i].list.push(data);
            continue;
          }
          sum = sum + dataList[i].list[n].count;
          if (dataList[i].list[n].date !== "-") {
            dataList[i].list[n].date = moment(dataList[i].list[n].date).format(
              "YYYY-MM"
            );
          }
        }

        sumList.push(sum);
        priceSumList.push(dataList[i].price * sum);
        price = price + dataList[i].price * sum;
        count = count + sum;
      }

      for (let n = 0; n < dataList[0].list.length; n++) {
        let dayCount = 0;
        for (let i = 0; i < dataList.length; i++) {
          dayCount = dayCount + dataList[i].list[n].count;
        }
        countList.push(dayCount);
      }

      for (let t = 0; t < 12; t++) {
        dateArray.push(String(dataList[0].list[t].date));
      }

      setDateSum(countList);
      setCount(count);
      setPrice(price);
      setSumCount(sumList);
      setSumPrice(priceSumList);
      setDateArr(dateArray);
    }
  }, [props.dataList]);

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      fontWeight: 700,
    },
  });
  const classes = useStyles();

  //   const dateCreate = (sd?: string, ed?: string) => {
  //     if (!sd) sd = "2021-08-01";
  //     if (!ed) ed = "2021-08-30";
  //     var regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
  //     if (!(regex.test(sd) && regex.test(ed))) return "Not Date Format";
  //     var result = [];
  //     var curDate = new Date(sd);
  //     while (curDate <= new Date(ed)) {
  //       result.push(curDate.toISOString().split("T")[0]);
  //       curDate.setDate(curDate.getDate() + 1);
  //     }

  //     setDateArr(result);
  //   };

  const dateCreate = (year?: any) => {
    if (!year) year = moment(new Date()).format("YYYY");

    let month = [
      year + "-01",
      year + "-02",
      year + "-03",
      year + "-04",
      year + "-05",
      year + "-06",
      year + "-07",
      year + "-08",
      year + "-09",
      year + "-10",
      year + "-11",
      year + "-12",
    ];
    // console.log(month);
    // setDateArr(month);
  };

  return (
    <Wrapper>
      <div className="headers">
        <h3 onClick={() => dateCreate()}>세탁집계표</h3>
      </div>
      <div>
        <h4>{props.franchiseName} 貴中</h4>
        <h5>
          세탁기간 : {props.startDate} ~ {props.endDate}
        </h5>
      </div>
      <LaundryTableBlock>
        <table>
          <thead>
            {dateSum.length > 0 && (
              <>
                <tr className={"title"}>
                  <th>No.</th>
                  <th>품목</th>
                  {dateArr.map((date, index) => (
                    <th key={index}>{date}</th>
                  ))}
                  <th>총 수량</th>
                  <th>단가</th>
                  <th>총 발생액</th>
                </tr>
                <tr>
                  <th colSpan={2}>합계</th>
                  {dateSum.map((val, index) => (
                    <th key={index}>
                      {val ? NumberUtils.numberToComma(val) : "-"}
                    </th>
                  ))}
                  <th className={"price"}>
                    {NumberUtils.numberToComma(count)}
                  </th>
                  <th></th>
                  <th className={"price"}>
                    {props.contractType === "UNIT_PRICE"
                      ? NumberUtils.numberToComma(price)
                      : NumberUtils.numberToComma(props.contractPrice)}
                  </th>
                </tr>
              </>
            )}
          </thead>
          <tbody>
            {props.dataList.map((val, index) => (
              <tr key={index}>
                <td className="frontPlace">{index + 1}</td>
                <td className={"title frontPlace"}>{val.title}</td>
                {val.list.map((item, idx) => (
                  <td key={idx}>
                    {/* 1,000,000,000 */}
                    {item.count ? NumberUtils.numberToComma(item.count) : "-"}
                  </td>
                ))}
                <td className={"price"}>
                  {NumberUtils.numberToComma(sumCount[index])}
                </td>
                <td className={"price"}>
                  {props.contractType === "UNIT_PRICE"
                    ? NumberUtils.numberToComma(val.price)
                    : ""}
                  {/* {NumberUtils.numberToComma(val.price)} */}
                </td>
                <td className={"price lastPlace"}>
                  {sumPrice[index] && props.contractType === "UNIT_PRICE"
                    ? NumberUtils.numberToComma(sumPrice[index])
                    : ""}
                  {/* {NumberUtils.numberToComma(sumPrice[index])} */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={"footer"}>
          공급자 {props.factoryProfileDetail?.companyName} | (대표자){" "}
          {props.factoryProfileDetail?.representative} | (등록번호)
          {props.factoryProfileDetail?.registrationNumber} | (주소){" "}
          {props.factoryProfileDetail?.companyAddress.street} | (TEL){" "}
          {props.factoryProfileDetail?.companyPhone} | (FAX)
          {props.factoryProfileDetail?.fax}
        </div>
      </LaundryTableBlock>
    </Wrapper>
  );
};

export default LaundryTableYear;

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  max-height: 1200px;

  & > .headers {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    align-items: center;
  }
  & > div:nth-child(2) {
    padding-left: 10px;
  }
`;

export const LaundryTableBlock = styled.div`
  & > table {
    margin: 0 auto;
    width: 1414px;
    height: 1000px;
    border-collapse: collapse;
    & > thead {
      & > tr {
        background-color: #fce4d6;
        height: 23px;
        & > th {
          border: 1px solid #000;
          font-size: 12px;
          min-width: 40px;
        }
        &.title {
          background-color: #ddebf7;
        }
      }
    }
    & > tbody {
      & > tr {
        height: 23px;
        & > td {
          border: 1px solid #000;
          text-align: center;
          font-size: 12px;
          &.title {
            text-align: left;
          }
          &.price {
            text-align: right;
          }
        }
        & > .frontPlace {
          background-color: #ededed;
        }
        & > .lastPlace {
          background-color: #fff2cc;
        }
      }
    }
  }
  & > .footer {
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
    line-height: 40px;
  }
`;
