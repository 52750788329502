import AppLayout from "components/layouts/AppLayout";
import AdminBrandContainer from "containers/admin/AdminBrandContainer";
import AdminDashBoardContainer from "containers/admin/AdminDashBoard";
import AdminFactoryContainer from "containers/admin/AdminFactoryContainer";
import AdminLaundryContainer from "containers/admin/AdminLaundryContainer";
import NoticeContainer from "containers/common/NoticeContainer";
import AdminPaymentContainer from "containers/admin/AdminPaymentContainer";
import AdminProfileContainer from "containers/admin/AdminProfileContainer";
import AdminStoreContainer from "containers/admin/AdminStoreContainer";
import React from "react";
import { Route, Switch } from "react-router";

import Prepared from "components/common/Prepared";
import BoardContainer from "containers/common/BoardContainer";
import AdminItemizedAccountContainer from "containers/admin/AdminItemizedAccountContainer";
import CheckLocationContainer from "containers/common/CheckLocationContainer";
import AdminPayManagementContainer from "containers/admin/AdminPayManagementContainer";
import AdminLaundryRfidCountContainer from "containers/admin/AdminLaundryRfidCountContainer";
import AdminAccessHistoryContainer from "containers/admin/AdminAccessHistoryContainer";
import AdminCalculateManagementContainer from "containers/admin/AdminCalculateManagement";
import AdminCalculateDetailContainer from "containers/admin/AdminCalculateDetailContainer";

interface AdminPageProps {}

const AdminPage: React.FC<AdminPageProps> = () => {
  return (
    <AppLayout>
      <Switch>
        <Route path="/admin/profile" component={AdminProfileContainer} />
        <Route exact path="/admin/itemizedAccount/:id" component={AdminItemizedAccountContainer} />

        <Route exact path="/admin/itemizedAccount" component={AdminItemizedAccountContainer} />

        {/* <Route exact path="/admin/notice/detail" component={NoticeContainer} /> */}
        <Route exact path="/admin/notice/modify/:id" component={NoticeContainer} />
        <Route exact path="/admin/notice/add" component={NoticeContainer} />
        <Route exact path="/admin/notice/:id" component={NoticeContainer} />
        <Route exact path="/admin/notice" component={NoticeContainer} />

        <Route exact path="/admin/board/:id" component={BoardContainer} />

        {/* <Route exact path="/admin/board/detail" component={BoardContainer} /> */}
        <Route exact path="/admin/board/modify/:id" component={BoardContainer} />
        <Route exact path="/admin/board/add" component={BoardContainer} />
        <Route exact path="/admin/board" component={BoardContainer} />

        <Route path="/admin/factory" component={AdminFactoryContainer} />

        <Route exact path="/admin/store/item/:id" component={AdminLaundryRfidCountContainer} />
        <Route path="/admin/store/:id" component={AdminStoreContainer} />
        <Route path="/admin/store/detail" component={AdminStoreContainer} />
        <Route path="/admin/store" component={AdminStoreContainer} />

        <Route path="/admin/brand/add" component={AdminBrandContainer} />
        <Route path="/admin/brand/detail" component={AdminBrandContainer} />
        <Route path="/admin/brand/:id" component={AdminBrandContainer} />
        <Route path="/admin/brand" component={AdminBrandContainer} />

        <Route exact path="/admin/payment/aggregation" component={AdminPaymentContainer} />
        <Route exact path="/admin/paymanagement/complete" component={AdminPayManagementContainer} />
        <Route exact path="/admin/paymanagement/" component={AdminPayManagementContainer} />
        <Route exact path="/admin/payment/detail" component={AdminPaymentContainer} />
        <Route exact path="/admin/payment" component={AdminPaymentContainer} />

        <Route exact path="/admin/calculate/sales/:id" component={AdminCalculateDetailContainer} />
        <Route
          exact
          path="/admin/calculate/purchase/:id"
          component={AdminCalculateDetailContainer}
        />
        <Route path="/admin/calculate" component={AdminCalculateManagementContainer} />

        <Route path="/admin/laundry/:id" component={AdminLaundryContainer} />
        <Route path="/admin/laundry" component={AdminLaundryContainer} />
        <Route path="/admin/dashboard" component={AdminDashBoardContainer} />
        <Route path="/admin/checkLocation" component={CheckLocationContainer} />
        <Route path="/admin/accessHistory" component={AdminAccessHistoryContainer} />

        <Route component={Prepared} />
      </Switch>
    </AppLayout>
  );
};

export default AdminPage;
