import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Palette, ThemeColor } from "styles/palette";

import pen from "assets/icons/pen.png";
import pen_sel from "assets/icons/pen_sel.png";
import store from "assets/icons/store.png";
import store_sel from "assets/icons/store_sel.png";
import product from "assets/icons/product.png";
import product_sel from "assets/icons/product_sel.png";
import calendar from "assets/icons/calendar.png";
import calendar_sel from "assets/icons/calendar_sel.png";
import successimg from "assets/icons/success.png";
import refreshimg from "assets/icons/refresh.png";

import SockJS from "sockjs-client";

import Stomp from "stompjs";

import DateApp from "components/common/datePickerTest";

import { ILoginResponse } from "modules/user/types";
import TypeUtils from "utils/TypeUtils";
import {
  FactoryFranchiseLaundryDate,
  FactoryFranchiseProgressList,
  FactoryGetTagData,
  FactoryGetTagResponseData,
  FactoryMatchingFranchiseList,
  FactoryTableDirectData,
  FactoryTableReadingData,
  FactoryTableRequest,
} from "modules/factory/types";
import Button from "components/common/Button";
import NumberUtils from "utils/NumberUtils";
import moment from "moment";
import DateFormatUtils from "utils/DateFormatUtils";

interface FactoryTableReaderProps {
  layoutHeight: number;
  user: ILoginResponse;
  factoryMatchingFranchiseList: FactoryMatchingFranchiseList | null;
  franchiseProgressList: FactoryFranchiseProgressList[] | null;
  getProgressList: (id: number) => void;
  factoryMacAddress: string | null;
  postFactoryMacAddress: (macAdd: string) => void;
  postFactoryTagLaundryComplete: (data: FactoryTableReadingData) => void;
  postFactoryLaundrayTagRequest: (data: FactoryTableRequest) => void;
  getTagData: (data: FactoryGetTagData) => void;
  responseTagData: FactoryGetTagResponseData | null;
  postReLaundryTagRequest: (data: FactoryTableReadingData) => void;
  postDirectAmountComplete: (data: FactoryTableDirectData) => void;
  postDirectReLaundry: (data: FactoryTableDirectData) => void;
  getFranchiseLaundryDate: (id: number) => void;
  franchiseLaundryDate: FactoryFranchiseLaundryDate[] | [];
  franchiseLaundryCompleteDate: FactoryFranchiseLaundryDate[] | [];
  activationTableReaderServer: () => void;
}

type FactoryTableBlockProps = {
  layoutHeight: number;
};

type ScanTagListProps = {
  id: number;
  code: string;
  name: string;
  franchiseId: number;
  franchiseName: string;
  createDate: string;
  type?: number;
  assetId: number;
  largeCategory: string;
  subCategory: string;
  amount: number;
};

type AllScanTagListProps = {
  franchiseName: string;
  largeCategory: string;
  subCategory: string;
  name: string;
  amount: number;
};

type tagCode = {
  code: string;
};

const FactoryTableReader: React.FC<FactoryTableReaderProps> = (props) => {
  const [allScanTagList, setAllScanTagList] = useState<tagCode[]>([]);
  const [scanTagList, setScanTagList] = useState<ScanTagListProps[]>([]);
  const [scanTagData, setScanTagData] = useState<ScanTagListProps>();
  const [responseCheck, setResponseCheck] = useState<tagCode[]>([]);

  // console.log("스캔태그리스트", scanTagList);

  // console.log("올스캔", allScanTagList);

  const [postScanTagList, setPostScanTagList] = useState<tagCode[]>([]);

  // console.log("전송데이터", postScanTagList);

  // 선택 정보
  const [inputSetting, setInputSetting] = useState<string>(""); // 입력설정
  const [statusSelect, setStatusSelect] = useState<string>(""); // 상태선택
  const [storeId, setStoreId] = useState({
    franchiseId: "",
    franchiseName: "",
  }); // 지점선택
  const [laundryName, setLaundryName] = useState({
    id: "",
    subCategory: "",
  }); // 품명선택
  const [applicationDate, setApplicationDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  ); // 세탁신청일
  const [deliveryDate, setDeliveryDate] = useState<string>(
    moment(new Date()).add(1, "days").format("YYYY-MM-DD")
  ); // 세탁납품일

  const [dataPicStatus, setDataPicStatus] = useState<string>(""); // 선택한 스테이터스 모달창
  const [statusAllCheck, setStatusAllCheck] = useState<boolean>(false);
  const [movementToggle, setMovementToggle] = useState<string>("stop"); // 시작, 정지/초기화

  const [startDate, setStartDate] = useState<string>("");

  // useEffect(() => {
  //   console.log(props.franchiseLaundryDate);
  //   if (props.franchiseLaundryDate && props.franchiseLaundryDate !== null)
  //     setStartDate(props.franchiseLaundryDate[0].date);
  // }, [props.franchiseLaundryDate]);

  // console.log("입력설정", inputSetting);
  // console.log("상태선택", statusSelect);
  // console.log("지점선택", storeId);
  // console.log("품명선택", laundryName);
  // console.log("세탁신청일", applicationDate);
  // console.log("세탁납품일", deliveryDate);

  // console.log("오늘", moment(new Date()).format("YYYY-MM-DD"));

  // console.log("상태", statusAllCheck);

  // 단말기로 스캔한 최종 수량 갯수
  const [readCount, setReadCount] = useState<number>(0);
  const [readerNumer, setReaderNumber] = useState<string>(""); // 리더기 넘버
  const [antNumber, setAntNumber] = useState<string>("");

  // 입력가능, 입력불가능 값
  const [inputPossibilityCount, setInputPossibilityCount] = useState<number>(0);
  const [inputCompletion, setInputCompletion] = useState<number>(0);

  // 실제수량, 기준수량, 수기수량
  const [actualQuantity, setActualQuantity] = useState<number>(0); // 실제 수량은 scanTagList length로?
  const [standardQuantity, setStandardQuantity] = useState<number>(0); // 기준수량
  const [directQuantity, setDirectQuantity] = useState<number>(0);

  const [successCheck, setSuccessCheck] = useState<string>("");

  useEffect(() => {
    setReaderNumber(props.factoryMacAddress ?? "");
  }, [props]);

  // useEffect(() => {
  //   if (props.franchiseLaundryDate && statusSelect !== "APPLICATION") {
  //     setApplicationDate(props.franchiseLaundryDate[0].date);
  //   }
  // }, [props.franchiseLaundryDate]);

  //소켓 연결
  const token = localStorage.getItem("CURRENT_USER");
  const headers = {
    Authorization: token,
    "X-AUTH-TOKEN": token,
  }; //X-AUTH-TOKEN은 웹소켓 통신에 사용되는 토큰, 아마도 고정으로 있어야 하는 값?

  const onSubscribeClick = (status: string) => {
    let sockJS = new SockJS(process.env.REACT_APP_BASE_URL + "ws-stomp");
    let stompClient = Stomp.over(sockJS);
    // console.log("스톰프", stompClient);
    // console.log("속", sockJS);
    if (status === "start") {
      stompClient.connect(headers, (e) => {
        stompClient.subscribe(
          `/sub/tag/${readerNumer}${antNumber}`,
          async (data) => {
            const newData = await JSON.parse(data.body);

            let modeNumber = 0;
            if (statusSelect === "COMPLETE") modeNumber = 1;
            if (statusSelect === "RELAUNDRY") modeNumber = 2;

            let scanData2 = {
              tagCode: newData.code,
              mode: String(modeNumber),
            };

            if (!responseCheck.find((item) => item.code === newData.code)) {
              setResponseCheck(newData.code);
              await props.getTagData(scanData2);
            }
          },
          headers
        );
      });
    }
    if (status === "stop") {
      if (stompClient.connected) {
        stompClient.unsubscribe(`/sub/tag/${readerNumer}${antNumber}`);
        stompClient.disconnect(() => {}, headers);
      }
    }
  };

  // 소켓으로 들어온 값 계산 및 정리

  // console.log("리스폰 데이터", props.responseTagData);

  // 구별조건
  useEffect(() => {
    if (!props.responseTagData) return;
    let resData = props.responseTagData;
    // console.log(resData);
    if (movementToggle === "start") {
      // 같은 지점
      if (statusSelect !== "INQUIRY") {
        // 태그조회가 아닐경우
        if (Number(storeId.franchiseId) === resData.franchiseId) {
          if (scanTagList.length <= 0) {
            // 리스트가 0일때
            const data = {
              id: resData.id,
              code: resData.code,
              name: resData.name,
              franchiseId: resData.franchiseId,
              franchiseName: resData.franchiseName,
              createDate: resData.createdDate,
              assetId: resData.assetId,
              largeCategory: resData.largeCategory,
              subCategory: resData.subCategory,
              amount: 1,
            };
            if (resData.type === 0) {
              setScanTagList((scanTagList) => [...scanTagList, data]);
              setPostScanTagList((postScanTagList) => [...postScanTagList, { code: resData.code }]);
              setInputPossibilityCount(inputPossibilityCount + 1); // 입력가능
            }
            // setActualQuantity(actualQuantity + 1);

            if (resData.type === 1) {
              if (!allScanTagList.find((item) => item.code === resData.code)) {
                setInputCompletion(inputCompletion + 1);
              }
            } // 입력완료
          } else {
            // 리스트가 0이 아닐때
            if (!scanTagList.find((item) => item.id === resData.id)) {
              // 동일한 아이디가 아닐때 = 동일한 아이디는 묵살
              if (!postScanTagList.find((item) => item.code === resData.code)) {
                if (
                  !scanTagList.find((item) => item.assetId === resData.assetId) // 동일한 상품이 아닐때
                ) {
                  const data = {
                    id: resData.id,
                    code: resData.code,
                    name: resData.name,
                    franchiseId: resData.franchiseId,
                    franchiseName: resData.franchiseName,
                    createDate: resData.createdDate,
                    assetId: resData.assetId,
                    largeCategory: resData.largeCategory,
                    subCategory: resData.subCategory,
                    amount: 1,
                  };
                  if (resData.type === 0) setScanTagList((scanTagList) => [...scanTagList, data]);
                } else {
                  // 동일한 상품일때
                  if (resData.type === 0) {
                    if (!allScanTagList.find((item) => item.code === resData.code)) {
                      setScanTagList(
                        scanTagList.map((item) =>
                          item.assetId === resData.assetId
                            ? { ...item, amount: item.amount + 1 }
                            : item
                        )
                      );
                    }
                  }
                }
                // setActualQuantity(actualQuantity + 1);

                if (!allScanTagList.find((item) => item.code === resData.code)) {
                  if (resData.type === 0) {
                    setInputPossibilityCount(inputPossibilityCount + 1); // 입력가능
                    setPostScanTagList((postScanTagList) => [
                      ...postScanTagList,
                      { code: resData.code },
                    ]);
                  }

                  if (resData.type === 1) {
                    setInputCompletion(inputCompletion + 1);
                  } // 입력완료
                }
              }
            }
          }
          if (!allScanTagList.find((item) => item.code === resData.code)) {
            setAllScanTagList((allScanTagList) => [...allScanTagList, { code: resData.code }]);
            setActualQuantity(actualQuantity + 1);
          }
        }
      }
      if (statusSelect === "INQUIRY") {
        // 태그조회일경우
        if (Number(storeId.franchiseId) !== 0) {
          if (Number(storeId.franchiseId) === resData.franchiseId) {
            if (scanTagList.length <= 0) {
              // 리스트가 0일때
              const data = {
                id: resData.id,
                code: resData.code,
                name: resData.name,
                franchiseId: resData.franchiseId,
                franchiseName: resData.franchiseName,
                createDate: resData.createdDate,
                assetId: resData.assetId,
                largeCategory: resData.largeCategory,
                subCategory: resData.subCategory,
                amount: 1,
              };
              // if (resData.type === 0) {
              setScanTagList((scanTagList) => [...scanTagList, data]);
              setPostScanTagList((postScanTagList) => [...postScanTagList, { code: resData.code }]);

              // }
              // setActualQuantity(actualQuantity + 1);
              if (resData.type === 0) setInputPossibilityCount(inputPossibilityCount + 1); // 입력가능

              if (resData.type === 1) {
                if (!allScanTagList.find((item) => item.code === resData.code)) {
                  setInputCompletion(inputCompletion + 1);
                }
              } // 입력완료
            } else {
              // 리스트가 0이 아닐때
              if (!scanTagList.find((item) => item.id === resData.id)) {
                // 동일한 아이디가 아닐때 = 동일한 아이디는 묵살
                if (!postScanTagList.find((item) => item.code === resData.code)) {
                  if (
                    !scanTagList.find((item) => item.assetId === resData.assetId) // 동일한 상품이 아닐때
                  ) {
                    const data = {
                      id: resData.id,
                      code: resData.code,
                      name: resData.name,
                      franchiseId: resData.franchiseId,
                      franchiseName: resData.franchiseName,
                      createDate: resData.createdDate,
                      assetId: resData.assetId,
                      largeCategory: resData.largeCategory,
                      subCategory: resData.subCategory,
                      amount: 1,
                    };
                    // if (resData.type === 0)
                    setScanTagList((scanTagList) => [...scanTagList, data]);
                  } else {
                    // 동일한 상품일때
                    // if (resData.type === 0) {
                    if (!allScanTagList.find((item) => item.code === resData.code)) {
                      setScanTagList(
                        scanTagList.map((item) =>
                          item.assetId === resData.assetId
                            ? { ...item, amount: item.amount + 1 }
                            : item
                        )
                      );
                    }
                    // }
                  }
                  // setActualQuantity(actualQuantity + 1);

                  if (!allScanTagList.find((item) => item.code === resData.code)) {
                    if (resData.type === 0) {
                      setInputPossibilityCount(inputPossibilityCount + 1); // 입력가능
                      setPostScanTagList((postScanTagList) => [
                        ...postScanTagList,
                        { code: resData.code },
                      ]);
                    }

                    if (resData.type === 1) {
                      setInputCompletion(inputCompletion + 1);
                    } // 입력완료
                  }
                }
              }
            }
            if (!allScanTagList.find((item) => item.code === resData.code)) {
              setAllScanTagList((allScanTagList) => [...allScanTagList, { code: resData.code }]);
              setActualQuantity(actualQuantity + 1);
            }
          }
        } else {
          if (scanTagList.length <= 0) {
            // 리스트가 0일때
            const data = {
              id: resData.id,
              code: resData.code,
              name: resData.name,
              franchiseId: resData.franchiseId,
              franchiseName: resData.franchiseName,
              createDate: resData.createdDate,
              assetId: resData.assetId,
              largeCategory: resData.largeCategory,
              subCategory: resData.subCategory,
              amount: 1,
            };

            setScanTagList((scanTagList) => [...scanTagList, data]);
            setPostScanTagList((postScanTagList) => [...postScanTagList, { code: resData.code }]);
            if (resData.type === 0) setInputPossibilityCount(inputPossibilityCount + 1); // 입력가능

            // setActualQuantity(actualQuantity + 1);

            if (resData.type === 1) {
              if (!allScanTagList.find((item) => item.code === resData.code)) {
                setInputCompletion(inputCompletion + 1);
              }
            } // 입력완료
          } else {
            // 리스트가 0이 아닐때
            if (!scanTagList.find((item) => item.id === resData.id)) {
              // 동일한 아이디가 아닐때 = 동일한 아이디는 묵살
              if (!postScanTagList.find((item) => item.code === resData.code)) {
                if (
                  !scanTagList.find((item) => item.assetId === resData.assetId) // 동일한 상품이 아닐때
                ) {
                  const data = {
                    id: resData.id,
                    code: resData.code,
                    name: resData.name,
                    franchiseId: resData.franchiseId,
                    franchiseName: resData.franchiseName,
                    createDate: resData.createdDate,
                    assetId: resData.assetId,
                    largeCategory: resData.largeCategory,
                    subCategory: resData.subCategory,
                    amount: 1,
                  };
                  // if (resData.type === 0)
                  setScanTagList((scanTagList) => [...scanTagList, data]);
                } else {
                  // 동일한 상품일때
                  // if (resData.type === 0) {
                  if (!allScanTagList.find((item) => item.code === resData.code)) {
                    setScanTagList(
                      scanTagList.map((item) =>
                        item.assetId === resData.assetId
                          ? { ...item, amount: item.amount + 1 }
                          : item
                      )
                    );
                  }
                  // }
                }
                // setActualQuantity(actualQuantity + 1);

                if (!allScanTagList.find((item) => item.code === resData.code)) {
                  if (resData.type === 0) {
                    setInputPossibilityCount(inputPossibilityCount + 1); // 입력가능
                    setPostScanTagList((postScanTagList) => [
                      ...postScanTagList,
                      { code: resData.code },
                    ]);
                  }

                  if (resData.type === 1) {
                    setInputCompletion(inputCompletion + 1);
                  } // 입력완료
                }
              }
            }
          }
          if (!allScanTagList.find((item) => item.code === resData.code)) {
            setAllScanTagList((allScanTagList) => [...allScanTagList, { code: resData.code }]);
            setActualQuantity(actualQuantity + 1);
          }
        }
      }
    }
  }, [props.responseTagData]);

  useEffect(() => {
    postScanTagList.map((item) => item.code);
  }, []);

  // 스테이터스 체크 여부
  useEffect(() => {
    if (inputSetting === "RFID") {
      if (statusSelect && storeId.franchiseId) {
        setStatusAllCheck(true);
      } else {
        setStatusAllCheck(false);
      }
    }
    if (inputSetting === "WRITE") {
      if (statusSelect && storeId.franchiseId && laundryName.id) {
        setStatusAllCheck(true);
      } else {
        setStatusAllCheck(false);
      }
    }
  }, [inputSetting, statusSelect, storeId, laundryName]);

  const onMovementToggle = async (status: string) => {
    if (status === "start") {
      if (inputSetting === "RFID") {
        if (statusAllCheck) {
          if (!readerNumer) return alert("리더기 번호를 입력해주세요");
          if (!antNumber) return alert("안테나번호를 입력해주세요");
        }
        if (movementToggle === "start" && status === "start") {
          return alert("이미 실행중입니다.");
        }
        if (statusAllCheck && movementToggle === "stop") {
          props.activationTableReaderServer();
          onSubscribeClick("start");
          return setMovementToggle("start");
        }
      }
    }
    if (status === "stop") {
      onSubscribeClick("stop");
      setMovementToggle("stop");
      onAllReset();
    }
  };

  const onRefresh = () => {
    setScanTagList([]);
    setActualQuantity(0);
    setInputPossibilityCount(0);
    setInputCompletion(0);
    setReadCount(0);
    setPostScanTagList([]);
    setAllScanTagList([]);
    setResponseCheck([]);
    if (inputSetting === "RFID") setDirectQuantity(0);
  };

  const onAllReset = () => {
    onRefresh();
    setInputSetting("");
    setStatusSelect("");
    setStandardQuantity(0);
    setStoreId({
      franchiseId: "",
      franchiseName: "",
    });
    setLaundryName({
      id: "",
      subCategory: "",
    });
    setApplicationDate(moment(new Date()).format("YYYY-MM-DD"));
    setDeliveryDate(moment(new Date()).add(1, "days").format("YYYY-MM-DD"));
    setStatusAllCheck(false);
  };

  const quantityBtn = (item: number) => {
    if (inputSetting === "RFID")
      setStandardQuantity(standardQuantity + item <= 0 ? 0 : standardQuantity + item);

    if (inputSetting === "WRITE")
      setDirectQuantity(directQuantity + item <= 0 ? 0 : directQuantity + item);
  };

  // 날짜 데이터 저장
  const startDateSelector = (date: string) => {
    setApplicationDate(DateFormatUtils.dateToFormat2(date));
  };

  const endDateSelector = (date: string) => {
    setDeliveryDate(DateFormatUtils.dateToFormat2(date));
  };

  // 입력설정 status
  const inputSetStatus = (type: string) => {
    if (inputSetting === type) return;
    setInputSetting(type);
    setStatusSelect("");
    setStoreId({
      franchiseId: "",
      franchiseName: "",
    });
    setLaundryName({
      id: "",
      subCategory: "",
    });
    setApplicationDate(moment(new Date()).format("YYYY-MM-DD"));
    setDeliveryDate(moment(new Date()).add(1, "days").format("YYYY-MM-DD"));
    setStatusAllCheck(false);
  };

  const statusSetStatus = (type: string) => {
    if (statusSelect === type) return;
    setStatusSelect(type);
    setStoreId({
      franchiseId: "",
      franchiseName: "",
    });
    setLaundryName({
      id: "",
      subCategory: "",
    });
    setApplicationDate(moment(new Date()).format("YYYY-MM-DD"));
    setDeliveryDate(moment(new Date()).add(1, "days").format("YYYY-MM-DD"));
    setStatusAllCheck(false);
  };

  const storeSetStatus = (type: { franchiseId: string; franchiseName: string }) => {
    if (storeId === type) return;
    setStoreId(type);
    setLaundryName({
      id: "",
      subCategory: "",
    });
    setApplicationDate(moment(new Date()).format("YYYY-MM-DD"));
    setDeliveryDate(moment(new Date()).add(1, "days").format("YYYY-MM-DD"));
    setStatusAllCheck(false);
  };

  const dataPic = (type: string) => {
    if (type === dataPicStatus) return setDataPicStatus("");
    setDataPicStatus(type);
  };

  // 리더기 넘버 MacAddress 저장/변경
  const postMacAddress = () => {
    if (readerNumer) props.postFactoryMacAddress(readerNumer);
  };

  // 데이터 전송
  const onRequestSender = () => {
    if (statusSelect === "INQUIRY") return;
    if (!statusAllCheck || !inputSetting) return;
    if (inputSetting === "RFID") {
      if (standardQuantity !== 0 && actualQuantity !== standardQuantity) return;
    }
    if (inputSetting === "WRITE") {
      if (directQuantity <= 0) {
        return;
      }
    }
    if (statusSelect === "COMPLETE" || statusSelect === "RELAUNDRY") {
      if (!props.franchiseLaundryDate || props.franchiseLaundryDate?.length <= 0)
        return alert("해당신청일이 없습니다.");
    }

    // 스캔 세탁완료, 재세탁일 경우 상용데이터
    let data: FactoryTableReadingData = {
      endDate: deliveryDate,
      frId: Number(storeId.franchiseId),
      startDate: applicationDate,
      tagList: postScanTagList,
    };

    // 스캔 신청시 사용 데이터
    let requestData: FactoryTableRequest = {
      frId: Number(storeId.franchiseId),
      tagList: postScanTagList,
    };

    let directData: FactoryTableDirectData = {
      endDate: deliveryDate,
      frId: Number(storeId.franchiseId),
      startDate: applicationDate,
      assetId: Number(laundryName.id),
      amount: directQuantity,
    };

    if (inputSetting === "RFID") {
      if (allScanTagList.length > 0) {
        if (statusSelect === "APPLICATION") {
          props.postFactoryLaundrayTagRequest(requestData);
        }

        if (statusSelect === "COMPLETE") {
          props.postFactoryTagLaundryComplete(data);
        }
        if (statusSelect === "RELAUNDRY") {
          props.postReLaundryTagRequest(data);
        }
      }
    }
    if (inputSetting === "WRITE") {
      if (statusSelect === "COMPLETE") {
        props.postDirectAmountComplete(directData);
      }
      if (statusSelect === "RELAUNDRY") props.postDirectReLaundry(directData);
    }

    // onRefresh();
    setSuccessCheck("success");
    // alert("전송 성공");
    setTimeout(() => {
      setSuccessCheck("");
      onRefresh();
    }, 1200);
  };

  return (
    <FactoryTableReaderBlock layoutHeight={props.layoutHeight}>
      <div className="headers">
        <h3>테이블리더기</h3>
      </div>
      <div className="contentWrapper">
        <div className="macAddressInput">
          <div className="inputBox">
            <span>리더기 정보:</span>
            <input
              value={readerNumer}
              onChange={(e) => {
                setReaderNumber(e.target.value);
              }}
              placeholder="MAC주소를 입력해주세요"
            />
            <span>안테나:</span>
            <input
              value={antNumber}
              onChange={(e) => setAntNumber(NumberUtils.numberOnlyToString(e.target.value))}
              placeholder="안테나번호를 입력해주세요 (0~3)"
            />
            <Button theme={ThemeColor.primary} buttonSize="m" onClick={postMacAddress}>
              저장
            </Button>
          </div>
        </div>
        <div className="content">
          <div className="manipulationDetail">
            <div className="selectItem">
              <div>
                <div>
                  <div
                    className={"rfidSetting onSelect"}
                    onClick={(e) => {
                      if (movementToggle === "stop") {
                        dataPic("rfidSetting");
                      }
                    }}
                  >
                    {!inputSetting ? (
                      <>
                        <div>
                          <img src={pen_sel} alt="" />
                        </div>
                        <span>1. 입력 설정</span>
                      </>
                    ) : (
                      <>
                        <span>1. 입력 설정</span>
                        <span className="checkSpan">
                          {TypeUtils.TableReaderStatus(inputSetting)}
                        </span>
                      </>
                    )}
                    {dataPicStatus === "rfidSetting" && (
                      <div className="statusSelector">
                        {/* <span
                          className="rfid"
                          onClick={() => inputSetStatus("RFID")}
                        >
                          스캔 입력
                        </span> */}
                        <span onClick={() => inputSetStatus("RFID")}>스캔 입력</span>
                        <span onClick={() => inputSetStatus("WRITE")}>수기 입력</span>
                      </div>
                    )}
                  </div>
                  <div
                    className={inputSetting ? "productName onSelect" : "productName"}
                    onClick={(e) => {
                      if (inputSetting && movementToggle === "stop") dataPic("productName");
                    }}
                  >
                    {!statusSelect ? (
                      <>
                        <div>
                          <img src={inputSetting ? pen_sel : pen} alt="" />
                        </div>
                        <span>2. 상태 선택</span>
                      </>
                    ) : (
                      <>
                        <span>2. 상태 선택</span>
                        <span className="checkSpan">
                          {TypeUtils.TableReaderStatus(statusSelect)}
                        </span>
                      </>
                    )}

                    {dataPicStatus === "productName" && (
                      <div className="statusSelector">
                        {inputSetting === "RFID" && (
                          <>
                            <span onClick={() => statusSetStatus("INQUIRY")}>태그조회</span>
                            <span onClick={() => statusSetStatus("APPLICATION")}>세탁 신청</span>
                          </>
                        )}
                        <span onClick={() => statusSetStatus("COMPLETE")}>세탁 완료</span>
                        <span onClick={() => statusSetStatus("RELAUNDRY")}>재세탁</span>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div
                    className={statusSelect ? "storeName onSelect" : "storeName"}
                    onClick={(e) => {
                      if (statusSelect && movementToggle === "stop") dataPic("storeName");
                    }}
                  >
                    {!storeId.franchiseId ? (
                      <>
                        <div>
                          <img src={statusSelect ? store_sel : store} alt="" />
                        </div>
                        <span>3. 지점 선택</span>
                      </>
                    ) : (
                      <>
                        <span>3. 지점 선택</span>
                        <span className="checkSpan">
                          {storeId.franchiseName}
                          {/* 스카이파크호텔 킹스타운점 담당자 스카이파크호텔
                          킹스타운점 담당자 */}
                        </span>
                      </>
                    )}

                    {dataPicStatus === "storeName" && (
                      <div className="statusSelector">
                        {statusSelect === "INQUIRY" && (
                          <span
                            onClick={() =>
                              storeSetStatus({
                                franchiseId: String(0),
                                franchiseName: "전체 지점 조회",
                              })
                            }
                          >
                            전체 지점 조회
                          </span>
                        )}
                        {props.factoryMatchingFranchiseList?.franchiseList.map((item, index) => (
                          <span
                            key={index}
                            className="alignLeft"
                            onClick={() => {
                              storeSetStatus({
                                franchiseId: String(item.franchiseId),
                                franchiseName: item.franchiseName,
                              });
                              props.getProgressList(item.franchiseId);
                              props.getFranchiseLaundryDate(Number(item.franchiseId));
                            }}
                          >
                            {item.franchiseName}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                  <div
                    className={
                      statusSelect
                        ? inputSetting !== "RFID"
                          ? "laundryProgress onSelect"
                          : "laundryProgress"
                        : "laundryProgress"
                    }
                    onClick={(e) => {
                      if (statusSelect && movementToggle === "stop" && inputSetting !== "RFID")
                        dataPic("laundryProgress");
                    }}
                  >
                    {!laundryName.id ? (
                      <>
                        <div>
                          <img
                            src={statusSelect && inputSetting !== "RFID" ? product_sel : product}
                            alt=""
                          />
                        </div>
                        <span>4. 품명 선택</span>
                      </>
                    ) : (
                      <>
                        <span>4. 품명 선택</span>
                        <span className="checkSpan">{laundryName.subCategory}</span>
                      </>
                    )}

                    {dataPicStatus === "laundryProgress" && (
                      <div className="statusSelector">
                        {props.franchiseProgressList?.map((item, index) => (
                          <span
                            key={index}
                            className="alignLeft"
                            onClick={() =>
                              setLaundryName({
                                id: String(item.id),
                                subCategory: item.subCategory,
                              })
                            }
                          >
                            {item.subCategory}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div
                    className={
                      statusSelect && statusSelect !== "INQUIRY"
                        ? "applicationDate onSelect"
                        : "applicationDate"
                    }
                  >
                    <span>5. 세탁신청일</span>
                    <div>
                      <DateApp
                        dateItem={startDateSelector}
                        today={
                          props.franchiseLaundryDate &&
                          props.franchiseLaundryDate?.length > 0 &&
                          storeId.franchiseId !== "" &&
                          (statusSelect === "COMPLETE" || statusSelect === "RELAUNDRY")
                            ? props.franchiseLaundryDate[0].date
                            : applicationDate
                        }
                        // clickCheck={
                        //   statusSelect === "APPLICATION" ? "noCheck" : "noCheck"
                        // }
                        clickCheck={"noCheck"}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      (statusSelect && statusSelect === "COMPLETE") || statusSelect === "RELAUNDRY"
                        ? "deliveryDate onSelect"
                        : "deliveryDate"
                    }
                  >
                    <span>6. 세탁납품일</span>
                    <div>
                      <DateApp
                        dateItem={endDateSelector}
                        today={
                          props.franchiseLaundryCompleteDate &&
                          props.franchiseLaundryCompleteDate.length > 0 &&
                          storeId.franchiseId !== "" &&
                          statusSelect !== "APPLICATION"
                            ? props.franchiseLaundryCompleteDate[0].date
                            : deliveryDate
                        }
                        clickCheck={
                          statusSelect === "COMPLETE" || statusSelect === "RELAUNDRY"
                            ? movementToggle === "stop"
                              ? "check"
                              : "noCheck"
                            : "noCheck"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="manipulation">
                <button
                  className={
                    statusAllCheck && inputSetting === "RFID" && movementToggle === "stop"
                      ? "on"
                      : ""
                  }
                  onClick={() => {
                    if (inputSetting === "RFID") onMovementToggle("start");
                  }}
                  type="button"
                >
                  시작
                </button>
                <button
                  className={statusAllCheck && movementToggle === "start" ? "on" : ""}
                  onClick={() => onMovementToggle("stop")}
                  type="button"
                >
                  중지/초기화
                </button>
              </div>
            </div>
            <div className="amountItem">
              <div>
                <div className="defaultAmount">
                  <div className="count">
                    {inputSetting !== "WRITE" ? (
                      <>
                        <div>
                          {statusSelect === "INQUIRY"
                            ? "조회수량"
                            : statusSelect === "COMPLETE" || statusSelect === "RELAUNDRY"
                            ? "실제수량 / 기준수량"
                            : "입력수량"}
                        </div>
                        <div className="amount">
                          <span>{actualQuantity}</span>
                          {(statusSelect === "COMPLETE" || statusSelect === "RELAUNDRY") && (
                            <>
                              <span>/</span>
                              <span>{standardQuantity}</span>
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div>입력수량</div>
                        <div className="amount">
                          <span>{directQuantity}</span>
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className={successCheck ? "countSortation onRequestSuccess" : "countSortation"}
                  >
                    {successCheck ? (
                      <span className="successComplete">입력하신 내용이 저장되었습니다.</span>
                    ) : (
                      <>
                        <span>
                          {inputSetting !== "WRITE" && `입력가능 : ${inputPossibilityCount}`}
                        </span>
                        <span>{inputSetting !== "WRITE" && `입력완료 : ${inputCompletion}`}</span>
                      </>
                    )}
                  </div>
                </div>
                <div className="countBtn">
                  <div className="minus">
                    <div
                      onClick={() => {
                        if (statusSelect === "COMPLETE" || statusSelect === "RELAUNDRY")
                          quantityBtn(-1);
                      }}
                    >
                      -1
                    </div>
                    <div
                      onClick={() => {
                        if (statusSelect === "COMPLETE" || statusSelect === "RELAUNDRY")
                          quantityBtn(-5);
                      }}
                    >
                      -5
                    </div>
                    <div
                      onClick={() => {
                        if (statusSelect === "COMPLETE" || statusSelect === "RELAUNDRY")
                          quantityBtn(-10);
                      }}
                    >
                      -10
                    </div>
                  </div>
                  <div className="plus">
                    <div
                      onClick={() => {
                        if (statusSelect === "COMPLETE" || statusSelect === "RELAUNDRY")
                          quantityBtn(1);
                      }}
                    >
                      +1
                    </div>
                    <div
                      onClick={() => {
                        if (statusSelect === "COMPLETE" || statusSelect === "RELAUNDRY")
                          quantityBtn(5);
                      }}
                    >
                      +5
                    </div>
                    <div
                      onClick={() => {
                        if (statusSelect === "COMPLETE" || statusSelect === "RELAUNDRY")
                          quantityBtn(10);
                      }}
                    >
                      +10
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="requestSender">
              <div onClick={onRequestSender}>
                <div
                  className={
                    standardQuantity === 0 || standardQuantity === actualQuantity
                      ? "sendPossible"
                      : ""
                  }
                >
                  {standardQuantity === 0 || standardQuantity === actualQuantity ? (
                    <div className="green">
                      <img src={successimg} alt="" />
                    </div>
                  ) : (
                    <div className="red">
                      <span>{standardQuantity > actualQuantity ? "-" : "+"}</span>
                      <span>
                        {Math.abs(standardQuantity - actualQuantity) < 10
                          ? `0${Math.abs(standardQuantity - actualQuantity)}`
                          : Math.abs(standardQuantity - actualQuantity)}
                      </span>
                    </div>
                  )}

                  <span>
                    {standardQuantity === 0 || standardQuantity === actualQuantity
                      ? "데이터 입력"
                      : "입력 불가능"}
                  </span>
                </div>
              </div>
              <div className="refresh" onClick={onRefresh}>
                <div>
                  <img src={refreshimg} alt="" />
                </div>
                <span>새로고침</span>
              </div>
            </div>
          </div>
          <div className="tagList">
            <div className="tagListHeader">
              <div>
                <span>순번</span>
                <span>지점</span>
                <span>대분류</span>
                <span>소분류</span>
                <span>품목명</span>
                <span>수량</span>
              </div>
            </div>
            <div className="tagListBody">
              {scanTagList.map((item, index) => (
                <div key={index}>
                  <span>{index + 1}</span>
                  <span>{item.franchiseName}</span>
                  <span>{item.largeCategory}</span>
                  <span>{item.subCategory}</span>
                  <span>{item.name}</span>
                  <span>{item.amount}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* <DatePickerModal></DatePickerModal> */}
    </FactoryTableReaderBlock>
  );
};

export default FactoryTableReader;

const DatePickerModal = styled.div`
  position: absolute;
  width: 300px;
  height: 150px;
  background-color: red;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const FactoryTableReaderBlock = styled.div<FactoryTableBlockProps>`
  position: relative;
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  & > .contentWrapper {
    width: 100%;
    height: ${(props) => `${props.layoutHeight - 156}px`};
    /* padding: 40px 0; */
    & > .macAddressInput {
      width: 100%;
      height: 40px;
      /* background-color: red; */
      display: flex;
      justify-content: flex-end;
      & > .inputBox {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > span {
          color: #999;
          font-size: 14px;
        }
        & > input {
          width: 250px;
          height: 30px;
          margin: 0 5px;
        }
        & > input:nth-child(2) {
          margin-right: 15px;
        }
      }
    }
    & > .content {
      width: 100%;
      height: ${(props) => `${props.layoutHeight - 225}px`};
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      & > div {
        width: 100%;
        height: calc(100% / 2 - 10px);

        & > div {
          & > div {
            width: 100%;
            & > span {
              display: inline-block;
              width: calc(100% / 6);
              text-align: center;
            }
          }
        }
      }

      & > .manipulationDetail {
        width: 100%;
        display: flex;
        justify-content: space-between;
        & > div {
          height: 100%;
        }
        & > .selectItem {
          width: 25%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          & > div {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            & > div {
              width: 100%;
              height: calc(100% / 3 - 4px);
              display: flex;
              justify-content: space-between;
              & > div {
                position: relative;
                width: calc(50% - 3px);
                height: 100%;
                border: 1px solid ${Palette.lightGray};
                box-sizing: border-box;
                background-color: #ddd;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-weight: 600;
                & > div {
                  width: 20px;
                  height: 20px;
                  & > img {
                    width: 100%;
                    height: 100%;
                  }
                }
                & > span {
                  margin-top: 8px;
                }
                & > .checkSpan {
                  font-size: 18px;
                  font-weight: bold;
                  /* white-space: nowrap; */
                  padding: 0 5px;
                }
                & > .date {
                  width: 100%;
                  text-align: center;
                  margin-top: 5px;
                  font-size: 18px;
                }
                & > .statusSelector {
                  width: auto;
                  min-width: 100%;
                  height: auto;
                  min-height: 100%;
                  max-height: 500px;
                  overflow-y: auto;
                  position: absolute;
                  /* background-color: red; */
                  top: 0;
                  left: 0;
                  background-color: #fff;
                  z-index: 5;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  border: 1px solid black;
                  padding: 5px;
                  /* display: none; */
                  & > span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-bottom: 1px solid black;
                    white-space: nowrap;
                    width: auto;
                    min-width: 100%;
                    height: 35px;
                    color: #333;
                    font-size: 16px;
                    cursor: pointer;
                  }

                  & > .rfid {
                    text-align: center;
                    width: 100%;
                    height: 100%;
                    flex: 1;
                    display: flex;
                  }

                  & > .alignLeft {
                    align-self: flex-start;
                    text-align: left;
                    padding: 5px;
                  }
                  & > span:last-child {
                    border-bottom: 0;
                  }
                }
              }
              & > .applicationDate,
              .deliveryDate {
                & > div {
                  width: 100%;
                  height: auto;
                  display: flex;
                  justify-content: center;
                  & > div {
                    text-align: center;
                    .test121 {
                      .test13 {
                        border: 0 !important;
                      }
                      .test1 {
                        div {
                          left: 0 !important;
                          right: 0;
                          /* top: 0; */
                        }
                      }
                    }
                  }
                }
              }
              & > .onSelect {
                background-color: ${Palette.primary};
                color: #fff;
                cursor: pointer;
              }
            }
          }
          & > div:nth-child(1) {
            flex: 1;
          }
          & > .manipulation {
            width: 100%;
            height: 40px;
            margin-top: 4px;
            flex-direction: row;
            justify-content: space-between;
            & > button {
              width: calc(50% - 3px);
              border-radius: 0;
              font-weight: 600;
              background-color: #ddd;
              border: 1px solid ${Palette.lightGray};
              color: black;
            }
            & > button:nth-child(2) {
              cursor: pointer;
            }
            & > .on {
              background-color: ${Palette.primary};
              color: #fff;
              cursor: pointer;
            }
          }
        }
        & > .amountItem {
          width: calc(59.7% - 3px);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          & > div {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            & > .defaultAmount {
              flex: 1;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              & > .count {
                width: 100%;
                /* height: 84.5%; */
                flex: 1;
                background-color: ${Palette.primary};
                border: 1px solid ${Palette.lightGray};
                & > div {
                  width: 100%;
                  color: #fff;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
                & > .amount {
                  height: 75%;
                  flex-direction: row;
                  justify-content: center;

                  & > span {
                    font-size: 8.5vw;
                    font-weight: bold;
                  }
                  & > span:nth-child(2) {
                    margin: 0 1vw;
                    font-weight: 500;
                  }
                }
                & > div:nth-child(1) {
                  font-size: 24px;
                  height: 25%;
                  display: flex;
                  justify-content: center;
                  align-items: flex-end;
                  /* background-color: red; */
                }
              }
              & > .countSortation {
                width: 100%;
                height: 40px;
                margin-top: 4px;
                flex-direction: row;
                display: flex;
                justify-content: center;
                align-items: center;
                /* background-color: #ddd; */
                border: 1px solid ${Palette.lightGray};
                font-size: 18px;
                font-weight: bold;
                & > span {
                  width: calc(50% - 100px);
                  text-align: center;
                  /* background-color: red; */
                }
                & > .successComplete {
                  width: 100%;
                }
              }
              & > .onRequestSuccess {
                background-color: #51b651;
                color: #fff;
              }
            }
            & > .countBtn {
              width: 120px;
              height: 100%;
              margin-left: 5px;
              box-sizing: border-box;

              display: flex;
              justify-content: space-between;
              & > div {
                width: calc(100% / 2 - 3px);
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                & > div {
                  width: 100%;
                  height: calc(100% / 3 - 3px);
                  background-color: #ddd;
                  border: 1px solid ${Palette.lightGray};
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 22px;
                  font-weight: bold;
                  cursor: pointer;
                }
              }
              & > .minus {
                & > div {
                  color: #ff0000;
                }
              }
              & > .plus {
                & > div {
                  color: #0000ff;
                }
              }
            }
            & > div:nth-child(1) {
              margin-left: 0;
            }
          }
        }
        & > .requestSender {
          width: calc(15% - 5px);
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          & > div {
            width: 100%;
            height: calc(68% - 7px);
            background-color: #ddd;
            border: 1px solid ${Palette.lightGray};
            & > div {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              background-color: #a63232;
              cursor: default;
              & > .green {
                width: 60px;
                height: 60px;
                margin: 0 auto;
                /* border-radius: 40px; */
                /* border: 7px solid #fff; */
                & > img {
                  width: 100%;
                  height: 100%;
                }
              }
              & > .red {
                width: auto;
                height: 60px;
                text-align: center;
                font-size: 60px;
                font-weight: bold;
                color: #fff;

                & > span:nth-child(1) {
                  margin-right: 10px;
                }
              }
              & > span {
                width: 100%;
                height: auto;
                color: #fff;
                font-weight: bold;
                font-size: 20px;
                margin-top: 20px;
                display: inline-block;
                text-align: center;
              }
            }
            & > .sendPossible {
              background-color: #51b651;
              cursor: pointer;
            }
          }
          & > .refresh {
            height: 32.5%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            & > div {
              width: 30px;
              height: 30px;

              margin-bottom: 10px;
              background-color: transparent;
              /* background-color: red; */
              & > img {
                width: 100%;
                height: 100%;
              }
            }
            & > span {
              display: inline-block;
              width: 100%;
            }
          }
        }
      }

      & > .tagList {
        padding: 20px;
        border-radius: 7px;
        border: 1px solid black;
        box-sizing: border-box;
        & > .tagListHeader {
          height: 30px;
          border-bottom: 2px solid black;
          display: flex;
          justify-content: space-between;
          padding-right: 25px;
          & > div {
            & > span {
              height: 30px;
              line-height: 30px;
            }
          }
        }
        & > .tagListBody {
          height: 87%;
          margin-top: 10px;
          overflow-y: scroll;
          padding-right: 10px;
          & > div {
            height: 40px;
            border-bottom: 1px solid ${Palette.lightGray};
            & > span {
              height: 100%;
              font-size: 20px;
              line-height: 40px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
`;
