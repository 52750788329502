import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Iframe from "react-iframe";

interface CheckLocationProps {}

const CheckLocationBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }

  iframe {
    width: 100%;
    height: 80vh;
  }
`;

const CheckLocation: React.FC<CheckLocationProps> = () => {
  return (
    <CheckLocationBlock>
      <div className="headers">
        <h3>위치확인</h3>
      </div>
      {/* <iframe title="sinotrackpro" src="http://www.sinotrackpro.com/"></iframe> */}
      {/* <iframe
        title="sinotrackpro"
        src="http://www.4guardlbs.com/html/Login.html"
        sandbox="
        allow-modals 
        allow-scripts 
        allow-popups 
        allow-popups-to-escape-sandbox 
        allow-forms 
        allow-presentation 
        allow-top-navigation
        allow-popups 
        allow-same-origin"
      /> */}
      <Iframe id="iframe-cds" url="https://cds.carbeast.co.kr/biz/index.html" />
    </CheckLocationBlock>
  );
};

export default CheckLocation;
