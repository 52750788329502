import PopupSmallLayout from "components/common/PopupSmallLayout";
import React from "react";
import styled from "styled-components";
import Button from "components/common/Button";
import { useState } from "react";
import {
  FranchiseAddBagCount,
  FranchiseGetList,
  FranchiseLaundryFactory,
  FranchiseLaundryPage,
} from "modules/store/types";
import { ILoginResponse } from "modules/user/types";
import moment from "moment";
import { Palette, ThemeColor } from "styles/palette";
import ScrollUtils from "utils/ScrollUtils";
import StoreLaundryStatusList from "./StoreLaundryStatusList";
import LaundrySearch from "components/common/LaundrySearch";

interface StoreLaundryProps {
  addLaundryOrder: () => void;
  onChange: () => void;
  inputs: FranchiseAddBagCount;
  franchiseLaundryFactory: FranchiseLaundryFactory | null;
  user: ILoginResponse;
  role: string;
  franchiseLaundryPage: FranchiseLaundryPage;
  onDetail: (id: number) => void;
  toggle: boolean;
  onToggle: (toggle: boolean) => void;
  laundryList: (page: number, data: FranchiseGetList | null) => void;
}

const StoreLaundry: React.FC<StoreLaundryProps> = ({
  addLaundryOrder,
  onChange,
  inputs,
  franchiseLaundryFactory,
  user,
  role,
  franchiseLaundryPage,
  onDetail,
  // franchiseDetailLaundry,
  toggle,
  onToggle,
  laundryList,
}) => {
  const [popupCheck, setPopupCheck] = useState(false);
  const [searchData, setSearchData] = useState<FranchiseGetList>({
    query: "",
    dropBox: "",
    startDate: "",
    endDate: "",
    size: 10,
  });

  const enrollPopupCheck = () => {
    const scrollY = document.body.style.top;
    if (popupCheck === false) ScrollUtils.onPopupScrollStop(window.scrollY);
    if (popupCheck === true) ScrollUtils.closePopupScrollRun(scrollY);
    setPopupCheck(!popupCheck);
  };

  const laundryOrderSubmit = () => {
    addLaundryOrder();
    setPopupCheck(false);
    enrollPopupCheck();
    // if (inputs.bagCount) {
    //   setPopupCheck(!popupCheck);
    // }
  };

  const onSearch = (page: number, data: FranchiseGetList) => {
    setSearchData(data);
    laundryList(page, data);
  };

  const pageMove = (page: number) => {
    laundryList(page, searchData ?? null);
  };

  const selectShowEntries = (size: number) => {
    searchData.size = size;
    laundryList(1, searchData ?? null);
  };

  return (
    <StoreLaundryBlock>
      {popupCheck && (
        <PopupSmallLayout className="smallPadding">
          <h2 className="popupHeader">세탁 신청</h2>
          <article className="popupInput">
            <div className="inputField">
              <div className="field">
                <span>신청일</span>
                <input value={moment().format("YYYY-MM-DD")} disabled />
              </div>
              <div className="field">
                <span>세탁물 수량</span>
                <input
                  name="bagCount"
                  type="number"
                  value={inputs.bagCount}
                  onChange={onChange}
                  placeholder="포대/카트 수량을 입력하세요."
                />
              </div>
              <div className="field secondField">
                <span>지점 담당</span>
                <input value={user.nickname} disabled />
                <input value={user.phone} disabled />
              </div>
              <div className="field">
                <span>세탁공장</span>
                <input value={franchiseLaundryFactory?.companyName} disabled />
              </div>
              <div className="field secondField">
                <span>세탁공장 담당</span>
                <input
                  value={franchiseLaundryFactory?.manager.nickname}
                  disabled
                />
                <input
                  value={franchiseLaundryFactory?.manager.phone}
                  disabled
                />
              </div>
            </div>
          </article>
          <div className="buttonWrapper">
            <Button
              theme={ThemeColor.primary}
              buttonSize="m"
              onClick={enrollPopupCheck}
            >
              취소
            </Button>
            <Button
              theme={ThemeColor.primary}
              buttonSize="m"
              onClick={laundryOrderSubmit}
            >
              확인
            </Button>
          </div>
        </PopupSmallLayout>
      )}
      <div className="headers">
        <h3>세탁 현황</h3>
      </div>
      <div className="tableSelect">
        <div>
          <span>Show </span>
          <select
            onChange={(e) => selectShowEntries(parseInt(e.target.value, 10))}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
          <span> entries</span>
        </div>
        <div className="topWrapper">
          <span>Search: </span>
          <div>
            <LaundrySearch onSearch={onSearch} role={role} />
            <Button
              theme={ThemeColor.tertiary}
              buttonSize="m"
              className="laundryOrderBtn"
              onClick={enrollPopupCheck}
            >
              세탁 신청
            </Button>
          </div>
        </div>
      </div>
      <StoreLaundryStatusList
        pageMove={pageMove}
        laundryList={laundryList}
        role={role!}
        franchiseLaundryPage={franchiseLaundryPage!}
        onDetail={onDetail}
        toggle={toggle}
        onToggle={onToggle}
        size={searchData.size ?? 10}
      />
    </StoreLaundryBlock>
  );
};

export default StoreLaundry;

const StoreLaundryBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);

  .popupHeader {
    margin-top: 2.5rem;
    margin-left: 3rem;
  }
  .popupInput {
    flex: 1;
    width: 21.5rem;
    margin: 0 auto;
    .inputField {
      width: 100%;
      margin-top: 5.5rem;
      .field {
        /* height: 2.1rem; */
        display: grid;
        grid-template-columns: 8rem 1fr;
        align-items: center;
        line-height: 2rem;
        select,
        input {
          height: 2.1rem;
        }
        label {
          span {
            margin-left: 0.7rem;
          }
        }
      }
      .field + .field {
        margin-top: 1.3rem;
      }
      .secondField {
        grid-template-columns: 8rem 1fr 1.5fr;
        & > input {
          width: 5rem;
        }
        & > input:nth-child(3) {
          width: 8.1rem;
        }
      }
    }
  }
  .buttonWrapper {
    margin: 0 auto;
    padding: 2rem;
  }
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  .tableSelect {
    height: 70px;
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: space-between;
    & > div {
      color: #999;
      height: 40px;
      /* overflow: hidden; */
      & > span {
        font-size: 14px;
        line-height: 37px;
      }
      & > select {
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        transition: ease-in-out;
        transition-duration: 0.1s;
        border-radius: 3px;
      }
      & > select:hover {
        border-bottom: 2px solid ${Palette.primary};
      }
    }
  }
  .topWrapper {
    display: flex;
    justify-content: flex-end;
    & > span {
      font-size: 14px;
      line-height: 37px;
      margin-right: 5px;
    }
    & > div {
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div {
        margin: 0;
        margin-left: 1rem;
      }
      & > div:nth-child(2) {
        margin-left: 5px;
      }
    }
  }
`;
