export enum Palette {
  primary = "#1B2253",
  secondary = "#1B2253",
  primaryHover = "#3d4663",
  tertiary = "#6e7ce6",
  gray = "#828282",
  red = "#AF0808",
  lightGray = "#aaa",
  lightGray2 = "#ccc",
  white = "#fff",
  pagingNumColor = "#1B2253",
  default = "#3d4663",
}

type ButtonProperty = {
  backgroundColor: Palette;
  color: Palette;
  border: Palette;
};

export enum ThemeColor {
  primary = "primary",
  secondary = "secondary",
  tertiary = "tertiary",
  quadratic = "quadratic",
  warning = "warning",
}

export const buttonColorMap: { [key in ThemeColor]: ButtonProperty } = {
  primary: {
    backgroundColor: Palette.primary,
    color: Palette.white,
    border: Palette.primary,
  },
  secondary: {
    backgroundColor: Palette.secondary,
    color: Palette.white,
    border: Palette.white,
  },
  tertiary: {
    backgroundColor: Palette.tertiary,
    color: Palette.white,
    border: Palette.tertiary,
  },
  quadratic: {
    backgroundColor: Palette.gray,
    color: Palette.white,
    border: Palette.gray,
  },
  warning: {
    backgroundColor: Palette.red,
    color: Palette.white,
    border: Palette.red,
  },
};
