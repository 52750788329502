import { FactoryGetList, LabelSearchType } from "modules/factory/types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ThemeColor } from "styles/palette";
import RoleUtils from "utils/RoleUtils";
import Button from "./Button";
import { LaundrySearchBlock } from "./LaundrySearch";
import "date-fns";

import DateFormatUtils from "utils/DateFormatUtils";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { SpecificationsFranchiseList } from "modules/user/types";

interface LabelSearchProps {
  role: string;
  onSearch: (query: string, type: string) => void;
  size?: number | null;
  selectList: SpecificationsFranchiseList | null;
}

const LabelSearch: React.FC<LabelSearchProps> = ({
  role,
  onSearch,
  size,
  selectList,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchType, setSearchType] = useState<string>("frname");

  const submitSearch = (e: any) => {
    e.preventDefault();
    onSearch(searchQuery, searchType);
  };

  const selectHandleChange = (e: string) => {
    setSearchType(e);
  };

  // const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   setSearchType(event.target.value as string);
  // };

  useEffect(() => {
    if (RoleUtils.isFactory(role) || RoleUtils.isMaster(role)) {
      selectHandleChange("franchiseName");
    }
    if (RoleUtils.isStore(role)) {
      selectHandleChange("factoryName");
    }
  }, []);

  return (
    <LabelSearchBlock
      onSubmit={(e) => {
        submitSearch(e);
      }}
    >
      <select
        className="selectBox"
        value={searchType!}
        onChange={(e) => {
          selectHandleChange(e.target.value);
        }}
      >
        <option value="frname">지점명</option>
        <option value="asset">품목명</option>
        <option value="num">번호</option>
        <option value="department">부서명</option>
        <option value="name">이름</option>
        {/* {selectList &&
          selectList.franchiseList.map((item, index) => (
            <option value={item.franchiseId} key={index}>
              {item.franchiseName}
            </option>
          ))} */}
      </select>

      <input
        className="textBox"
        value={searchQuery ?? ""}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      <Button
        theme={ThemeColor.primary}
        buttonSize="m"
        onClick={submitSearch}
        className="buttonSize"
      >
        검색
      </Button>
    </LabelSearchBlock>
  );
};

export default LabelSearch;

export const LabelSearchBlock = styled(LaundrySearchBlock)``;
