import axios from "api/defaultClient";
import { AdminGetList } from "modules/admin/types";
import {
  BillingData,
  FactoryGetList,
  FactoryItemizedAccountSearch,
  LaundryClassification,
  FactoryExcelMonth,
  FactoryTableReadingData,
  FactoryTableRequest,
  FactoryGetTagData,
  FactoryTableDirectData,
  LabelSearchType,
} from "modules/factory/types";
import { ProfileModify } from "modules/user/types";

export const getDashBoard = (day: string) => {
  return axios.get(`/factory/dashboard?date=${day}`);
};
export const getFactoryProfile = () => {
  return axios.get(`/factory/me`);
};

export const patchFactoryProfileModify = (payload: ProfileModify) => {
  return axios.patch(`/factory/updateme`, payload);
};

export const getFactoryLaundryList = (
  page: number,
  data: FactoryGetList | null,
  sort?: string
) => {
  if (data) {
    if (data.dropBox === "franchiseName") {
      return axios.get(
        `/factory/laundry?page=${page}&query=${data?.query}&dropBox=${
          data?.dropBox
        }&size=${data.size}&sort=${sort ?? ""}`
      );
    }

    return axios.get(
      `/factory/laundry?page=${page}&startDate=${
        data?.startDate ?? ""
      }&endDate=${data?.endDate ?? ""}&dropBox=${data?.dropBox}&size=${
        data.size
      }&sort=${sort ?? ""}`
    );
  }
  if (!data) return axios.get(`/factory/laundry?page=${page}`);
};

export const getFactoryLaundryDetail = (id: number) => {
  return axios.get(`/factory/laundry/${id}`);
};

export const postFactoryLaundryClassification = (
  payload: LaundryClassification
) => {
  return axios.post(`/factory/laundry/${payload.id}`, {
    outBagCount: payload.outBagCount,
    complete: payload.complete,
    items: payload.items,
  });
};

export const patchFactoryLaundryClassification = (
  payload: LaundryClassification
) => {
  return axios.patch(`/factory/laundry/${payload.id}`, {
    outBagCount: payload.outBagCount,
    complete: payload.complete,
    items: payload.items,
  });
};

export const deleteFactoryLaundryData = (id: number) => {
  return axios.delete(`/factory/laundry/${id}`);
};

export const getFactoryBillingList = (
  page: number,
  data: FactoryGetList | null
) => {
  if (data) {
    return axios.get(
      `/factory/billing?page=${page}&query=${data?.query}&startDate=${data?.startDate}&endDate=${data?.endDate}&dropBox=${data?.dropBox}&size=${data.size}`
    );
  }
  if (!data) return axios.get(`/factory/billing?page=${page}`);
};

export const getFactoryBillingDetail = (id: number) => {
  return axios.get(`/factory/billing/${id}`);
};

export const patchFactoryBillingData = (payload: BillingData) => {
  return axios.patch(`/factory/billing/${payload.id}`, {
    price: payload.price,
    status: payload.status,
  });
};

export const getFactoryMatchingFranchiseList = () => {
  return axios.get(`/factory/franchiselist`);
};

export const getFactoryItemizedAccount = (
  payload: FactoryItemizedAccountSearch
) => {
  return axios.get(
    `/factory/billing/excel/${payload.dropBox}?startDate=${payload.startDate}&endDate=${payload.endDate}`
  );
};

export const getFactoryMatchedFranchiseData = (
  page: number,
  data: FactoryGetList | null,
  sort?: string
) => {
  if (data) {
    return axios.get(
      `/factory/matched/franchise?page=${page}&query=${data?.query}&dropBox=${
        data?.dropBox
      }&size=${data.size}&sort=${sort ?? ""}`
    );
  }
  if (!data) return axios.get(`/factory/matched/franchise?page=${page}`);
};

export const postFactoryLaundryRequestData = (id: number, bagCount: number) => {
  const data = {
    franchiseId: id,
    bagCount: bagCount,
  };
  return axios.post(`/factory/laundry/franchiseid/`, data);
};

export const getFactoryDeliveryExcelData = (
  id: number,
  data: AdminGetList | null
) => {
  if (data) {
    return axios.get(
      `/factory/billing/excel/state/${id}?startDate=${data.startDate}&endDate=${data.endDate}`
    );
  }
  return axios.get(`/factory/billing/excel/state/${id}`);
};

export const getFactoryLaundryTableData = (
  id: number,
  startDate: string,
  endDate: string,
  chartType: string
) => {
  if (chartType === "aggregation1") {
    return axios.get(`/factory/excel-franchise/${id}?month=${startDate}`);
  } else {
    return axios.get(
      `/factory/excel-franchise/${id}?startDate=${startDate}&endDate=${endDate}`
    );
  }
};

export const postFactoryTableShare = (
  image: string,
  frId: number,
  title: string,
  startDate: string,
  endDate: string,
  monthData: string,
  chartType: string
) => {
  return axios.post(`/user/history1`, {
    image,
    frId,
    title,
    startDate,
    endDate,
    month: monthData,
    chartType,
  });
};

export const getFactorySpecificationsList = (
  id: number | null,
  page: number,
  data: FactoryGetList | null,
  sort?: string
) => {
  let idCheck = `&frId=${id}`;

  if (id === null) idCheck = "";

  if (data) {
    return axios.get(
      `/history/fr?page=${page}&limit=${data.size}&startDate=${
        data.startDate
      }&endDate=${data.endDate}&frId=${data.dropBox ?? ""}&sort=${sort ?? ""}`
    );
  }
  return axios.get(`/history/fr?page=${page}&limit=10`);
};

export const getFactoryLaundryTableYearData = (data: FactoryExcelMonth) => {
  return axios.get(
    `/factory/excel-month/${data.id}?startMonth=${data.startMonth}&endMonth=${data.endMonth}`
  );
};

// 명세서 삭제 api
export const deleteFactoryStatement = (id: number) => {
  return axios.delete(`/factory/billing/history/${id}`);
};

// 테이블 MAC 주소
export const getMacAddress = (id: number) => {
  return axios.get(`/factory/mac?factoryId=${id}`);
};

export const postMacAddress = (factoryId: number, macAddress: string) => {
  const data = {
    factoryId,
    macAddress,
  };
  return axios.post(`/factory/mac`, data);
};

// 테이블리더기 세탁신청 api
export const postFactoryLaundryTagRequest = (data: FactoryTableRequest) => {
  return axios.post(`/tag/factory`, data);
};

// 테이블리더기 세탁완료 api
export const postLaundryComplete = (data: FactoryTableReadingData) => {
  return axios.post(`/tag/factory/complete`, data);
};

// 테이블리더기 재세탁 api
export const postReLaundry = (data: FactoryTableReadingData) => {
  return axios.post(`/tag/factory/re`, data);
};

export const getTagData = (data: FactoryGetTagData) => {
  return axios.get(`/tag/table?mode=${data.mode}&tagCode=${data.tagCode}`);
};

// 테이블리더기 수기입력 세탁완료 api
export const postDirectAmountComplete = (data: FactoryTableDirectData) => {
  return axios.post(`/tag/factory/laundry`, data);
};

//테이블리더기 수기입력 재세탁 api
export const postDirectReLaundry = (data: FactoryTableDirectData) => {
  return axios.post(`/tag/factory/laundry/re`, data);
};

//테이블리더기 최근 세탁신청일 가져오기 api
export const getFactoryFranchiseLaundryDate = (id: number) => {
  return axios.get(`/tag/factory/date/${id}`);
};

//테이블리더기 최근 세탁완료일 가져오기 api
export const getFactoryFranchiseLaundryCompleteDate = (id: number) => {
  return axios.get(`/tag/complete/${id}`);
};

// 테이블리더기 웹소켓 서버 활성화
export const activationTableReaderServer = () => {
  return axios.get(`/tcp/on`);
};

// 라벨 출력한 데이터 가져오기
export const labelExportData = (data: LabelSearchType) => {
  let item = "";
  if (data.query) {
    item = item + `&query=${data.query}`;
  }
  if (data.type) {
    item = item + `&type=${data.type}`;
  }
  if (data.sort) {
    item = item + `&sort=${data.sort}`;
  }


  // console.log("data", data);

  return axios.get(`/label/page?page=${data.page}&limit=${data.limit}${item}`);
};
