import {
  FranchiseDetailLaundry,
  FranchiseGetList,
  FranchiseLaundry,
  FranchiseLaundryPage,
} from "modules/store/types";
import { ThemeColor } from "styles/palette";
import LaundaryStatusDetail from "components/common/LaundaryStatusDetail";
import Button from "components/common/Button";
import styled from "styled-components";
import Paging from "components/common/Paging";
import DateFormatUtils from "utils/DateFormatUtils";
import NumberUtils from "utils/NumberUtils";
import { useEffect, useState } from "react";
// import { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TypeUtils from "utils/TypeUtils";

interface StoreLaundryStatusListProps {
  role: string;
  franchiseLaundryPage: FranchiseLaundryPage;
  onDetail: (id: number) => void;
  toggle: boolean;
  onToggle: (toggle: boolean) => void;
  laundryList: (page: number, data: FranchiseGetList) => void;
  pageMove: (page: number) => void;
  size?: number;
}

const StoreLaundryStatusList: React.FC<StoreLaundryStatusListProps> = ({
  role,
  franchiseLaundryPage,
  onDetail,
  toggle,
  onToggle,
  laundryList,
  pageMove,
  size,
}) => {
  const [listNumber, setListNumber] = useState<number[]>([]);
  useEffect(() => {
    setListNumber(
      NumberUtils.numberToList(
        franchiseLaundryPage?.currentPage ?? 0,
        franchiseLaundryPage?.totalCount ?? 0,
        size
      )
    );
  }, [franchiseLaundryPage, size]);
  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      fontWeight: 700,
    },
  });
  const classes = useStyles();

  const [laundryDetailItems, setLaundryDetailItems] = useState<
    FranchiseLaundry[]
  >([]);

  useEffect(() => {
    setLaundryDetailItems(franchiseLaundryPage.list ?? []);
  }, [franchiseLaundryPage]);

  // console.log(laundryDetailItems);

  return (
    <StoreLaundryStatusListBlock>
      {/* {toggle && (
        <LaundaryStatusDetail
          franchiseDetailLaundry={franchiseDetailLaundry!}
          onToggle={onToggle}
          onDetail={onDetail}
        />
      )} */}

      <div className="tableWrapper">
        <TableContainer component={Paper} className="tables">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: 900,
                    width: "70px",
                  }}
                >
                  순번
                </TableCell>
                {/* <TableCell
                  align="center"
                  style={{
                    fontWeight: 900,
                    width: "70px",
                  }}
                >
                  구분
                </TableCell> */}
                <TableCell align="center" style={{ fontWeight: 900 }}>
                  세탁공장
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: 900,
                    width: "20%",
                  }}
                >
                  세탁 신청일
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "20%" }}
                >
                  납품일
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: 900,
                    width: "20%",
                  }}
                >
                  신청 포대/카트
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {laundryDetailItems.map((laundry, index) => (
                <TableRow
                  key={index}
                  className="tableHover"
                  onClick={() => onDetail(laundry.id)}
                >
                  <TableCell component="th" scope="row" align="center">
                    {listNumber[index]}
                  </TableCell>
                  {/* <TableCell component="th" scope="row" align="center">
                    {TypeUtils.laundryRequestType(laundry.type)}
                  </TableCell> */}
                  <TableCell align="center">{laundry.factoryName}</TableCell>
                  <TableCell align="center">
                    {DateFormatUtils.dateToFormat(laundry.applicationDate)}
                  </TableCell>
                  <TableCell align="center">
                    {DateFormatUtils.dateToFormat(laundry.completedDate)}
                  </TableCell>
                  <TableCell align="center">
                    {NumberUtils.numberToComma(laundry.bagCount ?? 0)} 포대/카트
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Paging
          listAction={pageMove}
          listCount={franchiseLaundryPage?.totalPage ?? 0}
          currentPage={franchiseLaundryPage.currentPage}
          totalCount={franchiseLaundryPage?.totalCount}
          size={size}
        ></Paging>
      </div>
    </StoreLaundryStatusListBlock>
  );
};

export default StoreLaundryStatusList;

const StoreLaundryStatusListBlock = styled.div`
  .tables {
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .headers {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .topWrapper {
    display: flex;
    justify-content: flex-end;
    & > div {
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div {
        margin: 0;
        margin-left: 1rem;
      }
    }
  }
  .tableWrapper {
    min-width: 51rem;
    padding-bottom: 1.5rem;
  }
  .tableHover:hover {
    background-color: #eee;
    cursor: pointer;
  }
`;
