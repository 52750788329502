export const DAY = 1000 * 60 * 60 * 24;
export const SIZE = 36;

export function easeInOutQuad(t: any) {
  return t < 0.5 ? 2 * t * t : t * (4 - 2 * t) - 1;
}

export function easeOutCubic(t: any) {
  return Math.pow(t - 1, 3) + 1;
}

export function twoDigit(num: any) {
  return `0${num}`.substr(-2);
}

// yyyy-mm-dd
export function makeIso(date: any) {
  return `${date.getFullYear()}-${twoDigit(date.getMonth() + 1)}-${twoDigit(
    date.getDate()
  )}`;
}

export function getMonthFromDayNumber(dayNumber: any) {
  const date = new Date(dayNumber * DAY);
  return date.getUTCMonth();
}

export function todaysDayNumber() {
  return (new Date(makeIso(new Date())).getTime() / DAY) | 0;
}

export function isIsoDateValid(str: any) {
  const date = new Date(str);
  if (isNaN(date.getTime())) return false;
  return date.toISOString().replace(/T.*/, "") === str;
}

export function getLanguage() {
  if (navigator.languages !== undefined) return navigator.languages[0];
  else return navigator.language;
}
