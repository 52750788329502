import React from "react";
import styled from "styled-components";
import Button from "../Button";
import { ThemeColor } from "styles/palette";
import { useState } from "react";
import { useEffect } from "react";
import {
  BoardDetailData,
  BoardPostData,
  BoardToUserList,
} from "modules/user/types";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link, useHistory } from "react-router-dom";
import RoleUtils from "utils/RoleUtils";

interface TextEditorProps {
  detailId?: number | null;
  role: string;
  editorType?: string;
  title?: string;
  boardToUserList?: BoardToUserList;
  type: string;
  auth?: string;
  selectType: (type: string) => void;
  content?: BoardDetailData | null;
  dataSubmit: (boardCommand: BoardPostData) => void;
}

const TextEditor: React.FC<TextEditorProps> = ({
  editorType,
  title,
  boardToUserList,
  selectType,
  type,
  auth,
  content,
  dataSubmit,
  role,
  detailId,
}) => {
  const history = useHistory();
  const [sortation, setSortation] = useState<string>("default");
  const [boardTitle, setBoardTitle] = useState<string | null>("");
  const [queryType, setQueryType] = useState<string | null>("");
  const [text, setText] = useState("");

  const [sendType, setSendType] = useState<string>("all");
  const [sendTo, setSendTo] = useState<string>("default");

  const [postToId, setPostToId] = useState<string>("");

  useEffect(() => {
    setPostToId(content?.receiver ? String(content.receiver.id) : "");
  }, [content?.receiver]);

  useEffect(() => {
    if (type === "write") setQueryType("");
    if (type === "modify") setQueryType(`/${detailId}`);
  }, [type]);

  useEffect(() => {
    setSendTo("default");
  }, [sendType]);

  const onSortationChange = (e: string) => {
    setSortation(e);
  };

  const onTitleChange = (e: string) => {
    setBoardTitle(e);
  };

  const onReversePage = () => {
    if (type === "write") selectType("list");
    if (type === "modify") selectType("detail");
    history.push(`/${auth}/${editorType}${queryType}`);
  };

  useEffect(() => {
    if (type === "modify") {
      setText(content!.content);
      setBoardTitle(content!.title);
      if (editorType === "board") setSortation(content?.boardType ?? "");
    }
  }, [content, type, editorType]);

  const onSubmit = () => {
    if (sortation === "default" && editorType === "board")
      return alert("구분을 선택해주세요");
    if (boardTitle === "") return alert("제목을 입력해주세요");
    let boardCommand = {
      title: boardTitle ?? "",
      content: text ?? "",
      boardType: sortation,
      receiverId: Number(postToId),
    };

    if (editorType === "notice") boardCommand.boardType = "NOTICE";
    dataSubmit(boardCommand);

    setSortation("default");
    setBoardTitle("");
    setText("");
    onReversePage();
    history.push(`/${auth}/${editorType}${queryType}`);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      // ["bold", "italic", "underline", "strike", "blockquote"],
      ["bold", "italic", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      // ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const handleChange = (
    content: string,
    _delta: any,
    _source: any,
    editor: any
  ) => {
    setText(content);
    // console.log(editor.getHTML(), "rich text"); // rich text
    // console.log(editor.getText(), "plain text"); // plain text
    // console.log(editor.getLength(), "number of characters"); // number of characters
  };

  return (
    <TextEditorBlock>
      <div className="headers">
        <h3>{title}</h3>
        <div className="submitBtnBox">
          <Button
            theme={ThemeColor.primary}
            buttonSize="s"
            onClick={onReversePage}
          >
            뒤로가기
          </Button>

          <Button theme={ThemeColor.primary} buttonSize="s" onClick={onSubmit}>
            등록
          </Button>
        </div>
      </div>
      <div className="submitBtnWrapper">
        <div>
          {editorType === "board" && (
            <>
              <label>
                <span>구분: </span>
                <select
                  value={sortation}
                  onChange={(e) => onSortationChange(e.target.value)}
                >
                  <option value="default">선택해주세요</option>
                  <option value="INQUIRY">문의사항</option>
                  {/* <option value="DISCONTENT">불만 사항</option> */}
                  {!RoleUtils.isFactory(role) && (
                    <option value="SUJUNG">수정요청</option>
                  )}
                  <option value="ETC">기타</option>
                </select>
              </label>
              {RoleUtils.isStore(role) && (
                <label>
                  <span>발송대상: </span>
                  <select
                    onChange={(e) => setPostToId(e.target.value)}
                    value={postToId}
                  >
                    <option value="all">전체</option>
                    {boardToUserList?.brand.map((brand, index) => (
                      <option key={index} value={brand.id}>
                        {brand.name}
                      </option>
                    ))}
                    {boardToUserList?.factory.map((factory, index) => (
                      <option key={index} value={factory.id}>
                        {factory.name}
                      </option>
                    ))}
                  </select>
                </label>
              )}
              {RoleUtils.isFactory(role) && (
                <label>
                  <span>발송대상: </span>
                  <select
                    onChange={(e) => setPostToId(e.target.value)}
                    value={postToId}
                  >
                    <option value="all">전체</option>
                    {boardToUserList?.franchise.map((store, index) => (
                      <option key={index} value={store.id}>
                        {store.name}
                      </option>
                    ))}
                  </select>
                </label>
              )}
              {RoleUtils.isMaster(role) && (
                <>
                  <label>
                    <span>발송대상: </span>
                    <select
                      value={sendType}
                      onChange={(e) => setSendType(e.target.value)}
                    >
                      <option value="all">전체</option>
                      <option value="store">지점</option>
                      <option value="factory">세탁공장</option>
                    </select>
                  </label>
                  {sendType !== "all" && (
                    <>
                      <label>
                        <span>
                          {sendType === "store" && "지점선택: "}
                          {sendType === "factory" && "공장선택: "}
                        </span>
                        {sendType === "store" && (
                          <select
                            value={postToId}
                            onChange={(e) => setPostToId(e.target.value)}
                          >
                            <option value="">선택</option>
                            {boardToUserList?.franchise.map((store, index) => (
                              <option key={index} value={store.id}>
                                {store.name}
                              </option>
                            ))}
                          </select>
                        )}
                        {sendType === "factory" && (
                          <select
                            value={postToId}
                            onChange={(e) => setPostToId(e.target.value)}
                          >
                            <option value="">선택</option>
                            {boardToUserList?.factory.map((factory, index) => (
                              <option key={index} value={factory.id}>
                                {factory.name}
                              </option>
                            ))}
                          </select>
                        )}
                      </label>
                    </>
                  )}
                </>
              )}
            </>
          )}
          <label>
            <span>제목: </span>
            <input
              value={boardTitle ?? ""}
              onChange={(e) => onTitleChange(e.target.value)}
            />
          </label>
        </div>
      </div>
      <div className="editor">
        <ReactQuill
          className="quillEditor"
          theme="snow"
          value={text}
          modules={modules}
          formats={formats}
          onChange={handleChange}
        />
      </div>
    </TextEditorBlock>
  );
};

export default TextEditor;

const TextEditorBlock = styled.form`
  min-height: 500px;
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);

  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }

  .submitBtnWrapper {
    height: 70px;
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: space-between;
    div {
      & > label {
        & > span {
          color: #999;
          font-size: 14px;
          line-height: 37px;
        }
        & > input,
        select {
          /* width: 665px; */
          width: 300px;

          border-radius: 3px;
          border: 1px solid #dee2e6;
          box-sizing: border-box;
        }
        & > select {
          width: 150px;
          padding-left: 10px;
          margin-right: 16px;
        }
      }
    }
    & > .submitBtnBox {
      margin-left: 5px;
    }
  }
  .editor {
    height: auto;
    display: flex;
    margin-top: 8px;
    padding-bottom: 20px;
    & > .quillEditor {
      height: auto;
      min-height: 500px;
      flex: 1;
      padding-bottom: 40px;
    }
  }
`;
