import React, {
  useRef,
  forwardRef,
  memo,
  useMemo,
  useLayoutEffect,
  useState,
  useImperativeHandle,
} from "react";
import { useRaf } from "./useRaf";

import {
  DAY,
  SIZE,
  makeIso,
  easeInOutQuad,
  getMonthFromDayNumber,
  todaysDayNumber,
  getLanguage,
} from "./util";

function twoDigit(num: any) {
  return `0${num}`.substr(-2);
}

function getWeekRow(dayNumber: any, offset = 3) {
  return ((dayNumber + offset) / 7) | 0;
}

function getMonthRow(dayNumber: any) {
  const date = new Date(dayNumber * DAY);
  const monthFirst = new Date(
    `${date.getUTCFullYear()}-${twoDigit(date.getMonth() + 1)}-01`
  );
  return getWeekRow((monthFirst.getTime() / DAY) | 0);
}

// offset helps us get first day of week
function getDates(scrollOffset: any, offset = 3) {
  const rowNumber = (scrollOffset / SIZE) | 0;
  const firstDayNumber = rowNumber * 7 - offset;
  const weeks = [];
  for (let week = 0; week < 7; week++) {
    const days = [];
    for (let day = 0; day < 7; day++) {
      days.push({
        key: `day-${day}`,
        dayNumber: firstDayNumber + day + week * 7,
      });
    }
    weeks.push({
      key: `week-${((getWeekRow(days[0].dayNumber) % 7) + 7) % 7}`,
      offsetY: week * SIZE,
      days,
    });
  }
  return weeks;
}

function deltaMonth(dayNumber: any, delta: any) {
  const _date = new Date(dayNumber * DAY);
  const month = _date.getUTCMonth() + delta;
  const year = _date.getUTCFullYear();
  const date = _date.getUTCDate();
  const lastDate = new Date(Date.UTC(year, month + 1, 0)).getUTCDate();
  //   console.log(lastDate, month);
  return (
    new Date(Date.UTC(year, month, Math.min(date, lastDate))).getTime() / DAY
  );
}

const today = new Date(makeIso(new Date())).getTime() / DAY;
export const Dates = forwardRef(
  (
    { value, defaultValue = today, onChange, onSelect, autoFocus }: any,
    ref
  ) => {
    // resolve date to integer day

    //const [scrollPos, setScrollPos] = useState(0);
    const [dayNumber, _setDayNumber] = useState<any>(defaultValue);
    const callbacksRef = useRef<any>({});
    const scrollPos = getMonthRow(dayNumber) * SIZE;
    const [animScroll, setAnimScroll] = useState<any>(scrollPos);
    const scrollRef = useRef<any>({ current: scrollPos, previous: scrollPos });
    const elRef = useRef<any>();
    const focusRef = useRef<any>();
    const dayFormatter = useMemo<any>(
      () => Intl.DateTimeFormat(getLanguage(), { weekday: "narrow" }),
      []
    );
    function setDayNumber(dayNumber: any) {
      _setDayNumber(dayNumber);
      focusRef.current = dayNumber;
      if (typeof onSelect === "function") {
        onSelect(dayNumber);
      }
    }

    callbacksRef.current.setDayNumber = setDayNumber;
    callbacksRef.current.onChange = onChange;

    //const [dayNumber, setDayNumber] = useState(today);

    scrollRef.current.previous = scrollRef.current.current;
    scrollRef.current.current = scrollPos;

    if (scrollRef.current.previous !== scrollRef.current.current) {
      const startPos =
        scrollRef.current.transition &&
        scrollRef.current.transition.animPos !== undefined
          ? scrollRef.current.transition.animPos
          : scrollRef.current.previous;
      scrollRef.current.transition = {
        startPos,
        startTime: performance.now(),
        duration: 400,
        dist: scrollRef.current.current - startPos,
      };
    }
    useLayoutEffect(() => {
      if (focusRef.current !== undefined && elRef.current) {
        const el = elRef.current.querySelector(
          `[data-day-number="${focusRef.current}"]`
        );
        if (el) {
          el.focus();
        }
        focusRef.current = undefined;
      }
    });

    useLayoutEffect(() => {
      if (elRef.current && autoFocus) {
        elRef.current.focus();
      }
    }, []);

    useRaf(() => {
      if (!scrollRef.current.transition) return;
      const now = performance.now();
      const { startTime, duration, dist, startPos } =
        scrollRef.current.transition;
      const frac = (now - startTime) / duration;
      if (frac < 1) {
        const pos = easeInOutQuad(frac) * dist + startPos;
        setAnimScroll(pos);
        scrollRef.current.transition.animPos = pos;
      } else {
        scrollRef.current.transition = undefined;
        setAnimScroll(dist + startPos);
      }
    }, !scrollRef.current.transition);

    function onKeyDown(e: any) {
      switch (e.keyCode) {
        //up
        case 38:
          e.preventDefault();
          setDayNumber(dayNumber - 7);
          break;
        //down
        case 40:
          e.preventDefault();
          setDayNumber(dayNumber + 7);
          break;
        //left
        case 37:
          e.preventDefault();
          setDayNumber(dayNumber - 1);
          break;
        //right
        case 39:
          e.preventDefault();
          setDayNumber(dayNumber + 1);
          break;
        //page up
        case 33:
          e.preventDefault();
          setDayNumber(deltaMonth(dayNumber, -1));
          break;
        //page down
        case 34:
          e.preventDefault();
          setDayNumber(deltaMonth(dayNumber, 1));
          break;
        case 13:
          e.preventDefault();
          if (typeof onChange === "function") {
            onChange(dayNumber);
          }
          break;
        default:
      }
    }

    useImperativeHandle(ref, () => {
      return {
        nextMonth: () => setDayNumber(deltaMonth(dayNumber, 1)),
        previousMonth: () => setDayNumber(deltaMonth(dayNumber, -1)),
      };
    });

    const _todaysDayNumber = todaysDayNumber();

    return (
      <>
        <div
          style={{
            width: SIZE * 7,
            height: SIZE,
            border: "3px soild red",
            display: "flex",
          }}
        >
          {[0, 1, 2, 3, 4, 5, 6].map((x) => (
            <div
              key={"day" + x}
              style={{
                color: "#ccc",
                width: SIZE,
                height: `${SIZE}px`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                fontSize: 14,
              }}
            >
              {dayFormatter.format(new Date(2020, 1, x + 3))}
            </div>
          ))}
        </div>
        <div
          style={{
            width: SIZE * 7,
            overflow: "hidden",
            position: "relative",
            height: SIZE * (7 - 1),
          }}
          onKeyDown={onKeyDown}
          tabIndex={0}
          ref={elRef}
        >
          {getDates(animScroll).map((week) => (
            <div
              key={week.key}
              style={{
                cursor: "default",
                userSelect: "none",
                display: "flex",
                position: "absolute",
                height: SIZE,
                width: SIZE * 7,
                top: 0,
                left: 0,

                transform: `translate3d(0,${
                  week.offsetY - (((animScroll % SIZE) + SIZE) % SIZE)
                }px,0)`,
              }}
            >
              {week.days.map((day) => (
                <Day
                  {...{
                    fontSize: 14,
                    dayNumber: day.dayNumber,
                    isSelected: day.dayNumber === dayNumber,
                    isMonth:
                      getMonthFromDayNumber(day.dayNumber) ===
                      getMonthFromDayNumber(dayNumber),
                    key: day.key,
                    callbacksRef,
                    todaysDayNumber: _todaysDayNumber,
                  }}
                />
              ))}
            </div>
          ))}
        </div>
        <button
          style={{ marginTop: 16 }}
          onClick={() => {
            if (typeof onChange === "function") {
              onChange(_todaysDayNumber);
            }
            setDayNumber(_todaysDayNumber);
          }}
        >
          Today
        </button>
      </>
    );
  }
);

const Day = memo(
  ({
    dayNumber,
    isSelected,
    isMonth,
    dayKey,
    callbacksRef,
    todaysDayNumber,
  }: any) => {
    const { onChange, setDayNumber } = callbacksRef.current;
    return (
      <div
        // autoFocus={isSelected}
        //   tabIndex={isSelected ? 1 : -1}
        data-day-number={dayNumber}
        onClick={() => {
          if (typeof onChange === "function") {
            onChange(dayNumber);
          }
          setDayNumber(dayNumber);
        }}
        style={{
          color: isMonth ? "#000" : "#ccc",
          width: SIZE,
          height: `${SIZE}px`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          fontSize: 9,
          fontWeight: todaysDayNumber === dayNumber ? "bold" : "normal",
          //boxShadow: "inset 0px 0px 0px 1px #000",
          backgroundColor: isSelected ? "#ccc" : "transparent",
        }}
      >
        <div style={{ fontSize: 16 }}>
          {new Date(dayNumber * DAY)
            .toISOString()
            .replace(/T.*/, "")
            .replace(/.*-/, "")}
        </div>
        {false && (
          <div>
            {new Date(dayNumber * DAY)
              .toISOString()
              .replace(/T.*/, "")
              .replace(/-\d\d$/, "")}
          </div>
        )}
      </div>
    );
  }
);
