import DashBoardWrap from "components/common/DashBoardWrap";
import moment from "moment";
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import NumberUtils from "utils/NumberUtils";
import Calendar from "components/common/Calendar/Calendar";
import "react-calendar/dist/Calendar.css";
import { useHistory } from "react-router";

import up_line from "assets/icons/up_line.png";
import down_line from "assets/icons/down_line.png";
import { FranchiseDashBoardData } from "modules/store/types";
import info from "assets/icons/info.png";
import DashBoardTitleWrap from "components/common/DashBoardTitleWrap";

interface StoreDashboardProps {
  franchiseDashboard: FranchiseDashBoardData | null;
  getDashboard: (day: string) => void;
  timeNow: Date;
}

const StoreDashboard: React.FC<StoreDashboardProps> = ({
  franchiseDashboard,
  getDashboard,
  timeNow,
}) => {
  const days = ["일", "월", "화", "수", "목", "금", "토"];
  const history = useHistory();

  const [graphData, setGraphData] = useState({
    series: [
      {
        name: "세탁물",
        data: [0],
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "45%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },

      colors: ["#1B2253"],
      xaxis: {
        categories: [
          moment(new Date()).format("MM/DD") +
            " (" +
            days[moment(new Date()).day()] +
            ")",
        ],
      },
      yaxis: {
        title: {
          text: "",
        },
      },
      grid: {
        borderColor: "#f1f1f1",
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return val + " 수량";
          },
        },
      },
    },
  });

  const [date, setDate] = useState<moment.Moment>(() => moment());

  useEffect(() => {
    if (franchiseDashboard) {
      let arr = [];
      let xa = [];
      for (
        let i = 0;
        i < franchiseDashboard.dateAndLaundryCountList.length;
        i++
      ) {
        arr.push(franchiseDashboard.dateAndLaundryCountList[i].count);
        xa.push(
          moment(franchiseDashboard.dateAndLaundryCountList[i].date).format(
            "MM/DD"
          ) +
            " (" +
            days[
              moment(franchiseDashboard.dateAndLaundryCountList[i].date).day()
            ] +
            ")"
        );
      }
      // data.series[0].data = [...arr];
      // data.options.xaxis.categories = [...xa];

      setGraphData({
        series: [
          {
            name: "세탁물",
            data: [...arr],
          },
        ],
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "45%",
              endingShape: "rounded",
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },

          colors: ["#1B2253"],
          xaxis: {
            categories: [...xa],
          },
          yaxis: {
            title: {
              text: "",
            },
          },
          grid: {
            borderColor: "#f1f1f1",
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val: any) {
                return val + " 수량";
              },
            },
          },
        },
      });
    }
  }, [franchiseDashboard]);

  const dateChange = (e: moment.Moment) => {
    setDate(e);
    getDashboard(moment(e).format("YYYY-MM-DD"));
    // console.log(moment(e).format("YYYY-MM-DD"));
  };
  return (
    <>
      <DashBoardTitleWrap>
        <div className="headers">
          <h3>지점 대시보드</h3>
        </div>
      </DashBoardTitleWrap>
      <DashBoardWrap>
        <div className={"top"}>
          <div className={"graphWrap"}>
            <div className={"graph"}>
              <div>
                <div style={{ color: "#1B2253" }}>세탁현황</div>
              </div>
              <ReactApexChart
                options={graphData.options}
                series={graphData.series}
                type="bar"
                height={320}
                className="apex-charts"
              />
              <div className="timeNow">
                최근 갱신 일 : {moment(timeNow).format("YYYY-MM-DD HH:mm:SS")}
              </div>
            </div>
            <div className={"count store"}>
              <div>
                <h4>세탁(건)</h4>
                <h3>
                  {NumberUtils.numberToComma(
                    franchiseDashboard
                      ? franchiseDashboard.before7DaysTotalCount
                      : 0
                  )}
                </h3>
              </div>
              <div>
                <h4>세탁물(수량)</h4>
                <h3>
                  {NumberUtils.numberToComma(
                    franchiseDashboard
                      ? franchiseDashboard.before7DaysTotalAmount
                      : 0
                  )}
                </h3>
              </div>
            </div>
          </div>
          <div className={"calendar"}>
            <div className="infoBox">
              <div className="info">
                <span>서비스가이드</span>
                <span>
                  <img src={info} alt="" />
                </span>
              </div>
              <div className="infoGuide">
                캘린더에서 선택한 날짜를 기준으로 지난 일주일간의
                <br /> 데이터가 세탁현황에 표시됩니다.
              </div>
            </div>
            <h3>캘린더</h3>
            <div>
              <Calendar dateChange={dateChange} date={date} />
            </div>
          </div>
        </div>
        <div className={"bottom"}>
          <div className={"laundry count"}>
            <div className="infoBox">
              <div className="info">
                <span>서비스가이드</span>
                <span>
                  <img src={info} alt="" />
                </span>
              </div>
              <div className="infoGuide">
                오늘과 누적 세탁현황을 확인할 수 있으며, 지난주
                <br />
                대비 세탁현황을 비교할 수 있습니다.
              </div>
            </div>
            <div className={"nums"}>
              <div>
                <h3>총 세탁</h3>
                <div className={"today"}>
                  <span>오늘</span>
                  <strong>
                    {NumberUtils.numberToComma(
                      franchiseDashboard
                        ? franchiseDashboard.laundryTodayCount
                        : 0
                    )}
                  </strong>
                  <span>건</span>
                </div>
                <div className={"accumulate"}>
                  <span>누적</span>
                  <strong>
                    {NumberUtils.numberToComma(
                      franchiseDashboard
                        ? franchiseDashboard.laundryAccumulationCount
                        : 0
                    )}
                  </strong>
                  <span>건</span>
                </div>
              </div>
              <div>
                <h3>총 세탁 수량</h3>
                <div className={"today"}>
                  <span>오늘</span>
                  <strong>
                    {NumberUtils.numberToComma(
                      franchiseDashboard
                        ? franchiseDashboard.laundryTodayAmount
                          ? franchiseDashboard.laundryTodayAmount
                          : 0
                        : 0
                    )}
                  </strong>
                  <span>개</span>
                </div>
                <div className={"accumulate"}>
                  <span>누적</span>
                  <strong>
                    {NumberUtils.numberToComma(
                      franchiseDashboard
                        ? franchiseDashboard.laundryAccumulationAmount
                        : 0
                    )}
                  </strong>
                  <span>개</span>
                </div>
              </div>
            </div>
            <div className={"comparison"}>
              <h3>지난주 대비</h3>
              <div className={"store"}>
                <div>
                  <figure>
                    <img
                      src={
                        franchiseDashboard
                          ? franchiseDashboard.weekContrastCount >= 0
                            ? up_line
                            : down_line
                          : up_line
                      }
                      alt=""
                    />
                  </figure>
                  <div>
                    <span>세탁(건)</span>
                    <strong>
                      {franchiseDashboard
                        ? franchiseDashboard.weekContrastCount >= 0
                          ? "+ " +
                            NumberUtils.numberToComma(
                              franchiseDashboard.weekContrastCount
                            )
                          : NumberUtils.numberToComma(
                              franchiseDashboard.weekContrastCount
                            )
                        : 0}
                    </strong>
                  </div>
                </div>
                <div>
                  <figure>
                    <img
                      src={
                        franchiseDashboard
                          ? franchiseDashboard.weekContrastAmount >= 0
                            ? up_line
                            : down_line
                          : up_line
                      }
                      alt=""
                    />
                  </figure>
                  <div>
                    <span>세탁물(개)</span>
                    <strong>
                      {franchiseDashboard
                        ? franchiseDashboard.weekContrastAmount >= 0
                          ? "+ " +
                            NumberUtils.numberToComma(
                              franchiseDashboard.weekContrastAmount
                            )
                          : NumberUtils.numberToComma(
                              franchiseDashboard.weekContrastAmount
                            )
                        : 0}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"laundryBoard"}>
            <div className="infoBox">
              <div className="info">
                <span>서비스가이드</span>
                <span>
                  <img src={info} alt="" />
                </span>
              </div>
              <div className="infoGuide infoCenter">
                최신 등록된 게시글을 확인하실 수 있습니다.
              </div>
            </div>
            <h3>세탁 게시판</h3>
            <div className={"dataWrap"}>
              {franchiseDashboard &&
                franchiseDashboard.boardList.map((val, index) => (
                  <div
                    className={"listWrap"}
                    key={index}
                    onClick={() => {
                      history.push(`/store/board/${val.id}`);
                    }}
                  >
                    <div className={"role"}>
                      <h4 className="boardTitle">{val.writer.nickname}</h4>
                      <p>
                        {val.writer.roles[0] === "ROLE_MASTER_ADMIN" && "본사"}
                        {val.writer.roles[0] === "ROLE_MASTER" && "브랜드"}
                        {val.writer.roles[0] === "ROLE_FRANCHISE" && "지점"}
                        {val.writer.roles[0] === "ROLE_FACTORY" && "세탁공장"}
                      </p>
                    </div>
                    <div className="boardTitle">{val.title}</div>
                  </div>
                ))}
            </div>
            <div className={"more"}>
              <button
                onClick={() => {
                  history.push("/store/board");
                }}
              >
                more
              </button>
            </div>
          </div>
          <div className={"noticeBoard"}>
            <div className="infoBox">
              <div className="info">
                <span>서비스가이드</span>
                <span>
                  <img src={info} alt="" />
                </span>
              </div>
              <div className="infoGuide infoCenter">
                최신 등록된 공지사항을 확인하실 수 있습니다.
              </div>
            </div>
            <h3>공지사항</h3>
            <div className={"dataWrap"}>
              {franchiseDashboard &&
                franchiseDashboard.franchiseIdResponseList.map((val, index) => (
                  <React.Fragment key={val.id}>
                    {index < 5 && (
                      <div
                        className={"listWrap notice"}
                        onClick={() => {
                          history.push(`/store/notice/${val.id}`);
                        }}
                      >
                        <div className={"role"}>
                          <h4>{val.title}</h4>
                          <p>{val.writer.nickname}</p>
                        </div>
                        <div>{moment(val.createdDate).format("YY.MM.DD")}</div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
            </div>
            <div className={"more"}>
              <button
                onClick={() => {
                  history.push("/store/notice");
                }}
              >
                more
              </button>
            </div>
          </div>
        </div>
      </DashBoardWrap>
    </>
  );
};

export default StoreDashboard;
