import React, { useState, useEffect, ChangeEvent } from "react";
import styled from "styled-components";
import { Palette, ThemeColor } from "styles/palette";
import Paging from "components/common/Paging";
import Button from "components/common/Button";
import {
  FactoryGetList,
  FactoryMatchedFranchiseData,
  MatchedFranchiseData,
} from "modules/factory/types";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import LaundrySearch from "components/common/LaundrySearch";
import NumberUtils from "utils/NumberUtils";
import LaundrySignUpSearch from "components/common/LaundrySignUpSearch";

import nonSelect from "assets/arrow/non_select.svg";
import downSelect from "assets/arrow/down_select.svg";
import upSelect from "assets/arrow/up_select.svg";

interface FactoryLaundrySignUpStatusProps {
  matchedFranchiseList: FactoryMatchedFranchiseData | null;
  matchedList: (
    page: number,
    data: FactoryGetList | null,
    sort?: string
  ) => void;
  laundryRequest: (
    id: number,
    bagCount: number,
    page: number,
    data: FactoryGetList | null
  ) => void;
}

const FactoryLaundrySignUpStatus: React.FC<FactoryLaundrySignUpStatusProps> = ({
  matchedFranchiseList,
  matchedList,
  laundryRequest,
}) => {
  const [searchData, setSearchData] = useState<FactoryGetList>({
    query: "",
    dropBox: "",
    startDate: "",
    endDate: "",
    size: 10,
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [newItems, setNewItems] = useState<MatchedFranchiseData[] | null>(null);

  const [sortCheck, setSortCheck] = useState<string>("");
  const [moveSortType, setMoveSortType] = useState<string>("");

  useEffect(() => {
    setNewItems(matchedFranchiseList?.list ?? []);
  }, [matchedFranchiseList]);

  const amountHandler = (e: ChangeEvent<HTMLInputElement>, id: number) => {
    const { value } = e.target;
    setNewItems(
      newItems!.map((item) =>
        item.id === id ? { ...item, amount: parseInt(value, 10) } : item
      )
    );
  };

  const onSearch = (page: number, data: FactoryGetList) => {
    setSearchData(data);
    matchedList(page, data, moveSortType);
  };

  const pageMove = (page: number) => {
    setCurrentPage(page);
    matchedList(page, searchData ?? null, moveSortType);
  };

  const selectShowEntries = (size: number) => {
    searchData.size = size;
    matchedList(1, searchData ?? null, moveSortType);
  };

  const onSubmit = (id: number) => {
    const data = newItems?.find((item) => item.id === id);
    // if (data?.amount && data?.amount !== 0) {
    laundryRequest(id, data?.amount ?? 0, currentPage, searchData ?? null);
    // }
  };

  const [listNumber, setListNumber] = useState<number[]>([]);
  useEffect(() => {
    setListNumber(
      NumberUtils.numberToList(
        matchedFranchiseList?.currentPage ?? 0,
        matchedFranchiseList?.totalCount ?? 0,
        searchData.size ?? 10
      )
    );
  }, [matchedFranchiseList, searchData]);

  const onSortList = (sortType: string) => {
    if (sortCheck === "" || sortCheck === "ASC") setSortCheck("DESC");
    if (sortCheck === "DESC") setSortCheck("ASC");

    let sort = sortCheck === "DESC" ? `${sortType}desc` : `${sortType}asc`;

    setMoveSortType(sort);
    matchedList(currentPage, searchData ?? null, sort);
  };

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      fontWeight: 700,
    },
    tableRow: {
      height: 10,
      padding: 0,
      minHeight: 0,
    },
  });
  const classes = useStyles();

  return (
    <FactoryLaundrySignUpStatusBlock>
      <div className="headers">
        <h3>세탁 신청</h3>
      </div>
      <div className="tableSelect">
        <div>
          <span>Show </span>
          <select
            onChange={(e) => selectShowEntries(parseInt(e.target.value, 10))}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
          <span> entries</span>
        </div>
        <div className="topWrapper">
          <span>Search: </span>
          <div>
            <LaundrySignUpSearch
              role={"ROLE_MASTER_ADMIN"}
              onSearch={onSearch}
              size={searchData.size ?? 10}
            />
          </div>
        </div>
      </div>
      <div className="tableWrapper">
        <TableContainer component={Paper} className="tables">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: 900,
                    width: "70px",
                  }}
                >
                  순번
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900 }}>
                  브랜드
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "16%" }}
                  className="sortList"
                  onClick={() => onSortList("fr")}
                >
                  지점명
                  <img
                    className="sortSelect"
                    src={
                      sortCheck === ""
                        ? nonSelect
                        : sortCheck === "DESC"
                        ? downSelect
                        : upSelect
                    }
                    alt=""
                  />
                  {/* {sortCheck === "ASC" && <span className="sortDown">▼</span>}
                  {sortCheck === "DESC" && <span className="sortUp">▲</span>} */}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "15%" }}
                >
                  지점담당자
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "15%" }}
                >
                  담당자연락처
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "15%" }}
                >
                  세탁신청(포대/카트)
                </TableCell>
                <TableCell style={{ width: "10%" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="tableBody">
              {newItems?.map((list, index) => (
                <TableRow
                  // className="tableHover"
                  key={list.id}
                  className={classes.tableRow}
                >
                  <TableCell component="th" scope="row" align="center">
                    {listNumber[index]}
                  </TableCell>
                  <TableCell align="center">{list.brandName}</TableCell>
                  <TableCell align="center">{list.companyName}</TableCell>
                  <TableCell align="center">{list.manager.nickname}</TableCell>
                  <TableCell align="center">{list.manager.phone}</TableCell>
                  <TableCell align="center">
                    <input
                      className="bagCountInput"
                      value={list.amount ? list.amount : undefined}
                      onChange={(e) => amountHandler(e, list.id)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      theme={ThemeColor.primary}
                      buttonSize="s"
                      onClick={() => onSubmit(list.id)}
                    >
                      세탁신청
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Paging
          listAction={pageMove}
          listCount={matchedFranchiseList?.totalPage ?? 0}
          currentPage={matchedFranchiseList?.currentPage}
          totalCount={matchedFranchiseList?.totalCount}
          size={searchData.size ?? 10}
        ></Paging>
      </div>
    </FactoryLaundrySignUpStatusBlock>
  );
};

export default FactoryLaundrySignUpStatus;

const FactoryLaundrySignUpStatusBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);

  .tables {
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  .bagCountInput {
    border: 1px solid #dee2e6;
    width: 120px;
  }
  .tableHover:hover {
    background-color: #eee;
  }
  .topWrapper {
    display: flex;
    justify-content: flex-end;
    & > span {
      font-size: 14px;
      line-height: 37px;
      margin-right: 5px;
    }
    & > div {
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > div {
        margin: 0;
        margin-left: 1rem;
      }
    }
  }
  .tableSelect {
    height: 70px;
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: space-between;
    & > div {
      color: #999;
      height: 40px;
      /* overflow: hidden; */
      & > span {
        font-size: 14px;
        line-height: 37px;
      }
      & > select {
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        transition: ease-in-out;
        transition-duration: 0.1s;
        border-radius: 3px;
      }
      & > select:hover {
        border-bottom: 2px solid ${Palette.primary};
      }
    }
  }
  .tableWrapper {
    min-width: 51rem;
    padding-bottom: 1.5rem;
    .newIcons {
      padding: 2px 7px;
      background-color: red;
      margin-left: 7px;
      color: #fff;
      border-radius: 2px;
      font-size: 12px;
    }
  }
  .tableBody {
    tr {
      height: 53px !important;
      td {
        padding: 0;
      }
    }
  }
  .sortList {
    cursor: pointer;
  }
  .sortSelect {
    width: 13px;
    height: 13px;
    margin-left: 5px;
    position: relative;
    top: 2px;
  }
  .sortUp {
    color: red;
  }
  .sortDown {
    color: blue;
  }
`;
