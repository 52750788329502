import { AxiosResponse } from "axios";
import * as adminApi from "api/adminApi";
import {
  AdminDashBoardData,
  AdminAction,
  AdminBrandDetail,
  AdminBrandList,
  AdminFactoryDetail,
  AdminFactoryList,
  AdminGetList,
  AdminLaundryDetail,
  AdminLaundryList,
  AdminMatchingData,
  AdminMatchingList,
  AdminPaymentData,
  AdminPaymentDeatilData,
  AdminPaymentDetail,
  AdminPaymentList,
  AdminRegistration,
  AdminStoreDetail,
  AdminStoreList,
  IAdminState,
  AdminSpecificationsData,
  AdminFranchiseContract,
  AdminPayManagement,
  AdminTagGetList,
  AdminLaundryTagList,
  AdminLaundryItemList,
  AdminUserAccessHistory,
  AdminCalculateResTypes,
  AdminCalculateDetailResType,
  AdminCalculateModifyReqType,
  AdminAssetItemReqType,
} from "./types";
import { call, put, takeEvery } from "redux-saga/effects";
import { LaundryDataListDetail } from "modules/factory/types";
import { ProfileModify } from "modules/user/types";
import { CalculateListSearchTypes } from "interface/Admin/CalculateListTypes";
import { type } from "os";

const GET_ADMIN_DASHBOARD = "GET_ADMIN_DASHBOARD" as const;
const GET_ADMIN_DASHBOARD_SUCCESS = "GET_ADMIN_DASHBOARD_SUCCESS" as const;

const GET_ADMIN_LAUNDRY_LIST = "GET_ADMIN_LAUNDRY_LIST" as const;
const GET_ADMIN_LAUNDRY_LIST_SUCCESS = "GET_ADMIN_LAUNDRY_LIST_SUCCESS" as const;

const GET_ADMIN_LAUNDRY_DETAIL = "GET_ADMIN_LAUNDRY_DETAIL" as const;
const GET_ADMIN_LAUNDRY_DETAIL_SUCCESS = "GET_ADMIN_LAUNDRY_DETAIL_SUCCESS" as const;

const GET_ADMIN_PAYMENT_LIST = "GET_ADMIN_PAYMENT_LIST" as const;
const GET_ADMIN_PAYMENT_LIST_SUCCESS = " GET_ADMIN_PAYMENT_LIST_SUCCESS" as const;

const GET_ADMIN_PAYMENT_DETAIL = "GET_ADMIN_PAYMENT_DETAIL" as const;
const GET_ADMIN_PAYMENT_DETAIL_SUCCESS = "GET_ADMIN_PAYMENT_DETAIL_SUCCESS" as const;

const POST_ADMIN_BRAND_REGISTRATION = "POST_ADMIN_BRAND_REGISTRATION" as const;
const POST_ADMIN_BRAND_REGISTRATION_SUCCESS = "POST_ADMIN_BRAND_REGISTRATION_SUCCESS" as const;

const POST_ADMIN_FACTORY_REGISTRATION = "POST_ADMIN_FACTORY_REGISTRATION" as const;
const POST_ADMIN_FACTORY_REGISTRATION_SUCCESS = "POST_ADMIN_FACTORY_REGISTRATION_SUCCESS" as const;

const GET_ADMIN_FACTORY_LIST = "GET_ADMIN_FACTORY_LIST" as const;
const GET_ADMIN_FACTORY_LIST_SUCCESS = "GET_ADMIN_FACTORY_LIST_SUCCESS" as const;
const GET_ADMIN_FACTORY_DETAIL = "GET_ADMIN_FACTORY_DETAIL" as const;
const GET_ADMIN_FACTORY_DETAIL_SUCCESS = "GET_ADMIN_FACTORY_DETAIL_SUCCESS" as const;

const GET_ADMIN_BRAND_DETAIL = "GET_ADMIN_BRAND_DETAIL" as const;
const GET_ADMIN_BRAND_DETAIL_SUCCESS = "GET_ADMIN_BRAND_DETAIL_SUCCESS" as const;
const GET_ADMIN_BRAND_LIST = "GET_ADMIN_BRAND_LIST" as const;
const GET_ADMIN_BRAND_LIST_SUCCESS = "GET_ADMIN_BRAND_LIST_SUCCESS" as const;
const GET_ADMIN_MATCHING_LIST = "GET_ADMIN_MATCHING_LIST" as const;
const GET_ADMIN_MATCHING_LIST_SUCCESS = "GET_ADMIN_MATCHING_LIST_SUCCESS" as const;
const PATCH_ADMIN_MATCHING_FACTORY = "PATCH_ADMIN_MATCHING_FACTORY" as const;
const PATCH_ADMIN_MATCHING_FACTORY_SUCCESS = "PATCH_ADMIN_MATCHING_FACTORY_SUCCESS" as const;

const GET_ADMIN_STORE_LIST = "GET_ADMIN_STORE_LIST" as const;
const GET_ADMIN_STORE_LIST_SUCCESS = "GET_ADMIN_STORE_LIST_SUCCESS" as const;

const GET_ADMIN_STORE_DETAIL = "GET_ADMIN_STORE_DETAIL" as const;
const GET_ADMIN_STORE_DETAIL_SUCCESS = "GET_ADMIN_STORE_DETAIL_SUCCESS" as const;

const GET_ADMIN_SPECIFICATIONS_LIST = "GET_ADMIN_SPECIFICATIONS_LIST" as const;
const GET_ADMIN_SPECIFICATIONS_LIST_SUCCESS = "GET_ADMIN_SPECIFICATIONS_LIST_SUCCESS" as const;

const GET_ADMIN_LAUNDRY_TABLE = "GET_ADMIN_LAUNDRY_TABLE" as const;
const GET_ADMIN_LAUNDRY_TABLE_SUCCESS = "GET_ADMIN_LAUNDRY_TABLE_SUCCESS" as const;

const POST_PW_INITIALIZATION = "POST_PW_INITIALIZATION" as const;

const PATCH_FACTORY_INFO = "PATCH_FACTORY_INFO" as const;
const PATCH_FRANCHISE_INFO = "PATCH_FRANCHISE_INFO" as const;
const PATCH_BRAND_INFO = "PATCH_BRAND_INFO" as const;
const GET_ID_DUPLICATE_CHECK = "GET_ID_DUPLICATE_CHECK" as const;
const GET_ID_DUPLICATE_CHECK_SUCCESS = "GET_ID_DUPLICATE_CHECK_SUCCESS" as const;
const GET_ID_DUPLICATE_CHECK_CANCLE = "GET_ID_DUPLICATE_CHECK_CANCLE" as const;

const GET_ADMIN_PAYMENT_LIST_DATA = "GET_ADMIN_PAYMENT_LIST_DATA" as const;
const GET_ADMIN_PAYMENT_LIST_DATA_SUCCESS = "GET_ADMIN_PAYMENT_LIST_DATA_SUCCESS" as const;

const GET_ADMIN_PAYMENT_FACTORY_LIST_DATA = "GET_ADMIN_PAYMENT_FACTORY_LIST_DATA" as const;
const GET_ADMIN_PAYMENT_FACTORY_LIST_DATA_SUCCESS =
  "GET_ADMIN_PAYMENT_FACTORY_LIST_DATA_SUCCESS" as const;

const GET_ADMIN_PAYMENT_BRAND_LIST_DATA = "GET_ADMIN_PAYMENT_BRAND_LIST_DATA" as const;
const GET_ADMIN_PAYMENT_BRAND_LIST_DATA_SUCCESS =
  "GET_ADMIN_PAYMENT_BRAND_LIST_DATA_SUCCESS" as const;

const GET_ADMIN_PAYMENT_DETAIL_BRAND_DATA = "GET_ADMIN_PAYMENT_DETAIL_BRAND_DATA" as const;
const GET_ADMIN_PAYMENT_DETAIL_BRAND_DATA_SUCCESS =
  "GET_ADMIN_PAYMENT_DETAIL_BRAND_DATA_SUCCESS" as const;

const GET_ADMIN_PAYMENT_DETAIL_FACTORY_DATA = "GET_ADMIN_PAYMENT_DETAIL_FACTORY_DATA" as const;
const GET_ADMIN_PAYMENT_DETAIL_FACTORY_DATA_SUCCESS =
  "GET_ADMIN_PAYMENT_DETAIL_FACTORY_DATA_SUCCESS" as const;

const POST_ADMIN_FRANCHISE_CONTRACT = "POST_ADMIN_FRANCHISE_CONTRACT" as const;

const GET_ADMIN_PAYMANAGEMENT_LIST = "GET_ADMIN_PAYMANAGEMENT_LIST" as const;
const GET_ADMIN_PAYMANAGEMENT_LIST_SUCCESS = "GET_ADMIN_PAYMANAGEMENT_LIST_SUCCESS" as const;

const PATCH_ADMIN_PAYMANAGEMENT_DATA = "PATCH_ADMIN_PAYMANAGEMENT_DATA" as const;

const PATCH_ADMIN_USERNAME_MODIFY = "PATCH_ADMIN_USERNAME_MODIFY" as const;
const PATCH_ADMIN_USERNAME_MODIFY_SUCCESS = "PATCH_ADMIN_USERNAME_MODIFY_SUCCESS" as const;

const GET_LAUNDRY_TAG_LIST = "GET_LAUNDRY_TAG_LIST" as const;
const GET_LAUNDRY_TAG_LIST_SUCCESS = "GET_LAUNDRY_TAG_LIST_SUCCESS" as const;

const GET_LAUNDRY_ITEM_LIST = "GET_LAUNDRY_ITEM_LIST" as const;
const GET_LAUNDRY_ITEM_LIST_SUCCESS = "GET_LAUNDRY_ITEM_LIST_SUCCESS" as const;

// 접속기록
const GET_USER_ACCESS_HISTORY = "GET_USER_ACCESS_HISTORY" as const;
const GET_USER_ACCESS_HISTORY_SUCCESS = "GET_USER_ACCESS_HISTORY_SUCCESS" as const;

// 정산관리 리스트
const GET_CALCULATE_LIST = "GET_CALCULATE_LIST" as const;
const GET_CALCULATE_LIST_SUCCESS = "GET_CALCULATE_LIST_SUCCESS" as const;

const GET_CALCULATE_DETAIL = "GET_CALCULATE_DETAIL" as const;
const GET_CALCULATE_DETAIL_SUCCESS = "GET_CALCULATE_DETAIL_SUCCESS" as const;

// 정산관리 매입 리스트
const GET_CALUCLATE_PURCHASE_LIST = "GET_CALUCLATE_PURCHASE_LIST" as const;
const GET_CALUCLATE_PURCHASE_LIST_SUCCESS = "GET_CALUCLATE_PURCHASE_LIST_SUCCESS" as const;

const GET_CALCULATE_PURCHASE_DETAIL = "GET_CALCULATE_PURCHASE_DETAIL" as const;
const GET_CALCULATE_PURCHASE_DETAIL_SUCCESS = "GET_CALCULATE_PURCHASE_DETAIL_SUCCESS" as const;

// 정산관리 매출 수정
const PATCH_CALCULATE = "PATCH_CALCULATE" as const;
// 정산관리 매입 수정
const PATCH_CALCULATE_PURCHASE = "PATCH_CALCULATE_PURCHASE" as const;

// 정산관리 리스트 리셋
const RESET_CALCULATE_LIST = "RESET_CALCULATE_LIST" as const;

// 자산 삭제
const DELETE_LAUNDRY_ITEM = "DELETE_LAUNDRY_ITEM" as const;

// 자산 등록
const POST_ADD_LAUNDRY_ITEM = "POST_ADD_LAUNDRY_ITEM" as const;

// 자산 정보 수정
const PATCH_MODIFY_LAUNDRY_ITEM = "PATCH_MODIFY_LAUNDRY_ITEM" as const;

// 브랜드 삭제
const DELETE_ADMIN_BRAND = "DELETE_ADMIN_BRAND" as const;

// 지점 삭제
const DELETE_ADMIN_FRANCHISE = "DELETE_ADMIN_FRANCHISE" as const;

export const getAdminDashboardAction = (day: string, keyType: string) => ({
  type: GET_ADMIN_DASHBOARD,
  day,
  keyType,
});
export const getAdminDashboardSuccessAction = (adminDashBoard: AdminDashBoardData) => ({
  type: GET_ADMIN_DASHBOARD_SUCCESS,
  adminDashBoard,
});

export const getAdminLaundryListAction = (page: number, data: AdminGetList | null) => ({
  type: GET_ADMIN_LAUNDRY_LIST,
  page,
  data,
});
export const getAdminLaundryListSuccessAction = (adminLaundryList: AdminLaundryList) => ({
  type: GET_ADMIN_LAUNDRY_LIST_SUCCESS,
  adminLaundryList,
});

export const getAdminLaundryDetailAction = (id: number) => ({
  type: GET_ADMIN_LAUNDRY_DETAIL,
  id,
});
export const getAdminLaundryDetailSuccessAction = (adminLaundryDetail: AdminLaundryDetail) => ({
  type: GET_ADMIN_LAUNDRY_DETAIL_SUCCESS,
  adminLaundryDetail,
});

export const getAdminPaymentListAction = (page: number, data: AdminGetList | null) => ({
  type: GET_ADMIN_PAYMENT_LIST,
  page,
  data,
});
export const getAdminPaymentListSuccessAction = (adminPaymentList: AdminPaymentList) => ({
  type: GET_ADMIN_PAYMENT_LIST_SUCCESS,
  adminPaymentList,
});

export const getAdminPaymentDetailAction = (id: number) => ({
  type: GET_ADMIN_PAYMENT_DETAIL,
  id,
});
export const getAdminPaymentDetailSuccessAction = (adminPaymentDetail: AdminPaymentDetail) => ({
  type: GET_ADMIN_PAYMENT_DETAIL_SUCCESS,
  adminPaymentDetail,
});

export const postAdminBrandRegistrationAction = (
  payload: AdminRegistration,
  data: AdminGetList
) => ({
  type: POST_ADMIN_BRAND_REGISTRATION,
  payload,
  data,
});
export const postAdminBrandRegistrationSuccessAction = () => ({
  type: POST_ADMIN_BRAND_REGISTRATION_SUCCESS,
});

export const getAdminBrandDetailAction = (id: number) => ({
  type: GET_ADMIN_BRAND_DETAIL,
  id,
});
export const getAdminBrandDetailSuccessAction = (adminBrandDetail: AdminBrandDetail) => ({
  type: GET_ADMIN_BRAND_DETAIL_SUCCESS,
  adminBrandDetail,
});

export const getAdminBrandListAction = (page: number, data: AdminGetList | null) => ({
  type: GET_ADMIN_BRAND_LIST,
  page,
  data,
});
export const getAdminBrandListSuccessAction = (adminBrandList: AdminBrandList) => ({
  type: GET_ADMIN_BRAND_LIST_SUCCESS,
  adminBrandList,
});

export const postAdminFactoryRegistrationAction = (payload: AdminRegistration) => ({
  type: POST_ADMIN_FACTORY_REGISTRATION,
  payload,
});

export const postAdminFactoryRegistrationSuccessAction = () => ({
  type: POST_ADMIN_FACTORY_REGISTRATION_SUCCESS,
});

export const getAdminFactoryListAction = (page: number, data: AdminGetList | null) => ({
  type: GET_ADMIN_FACTORY_LIST,
  page,
  data,
});
export const getAdminFactoryListSuccessAction = (adminFactoryList: AdminFactoryList) => ({
  type: GET_ADMIN_FACTORY_LIST_SUCCESS,
  adminFactoryList,
});

export const getAdminFactoryDetailAction = (id: number) => ({
  type: GET_ADMIN_FACTORY_DETAIL,
  id,
});
export const getAdminFactoryDetailSuccessAction = (adminFactoryDetail: AdminFactoryDetail) => ({
  type: GET_ADMIN_FACTORY_DETAIL_SUCCESS,
  adminFactoryDetail,
});

export const getAdminStoreListAction = (page: number, data: AdminGetList | null) => ({
  type: GET_ADMIN_STORE_LIST,
  page,
  data,
});
export const getAdminStoreListSuccessAction = (adminStoreList: AdminStoreList) => ({
  type: GET_ADMIN_STORE_LIST_SUCCESS,
  adminStoreList,
});

export const getAdminStoreDetailAction = (id: number) => ({
  type: GET_ADMIN_STORE_DETAIL,
  id,
});
export const getAdminStoreDetailSuccessAction = (adminStoreDetail: AdminStoreDetail) => ({
  type: GET_ADMIN_STORE_DETAIL_SUCCESS,
  adminStoreDetail,
});

export const getAdminMatchingListAction = () => ({
  type: GET_ADMIN_MATCHING_LIST,
});
export const getAdminMatchingListSuccessAction = (adminMatchingList: AdminMatchingList[]) => ({
  type: GET_ADMIN_MATCHING_LIST_SUCCESS,
  adminMatchingList,
});
export const patchAdminMatchingFactoryAction = (payload: AdminMatchingData, page: number) => ({
  type: PATCH_ADMIN_MATCHING_FACTORY,
  payload,
  page,
});
export const patchAdminMatchingFactorySuccessAction = () => ({
  type: PATCH_ADMIN_MATCHING_FACTORY_SUCCESS,
});

export const getAdminSpecificationsListAction = (
  id: number | null,
  page: number,
  data: AdminGetList | null
) => ({
  type: GET_ADMIN_SPECIFICATIONS_LIST,
  id,
  page,
  data,
});
export const getAdminSpecificationsListSuccessAction = (
  adminSpecificationsData: AdminSpecificationsData
) => ({
  type: GET_ADMIN_SPECIFICATIONS_LIST_SUCCESS,
  adminSpecificationsData,
});

export const patchAdminUserNameModifyAction = (
  id: number,
  userName: string,
  page: number,
  data: AdminGetList | null,
  modiType: string
) => ({
  type: PATCH_ADMIN_USERNAME_MODIFY,
  id,
  userName,
  page,
  data,
  modiType,
});

export const patchAdminUserNameModifySuccessAction = () => ({
  type: PATCH_ADMIN_USERNAME_MODIFY_SUCCESS,
});

export const getAdminLaundryTableAction = (id: number, startDate: string, endDate: string) => ({
  type: GET_ADMIN_LAUNDRY_TABLE,
  id,
  startDate,
  endDate,
});
export const getAdminLaundryTableSuccessAction = (
  adminLaundryTableData: LaundryDataListDetail[]
) => ({
  type: GET_ADMIN_LAUNDRY_TABLE_SUCCESS,
  adminLaundryTableData,
});

export const postPWInitializationAction = (id: number) => ({
  type: POST_PW_INITIALIZATION,
  id,
});

export const patchFactoryInfoAction = (
  id: number,
  payload: ProfileModify,
  page: number,
  data: AdminGetList | null
) => ({
  type: PATCH_FACTORY_INFO,
  id,
  payload,
  page,
  data,
});

export const patchFranchiseInfoAction = (
  id: number,
  payload: ProfileModify,
  page: number,
  data: AdminGetList | null
) => ({
  type: PATCH_FRANCHISE_INFO,
  id,
  payload,
  page,
  data,
});

export const patchBrandInfoAction = (
  id: number,
  payload: ProfileModify,
  page: number,
  data: AdminGetList | null
) => ({
  type: PATCH_BRAND_INFO,
  id,
  payload,
  page,
  data,
});

export const getIdDuplicateCheckAction = (id: string) => ({
  type: GET_ID_DUPLICATE_CHECK,
  id,
});
export const getIdDuplicateCheckSuccessAction = (adminIdDuplicateCheck: string) => ({
  type: GET_ID_DUPLICATE_CHECK_SUCCESS,
  adminIdDuplicateCheck,
});
export const getIdDuplicationCheckCancelAction = () => ({
  type: GET_ID_DUPLICATE_CHECK_CANCLE,
});

export const getAdminPaymentListDataAction = (data: AdminGetList | null) => ({
  type: GET_ADMIN_PAYMENT_LIST_DATA,
  data,
});
export const getAdminPaymentListDataSuccessAction = (adminPaymentData: AdminPaymentData) => ({
  type: GET_ADMIN_PAYMENT_LIST_DATA_SUCCESS,
  adminPaymentData,
});

export const getAdminPaymentFactoryListDataAction = (page: number, data: AdminGetList | null) => ({
  type: GET_ADMIN_PAYMENT_FACTORY_LIST_DATA,
  page,
  data,
});

export const getAdminPaymentFactoryListDataSuccessAction = (
  adminPaymentFactoryData: AdminPaymentData
) => ({
  type: GET_ADMIN_PAYMENT_FACTORY_LIST_DATA_SUCCESS,
  adminPaymentFactoryData,
});

export const getAdminPaymentBrandListDataAction = (page: number, data: AdminGetList | null) => ({
  type: GET_ADMIN_PAYMENT_BRAND_LIST_DATA,
  page,
  data,
});

export const getAdminPaymentBrandListDataSuccessAction = (
  adminPaymentBrandData: AdminPaymentData
) => ({
  type: GET_ADMIN_PAYMENT_BRAND_LIST_DATA_SUCCESS,
  adminPaymentBrandData,
});

export const getAdminPaymentDetailBrandDataAction = (id: number, data: AdminGetList | null) => ({
  type: GET_ADMIN_PAYMENT_DETAIL_BRAND_DATA,
  id,
  data,
});
export const getAdminPaymentDetailBrandDataSuccessAction = (
  adminPaymentDeatilBrandData: AdminPaymentDeatilData
) => ({
  type: GET_ADMIN_PAYMENT_DETAIL_BRAND_DATA_SUCCESS,
  adminPaymentDeatilBrandData,
});

export const getAdminPaymentDetailFactoryDataAction = (id: number, data: AdminGetList | null) => ({
  type: GET_ADMIN_PAYMENT_DETAIL_FACTORY_DATA,
  id,
  data,
});
export const getAdminPaymentDetailFactoryDataSuccessAction = (
  adminPaymentDeatilFactoryData: AdminPaymentDeatilData
) => ({
  type: GET_ADMIN_PAYMENT_DETAIL_FACTORY_DATA_SUCCESS,
  adminPaymentDeatilFactoryData,
});

export const postAdminFranchiseContractAction = (
  payload: AdminFranchiseContract,
  page: number,
  data: AdminGetList
) => ({
  type: POST_ADMIN_FRANCHISE_CONTRACT,
  payload,
  page,
  data,
});

export const getAdminPayManagementListAction = (
  page: number,
  data: AdminGetList | null,
  completeCheck: string,
  drop: string
) => ({
  type: GET_ADMIN_PAYMANAGEMENT_LIST,
  page,
  data,
  completeCheck,
  drop,
});
export const getAdminPayManagementListSuccessAction = (
  adminPayManagementList: AdminPayManagement
) => ({
  type: GET_ADMIN_PAYMANAGEMENT_LIST_SUCCESS,
  adminPayManagementList,
});

export const patchAdminPaymanagementDataAction = (
  id: number,
  status: string,
  page: number,
  data: AdminGetList,
  completeCheck: string,
  drop: string
) => ({
  type: PATCH_ADMIN_PAYMANAGEMENT_DATA,
  id,
  status,
  page,
  data,
  completeCheck,
  drop,
});

export const getAdminLaundryTagListAction = (page: number, data: AdminTagGetList) => ({
  type: GET_LAUNDRY_TAG_LIST,
  page,
  data,
});
export const getAdminLaundryTagListSuccessAction = (adminLaundryTagList: AdminLaundryTagList) => ({
  type: GET_LAUNDRY_TAG_LIST_SUCCESS,
  adminLaundryTagList,
});

export const getAdminLaundryItemListAction = (page: number, frId: number, limit: number) => ({
  type: GET_LAUNDRY_ITEM_LIST,
  page,
  frId,
  limit,
});
export const getAdminLaundryItemListSuccessAction = (
  adminLaundryItemList: AdminLaundryItemList
) => ({
  type: GET_LAUNDRY_ITEM_LIST_SUCCESS,
  adminLaundryItemList,
});

//접속기록
export const getAdminUserAccessHistoryAction = (page: number, query: string, size: number) => ({
  type: GET_USER_ACCESS_HISTORY,
  page,
  query,
  size,
});

export const getAdminUserAccessHistorySuccessAction = (
  adminUserAccessHistory: AdminUserAccessHistory
) => ({
  type: GET_USER_ACCESS_HISTORY_SUCCESS,
  adminUserAccessHistory,
});

// 정산관리 리스트
export const getAdminCalculateListAction = (data: CalculateListSearchTypes) => ({
  type: GET_CALCULATE_LIST,
  data,
});
export const getAdminCalculateListSuccessAction = (data: AdminCalculateResTypes) => ({
  type: GET_CALCULATE_LIST_SUCCESS,
  data,
});

export const getAdminCalculateDetailAction = (id: number) => ({
  type: GET_CALCULATE_DETAIL,
  id,
});
export const getAdminCalculateDetailSuccessAction = (data: AdminCalculateDetailResType) => ({
  type: GET_CALCULATE_DETAIL_SUCCESS,
  data,
});

// 정산관리 매입 리스트
export const getAdminCalculatePurchaseListAction = (data: CalculateListSearchTypes) => ({
  type: GET_CALUCLATE_PURCHASE_LIST,
  data,
});
export const getAdminCalculatePurchaseListSuccessAction = (data: AdminCalculateResTypes) => ({
  type: GET_CALUCLATE_PURCHASE_LIST_SUCCESS,
  data,
});

export const getAdminCalculatePurchaseDetailAction = (id: number) => ({
  type: GET_CALCULATE_PURCHASE_DETAIL,
  id,
});
export const getAdminCalculatePurchaseDetailSuccessAction = (
  data: AdminCalculateDetailResType
) => ({
  type: GET_CALCULATE_PURCHASE_DETAIL_SUCCESS,
  data,
});

// 정산관리 매출 수정
export const patchCalculateAction = (data: AdminCalculateModifyReqType) => ({
  type: PATCH_CALCULATE,
  data,
});
// 정산관리 매입 수정
export const patchCalculatePurchaseAction = (data: AdminCalculateModifyReqType) => ({
  type: PATCH_CALCULATE_PURCHASE,
  data,
});

// 정산관리 리스트 리셋
export const resetCalculateListAction = () => ({
  type: RESET_CALCULATE_LIST,
});

/** 자산 삭제 */
export const deleteLaundryItemAction = (id: number, page: number, frId: number, size: number) => ({
  type: DELETE_LAUNDRY_ITEM,
  id,
  page,
  frId,
  size,
});

/** 자산 등록 */
export const postLaundryItemAction = (
  data: AdminAssetItemReqType,
  page: number,
  frId: number,
  size: number
) => ({
  type: POST_ADD_LAUNDRY_ITEM,
  data,
  page,
  frId,
  size,
});

/** 자산 정보 수정 */
export const patchModifyLaundryItemAction = (
  data: AdminAssetItemReqType,
  page: number,
  frId: number,
  size: number
) => ({
  type: PATCH_MODIFY_LAUNDRY_ITEM,
  data,
  page,
  frId,
  size,
});

/** 브랜드 삭제 */
export const deleteAdminBrandAction = (id: number, page: number, data: AdminGetList | null) => ({
  type: DELETE_ADMIN_BRAND,
  id,
  page,
  data,
});

/** 지점 삭제 */
export const deleteAdminFranchiseAction = (
  id: number,
  page: number,
  data: AdminGetList | null
) => ({
  type: DELETE_ADMIN_FRANCHISE,
  id,
  page,
  data,
});

function* getAdminDashboardSaga(action: ReturnType<typeof getAdminDashboardAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.getDashBoard, action.day, action.keyType);
    if (status !== "OK") return alert("에러");
    yield put(getAdminDashboardSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getAdminLaundryListSaga(action: ReturnType<typeof getAdminLaundryListAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.getAdminLaundryList, action.page, action.data);
    if (status !== "OK") return alert("에러");
    yield put(getAdminLaundryListSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getAdminLaundryDetailSaga(action: ReturnType<typeof getAdminLaundryDetailAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.getAdminLaundryDetail, action.id);
    if (status !== "OK") return alert("에러");
    yield put(getAdminLaundryDetailSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getAdminPaymentListSaga(action: ReturnType<typeof getAdminPaymentListAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.getAdminPaymentList, action.page, action.data);
    if (status !== "OK") return alert("에러");
    yield put(getAdminPaymentListSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getAdminPaymentDetailSaga(action: ReturnType<typeof getAdminPaymentDetailAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.getAdminPaymentDetail, action.id);
    if (status !== "OK") return alert("에러");
    yield put(getAdminPaymentDetailSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* postAdminBrandRegistrationSaga(
  action: ReturnType<typeof postAdminBrandRegistrationAction>
) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(adminApi.postAdminBrandRegistration, action.payload);
    if (status !== "CREATED") return alert("에러");
    yield put(postAdminBrandRegistrationSuccessAction());
    yield put(getAdminBrandListAction(1, action.data));
    yield alert(`${action.payload.companyName} 등록되었습니다.`);
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* postAdminFactoryRegistrationSaga(
  action: ReturnType<typeof postAdminFactoryRegistrationAction>
) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(adminApi.postAdminFactoryRegistration, action.payload);
    if (status !== "CREATED") return alert("에러");
    yield put(postAdminFactoryRegistrationSuccessAction());
    yield put(getAdminFactoryListAction(1, null));
    yield alert(`${action.payload.companyName} 등록되었습니다.`);
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getAdminBrandDetailSaga(action: ReturnType<typeof getAdminBrandDetailAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.getAdminBrandDetail, action.id);
    if (status !== "OK") return alert("에러");
    yield put(getAdminBrandDetailSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getAdminBrandListSaga(action: ReturnType<typeof getAdminBrandListAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.getAdminBrandList, action.page, action.data);
    if (status !== "OK") return alert("에러");
    yield put(getAdminBrandListSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getAdminFactoryList(action: ReturnType<typeof getAdminFactoryListAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.getAdminFactoryList, action.page, action.data);
    if (status !== "OK") return alert("에러");
    yield put(getAdminFactoryListSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getAdminFactoryDetailSaga(action: ReturnType<typeof getAdminFactoryDetailAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.getAdminFactoryDetail, action.id);
    if (status !== "OK") return alert("에러");
    yield put(getAdminFactoryDetailSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getAdminStoreListSaga(action: ReturnType<typeof getAdminStoreListAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.getAdminStoreList, action.page, action.data);
    if (status !== "OK") return alert("에러");
    yield put(getAdminStoreListSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getAdminStoreDetailSaga(action: ReturnType<typeof getAdminStoreDetailAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.getAdminStoreDetail, action.id);
    if (status !== "OK") return alert("에러");
    yield put(getAdminStoreDetailSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getAdminMatchingListSaga() {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.getAdminMatchingFactoryList);
    if (status !== "OK") return alert("에러");
    yield put(getAdminMatchingListSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* patchAdminMatchingFactorySaga(
  action: ReturnType<typeof patchAdminMatchingFactoryAction>
) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(adminApi.patchAdminMatchingFactory, action.payload);
    if (status !== "OK") return alert("에러");
    if (status === "OK") alert("반영되었습니다.");
    yield put(patchAdminMatchingFactorySuccessAction());
    yield put(getAdminStoreListAction(action.page, null));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getAdminPaymentListDataSaga(action: ReturnType<typeof getAdminPaymentListDataAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.getAdminPaymentListData, action.data);
    if (status !== "OK") return alert("에러");
    yield put(getAdminPaymentListDataSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getAdminPaymentDetailBrandDataSaga(
  action: ReturnType<typeof getAdminPaymentDetailBrandDataAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.getAdminPaymentDetailBrandData, action.id, action.data);
    if (status !== "OK") return alert("에러");
    yield put(getAdminPaymentDetailBrandDataSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getAdminPaymentDetailFactoryDataSaga(
  action: ReturnType<typeof getAdminPaymentDetailBrandDataAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      adminApi.getAdminPaymentDetailFactoryData,
      action.id,
      action.data
    );
    if (status !== "OK") return alert("에러");
    yield put(getAdminPaymentDetailFactoryDataSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* patchAdminUserNameModifySaga(action: ReturnType<typeof patchAdminUserNameModifyAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.patchAdminUserNameModify, action.id, action.userName);
    if (status !== "OK") return alert("에러");
    yield put(patchAdminUserNameModifySuccessAction());

    if (action.modiType === "brand") yield put(getAdminBrandListAction(action.page, action.data));
    if (action.modiType === "factory")
      yield put(getAdminFactoryListAction(action.page, action.data));
    if (action.modiType === "store") yield put(getAdminStoreListAction(action.page, action.data));

    yield alert("변경되었습니다");
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getAdminSpecificationsListSaga(
  action: ReturnType<typeof getAdminSpecificationsListAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      adminApi.getAdminSpecificationsList,
      action.id,
      action.page,
      action.data
    );
    if (status !== "OK") return alert("에러");

    yield put(getAdminSpecificationsListSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getAdminLaundryTableSaga(action: ReturnType<typeof getAdminLaundryTableAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      adminApi.getAdminLaundryTableData,
      action.id,
      action.startDate,
      action.endDate
    );
    if (status !== "OK") return alert("에러");
    yield put(getAdminLaundryTableSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* postPWInitializationSaga(action: ReturnType<typeof postPWInitializationAction>) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(adminApi.postPWInitialization, action.id);
    if (status !== "OK") return alert("에러");
    yield alert("비밀번호를 초기화 하였습니다");
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* patchFranchiseInfoSaga(action: ReturnType<typeof patchFranchiseInfoAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.patchFranchiseInfo, action.id, action.payload);
    if (status !== "OK") return alert("에러");
    yield put(getAdminStoreListAction(action.page, action.data));
    yield put(getAdminStoreDetailAction(action.id));
    yield alert("정보를 수정하였습니다");
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* patchFactoryInfoSaga(action: ReturnType<typeof patchFactoryInfoAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.patchFactoryInfo, action.id, action.payload);
    if (status !== "OK") return alert("에러");
    yield put(getAdminFactoryListAction(action.page, action.data));
    yield put(getAdminFactoryDetailAction(action.id));
    yield alert("정보를 수정하였습니다");
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* patchBrandInfoSaga(action: ReturnType<typeof patchBrandInfoAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.patchBrandInfo, action.id, action.payload);
    if (status !== "OK") return alert("에러");
    yield put(getAdminBrandListAction(action.page, action.data));
    yield put(getAdminBrandDetailAction(action.id));
    yield alert("정보를 수정하였습니다");
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getIdDuplicateCheckSaga(action: ReturnType<typeof getIdDuplicateCheckAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.getIdDuplicateCheck, action.id);
    if (data === "Y") alert("사용가능한 아이디 입니다.");
    if (data === "N") alert("중복된 아이디 입니다.");
    yield put(getIdDuplicateCheckSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}
function* getIdDuplicateCheckCancelSaga() {
  yield put(getIdDuplicateCheckSuccessAction("N"));
}

function* getAdminPaymentFactoryListDataSaga(
  action: ReturnType<typeof getAdminPaymentFactoryListDataAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      adminApi.getAdminPaymentFactoryListData,
      action.page,
      action.data
    );
    if (status !== "OK") return alert("에러");
    yield put(getAdminPaymentFactoryListDataSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getAdminPaymentBrandListDataSaga(
  action: ReturnType<typeof getAdminPaymentBrandListDataAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.getAdminPaymentBrandListData, action.page, action.data);
    if (status !== "OK") return alert("에러");
    yield put(getAdminPaymentBrandListDataSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* postAdminFranchiseContractSaga(
  action: ReturnType<typeof postAdminFranchiseContractAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.postAdminFranchiseContract, action.payload);
    if (status !== "OK") return alert("에러");
    yield put(getAdminStoreListAction(action.page, action.data));
    yield alert("계약설정이 반영되었습니다");
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getAdminPayManagementListSaga(
  action: ReturnType<typeof getAdminPayManagementListAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      adminApi.getAdminPaymanagementList,
      action.page,
      action.data,
      action.completeCheck,
      action.drop
    );
    if (status !== "OK") return alert("에러");
    yield put(getAdminPayManagementListSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* patchAdminPaymanagementDataSaga(
  action: ReturnType<typeof patchAdminPaymanagementDataAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.patchAdminPaymanagement, action.id, action.status);
    if (status !== "OK") return alert("에러");
    yield put(
      getAdminPayManagementListAction(action.page, action.data, action.completeCheck, action.drop)
    );
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getAdminLaundryTagListSaga(action: ReturnType<typeof getAdminLaundryTagListAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.getLaundryTagList, action.page, action.data);
    if (status !== "OK") return alert("에러");
    yield put(getAdminLaundryTagListSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getAdminLaundryItemListSaga(action: ReturnType<typeof getAdminLaundryItemListAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      adminApi.getLaundryItemList,
      action.page,
      action.frId,
      action.limit
    );
    if (status !== "OK") return alert("에러");
    yield put(getAdminLaundryItemListSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

// 접속기록
function* getAdminUserAccessHistorySaga(
  action: ReturnType<typeof getAdminUserAccessHistoryAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      adminApi.getAccessHistory,
      action.page,
      action.query,
      action.size
    );
    if (status !== "OK") return alert("에러");
    yield put(getAdminUserAccessHistorySuccessAction(data));
  } catch (error: any) {
    return alert(error.response.data.message);
  }
}

// 정산관리 리스트
function* getAdminCalculateListSaga(action: ReturnType<typeof getAdminCalculateListAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.getCalculateListApi, action.data);
    if (status !== "OK") return;
    yield put(getAdminCalculateListSuccessAction(data));
  } catch (error: any) {
    return alert(error.response.data.message);
  }
}

// 정산관리 매출 상세
function* getAdminCalculateDetailSaga(action: ReturnType<typeof getAdminCalculateDetailAction>) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.getCalculateDetailApi, action.id);
    if (status !== "OK") return;
    yield put(getAdminCalculateDetailSuccessAction(data));
  } catch (error: any) {
    return alert(error.response.data.message);
  }
}

// 정산관리 매입 리스트
function* getAdminCalculatePurchaseListSaga(
  action: ReturnType<typeof getAdminCalculatePurchaseListAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.getCalculatePurchaseListApi, action.data);
    if (status !== "OK") return;

    yield put(getAdminCalculatePurchaseListSuccessAction(data));
  } catch (error: any) {
    return alert(error.response.data.message);
  }
}

// 정산관리 매입 상세
function* getAdminCalculatePurchaseDetailSaga(
  action: ReturnType<typeof getAdminCalculatePurchaseDetailAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(adminApi.getCalculatePurchaseDetailApi, action.id);
    if (status !== "OK") return;
    yield put(getAdminCalculatePurchaseDetailSuccessAction(data));
  } catch (error: any) {
    return alert(error.response.data.message);
  }
}

// 정산관리 매출 수정
function* patchAdminCalculateSaga(action: ReturnType<typeof patchCalculateAction>) {
  try {
    const {
      data: { status, data },
    } = yield call(adminApi.patchCalculateApi, action.data);
  } catch (error: any) {
    return alert(error.response.data.message);
  }
}

// 정산관리 매입 수정
function* patchAdminCalculatePurchaseSaga(action: ReturnType<typeof patchCalculatePurchaseAction>) {
  try {
    const {
      data: { status, data },
    } = yield call(adminApi.patchCalculatePurchaseApi, action.data);
  } catch (error: any) {
    return alert(error.response.data.message);
  }
}

// 자산 삭제
function* deleteLaundryItemSaga(action: ReturnType<typeof deleteLaundryItemAction>) {
  try {
    const { status }: AxiosResponse = yield call(adminApi.deleteAssetDataApi, action.id);
    if (status !== 200) return;
    yield put(getAdminLaundryItemListAction(action.page, action.frId, action.size));
  } catch (error: any) {
    return alert(error.response.data.message);
  }
}

// 자산 등록
function* postLaundryItemSaga(action: ReturnType<typeof postLaundryItemAction>) {
  try {
    const { status }: AxiosResponse = yield call(adminApi.postAddAssetDataApi, action.data);
    if (status !== 200) return;
    yield put(getAdminLaundryItemListAction(action.page, action.frId, action.size));
  } catch (error: any) {
    return alert(error.response.data.message);
  }
}

// 자산 정보 수정
function* patchModifyLaundryItemSaga(action: ReturnType<typeof patchModifyLaundryItemAction>) {
  try {
    const { status }: AxiosResponse = yield call(adminApi.patchModifyAssetDataApi, action.data);
    if (status !== 200) return;
    yield put(getAdminLaundryItemListAction(action.page, action.frId, action.size));
  } catch (error: any) {
    return alert(error.response.data.message);
  }
}

// 브랜드 삭제
function* deleteAdminBrandSaga(action: ReturnType<typeof deleteAdminBrandAction>) {
  try {
    const { status }: AxiosResponse = yield call(adminApi.deleteAdminBrandApi, action.id);
    if (status !== 200) return;
    yield put(getAdminBrandListAction(action.page, action.data));
  } catch (error: any) {
    return alert(error.response.data.message);
  }
}

// 지점 삭제
function* deleteAdminFranchiseSaga(action: ReturnType<typeof deleteAdminFranchiseAction>) {
  try {
    const { status }: AxiosResponse = yield call(adminApi.deleteAdminFranchiseApi, action.id);
    if (status !== 200) return;
    yield put(getAdminStoreListAction(action.page, action.data));
  } catch (error: any) {
    return alert(error.response.data.message);
  }
}

const initialState: IAdminState = {
  adminDashBoard: null,
  adminBrandRegistration: null,
  adminStoreList: null,
  adminFactoryList: null,
  adminMatchingList: null,
  adminBrandDetail: null,
  adminBrandList: null,
  adminLaundryList: null,
  adminLaundryDetail: null,
  adminPaymentList: null,
  adminPaymentDetail: null,
  adminStoreDetail: null,
  adminFactoryDetail: null,
  adminSpecificationsData: null,
  adminLaundryTableData: null,
  adminPaymentData: null,
  adminPaymentFactoryData: null,
  adminPaymentBrandData: null,
  adminPaymentDeatilBrandData: null,
  adminPaymentDeatilFactoryData: null,
  adminIdDuplicateCheck: null,
  adminPayManagementList: null,
  adminLaundryTagList: null,
  adminLaundryItemList: null,
  adminUserAccessHistory: null,
  adminCalculateList: null,
  adminCalculateDetail: null,
};

export function* adminSaga() {
  yield takeEvery(GET_ADMIN_DASHBOARD, getAdminDashboardSaga);
  yield takeEvery(POST_ADMIN_BRAND_REGISTRATION, postAdminBrandRegistrationSaga);
  yield takeEvery(POST_ADMIN_FACTORY_REGISTRATION, postAdminFactoryRegistrationSaga);
  yield takeEvery(GET_ADMIN_BRAND_LIST, getAdminBrandListSaga);
  yield takeEvery(GET_ADMIN_BRAND_DETAIL, getAdminBrandDetailSaga);
  yield takeEvery(GET_ADMIN_FACTORY_LIST, getAdminFactoryList);
  yield takeEvery(GET_ADMIN_FACTORY_DETAIL, getAdminFactoryDetailSaga);
  yield takeEvery(GET_ADMIN_STORE_LIST, getAdminStoreListSaga);
  yield takeEvery(GET_ADMIN_STORE_DETAIL, getAdminStoreDetailSaga);
  yield takeEvery(GET_ADMIN_MATCHING_LIST, getAdminMatchingListSaga);
  yield takeEvery(PATCH_ADMIN_MATCHING_FACTORY, patchAdminMatchingFactorySaga);
  yield takeEvery(GET_ADMIN_LAUNDRY_LIST, getAdminLaundryListSaga);
  yield takeEvery(GET_ADMIN_LAUNDRY_DETAIL, getAdminLaundryDetailSaga);
  yield takeEvery(GET_ADMIN_PAYMENT_LIST, getAdminPaymentListSaga);
  yield takeEvery(GET_ADMIN_PAYMENT_DETAIL, getAdminPaymentDetailSaga);
  yield takeEvery(PATCH_ADMIN_USERNAME_MODIFY, patchAdminUserNameModifySaga);
  yield takeEvery(GET_ADMIN_SPECIFICATIONS_LIST, getAdminSpecificationsListSaga);
  yield takeEvery(GET_ADMIN_LAUNDRY_TABLE, getAdminLaundryTableSaga);
  yield takeEvery(POST_PW_INITIALIZATION, postPWInitializationSaga);
  yield takeEvery(PATCH_FRANCHISE_INFO, patchFranchiseInfoSaga);
  yield takeEvery(PATCH_FACTORY_INFO, patchFactoryInfoSaga);
  yield takeEvery(PATCH_BRAND_INFO, patchBrandInfoSaga);
  yield takeEvery(GET_ADMIN_PAYMENT_LIST_DATA, getAdminPaymentListDataSaga);
  yield takeEvery(GET_ADMIN_PAYMENT_DETAIL_BRAND_DATA, getAdminPaymentDetailBrandDataSaga);
  yield takeEvery(GET_ADMIN_PAYMENT_DETAIL_FACTORY_DATA, getAdminPaymentDetailFactoryDataSaga);
  yield takeEvery(GET_ADMIN_PAYMENT_FACTORY_LIST_DATA, getAdminPaymentFactoryListDataSaga);
  yield takeEvery(GET_ADMIN_PAYMENT_BRAND_LIST_DATA, getAdminPaymentBrandListDataSaga);
  yield takeEvery(GET_ID_DUPLICATE_CHECK, getIdDuplicateCheckSaga);
  yield takeEvery(GET_ID_DUPLICATE_CHECK_CANCLE, getIdDuplicateCheckCancelSaga);
  yield takeEvery(POST_ADMIN_FRANCHISE_CONTRACT, postAdminFranchiseContractSaga);
  yield takeEvery(GET_ADMIN_PAYMANAGEMENT_LIST, getAdminPayManagementListSaga);
  yield takeEvery(PATCH_ADMIN_PAYMANAGEMENT_DATA, patchAdminPaymanagementDataSaga);
  yield takeEvery(GET_LAUNDRY_TAG_LIST, getAdminLaundryTagListSaga);
  yield takeEvery(GET_LAUNDRY_ITEM_LIST, getAdminLaundryItemListSaga);
  yield takeEvery(GET_USER_ACCESS_HISTORY, getAdminUserAccessHistorySaga);
  yield takeEvery(GET_CALCULATE_LIST, getAdminCalculateListSaga);
  yield takeEvery(GET_CALCULATE_DETAIL, getAdminCalculateDetailSaga);
  yield takeEvery(GET_CALUCLATE_PURCHASE_LIST, getAdminCalculatePurchaseListSaga);
  yield takeEvery(GET_CALCULATE_PURCHASE_DETAIL, getAdminCalculatePurchaseDetailSaga);
  yield takeEvery(PATCH_CALCULATE, patchAdminCalculateSaga);
  yield takeEvery(PATCH_CALCULATE_PURCHASE, patchAdminCalculatePurchaseSaga);
  yield takeEvery(DELETE_LAUNDRY_ITEM, deleteLaundryItemSaga);
  yield takeEvery(POST_ADD_LAUNDRY_ITEM, postLaundryItemSaga);
  yield takeEvery(PATCH_MODIFY_LAUNDRY_ITEM, patchModifyLaundryItemSaga);
  yield takeEvery(DELETE_ADMIN_BRAND, deleteAdminBrandSaga);
  yield takeEvery(DELETE_ADMIN_FRANCHISE, deleteAdminFranchiseSaga);
}

function admin(state = initialState, action: AdminAction) {
  switch (action.type) {
    case GET_ADMIN_DASHBOARD_SUCCESS:
      return { ...state, adminDashBoard: action.adminDashBoard };
    case POST_ADMIN_BRAND_REGISTRATION_SUCCESS:
      return { ...state };
    case POST_ADMIN_FACTORY_REGISTRATION_SUCCESS:
      return { ...state };
    case GET_ADMIN_BRAND_LIST_SUCCESS:
      return { ...state, adminBrandList: action.adminBrandList };
    case GET_ADMIN_BRAND_DETAIL_SUCCESS:
      return { ...state, adminBrandDetail: action.adminBrandDetail };
    case GET_ADMIN_FACTORY_LIST_SUCCESS:
      return { ...state, adminFactoryList: action.adminFactoryList };
    case GET_ADMIN_FACTORY_DETAIL_SUCCESS:
      return { ...state, adminFactoryDetail: action.adminFactoryDetail };
    case GET_ADMIN_STORE_LIST_SUCCESS:
      return { ...state, adminStoreList: action.adminStoreList };
    case GET_ADMIN_STORE_DETAIL_SUCCESS:
      return { ...state, adminStoreDetail: action.adminStoreDetail };
    case GET_ADMIN_MATCHING_LIST_SUCCESS:
      return { ...state, adminMatchingList: action.adminMatchingList };
    case PATCH_ADMIN_MATCHING_FACTORY_SUCCESS:
      return { ...state };
    case GET_ADMIN_LAUNDRY_LIST_SUCCESS:
      return { ...state, adminLaundryList: action.adminLaundryList };
    case GET_ADMIN_LAUNDRY_DETAIL_SUCCESS:
      return { ...state, adminLaundryDetail: action.adminLaundryDetail };
    case GET_ADMIN_PAYMENT_LIST_SUCCESS:
      return { ...state, adminPaymentList: action.adminPaymentList };
    case GET_ADMIN_PAYMENT_DETAIL_SUCCESS:
      return { ...state, adminPaymentDetail: action.adminPaymentDetail };
    case PATCH_ADMIN_USERNAME_MODIFY_SUCCESS:
      return { ...state };
    case GET_ADMIN_SPECIFICATIONS_LIST_SUCCESS:
      return {
        ...state,
        adminSpecificationsData: action.adminSpecificationsData,
      };
    case GET_ADMIN_LAUNDRY_TABLE_SUCCESS:
      return {
        ...state,
        adminLaundryTableData: action.adminLaundryTableData,
      };
    case GET_ADMIN_PAYMENT_LIST_DATA_SUCCESS:
      return { ...state, adminPaymentData: action.adminPaymentData };
    case GET_ADMIN_PAYMENT_DETAIL_BRAND_DATA_SUCCESS:
      return {
        ...state,
        adminPaymentDeatilBrandData: action.adminPaymentDeatilBrandData,
      };
    case GET_ADMIN_PAYMENT_DETAIL_FACTORY_DATA_SUCCESS:
      return {
        ...state,
        adminPaymentDeatilFactoryData: action.adminPaymentDeatilFactoryData,
      };
    case GET_ADMIN_PAYMENT_FACTORY_LIST_DATA_SUCCESS:
      return {
        ...state,
        adminPaymentFactoryData: action.adminPaymentFactoryData,
      };
    case GET_ADMIN_PAYMENT_BRAND_LIST_DATA_SUCCESS:
      return { ...state, adminPaymentBrandData: action.adminPaymentBrandData };
    case GET_ID_DUPLICATE_CHECK_SUCCESS:
      return { ...state, adminIdDuplicateCheck: action.adminIdDuplicateCheck };
    case GET_ADMIN_PAYMANAGEMENT_LIST_SUCCESS:
      return {
        ...state,
        adminPayManagementList: action.adminPayManagementList,
      };
    case GET_LAUNDRY_TAG_LIST_SUCCESS:
      return { ...state, adminLaundryTagList: action.adminLaundryTagList };
    case GET_LAUNDRY_ITEM_LIST_SUCCESS:
      return { ...state, adminLaundryItemList: action.adminLaundryItemList };
    case GET_USER_ACCESS_HISTORY_SUCCESS:
      return {
        ...state,
        adminUserAccessHistory: action.adminUserAccessHistory,
      };
    case GET_CALCULATE_LIST_SUCCESS:
      return { ...state, adminCalculateList: action.data };
    case GET_CALUCLATE_PURCHASE_LIST_SUCCESS:
      return { ...state, adminCalculateList: action.data };
    case RESET_CALCULATE_LIST:
      return { ...state, adminCalculateList: null };
    case GET_CALCULATE_DETAIL_SUCCESS:
      return { ...state, adminCalculateDetail: action.data };
    case GET_CALCULATE_PURCHASE_DETAIL_SUCCESS:
      return { ...state, adminCalculateDetail: action.data };
    default:
      return state;
  }
}

export default admin;
