import React, { HTMLProps } from "react";
import styled, { css } from "styled-components";
import { buttonColorMap, ThemeColor } from "styles/palette";
import LoadingImage from 'assets/image/loading.gif';

const LoadingBlock = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LoadingImageBlock = styled.img`
  width: 120px;
  height: 120px;
`;

interface LoadingProps {

}

const Loading: React.FC<LoadingProps> = () => {

  return (
    <LoadingBlock>
      <LoadingImageBlock src={LoadingImage} alt=""/>
    </LoadingBlock>
  );
};

export default Loading;
