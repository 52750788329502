import TransactionTable from "components/common/TransactionTable";
import FactoryItemizedAccount from "components/factory/FactoryItemizedAccount";
import FactoryItemizedAccountList from "components/factory/FactoryItemizedAccountList";
import { IRootState } from "modules";
import qs from "query-string";

import {
  getFactoryDeliveryExcelDataAction,
  getFactoryItemizedAccountAction,
  getFactoryLaundryTableAction,
  getFactoryMatchinsgFranchiseListAction,
  postFactoryTableShareAction,
  getFactorySpecificationsListAction,
  deleteFactoryStatementAction,
  getFactoryLaundryTableYearAction,
} from "modules/factory/factory";
import {
  FactoryGetList,
  FactoryItemizedAccountSearch,
  FactoryExcelMonth,
} from "modules/factory/types";
import {
  getBoardAlarmAction,
  getDeliveryAlarmAction,
  getExcelDataAction,
  getLaundryCompleteAlarmAction,
  getLaundryRequestAlarmAction,
  getNoticeAlarmAction,
  getProfileDetailAction,
  getSpecificationsDetailAction,
  getSpecificationsFranchiseListAction,
  getStatementAlarmAction,
  getTransExcelDataAction,
} from "modules/user/user";

import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import DateFormatUtils from "utils/DateFormatUtils";
import RoleUtils from "utils/RoleUtils";

interface FactoryItemizedAccountContainerProps {
  match: any;
}

const FactoryItemizedAccountContainer: React.FC<FactoryItemizedAccountContainerProps> = ({
  match,
}) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectType, setSelectType] = useState<string>("inquiry");
  const [franchiseId, setFranchiseId] = useState<number>(0);
  const [selectDetailTitle, setSelectDetailTitle] = useState({
    title: "",
    name: "",
    date: "",
    chartType: "",
  });

  const [searchData, setSearchData] = useState<FactoryGetList | null>({
    query: "",
    dropBox: "",
    startDate: "",
    endDate: "",
    size: 10,
  });
  const [searchPage, setSearchPage] = useState<number>(1);
  const [searchId, setSearchId] = useState<number | null>(null);

  const role = useSelector((state: IRootState) => state.user.profile.type);

  const name = useSelector((state: IRootState) => state.user.profile.name);

  const factoryItemizedAccount = useSelector(
    (state: IRootState) => state.factory.factoryItemizedAccount
  );
  const factoryMatchingFranchiseList = useSelector(
    (state: IRootState) => state.factory.factoryMatchingFranchiseList
  );

  const laundryTable = useSelector((state: IRootState) => state.factory.factoryLaundryTableData);

  const factoryItemizedAccountExcel = useSelector(
    (state: IRootState) => state.factory.factoryItemizedAccountExcel
  );

  const factorySpecificationsList = useSelector(
    (state: IRootState) => state.factory.factorySpecificationsList
  );

  const specificationsDetailData = useSelector(
    (state: IRootState) => state.user.specificationsDetail
  );

  const specificationsFranchiseList = useSelector(
    (state: IRootState) => state.user.specificationsFranchiseList
  );

  const laundryTableYearData = useSelector(
    (state: IRootState) => state.factory.factoryExcelMonthList
  );

  const factoryProfileDetail = useSelector((state: IRootState) => state.user.profileDetail);

  const layoutHeightData = useSelector((state: IRootState) => state.user.layoutHeight);

  const query = window.location.pathname;

  const searchQuery = qs.parse(window.location.search);

  useEffect(() => {
    if (query === `/factory/itemizedAccount/${match.params.id}`) setSelectType("detail");
    if (query === `/factory/itemizedAccount/list`) {
      setSelectType("list");
      // dispatch(getFactoryMatchinsgFranchiseListAction());
      let sortData: string = "";
      const params = new URLSearchParams(window.location.search);
      if (params.get("sort")) {
        sortData = String(params.get("sort"));
      }
      dispatch(
        getFactorySpecificationsListAction(searchId, searchPage, searchData, sortData ?? "")
      );
    }
    if (query === `/factory/itemizedAccount`) {
      setSelectType("inquiry");

      let item = searchQuery;

      if (Object.keys(item).length > 0) {
        let data: FactoryGetList = {
          query: null,
          startDate: String(item.accountDate),
          endDate: String(item.accountDate),
          dropBox: null,
        };

        onDeliverySearch(Number(item.franchiseId), data, true);
      }
    }
  }, [query, match.params.id]);

  const onDeliverySearch = (id: number, data: FactoryGetList | null, reqType?: boolean) => {
    setFranchiseId(id);
    dispatch(getFactoryDeliveryExcelDataAction(id, data));
    if (!reqType) {
      history.push(`/factory/itemizedAccount`);
    }
  };

  useEffect(() => {
    dispatch(getFactoryMatchinsgFranchiseListAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProfileDetailAction(role));
  }, [dispatch, role]);

  // useEffect(() => {
  //   history.push(``)
  //   setSelectType("inquiry");
  // }, []);

  useEffect(() => {
    if (match.params.id && Number(match.params.id)) {
      setSearchId(match.params.id);
      setSelectType("detail");
      if (selectType === "detail") dispatch(getSpecificationsDetailAction(match.params.id));
    } else {
      // setSelectType("inquiry");
      // history.push(match.path);
    }
  }, [match.params.id]);

  useEffect(() => {
    if ((match.params.id && Number(match.params.id)) || match.params.id === "list") {
      history.push(`/factory/itemizedAccount`);
      dispatch(getFactorySpecificationsListAction(null, searchPage, searchData));
    }
  }, []);

  const specificationsListSearch = (
    id: number | null,
    page: number,
    data: FactoryGetList | null,
    sort?: string
  ) => {
    setSearchId(id);
    setSearchData(data ?? null);
    setSearchPage(page);
    dispatch(getSpecificationsFranchiseListAction());
    dispatch(getFactorySpecificationsListAction(id, page, data, sort));
  };

  const getFactoryLaundryTable = (id: number, data: FactoryGetList | null, chartType: string) => {
    let startDate = data?.startDate;
    let endDate = data?.endDate;
    setFranchiseId(id);
    dispatch(getFactoryLaundryTableAction(id, startDate!, endDate!, chartType));
  };

  const getFactoryLaundryTableYear = (data: FactoryExcelMonth) => {
    dispatch(getFactoryLaundryTableYearAction(data));
  };

  const onSearch = (payload: FactoryItemizedAccountSearch) => {
    dispatch(getFactoryItemizedAccountAction(payload));
  };

  const postFactoryTableShare = (
    image: string,
    frId: number,
    title: string,
    startDate: string,
    endDate: string,
    monthData: string,
    chartType: string
  ) => {
    dispatch(
      postFactoryTableShareAction(image, frId, title, startDate, endDate, monthData, chartType)
    );
  };

  const specificationsDetail = (
    id: number,
    title: string,
    name: string,
    date: Date,
    chartType: string
  ) => {
    const selectDate = DateFormatUtils.dateToFormat(date);
    setSelectDetailTitle({ title, name, date: selectDate, chartType });
    dispatch(getSpecificationsDetailAction(id));
  };

  const onSelectType = (type: string) => {
    setSelectType(type);
    if (type === "list") {
      specificationsListSearch(null, 1, null);
    }
  };

  const onDeleteStatement = (deleteId: number, type: string) => {
    dispatch(deleteFactoryStatementAction(deleteId, searchId, searchPage, searchData));
    setSelectType(type);
  };

  useEffect(() => {
    dispatch(getNoticeAlarmAction());
    dispatch(getBoardAlarmAction());

    if (RoleUtils.isFactory(role)) {
      dispatch(getDeliveryAlarmAction());
      dispatch(getLaundryRequestAlarmAction());
    }
    if (RoleUtils.isStore(role)) {
      dispatch(getStatementAlarmAction());
      dispatch(getLaundryCompleteAlarmAction());
    }
  }, [dispatch, role]);

  const historyBack = () => {
    setSelectDetailTitle({
      title: "",
      name: "",
      date: "",
      chartType: "",
    });
    history.push(`/factory/itemizedAccount/list`);
    setSelectType("list");
  };

  const excelFile = useSelector((state: IRootState) => state.user.excelData);
  const transExcelFile = useSelector((state: IRootState) => state.user.transExcelData);

  const getExcelData = () => {
    dispatch(getExcelDataAction(match.params.id));
  };
  const getTransExcelData = () => {
    dispatch(getTransExcelDataAction(match.params.id));
  };
  // console.log(selectType);
  return (
    <>
      {selectType === "inquiry" && (
        <FactoryItemizedAccount
          name={name!}
          onSearch={onSearch}
          getFactoryLaundryTableYearSearch={getFactoryLaundryTableYear}
          onDeliverySearch={onDeliverySearch}
          factoryItemizedAccountExcel={factoryItemizedAccountExcel}
          factoryMatchingFranchiseList={factoryMatchingFranchiseList}
          factoryItemizedAccount={factoryItemizedAccount}
          getFactoryLaundryTable={getFactoryLaundryTable}
          laundryTableData={laundryTable!}
          laundryTableYearData={laundryTableYearData}
          postFactoryTableShare={postFactoryTableShare}
          franchiseId={franchiseId}
          selectType={selectType}
          onSelectType={onSelectType}
          factoryProfileDetail={factoryProfileDetail}
          layoutHeightData={layoutHeightData!}
          searchQuery={searchQuery}
        />
      )}
      {selectType === "list" && (
        <FactoryItemizedAccountList
          specificationsFranchiseList={specificationsFranchiseList}
          factoryMatchingFranchiseList={factoryMatchingFranchiseList}
          onSelectType={onSelectType}
          specificationsDetail={specificationsDetail}
          specificationsListSearch={specificationsListSearch}
          factorySpecificationsList={factorySpecificationsList}
          searchPage={searchPage}
        />
      )}
      {selectType === "detail" && (
        <TransactionTable
          role={role}
          selectDetailTitle={selectDetailTitle}
          specificationsDetailData={specificationsDetailData}
          factoryProfileDetail={factoryProfileDetail}
          onDeleteStatement={onDeleteStatement}
          historyBack={historyBack}
          excelFile={excelFile}
          transExcelFile={transExcelFile}
          getExcelData={getExcelData}
          getTransExcelData={getTransExcelData}
        />
      )}
    </>
  );
};

export default FactoryItemizedAccountContainer;
