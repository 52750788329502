import { BoardDetailData, BoardPostData } from "modules/user/types";
import React from "react";
import TextEditor from "./board/TextEditor";

interface NoticeWriteProps {
  detailId?: number | null;
  role: string;
  type: string;
  auth: string;
  selectNoticeType: (type: string) => void;
  noticeDataSubmit: (noticeCommnad: BoardPostData) => void;
  content?: BoardDetailData | null;
}

const NoticeWrite: React.FC<NoticeWriteProps> = ({
  detailId,
  selectNoticeType,
  noticeDataSubmit,
  type,
  auth,
  content,
  role,
}) => {
  return (
    <TextEditor
      detailId={detailId}
      title={"공지사항"}
      selectType={selectNoticeType}
      dataSubmit={noticeDataSubmit}
      type={type}
      content={content}
      editorType={"notice"}
      auth={auth}
      role={role}
    />
  );
};

export default NoticeWrite;
