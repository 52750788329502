import MasterItemStatus from "components/master/MasterItemStatus";
import { IRootState } from "modules";
import {
  deleteBrandItemAction,
  getBrandInventoryListAllAction,
  getMasterItemListAction,
  getMasterItemListAllAction,
  patchBrandInventoryListAllAction,
  patchMasterItemModifyAction,
  postMasterItemRegistrationAction,
  postMasterItemRegistrationExcelAction,
} from "modules/master/master";
import {
  BrandInventoryPatchData,
  MasterItemDataModify,
  MasterItemRegistration,
} from "modules/master/types";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

interface MasterItemStatusContainerProps {}

const MasterItemStatusContainer: React.FC<MasterItemStatusContainerProps> = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMasterItemListAction(1, 10));
    dispatch(getMasterItemListAllAction());
    dispatch(getBrandInventoryListAllAction());
  }, []);

  const masterItemList = useSelector((state: IRootState) => state.master.masterItemList);

  const masterItemListAll = useSelector((state: IRootState) => state.master.masterItemListAll);

  const masterInventoryListAll = useSelector(
    (state: IRootState) => state.master.masterInventoryListAll
  );

  const masterItemRegistration = (payload: MasterItemRegistration, size: number) => {
    dispatch(postMasterItemRegistrationAction(payload, size));
  };

  const masterItemRegistrationExcel = async (payload: MasterItemRegistration[], size: number) => {
    dispatch(postMasterItemRegistrationExcelAction(payload, size));
  };

  const masterItemModify = (payload: MasterItemDataModify, page: number, size: number) => {
    dispatch(patchMasterItemModifyAction(payload, page, size));
  };
  const itemList = (page: number, size: number) => {
    dispatch(getMasterItemListAction(page, size));
  };
  const itemListAll = () => {
    dispatch(getMasterItemListAllAction());
  };

  const masterPatchItemModify = (data: BrandInventoryPatchData[], page: number, size: number) => {
    dispatch(patchBrandInventoryListAllAction(data, page, size));
  };

  /** 자산 삭제 */
  const onDelete = (id: number, page: number, size: number) => {
    dispatch(deleteBrandItemAction(id, page, size));
  };

  if (!masterItemList) return <div>로딩중</div>;
  return (
    <MasterItemStatus
      itemList={itemList}
      masterItemList={masterItemList}
      masterItemRegistration={masterItemRegistration}
      masterItemModify={masterItemModify}
      masterItemRegistrationExcel={masterItemRegistrationExcel}
      itemListAll={itemListAll}
      masterItemListAll={masterItemListAll}
      masterInventoryListAll={masterInventoryListAll}
      masterPatchItemModify={masterPatchItemModify}
      onDelete={onDelete}
    />
  );
};

export default MasterItemStatusContainer;
