import React, { useEffect } from "react";
import styled from "styled-components";
import Button from "components/common/Button";
import { Palette, ThemeColor } from "styles/palette";
import { AdminCalculateDetailResType } from "modules/admin/types";
import DateApp from "components/common/datePickerTest";
import moment from "moment";
import NumberUtils from "utils/NumberUtils";

interface AdminCalculateDetailTypes {
  pathname: string;
  state: AdminCalculateDetailResType | null;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onDateSelector: (date: string, name: string) => void;
  onModifySubmit: () => void;
  goBack: () => void;
}

function AdminCalculateDetail(props: AdminCalculateDetailTypes) {
  const taxDateSelector = (date: string) => {
    props.onDateSelector(moment(date).format("YYYY-MM-DD"), "taxCompletedDate");
  };

  const billingDateSelector = (date: string) => {
    props.onDateSelector(moment(date).format("YYYY-MM-DD"), "completedDate");
  };

  return (
    <AdminCalculateDetailBlock>
      <div className="headers">
        <h3>
          정산 {props.pathname === "sales" && "매출"}
          {props.pathname === "purchase" && "매입"} 상세
        </h3>
        <Button theme={ThemeColor.primary} buttonSize="m" onClick={props.goBack}>
          뒤로가기
        </Button>
      </div>

      <div className="contentWrapper">
        <div className="detailInfo">
          <div className="field">
            <span>
              {props.pathname === "sales" && "지점명"}
              {props.pathname === "purchase" && "세탁공장명"}
            </span>
            <p>
              {props.pathname === "sales" && (props.state?.franchiseName ?? "-")}
              {props.pathname === "purchase" && (props.state?.factoryName ?? "-")}
            </p>
          </div>
          <div className="field">
            <span>정산기간</span>
            <p>
              {props.state?.startDate} ~ {props.state?.endDate}
            </p>
          </div>
          <div className="field">
            <span>정산월</span>
            <p>{props.state?.billingMonth}</p>
          </div>
          {props.pathname === "sales" && (
            <div className="field">
              <span>계약방식</span>
              <p>{props.state?.contractBasis}</p>
            </div>
          )}
          {props.pathname === "purchase" && (
            <>
              <div className="field">
                <span>매출합계</span>
                <p>{props.state?.salesTotalAmount}</p>
              </div>
              <div className="field">
                <span>정산비율</span>
                <p>{props.state?.fee}</p>
              </div>
            </>
          )}
          <div className="field">
            <span>예상 정산금액(원)</span>
            <p>{NumberUtils.numberToComma(props.state?.estimatedAmount ?? 0)}</p>
          </div>
          <div className="field">
            <span>정산금액(원)</span>
            <input
              name="amount"
              className="onHover"
              value={NumberUtils.numberToComma(props.state?.amount ?? 0)}
              onChange={(e) => props.onChange(e)}
            />
          </div>
          <div className="field">
            <span>세금계산서 발행 유무</span>
            <select name="taxStatus" className="onHover" onChange={(e) => props.onChange(e)}>
              <option value="발행완료" selected={props.state?.taxStatus === "발행완료"}>
                발행완료
              </option>
              <option value="미발행" selected={props.state?.taxStatus === "미발행"}>
                미발행
              </option>
            </select>
          </div>
          <div className="field">
            <span>세금계산서 발행일시</span>
            <DateApp
              dateItem={taxDateSelector}
              notDefault={props.state?.taxCompletedDate === ""}
              today={
                props.state?.taxCompletedDate
                  ? moment(props.state?.taxCompletedDate).format("YYYY-MM-DD")
                  : ""
              }
            />
          </div>
          <div className="field">
            <span>정산상태</span>
            <select name="billingStatus" className="onHover" onChange={(e) => props.onChange(e)}>
              <option value="정산완료" selected={props.state?.billingStatus === "정산완료"}>
                정산완료
              </option>
              <option value="미완료" selected={props.state?.billingStatus === "미완료"}>
                미완료
              </option>
            </select>
          </div>
          <div className="field">
            <span>정산완료 일시</span>
            <DateApp
              dateItem={billingDateSelector}
              notDefault={props.state?.completedDate === ""}
              today={
                props.state?.completedDate
                  ? moment(props.state?.completedDate).format("YYYY-MM-DD")
                  : ""
              }
            />
          </div>
          {props.pathname === "sales" && (
            <div className="field">
              <span>등록일시(시스템)</span>
              <p>{moment(props.state?.createdDate).format("YYYY-MM-DD")}</p>
            </div>
          )}
          <div className="buttonWrapper">
            <Button theme={ThemeColor.quadratic} buttonSize="m" onClick={props.goBack}>
              취소
            </Button>
            <Button theme={ThemeColor.primary} buttonSize="m" onClick={props.onModifySubmit}>
              저장
            </Button>
          </div>
        </div>
      </div>
    </AdminCalculateDetailBlock>
  );
}

export default AdminCalculateDetail;

const AdminCalculateDetailBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  & > .contentWrapper {
    width: 100%;
    height: 820px;

    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    & > div {
      width: calc(100% / 2);
    }
    & > div:nth-child(2) {
      border-left: 1px solid black;
    }
    & > .detailInfo {
      width: auto;
      margin: 0 auto;

      & > .field {
        width: 100%;
        min-height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
        & > span {
          width: 140px;
        }
        & > p,
        input,
        select {
          border: 1px solid #ddd;
          font-size: 14px;
          border-radius: 5px;
          height: 100%;
          width: 260px;
          line-height: 35px;
          margin: 0;
          padding-left: 10px;
        }
        & > input {
          height: 37px;
        }
        & > div {
          & > .changeId {
            width: 179px;
            border: 1px solid #ddd;
            height: 37px;
            margin-right: 5px;
          }
        }
        & > .PWBtn {
          width: 260px;
        }
      }
      & > .secoundField {
        & > p,
        input {
          width: 174px;
          margin-right: 10px;
        }
      }
      & > .buttonWrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 50px;
      }
    }
    & > .stockStatus {
      height: 740px;
      & > div {
        width: 500px;
        height: auto;
        margin: 0 auto;
        & > h3 {
          margin: 0;
        }
        & > .stockInField {
          margin-top: 20px;
          & > div {
            width: 100%;
            height: 690px;
            border-radius: 10px;
            background-color: #ecf0f5;
            padding: 20px 5px;
            overflow: hidden;
            & > .stockHeader {
              display: flex;
              justify-content: space-between;
              align-items: center;
              & > span {
                width: calc(100% / 6);
                text-align: center;
              }
            }
            & > .stockList {
              overflow-y: scroll;
              height: 90%;
              margin-top: 20px;
              & > div {
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #fff;
                padding: 2px 0;
                & > span {
                  width: calc(100% / 6);
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }

  .onHover {
    border: 1px solid ${Palette.primary} !important;
  }
  .test121 {
    width: 260px !important;
    .test13 {
      padding-left: 10px !important;
      width: 260px !important;
      border: 1px solid ${Palette.primary} !important;
    }
  }
  .postCode {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    & > div {
      width: 30rem;
      & > div {
        & > .postCodeCloseBtn {
          margin-top: 1rem;
        }
      }
    }
  }
`;
