import { MasterGetList } from "modules/master/types";
import React, { useState } from "react";
import styled from "styled-components";
import { ThemeColor } from "styles/palette";
import Button from "./Button";
import { LaundrySearchBlock } from "./LaundrySearch";

import "date-fns";
// import Grid from "@material-ui/core/Grid";
// import DateFnsUtils from "@date-io/date-fns";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
// import DateFormatUtils from "utils/DateFormatUtils";
// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// import InputLabel from "@material-ui/core/InputLabel";
// import MenuItem from "@material-ui/core/MenuItem";
// import FormHelperText from "@material-ui/core/FormHelperText";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
// import TextField from "@material-ui/core/TextField";
// import RoleUtils from "utils/RoleUtils";

interface FactorySearchProps {
  role: string;
  onSearch: (page: number, data: MasterGetList) => void;
  size?: number;
}

const FactorySearch: React.FC<FactorySearchProps> = ({ onSearch, size }) => {
  const [searchQuery, setSearchQuery] = useState<string | null>("");
  const [searchType, setSearchType] = useState<string | null>("factoryName");

  // const useStyles = makeStyles((theme: Theme) =>
  //   createStyles({
  //     formControl: {
  //       minWidth: 120,
  //     },
  //   })
  // );

  // const classes = useStyles();

  const submitSearch = (e: any) => {
    e.preventDefault();
    const data = {
      query: searchQuery,
      dropBox: searchType,
      size: size ?? 10,
    };
    onSearch(1, data);
  };

  const selectHandleChange = (e: string) => {
    setSearchType(e);
  };

  // const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   setSearchType(event.target.value as string);
  // };

  return (
    <FactorySearchBlock onSubmit={(e) => submitSearch(e)}>
      {/* <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Select</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined-label"
          value={searchType}
          style={{
            width: "130px",
            height: "40px",
            fontSize: "15px",
            margin: 0,
          }}
          onChange={handleChange}
        >
          <MenuItem value="factoryName">공장명</MenuItem>
          <MenuItem value="userName">아이디</MenuItem>
        </Select>
      </FormControl> */}
      <select
        className="selectBox"
        value={searchType!}
        onChange={(e) => {
          selectHandleChange(e.target.value);
        }}
      >
        <option value="factoryName">공장명</option>
        <option value="userName">아이디</option>
      </select>
      <input
        className="textBox"
        value={searchQuery ?? ""}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      {/* <TextField
        id="standard-basic"
        label="Search"
        className="textField"
        style={{ width: "112px" }}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      /> */}
      <Button theme={ThemeColor.primary} buttonSize="m" onClick={submitSearch}>
        검색
      </Button>
    </FactorySearchBlock>
  );
};

export default FactorySearch;

const FactorySearchBlock = styled(LaundrySearchBlock)`
  .textField {
    margin-left: 28px;
  }
`;
