import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { Palette, ThemeColor } from "styles/palette";
import Button from "components/common/Button";
import { NavLink } from "react-router-dom";
import Paging from "components/common/Paging";
import PopupLargeLayout from "components/common/PopupLargeLayout";
import InventoryStaffPropertySearch from "components/common/InventoryStaffPropertySearch";
import InventoryStaffInfoSearch from "components/common/InventoryStaffInfoSearch";

import XLSX from "xlsx";
import { on } from "stream";
import {
  PersonalInfoList,
  StoreStaffDepartmentList,
  StoreStaffDepartmentListRequest,
} from "modules/store/types";
import NumberUtils from "utils/NumberUtils";
import { getStaffDepartmentList } from "api/storeApi";
import moment from "moment";
import { ILoginResponse } from "modules/user/types";

interface StoreStaffInfoProps {
  storeStaffInfoExcelSubmit: (
    data: PersonalInfoList[],
    page: number,
    size: number
  ) => void;
  storeStaffInfoSubmit: (
    data: PersonalInfoList,
    page: number,
    size: number
  ) => void;
  staffDepartmentListSearch: (data: StoreStaffDepartmentListRequest) => void;
  storeStaffDepartmentList: StoreStaffDepartmentList | null;
  onPageMove: (page: number) => void;
  selectShowEntries: (limit: number) => void;
  onExcelDown: () => void;
  userProfile: ILoginResponse;
  storeStaffInfoDelete: (data: number[]) => void;
}

type staffInfoDetail = {
  id: number | null;
  num: string | null;
  userId: number | null;
  date: string | Date;
  departmentName: string;
  userName: string;
  modal?: boolean;
};

const StoreStaffInfo: React.FC<StoreStaffInfoProps> = (props) => {
  const [selectSize, setSelectSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [checkBox, setCheckBox] = useState<boolean>(false);
  const [deleteSelect, setDeleteSelect] = useState<number[]>([]);

  const [modalItem, setModalItem] = useState<staffInfoDetail>({
    id: null,
    num: null,
    userId: null,
    date: "",
    departmentName: "",
    userName: "",
    modal: false,
  });

  // console.log(props.storeStaffDepartmentList);

  const [listNumber, setListNumber] = useState<number[]>([]);
  useEffect(() => {
    setListNumber(
      NumberUtils.numberToList(
        props.storeStaffDepartmentList?.currentPage ?? 0,
        props.storeStaffDepartmentList?.totalCount ?? 0,
        selectSize
      )
    );
  }, [props.storeStaffDepartmentList, selectSize]);

  const selectShowEntries = (size: number) => {
    setDeleteSelect([]);
    setSelectSize(size);
    props.selectShowEntries(size);
  };

  const onDetail = (data: staffInfoDetail) => {
    setModalItem(data);
  };

  const onEnroll = () => {
    setModalItem({
      id: null,
      num: null,
      userId: null,
      date: "",
      departmentName: "",
      userName: "",
      modal: true,
    });
  };

  const onSubmit = () => {
    if (!modalItem.departmentName && !modalItem.userName && !modalItem.num)
      return alert("빈값 없이 입력해주세요");

    let item = {
      id: modalItem.id,
      userId: modalItem.userId,
      userNum: modalItem.num!,
      departmentName: modalItem.departmentName,
      userName: modalItem.userName,
    }; // id, userId 가 있으면 수정, 없으면 등록

    props.storeStaffInfoSubmit(item, page, selectSize);
    onClose();
  };

  const onClose = () => {
    setModalItem({
      id: null,
      num: null,
      userId: null,
      date: "",
      departmentName: "",
      userName: "",
      modal: false,
    });
  };

  const onSearch = (query: string, type: string) => {
    let item = {
      page: 1,
      limit: selectSize,
      query,
      type,
    };
    props.staffDepartmentListSearch(item);
  };

  const onPageMove = (page: number) => {
    setDeleteSelect([]);
    setPage(page);
    props.onPageMove(page);
  };

  const onSelectChange = (data: string, type: string) => {
    setModalItem((modalItem) => ({ ...modalItem, [type]: data }));
  };

  const excelDown = () => {
    props.onExcelDown();
  };

  const sampleExcelDown = () => {
    let excelDataList: any[] = [
      {
        번호: "",
        부서명: "",
        이름: "",
      },
    ];
    const ws = XLSX.utils.json_to_sheet(excelDataList);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(
      wb,
      `${props.userProfile.nickname}_직원정보관리_등록샘플.xlsx`
    );
  };

  const excelUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    var input = e.target.files;

    if (!input) return;
    var reader = new FileReader();
    reader.onload = async function () {
      var fileData = reader.result;
      var wb = XLSX.read(fileData, { type: "binary" });
      wb.SheetNames.forEach(function (sheetName) {
        var rowObj: any[] = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);

        let list: PersonalInfoList[] = [];
        for (let i = 0; i < rowObj.length; i++) {
          if (
            !Object.keys(rowObj[i]).includes("번호") ||
            !Object.keys(rowObj[i]).includes("부서명") ||
            !Object.keys(rowObj[i]).includes("이름")
          ) {
            alert("데이터 형식이 맞지 않습니다.");
            return;
          }
          let item = {
            userNum: rowObj[i]["번호"],
            departmentName: rowObj[i]["부서명"],
            userName: rowObj[i]["이름"],
          };
          list.push(item);
        }
        props.storeStaffInfoExcelSubmit(list, page, selectSize);
        e.target.value = "";
      });
    };

    reader.readAsBinaryString(input[0]);
  };

  const onCheck = () => {
    setDeleteSelect([]);
    setCheckBox(!checkBox);
  };

  const deleteCheck = (num: number) => {
    if (deleteSelect.find((item) => item === num)) {
      setDeleteSelect(deleteSelect.filter((item) => item !== num));
      return;
    }
    setDeleteSelect(deleteSelect.concat(num));
  };

  const deleteAllCheck = () => {
    if (
      (props.storeStaffDepartmentList?.list.length ?? 0) <=
        deleteSelect.length &&
      deleteSelect.length !== 0
    ) {
      setDeleteSelect([]);
      return;
    }

    props.storeStaffDepartmentList?.list.map(
      (data) =>
        !deleteSelect.some((item) => data.id === item) &&
        setDeleteSelect((deleteSelect) => [...deleteSelect, data.id])
    );
  };

  const onDelete = () => {
    if (deleteSelect.length === 0) return;
    if (window.confirm("선택한 정보를 삭제하시겠습니까?")) {
      props.storeStaffInfoDelete(deleteSelect);
      setCheckBox(false);
      setDeleteSelect([]);
    }
  };

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      fontWeight: 700,
    },
  });
  const classes = useStyles();

  return (
    <StoreStaffInfoBlock>
      {modalItem.modal && (
        <PopupLargeLayout>
          <div className="popupLarge">
            <h3>
              {modalItem.id !== null ? "직원 정보 관리" : "직원 정보 등록"}
            </h3>
            <div className="inputWrapper">
              <div>
                {modalItem.id !== null && (
                  <div>
                    <span>번호</span>
                    <input
                      name="num"
                      className="infoInput"
                      placeholder="직원 번호를 입력해주세요"
                      value={modalItem.num ?? ""}
                      onChange={(e) =>
                        onSelectChange(e.target.value, e.target.name)
                      }
                    />
                  </div>
                )}
                <div>
                  <span>부서명</span>
                  <input
                    name="departmentName"
                    className="infoInput"
                    placeholder="부서명을 입력해주세요"
                    value={modalItem.departmentName}
                    onChange={(e) =>
                      onSelectChange(e.target.value, e.target.name)
                    }
                  />
                </div>
                <div>
                  <span>이름</span>
                  <input
                    name="userName"
                    className="infoInput"
                    placeholder="직원 이름을 입력해주세요"
                    value={modalItem.userName}
                    onChange={(e) =>
                      onSelectChange(e.target.value, e.target.name)
                    }
                  />
                </div>
              </div>
              <div>
                {modalItem.id !== null && (
                  <div>
                    <span>등록일시</span>
                    <input value={String(modalItem.date)} disabled />
                  </div>
                )}
                {modalItem.id === null && (
                  <div>
                    <span>번호</span>
                    <input
                      name="num"
                      className="infoInput"
                      placeholder="직원 번호를 입력해주세요"
                      value={modalItem.num ?? ""}
                      onChange={(e) =>
                        onSelectChange(e.target.value, e.target.name)
                      }
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="buttonWrapper">
              <Button
                theme={ThemeColor.primary}
                buttonSize="m"
                onClick={onClose}
              >
                취소
              </Button>
              <Button
                theme={ThemeColor.primary}
                buttonSize="m"
                onClick={onSubmit}
              >
                저장
              </Button>
            </div>
          </div>
        </PopupLargeLayout>
      )}
      <div className="headers">
        <h3>자산 관리</h3>
        <div>
          <span className="selectClick">
            <NavLink to="/store/inventory">기본 자산</NavLink>
          </span>
          <span>|</span>
          <span className="selectClick">
            <NavLink to="/store/inventory/staff/property">관계자 자산</NavLink>
          </span>
          <span>|</span>
          <span className="selectClick on">
            <NavLink to="/store/inventory/staff/info">직원 정보 관리</NavLink>
          </span>
        </div>
      </div>
      <div className="tableSelect">
        <div>
          <span>Show </span>
          <select
            onChange={(e) => selectShowEntries(parseInt(e.target.value, 10))}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
          <span> entries</span>
        </div>
        <div className="topWrapper">
          <InventoryStaffInfoSearch
            role={""}
            onSearch={onSearch}
            size={selectSize}
          />
          <span>|</span>
          <div>
            <Button
              theme={!checkBox ? ThemeColor.primary : ThemeColor.tertiary}
              buttonSize="m"
              onClick={onCheck}
            >
              체크박스
            </Button>
            {!checkBox ? (
              <>
                <Button
                  theme={ThemeColor.primary}
                  buttonSize="m"
                  onClick={excelDown}
                >
                  엑셀저장
                </Button>
                <Button
                  theme={ThemeColor.primary}
                  buttonSize="m"
                  onClick={sampleExcelDown}
                >
                  업로드샘플
                </Button>
                <div className={"excelBtn"}>
                  <label>
                    엑셀업로드
                    <input
                      type="file"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      name="excelInput"
                      id="excel_upload_input"
                      onChange={(e) => {
                        excelUpload(e);
                      }}
                    />
                  </label>
                </div>
                <Button
                  theme={ThemeColor.primary}
                  buttonSize="m"
                  onClick={onEnroll}
                >
                  등록
                </Button>
              </>
            ) : (
              <Button
                theme={ThemeColor.primary}
                buttonSize="m"
                onClick={onDelete}
              >
                선택삭제
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="tableWrapper">
        <TableContainer component={Paper} className="tables">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {checkBox && (
                  <TableCell
                    align="center"
                    style={{ fontWeight: 900, width: "70px" }}
                  >
                    <input
                      type="checkbox"
                      style={{ margin: 0 }}
                      onChange={deleteAllCheck}
                      checked={
                        props.storeStaffDepartmentList?.list.length ===
                        deleteSelect.length
                      }
                    />
                  </TableCell>
                )}

                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "70px" }}
                >
                  순번
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "30%" }}
                >
                  번호
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900 }}>
                  부서명
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "30%" }}
                >
                  이름
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="tableBody">
              {props.storeStaffDepartmentList &&
                props.storeStaffDepartmentList.list.map((item, index) => (
                  <TableRow
                    className="tableHover"
                    key={index}
                    onClick={() =>
                      onDetail({
                        id: item.id,
                        num: String(item.userNum ?? "-"),
                        userId: item.userId,
                        date: moment(item.createdDate).format(
                          "YYYY-MM-DD HH:mm:ss"
                        ),
                        departmentName: item.departmentName,
                        userName: item.userName,
                        modal: true,
                      })
                    }
                  >
                    {checkBox && (
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        onClick={(event) => event.stopPropagation()}
                      >
                        <input
                          type="checkbox"
                          style={{ margin: 0 }}
                          onChange={() => {
                            deleteCheck(item.id);
                          }}
                          checked={deleteSelect.some(
                            (data) => item.id === data
                          )}
                        />
                      </TableCell>
                    )}
                    <TableCell component="th" scope="row" align="center">
                      {listNumber[index]}
                    </TableCell>
                    <TableCell align="center">{item.userNum ?? "-"}</TableCell>
                    <TableCell align="center">{item.departmentName}</TableCell>
                    <TableCell align="center">{item.userName}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Paging
          listAction={onPageMove}
          listCount={props.storeStaffDepartmentList?.totalPage ?? 0}
          currentPage={props.storeStaffDepartmentList?.currentPage}
          totalCount={props.storeStaffDepartmentList?.totalCount}
          size={selectSize ?? 10}
        ></Paging>
      </div>
    </StoreStaffInfoBlock>
  );
};

export default StoreStaffInfo;

const StoreStaffInfoBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);

  .popupLarge {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & > h3 {
      margin: 0;
    }
    & > .inputWrapper {
      width: 90%;
      min-height: 172px;
      flex: 1;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      margin-top: 50px;
      margin-bottom: 50px;
      & > div {
        width: 343px;
        min-height: 172px;
        & > div {
          width: 100%;
          height: auto;
          margin-bottom: 7px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          & > span {
            width: 100px;
            display: inline-block;
          }
          & > input,
          select {
            flex: 1;
            border: 0;
            height: 30px;
            padding: 0;
            padding-left: 10px;
          }
          & > input {
            background-color: #eee;
            color: #aaa;
          }
          & > .infoInput {
            background-color: #fff;
            color: #333;
          }
        }
      }
    }
    & > .buttonWrapper {
      height: auto;
      background-color: seashell;

      display: flex;
      justify-content: center;
    }
  }

  .tables {
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
    & > div {
      width: auto;
      height: 20px;
      display: flex;
      justify-content: space-between;
      margin-left: 20px;
      padding-top: 5px;

      & > span {
        color: #dee2e6;
      }
      & > .selectClick {
        cursor: pointer;
        font-size: 14px;
        & > a {
          color: #dee2e6;
        }
        & > a:hover {
          color: ${Palette.primary};
          font-weight: bold;
        }
      }
      & > .on {
        & > a {
          color: ${Palette.primary};
          font-weight: bold;
        }
      }
      & > .selectClick:hover {
        color: ${Palette.primary};
        font-weight: bold;
      }
      & > span:nth-child(2n) {
        font-size: 10px;
        margin: 0 10px;
        color: #333;
      }
    }
  }
  .tableSelect {
    height: 70px;
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: space-between;
    & > div {
      color: #999;
      height: 40px;
      overflow: hidden;
      & > span {
        font-size: 14px;
        line-height: 37px;
      }
      & > select {
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        transition: ease-in-out;
        transition-duration: 0.1s;
      }
      & > select:hover {
        border-bottom: 2px solid ${Palette.primary};
      }
    }
  }
  .topWrapper {
    display: flex;
    justify-content: flex-end;
    & > form {
      padding-top: 2.5px;
    }
    & > span {
      margin: 0 10px;
    }
    & > div {
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > .excelBtn {
        height: 35px;

        & > label {
          display: inline-block;
          width: 75px;
          padding: 0.56rem 0;
          text-align: center;
          font-size: 14px;
          color: #fff;
          border-radius: 5px;
          height: 35px;
          background-color: ${Palette.tertiary};
          cursor: pointer;
          & > input {
            display: none;
          }
        }
      }
      & > div {
        margin: 0;
        margin-right: 3px;
      }
      & > div:last-child {
        margin-right: 0;
      }
    }
  }
  .tableWrapper {
    min-width: 51rem;
    padding-bottom: 1.5rem;
  }
  .tableHover:hover {
    background-color: #eee;
    cursor: pointer;
  }
  .tableBody {
    tr {
      height: 53px !important;
      td {
        padding: 0;
      }
    }
  }
`;
