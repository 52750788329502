import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { Palette, ThemeColor } from "styles/palette";
import RoleUtils from "utils/RoleUtils";
// import Button from "../Button";
import Badge from "@material-ui/core/Badge";
import main_logo from "assets/logo/main_logo.svg";
import user from "assets/icons/user.png";
import user_icon from "assets/icons/user_sel.png";
import logout from "assets/icons/logout.png";
import logout_sel from "assets/icons/logout_sel.png";

import { BsBell, BsBellFill, BsBoxArrowInRight } from "react-icons/bs";
import { Link, useHistory } from "react-router-dom";
import {
  AlarmData,
  BoardAlarm,
  ILoginResponse,
  LaundryCompleteAlarm,
  LaundryRequestAlarm,
  NoticeAlarm,
} from "modules/user/types";

interface HeaderProps {
  profile: ILoginResponse;
  username: string;
  sizeButton: () => void;
  role: string;
  onLogout: () => void;
  userInfoBtn: () => void;
  userInfo: boolean;
  alarmInfoBtn: () => void;
  alarmInfo: boolean;
  noticeAlarm: NoticeAlarm | null;
  boardAlarm: BoardAlarm | null;
  laundryCompleteAlarm: LaundryCompleteAlarm | null;
  laundryRequestAlarm: LaundryRequestAlarm | null;
  deliveryAlarm: AlarmData[] | null;
  statementAlarm: AlarmData[] | null;
  alarmAllCheck: () => void;
  alarmReflash: () => void;
}

type HeaderBlockProps = {
  selectType: boolean;
  alarmType: boolean;
};

const Header: React.FC<HeaderProps> = ({
  profile,
  username,
  onLogout,
  role,
  sizeButton,
  userInfoBtn,
  userInfo,
  alarmInfoBtn,
  alarmInfo,
  noticeAlarm,
  boardAlarm,
  laundryCompleteAlarm,
  laundryRequestAlarm,
  deliveryAlarm,
  statementAlarm,
  alarmAllCheck,
  alarmReflash,
}) => {
  const [type, setType] = useState<string>("");
  useEffect(() => {
    if (RoleUtils.isMasterAdmin(role)) return setType("admin");
    if (RoleUtils.isMaster(role)) return setType("master");
    if (RoleUtils.isStore(role)) return setType("store");
    if (RoleUtils.isFactory(role)) return setType("factory");
  }, [role]);

  const [profileHover, setProfileHover] = useState<boolean>(false);
  const [logoutHover, setLogoutHover] = useState<boolean>(false);
  const [onAlarm, _setOnAlarm] = useState(false);

  const onHover = (type: string) => {
    if (type === "profile") {
      setProfileHover(!profileHover);
    }
    if (type === "logout") {
      setLogoutHover(!logoutHover);
    }
  };

  const userInfoRef = useRef<HTMLDivElement>(null);
  // const alarmInfoRef = useRef<HTMLDivElement>(null);

  const onUserInfo = (e: any) => {
    userInfoBtn();
    setProfileHover(false);
    setLogoutHover(false);
  };

  // const onAlarmInfo = (e: any) => {
  //   alarmInfoBtn();
  // };

  const stateType: any = {
    DISCONTENT: "불만 사항",
    INQUIRY: "문의사항",
    SUJUNG: "수정요청",
    ETC: "기타",
  };

  const [auth, setAuth] = useState<string>("");
  const [newCount, setNewCount] = useState<number>(0);

  useEffect(() => {
    let noticeNum = noticeAlarm?.list.length ?? 0;
    let boardNum = boardAlarm?.list.length ?? 0;
    let laundryNum = laundryCompleteAlarm?.list.length ?? 0;
    let deliveryNum = statementAlarm?.length ?? 0;
    // if (RoleUtils.isMaster(role) || RoleUtils.isStore(role)) {
    //   laundryNum = laundryCompleteAlarm?.list.length ?? 0;
    //   deliveryNum = statementAlarm?.length ?? 0;
    // }
    if (RoleUtils.isFactory(role)) {
      laundryNum = laundryRequestAlarm?.list.length ?? 0;
      deliveryNum = deliveryAlarm?.length ?? 0;
    }

    let data = Number(noticeNum) + Number(boardNum) + Number(laundryNum) + Number(deliveryNum);

    // console.log("test1");
    // console.log(noticeNum);
    // console.log("test2");
    // console.log(boardNum);
    // console.log("test3");
    // console.log(laundryNum);
    // console.log("test4");
    // console.log(deliveryNum);

    setNewCount(data);
  }, [
    noticeAlarm,
    boardAlarm,
    laundryCompleteAlarm,
    laundryRequestAlarm,
    statementAlarm,
    deliveryAlarm,
    role,
  ]);

  useEffect(() => {
    let authority = "";
    if (RoleUtils.isMasterAdmin(role)) authority = "admin";
    if (RoleUtils.isMaster(role)) authority = "master";
    if (RoleUtils.isStore(role)) authority = "store";
    if (RoleUtils.isFactory(role)) authority = "factory";
    setAuth(authority);
  }, [role]);

  const alarmListRef = useRef<any>();

  useEffect(() => {
    window.addEventListener("click", closeAlarm);
    return () => {
      window.removeEventListener("click", closeAlarm);
    };
  }, [onAlarm]);

  const closeAlarm = (target: any) => {
    if (onAlarm && !alarmListRef.current!.contains(target.target)) {
      _setOnAlarm(false);
    }
  };

  const alarmToggle = () => {
    _setOnAlarm(!onAlarm);
  };

  const history = useHistory();

  const laundryPageLocomotion = (id: number) => {
    alarmReflash();
    _setOnAlarm(false);
    history.push(`/${auth}/laundry/${id}`);
  };

  const boardPageLocomotion = (id: number) => {
    alarmReflash();
    _setOnAlarm(false);
    history.push(`/${auth}/board/${id}`);
  };

  const noticePageLocomotion = (id: number) => {
    alarmReflash();
    _setOnAlarm(false);
    history.push(`/${auth}/notice/${id}`);
  };

  const deliveryPageLocomotion = (id: number) => {
    alarmReflash();
    _setOnAlarm(false);
    history.push(`/${auth}/itemizedAccount/${id}`);
  };

  return (
    <HeaderBlock selectType={userInfo} alarmType={onAlarm}>
      <div>
        <div className="logoBox">
          <div className="logo">
            <img src={main_logo} alt="logo" />
          </div>
          <div className="sizeButton" onClick={sizeButton}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="iconBox">
          <div className="icons">
            <Badge badgeContent={newCount ? newCount : 0} color="secondary" onClick={alarmToggle}>
              <BsBellFill color="white" />
            </Badge>
          </div>
          <div className="headerList">
            <span>
              {/* {username} 님 */}
              <div onClick={onUserInfo} className="icon">
                {/* <BsBoxArrowInRight /> */}
                <img src={user_icon} alt="logout" />
              </div>
            </span>
          </div>
        </div>
      </div>
      <div className="profileModal" ref={userInfoRef}>
        <div className="profileHeader">{profile.name} 님</div>
        <div className="profileBody">
          <ul>
            <Link to={`/${type}/profile`}>
              <li
                onMouseEnter={() => {
                  onHover("profile");
                }}
                onMouseLeave={() => {
                  onHover("profile");
                }}
              >
                <img src={profileHover ? user_icon : user} alt="" />
                관리자 정보
              </li>
            </Link>
            <Link to="#">
              <li
                onMouseEnter={() => {
                  onHover("logout");
                }}
                onMouseLeave={() => {
                  onHover("logout");
                }}
                onClick={onLogout}
              >
                <img src={logoutHover ? logout_sel : logout} alt="" />
                로그아웃
              </li>
            </Link>
          </ul>
        </div>
      </div>
      <div className={onAlarm ? "newAlarmModal active" : "newAlarmModal"} ref={alarmListRef}>
        <div className="alarmHeader">
          <span>알림</span>
          <span onClick={alarmAllCheck}>모두 읽음</span>
        </div>
        <div className="alarmBody">
          {RoleUtils.isFactory(role) && (
            <>
              {laundryRequestAlarm && laundryRequestAlarm?.list.length > 0 && (
                <div className="noticeAlarm">
                  <span>세탁현황</span>
                  {laundryRequestAlarm?.list.map((item, index) => (
                    <ul key={index} onClick={() => laundryPageLocomotion(item.laundryId)}>
                      <li>{item.franchiseName}</li>
                      <li>세탁 신청을 등록 하였습니다.</li>
                    </ul>
                  ))}
                </div>
              )}
            </>
          )}
          {RoleUtils.isMaster(role) ||
            (RoleUtils.isStore(role) && (
              <>
                {laundryCompleteAlarm && laundryCompleteAlarm?.list.length > 0 && (
                  <div className="noticeAlarm">
                    <span>세탁현황</span>
                    {laundryCompleteAlarm?.list.map((item, index) => (
                      <ul key={index} onClick={() => laundryPageLocomotion(item.id)}>
                        <li>{item.factoryName}</li>
                        <li>세탁 완료 등록을 하였습니다.</li>
                      </ul>
                    ))}
                  </div>
                )}
              </>
            ))}

          {boardAlarm && boardAlarm?.list.length > 0 && (
            <div className="noticeAlarm">
              <span>세탁게시판</span>
              {boardAlarm?.list.map((item, index) => (
                <ul key={index} onClick={() => boardPageLocomotion(item.id)}>
                  <li>{item.writer}</li>
                  <li>새로운 글({stateType[item.boardType]})이 등록되었습니다.</li>
                </ul>
              ))}
            </div>
          )}
          {noticeAlarm && noticeAlarm?.list.length > 0 && (
            <div className="noticeAlarm">
              <span>공지사항</span>
              {noticeAlarm?.list.map((item, index) => (
                <ul key={index} onClick={() => noticePageLocomotion(item.id)}>
                  <li>{item.writer}</li>
                  <li>새로운 공지사항이 등록 되었습니다.</li>
                </ul>
              ))}
            </div>
          )}
          {statementAlarm && statementAlarm?.length > 0 && (
            <div className="noticeAlarm">
              <span>거래명세표</span>
              {statementAlarm?.map((alarm, index) => (
                <ul onClick={() => deliveryPageLocomotion(alarm.id)} key={index}>
                  <li>{alarm.company}</li>
                  <li>새로운 {alarm.title.substring(0, 5)}가 전송되었습니다</li>
                </ul>
              ))}
            </div>
          )}
          {deliveryAlarm && deliveryAlarm.length > 0 && (
            <div className="noticeAlarm">
              <span>납품알림</span>
              {deliveryAlarm?.map((alarm, index) => (
                <ul onClick={() => deliveryPageLocomotion(alarm.id)} key={index}>
                  <li>{alarm.company}</li>
                  <li>납품확인을 완료하였습니다.</li>
                </ul>
              ))}
            </div>
          )}
        </div>
      </div>
    </HeaderBlock>
  );
};

export default Header;

const HeaderBlock = styled.div<HeaderBlockProps>`
  position: absolute;
  width: 100%;
  height: 190px;
  background-color: ${Palette.primary};
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    color: black;
    background-color: ${Palette.primary};
    position: relative;
    z-index: 2;
    & > .logoBox {
      width: 230px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      /* padding: 0 5px; */
      /* padding-top: 10px; */
      & > .logo {
        padding: 0;
        margin: 0;
        width: 210px;
        height: 60px;
        position: relative;
        /* background-color: red; */
        & > img {
          display: block;
          width: 85%;
          margin: 0 auto;

          object-fit: contain;
        }
      }
      & > .sizeButton {
        width: 17px;
        height: 14px;
        z-index: 2;
        margin-top: 10px;
        margin-right: 5px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & > span {
          display: block;
          width: 100%;
          height: 2px;
          background-color: #fff;
          transition: 0.3s cubic-bezier(0.97, 0.75, 0.61, 1.84);
        }
        & > span:nth-child(1) {
          width: 8px;
        }
        & > span:nth-child(3) {
          width: 12px;
        }
      }
      & > .sizeButton:hover {
        & > span:nth-child(1) {
          width: 17px;
        }
        & > span:nth-child(3) {
          width: 17px;
        }
      }
    }

    .headerList {
      margin-right: 36px;
      position: relative;
      z-index: 2;
      span {
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: auto;
        color: #fff;
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
          font-size: 30px;
          cursor: pointer;
          & > img {
            width: auto;
            height: 100%;
          }
        }
      }
    }
    .iconBox {
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icons {
        width: 60px;
        cursor: pointer;
      }
    }
  }
  & > .profileModal {
    width: ${(props) => (props.selectType ? "250px" : "0")};
    height: ${(props) => (props.selectType ? "172px" : "0")};
    overflow: hidden;
    position: absolute;
    border-radius: 5px;
    top: 60px;
    right: 36px;
    display: flex;
    flex-direction: column;
    transition: ease-in-out;
    transition-duration: 0.2s;
    & > .profileHeader {
      width: 100%;
      height: 50px;
      line-height: 50px;
      color: #fff;
      background-color: #373a3c;
      font-size: 18px;
      padding-left: 20px;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
    }
    & > .profileBody {
      width: 100%;
      flex: 1;
      padding: 20px 0;
      border: 1px solid #dee2e6;
      background-color: #fff;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      & > ul {
        padding: 0;
        width: 85%;
        height: auto;
        margin: 0 auto;
        & > a {
          color: #333;
          & > li {
            width: 100%;
            height: 40px;
            line-height: 45px;
            display: flex;
            align-items: center;
            padding-left: 10px;
            & > img {
              width: auto;
              height: 20px;
              margin-right: 15px;
            }
          }
          & > li:hover {
            color: #fff;
            cursor: pointer;
            background-color: ${Palette.primary};
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
            border-radius: 5px;
          }
        }
      }
    }
  }
  & > .newAlarmModal {
    width: ${(props) => (props.alarmType ? "348px" : "0")};
    height: ${(props) => (props.alarmType ? "auto" : "0")};
    max-height: 500px;
    background-color: lightsalmon;
    overflow: hidden;
    position: absolute;
    border-radius: 5px;
    top: 60px;
    right: 100px;
    display: flex;
    flex-direction: column;
    transition: ease-in-out;
    transition-duration: 0.2s;
    &.active {
      width: 348px;
      height: auto;
      min-height: 120px;
      height: auto;
    }
    & > .alarmHeader {
      width: 100%;
      height: 50px;
      line-height: 50px;
      color: #fff;
      background-color: #373a3c;
      font-size: 18px;
      padding: 0 20px;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      display: flex;
      justify-content: space-between;
      & > span:nth-child(2) {
        font-size: 12px;
        font-weight: 400;
        cursor: pointer;
      }
      & > span:nth-child(2):hover {
        text-decoration: underline;
      }
    }
    & > .alarmBody {
      flex: 1;
      width: 100%;
      padding: 10px 0;
      border: 1px solid #dee2e6;
      background-color: #fff;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      overflow-y: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
      }
      & > div {
        padding: 0 10px;
        border-bottom: 1px solid #dee2e6;
        margin-bottom: 15px;
        & > span {
          font-size: 13px;
          color: #999;
        }
        & > ul {
          width: 100%;
          height: auto;
          /* margin: 0 auto; */
          cursor: pointer;
          padding: 8px 5px;
          margin: 5px 0;
          border-radius: 5px;
          & > li {
            width: 100%;
            /* cursor: default; */
            cursor: pointer;
          }
          & > li:nth-child(1) {
            font-weight: bold;
            margin-bottom: 5px;
          }
          & > li:nth-child(2) {
            font-size: 13px;
            color: #333;
            /* margin-bottom: 10px; */
          }
        }
        & > ul:hover {
          background-color: #dee2e6;
        }
      }
      & > div:last-child {
        margin-bottom: 0;
        border-bottom: 0;
      }
    }
  }
`;
