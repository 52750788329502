import Button from "components/common/Button";
import FactorySearch from "components/common/FactorySearch";
import Paging from "components/common/Paging";
import {
  AdminFactoryDetail,
  AdminFactoryList,
  AdminGetList,
  AdminRegistration,
} from "modules/admin/types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Palette, ThemeColor } from "styles/palette";
import NumberUtils from "utils/NumberUtils";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

interface AdminFacotryStatusProps {
  role: string;
  factoryRegistration: (payload: AdminRegistration) => void;
  factoryListPageMove: (page: number, data: AdminGetList) => void;
  factoryDetail: (id: number) => void;
  adminFactoryList: AdminFactoryList | null;
  adminFactoryDetail: AdminFactoryDetail | null;
  modifyFactoryUserName: (
    id: number,
    userName: string,
    page: number,
    data: AdminGetList | null
  ) => void;
  postPWInitialization: (id: number) => void;
  factoryRegistrationStatus: () => void;
}

const AdminFactory: React.FC<AdminFacotryStatusProps> = ({
  role,
  factoryRegistration,
  factoryListPageMove,
  factoryDetail,
  adminFactoryList,
  adminFactoryDetail,
  modifyFactoryUserName,
  postPWInitialization,
  factoryRegistrationStatus,
}) => {
  // const [registration, setRegistration] = useState(false);
  // const [detail, setDetail] = useState(false);
  const [searchData, setSearchData] = useState<AdminGetList>({
    query: "",
    dropBox: "",
    startDate: "",
    endDate: "",
    size: 10,
  });

  // const [currentPage, setCurrentPage] = useState<number>(0);

  // const onClose = () => {
  //   setDetail(false);
  //   setRegistration(false);
  // };

  const onDetail = (id: number) => {
    // setDetail(true);
    factoryDetail(id);
  };

  const onSearch = (page: number, data: AdminGetList) => {
    setSearchData(data);
    // setCurrentPage(page);
    factoryListPageMove(page, data);
  };

  const pageMove = (page: number) => {
    // setCurrentPage(page);
    factoryListPageMove(page, searchData ?? null);
  };

  const selectShowEntries = (size: number) => {
    searchData.size = size;
    factoryListPageMove(1, searchData ?? null);
  };

  const [listNumber, setListNumber] = useState<number[]>([]);
  useEffect(() => {
    setListNumber(
      NumberUtils.numberToList(
        adminFactoryList?.currentPage ?? 0,
        adminFactoryList?.totalCount ?? 0,
        searchData.size ?? 10
      )
    );
  }, [adminFactoryList, searchData]);

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      fontWeight: 700,
    },
  });
  const classes = useStyles();

  // const onModifyFactoryUserName = (id: number, userName: string) => {
  //   modifyFactoryUserName(id, userName, currentPage, searchData ?? null);
  // };

  return (
    <AdminFacotryBlock>
      {/* {detail && (
        <AdminFactoryStatusDetail
          role={role}
          onClose={onClose}
          adminFactoryDetail={adminFactoryDetail}
          modifyFactoryUserName={onModifyFactoryUserName}
          postPWInitialization={postPWInitialization}
        />
      )} */}
      <div className="headers">
        <h3>공장 관리</h3>
      </div>
      <div className="tableSelect">
        <div>
          <span>Show </span>
          <select
            onChange={(e) => selectShowEntries(parseInt(e.target.value, 10))}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
          <span> entries</span>
        </div>
        <div className="topWrapper">
          <span>Search: </span>
          <div>
            <FactorySearch
              role={role}
              onSearch={onSearch}
              size={searchData.size ?? 10}
            />
            <Button
              theme={ThemeColor.tertiary}
              buttonSize="m"
              onClick={factoryRegistrationStatus}
            >
              등록
            </Button>
          </div>
        </div>
      </div>
      <div className="tableWrapper">
        <TableContainer component={Paper} className="tables">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "70px" }}
                >
                  순번
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900 }}>
                  아이디
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "20%" }}
                >
                  공장명
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "20%" }}
                >
                  연락처
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "20%" }}
                >
                  누적 세탁건수
                </TableCell>
                {/* <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "15%" }}
                >
                  관리지역
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "15%" }}
                >
                  상태
                </TableCell> */}
                {/* <TableCell
                  align="center"
                  style={{ fontWeight: 900 }}
                ></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody className="tableBody">
              {adminFactoryList?.list.map((factory, index) => (
                <TableRow
                  key={index}
                  className="tableHover"
                  onClick={() => onDetail(factory.id)}
                >
                  <TableCell component="th" scope="row" align="center">
                    {listNumber[index]}
                  </TableCell>
                  <TableCell align="center">{factory.username}</TableCell>
                  <TableCell align="center">{factory.companyName}</TableCell>
                  <TableCell align="center">{factory.companyPhone}</TableCell>
                  <TableCell align="center">
                    {" "}
                    {NumberUtils.numberToComma(factory.totalBillingAmount ?? 0)}
                  </TableCell>
                  {/* <TableCell align="center">{factory.managingArea}</TableCell>
                  <TableCell align="center">
                    {FactoryUtils.operationStatusToString(factory.status)}
                  </TableCell> */}
                  {/* <TableCell align="center">
                    <Button
                      theme={ThemeColor.primary}
                      buttonSize="s"
                      onClick={() => onDetail(factory.id)}
                    >
                      상세보기
                    </Button>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Paging
          listAction={pageMove}
          listCount={adminFactoryList?.totalPage ?? 1}
          currentPage={adminFactoryList?.currentPage}
          totalCount={adminFactoryList?.totalCount ?? 1}
          size={searchData.size ?? 10}
        ></Paging>
      </div>
    </AdminFacotryBlock>
  );
};

const AdminFacotryBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  .tables {
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .tableHover > td {
    padding: 10px;
  }
  .tableHover:hover {
    background-color: #eee;
    cursor: pointer;
  }
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  .tableSelect {
    height: 70px;
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: space-between;
    & > div {
      color: #999;
      height: 40px;
      overflow: hidden;
      & > span {
        font-size: 14px;
        line-height: 37px;
      }
      & > select {
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        transition: ease-in-out;
        transition-duration: 0.1s;
        border-radius: 3px;
        /* padding: 4px; */
      }
      & > select:hover {
        border-bottom: 2px solid ${Palette.primary};
      }
    }
  }
  .topWrapper {
    display: flex;
    justify-content: flex-end;
    & > span {
      font-size: 14px;
      line-height: 37px;
      margin-right: 5px;
    }
    & > div {
      width: auto;
      display: inline-block;

      & > div {
        margin: 0;
        margin-left: 5px;
      }
    }
  }
  .tableWrapper {
    min-width: 51rem;
    padding-bottom: 1.5rem;
  }
  .tableBody {
    tr {
      height: 53px !important;
      td {
        padding: 0;
      }
    }
  }
`;

export default AdminFactory;
