import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Palette } from "styles/palette";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import NumberUtils from "utils/NumberUtils";
import Paging from "components/common/Paging";
import { AdminCalculateListType, AdminGetList, AdminPayManagement } from "modules/admin/types";

import TypeUtils from "utils/TypeUtils";
import PayManagementSearch from "components/common/PayManagementSearch";
import { CalculateListSearchTypes } from "interface/Admin/CalculateListTypes";
import CalculateSearchBox from "components/common/SearchBox/CalculateSearchBox/CalculateSearchBox";

interface AdminCalculateManagementProps {
  calculateList: AdminCalculateListType[] | [];
  pageInfo: { totalCount: number; totalPage: number };
  pathname: string;
  searchData: CalculateListSearchTypes;
  listType: (type: string) => void;
  onSubmit: (data?: CalculateListSearchTypes) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  goPage: (id: number) => void;
}

const AdminCalculateManagement: React.FC<AdminCalculateManagementProps> = (
  props: AdminCalculateManagementProps
) => {
  const [listNumber, setListNumber] = useState<number[]>([]);

  useEffect(() => {
    setListNumber(
      NumberUtils.numberToList(
        props.searchData?.page ?? 0,
        props.pageInfo?.totalCount ?? 0,
        props.searchData.limit ?? 10
      )
    );
  }, [props.pageInfo, props.searchData]);

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      fontWeight: 700,
    },
  });
  const classes = useStyles();

  /** 리스트 사이즈 변경 */
  const selectShowEntries = (size: number) => {
    let data = { ...props.searchData };
    data.limit = size;

    props.onSubmit(data);
  };

  /** 페이지 변경, 이동 */
  const pageMove = (page: number) => {
    let data = { ...props.searchData };
    data.page = page;

    props.onSubmit(data);
  };

  /** 검색 요청 */
  const onSearch = () => {
    props.onSubmit();
  };

  return (
    <AdminCalculateManagementBlock>
      <div className="headers">
        <h3>정산 관리</h3>
        <div>
          <span
            className={props.pathname === "sales" ? "selectClick on" : "selectClick"}
            onClick={() => props.listType("sales")}
          >
            매출
          </span>
          <span>|</span>
          <span
            className={props.pathname === "purchase" ? "selectClick on" : "selectClick"}
            onClick={() => props.listType("purchase")}
          >
            매입
          </span>
        </div>
      </div>
      <div className="tableSelect">
        <div>
          <span>Show </span>
          <select onChange={(e) => selectShowEntries(parseInt(e.target.value, 10))}>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
          <span> entries</span>
        </div>
        <div className="topWrapper">
          <div>
            <CalculateSearchBox
              searchData={props.searchData}
              onSearch={onSearch}
              onChange={props.onChange}
            />
          </div>
        </div>
      </div>
      <div className="tableWrapper">
        <TableContainer component={Paper} className="tables">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: 900, width: "70px" }}>
                  순번
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900 }}>
                  {props.pathname === "sales" && "지점명"}
                  {props.pathname === "purchase" && "세탁공장명"}
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900, width: "20%" }}>
                  정산기간
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900, width: "10%" }}>
                  {props.pathname === "sales" && "계약방식"}
                  {props.pathname === "purchase" && "등록일시"}
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900, width: "15%" }}>
                  정산월
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900, width: "15%" }}>
                  정산금액(원)
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900, width: "10%" }}>
                  세금계산서
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900, width: "10%" }}>
                  정산상태
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="tableBody">
              {props.calculateList.map((pay, index) => (
                <TableRow key={index} className="tableHover" onClick={() => props.goPage(pay.id)}>
                  <TableCell component="th" scope="row" align="center">
                    {listNumber[index]}
                  </TableCell>
                  <TableCell align="center">
                    {props.pathname === "sales" && (pay.franchiseName ?? "-")}
                    {props.pathname === "purchase" && (pay.factoryName ?? "-")}
                  </TableCell>
                  <TableCell align="center">1일~말일</TableCell>
                  <TableCell align="center">{pay.contractBasis}</TableCell>
                  <TableCell align="center">{pay.billingMonth ?? "-"}</TableCell>
                  <TableCell align="center">
                    {pay.estimatedAmount
                      ? NumberUtils.numberToComma(Number(pay.estimatedAmount))
                      : "-"}
                  </TableCell>
                  <TableCell align="center">{pay.taxStatus}</TableCell>
                  <TableCell align="center">{pay.billingStatus}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Paging
          listAction={pageMove}
          listCount={props.pageInfo?.totalPage ?? 1}
          currentPage={props.searchData?.page}
          totalCount={props.pageInfo?.totalCount ?? 1}
          size={props.searchData.limit ?? 10}
        ></Paging>
      </div>
    </AdminCalculateManagementBlock>
  );
};

export default AdminCalculateManagement;

const AdminCalculateManagementBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  .tables {
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .tableHover > td {
    padding: 10px;
  }
  .tableHover:hover {
    background-color: #eee;
    cursor: pointer;
  }
  & > .headers {
    height: 70px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid black;
    position: relative;

    & > div {
      width: 80px;
      height: 20px;
      display: flex;
      justify-content: space-between;
      margin-left: 20px;
      padding-top: 5px;
      /* background-color: red; */
      & > span {
        color: #dee2e6;
        font-size: 14px;
      }
      & > .selectClick {
        cursor: pointer;
      }
      & > .on {
        color: ${Palette.primary};
        font-weight: bold;
      }
      & > .selectClick:hover {
        color: ${Palette.primary};
        font-weight: bold;
      }
      & > span:nth-child(2) {
        font-size: 12px;
        align-self: center;
        cursor: default;
        margin: 0 10px;
        /* padding-top: 5px; */
        color: #333;
      }
    }
  }
  .tableSelect {
    height: 70px;
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: space-between;
    & > div {
      color: #999;
      height: 40px;
      overflow: hidden;
      & > span {
        font-size: 14px;
        line-height: 37px;
      }
      & > select {
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        transition: ease-in-out;
        transition-duration: 0.1s;
        border-radius: 3px;
        /* padding: 4px; */
      }
      & > select:hover {
        border-bottom: 2px solid ${Palette.primary};
      }
    }
  }
  .topWrapper {
    display: flex;
    justify-content: flex-end;
    & > span {
      font-size: 14px;
      line-height: 37px;
      margin-right: 5px;
    }
    & > div {
      /* width: auto;
      display: inline-block; */

      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div {
        margin: 0;
        margin-left: 5px;
      }
    }
  }
  .tableWrapper {
    min-width: 51rem;
    padding-bottom: 1.5rem;
  }
  .tableBody {
    tr {
      height: 53px !important;
      td {
        padding: 0;
      }
    }
  }
`;
