import axios from "api/defaultClient";
import { CalculateListSearchTypes } from "interface/Admin/CalculateListTypes";
import {
  AdminAssetItemReqType,
  AdminCalculateModifyReqType,
  AdminFranchiseContract,
  AdminGetList,
  AdminMatchingData,
  AdminRegistration,
  AdminTagGetList,
} from "modules/admin/types";
import { ProfileModify } from "modules/user/types";

export const getDashBoard = (day: string, keyType: string) => {
  return axios.get(`/admin/dashboard`, {
    params: {
      date: day,
      type: keyType,
    },
  });
};

export const getAdminLaundryList = (page: number, data: AdminGetList | null) => {
  if (data) {
    return axios.get(
      `/admin/laundry?page=${page}&query=${data?.query}&startDate=${data?.startDate}&endDate=${data?.endDate}&dropBox=${data?.dropBox}&size=${data.size}`
    );
  }
  if (!data) return axios.get(`/admin/laundry?page=${page}`);
};

export const getAdminLaundryDetail = (id: number) => {
  return axios.get(`/admin/laundry/${id}`);
};

export const getAdminPaymentList = (page: number, data: AdminGetList | null) => {
  if (data) {
    return axios.get(
      `/admin/billing?page=${page}&query=${data?.query}&dropBox=${data?.dropBox}&size=${data.size}`
    );
  }
  if (!data) return axios.get(`/admin/billing?page=${page}`);
};

export const getAdminPaymentDetail = (id: number) => {
  return axios.get(`/admin/billing/${id}`);
};

export const postAdminBrandRegistration = (payload: AdminRegistration) => {
  return axios.post(`/admin/brand`, payload);
};

export const postAdminFactoryRegistration = (payload: AdminRegistration) => {
  return axios.post(`/admin/factory`, payload);
};

export const getAdminStoreList = (page: number, data: AdminGetList | null) => {
  if (data) {
    return axios.get(
      `/admin/franchise?page=${page}&query=${data?.query}&startDate=${data?.startDate}&endDate=${data?.endDate}&dropBox=${data?.dropBox}&size=${data.size}`
    );
  }
  if (!data) return axios.get(`/admin/franchise?page=${page}`);
};

export const getAdminStoreDetail = (id: number) => {
  return axios.get(`/admin/franchise/${id}`);
};

export const getAdminFactoryList = (page: number, data: AdminGetList | null) => {
  if (data) {
    return axios.get(
      `/admin/factory?page=${page}&query=${data?.query}&dropBox=${data?.dropBox}&size=${data.size}`
    );
  }
  if (!data) return axios.get(`/admin/factory?page=${page}`);
};

export const getAdminFactoryDetail = (id: number) => {
  return axios.get(`/admin/factory/${id}`);
};

export const getAdminMatchingFactoryList = () => {
  return axios.get(`/admin/factorylist`);
};

export const patchAdminMatchingFactory = (payload: AdminMatchingData) => {
  return axios.patch(`/admin/franchise`, payload);
};

export const getAdminBrandDetail = (id: number) => {
  return axios.get(`/admin/brand/${id}`);
};

export const getAdminBrandList = (page: number, data: AdminGetList | null) => {
  if (data) {
    return axios.get(
      `/admin/brand?page=${page}&query=${data?.query}&dropBox=${data?.dropBox}&size=${data.size}`
    );
  }
  if (!data) return axios.get(`/admin/brand?page=${page}`);
};

export const getAdminProfile = () => {
  return axios.get(`/admin/me`);
};

export const patchAdminProfileModify = (payload: ProfileModify) => {
  return axios.patch(`/admin/updateme`, payload);
};

export const getAdminPaymentDetailBrandData = (id: number, data: AdminGetList | null) => {
  if (data?.startDate && data?.endDate) {
    return axios.get(
      `/admin/billing-brand?brandId=${id}&startDate=${data.startDate}&endDate=${data.endDate}`
    );
  } else {
    return axios.get(`/admin/billing-brand?brandId=${id}`);
  }
};

export const getAdminPaymentDetailFactoryData = (id: number, data: AdminGetList | null) => {
  if (data?.startDate && data?.endDate) {
    return axios.get(
      `/admin/billing-factory?factoryId=${id}&startDate=${data.startDate}&endDate=${data.endDate}`
    );
  } else {
    return axios.get(`/admin/billing-factory?factoryId=${id}`);
  }
};

export const patchAdminUserNameModify = (id: number, userName: string) => {
  const data = {
    userId: id,
    newUsername: userName,
  };
  return axios.patch(`/admin/username`, data);
};

export const getAdminSpecificationsList = (
  id: number | null,
  page: number,
  data: AdminGetList | null
) => {
  if (data) {
    return axios.get(
      `/admin/history?page=${page}&limit=${data.size}&startDate=${data.startDate}&endDate=${
        data.endDate
      }&frId=${id ?? ""}`
    );
  }
  return axios.get(`/admin/history?page=${page}&limit=10`);
};

export const getAdminLaundryTableData = (id: number, startDate: string, endDate: string) => {
  return axios.get(`/admin/excel-franchise/${id}?startDate=${startDate}&endDate=${endDate}`);
};

export const postPWInitialization = (id: number) => {
  return axios.post(`/admin/reset/${id}`);
};

// 본사에서 가입유저 정보 수정
export const patchFactoryInfo = (id: number, payload: ProfileModify) => {
  return axios.patch(`/admin/factory/updateme/${id}`, payload);
};

export const patchFranchiseInfo = (id: number, payload: ProfileModify) => {
  return axios.patch(`/admin/franchise/updateme/${id}`, payload);
};

export const patchBrandInfo = (id: number, payload: ProfileModify) => {
  return axios.patch(`/admin/brand/updateme/${id}`, payload);
};

export const getIdDuplicateCheck = (id: string) => {
  return axios.get(`/user/duplicate?id=${id}`);
};

//  본사 정산현황 리스트
// 본사
export const getAdminPaymentListData = (data: AdminGetList | null) => {
  if (data?.startDate && data?.endDate) {
    return axios.get(`/admin/billing-list?startDate=${data.startDate}&endDate=${data.endDate}`);
  } else {
    return axios.get(`/admin/billing-list`);
  }
};

// 세탁공장
export const getAdminPaymentFactoryListData = (page: number, data: AdminGetList | null) => {
  if (data) {
    let startDate = "";
    let endDate = "";
    let query = "";
    let size = "";
    if (data.startDate) startDate = `&startDate=${data.startDate}`;
    if (data.endDate) endDate = `&endDate=${data.endDate}`;
    if (data.query) query = `&query=${data.query}`;
    if (data.size) size = `&limit=${data.size}`;
    return axios.get(
      `/admin/billing-list-factory?page=${page}` +
        `${startDate}` +
        `${endDate}` +
        `${query}` +
        `${size}`
    );
  } else {
    return axios.get(`/admin/billing-list-factory?page=${page}`);
  }
};

// 브랜드
export const getAdminPaymentBrandListData = (page: number, data: AdminGetList | null) => {
  if (data) {
    let startDate = "";
    let endDate = "";
    let query = "";
    let size = "";
    if (data.startDate) startDate = `&startDate=${data.startDate}`;
    if (data.endDate) endDate = `&endDate=${data.endDate}`;
    if (data.query) query = `&query=${data.query}`;
    if (data.size) size = `&limit=${data.size}`;
    return axios.get(
      `/admin/billing-list-brand?page=${page}` +
        `${startDate}` +
        `${endDate}` +
        `${query}` +
        `${size}`
    );
  } else {
    return axios.get(`/admin/billing-list-brand?page=${page}`);
  }
};

// 어드민 지점 계약설정
export const postAdminFranchiseContract = (payload: AdminFranchiseContract) => {
  return axios.post(`/admin/contract`, payload);
};

// 어드민 결제관리 api
export const getAdminPaymanagementList = (
  page: number,
  data: AdminGetList | null,
  type: string,
  drop: string
) => {
  let typeData = "";
  if (type === "complete") typeData = `&type=COMPLETE`;
  if (drop === "WAITING" || drop === "TAX_INVOICE") typeData = `&type=${drop}`;
  if (data) {
    return axios.get(
      `/history/payment?page=${page}&query=${data?.query}&startDate=${data?.startDate}&endDate=${data?.endDate}&dropBox=${data?.dropBox}&limit=${data.size}` +
        typeData
    );
  }
  return axios.get(`/history/payment?page=${page}` + typeData);
};

export const patchAdminPaymanagement = (id: number, paymentStatus: string) => {
  let data = {
    id,
    paymentStatus,
  };
  return axios.patch(`/history/payment`, data);
};

// PRO ver. -----------------------------------------------------------------------------------------

// 해당품목의 태그리스트 조회

export const getLaundryItemList = (page: number, frId: number, limit: number) => {
  return axios.get(`/tag/asset-list/${frId}?frId=${frId}&page=${page}&limit=${limit}`);
};

export const getLaundryTagList = (page: number, data: AdminTagGetList | null) => {
  if (data) {
    return axios.get(
      `/tag/asset?page=${page}&assetId=${data.assetId}&frId=${data.frId}&limit=${data.limit}&sort=${data.sort}`
    );
  }
  return axios.get(`/tag/asset?page=${page}`);
};

// 접속 기록 가져오기

export const getAccessHistory = (page: number, query: string, size: number) => {
  return axios.get(`/user/login/history?page=${page}&query=${query}&limit=${size}`);
};

// 정산관리 매출 리스트 api
export const getCalculateListApi = (data: CalculateListSearchTypes) => {
  return axios.get(`/sales`, {
    params: {
      page: data.page,
      limit: data.limit,
      billingStatus: data.billingStatus,
      taxStatus: data.taxStatus,
      query: data.query,
    },
  });
};

// 정산관리 매출 상세 api
export const getCalculateDetailApi = (id: number) => {
  return axios.get(`/sales/${id}`);
};

// 정산관리 매입 리스트 api
export const getCalculatePurchaseListApi = (data: CalculateListSearchTypes) => {
  return axios.get(`/sales/purchase`, {
    params: {
      page: data.page,
      limit: data.limit,
      billingStatus: data.billingStatus,
      taxStatus: data.taxStatus,
      query: data.query,
    },
  });
};

// 정산관리 매입 상세 api
export const getCalculatePurchaseDetailApi = (id: number) => {
  return axios.get(`/sales/purchase/${id}`);
};

// 정산관리 매출 수정 api
export const patchCalculateApi = (data: AdminCalculateModifyReqType) => {
  return axios.patch(`/sales`, data);
};

// 정산관리 매입 수정 api
export const patchCalculatePurchaseApi = (data: AdminCalculateModifyReqType) => {
  return axios.patch(`/sales/purchase`, data);
};

/** 자산 삭제 */
export const deleteAssetDataApi = (id: number) => {
  return axios.delete(`/asset/${id}`);
};

/** 자산 등록 */
export const postAddAssetDataApi = (data: AdminAssetItemReqType) => {
  return axios.post(`/asset`, data);
};

/** 자산 정보 수정 */
export const patchModifyAssetDataApi = (data: AdminAssetItemReqType) => {
  return axios.patch(`/asset`, data);
};

/** 브랜드 삭제 */
export const deleteAdminBrandApi = (id: number) => {
  return axios.delete(`/admin/brand/${id}`);
};

/** 지점 삭제 */
export const deleteAdminFranchiseApi = (id: number) => {
  return axios.delete(`/admin/franchise/${id}`);
};
