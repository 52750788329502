import Button from "components/common/Button";
import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { ThemeColor } from "styles/palette";

interface UnAuthorizedProps {
  title: string;
}

const UnAuthorized: React.FC<UnAuthorizedProps> = ({ title }) => {
  const history = useHistory();
  return (
    <UnAuthorizedBlock>
      <div>
        <h1>{title}</h1>
        <Button
          theme={ThemeColor.primary}
          buttonSize="l"
          onClick={() => history.push("/")}
        >
          돌아가기
        </Button>
      </div>
    </UnAuthorizedBlock>
  );
};

export default UnAuthorized;

const UnAuthorizedBlock = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    text-align: center;
    h1 {
      margin-bottom: 2rem;
    }
  }
`;
