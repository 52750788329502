import FactoryLaundryStatus from "components/factory/FactoryLaundryStatus";
import FactoryLaundaryStatusDetail from "components/factory/FactoryLaundryStatusDetail";
import { IRootState } from "modules";
import {
  deleteFactoryLaundryDataAction,
  getFactoryLaundryDetailAction,
  getFactoryLaundryListAction,
  patchFactoryLaundryClassificationAction,
  postFactoryLaundryClassificationAction,
} from "modules/factory/factory";
import {
  FactoryGetList,
  FactoryLaundryDetail,
  LaundryClassification,
} from "modules/factory/types";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

interface FactoryLaundryStatusContainerProps {
  match: any;
}

const FactoryLaundryStatusContainer: React.FC<
  FactoryLaundryStatusContainerProps
> = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [laundryDetailData, setLaundryDetailData] =
    useState<FactoryLaundryDetail | null>();

  const role = useSelector((state: IRootState) => state.user.profile?.type);

  const factoryLaundryList = useSelector(
    (state: IRootState) => state.factory.factoryLaundryList
  );
  const factoryLaundryDetail = useSelector(
    (state: IRootState) => state.factory.factoryLaundryDetail
  );

  const [toggle, setToggle] = useState(false);
  const [enrollToggle, setEnrollToggle] = useState(false);

  const [page, setPage] = useState<number>(1);
  const [searchData, setSearchData] = useState<FactoryGetList | null>({
    query: "",
    dropBox: "",
    startDate: "",
    endDate: "",
    size: 10,
  });

  const [detailId, setDetailId] = useState<number | null>(null);
  const [selectType, setSelectType] = useState<string>("list");

  // useEffect(() => {
  //   setInterval(() => {
  //     dispatch(getFactoryLaundryListAction(page, searchData ?? null));
  //   }, 5 * 60 * 1000);
  // }, []);

  useEffect(() => {
    let sortData: string = "";
    const params = new URLSearchParams(window.location.search);
    if (params.get("sort")) {
      sortData = String(params.get("sort"));
      dispatch(getFactoryLaundryListAction(page, searchData, sortData));
    } else {
      dispatch(getFactoryLaundryListAction(1, null));
    }
  }, [dispatch]);

  useEffect(() => {
    setLaundryDetailData(factoryLaundryDetail ?? null);
  }, [factoryLaundryDetail, laundryDetailData]);

  useEffect(() => {
    if (match.params.id && Number(match.params.id)) {
      setDetailId(match.params.id);
      setSelectType("detail");
      dispatch(
        getFactoryLaundryDetailAction(match.params.id, page, searchData ?? null)
      );
    } else {
      setSelectType("list");
    }
  }, [match.params.id]);

  const query = window.location.pathname;
  const params = new URLSearchParams(window.location.search);

  const urlParams = new URL(window.location.href);

  useEffect(() => {
    if (query === `/factory/laundry`) {
      setSelectType("list");
      setDetailId(null);

      if (urlParams.search) {
        let sortData: string = "";
        if (params.get("sort")) {
          sortData = String(params.get("sort"));
        }

        let queryPage = params.get("page") ? Number(params.get("page")) : page;

        let data = {
          query: params.get("query") ?? "",
          dropBox: params.get("dropBox") ?? "",
          startDate: params.get("startDate") ?? "",
          endDate: params.get("endDate") ?? "",
          size: params.get("size") ? Number(params.get("size")) : 10,
        };

        dispatch(getFactoryLaundryListAction(queryPage, data, sortData));
      } else {
        dispatch(getFactoryLaundryListAction(page, searchData, ""));
      }
    } else {
      setSelectType("detail");
    }
  }, [urlParams.search]);

  const onToggle = () => {
    // setToggle(!toggle);
  };
  const onEnrollToggle = (type: boolean) => {
    setEnrollToggle(type);
  };

  const lanudryList = (
    page: number,
    data: FactoryGetList | null,
    sort: string
  ) => {
    setPage(page);
    setSearchData(data);
    dispatch(getFactoryLaundryListAction(page, data, sort));
  };

  const laundryDetail = (
    id: number,
    page: number,
    data: FactoryGetList | null
  ) => {
    setDetailId(id);
    dispatch(getFactoryLaundryDetailAction(id, page, data));
    setSelectType("detail");
    history.push(`/factory/laundry/${id}`);
  };

  const laundryDetailLite = (
    id: number,
    page: number,
    data: FactoryGetList | null,
    sort?: string
  ) => {
    dispatch(getFactoryLaundryDetailAction(id, page, data, sort));
  };

  const onClose = () => {
    setDetailId(null);
    setSelectType("list");
    history.push(`/factory/laundry`);
  };

  const laundryClassification = (
    payload: LaundryClassification,
    page: number,
    data: FactoryGetList | null,
    sort?: string
  ) => {
    dispatch(postFactoryLaundryClassificationAction(payload, page, data, sort));
    onEnrollToggle(false);
  };

  const modifyLaundryClassification = (
    payload: LaundryClassification,
    page: number,
    data: FactoryGetList | null,
    sort?: string
  ) => {
    dispatch(
      patchFactoryLaundryClassificationAction(payload, page, data, sort)
    );
    onEnrollToggle(false);
  };

  const deleteLaundryData = (id: number) => {
    dispatch(deleteFactoryLaundryDataAction(id, page, searchData ?? null));
    onClose();
  };

  return (
    <>
      {selectType === "list" && (
        <FactoryLaundryStatus
          role={role}
          toggle={toggle}
          onToggle={onToggle}
          setDetailId={setDetailId}
          enrollToggle={enrollToggle}
          onEnrollToggle={onEnrollToggle}
          factoryLaundryList={factoryLaundryList}
          factoryLaundryDetail={factoryLaundryDetail ?? null}
          laundryDetail={laundryDetail}
          laundryDetailLite={laundryDetailLite}
          laundryClassification={laundryClassification}
          lanudryList={lanudryList}
          modifyLaundryClassification={modifyLaundryClassification}
        />
      )}
      {selectType === "detail" && (
        <FactoryLaundaryStatusDetail
          onClose={onClose}
          factoryLaundryDetail={factoryLaundryDetail}
          onDeleteLaundryData={deleteLaundryData}
        />
      )}
    </>
  );
};

export default FactoryLaundryStatusContainer;
