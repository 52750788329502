import { AxiosResponse } from "axios";
import { call, takeEvery, put } from "redux-saga/effects";
import * as factoryApi from "api/factoryApi";
import * as storeApi from "api/storeApi";
import {
  FactoryDashBoardData,
  BillingData,
  FactoryAction,
  FactoryBillingDetail,
  FactoryBillingList,
  FactoryGetList,
  FactoryItemizedAccountExcel,
  FactoryItemizedAccountPDF,
  FactoryItemizedAccountSearch,
  FactoryLaundryDetail,
  FactoryLaundryList,
  FactoryMatchedFranchiseData,
  FactoryMatchingFranchiseList,
  IFactoryState,
  LaundryClassification,
  LaundryDataList,
  FactorySpecificationsList,
  FactoryExcelMonth,
  LaundryDataListDetail,
  FactoryFranchiseProgressList,
  FactoryTableReadingData,
  FactoryTableRequest,
  FactoryGetTagData,
  FactoryGetTagResponseData,
  FactoryTableDirectData,
  FactoryFranchiseLaundryDate,
  LabelSearchType,
  LabelExportDataListType,
} from "./types";
import { AdminGetList } from "modules/admin/types";
import { getLaundryRequestAlarmAction } from "modules/user/user";

const GET_FACTORY_DASHBOARD = "GET_FACTORY_DASHBOARD" as const;
const GET_FACTORY_DASHBOARD_SUCCESS = "GET_FACTORY_DASHBOARD_SUCCESS" as const;

const GET_FACTORY_LAUNDRY_LIST = "GET_FACTORY_LAUNDRY_LIST" as const;
const GET_FACTORY_LAUNDRY_LIST_SUCCESS =
  "GET_FACTORY_LAUNDRY_LIST_SUCCESS" as const;

const GET_FACTORY_LAUNDRY_DETAIL = "GET_FACTORY_LAUNDRY_DETAIL" as const;
const GET_FACTORY_LAUNDRY_DETAIL_SUCCESS =
  "GET_FACTORY_LAUNDRY_DETAIL_SUCCESS" as const;

const POST_FACTORY_LAUNDRY_CLASSIFICATION =
  "POST_FACTORY_LAUNDRY_CLASSIFICATION" as const;
const POST_FACTORY_LAUNDRY_CLASSIFICATION_SUCCESS =
  "POST_FACTORY_LAUNDRY_CLASSIFICATION_SUCCESS" as const;

const PATCH_FACTORY_LAUNDRY_CLASSIFICATION =
  "PATCH_FACTORY_LAUNDRY_CLASSIFICATION" as const;
const PATCH_FACTORY_LAUNDRY_CLASSIFICATION_SUCCESS =
  "PATCH_FACTORY_LAUNDRY_CLASSIFICATION_SUCCESS" as const;

const DELETE_FACTORY_LAUNDRY_DATA = "DELETE_FACTORY_LAUNDRY_DATA" as const;
const DELETE_FACTORY_LAUNDRY_DATA_SUCCESS =
  "DELETE_FACTORY_LAUNDRY_DATA_SUCCESS" as const;

const GET_FACTORY_BILLING_LIST = "GET_FACTORY_BILLING_LIST" as const;
const GET_FACTORY_BILLING_LIST_SUCCESS =
  "GET_FACTORY_BILLING_LIST_SUCCESS" as const;

const GET_FACTORY_BILLING_DETAIL = "GET_FACTORY_BILLING_DETAIL" as const;
const GET_FACTORY_BILLING_DETAIL_SUCCESS =
  "GET_FACTORY_BILLING_DETAIL_SUCCESS" as const;

const PATCH_FACTORY_BILLING_DATA = "PATCH_FACTORY_BILLING_DATA" as const;
const PATCH_FACTORY_BILLING_DATA_SUCCESS =
  "PATCH_FACTORY_BILLING_DATA" as const;

const GET_FACTORY_MATCHING_FRANCHISE_LIST =
  "GET_FACTORY_MATCHING_FRANCHISE_LIST" as const;
const GET_FACTORY_MATCHING_FRANCHISE_LIST_SUCCESS =
  "GET_FACTORY_MATCHING_FRANCHISE_LIST_SUCCESS" as const;

const GET_FACTORY_ITEMIZED_ACCOUNT = "GET_FACTORY_ITEMIZED_ACCOUNT" as const;
const GET_FACTORY_ITEMIZED_ACCOUNT_SUCCESS =
  "GET_FACTORY_ITEMIZED_ACCOUNT_SUCCESS" as const;

const GET_FACTORY_MATCHED_FRANCHISE_DATA =
  "GET_FACTORY_MATCHED_FRANCHISE_DATA" as const;
const GET_FACTORY_MATCHED_FRANCHISE_DATA_SUCCESS =
  "GET_FACTORY_MATCHED_FRANCHISE_DATA_SUCCESS" as const;

const POST_FACTORY_LAUNDRY_SIGN_UP = "POST_FACTORY_LAUNDRY_SIGN_UP" as const;
const POST_FACTORY_LAUNDRY_SIGN_UP_SUCCESS =
  "POST_FACTORY_LAUNDRY_SIGN_UP_SUCCESS" as const;

const GET_FACTORY_DELIVERY_EXCEL_DATA =
  "GET_FACTORY_DELIVERY_EXCEL_DATA" as const;
const GET_FACTORY_DELIVERY_EXCEL_DATA_SUCCESS =
  "GET_FACTORY_DELIVERY_EXCEL_DATA_SUCCESS" as const;

const GET_FACTORY_LAUNDRY_TABLE = "GET_FACTORY_LAUNDRY_TABLE" as const;
const GET_FACTORY_LAUNDRY_TABLE_SUCCESS =
  "GET_FACTORY_LAUNDRY_TABLE_SUCCESS" as const;

const POST_FACTORY_TABLE_SHARE = "POST_FACTORY_TABLE_SHARE" as const;
const POST_FACTORY_TABLE_SHARE_SUCCESS =
  "POST_FACTORY_TABLE_SHARE_SUCCESS" as const;

const GET_FACTORY_SPECIFICATIONS_LIST =
  "GET_FACTORY_SPECIFICATIONS_LIST" as const;
const GET_FACTORY_SPECIFICATIONS_LIST_SUCCESS =
  "GET_FACTORY_SPECIFICATIONS_LIST_SUCCESS" as const;

const GET_FACTORY_LAUNDRY_TABLE_YEAR =
  "GET_FACTORY_LAUNDRY_TABLE_YEAR" as const;
const GET_FACTORY_LAUNDRY_TABLE_YEAR_SUCCESS =
  "GET_FACTORY_LAUNDRY_TABLE_YEAR_SUCCESS" as const;

const DELETE_FACTORY_STATEMENT = "DELETE_FACTORY_STATEMENT" as const;

const GET_FACTORY_FRANCHISE_PROGRESS =
  "GET_FACTORY_FRANCHISE_PROGRESS" as const;
const GET_FACTORY_FRANCHISE_PROGRESS_SUCCESS =
  "GET_FACTORY_FRANCHISE_PROGRESS_SUCCESS" as const;

const GET_FACTORY_MAC_ADDRESS = "GET_FACTORY_MAC_ADDRESS" as const;
const GET_FACTORY_MAC_ADDRESS_SUCCESS =
  "GET_FACTORY_MAC_ADDRESS_SUCCESS" as const;

const POST_FACTORY_MAC_ADDRESS = "POST_FACTORY_MAC_ADDRESS" as const;

const POST_FACTORY_TAG_REQUEST = "POST_FACTORY_TAG_REQUEST" as const;

const POST_FACTORY_TAG_LAUNDRY_COMPLETE =
  "POST_FACTORY_TAG_LAUNDRY_COMPLETE" as const;

const GET_FACTORY_TAG_DATA = "GET_FACTORY_TAG_DATA" as const;
const GET_FACTORY_TAG_DATA_SUCCESS = "GET_FACTORY_TAG_DATA_SUCCESS" as const;

const POST_RE_LAUNDRY_REQUEST = "POST_RE_LAUNDRY_REQUEST" as const;

const POST_DIRECT_AMOUNT_COMPLETE = "POST_DIRECT_AMOUNT_COMPLETE" as const;
const POST_DIRECT_RE_LAUNDRY = "POST_DIRECT_RE_LAUNDRY" as const;

const GET_FACTORY_FRANCHISE_LAUNDRAY_DATE =
  "GET_FACTORY_FRANCHISE_LAUNDRAY_DATE" as const;
const GET_FACTORY_FRANCHISE_LAUNDRAY_DATE_SUCCESS =
  "GET_FACTORY_FRANCHISE_LAUNDRAY_DATE_SUCCESS" as const;

const GET_FACTORY_FRANCHISE_LAUNDRY_COMPLETE_DATE =
  "GET_FACTORY_FRANCHISE_LAUNDRY_COMPLETE_DATE" as const;
const GET_FACTORY_FRANCHISE_LAUNDRY_COMPLETE_DATE_SUCCESS =
  "GET_FACTORY_FRANCHISE_LAUNDRY_COMPLETE_DATE_SUCCESS" as const;

// 테이블리더기 웹소켓 서버 활성화
const ACTIVATION_TABLEREADER_SERVER = "ACTIVATION_TABLEREADER_SERVER" as const;

// 라벨 출력한 데이터 가져오기
const LABEL_EXPORT_DATA = "LABEL_EXPORT_DATA" as const;
const LABEL_EXPORT_DATA_SUCCESS = "LABEL_EXPORT_DATA_SUCCESS" as const;

export const getFactoryDashboardAction = (day: string) => ({
  type: GET_FACTORY_DASHBOARD,
  day,
});
export const getFactoryDashboardSuccessAction = (
  factoryDashBoard: FactoryDashBoardData
) => ({
  type: GET_FACTORY_DASHBOARD_SUCCESS,
  factoryDashBoard,
});

export const getFactoryLaundryListAction = (
  page: number,
  data: FactoryGetList | null,
  sort?: string
) => ({
  type: GET_FACTORY_LAUNDRY_LIST,
  page,
  data,
  sort,
});

export const getFactoryLaundryListSuccessAction = (
  factoryLaundryList: FactoryLaundryList
) => ({
  type: GET_FACTORY_LAUNDRY_LIST_SUCCESS,
  factoryLaundryList,
});

export const getFactoryLaundryDetailAction = (
  id: number,
  page: number,
  data: FactoryGetList | null,
  sort?: string
) => ({
  type: GET_FACTORY_LAUNDRY_DETAIL,
  id,
  page,
  data,
  sort,
});

export const getFactoryLaundryDetailSuccessAction = (
  factoryLaundryDetail: FactoryLaundryDetail
) => ({
  type: GET_FACTORY_LAUNDRY_DETAIL_SUCCESS,
  factoryLaundryDetail,
});

export const postFactoryLaundryClassificationAction = (
  payload: LaundryClassification,
  page: number,
  data: FactoryGetList | null,
  sort?: string
) => ({
  type: POST_FACTORY_LAUNDRY_CLASSIFICATION,
  payload,
  page,
  data,
  sort,
});
export const postFactoryLaundryClassificationSuccessAction = () => ({
  type: POST_FACTORY_LAUNDRY_CLASSIFICATION_SUCCESS,
});

export const patchFactoryLaundryClassificationAction = (
  payload: LaundryClassification,
  page: number,
  data: FactoryGetList | null,
  sort?: string
) => ({
  type: PATCH_FACTORY_LAUNDRY_CLASSIFICATION,
  payload,
  page,
  data,
  sort,
});
export const patchFactoryLaundryClassificationSuccessAction = () => ({
  type: PATCH_FACTORY_LAUNDRY_CLASSIFICATION_SUCCESS,
});

export const deleteFactoryLaundryDataAction = (
  id: number,
  page: number,
  data: FactoryGetList | null
) => ({
  type: DELETE_FACTORY_LAUNDRY_DATA,
  id,
  page,
  data,
});
export const deleteFactoryLaundryDataSuccessAction = () => ({
  type: DELETE_FACTORY_LAUNDRY_DATA_SUCCESS,
});

export const getFactoryBillingListAction = (
  page: number,
  data: FactoryGetList | null
) => ({
  type: GET_FACTORY_BILLING_LIST,
  page,
  data,
});

export const getFactoryBillingListSuccessAction = (
  factoryBillingList: FactoryBillingList
) => ({
  type: GET_FACTORY_BILLING_LIST_SUCCESS,
  factoryBillingList,
});

export const getFactoryBillingDetailAction = (id: number) => ({
  type: GET_FACTORY_BILLING_DETAIL,
  id,
});
export const getFactoryBillingDetailSuccessAction = (
  factoryBillingDetail: FactoryBillingDetail
) => ({
  type: GET_FACTORY_BILLING_DETAIL_SUCCESS,
  factoryBillingDetail,
});

export const patchFactoryBillingDataAction = (billingData: BillingData) => ({
  type: PATCH_FACTORY_BILLING_DATA,
  billingData,
});

export const patchFactoryBillingDataSuccessAction = () => ({
  type: PATCH_FACTORY_BILLING_DATA_SUCCESS,
});

export const getFactoryMatchinsgFranchiseListAction = () => ({
  type: GET_FACTORY_MATCHING_FRANCHISE_LIST,
});
export const getFactoryMatchinsgFranchiseListSuccessAction = (
  factoryMatchingFranchiseList: FactoryMatchingFranchiseList
) => ({
  type: GET_FACTORY_MATCHING_FRANCHISE_LIST_SUCCESS,
  factoryMatchingFranchiseList,
});

export const getFactoryItemizedAccountAction = (
  payload: FactoryItemizedAccountSearch
) => ({
  type: GET_FACTORY_ITEMIZED_ACCOUNT,
  payload,
});
export const getFactoryItemizedAccountSuccessAction = (
  factoryItemizedAccount: FactoryItemizedAccountPDF
) => ({
  type: GET_FACTORY_ITEMIZED_ACCOUNT_SUCCESS,
  factoryItemizedAccount,
});

export const getFactoryMatchedFranchiseDataAction = (
  page: number,
  data: FactoryGetList | null,
  sort?: string
) => ({
  type: GET_FACTORY_MATCHED_FRANCHISE_DATA,
  page,
  data,
  sort,
});
export const getFactoryMatchedFranchiseDataSuccessAction = (
  matchedFranchiseList: FactoryMatchedFranchiseData
) => ({
  type: GET_FACTORY_MATCHED_FRANCHISE_DATA_SUCCESS,
  matchedFranchiseList,
});

export const postFactoryLaundryRequestDataAction = (
  id: number,
  bagCount: number,
  page: number,
  data: FactoryGetList | null
) => ({
  type: POST_FACTORY_LAUNDRY_SIGN_UP,
  id,
  bagCount,
  page,
  data,
});
export const postFactoryLaundryRequestDataSuccessAction = () => ({
  type: POST_FACTORY_LAUNDRY_SIGN_UP_SUCCESS,
});

export const getFactoryDeliveryExcelDataAction = (
  id: number,
  data: AdminGetList | null
) => ({
  type: GET_FACTORY_DELIVERY_EXCEL_DATA,
  id,
  data,
});
export const getFactoryDeliveryExcelDataSuccessAction = (
  factoryItemizedAccountExcel: FactoryItemizedAccountExcel
) => ({
  type: GET_FACTORY_DELIVERY_EXCEL_DATA_SUCCESS,
  factoryItemizedAccountExcel,
});

export const getFactoryLaundryTableAction = (
  id: number,
  startDate: string,
  endDate: string,
  chartType: string
) => ({
  type: GET_FACTORY_LAUNDRY_TABLE,
  id,
  startDate,
  endDate,
  chartType,
});
export const getFactoryLaundryTableSuccessAction = (
  factoryLaundryTableData: LaundryDataList
) => ({
  type: GET_FACTORY_LAUNDRY_TABLE_SUCCESS,
  factoryLaundryTableData,
});

export const getFactoryLaundryTableYearAction = (data: FactoryExcelMonth) => ({
  type: GET_FACTORY_LAUNDRY_TABLE_YEAR,
  data,
});
export const getFactoryLaundryTableYearSuccessAction = (
  factoryExcelMonthList: LaundryDataListDetail[]
) => ({
  type: GET_FACTORY_LAUNDRY_TABLE_YEAR_SUCCESS,
  factoryExcelMonthList,
});

export const postFactoryTableShareAction = (
  image: string,
  frId: number,
  title: string,
  startDate: string,
  endDate: string,
  monthData: string,
  chartType: string
) => ({
  type: POST_FACTORY_TABLE_SHARE,
  image,
  frId,
  title,
  startDate,
  endDate,
  monthData,
  chartType,
});

export const postFactoryTableShareSuccessAction = () => ({
  type: POST_FACTORY_TABLE_SHARE_SUCCESS,
});

export const getFactorySpecificationsListAction = (
  id: number | null,
  page: number,
  data: FactoryGetList | null,
  sort?: string
) => ({
  type: GET_FACTORY_SPECIFICATIONS_LIST,
  id,
  page,
  data,
  sort,
});
export const getFactorySpecificationsListSuccessAction = (
  factorySpecificationsList: FactorySpecificationsList
) => ({
  type: GET_FACTORY_SPECIFICATIONS_LIST_SUCCESS,
  factorySpecificationsList,
});

export const deleteFactoryStatementAction = (
  deleteId: number,
  id: number | null,
  page: number,
  data: FactoryGetList | null
) => ({
  type: DELETE_FACTORY_STATEMENT,
  deleteId,
  id,
  page,
  data,
});

export const getFranchiseProgressListAction = (id: number) => ({
  type: GET_FACTORY_FRANCHISE_PROGRESS,
  id,
});

export const getFranchiseProgressListSuccessAction = (
  factoryFranchiseProgressList: FactoryFranchiseProgressList[]
) => ({
  type: GET_FACTORY_FRANCHISE_PROGRESS_SUCCESS,
  factoryFranchiseProgressList,
});

export const getFactoryMacAddressAction = (id: number) => ({
  type: GET_FACTORY_MAC_ADDRESS,
  id,
});

export const getFactoryMacAddressSuccessAction = (
  factoryMacAddress: string
) => ({
  type: GET_FACTORY_MAC_ADDRESS_SUCCESS,
  factoryMacAddress,
});

export const postFactoryMacAddressAction = (
  factoryId: number,
  macAddress: string
) => ({
  type: POST_FACTORY_MAC_ADDRESS,
  factoryId,
  macAddress,
});

export const postFactoryTagLaundryCompleteAction = (
  data: FactoryTableReadingData
) => ({
  type: POST_FACTORY_TAG_LAUNDRY_COMPLETE,
  data,
});

export const postFactoryLaundryTagRequestAction = (
  data: FactoryTableRequest
) => ({
  type: POST_FACTORY_TAG_REQUEST,
  data,
});

export const postReLaundryTagRequestAction = (
  data: FactoryTableReadingData
) => ({
  type: POST_RE_LAUNDRY_REQUEST,
  data,
});

export const getFactoryTagDataAction = (data: FactoryGetTagData) => ({
  type: GET_FACTORY_TAG_DATA,
  data,
});
export const getFactoryTagDataSuccessAction = (
  factoryGetTagResponseData: FactoryGetTagResponseData
) => ({
  type: GET_FACTORY_TAG_DATA_SUCCESS,
  factoryGetTagResponseData,
});

export const postDirectAmountCompleteAction = (
  data: FactoryTableDirectData
) => ({
  type: POST_DIRECT_AMOUNT_COMPLETE,
  data,
});

export const postDirectReLaundryAction = (data: FactoryTableDirectData) => ({
  type: POST_DIRECT_RE_LAUNDRY,
  data,
});

export const getFactoryFranchiseLaundryDateAction = (id: number) => ({
  type: GET_FACTORY_FRANCHISE_LAUNDRAY_DATE,
  id,
});
export const getFactoryFranchiseLaundryDateSuccessAction = (
  date: FactoryFranchiseLaundryDate[] | []
) => ({
  type: GET_FACTORY_FRANCHISE_LAUNDRAY_DATE_SUCCESS,
  date,
});

export const getFactoryFranchiseLaundryCompleteDateAction = (id: number) => ({
  type: GET_FACTORY_FRANCHISE_LAUNDRY_COMPLETE_DATE,
  id,
});
export const getFactoryFranchiseLaundryCompleteDateSuccessAction = (
  date: FactoryFranchiseLaundryDate[] | []
) => ({
  type: GET_FACTORY_FRANCHISE_LAUNDRY_COMPLETE_DATE_SUCCESS,
  date,
});

// 테이블리더기 웹소켓 서버 활성화
export const activationTableReaderServerAction = () => ({
  type: ACTIVATION_TABLEREADER_SERVER,
});

// 라벨 출력한 데이터 가져오기
export const getLabelExportDataAction = (data: LabelSearchType) => ({
  type: LABEL_EXPORT_DATA,
  data,
});
export const getLabelExportDataSuccessAction = (
  labelExportDataListType: LabelExportDataListType
) => ({
  type: LABEL_EXPORT_DATA_SUCCESS,
  labelExportDataListType,
});

function* getFactoryDashboardSaga(
  action: ReturnType<typeof getFactoryDashboardAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(factoryApi.getDashBoard, action.day);
    if (status !== "OK") return alert("에러");
    yield put(getFactoryDashboardSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getFactoryLaundryListSaga(
  action: ReturnType<typeof getFactoryLaundryListAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      factoryApi.getFactoryLaundryList,
      action.page,
      action.data,
      action.sort
    );
    if (status !== "OK") return alert("에러");
    yield put(getFactoryLaundryListSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getFactoryLaundryDetailSaga(
  action: ReturnType<typeof getFactoryLaundryDetailAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      factoryApi.getFactoryLaundryDetail,
      action.id
    );
    if (status !== "OK") return alert("에러");
    yield put(getFactoryLaundryDetailSuccessAction(data));
    yield put(
      getFactoryLaundryListAction(action.page, action.data, action.sort)
    );
    yield put(getLaundryRequestAlarmAction());
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* postFactoryLaundryClassificationSaga(
  action: ReturnType<typeof postFactoryLaundryClassificationAction>
) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(
      factoryApi.postFactoryLaundryClassification,
      action.payload
    );
    if (status !== "CREATED") return alert("에러");
    if (status === "CREATED") alert("성공");
    yield put(
      getFactoryLaundryListAction(action.page, action.data, action.sort)
    );
    yield put(postFactoryLaundryClassificationSuccessAction());
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* patchFactoryLaundryClassificationSaga(
  action: ReturnType<typeof patchFactoryLaundryClassificationAction>
) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(
      factoryApi.patchFactoryLaundryClassification,
      action.payload
    );
    // if (status !== "CREATED") return alert("에러");
    // if (status === "CREATED") alert("성공");
    yield put(
      getFactoryLaundryListAction(action.page, action.data, action.sort)
    );
    yield put(patchFactoryLaundryClassificationSuccessAction());
    yield alert("수정되었습니다");
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* deleteFactoryLaundryDataSaga(
  action: ReturnType<typeof deleteFactoryLaundryDataAction>
) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(
      factoryApi.deleteFactoryLaundryData,
      action.id
    );
    if (status !== "OK") return alert("에러");
    yield put(deleteFactoryLaundryDataSuccessAction());
    yield put(getFactoryLaundryListAction(action.page, action.data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getFactoryBillingListSaga(
  action: ReturnType<typeof getFactoryLaundryListAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      factoryApi.getFactoryBillingList,
      action.page,
      action.data
    );
    if (status !== "OK") return alert("에러");

    yield put(getFactoryBillingListSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getFactoryBillingDetailSaga(
  action: ReturnType<typeof getFactoryBillingDetailAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      factoryApi.getFactoryBillingDetail,
      action.id
    );
    if (status !== "OK") return alert("에러");
    yield put(getFactoryBillingDetailSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* patchFactoryBillingDataSaga(
  action: ReturnType<typeof patchFactoryBillingDataAction>
) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(
      factoryApi.patchFactoryBillingData,
      action.billingData
    );
    if (status !== "OK") return alert("에러");
    yield put(getFactoryBillingListAction(1, null));
    // yield put(getFactoryBillingDetailSaga(action.billingData.id));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getFactoryMatchingFranchiseListSaga() {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(factoryApi.getFactoryMatchingFranchiseList);
    if (status !== "OK") return alert("에러");
    yield put(getFactoryMatchinsgFranchiseListSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getFactoryItemizedAccountSaga(
  action: ReturnType<typeof getFactoryItemizedAccountAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      factoryApi.getFactoryItemizedAccount,
      action.payload
    );
    if (status !== "OK") return alert("에러");

    yield put(getFactoryItemizedAccountSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getFactoryMatchedFranchiseDataSaga(
  action: ReturnType<typeof getFactoryMatchedFranchiseDataAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      factoryApi.getFactoryMatchedFranchiseData,
      action.page,
      action.data,
      action.sort
    );
    if (status !== "OK") return alert("에러");
    yield put(getFactoryMatchedFranchiseDataSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* postFactoryLaundryRequestDataSaga(
  action: ReturnType<typeof postFactoryLaundryRequestDataAction>
) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(
      factoryApi.postFactoryLaundryRequestData,
      action.id,
      action.bagCount
    );
    if (status !== "CREATED") return alert("에러");
    if (status === "CREATED") alert("신청되었습니다");
    yield put(postFactoryLaundryRequestDataSuccessAction());
    yield put(getFactoryMatchedFranchiseDataAction(action.page, action.data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getFactoryDeliveryExcelDataSaga(
  action: ReturnType<typeof getFactoryDeliveryExcelDataAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      factoryApi.getFactoryDeliveryExcelData,
      action.id,
      action.data
    );
    if (status !== "OK") return alert("에러");
    yield put(getFactoryDeliveryExcelDataSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getFactoryLaundryTableSaga(
  action: ReturnType<typeof getFactoryLaundryTableAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      factoryApi.getFactoryLaundryTableData,
      action.id,
      action.startDate,
      action.endDate,
      action.chartType
    );
    if (status !== "OK") return alert("에러");
    yield put(getFactoryLaundryTableSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getFactoryLaundryTableYearSaga(
  action: ReturnType<typeof getFactoryLaundryTableYearAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      factoryApi.getFactoryLaundryTableYearData,
      action.data
    );
    if (status !== "OK") return alert("에러");
    yield put(getFactoryLaundryTableYearSuccessAction(data));
  } catch (error: any) {
    return alert(error.response.data.message);
  }
}

function* postFactoryTableShareSaga(
  action: ReturnType<typeof postFactoryTableShareAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      factoryApi.postFactoryTableShare,
      action.image,
      action.frId,
      action.title,
      action.startDate,
      action.endDate,
      action.monthData,
      action.chartType
    );
    if (status !== "OK") return alert("에러");
    yield put(postFactoryTableShareSuccessAction());
    yield put(getFactorySpecificationsListAction(null, 1, null));
    // yield alert("전송되었습니다");
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getFactorySpecificationsListSaga(
  action: ReturnType<typeof getFactorySpecificationsListAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      factoryApi.getFactorySpecificationsList,
      action.id,
      action.page,
      action.data,
      action.sort
    );
    if (status !== "OK") return alert("에러");

    yield put(getFactorySpecificationsListSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* deleteFactoryStatementSaga(
  action: ReturnType<typeof deleteFactoryStatementAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      factoryApi.deleteFactoryStatement,
      action.deleteId
    );
    yield put(
      getFactorySpecificationsListAction(action.id, action.page, action.data)
    );
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getFranchiseProgressSaga(
  action: ReturnType<typeof getFranchiseProgressListAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(storeApi.getFranchiseProgressList, action.id);
    if (status !== "OK") return alert("에러");
    yield put(getFranchiseProgressListSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getFactoryMacAddressSaga(
  action: ReturnType<typeof getFactoryMacAddressAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(factoryApi.getMacAddress, action.id);
    if (status !== "OK") return alert("에러");
    yield put(getFactoryMacAddressSuccessAction(data));
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* postFactoryMacAddressSaga(
  action: ReturnType<typeof postFactoryMacAddressAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      factoryApi.postMacAddress,
      action.factoryId,
      action.macAddress
    );
    if (status !== "OK") return alert("에러");
    yield alert("등록되었습니다.");
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* postFactoryLaundryTagRequestSaga(
  action: ReturnType<typeof postFactoryLaundryTagRequestAction>
) {
  try {
    const {
      data: { status },
    }: AxiosResponse = yield call(
      factoryApi.postFactoryLaundryTagRequest,
      action.data
    );
    if (status !== "OK") return alert("에러");
    // yield alert("신청되었습니다.");
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* postFactoryTagLaundryCompleteSaga(
  action: ReturnType<typeof postFactoryTagLaundryCompleteAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(factoryApi.postLaundryComplete, action.data);
    if (status !== "OK") return alert("에러");
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* getFactoryTagDataSaga(
  action: ReturnType<typeof getFactoryTagDataAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(factoryApi.getTagData, action.data);
    if (status !== "OK") return alert("에러");
    yield put(getFactoryTagDataSuccessAction(data));
  } catch (error) {
    // return alert(error.response.data.message);
  }
}

function* postReLaundryTagRequestSaga(
  action: ReturnType<typeof postReLaundryTagRequestAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(factoryApi.postReLaundry, action.data);
    if (status !== "OK") return alert("에러");
  } catch (error) {
    // return alert(error.response.data.message);
  }
}

function* postDirectAmountCompleteSaga(
  action: ReturnType<typeof postDirectAmountCompleteAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      factoryApi.postDirectAmountComplete,
      action.data
    );
    if (status !== "OK") return alert("에러");
  } catch (error) {
    return alert(error.response.data.message);
  }
}

function* postDirectReLaundrySaga(
  action: ReturnType<typeof postDirectReLaundryAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(factoryApi.postDirectReLaundry, action.data);
    if (status !== "OK") return alert("에러");
  } catch (error) {
    alert(error.response.data.message);
  }
}

function* getFactoryFranchiseLaundryDateSaga(
  action: ReturnType<typeof getFactoryFranchiseLaundryDateAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      factoryApi.getFactoryFranchiseLaundryDate,
      action.id
    );
    if (status !== "OK") return alert("에러");
    yield put(getFactoryFranchiseLaundryDateSuccessAction(data));
  } catch (error) {
    alert(error.response.data.message);
  }
}

function* getFactoryFranchiseLaundryCompleteDateSaga(
  action: ReturnType<typeof getFactoryFranchiseLaundryCompleteDateAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(
      factoryApi.getFactoryFranchiseLaundryCompleteDate,
      action.id
    );
    if (status !== "OK") return alert("에러");
    yield put(getFactoryFranchiseLaundryCompleteDateSuccessAction(data));
  } catch (error) {
    alert(error.response.data.message);
  }
}

function* activationTableReaderServerSaga() {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(factoryApi.activationTableReaderServer);
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

function* getLabelExportDataSaga(
  action: ReturnType<typeof getLabelExportDataAction>
) {
  try {
    const {
      data: { status, data },
    }: AxiosResponse = yield call(factoryApi.labelExportData, action.data);
    if (status !== "OK") return alert("에러");
    yield put(getLabelExportDataSuccessAction(data));
  } catch (error: any) {
    alert(error.response.data.message);
  }
}

export function* factorySaga() {
  yield takeEvery(GET_FACTORY_DASHBOARD, getFactoryDashboardSaga);
  yield takeEvery(GET_FACTORY_BILLING_LIST, getFactoryBillingListSaga);
  yield takeEvery(GET_FACTORY_BILLING_DETAIL, getFactoryBillingDetailSaga);
  yield takeEvery(PATCH_FACTORY_BILLING_DATA, patchFactoryBillingDataSaga);
  yield takeEvery(GET_FACTORY_LAUNDRY_LIST, getFactoryLaundryListSaga);
  yield takeEvery(GET_FACTORY_LAUNDRY_DETAIL, getFactoryLaundryDetailSaga);
  yield takeEvery(
    POST_FACTORY_LAUNDRY_CLASSIFICATION,
    postFactoryLaundryClassificationSaga
  );
  yield takeEvery(
    PATCH_FACTORY_LAUNDRY_CLASSIFICATION,
    patchFactoryLaundryClassificationSaga
  );
  yield takeEvery(DELETE_FACTORY_LAUNDRY_DATA, deleteFactoryLaundryDataSaga);
  yield takeEvery(
    GET_FACTORY_MATCHING_FRANCHISE_LIST,
    getFactoryMatchingFranchiseListSaga
  );
  yield takeEvery(GET_FACTORY_ITEMIZED_ACCOUNT, getFactoryItemizedAccountSaga);
  yield takeEvery(
    GET_FACTORY_MATCHED_FRANCHISE_DATA,
    getFactoryMatchedFranchiseDataSaga
  );
  yield takeEvery(
    POST_FACTORY_LAUNDRY_SIGN_UP,
    postFactoryLaundryRequestDataSaga
  );
  yield takeEvery(
    GET_FACTORY_DELIVERY_EXCEL_DATA,
    getFactoryDeliveryExcelDataSaga
  );
  yield takeEvery(GET_FACTORY_LAUNDRY_TABLE, getFactoryLaundryTableSaga);
  yield takeEvery(
    GET_FACTORY_LAUNDRY_TABLE_YEAR,
    getFactoryLaundryTableYearSaga
  );
  yield takeEvery(POST_FACTORY_TABLE_SHARE, postFactoryTableShareSaga);
  yield takeEvery(
    GET_FACTORY_SPECIFICATIONS_LIST,
    getFactorySpecificationsListSaga
  );
  yield takeEvery(DELETE_FACTORY_STATEMENT, deleteFactoryStatementSaga);
  yield takeEvery(GET_FACTORY_FRANCHISE_PROGRESS, getFranchiseProgressSaga);
  yield takeEvery(GET_FACTORY_MAC_ADDRESS, getFactoryMacAddressSaga);
  yield takeEvery(POST_FACTORY_MAC_ADDRESS, postFactoryMacAddressSaga);
  yield takeEvery(
    POST_FACTORY_TAG_LAUNDRY_COMPLETE,
    postFactoryTagLaundryCompleteSaga
  );
  yield takeEvery(POST_FACTORY_TAG_REQUEST, postFactoryLaundryTagRequestSaga);
  yield takeEvery(GET_FACTORY_TAG_DATA, getFactoryTagDataSaga);
  yield takeEvery(POST_RE_LAUNDRY_REQUEST, postReLaundryTagRequestSaga);
  yield takeEvery(POST_DIRECT_AMOUNT_COMPLETE, postDirectAmountCompleteSaga);
  yield takeEvery(POST_DIRECT_RE_LAUNDRY, postDirectReLaundrySaga);
  yield takeEvery(
    GET_FACTORY_FRANCHISE_LAUNDRAY_DATE,
    getFactoryFranchiseLaundryDateSaga
  );
  yield takeEvery(
    GET_FACTORY_FRANCHISE_LAUNDRY_COMPLETE_DATE,
    getFactoryFranchiseLaundryCompleteDateSaga
  );
  yield takeEvery(
    ACTIVATION_TABLEREADER_SERVER,
    activationTableReaderServerSaga
  );
  yield takeEvery(LABEL_EXPORT_DATA, getLabelExportDataSaga);
}

const initialState: IFactoryState = {
  factoryDashBoard: null,
  factoryLaundryList: null,
  factoryLaundryDetail: null,
  factoryBillingList: null,
  factoryBillingDetail: null,
  factoryItemizedAccount: null,
  factoryMatchingFranchiseList: null,
  matchedFranchiseList: null,
  factoryItemizedAccountExcel: null,
  factoryLaundryTableData: null,
  factorySpecificationsList: null,
  factoryExcelMonthList: null,
  factoryFranchiseProgressList: null,
  factoryMacAddress: null,
  factoryGetTagResponseData: null,
  factoryFranchiseLaundryDate: null,
  factoryFranchiseLaundryCompleteDate: null,
  labelExportDataListType: null,
};

function factory(state = initialState, action: FactoryAction) {
  switch (action.type) {
    case GET_FACTORY_DASHBOARD_SUCCESS:
      return { ...state, factoryDashBoard: action.factoryDashBoard };
    case GET_FACTORY_LAUNDRY_LIST_SUCCESS:
      return { ...state, factoryLaundryList: action.factoryLaundryList };
    case GET_FACTORY_LAUNDRY_DETAIL_SUCCESS:
      return {
        ...state,
        factoryLaundryDetail: action.factoryLaundryDetail,
      };
    case POST_FACTORY_LAUNDRY_CLASSIFICATION_SUCCESS:
      return { ...state };
    case PATCH_FACTORY_LAUNDRY_CLASSIFICATION_SUCCESS:
      return { ...state };
    case DELETE_FACTORY_LAUNDRY_DATA_SUCCESS:
      return { ...state };
    case GET_FACTORY_BILLING_LIST_SUCCESS:
      return { ...state, factoryBillingList: action.factoryBillingList };
    case GET_FACTORY_BILLING_DETAIL_SUCCESS:
      return {
        ...state,
        factoryBillingDetail: action.factoryBillingDetail,
      };
    case PATCH_FACTORY_BILLING_DATA_SUCCESS:
      return { ...state };
    case GET_FACTORY_MATCHING_FRANCHISE_LIST_SUCCESS:
      return {
        ...state,
        factoryMatchingFranchiseList: action.factoryMatchingFranchiseList,
      };
    case GET_FACTORY_ITEMIZED_ACCOUNT_SUCCESS:
      return {
        ...state,
        factoryItemizedAccount: action.factoryItemizedAccount,
      };
    case GET_FACTORY_MATCHED_FRANCHISE_DATA_SUCCESS:
      return {
        ...state,
        matchedFranchiseList: action.matchedFranchiseList,
      };
    case POST_FACTORY_LAUNDRY_SIGN_UP_SUCCESS:
      return { ...state };
    case GET_FACTORY_DELIVERY_EXCEL_DATA_SUCCESS:
      return {
        ...state,
        factoryItemizedAccountExcel: action.factoryItemizedAccountExcel,
      };
    case GET_FACTORY_LAUNDRY_TABLE_SUCCESS:
      return {
        ...state,
        factoryLaundryTableData: action.factoryLaundryTableData,
      };
    case GET_FACTORY_LAUNDRY_TABLE_YEAR_SUCCESS:
      return { ...state, factoryExcelMonthList: action.factoryExcelMonthList };
    case GET_FACTORY_SPECIFICATIONS_LIST_SUCCESS:
      return {
        ...state,
        factorySpecificationsList: action.factorySpecificationsList,
      };
    case GET_FACTORY_FRANCHISE_PROGRESS_SUCCESS:
      return {
        ...state,
        factoryFranchiseProgressList: action.factoryFranchiseProgressList,
      };
    case GET_FACTORY_MAC_ADDRESS_SUCCESS:
      return { ...state, factoryMacAddress: action.factoryMacAddress };
    case GET_FACTORY_TAG_DATA_SUCCESS:
      return {
        ...state,
        factoryGetTagResponseData: action.factoryGetTagResponseData,
      };
    case GET_FACTORY_FRANCHISE_LAUNDRAY_DATE_SUCCESS:
      return { ...state, factoryFranchiseLaundryDate: action.date };
    case GET_FACTORY_FRANCHISE_LAUNDRY_COMPLETE_DATE_SUCCESS:
      return {
        ...state,
        factoryFranchiseLaundryCompleteDate: action.date,
      };
    case LABEL_EXPORT_DATA_SUCCESS:
      return {
        ...state,
        labelExportDataListType: action.labelExportDataListType,
      };
    default:
      return state;
  }
}

export default factory;
