import RegistrationLayout from "components/master/MasterRegistrationLayout";
import MasterStoreStatus from "components/master/MasterStoreStatus";
import MasterStoreStatusDetail from "components/master/MasterStoreStatusDetail";
import { IRootState } from "modules";
import { getIdDuplicateCheckAction } from "modules/admin/admin";
import {
  deleteMasterStoreAction,
  getMasterStoreDetailAction,
  getMasterStoreListAction,
  postMasterStoreRegistrationAction,
} from "modules/master/master";
import { MasterGetList, MasterStoreRegistration } from "modules/master/types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import RoleUtils from "utils/RoleUtils";

interface MasterStoreStatusContainerProps {
  match: any;
}

const MasterStoreStatusContainer: React.FC<MasterStoreStatusContainerProps> = ({
  match,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectType, setSelectType] = useState<string>("list");
  // const [detailId, setDetailId] = useState<number | null>(null);
  const [auth, setAuth] = useState<string>("");
  // const [searchData, setSearchData] = useState<MasterGetList>({
  //   query: "",
  //   dropBox: "",
  //   startDate: "",
  //   endDate: "",
  //   size: 10,
  // });

  const role = useSelector((state: IRootState) => state.user.profile.type);
  const masterStoreList = useSelector(
    (state: IRootState) => state.master.masterStoreList
  );

  const masterStoreDetail = useSelector(
    (state: IRootState) => state.master.masterStoreDetail
  );

  useEffect(() => {
    dispatch(getMasterStoreListAction(1, null));
  }, [dispatch]);

  useEffect(() => {
    let authority = "";
    if (RoleUtils.isMasterAdmin(role)) authority = "admin";
    if (RoleUtils.isMaster(role)) authority = "master";
    if (RoleUtils.isStore(role)) authority = "store";
    if (RoleUtils.isFactory(role)) authority = "factory";
    setAuth(authority);
  }, [role]);

  const query = window.location.pathname;

  useEffect(() => {
    if (query === `/${auth}/store/detail`) setSelectType("detail");
    if (query === `/${auth}/store`) setSelectType("list");
  }, [query, auth]);

  useEffect(() => {
    if (match.params.id && Number(match.params.id)) {
      // setDetailId(match.params.id);
      setSelectType("detail");
      dispatch(getMasterStoreDetailAction(match.params.id));
    } else {
      setSelectType("list");
    }
  }, [match.params.id]);

  const masterStoreRegistration = (payload: MasterStoreRegistration) => {
    dispatch(postMasterStoreRegistrationAction(payload));
  };

  const storeList = (page: number, data: MasterGetList) => {
    // setSearchData(data);
    // setCurrentPage(page);
    dispatch(getMasterStoreListAction(page, data));
  };

  const storeDetail = (id: number) => {
    history.push(`/master/store/${id}`);
    dispatch(getMasterStoreDetailAction(id));
  };

  const storeDelete = (id: number) => {
    dispatch(deleteMasterStoreAction(id));
  };

  const historyBack = () => {
    // setDetailId(null);
    history.push(`/master/store`);
  };

  const storeAdd = () => {
    setSelectType("add");
    history.push(`/master/store/add`);
  };

  const duplicateCheck = (id: string) => {
    dispatch(getIdDuplicateCheckAction(id));
  };

  if (!masterStoreList) return <h1>로딩중</h1>;
  return (
    <>
      {selectType === "list" && (
        <MasterStoreStatus
          role={role}
          masterStoreList={masterStoreList}
          storeList={storeList}
          masterStoreRegistration={masterStoreRegistration}
          storeDetail={storeDetail}
          masterStoreDetail={masterStoreDetail}
          storeDelete={storeDelete}
          storeAdd={storeAdd}
        />
      )}
      {selectType === "detail" && (
        <MasterStoreStatusDetail
          onDelete={storeDelete}
          onClose={() => console.log("close")}
          masterStoreDetail={masterStoreDetail}
          historyBack={historyBack}
        />
      )}
      {selectType === "add" && (
        <RegistrationLayout
          duplicateCheckRequest={duplicateCheck}
          onClose={historyBack}
          masterStoreRegistration={masterStoreRegistration}
        />
      )}
    </>
  );
};

export default MasterStoreStatusContainer;
