import Button from "components/common/Button";
import { CenterAligned } from "components/common/layouts/CenterAligned";
import { AdminMatchingList } from "modules/admin/types";
import React, { useState } from "react";
import styled from "styled-components";
import { Palette, ThemeColor } from "styles/palette";

interface AdminMatchingFieldStatusProps {
  matchingFactoryId: number;
  factoryMatching: (factoryId: number) => void;
  adminMatchingList: AdminMatchingList[] | null;
  onClose: () => void;
}

const AdminMatchingField: React.FC<AdminMatchingFieldStatusProps> = ({
  factoryMatching,
  adminMatchingList,
  matchingFactoryId,
  onClose,
}) => {
  const [factorySelector, setFactorySelector] = useState<number | null>(
    matchingFactoryId
  );

  const factorySelectCheck = (id: number) => {
    // if (factorySelector === id) return setFactorySelector(null);
    setFactorySelector(id);
  };

  const matching = (factoryId: number) => {
    if (factoryId !== null) factoryMatching(factoryId);
    onClose();
  };

  return (
    <AdminMatchingFieldBlock>
      <div className="matchingWrapper">
        <h2>세탁공장 매칭</h2>
        <div className="matchingFieldWrapper">
          <div className="content">
            <div>
              <span>세탁공장명</span>
              <span>관리지역</span>
            </div>
            <div>
              <span>세탁공장명</span>
              <span>관리지역</span>
            </div>
          </div>
          <div className="content">
            {adminMatchingList?.map((data) => (
              <div
                key={data.id}
                className={factorySelector === data.id ? "field on" : "field"}
                onClick={() => factorySelectCheck(data.id)}
              >
                <span>{data.factoryName}</span>
                <span>{data.managingArea}</span>
              </div>
            ))}
          </div>
        </div>
        <CenterAligned className="btnWrapper">
          <Button theme={ThemeColor.quadratic} buttonSize="m" onClick={onClose}>
            취소
          </Button>
          <Button
            theme={ThemeColor.primary}
            buttonSize="m"
            onClick={() => matching(factorySelector!)}
          >
            확인
          </Button>
        </CenterAligned>
      </div>
    </AdminMatchingFieldBlock>
  );
};

export default AdminMatchingField;

const AdminMatchingFieldBlock = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  & > .matchingWrapper {
    width: 46rem;
    height: 30rem;
    background-color: #f0f0f0;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    & > h2 {
      padding-left: 1rem;
      align-self: flex-start;
      color: ${Palette.primary};
    }
    & > .matchingFieldWrapper {
      width: 32rem;
      flex: 1;
      & > .content {
        height: 2.1rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 5rem;
        & > div {
          height: 2.1rem;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          text-align: center;
          align-items: center;
        }
        & > .field {
          background-color: #fff;
          border-radius: 0.3rem;
          cursor: pointer;
        }
        .on {
          background-color: ${Palette.tertiary};
          color: ${Palette.white};
        }
      }
    }
    & > .btnWrapper {
      padding: 0.5rem;
    }
  }
`;
