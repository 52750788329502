import StoreInventoryStatus from "components/store/StoreInventoryStatus";
import React from "react";
import { IRootState } from "modules";
import { useDispatch, useSelector } from "react-redux";
import useInputs from "hooks/useInputs";
import {
  FranchiseAddItem,
  FranchiseGetItem,
  FranchiseItem,
  FranchiseItemModify,
  StoreItemExcelUpload,
} from "modules/store/types";
import { useEffect } from "react";
import RoleUtils from "utils/RoleUtils";
import {
  addFranchiseItemAction,
  deleteAssetDataAction,
  deleteFranchiseItemAction,
  getFranchiseItemDataAction,
  getFranchiseItemDataAllAction,
  getFranchiseItemListAction,
  patchAmountExcelAction,
  patchFranchiseItemModifyAction,
} from "modules/store/store";
import { useState } from "react";

interface StoreInventoryStatusContainerProps {}

const StoreInventoryStatusContainer: React.FC<StoreInventoryStatusContainerProps> = () => {
  const dispatch = useDispatch();
  const type = useSelector((state: IRootState) => state.user.profile?.type);
  const [itemListData, setItemListData] = useState<FranchiseGetItem>({
    type: "large",
    large: "",
    sub: "",
    name: "",
    size: "",
  });
  const [itemLargeList, setItemLargeList] = useState<FranchiseItem[]>([]);
  const [itemSubList, setItemSubList] = useState<FranchiseItem[]>([]);
  const [itemNameList, setItemNameList] = useState<FranchiseItem[]>([]);
  const [itemSizeData, setItemSizeData] = useState({
    size: "",
    content: "",
  });

  const storeName = useSelector((state: IRootState) => state.user.profile.name);

  useEffect(() => {
    dispatch(getFranchiseItemListAction(1, 10));
    //9999 고쳐야할 부분임
    dispatch(getFranchiseItemDataAllAction(9999));
    if (RoleUtils.isStore(type)) dispatch(getFranchiseItemDataAction(itemListData));
  }, [dispatch, type]);

  const franchiseItemData = useSelector((state: IRootState) => state.store.franchiseItemData);
  const franchiseItemList = useSelector((state: IRootState) => state.store.franchiseItemList);

  const franchiseItemListAll = useSelector((state: IRootState) => state.store.franchiseItemDataAll);

  useEffect(() => {
    if (itemListData.type === "large") setItemLargeList(franchiseItemData ?? []);
    if (itemListData.type === "sub") setItemSubList(franchiseItemData ?? []);
    if (itemListData.type === "name") setItemNameList(franchiseItemData ?? []);
    if (itemListData.type === "size") {
      franchiseItemData?.map((item) => {
        const data = {
          size: item.size ?? "",
          content: item.content ?? "",
        };
        return setItemSizeData(data);
      });
    }
  }, [franchiseItemData, itemListData]);

  const [inputs, onChange, onReset] = useInputs<FranchiseAddItem>({
    assetDetailsId: undefined,
    amount: undefined,
  });

  const addItemCount = (size: number) => {
    if (!inputs.assetDetailsId && !inputs.amount)
      return alert("값이 올바르지 않거나, 빈값을 채워주세요.");
    dispatch(
      addFranchiseItemAction(
        {
          assetDetailsId: inputs.assetDetailsId,
          amount: inputs.amount,
        },
        size
      )
    );
  };

  const patchAmountExcel = (payload: StoreItemExcelUpload[], page: number, size: number) => {
    dispatch(patchAmountExcelAction(payload, page, size));
  };

  const modifyItem = (payload: FranchiseItemModify, page: number, size: number) => {
    dispatch(patchFranchiseItemModifyAction(payload, page, size));
  };

  const deleteItem = (id: number) => {
    dispatch(deleteFranchiseItemAction(id));
  };

  const itemList = (page: number, size: number) => {
    dispatch(getFranchiseItemListAction(page, size));
  };

  const getItemListData = (payload: FranchiseGetItem) => {
    if (payload.type === "sub") {
      setItemSubList([]);
      setItemNameList([]);
    }
    if (payload.type === "name") {
      setItemNameList([]);
    }
    // console.log(payload);

    const data = {
      type: payload.type,
      large: payload.large ?? itemListData.large,
      sub: payload.sub ?? itemListData.sub,
      name: payload.name ?? itemListData.name,
      size: payload.size ?? itemListData.size,
    };

    setItemListData(data);
    dispatch(getFranchiseItemDataAction(data));
  };

  const onClose = () => {
    setItemSubList([]);
    setItemNameList([]);
    setItemNameList([]);
    setItemSizeData({
      size: "",
      content: "",
    });
  };

  const onDelete = (id: number, page: number, size: number) => {
    dispatch(deleteAssetDataAction(id, page, size));
  };

  if (type === "GUEST") return null;
  return (
    <StoreInventoryStatus
      inputs={inputs}
      storeName={storeName!}
      itemList={itemList}
      onChange={onChange}
      onReset={onReset}
      onClose={onClose}
      patchAmountExcel={patchAmountExcel}
      // franchiseItemData={franchiseItemData}
      franchiseItemList={franchiseItemList}
      addItemCount={addItemCount}
      modifyItem={modifyItem}
      deleteItem={deleteItem}
      getItemListData={getItemListData}
      itemLargeList={itemLargeList}
      itemSubList={itemSubList}
      itemNameList={itemNameList}
      itemSizeData={itemSizeData}
      franchiseItemListAll={franchiseItemListAll}
      onDelete={onDelete}
    />
  );
};

export default StoreInventoryStatusContainer;
