import FactoryProfile from 'components/factory/FactoryProfile';
import React from 'react';

interface FactoryProfileContainerProps {}

const FactoryProfileContainer: React.FC<FactoryProfileContainerProps> = () => {
  return <FactoryProfile />;
};

export default FactoryProfileContainer;
