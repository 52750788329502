import MasterProfile from 'components/master/MasterProfile';
import React from 'react';

interface MasterProfileContainerProps {}

const MasterProfileContainer: React.FC<MasterProfileContainerProps> = () => {
  return <MasterProfile />;
};

export default MasterProfileContainer;
