import Prepared from "components/common/Prepared";
import AppLayout from "components/layouts/AppLayout";
import BoardContainer from "containers/common/BoardContainer";
import NoticeContainer from "containers/common/NoticeContainer";
import MasterDashBoardContainer from "containers/master/MasterDashBoardContainer";
import MasterFactoryStatusContainer from "containers/master/MasterFactoryStatusContainer";
import MasterItemStatusContainer from "containers/master/MasterItemStatusContainer";
import MasterLaundryStatusContainer from "containers/master/MasterLaundryStatusContainer";
import MasterPaymentStatusContainer from "containers/master/MasterPaymentStatusContainer";
import MasterProfileContainer from "containers/master/MasterProfileContainer";
import MasterStoreStatusContainer from "containers/master/MasterStoreStatusContainer";
import MasterExcelContainer from "containers/master/MasterExcelUpload";
import React from "react";
import { Route, Switch } from "react-router";
import MasterItemizedAccountContainer from "containers/master/MasterItemizedAccountContainer";
import TransactionTable from "components/common/TransactionTable";
import CheckLocationContainer from "containers/common/CheckLocationContainer";

interface MasterPageProps {}

const MasterPage: React.FC<MasterPageProps> = () => {
  return (
    <AppLayout>
      <Switch>
        <Route
          exact
          path="/master/laundry/:id"
          component={MasterLaundryStatusContainer}
        />
        <Route
          exact
          path="/master/laundry"
          component={MasterLaundryStatusContainer}
        />
        <Route
          exact
          path="/master/itemizedAccount/:id"
          component={MasterItemizedAccountContainer}
        />
        <Route
          exact
          path="/master/itemizedAccount/list"
          component={MasterItemizedAccountContainer}
        />
        <Route
          exact
          path="/master/itemizedAccount"
          component={MasterItemizedAccountContainer}
        />
        <Route exact path="/master/notice/detail" component={NoticeContainer} />
        <Route exact path="/master/notice" component={NoticeContainer} />
        <Route exact path="/master/notice/:id" component={NoticeContainer} />

        <Route exact path="/master/board/:id" component={BoardContainer} />
        {/* <Route exact path="/master/board/detail" component={BoardContainer} /> */}
        <Route
          exact
          path="/master/board/modify/:id"
          component={BoardContainer}
        />
        <Route exact path="/master/board/add" component={BoardContainer} />
        <Route exact path="/master/board" component={BoardContainer} />
        <Route
          exact
          path="/master/payment/:id"
          component={MasterPaymentStatusContainer}
        />
        <Route
          exact
          path="/master/payment"
          component={MasterPaymentStatusContainer}
        />
        <Route
          path="/master/store/add"
          component={MasterStoreStatusContainer}
        />
        <Route
          path="/master/store/:id"
          component={MasterStoreStatusContainer}
        />
        <Route path="/master/store" component={MasterStoreStatusContainer} />
        <Route
          exact
          path="/master/factory/:id"
          component={MasterFactoryStatusContainer}
        />
        <Route
          exact
          path="/master/factory"
          component={MasterFactoryStatusContainer}
        />
        <Route path="/master/inventory" component={MasterItemStatusContainer} />
        <Route path="/master/profile" component={MasterProfileContainer} />
        <Route path="/master/dashboard" component={MasterDashBoardContainer} />
        <Route path="/master/excel" component={MasterExcelContainer} />
        <Route path="/master/transaction" component={TransactionTable} />
        <Route
          path="/master/checkLocation"
          component={CheckLocationContainer}
        />

        <Route component={Prepared} />
      </Switch>
    </AppLayout>
  );
};

export default MasterPage;
