import Paging from "components/common/Paging";

import {
  AdminGetList,
  AdminPaymentData,
  AdminPaymentDeatilData,
  AdminPaymentDetail,
  AdminPaymentList,
} from "modules/admin/types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Palette } from "styles/palette";

import NumberUtils from "utils/NumberUtils";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AdminPaymentDetailList from "./AdminPaymentDetailList";
import AdminPaymentSearch from "components/common/AdminPaymentSearch";
import { useHistory } from "react-router";
import AdminAggregationDetail from "./AdminAggregationDetail";
import { LaundryDataListDetail } from "modules/factory/types";
import { SpecificationsFranchiseList } from "modules/user/types";
import PaymentBrandSearch from "components/common/PaymentBrnadSearch";
import moment from "moment";

interface AdminPaymentStatusProps {
  match: any;
  role: string;
  adminPaymentList: AdminPaymentList | null;
  adminPaymentDetail: AdminPaymentDetail | null;
  paymentList: (page: number, data: AdminGetList | null) => void;
  paymentDetail: (id: number) => void;
  paymentDeatilBrand: (id: number, data: AdminGetList | null) => void;
  paymentDeatilFactory: (id: number, data: AdminGetList | null) => void;
  getPaymentListData: (data: AdminGetList | null) => void;
  laundryTable: LaundryDataListDetail[] | null;
  franchiseId: number;
  getAdminLaundryTable: (id: number, data: AdminGetList | null) => void;
  specificationsFranchiseList: SpecificationsFranchiseList | null;
  adminPaymentData: AdminPaymentData | null;
  adminPaymentBrandListData: AdminPaymentData | null;
  adminPaymentFactoryListData: AdminPaymentData | null;
  adminPaymentDeatilBrandData: AdminPaymentDeatilData | null;
  adminPaymentDeatilFactoryData: AdminPaymentDeatilData | null;
  getPaymentBrandListData: (page: number, data: AdminGetList | null) => void;
  getPaymentFactoryListData: (page: number, data: AdminGetList | null) => void;
}

const AdminPaymentStatus: React.FC<AdminPaymentStatusProps> = ({
  match,
  role,
  adminPaymentList,
  adminPaymentDetail,
  paymentList,
  paymentDetail,
  paymentDeatilBrand,
  paymentDeatilFactory,
  getPaymentListData,
  laundryTable,
  franchiseId,
  getAdminLaundryTable,
  specificationsFranchiseList,
  adminPaymentData,
  adminPaymentBrandListData,
  adminPaymentFactoryListData,
  adminPaymentDeatilBrandData,
  adminPaymentDeatilFactoryData,
  getPaymentBrandListData,
  getPaymentFactoryListData,
}) => {
  const history = useHistory();

  const [searchData, setSearchData] = useState<AdminGetList>({
    query: "",
    dropBox: "",
    startDate: "",
    endDate: "",
    size: 10,
  });

  const [detailData, setDetailData] = useState<AdminPaymentDeatilData | null>(
    null
  );
  const [requestType, setRequestType] = useState<string | null>(null);
  const [selectData, setSelectData] = useState<string | null>(null);
  const [searchId, setSearchId] = useState<number | null>(null);
  const [paymentType, setPaymentType] = useState<string>("default");
  const [franchiseName, setFranchiseName] = useState<string | null>(null);

  // const [brandSearchPage, setBrandSearchPage] = useState<number>(1);
  const [brandSearchData, setBrandSearchData] = useState<AdminGetList>({
    query: "",
    dropBox: "",
    startDate: "",
    endDate: "",
    size: 10,
  });
  // const [factorySearchPage, setFactorySearchPage] = useState<number>(1);
  const [factorySearchData, setFactorySearchData] = useState<AdminGetList>({
    query: "",
    dropBox: "",
    startDate: "",
    endDate: "",
    size: 10,
  });

  const [chartType, setChartType] = useState<string | null>("1");
  const [monthData, setMonthData] = useState<string>("");

  const [searchDate, setSearchDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [contractPrice, setContractPrice] = useState<number | null>();
  const [contractType, setContractType] = useState<string | null>("d2");
  const [contractDate, setContractDate] = useState({
    startDate: "",
    endDate: "",
  });

  const [searchCheck, setSearchCheck] = useState<boolean>(true);

  useEffect(() => {
    if (match.params.id === "detail") {
      setPaymentType("default");
      history.push(`/admin/payment`);
    }
  }, []);

  const query = window.location.pathname;

  useEffect(() => {
    if (query === `/admin/payment/aggregation`) setPaymentType("aggregation");
    if (query === `/admin/payment/detail`) setPaymentType("detail");
    if (query === `/admin/payment`) setPaymentType("default");
  }, [query]);

  const onBrandSearch = (data: AdminGetList) => {
    setBrandSearchData(data);
    getPaymentBrandListData(1, data);
  };
  const pageMoveBrand = (page: number) => {
    // setBrandSearchPage(page);
    getPaymentBrandListData(page, brandSearchData);
  };
  const onFactorySearch = (data: AdminGetList) => {
    setFactorySearchData(data);
    getPaymentFactoryListData(1, data);
  };
  const pageMoveFactory = (page: number) => {
    // setFactorySearchPage(page);
    getPaymentFactoryListData(page, factorySearchData);
  };

  const onSearchDate = (start: string, end: string) => {
    setSearchDate({
      startDate: start,
      endDate: end,
    });
  };

  const onSearch = (data: AdminGetList) => {
    setSearchData(data);

    if (data?.startDate && data?.endDate) {
      if(!data?.startDate || data?.startDate === "Invalid date")
        return alert("날짜를 확인 및 입력해주세요.");
      if(!data?.endDate || data?.endDate === "Invalid date")
        return alert("날짜를 확인 및 입력해주세요.");
    }
    if (requestType === "factory") paymentDeatilFactory(searchId!, data);
    if (requestType === "brand") paymentDeatilBrand(searchId!, data);
    if (paymentType === "default") {
      getPaymentListData(data);
    }
  };

  const onDetailList = (id: number, select: string, requestType: string) => {
    setPaymentType("detail");
    setSelectData(select);
    setRequestType(requestType);
    setSearchId(id);
    if (requestType === "factory") paymentDeatilFactory(id, searchData ?? null);
    if (requestType === "brand") paymentDeatilBrand(id, searchData ?? null);

    history.push(`/admin/payment/detail`);
  };

  useEffect(() => {
    if (requestType === "factory")
      return setDetailData(adminPaymentDeatilFactoryData);
    if (requestType === "brand")
      return setDetailData(adminPaymentDeatilBrandData);
  }, [adminPaymentDeatilBrandData, adminPaymentDeatilFactoryData, requestType]);

  const onClose = () => {
    setSelectData(null);
    history.push(`/admin/payment`);
  };

  const onSelector = (type: string) => {
    setPaymentType(type);
    history.push(`/admin/payment${type !== "default" ? "/aggregation" : ""}`);
  };

  const getAdminLaundryTableSearch = (
    id: number,
    name: string,
    data: AdminGetList | null
  ) => {
    setFranchiseName(name);
    setSearchData(data!);
    getAdminLaundryTable(id, data);
  };

  const contractDateData = (start: string, end: string) => {
    if (start.length === 1) start = `0${start}`;
    if (end.length === 1) end = `0${end}`;
    setContractDate({
      startDate: start,
      endDate: end,
    });
  };

  const monthSelector = (month: string, start: string, end: string) => {
    if (Number(start) === 1) {
      end = String(moment(month, "YYYY-MM").daysInMonth());
      setSearchCheck(false);
      // console.log(end);
    } else {
      if (end.length === 1) end = `0${end}`;
    }
    if (start.length === 1) start = `0${start}`;
    setMonthData(month);
    setContractDate({
      startDate: start,
      endDate: end,
    });
  };

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      fontWeight: 700,
    },
  });

  const classes = useStyles();

  return (
    <>
      <AdminPaymentStatusBlock>
        <div className="headers">
          <h3>정산 현황</h3>
          <div>
            <span
              className={paymentType !== "aggregation" ? "on" : ""}
              onClick={() => onSelector("default")}
            >
              총 정산 현황
            </span>
            <span>|</span>
            <span
              className={paymentType === "aggregation" ? "on" : ""}
              onClick={() => onSelector("aggregation")}
            >
              집계표
            </span>
          </div>
        </div>
        <div className="tableSelect">
          <div className="selectName">
            {paymentType === "detail" && selectData}
          </div>
          <div className="topWrapper">
            <span>Search: </span>
            <AdminPaymentSearch
              monthSelector={monthSelector}
              setContractPrice={setContractPrice}
              role={role}
              setContractType={setContractType}
              contractDateData={contractDateData}
              onSearchDate={onSearchDate}
              setMonthData={setMonthData}
              setChartType={setChartType}
              onSearch={onSearch}
              paymentType={paymentType}
              getAdminLaundryTable={getAdminLaundryTableSearch}
              specificationsFranchiseList={specificationsFranchiseList}
            />
          </div>
        </div>
      </AdminPaymentStatusBlock>
      {paymentType === "default" && (
        <>
          <AdminPaymentAdminBlock>
            <div className="headers">
              <h3>본사</h3>
            </div>
            <div className="tableSelect"></div>
            <div className="tableWrapper">
              <TableContainer component={Paper} className="tables">
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{ fontWeight: 900, width: "10%" }}
                      >
                        순번
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: 900, width: "37.5%" }}
                      >
                        이름
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: 900, width: "37.5%" }}
                      >
                        세탁물 수량(개)
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ fontWeight: 900, width: "15%" }}
                      >
                        금액(원)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {adminPaymentData?.list.list.map((admin, index) => (
                      <TableRow className="tableHover" key={index}>
                        <TableCell component="th" scope="row" align="center">
                          {index + 1}
                        </TableCell>
                        <TableCell align="center">{admin.name}</TableCell>
                        <TableCell align="center">
                          {NumberUtils.numberToComma(admin.amount)}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ paddingRight: "14px" }}
                        >
                          {NumberUtils.numberToComma(admin.price)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </AdminPaymentAdminBlock>
          <AdminPaymentFactoryBlock>
            <div className="headers">
              <h3>세탁공장</h3>
            </div>
            <div className="topSelect">
              <div className="topWrapper">
                <span>Search: </span>
                <PaymentBrandSearch role={role} onSearch={onFactorySearch} />
              </div>
            </div>
            <div className="tableSelect">
              <TableContainer component={Paper} className="tables">
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{ fontWeight: 900, width: "10%" }}
                      >
                        순번
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: 900, width: "37.5%" }}
                      >
                        이름
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: 900, width: "37.5%" }}
                      >
                        세탁물 수량(개)
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ fontWeight: 900, width: "15%" }}
                      >
                        금액(원)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {adminPaymentFactoryListData?.list.list.map(
                      (factory, index) => (
                        <TableRow
                          className="tableHover"
                          onClick={() =>
                            onDetailList(factory.id, factory.name, "factory")
                          }
                        >
                          <TableCell component="th" scope="row" align="center">
                            {index + 1}
                          </TableCell>
                          <TableCell align="center">{factory.name}</TableCell>
                          <TableCell align="center">
                            {NumberUtils.numberToComma(factory.amount)}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ paddingRight: "14px" }}
                          >
                            {NumberUtils.numberToComma(factory.price)}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                      ></TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: 900, color: "#000" }}
                      >
                        합계
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: 900,
                          color: "#000",
                        }}
                      >
                        {NumberUtils.numberToComma(
                          adminPaymentFactoryListData?.totalAmount ?? 0
                        )}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ fontWeight: 900, color: "#000" }}
                      >
                        {NumberUtils.numberToComma(
                          adminPaymentFactoryListData?.totalPrice ?? 0
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="paging">
                <Paging
                  listAction={pageMoveFactory}
                  listCount={adminPaymentFactoryListData?.list.totalPage ?? 1}
                  currentPage={adminPaymentFactoryListData?.list.currentPage}
                  totalCount={adminPaymentFactoryListData?.list.totalCount ?? 1}
                  size={factorySearchData.size ?? 10}
                ></Paging>
              </div>
            </div>
          </AdminPaymentFactoryBlock>
          <AdminPaymentBrandBlock>
            <div className="headers">
              <h3>브랜드</h3>
            </div>
            <div className="topSelect">
              <div className="topWrapper">
                <span>Search: </span>
                <PaymentBrandSearch role={role} onSearch={onBrandSearch} />
              </div>
            </div>
            <div className="tableSelect">
              <TableContainer component={Paper} className="tables">
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{ fontWeight: 900, width: "10%" }}
                      >
                        순번
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: 900, width: "37.5%" }}
                      >
                        이름
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: 900, width: "37.5%" }}
                      >
                        세탁물 수량(개)
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ fontWeight: 900, width: "15%" }}
                      >
                        금액(원)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {adminPaymentBrandListData?.list.list.map(
                      (brand, index) => (
                        <TableRow
                          className="tableHover"
                          onClick={() =>
                            onDetailList(brand.id, brand.name, "brand")
                          }
                        >
                          <TableCell component="th" scope="row" align="center">
                            {index + 1}
                          </TableCell>
                          <TableCell align="center">{brand.name}</TableCell>
                          <TableCell align="center">
                            {NumberUtils.numberToComma(brand.amount)}
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ paddingRight: "14px" }}
                          >
                            {NumberUtils.numberToComma(brand.price)}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                      ></TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: 900, color: "#000" }}
                      >
                        합계
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ fontWeight: 900, color: "#000" }}
                      >
                        {NumberUtils.numberToComma(
                          adminPaymentBrandListData?.totalAmount ?? 0
                        )}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          fontWeight: 900,
                          color: "#000",
                        }}
                      >
                        {NumberUtils.numberToComma(
                          adminPaymentBrandListData?.totalPrice ?? 0
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="paging">
                <Paging
                  listAction={pageMoveBrand}
                  listCount={adminPaymentBrandListData?.list.totalPage ?? 1}
                  currentPage={adminPaymentBrandListData?.list.currentPage}
                  totalCount={adminPaymentBrandListData?.list.totalCount ?? 1}
                  size={brandSearchData.size ?? 10}
                ></Paging>
              </div>
            </div>
          </AdminPaymentBrandBlock>
        </>
      )}
      {paymentType === "detail" && (
        <AdminPaymentDetailList detailData={detailData} onClose={onClose} />
      )}
      {paymentType === "aggregation" && (
        <AdminAggregationDetail
          contractType={contractType!}
          contractPrice={contractPrice!}
          startDate={
            chartType !== "aggregation1"
              ? searchDate.startDate
              : `${monthData}-${contractDate.startDate}`
          }
          endDate={
            chartType !== "aggregation1"
              ? searchDate.endDate
              : searchCheck
              ? moment(
                  new Date(`${monthData}-${contractDate.endDate}`).getTime()
                )
                  .add("1", "M")
                  .format("YYYY-MM-DD")
              : `${monthData}-${contractDate.endDate}`
          }
          dataList={laundryTable ?? null}
          franchiseName={franchiseName ?? ""}
        />
      )}
    </>
  );
};

export default AdminPaymentStatus;

const AdminPaymentStatusBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  padding-bottom: 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  margin-bottom: 16px;
  .tables {
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .tableHover > td {
    padding: 10px;
  }
  .tableHover:hover {
    background-color: #eee;
    cursor: pointer;
  }
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
    & > div {
      margin-left: 16px;
      width: auto;
      height: 22px;
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      & > span {
        font-weight: 400;
        color: #aaa;
        cursor: pointer;
        font-size: 14px;
      }
      & > span:nth-child(2) {
        font-size: 12px;
        align-self: center;
        cursor: default;
        margin: 0 10px;
        padding-top: 5px;
        color: #333;
      }
      & > .on {
        color: ${Palette.primary};
        font-weight: 600;
      }
    }
  }
  .tableSelect {
    height: 70px;
    padding-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > .selectName {
      color: #333;
      align-self: flex-end;
      font-weight: 600;
      font-size: 17px;
      line-height: 30px;
    }
    & > div {
      color: #999;
      height: 40px;
      /* overflow: hidden; */
      & > span {
        font-size: 14px;
        line-height: 37px;
      }
      & > select {
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        transition: ease-in-out;
        transition-duration: 0.1s;
      }
      & > select:hover {
        border-bottom: 2px solid ${Palette.primary};
      }
    }
  }
  .tableWrapper {
    min-width: 51rem;
    padding-bottom: 1.5rem;
    /* border-radius: 10px; */
  }
  .topWrapper {
    display: flex;
    justify-content: flex-end;
    /* background-color: red; */
    margin-top: 2px;
    & > span {
      font-size: 14px;
      line-height: 37px;
      margin-right: 5px;
    }
    & > div {
      /* width: auto; */
      /* display: inline-block; */
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div {
        margin: 0;
        color: #fff;
      }
      & > a {
        margin-left: 5px;
      }
    }
  }
`;

const AdminPaymentBrandBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  padding-bottom: 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  margin-bottom: 16px;
  .tableHover > td {
    padding: 10px;
  }
  .tableHover:hover {
    background-color: #eee;
    cursor: pointer;
  }
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  .topSelect {
    height: 70px;
    padding-top: 25px;
    /* background-color: red; */
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
    & > div {
      color: #999;
      height: 40px;
      overflow: hidden;
      & > span {
        font-size: 14px;
        line-height: 37px;
      }
      & > select {
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        transition: ease-in-out;
        transition-duration: 0.1s;
      }
      & > select:hover {
        border-bottom: 2px solid ${Palette.primary};
      }
    }
  }
  .tableSelect {
    .paging {
      margin-top: 10px;
    }
  }
`;

const AdminPaymentFactoryBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  padding-bottom: 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  margin-bottom: 16px;
  .tableHover > td {
    padding: 10px;
  }
  .tableHover:hover {
    background-color: #eee;
    cursor: pointer;
  }
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  .topSelect {
    height: 70px;
    padding-top: 25px;
    /* background-color: red; */
    display: flex;
    justify-content: flex-end;
    /* margin-bottom: 5px; */
    & > div {
      color: #999;
      height: 40px;
      overflow: hidden;
      & > span {
        font-size: 14px;
        line-height: 37px;
      }
      & > select {
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        transition: ease-in-out;
        transition-duration: 0.1s;
      }
      & > select:hover {
        border-bottom: 2px solid ${Palette.primary};
      }
    }
  }
  .tableSelect {
    .paging {
      margin-top: 10px;
    }
  }
`;

const AdminPaymentAdminBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  padding-bottom: 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  margin-bottom: 16px;
  .tableHover > td {
    padding: 10px;
  }
  .tableHover:hover {
    background-color: #eee;
    cursor: pointer;
  }
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
`;
