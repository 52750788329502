import MasterLaundryStatus from "components/master/MasterLaundryStatus";
import MasterLaundaryStatusDetail from "components/master/MasterLaundryStatusDetail";
import { IRootState } from "modules";
import {
  getMasterLaundryDetailAction,
  getMasterLaundryListAction,
} from "modules/master/master";
import { MasterGetList } from "modules/master/types";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

interface MasterLaundryStatusContainerProps {
  match: any;
}

const MasterLaundryStatusContainer: React.FC<MasterLaundryStatusContainerProps> =
  ({ match }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const role = useSelector((state: IRootState) => state.user.profile?.type);
    const masterLaundryList = useSelector(
      (state: IRootState) => state.master.masterLaundryList
    );
    const masterLaundryDetail = useSelector(
      (state: IRootState) => state.master.masterLaundryDetail
    );

    const [page, setPage] = useState<number>(1);
    const [searchData, setSearchData] = useState<MasterGetList | null>();
    // const [detailId, setDetailId] = useState<number | null>(null);
    const [selectType, setSelectType] = useState<string>("list");

    // useEffect(() => {
    //   setInterval(() => {
    //     dispatch(getMasterLaundryListAction(page, searchData ?? null));
    //   }, 5 * 60 * 1000);
    // }, []);

    useEffect(() => {
      if (match.params.id && Number(match.params.id)) {
        // setDetailId(match.params.id);
        setSelectType("detail");
        dispatch(getMasterLaundryDetailAction(match.params.id));
      } else {
        setSelectType("list");
      }
    }, [match.params.id]);

    const query = window.location.pathname;

    useEffect(() => {
      if (query === `/master/laundry`) {
        setSelectType("list");
        // setDetailId(null);
      } else {
        setSelectType("detail");
      }
    }, [query]);

    useEffect(() => {
      dispatch(getMasterLaundryListAction(1, null));
    }, [dispatch]);

    const laundryList = (page: number, data: MasterGetList | null) => {
      setPage(page);
      setSearchData(data);
      dispatch(getMasterLaundryListAction(page, data));
    };

    const laundryDetail = (id: number) => {
      // setDetailId(id);
      dispatch(getMasterLaundryDetailAction(id));
      setSelectType("detail");
      history.push(`/master/laundry/${id}`);
    };

    const onClose = () => {
      // setDetailId(null);
      setSelectType("list");
      history.push(`/master/laundry`);
    };

    if (!masterLaundryList) return null;
    return (
      <>
        {selectType === "list" && (
          <MasterLaundryStatus
            role={role}
            masterLaundryList={masterLaundryList}
            laundryList={laundryList}
            laundryDetail={laundryDetail}
          />
        )}
        {selectType === "detail" && (
          <MasterLaundaryStatusDetail
            onClose={onClose}
            masterLaundryDetail={masterLaundryDetail}
          />
        )}
      </>
    );
  };

export default MasterLaundryStatusContainer;
