import Button from "components/common/Button";
import Paging from "components/common/Paging";
import {
  BrandInventoryDataItem,
  BrandInventoryPatchData,
  Details,
  MasterItemDataModify,
  MasterItemList,
  MasterItemRegistration,
} from "modules/master/types";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import { Palette, ThemeColor } from "styles/palette";
import NumberUtils from "utils/NumberUtils";
import MasterItemAdd from "./MasterItemAdd";
import MasterItemModify from "./MasterItemModify";
import XLSX from "xlsx";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ModalTable from "./form/ModalTable";

interface MasterItemStatusProps {
  itemList: (page: number, size: number) => void;
  masterItemRegistration: (data: MasterItemRegistration, size: number) => void;
  masterItemList?: MasterItemList | null;
  masterItemModify: (payload: MasterItemDataModify, page: number, size: number) => void;
  masterItemRegistrationExcel: (
    assetDetailsCommandList: MasterItemRegistration[],
    size: number
  ) => Promise<void>;
  itemListAll: () => void;
  masterPatchItemModify: (data: BrandInventoryPatchData[], page: number, size: number) => void;
  masterItemListAll?: Details[] | null;
  masterInventoryListAll?: BrandInventoryDataItem[] | null;
  onDelete: (id: number, page: number, size: number) => void;
}

const MasterItemStatus: React.FC<MasterItemStatusProps> = ({
  itemList,
  masterItemList,
  masterItemRegistration,
  masterItemModify,
  masterItemRegistrationExcel,
  itemListAll,
  masterItemListAll,
  masterInventoryListAll,
  masterPatchItemModify,
  onDelete,
}) => {
  const [addItem, setAddItem] = useState(false);
  const [modifyItem, setModifyItem] = useState(false);
  const [item, setItem] = useState<Details | null>(null);

  const [selectSize, setSelectSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [excelFile, setExcelFile] = useState<any[]>([]);
  const [inputList, setInputList] = useState<MasterItemRegistration[]>([]);

  const [selItemInfoModal, setSelItemInfoModal] = useState<{
    itemId: number | null;
    check: boolean;
  }>({
    itemId: null,
    check: false,
  });

  const onClose = () => {
    setAddItem(false);
    setModifyItem(false);
  };
  const modifyPopupData = (
    detailsId: number,
    largeCategory: string | undefined,
    subCategory: string | undefined,
    name: string | undefined,
    unitPrice: number | undefined,
    content: string | undefined
  ) => {
    const data: Details = {
      detailsId,
      largeCategory,
      subCategory,
      name,
      unitPrice,
      content,
    };
    setItem(data);
    setModifyItem(true);
  };

  const pageMove = (page: number) => {
    setCurrentPage(page);
    itemList(page, selectSize);
  };

  const selectShowEntries = (size: number) => {
    setSelectSize(size);
    itemList(1, size ?? null);
  };

  const masterItemReg = (data: MasterItemRegistration) => {
    masterItemRegistration(data, selectSize);
  };

  const masterItemModi = (payload: MasterItemDataModify) => {
    masterItemModify(payload, currentPage, selectSize);
  };

  const masterItemRefExcel = async (list: MasterItemRegistration[]) => {
    // let assetDetailsCommandList = inputList;
    await masterItemRegistrationExcel(list, selectSize);
  };

  const [listNumber, setListNumber] = useState<number[]>([]);
  useEffect(() => {
    setListNumber(
      NumberUtils.numberToList(
        masterItemList?.currentPage ?? 0,
        masterItemList?.totalCount ?? 0,
        selectSize ?? 10
      )
    );
  }, [masterItemList, selectSize]);

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      fontWeight: 700,
    },
  });
  const classes = useStyles();

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    var input = e.target.files;

    if (!input) return;

    // size?: string | null;
    // largeCategory?: string | null; // 대분류
    // subCategory?: string | null; // 소분류
    // name?: string | null; // 품명
    // unitPrice?: number | null; // 단가
    // content?: string | null; // 비고
    var reader = new FileReader();
    reader.onload = async function () {
      var fileData = reader.result;
      var wb = XLSX.read(fileData, { type: "binary" });
      wb.SheetNames.forEach(function (sheetName) {
        var rowObj: any[] = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);
        setExcelFile(rowObj);
        let list = [];
        for (let i = 0; i < rowObj.length; i++) {
          // console.log(Object.keys(rowObj[i]));
          if (
            !Object.keys(rowObj[i]).includes("대분류") ||
            !Object.keys(rowObj[i]).includes("소분류") ||
            !Object.keys(rowObj[i]).includes("품명") ||
            !Object.keys(rowObj[i]).includes("사이즈") ||
            !Object.keys(rowObj[i]).includes("비고")
          ) {
            alert("데이터 형식이 맞지 않습니다.");
            return;
          }
          let item = {
            largeCategory: rowObj[i]["대분류"],
            subCategory: rowObj[i]["소분류"],
            name: rowObj[i]["품명"],
            size: rowObj[i]["사이즈"],
            content: rowObj[i]["비고"],
          };
          list.push(item);
        }
        setInputList([...list]);
        masterItemRefExcel(list);
        e.target.value = "";
      });
    };

    reader.readAsBinaryString(input[0]);
  };

  const excelDown = async () => {
    if (!masterItemListAll) return;

    let excelList = [];

    for (let i = 0; i < masterItemListAll.length; i++) {
      let arr = {
        대분류: masterItemListAll[i].largeCategory,
        소분류: masterItemListAll[i].subCategory,
        품명: masterItemListAll[i].name,
        사이즈: masterItemListAll[i].size,
        비고: masterItemListAll[i].content,
      };
      excelList.push(arr);
    }
    const ws = XLSX.utils.json_to_sheet(excelList);

    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, "자산표_브랜드.xlsx");
  };

  const exExcelDown = () => {
    let arr = [
      {
        대분류: "",
        소분류: "",
        품명: "",
        사이즈: "",
        비고: "",
      },
    ];
    const ws = XLSX.utils.json_to_sheet(arr);

    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, "자산표.xlsx");
  };

  //존재 이유를 몰라 주석처리함 해당 부분은 추후에 추가하시길 바람
  // const [itemDetailItems, setItemDetailItems] = useState<Details[]>([]);

  // useEffect(() => {
  //   setItemDetailItems(masterItemList?.list ?? []);
  // }, [masterItemList]);

  const [modal, setModal] = useState<boolean>(false);
  //상세페이지
  const [inventoryData, setInventoryData] = useState<BrandInventoryDataItem[]>([]);
  const [nowNumber, setNowNumber] = useState(1);

  ////////////Scroll Move System////////////
  //listHeight => currentScroll에서 처음에 스크롤할 부위의 높이 처음과 끝을 지정해주는 변수 값
  const listHeight = 500;
  //tableRef => 테이블 tr하나의 객체들을 각각 감싸서 배열로 넣는 Ref
  const tableRef = useRef<any>(null);
  //currentScroll => 스크롤 처음과 끝을 지정해 나중에 재사용하는 Ref 속성
  const currentScroll = useRef<any>({ scrollTop: 0, scrollBottom: listHeight });
  //containerRef => 전체를 감싸는 tbody의 Ref
  const containerRef = useRef<any>();

  const changeIdLocation = (id: number, status: boolean) => {
    let itemArray = [...inventoryData];
    const dataNumber = itemArray.findIndex((data) => data.detailsId === id);
    //status값에 따른 위 아래 움직임(true 위, false 아래)
    if (status) {
      if (dataNumber !== 0) {
        let prevData = itemArray[dataNumber - 1];
        itemArray[dataNumber - 1] = itemArray[dataNumber];
        itemArray[dataNumber] = prevData;

        //scrollIntoView => 해당 컨테이너(엘레멘트)의 위치로 스크롤해주는 메소드

        //스크롤 올리는 부분
        const elementTop = (dataNumber - 2) * 27;
        if (elementTop < currentScroll.current.scrollTop) {
          const prevItem = tableRef.current[dataNumber - 1];
          //block: "start" => 엘레멘트의 시작지점으로 이동
          prevItem && prevItem.scrollIntoView({ block: "start" });
        }

        setInventoryData(itemArray);
      }
    } else {
      if (dataNumber < itemArray.length - 1) {
        let nextData = itemArray[dataNumber + 1];
        itemArray[dataNumber + 1] = itemArray[dataNumber];
        itemArray[dataNumber] = nextData;

        //스크롤 내리는 부분
        const elementBottom = (dataNumber + 2) * 27;
        if (elementBottom > currentScroll.current.scrollBottom) {
          const nextItem = tableRef.current[dataNumber + 1];
          //block: "end" => 엘레멘트의 끝지점으로 이동
          nextItem && nextItem.scrollIntoView({ block: "end" });
        }

        setInventoryData(itemArray);
      }
    }
  };

  //현재 스크롤 값 저장
  //div의 onScroll의 경우에 적용되는 함수로
  //현재 div스크롤의 위치 처음과 위치 + div의 height값을 더함
  //listHeight는 그것을 상수로 직접 할당해서 한 방법
  //나중에 직접 Height를 구해서 가져올 방법이 있다면 그것도 좋을듯 함
  const onScroll = (e: any) => {
    currentScroll.current = {
      scrollTop: e.target.scrollTop,
      scrollBottom: e.target.scrollTop + listHeight,
    };
  };
  ////////////Scroll Move System////////////

  const uploadChangeIdData = () => {
    let data: BrandInventoryPatchData[] = [];
    for (let i = 0; i < inventoryData.length; i++) {
      data.push({ id: inventoryData[i].detailsId, sort: i + 1 });
    }
    masterPatchItemModify(data, currentPage, selectSize);
  };

  useEffect(() => {
    setInventoryData(masterInventoryListAll ? masterInventoryListAll : []);
  }, [masterInventoryListAll]);

  const onSelect = (id: number) => {
    let data: { itemId: number | null; check: boolean } = {
      itemId: null,
      check: false,
    };

    if (!selItemInfoModal.itemId || selItemInfoModal.itemId !== id) {
      data = {
        itemId: id,
        check: true,
      };
    }

    setSelItemInfoModal(data);
  };

  const onSelDelete = () => {
    if (window.confirm("등록된 태그 정보까지 삭제 됩니다.\n정말로 삭제하시겠습니까?")) {
      if (selItemInfoModal.itemId) onDelete(selItemInfoModal.itemId, currentPage, selectSize);

      setSelItemInfoModal({ itemId: null, check: false });
    }
  };

  return (
    <MasterItemStatusBlock>
      {modal && (
        <ModalTable
          uploadChangeIdData={uploadChangeIdData}
          setModal={setModal}
          onClose={() => {
            changeIdLocation(nowNumber, true);
            setModal(false);
          }}
        >
          <div className="header">
            <h3>세탁물 종류</h3>
            {/* <span>선택된 번호 : {nowNumber === 0 ? "없음" : nowNumber}</span> */}

            <div>
              <div
                className={nowNumber === 0 ? "arrowBox noSelect" : "arrowBox"}
                onClick={() => changeIdLocation(nowNumber, true)}
              >
                ▲
              </div>
              <div
                className={nowNumber === 0 ? "arrowBox noSelect" : "arrowBox"}
                onClick={() => changeIdLocation(nowNumber, false)}
              >
                ▼
              </div>
            </div>
          </div>
          <div className="paper" onScroll={onScroll}>
            <div>
              <table>
                <tbody ref={containerRef}>
                  {inventoryData?.map((data, key) => (
                    <tr
                      key={key}
                      onClick={(e) => {
                        setNowNumber(data?.detailsId);
                      }}
                      className={nowNumber === data?.detailsId ? "selected" : ""}
                      //ref 추가
                      ref={(ref) => {
                        tableRef.current = { ...tableRef.current, [key]: ref };
                      }}
                    >
                      <td>{data?.largeCategory}</td>
                      <td>{data?.subCategory}</td>
                      <td>{data?.name}</td>
                      <td>{data?.size}</td>
                      <td>{data?.content}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </ModalTable>
      )}

      {addItem && (
        <MasterItemAdd masterItemRegistration={masterItemReg} type="enroll" onClose={onClose} />
      )}
      {modifyItem && (
        <MasterItemModify item={item} onClose={onClose} masterItemModify={masterItemModi} />
      )}
      <div className="headers">
        <h3>자산 관리</h3>
      </div>
      <div className="tableSelect">
        <div>
          <span>Show </span>
          <select onChange={(e) => selectShowEntries(parseInt(e.target.value, 10))}>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
          <span> entries</span>
        </div>
        <div className="topWrapper">
          <div>
            <Button
              theme={ThemeColor.primary}
              buttonSize="m"
              onClick={() => {
                setModal(true);
              }}
            >
              순서변경
            </Button>
            <Button theme={ThemeColor.primary} buttonSize="m" onClick={() => exExcelDown()}>
              예시엑셀
            </Button>
            <Button theme={ThemeColor.primary} buttonSize="m" onClick={() => excelDown()}>
              엑셀저장
            </Button>
            <div className={"excelBtn"}>
              <label>
                엑셀업로드
                <input
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  name="excelInput"
                  id="excel_upload_input"
                  onChange={(e) => {
                    handleFile(e);
                  }}
                />
              </label>
            </div>
            <Button theme={ThemeColor.primary} buttonSize="m" onClick={() => setAddItem(!addItem)}>
              등록
            </Button>
            {selItemInfoModal.check && (
              <Button theme={ThemeColor.warning} buttonSize="m" onClick={onSelDelete}>
                삭제
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="tableWrapper">
        <TableContainer component={Paper} className="tables">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: 900,
                    width: "70px",
                  }}
                >
                  순번
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900 }}>
                  대분류
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: 900,
                    width: "15%",
                  }}
                >
                  소분류
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900, width: "15%" }}>
                  품명
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: 900,
                    width: "15%",
                  }}
                >
                  사이즈
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900, width: "20%" }}>
                  비고
                </TableCell>
                <TableCell style={{ width: "15%" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="tableBody">
              {masterItemList?.list.map((item, index) => (
                <TableRow
                  key={index}
                  className={`tableHover ${
                    selItemInfoModal.itemId === item.detailsId && "selItemInfo"
                  }`}
                  onClick={() => onSelect(item.detailsId)}
                >
                  <TableCell component="th" scope="row" align="center">
                    {listNumber[index]}
                  </TableCell>
                  <TableCell align="center">{item.largeCategory}</TableCell>
                  <TableCell align="center">{item.subCategory}</TableCell>
                  <TableCell align="center">{item.name}</TableCell>
                  <TableCell align="center">{item.size}</TableCell>
                  <TableCell align="center">{item.content}</TableCell>
                  <TableCell align="center">
                    <Button
                      theme={ThemeColor.secondary}
                      buttonSize="s"
                      onClick={(e) => {
                        e.stopPropagation();
                        modifyPopupData(
                          item.detailsId,
                          item?.largeCategory ?? undefined,
                          item?.subCategory ?? undefined,
                          item?.name ?? undefined,
                          item?.unitPrice ?? undefined,
                          item?.content ?? undefined
                        );
                      }}
                    >
                      수정
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Paging
          listAction={pageMove}
          listCount={masterItemList?.totalPage ?? 0}
          currentPage={masterItemList?.currentPage}
          totalCount={masterItemList?.totalCount}
          size={selectSize ?? 10}
        ></Paging>
      </div>
    </MasterItemStatusBlock>
  );
};

export default MasterItemStatus;

const MasterItemStatusBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);

  /* .registrationButton {
    height: 36.9px;
    display: flex;
    justify-content: flex-end;
  } */
  .tables {
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  .tableSelect {
    height: 70px;
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: space-between;
    & > div {
      color: #999;
      height: 40px;
      /* background-color: red; */
      overflow: hidden;
      & > span {
        font-size: 14px;
        line-height: 37px;
      }
      & > select {
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        transition: ease-in-out;
        transition-duration: 0.1s;
      }
      & > select:hover {
        border-bottom: 2px solid ${Palette.primary};
      }
    }
  }
  .topWrapper {
    display: flex;
    justify-content: flex-end;
    & > div {
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div {
        margin: 0;
        margin-left: 3px;
      }
      & > .excelBtn {
        & > label {
          display: inline-block;
          width: 75px;
          padding: 0.563rem 0;
          text-align: center;
          font-size: 14px;
          color: #fff;
          border-radius: 5px;
          cursor: pointer;

          background-color: ${Palette.primary};
          & > input {
            display: none;
          }
        }
      }
    }
  }
  .tableWrapper {
    min-width: 51rem;
    padding-bottom: 1.5rem;
  }
  .tableHover:hover {
    background-color: #eee;
    cursor: pointer;
  }
  .tableBody {
    tr {
      height: 53px !important;
      td {
        padding: 0;
      }
    }
  }

  .selItemInfo {
    background-color: pink;
  }
`;
