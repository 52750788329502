import DashBoardWrap from "components/common/DashBoardWrap";
import moment from "moment";
import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import NumberUtils from "utils/NumberUtils";
import Calendar from "components/common/Calendar/Calendar";
import "react-calendar/dist/Calendar.css";
import { AdminDashBoardData } from "modules/admin/types";
import { useHistory } from "react-router";

import up_line from "assets/icons/up_line.png";
import down_line from "assets/icons/down_line.png";
import info from "assets/icons/info.png";
import DashBoardTitleWrap from "components/common/DashBoardTitleWrap";
import { GraphKindTapType } from "interface/Admin/Dashboard/DashboardTypes";
import MonthCalendar from "components/common/Calendar/MonthCalendar";
import admin from "modules/admin/admin";

interface AdminDashBoardProps {
  adminDashboard: AdminDashBoardData | null;
  timeNow: Date;
  getDashboard: (day: string) => void;
  kindTap: GraphKindTapType;
  onKindTap: (data: GraphKindTapType) => void;
}

const AdminDashBoard: React.FC<AdminDashBoardProps> = ({
  adminDashboard,
  timeNow,
  getDashboard,
  kindTap,
  onKindTap,
}) => {
  const history = useHistory();

  const days = ["일", "월", "화", "수", "목", "금", "토"];
  const [graphData, setGraphData] = useState({
    series: [
      {
        name: kindTap.graphName,
        data: [0],
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "45%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },

      colors: ["#1B2253"],
      xaxis: {
        categories: [
          moment(new Date()).format("MM/DD") + " (" + days[moment(new Date()).day()] + ")",
        ],
      },
      yaxis: {
        title: {
          text: "",
        },
        labels: {
          formatter: (val: any) => {
            return String(NumberUtils.numberToComma(val));
          },
        },
      },
      grid: {
        borderColor: "#f1f1f1",
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return String(NumberUtils.numberToComma(val)) + ` 원`;
          },
        },
      },
    },
  });

  const [date, setDate] = useState<moment.Moment>(() => moment());

  useEffect(() => {
    if (adminDashboard) {
      let arr = [];
      let xa = [];
      for (let i = 0; i < adminDashboard.dateAndLaundryCountList.length; i++) {
        arr.push(adminDashboard.dateAndLaundryCountList[i].count);
        // xa.push(
        //   moment(adminDashboard.dateAndLaundryCountList[i].date).format("MM/DD") +
        //     " (" +
        //     days[moment(adminDashboard.dateAndLaundryCountList[i].date).day()] +
        //     ")"
        // );
        xa.push(moment(adminDashboard.dateAndLaundryCountList[i].date).format("M월"));
      }
      // data.series[0].data = [...arr];
      // data.options.xaxis.categories = [...xa];

      // console.log(kindTap);

      let unit = kindTap.unit;

      setGraphData({
        series: [
          {
            name: kindTap.graphName,
            data: [...arr],
          },
        ],
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },

          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "45%",
              endingShape: "rounded",
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
          },

          colors: ["#1B2253"],
          xaxis: {
            categories: [...xa],
          },
          yaxis: {
            title: {
              text: "",
            },
            labels: {
              formatter: (val: any) => {
                return String(NumberUtils.numberToComma(val));
              },
            },
          },
          grid: {
            borderColor: "#f1f1f1",
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val: any) {
                return String(NumberUtils.numberToComma(val)) + ` 원`;
              },
            },
          },
        },
      });
    }
  }, [adminDashboard, kindTap]);

  const dateChange = (e: moment.Moment) => {
    setDate(e);
    getDashboard(moment(e).format("YYYY-MM-DD"));
    // console.log(moment(e).format("YYYY-MM-DD"));
  };

  const onSelectKindTap = (type: string) => {
    let keyType: string = type;
    let graphName: string = "";
    let unit: string = "";

    if (type === "laundry") {
      graphName = "예상매출";
      unit = "원";
    }
    if (type === "sales") {
      graphName = "매출";
      unit = "원";
    }
    if (type === "purchase") {
      graphName = "매입";
      unit = "원";
    }

    let data = {
      keyType,
      graphName,
      unit,
    };

    onKindTap(data);
  };

  return (
    <>
      <DashBoardTitleWrap>
        <div className="headers">
          {/* <h3>리드고 대시보드</h3> */}
          <h3>대시보드</h3>
        </div>
      </DashBoardTitleWrap>
      <DashBoardWrap>
        <div className={"top"}>
          <div className={"graphWrap"}>
            <div className={"graph"}>
              <div className={"graphKindTab"}>
                <div>
                  <div
                    className={`${kindTap.keyType === "laundry" && "select"}`}
                    onClick={() => onSelectKindTap("laundry")}
                  >
                    세탁현황
                  </div>
                  <span>|</span>
                  <div
                    className={`${kindTap.keyType === "sales" && "select"}`}
                    onClick={() => onSelectKindTap("sales")}
                  >
                    매출
                  </div>
                  <span>|</span>
                  <div
                    className={`${kindTap.keyType === "purchase" && "select"}`}
                    onClick={() => onSelectKindTap("purchase")}
                  >
                    매입
                  </div>
                </div>
                <span>단위: 원</span>
              </div>
              <ReactApexChart
                options={graphData.options}
                series={graphData.series}
                type="bar"
                height={320}
                className="apex-charts"
              />
              <div className="timeNow">
                최근 갱신 일 : {moment(timeNow).format("YYYY-MM-DD HH:mm:SS")}
              </div>
            </div>
            <div className={"count"}>
              <div>
                <h4>
                  {kindTap.keyType === "laundry" && "세탁(건)"}
                  {(kindTap.keyType === "sales" || kindTap.keyType === "purchase") &&
                    "세금계산서 발행 건 수"}
                </h4>
                <h3>
                  {kindTap.keyType === "laundry" &&
                    NumberUtils.numberToComma(
                      adminDashboard ? adminDashboard.before7DaysTotalCount : 0
                    )}
                  {(kindTap.keyType === "sales" || kindTap.keyType === "purchase") && (
                    <>
                      {adminDashboard?.before7DaysTotalCount}/{adminDashboard?.totalBillingCount}
                      <span>(완료/전체)</span>
                    </>
                  )}
                </h3>
              </div>
              <div>
                <h4>
                  {kindTap.keyType === "laundry" && "세탁물(수량)"}
                  {(kindTap.keyType === "sales" || kindTap.keyType === "purchase") && "정산 현황"}
                </h4>
                <h3>
                  {kindTap.keyType === "laundry" &&
                    NumberUtils.numberToComma(
                      adminDashboard ? adminDashboard.before7DaysTotalAmount : 0
                    )}
                  {(kindTap.keyType === "sales" || kindTap.keyType === "purchase") && (
                    <>
                      {adminDashboard?.before7DaysTotalAmount}/{adminDashboard?.totalBillingCount}
                      <span>(완료/전체)</span>
                    </>
                  )}
                </h3>
              </div>
              <div>
                <h4>
                  {kindTap.keyType === "laundry" && "예상 매출(원)"}
                  {kindTap.keyType === "sales" && "매출 합계(원)"}
                  {kindTap.keyType === "purchase" && "매입 합계(원)"}
                </h4>
                <h3>
                  ₩{" "}
                  {NumberUtils.numberToComma(
                    adminDashboard ? adminDashboard.before7DaysTotalPrice : 0
                  )}
                </h3>
              </div>
            </div>
          </div>
          <div className={"calendar"}>
            <div className="infoBox">
              <div className="info">
                <span>서비스가이드</span>
                <span>
                  <img src={info} alt="" />
                </span>
              </div>
              <div className="infoGuide">
                캘린더에서 선택한 월의 데이터가 표시되며,
                <br />
                좌측의 그래프는 1년간의 데이터가 표시됩니다.
              </div>
            </div>
            <h3>캘린더</h3>
            <div>
              {/* <Calendar dateChange={dateChange} date={date} /> */}
              <MonthCalendar dateChange={dateChange} date={date} />
            </div>
            <div className={"comparison"}>
              <h3>전월 대비</h3>
              <div>
                <div>
                  <figure>
                    <img
                      src={
                        adminDashboard
                          ? adminDashboard.monthLaundryCount >= 0
                            ? up_line
                            : down_line
                          : up_line
                      }
                      alt=""
                    />
                  </figure>
                  <div>
                    <span>세탁(건)</span>
                    <strong>
                      {adminDashboard
                        ? adminDashboard.monthLaundryCount >= 0
                          ? "+ " + NumberUtils.numberToComma(adminDashboard.monthLaundryCount)
                          : NumberUtils.numberToComma(adminDashboard.monthLaundryCount)
                        : 0}
                    </strong>
                  </div>
                </div>
                {/* <div>
                  <figure>
                    <img
                      src={
                        adminDashboard
                          ? adminDashboard.weekContrastAmount >= 0
                            ? up_line
                            : down_line
                          : up_line
                      }
                      alt=""
                    />
                  </figure>
                  <div>
                    <span>세탁물(개)</span>
                    <strong>
                      {adminDashboard
                        ? adminDashboard.weekContrastAmount >= 0
                          ? "+ " + NumberUtils.numberToComma(adminDashboard.weekContrastAmount)
                          : NumberUtils.numberToComma(adminDashboard.weekContrastAmount)
                        : 0}
                    </strong>
                  </div>
                </div> */}
                <div>
                  <figure>
                    <img
                      src={
                        adminDashboard
                          ? adminDashboard.weekContrastPrice >= 0
                            ? up_line
                            : down_line
                          : up_line
                      }
                      alt=""
                    />
                  </figure>
                  <div>
                    <span>
                      {kindTap.keyType === "laundry" && "예상 매출(원)"}
                      {kindTap.keyType === "sales" && "매출(원)"}
                      {kindTap.keyType === "purchase" && "매입(원)"}
                    </span>
                    <strong>
                      {adminDashboard
                        ? adminDashboard.weekContrastPrice >= 0
                          ? "+ " + NumberUtils.numberToComma(adminDashboard.weekContrastPrice)
                          : NumberUtils.numberToComma(adminDashboard.weekContrastPrice)
                        : 0}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"bottom"}>
          <div className={"laundry count"}>
            <div className="infoBox">
              <div className="info">
                <span>서비스가이드</span>
                <span>
                  <img src={info} alt="" />
                </span>
              </div>
              <div className="infoGuide">
                오늘의 세탁현황을 확인할 수 있으며, 지난주
                <br />
                대비 세탁현황을 비교할 수 있습니다.
              </div>
            </div>
            <div className={"nums"}>
              <div>
                <h3>총 세탁</h3>
                <div className={"today"}>
                  <span>오늘</span>
                  <strong>
                    {NumberUtils.numberToComma(
                      adminDashboard
                        ? adminDashboard.laundryTodayCount
                          ? adminDashboard.laundryTodayCount
                          : 0
                        : 0
                    )}
                  </strong>
                  <span>건</span>
                </div>
                {/* <div className={"accumulate"}>
                  <span>누적</span>
                  <strong>
                    {NumberUtils.numberToComma(
                      adminDashboard
                        ? adminDashboard.laundryAccumulationCount
                          ? adminDashboard.laundryAccumulationCount
                          : 0
                        : 0
                    )}
                  </strong>
                  <span>건</span>
                </div> */}
              </div>
              <div>
                <h3>총 세탁 수량</h3>
                <div className={"today"}>
                  <span>오늘</span>
                  <strong>
                    {NumberUtils.numberToComma(
                      adminDashboard
                        ? adminDashboard.laundryTodayAmount
                          ? adminDashboard.laundryTodayAmount
                          : 0
                        : 0
                    )}
                  </strong>
                  <span>개</span>
                </div>
                {/* <div className={"accumulate"}>
                  <span>누적</span>
                  <strong>
                    {NumberUtils.numberToComma(
                      adminDashboard
                        ? adminDashboard.laundryAccumulationAmount
                          ? adminDashboard.laundryAccumulationAmount
                          : 0
                        : 0
                    )}
                  </strong>
                  <span>개</span>
                </div> */}
              </div>
            </div>
          </div>
          <div className={"laundryBoard"}>
            <div className="infoBox">
              <div className="info">
                <span>서비스가이드</span>
                <span>
                  <img src={info} alt="" />
                </span>
              </div>
              <div className="infoGuide infoCenter">최신 등록된 게시글을 확인하실 수 있습니다.</div>
            </div>
            <h3>세탁 게시판</h3>
            <div className={"dataWrap"}>
              {adminDashboard &&
                adminDashboard.boardList.map((val, index) => (
                  <div
                    className={"listWrap"}
                    key={index}
                    onClick={() => {
                      history.push(`/admin/board/${val.id}`);
                    }}
                  >
                    <div className={"role"}>
                      <h4 className="boardTitle">{val.writer.nickname}</h4>
                      <p>
                        {val.writer.roles[0] === "ROLE_MASTER_ADMIN" && "본사"}
                        {val.writer.roles[0] === "ROLE_MASTER" && "브랜드"}
                        {val.writer.roles[0] === "ROLE_FRANCHISE" && "지점"}
                        {val.writer.roles[0] === "ROLE_FACTORY" && "세탁공장"}
                      </p>
                    </div>
                    <div className="boardTitle">{val.title}</div>
                  </div>
                ))}
            </div>
            <div className={"more"}>
              <button
                onClick={() => {
                  history.push("/admin/board");
                }}
              >
                more
              </button>
            </div>
          </div>
          <div className={"noticeBoard"}>
            <div className="infoBox">
              <div className="info">
                <span>서비스가이드</span>
                <span>
                  <img src={info} alt="" />
                </span>
              </div>
              <div className="infoGuide infoCenter">
                최신 등록된 지점내역을 확인하실 수 있습니다.
              </div>
            </div>
            <h3>지점관리</h3>
            <div className={"dataWrap"}>
              {adminDashboard &&
                adminDashboard.franchiseIdResponseList.map((val, index) => (
                  <React.Fragment key={val.franchiseId}>
                    {index < 5 && (
                      <div
                        className={"listWrap"}
                        onClick={() => {
                          history.push(`/admin/store/${val.franchiseId}`);
                        }}
                      >
                        <div className={"role"}>
                          <h4>{val.franchiseName}</h4>
                          <p>{val.address.street + " " + val.address.streetDetails}</p>
                        </div>
                        <div>{moment(val.createdDate).format("YY.MM.DD")}</div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
            </div>
            <div className={"more"}>
              <button
                onClick={() => {
                  history.push("/admin/store");
                }}
              >
                more
              </button>
            </div>
          </div>
        </div>
      </DashBoardWrap>
    </>
  );
};

export default AdminDashBoard;

// const AdminDashBoardBlock = styled(StoreDashboardBlock);
