import AdminProfile from "components/admin/AdminProfile";
import React from "react";

interface AdminProfileContainerProps {}

const AdminProfileContainer: React.FC<AdminProfileContainerProps> = () => {
  return <AdminProfile />;
};

export default AdminProfileContainer;
