// import { MasterFactoryStatusDetailBlock } from "components/master/MasterFactoryDetail";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DateFormatUtils from "utils/DateFormatUtils";
import { ThemeColor } from "styles/palette";
import Button from "components/common/Button";
import { AdminFactoryDetail } from "modules/admin/types";
import DaumPostCode from "components/common/DaumPostCode";
import { CenterAligned } from "components/common/layouts/CenterAligned";
import { ProfileModify } from "modules/user/types";

interface AdminFactoryStatusDetailProps {
  role: string;
  onClose?: () => void;
  adminFactoryDetail: AdminFactoryDetail | null;
  modifyFactoryUserName: (id: number, userName: string) => void;
  postPWInitialization: (id: number) => void;
  historyBack?: () => void;
  factoryInfoModify: (payload: ProfileModify) => void;
  adminIdDuplicateCheck: string | null;
  duplicateCheckRequest: (id: string) => void;
  duplicateCheckCancel: () => void;
}

const AdminFactoryStatusDetail: React.FC<AdminFactoryStatusDetailProps> = ({
  role,
  adminFactoryDetail,
  modifyFactoryUserName,
  postPWInitialization,
  onClose,
  historyBack,
  factoryInfoModify,
  adminIdDuplicateCheck,
  duplicateCheckRequest,
  duplicateCheckCancel,
}) => {
  const [duplicateCheck, setDuplicateCheck] = useState<string>("N");

  const [newUserName, setNewUserName] = useState<string | null>(null);
  // const [onModi, setOnModi] = useState<boolean>(false);
  const [onInfoModi, setOnInfoModi] = useState<boolean>(false);
  const [postCodeCheck, setPostCodeCheck] = useState(false);

  const [companyName, setCompanyName] = useState<string>("");
  const [companyPhone, setCompanyPhone] = useState<string>();
  const [companyStreetDetail, setCompanyStreetDetail] = useState<string>("");
  const [companyManagerName, setCompanyManagerName] = useState<string>("");
  const [companyManagerPhone, setCompanyManagerPhone] = useState<string>("");
  const [representative, setRepresentative] = useState<string>("");
  const [registrationNumber, setRegistrationNumber] = useState<string>("");
  const [fax, setFax] = useState<string>("");
  const [fee, setFee] = useState<number>(0);

  const [zoneCode, setZoneCode] = useState<string>("");
  const [fullAddress, setFullAddress] = useState<string>("");

  const [defaultState, setDefaultState] = useState({
    newUserName: "",
    companyName: "",
    companyPhone: "",
    zoneCode: "",
    fullAddress: "",
    companyStreetDetail: "",
    companyManagerName: "",
    companyManagerPhone: "",
    representative: "",
    registrationNumber: "",
    fax: "",
    fee: 0,
  });

  useEffect(() => {
    setNewUserName(adminFactoryDetail?.manager?.username ?? "");
    setCompanyName(adminFactoryDetail?.companyName ?? "-");
    setCompanyPhone(adminFactoryDetail?.companyPhone ?? "-");
    setZoneCode(adminFactoryDetail?.companyAddress?.postCode ?? "-");
    setFullAddress(adminFactoryDetail?.companyAddress?.street ?? "-");
    setCompanyStreetDetail(adminFactoryDetail?.companyAddress?.streetDetails ?? "-");
    setCompanyManagerName(adminFactoryDetail?.manager?.nickname ?? "-");
    setCompanyManagerPhone(adminFactoryDetail?.manager?.phone ?? "-");
    setRepresentative(adminFactoryDetail?.representative ?? "-");
    setRegistrationNumber(adminFactoryDetail?.registrationNumber ?? "-");
    setFax(adminFactoryDetail?.fax ?? "-");
    setFee(adminFactoryDetail?.fee ?? 0);

    setDefaultState({
      newUserName: adminFactoryDetail?.manager?.username ?? "",
      companyName: adminFactoryDetail?.companyName ?? "",
      companyPhone: adminFactoryDetail?.companyPhone ?? "",
      zoneCode: adminFactoryDetail?.companyAddress?.postCode ?? "",
      fullAddress: adminFactoryDetail?.companyAddress?.street ?? "",
      companyStreetDetail: adminFactoryDetail?.companyAddress?.streetDetails ?? "",
      companyManagerName: adminFactoryDetail?.manager?.nickname ?? "",
      companyManagerPhone: adminFactoryDetail?.manager?.phone ?? "",
      representative: adminFactoryDetail?.representative ?? "",
      registrationNumber: adminFactoryDetail?.registrationNumber ?? "",
      fax: adminFactoryDetail?.fax ?? "",
      fee: adminFactoryDetail?.fee ?? 0,
    });
  }, [adminFactoryDetail]);

  // const onSubmit = (id: number) => {
  //   if (!newUserName) return alert("빈값은 입력할 수 없습니다");
  //   if (window.confirm("아이디를 변경하싰겠습니까?")) {
  //     modifyFactoryUserName(id, newUserName!);
  //     // setOnModi(false);
  //     historyBack!();
  //   }
  // };

  const onAddress = (zoneCode: string, fullAddress: string) => {
    setZoneCode(zoneCode);
    setFullAddress(fullAddress);
    setPostCodeCheck(false);
  };

  const onCloseModify = () => {
    setOnInfoModi(false);
    setNewUserName(defaultState.newUserName);
    setCompanyName(defaultState.companyName);
    setCompanyPhone(defaultState.companyPhone);
    setZoneCode(defaultState.zoneCode);
    setFullAddress(defaultState.fullAddress);
    setCompanyStreetDetail(defaultState.companyStreetDetail);
    setCompanyManagerName(defaultState.companyManagerName);
    setCompanyManagerPhone(defaultState.companyManagerPhone);
    setRepresentative(defaultState.representative);
    setRegistrationNumber(defaultState.registrationNumber);
    setFax(defaultState.fax);
  };

  const modifySubmit = () => {
    if (defaultState.newUserName !== newUserName && duplicateCheck === "N")
      return alert("중복된 아이디 입니다. 확인해주세요.");
    let address = {
      postCode: zoneCode!,
      street: fullAddress!,
      streetDetails: companyStreetDetail!,
      isLoadName: true,
    };

    let payload = {
      id: newUserName!,
      companyName: companyName!,
      phone: companyPhone!,
      address: address!,
      managerName: companyManagerName!,
      managerPhone: companyManagerPhone!,
      representative: representative!,
      registrationNumber: registrationNumber!,
      fax: fax!,
      fee: fee,
    };
    if (window.confirm("정보를 수정하시겠습니까?")) {
      factoryInfoModify(payload);
      setOnInfoModi(false);
    }
  };

  useEffect(() => {
    duplicateCheckCancel();
  }, []);

  useEffect(() => {
    setDuplicateCheck(adminIdDuplicateCheck ?? "N");
  }, [adminIdDuplicateCheck]);

  return (
    <AdminFactoryStatusDetailBlock>
      {postCodeCheck && (
        <div className="postCode">
          <div>
            <DaumPostCode onAddress={onAddress} />
            <CenterAligned>
              <Button
                theme={ThemeColor.tertiary}
                buttonSize="m"
                onClick={() => setPostCodeCheck(false)}
                className="postCodeCloseBtn"
              >
                닫기
              </Button>
            </CenterAligned>
          </div>
        </div>
      )}
      <div className="headers">
        <h3>세탁공장 상세</h3>
        <Button theme={ThemeColor.primary} buttonSize="m" onClick={historyBack}>
          뒤로가기
        </Button>
      </div>
      <div className="contentWrapper">
        <div className="detailInfo">
          <div className="field">
            <span>아이1디</span>
            <div>
              <input
                value={newUserName!}
                onChange={(e) => setNewUserName(e.target.value)}
                className="changeId"
                disabled={!onInfoModi}
              />
              {onInfoModi && (
                <Button
                  theme={ThemeColor.primary}
                  buttonSize="m"
                  className="changeIdBtn"
                  onClick={() => duplicateCheckRequest(newUserName!)}
                >
                  중복체크
                </Button>
              )}
            </div>
          </div>
          <div className="field">
            <span>{""}</span>
            <Button
              theme={ThemeColor.primary}
              buttonSize="m"
              className="PWBtn"
              onClick={() => postPWInitialization(Number(adminFactoryDetail?.manager?.id))}
            >
              비밀번호초기화
            </Button>
          </div>
          <div className="field">
            <span>세탁공장명</span>
            {onInfoModi ? (
              <input value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
            ) : (
              <p>{adminFactoryDetail?.companyName ?? "-"}</p>
            )}
          </div>
          <div className="field">
            <span>대표자</span>
            {onInfoModi ? (
              <input value={representative} onChange={(e) => setRepresentative(e.target.value)} />
            ) : (
              <p>{adminFactoryDetail?.representative ?? "-"}</p>
            )}
          </div>
          <div className="field">
            <span>등록번호</span>
            {onInfoModi ? (
              <input
                value={registrationNumber}
                onChange={(e) => setRegistrationNumber(e.target.value)}
              />
            ) : (
              <p>{adminFactoryDetail?.registrationNumber ?? "-"}</p>
            )}
          </div>
          <div className="field">
            <span>연락처</span>
            {onInfoModi ? (
              <input value={companyPhone} onChange={(e) => setCompanyPhone(e.target.value)} />
            ) : (
              <p>{adminFactoryDetail?.companyPhone ?? "-"}</p>
            )}
          </div>
          <div className="field">
            <span>FAX</span>
            {onInfoModi ? (
              <input value={fax} onChange={(e) => setFax(e.target.value)} />
            ) : (
              <p>{adminFactoryDetail?.fax ?? "-"}</p>
            )}
          </div>
          <div className="field secoundField">
            <span>주소</span>
            {onInfoModi ? (
              <>
                <input value={zoneCode} disabled />
                <Button
                  theme={ThemeColor.primary}
                  buttonSize="m"
                  onClick={() => setPostCodeCheck(true)}
                >
                  찾기
                </Button>
              </>
            ) : (
              <p>{adminFactoryDetail?.companyAddress?.postCode}</p>
            )}
          </div>
          <div className="field">
            <span></span>
            {onInfoModi ? (
              <input value={fullAddress} disabled />
            ) : (
              <p>{adminFactoryDetail?.companyAddress?.street ?? "-"}</p>
            )}
          </div>
          <div className="field">
            <span></span>
            {onInfoModi ? (
              <input
                value={companyStreetDetail}
                onChange={(e) => setCompanyStreetDetail(e.target.value)}
              />
            ) : (
              <p>{adminFactoryDetail?.companyAddress?.streetDetails ?? "-"}</p>
            )}
          </div>

          <div className="field">
            <span>담당자</span>
            {onInfoModi ? (
              <input
                value={companyManagerName}
                onChange={(e) => setCompanyManagerName(e.target.value)}
              />
            ) : (
              <p>{adminFactoryDetail?.manager?.nickname ?? "-"}</p>
            )}
          </div>
          <div className="field">
            <span>담당자 연락처</span>
            {onInfoModi ? (
              <input
                value={companyManagerPhone}
                onChange={(e) => setCompanyManagerPhone(e.target.value)}
              />
            ) : (
              <p>{adminFactoryDetail?.manager?.phone ?? "-"}</p>
            )}
          </div>
          <div className="field">
            <span>가입일</span>
            <p>{DateFormatUtils.dateToFormat(adminFactoryDetail?.joinDate)}</p>
          </div>
          <div className="field">
            <span>수수료(%)</span>
            {onInfoModi ? (
              <input value={fee} onChange={(e) => setFee(Number(e.target.value))} />
            ) : (
              <p>{adminFactoryDetail?.fee}</p>
            )}
          </div>
          <div className="buttonWrapper">
            {!onInfoModi ? (
              <Button theme={ThemeColor.primary} buttonSize="m" onClick={() => setOnInfoModi(true)}>
                수정
              </Button>
            ) : (
              <>
                <Button theme={ThemeColor.primary} buttonSize="m" onClick={modifySubmit}>
                  저장
                </Button>
                <Button theme={ThemeColor.primary} buttonSize="m" onClick={onCloseModify}>
                  취소
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="stockStatus">
          <div>
            <h3>매칭지점</h3>
            <div className="stockInField">
              <div>
                {" "}
                <div className="stockList">
                  {adminFactoryDetail?.franchiseList &&
                    adminFactoryDetail?.franchiseList.map((item, idx) => <p key={idx}>{item}</p>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminFactoryStatusDetailBlock>
  );
};

export default AdminFactoryStatusDetail;

const AdminFactoryStatusDetailBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  & > .contentWrapper {
    width: 100%;
    min-height: 760px;

    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    & > div {
      width: calc(100% / 2);
    }
    & > div:nth-child(2) {
      border-left: 1px solid black;
    }
    & > .detailInfo {
      width: auto;
      margin: 0 auto;

      & > .field {
        width: 100%;
        min-height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
        & > span {
          width: 140px;
        }
        & > p,
        input {
          border: 1px solid #ddd;
          font-size: 14px;
          border-radius: 5px;
          height: 100%;
          width: 260px;
          line-height: 35px;
          margin: 0;
          padding-left: 10px;
        }
        & > input {
          height: 37px;
        }
        & > div {
          & > .changeId {
            width: 179px;
            border: 1px solid #ddd;
            height: 37px;
            margin-right: 5px;
          }
        }
        & > .PWBtn {
          width: 260px;
        }
      }
      & > .secoundField {
        & > p,
        input {
          width: 174px;
          margin-right: 10px;
        }
      }
      & > .buttonWrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 50px;
      }
    }
    & > .stockStatus {
      height: 760px;
      & > div {
        width: 500px;
        height: auto;
        margin: 0 auto;
        & > h3 {
          margin: 0;
        }
        & > .stockInField {
          margin-top: 20px;
          & > div {
            width: 100%;
            height: 690px;
            border-radius: 10px;
            background-color: #ecf0f5;
            padding: 20px 5px;
            overflow: hidden;
            & > .stockList {
              overflow-y: scroll;
              height: 95%;
              /* margin-top: 20px; */
              padding-left: 20px;
              & > div {
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #fff;
                padding: 2px 0;
                & > span {
                  width: calc(100% / 6);
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }
  .postCode {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    & > div {
      width: 30rem;
      & > div {
        & > .postCodeCloseBtn {
          margin-top: 1rem;
        }
      }
    }
  }
`;
