import Button from "components/common/Button";
import Paging from "components/common/Paging";
import PaymentSearch from "components/common/PaymentSearch";
import {
  FactoryBillingDetail,
  FactoryBillingList,
  FactoryGetList,
} from "modules/factory/types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Palette, ThemeColor } from "styles/palette";
import DateFormatUtils from "utils/DateFormatUtils";
import NumberUtils from "utils/NumberUtils";
import PriceUtils from "utils/PriceUtils";
import FactoryPaymentStatusDetail from "./FactoryPaymentStatusDetail";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

interface FactoryPaymentStatusProps {
  role: string;
  toggle: boolean;
  onToggle: () => void;
  factoryBillingList: FactoryBillingList | null;
  factoryBillingDetail: FactoryBillingDetail | null;
  getFactoryBillingDetail: (id: number) => void;
  patchFactoryLaundryData: (id: number, price: number, status: string) => void;
  billingList: (page: number, data: FactoryGetList | null) => void;
}

const FactoryPaymentStatus: React.FC<FactoryPaymentStatusProps> = ({
  role,
  toggle,
  onToggle,
  factoryBillingList,
  factoryBillingDetail,
  getFactoryBillingDetail,
  patchFactoryLaundryData,
  billingList,
}) => {
  const [searchData, setSearchData] = useState<FactoryGetList>({
    query: "",
    dropBox: "",
    startDate: "",
    endDate: "",
    size: 10,
  });

  const onSearch = (page: number, data: FactoryGetList) => {
    setSearchData(data);
    billingList(page, data);
  };
  const pageMove = (page: number) => {
    billingList(page, searchData ?? null);
  };

  const selectShowEntries = (size: number) => {
    searchData.size = size;
    billingList(1, searchData ?? null);
  };

  const [listNumber, setListNumber] = useState<number[]>([]);
  useEffect(() => {
    setListNumber(
      NumberUtils.numberToList(
        factoryBillingList?.currentPage ?? 0,
        factoryBillingList?.totalCount ?? 0,
        searchData.size ?? 10
      )
    );
  }, [factoryBillingList, searchData]);

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      fontWeight: 700,
    },
  });
  const classes = useStyles();

  return (
    <FactoryPaymentStatusBlock>
      {/* {toggle && (
        <FactoryPaymentStatusDetail
          onToggle={onToggle}
          factoryBillingDetail={factoryBillingDetail}
          patchFactoryLaundryData={patchFactoryLaundryData}
        />
      )} */}
      <div className="headers">
        <h3>정산 현황</h3>
      </div>
      <div className="tableSelect">
        <div>
          <span>Show </span>
          <select
            onChange={(e) => selectShowEntries(parseInt(e.target.value, 10))}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
          <span> entries</span>
        </div>
        <div className="topWrapper">
          <span>Search: </span>
          <div>
            <PaymentSearch
              role={role}
              onSearch={onSearch}
              size={searchData.size}
              type="factoryPayment"
            />
          </div>
        </div>
      </div>
      <div className="tableWrapper">
        <TableContainer component={Paper} className="tables">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "70px" }}
                >
                  순번
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900 }}>
                  지점명
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "17%" }}
                >
                  세탁신청일
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "17%" }}
                >
                  납품일
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "17%" }}
                >
                  세탁물 수량(개)
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "20%" }}
                >
                  결제금액(원)
                </TableCell>
                {/* <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "10%" }}
                >
                  정산상태
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {factoryBillingList?.list.map((item, index) => (
                <TableRow
                  key={index}
                  className="tableHover"
                  onClick={() => getFactoryBillingDetail(item.id)}
                >
                  <TableCell component="th" scope="row" align="center">
                    {listNumber[index]}
                  </TableCell>
                  <TableCell align="center">
                    {item.franchiseCompanyName}
                  </TableCell>
                  <TableCell align="center">
                    {DateFormatUtils.dateToFormat(item.applicationDate)}
                  </TableCell>
                  <TableCell align="center">
                    {DateFormatUtils.dateToFormat(item.completedDate)}
                  </TableCell>
                  <TableCell align="center">
                    {NumberUtils.numberToComma(item.totalAmount ?? 0)}
                  </TableCell>
                  <TableCell align="center">
                    {NumberUtils.numberToComma(item.price ?? 0)}
                  </TableCell>
                  {/* <TableCell align="center">
                    {PriceUtils.priceStatusToStringColor(item.billingStatus)}
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Paging
          listAction={pageMove}
          listCount={factoryBillingList?.totalPage ?? 0}
          currentPage={factoryBillingList?.currentPage}
          totalCount={factoryBillingList?.totalCount}
          size={searchData.size ?? 10}
        ></Paging>
      </div>

      {/* <PaymentStatusListContainer /> */}
    </FactoryPaymentStatusBlock>
  );
};

export default FactoryPaymentStatus;

const FactoryPaymentStatusBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);

  .tables {
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  .topWrapper {
    display: flex;
    justify-content: flex-end;
    & > span {
      font-size: 14px;
      line-height: 37px;
      margin-right: 5px;
    }
    & > div {
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div {
        margin: 0;
        margin-left: 1rem;
      }
    }
  }
  .tableWrapper {
    min-width: 51rem;
    padding-bottom: 1.5rem;
  }
  .tableHover:hover {
    background-color: #eee;
    cursor: pointer;
  }
  .tableSelect {
    height: 70px;
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: space-between;
    & > div {
      color: #999;
      height: 40px;
      /* background-color: red; */
      overflow: hidden;
      & > span {
        font-size: 14px;
        line-height: 37px;
      }
      & > select {
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        transition: ease-in-out;
        transition-duration: 0.1s;
      }
      & > select:hover {
        border-bottom: 2px solid ${Palette.primary};
      }
    }
  }
  .paymentInfo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & > div {
      align-self: flex-end;
    }
    p {
      span + span {
        margin-left: 3rem;
      }
      strong {
        font-weight: normal;
        text-decoration: underline;
      }
    }
  }
`;
