import PaymentStatusDetail from "components/common/PaymentStatusDetail";
import StorePaymentStatus from "components/store/StorePaymentStatus";
import { IRootState } from "modules";
import {
  getFranchiseBillingDetailAction,
  getFranchiseBillingListAction,
} from "modules/store/store";
import { FranchiseGetList } from "modules/store/types";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

interface StorePaymentStatusContainerProps {
  match: any;
}

const StorePaymentStatusContainer: React.FC<StorePaymentStatusContainerProps> =
  ({ match }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const type = useSelector((state: IRootState) => state.user.profile?.type);
    const franchiseBillingPage = useSelector(
      (state: IRootState) => state.store.franchiseBillingPage
    );

    const franchiseDetailBilling = useSelector(
      (state: IRootState) => state.store.franchiseDetailBilling
    );

    const [page, setPage] = useState<number>(1);
    const [searchData, setSearchData] = useState<FranchiseGetList | null>();
    // const [detailId, setDetailId] = useState<number | null>(null);
    const [selectType, setSelectType] = useState<string>("list");

    useEffect(() => {
      dispatch(getFranchiseBillingListAction(1, null));
    }, [dispatch]);

    // useEffect(() => {
    //   setInterval(() => {
    //     dispatch(getFranchiseBillingListAction(page, searchData ?? null));
    //   }, 5 * 60 * 1000);
    // }, []);

    useEffect(() => {
      if (match.params.id && Number(match.params.id)) {
        // setDetailId(match.params.id);
        setSelectType("detail");
        dispatch(getFranchiseBillingDetailAction(match.params.id));
      } else {
        setSelectType("list");
      }
    }, [match.params.id]);

    const query = window.location.pathname;

    useEffect(() => {
      if (query === `/store/payment`) {
        setSelectType("list");
        // setDetailId(null);
      } else {
        setSelectType("detail");
      }
    }, [query]);

    const billingList = (page: number, data: FranchiseGetList | null) => {
      setPage(page);
      setSearchData(data);
      dispatch(getFranchiseBillingListAction(page, data));
    };

    const onDetail = (id: number) => {
      // setDetailId(id);
      dispatch(getFranchiseBillingDetailAction(id));
      setSelectType("detail");
      history.push(`/store/payment/${id}`);
    };

    const onClose = () => {
      // setDetailId(null);
      setSelectType("list");
      history.push(`/store/payment`);
    };

    if (type === "GUEST") return null;
    if (!franchiseBillingPage) return <h1>로딩중</h1>;
    return (
      <>
        {selectType === "list" && (
          <StorePaymentStatus
            role={type!}
            onDetail={onDetail}
            franchiseBillingPage={franchiseBillingPage}
            franchiseDetailBilling={franchiseDetailBilling!}
            billingList={billingList}
          />
        )}
        {selectType === "detail" && (
          <PaymentStatusDetail
            role={type!}
            onClose={onClose}
            franchiseDetailBilling={franchiseDetailBilling!}
          />
        )}
      </>
    );
  };

export default StorePaymentStatusContainer;
