import MasterPaymentDetail from "components/master/MasterPaymentDetail";
import MasterPaymentStatus from "components/master/MasterPaymentStatus";
import { IRootState } from "modules";
import {
  getMasterBillingDetailAction,
  getMasterBillingListAction,
} from "modules/master/master";
import { MasterGetList } from "modules/master/types";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

interface MasterPaymentStatusContainerProps {
  match: any;
}

const MasterPaymentStatusContainer: React.FC<MasterPaymentStatusContainerProps> =
  ({ match }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const user = useSelector((state: IRootState) => state.user.profile);
    const masterBillingList = useSelector(
      (state: IRootState) => state.master.masterBillingList
    );

    const masterBillingDetail = useSelector(
      (state: IRootState) => state.master.masterBillingDetail
    );

    const [page, setPage] = useState<number>(1);
    const [searchData, setSearchData] = useState<MasterGetList | null>();
    // const [detailId, setDetailId] = useState<number | null>(null);
    const [selectType, setSelectType] = useState<string>("list");

    // useEffect(() => {
    //   setInterval(() => {
    //     dispatch(getMasterBillingListAction(page, searchData ?? null));
    //   }, 5 * 60 * 1000);
    // }, []);

    useEffect(() => {
      if (match.params.id && Number(match.params.id)) {
        // setDetailId(match.params.id);
        setSelectType("detail");
        dispatch(getMasterBillingDetailAction(match.params.id));
      } else {
        setSelectType("list");
      }
    }, [match.params.id]);

    const query = window.location.pathname;

    useEffect(() => {
      if (query === `/master/payment`) {
        setSelectType("list");
        // setDetailId(null);
      } else {
        setSelectType("detail");
      }
    }, [query]);

    useEffect(() => {
      dispatch(getMasterBillingListAction(1, null));
    }, [dispatch]);

    const billingList = (page: number, data: MasterGetList | null) => {
      setPage(page);
      setSearchData(data);
      dispatch(getMasterBillingListAction(page, data));
    };

    const billingDetail = (id: number) => {
      // setDetailId(id);
      dispatch(getMasterBillingDetailAction(id));
      setSelectType("detail");
      history.push(`/master/payment/${id}`);
    };

    const onClose = () => {
      // setDetailId(null);
      setSelectType("list");
      history.push(`/master/payment`);
    };

    return (
      <>
        {selectType === "list" && (
          <MasterPaymentStatus
            billingList={billingList}
            masterBillingList={masterBillingList}
            billingDetail={billingDetail}
            masterBillingDetail={masterBillingDetail}
            role={user.type}
          />
        )}
        {selectType === "detail" && (
          <MasterPaymentDetail
            masterBillingDetail={masterBillingDetail}
            onClose={onClose}
          />
        )}
      </>
    );
  };

export default MasterPaymentStatusContainer;
