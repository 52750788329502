import LoginForm from "components/login/LoginForm";
import useInputs from "hooks/useInputs";
import { ILoginForm } from "modules/user/types";
import { getLoginKeepAction, loginAction } from "modules/user/user";
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import RoleUtils from "utils/RoleUtils";

interface LoginFormContainerProps {}

const LoginFormContainer: React.FC<LoginFormContainerProps> = () => {
  const dispatch = useDispatch();

  const [inputs, onChange] = useInputs<ILoginForm>({
    username: "",
    password: "",
  });

  useEffect(() => {
    if (RoleUtils.checkKeepLogin() && RoleUtils.checkLogin())
      dispatch(getLoginKeepAction());
  }, [dispatch]);

  const onLogin = () => {
    if (inputs.username === "") return alert("아이디를 입력해주세요");
    if (inputs.password === "") return alert("패스워드를 입력해주세요");

    dispatch(
      // TODO: loginAction arguments를 inputs로 갈아끼우기
      loginAction({
        username: inputs.username,
        password: inputs.password,
      })
    );
  };
  return (
    <>
      {/* <button
        onClick={() =>
          dispatch(
            // TODO: loginAction arguments를 inputs로 갈아끼우기
            loginAction({
              username: "test-franchise-1",
              password: "test-franchise-1",
            })
          )
        }
      >
        프랜차이즈 로그인
      </button>
      <button
        onClick={() =>
          dispatch(
            // TODO: loginAction arguments를 inputs로 갈아끼우기
            loginAction({
              username: "test-factory-1",
              password: "test-factory-1",
            })
          )
        }
      >
        세탁공장 로그인
      </button>
      <button
        onClick={() =>
          dispatch(
            // TODO: loginAction arguments를 inputs로 갈아끼우기
            loginAction({
              username: "test-brand-1",
              password: "test-brand-1",
            })
          )
        }
      >
        브랜드 로그인
      </button>
      <button
        onClick={() =>
          dispatch(
            // TODO: loginAction arguments를 inputs로 갈아끼우기
            loginAction({
              username: "admin",
              password: "admin",
            })
          )
        }
      >
        린넨서울
      </button> */}
      <LoginForm inputs={inputs} onChange={onChange} onLogin={onLogin} />
    </>
  );
};

export default LoginFormContainer;
