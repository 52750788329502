import FactoryPaymentStatus from "components/factory/FactoryPaymentStatus";
import FactoryPaymentStatusDetail from "components/factory/FactoryPaymentStatusDetail";
import { IRootState } from "modules";
import {
  getFactoryBillingDetailAction,
  getFactoryBillingListAction,
  patchFactoryBillingDataAction,
} from "modules/factory/factory";
import { BillingData, FactoryGetList } from "modules/factory/types";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

interface FactoryPaymentStatusContainerProps {
  match: any;
}

const FactoryPaymentStatusContainer: React.FC<FactoryPaymentStatusContainerProps> =
  ({ match }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [toggle, setToggle] = useState(false);

    const role = useSelector((state: IRootState) => state.user.profile?.type);
    const factoryBillingList = useSelector(
      (state: IRootState) => state.factory.factoryBillingList
    );
    const factoryBillingDetail = useSelector(
      (state: IRootState) => state.factory.factoryBillingDetail
    );

    const [page, setPage] = useState<number>(1);
    const [searchData, setSearchData] = useState<FactoryGetList | null>();
    // const [detailId, setDetailId] = useState<number | null>(null);
    const [selectType, setSelectType] = useState<string>("list");

    const onToggle = () => {
      setToggle(!toggle);
    };

    // useEffect(() => {
    //   setInterval(() => {
    //     dispatch(getFactoryBillingListAction(page, searchData ?? null));
    //   }, 5 * 60 * 1000);
    // }, []);

    useEffect(() => {
      if (match.params.id && Number(match.params.id)) {
        // setDetailId(match.params.id);
        setSelectType("detail");
        dispatch(getFactoryBillingDetailAction(match.params.id));
      } else {
        setSelectType("list");
      }
    }, [match.params.id]);

    const query = window.location.pathname;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (query === `/factory/payment`) {
        setSelectType("list");
        // setDetailId(null);
      } else {
        setSelectType("detail");
      }
    }, [query]);

    useEffect(() => {
      dispatch(getFactoryBillingListAction(1, null));
    }, [dispatch]);

    const billingList = (page: number, data: FactoryGetList | null) => {
      setPage(page);
      setSearchData(data);
      dispatch(getFactoryBillingListAction(page, data));
    };

    const patchFactoryLaundryData = (
      id: number,
      price: number,
      status: string
    ) => {
      const payload: BillingData = {
        id,
        price,
        status,
      };
      dispatch(patchFactoryBillingDataAction(payload));
    };

    const getFactoryBillingDetail = (id: number) => {
      // setDetailId(id);
      dispatch(getFactoryBillingDetailAction(id));
      setSelectType("detail");
      history.push(`/factory/payment/${id}`);
    };

    const onClose = () => {
      // setDetailId(null);
      setSelectType("list");
      history.push(`/factory/payment`);
    };

    return (
      <>
        {selectType === "list" && (
          <FactoryPaymentStatus
            role={role}
            toggle={toggle}
            onToggle={onToggle}
            factoryBillingList={factoryBillingList}
            billingList={billingList}
            factoryBillingDetail={factoryBillingDetail}
            getFactoryBillingDetail={getFactoryBillingDetail}
            patchFactoryLaundryData={patchFactoryLaundryData}
          />
        )}
        {selectType === "detail" && (
          <FactoryPaymentStatusDetail
            onClose={onClose}
            factoryBillingDetail={factoryBillingDetail}
            patchFactoryLaundryData={patchFactoryLaundryData}
          />
        )}
      </>
    );
  };

export default FactoryPaymentStatusContainer;
