import ProfileStatusContainer from "containers/common/ProfileStatusContainer";
import React from "react";
import styled from "styled-components";

interface FactoryProfileProps {}

const FactoryProfileBlock = styled.div`
  /* height: 100%;
  padding: 3rem; */
`;

const FactoryProfile: React.FC<FactoryProfileProps> = () => {
  return (
    <FactoryProfileBlock>
      <ProfileStatusContainer />
    </FactoryProfileBlock>
  );
};

export default FactoryProfile;
