import FactoryLaundrySignUpStatus from "components/factory/FactoryLaundrySignUpStatus";
import { IRootState } from "modules";
import {
  getFactoryMatchedFranchiseDataAction,
  postFactoryLaundryRequestDataAction,
} from "modules/factory/factory";
import { FactoryGetList } from "modules/factory/types";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

interface FactoryLaundrySignUpContainerProps {}

const FactoryLaundrySignUpContainer: React.FC<FactoryLaundrySignUpContainerProps> =
  () => {
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(getFactoryMatchedFranchiseDataAction(1, null));
    }, [dispatch]);

    const matchedFranchiseList = useSelector(
      (state: IRootState) => state.factory.matchedFranchiseList
    );

    const matchedList = (
      page: number,
      data: FactoryGetList | null,
      sort?: string
    ) => {
      dispatch(getFactoryMatchedFranchiseDataAction(page, data, sort));
    };

    const laundryRequest = (
      id: number,
      bagCount: number,
      page: number,
      data: FactoryGetList | null
    ) => {
      dispatch(postFactoryLaundryRequestDataAction(id, bagCount, page, data));
    };

    return (
      <FactoryLaundrySignUpStatus
        matchedFranchiseList={matchedFranchiseList}
        matchedList={matchedList}
        laundryRequest={laundryRequest}
      />
    );
  };

export default FactoryLaundrySignUpContainer;
