import Prepared from "components/common/Prepared";
import AppLayout from "components/layouts/AppLayout";
import BoardContainer from "containers/common/BoardContainer";
import CheckLocationContainer from "containers/common/CheckLocationContainer";
import NoticeContainer from "containers/common/NoticeContainer";
import StoreDashboardContainer from "containers/store/StoreDashboardContainer";
import StoreInventoryStatusContainer from "containers/store/StoreInventoryStatusContainer";
import StoreItemizedAccountContainer from "containers/store/StoreItemizedAccountContainer";
import StoreLaundryContainer from "containers/store/StoreLaundryContainer";
import StorePaymentStatusContainer from "containers/store/StorePaymentStatusContainer";
import StoreProfileContainer from "containers/store/StoreProfileContainer";
import StoreStaffInfoContainer from "containers/store/StoreStaffInfoContainer";
import StoreStaffInventoryContainer from "containers/store/StoreStaffInventoryContainer";
import React from "react";
import { Route, Switch } from "react-router";

interface StorePageProps {}

const StorePage: React.FC<StorePageProps> = () => {
  return (
    <AppLayout>
      <Switch>
        <Route
          exact
          path="/store/laundry/:id"
          component={StoreLaundryContainer}
        />
        <Route exact path="/store/laundry" component={StoreLaundryContainer} />

        <Route
          path="/store/itemizedAccount/:id"
          component={StoreItemizedAccountContainer}
        />

        <Route
          path="/store/itemizedAccount"
          component={StoreItemizedAccountContainer}
        />

        <Route exact path="/store/notice/:id" component={NoticeContainer} />

        <Route exact path="/store/notice/detail" component={NoticeContainer} />
        <Route exact path="/store/notice" component={NoticeContainer} />

        {/* <Route exact path="/store/board/detail" component={BoardContainer} /> */}
        <Route
          exact
          path="/store/board/modify/:id"
          component={BoardContainer}
        />
        <Route exact path="/store/board/add" component={BoardContainer} />
        <Route exact path="/store/board" component={BoardContainer} />
        <Route exact path="/store/board/:id" component={BoardContainer} />

        <Route
          exact
          path="/store/inventory/staff/info"
          component={StoreStaffInfoContainer}
        />
        <Route
          exact
          path="/store/inventory/staff/property"
          component={StoreStaffInventoryContainer}
        />
        <Route
          exact
          path="/store/inventory"
          component={StoreInventoryStatusContainer}
        />

        <Route
          exact
          path="/store/payment/:id"
          component={StorePaymentStatusContainer}
        />
        <Route
          exact
          path="/store/payment"
          component={StorePaymentStatusContainer}
        />
        <Route path="/store/profile" component={StoreProfileContainer} />
        <Route path="/store/dashboard" component={StoreDashboardContainer} />
        <Route path="/store/checkLocation" component={CheckLocationContainer} />
        <Route component={Prepared} />
      </Switch>
    </AppLayout>
  );
};

export default StorePage;
