import { MonthYearAccordian } from "./MonthYearAccordian";
import { Dates } from "./Dates";
import React, { useState, useRef, useMemo } from "react";
import { makeIso, todaysDayNumber, DAY, SIZE, getLanguage } from "./util";
import { useOnClickOutside } from "./useOnClickOutside";
import FocusLock from "react-focus-lock";

const monthFormatters = {};

export function Pane({ autoFocus, onSelect, defaultValue, setIsOpen }: any) {
  const [dayNumber, setDayNumber] = useState<any>(
    defaultValue || todaysDayNumber()
  );
  const [mode, setMode] = useState<any>(0);
  const monthYearFormatter = useMemo(
    () =>
      Intl.DateTimeFormat(getLanguage(), { month: "long", year: "numeric" }),
    []
  );
  const datesRef = useRef<any>();
  const elRef = useRef<any>();
  const date = new Date(dayNumber * DAY);
  const monthNumber = date.getUTCFullYear() * 12 + date.getUTCMonth();
  useOnClickOutside(elRef, (e: any) => setIsOpen(false));

  function setMonthYear2(monthNumber: any) {
    const year = (monthNumber / 12) | 0;
    const month = monthNumber % 12;
    const lastDateOfMonth = new Date(Date.UTC(year, month + 1, 0)).getDate();
    const _date = Math.min(date.getUTCDate(), lastDateOfMonth);
    //const yearMonth = new Date(dayNumber * DAY);
    const _dayNumber = new Date(Date.UTC(year, month, _date)).getTime() / DAY;
    setDayNumber(_dayNumber);
    setMode(0);
  }

  return (
    <FocusLock
      as="div"
      ref={elRef}
      returnFocus
      lockProps={{
        style: {
          width: SIZE * 8,
          padding: 16,
          borderRadius: 4,
          boxShadow:
            "rgba(0, 15, 23, 0.1) 0px 0.25rem 1rem 0px, rgba(0, 15, 23, 0.1) 0px 0.5rem 1rem 0px",
          position: "absolute",
          backgroundColor: "#fff",
          right: "-150px",
          // zIndex: 3,
        },
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button onClick={() => setMode(1 - mode)} type="button">
          {monthYearFormatter.format(
            new Date(date.getUTCFullYear(), date.getUTCMonth())
          )}
        </button>
        {mode === 0 && (
          <div>
            <button
              type="button"
              onClick={() => datesRef.current.previousMonth()}
            >
              ▲
            </button>
            <button type="button" onClick={() => datesRef.current.nextMonth()}>
              ▼
            </button>
          </div>
        )}
      </div>
      {mode ? (
        <MonthYearAccordian
          autoFocus={autoFocus}
          defaultValue={monthNumber}
          onChange={setMonthYear2}
        />
      ) : (
        <Dates
          ref={datesRef}
          autoFocus={autoFocus}
          defaultValue={dayNumber}
          onChange={(dayNumber: any) => {
            setDayNumber(dayNumber);
            onSelect(dayNumber);
          }}
          onSelect={setDayNumber}
        />
      )}
    </FocusLock>
  );
}
