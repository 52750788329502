import Button from "./../../Button";
import { LaundrySearchBlock } from "components/common/LaundrySearch";
import React from "react";
import styled from "styled-components";
import { ThemeColor } from "styles/palette";
import { CalculateListSearchTypes } from "interface/Admin/CalculateListTypes";

interface CalculateSearchBoxTypes {
  searchData: CalculateListSearchTypes;
  onSearch: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}

function CalculateSearchBox(props: CalculateSearchBoxTypes) {
  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    props.onChange(e);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSearch();
  };

  const onClickSubmit = () => {
    props.onSearch();
  };

  return (
    <CalculateSearchBoxBlock onSubmit={(e) => onSubmit(e)}>
      <div className="selectWrap">
        <span>Select: </span>
        <div>
          <select className="selectBox" name="taxStatus" onChange={(e) => onChange(e)}>
            <option value="" selected={!props.searchData.taxStatus}>
              전체보기
            </option>
            <option value="Publish" selected={props.searchData.taxStatus === "Publish"}>
              발행완료
            </option>
            <option value="UnPublish" selected={props.searchData.taxStatus === "UnPublish"}>
              미발행
            </option>
          </select>
          <select className="selectBox" name="billingStatus" onChange={(e) => onChange(e)}>
            <option value="" selected={!props.searchData.billingStatus}>
              전체보기
            </option>
            <option value="Completion" selected={props.searchData.billingStatus === "Completion"}>
              정산완료
            </option>
            <option
              value="UnCompletion"
              selected={props.searchData.billingStatus === "UnCompletion"}
            >
              미완료
            </option>
          </select>
        </div>
      </div>
      <span className="searchSpan">Search: </span>
      <input
        className="textBox"
        name="query"
        value={props.searchData.query ?? ""}
        onChange={(e) => onChange(e)}
      />
      <Button
        theme={ThemeColor.primary}
        buttonSize="m"
        onClick={onClickSubmit}
        className="buttonSize"
      >
        검색
      </Button>
    </CalculateSearchBoxBlock>
  );
}

export default CalculateSearchBox;

const CalculateSearchBoxBlock = styled(LaundrySearchBlock)`
  display: flex;
  justify-content: flex-start;
  & > .selectWrap {
    width: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  span {
    font-size: 14px;
    line-height: 37px;
    margin-right: 8px;
  }
  .searchSpan {
    margin-left: 14px;
  }
`;
