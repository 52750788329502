import React, { ChangeEvent, useState } from "react";
import styled from "styled-components";
import Button from "components/common/Button";
import { Palette, ThemeColor } from "styles/palette";
import {
  ClassificationItem,
  FactoryLaundryDetail,
  Item,
  LaundryClassification,
} from "modules/factory/types";
import { useEffect } from "react";
import ScrollUtils from "utils/ScrollUtils";
import DateFormatUtils from "utils/DateFormatUtils";
import NumberUtils from "utils/NumberUtils";
import moment from "moment";

interface FactoryLaundryStatusUploadProps {
  onEnrollToggle: (type: boolean) => void;
  factoryLaundryDetail: FactoryLaundryDetail | null;
  laundryClassification: (payload: LaundryClassification) => void;
  modifyLaundryClassification: (payload: LaundryClassification) => void;
  detailType: boolean;
}

type FactoryLaundryStatusUploadBlockProps = {
  type: boolean;
};

const FactoryLaundryStatusUpload: React.FC<FactoryLaundryStatusUploadProps> = ({
  onEnrollToggle,
  factoryLaundryDetail,
  laundryClassification,
  modifyLaundryClassification,
  detailType,
}) => {
  const [newItems, setNewItems] = useState<Item[] | null>(null);
  const [outBagCount, setOutBagCount] = useState<number | null>(null);
  const [deliveryDate, setDeliveryDate] = useState<Date | null>(null);

  useEffect(() => {
    if (factoryLaundryDetail) {
      setNewItems(factoryLaundryDetail?.items);
      setOutBagCount(factoryLaundryDetail?.laundryResponse.outBagCount);
      setDeliveryDate(factoryLaundryDetail.laundryResponse.completedDate);
    }
  }, [factoryLaundryDetail]);

  useEffect(() => {
    ScrollUtils.onPopupScrollStop(window.scrollY);
    return () => {
      const scrollY = document.body.style.top;
      ScrollUtils.closePopupScrollRun(scrollY);
    };
  }, []);

  const amountHandler = (e: ChangeEvent<HTMLInputElement>, id: number) => {
    const { value } = e.target;

    setNewItems(
      newItems!.map((item) =>
        item.id === id
          ? {
              ...item,
              amount: Number(
                parseInt(value, 10) <= 0 ? 0 : parseInt(value, 10)
              ),
            }
          : item
      )
    );
  };

  const relaunderingAmountHandler = (
    e: ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const { value } = e.target;
    setNewItems(
      newItems!.map((item) =>
        item.id === id
          ? {
              ...item,
              relaundering: Number(
                parseInt(value, 10) <= 0 ? 0 : parseInt(value, 10)
              ),
            }
          : item
      )
    );
  };

  const CalculationEnterToTab = (e: any, index: number, id: number) => {
    if (e.keyCode === 13) {
      const tab: any = document.querySelectorAll(".calculationNumber");
      if (e.target.value) {
        let data = e.target.value ?? 0;
        setNewItems(
          newItems!.map((item) =>
            item.id === id
              ? {
                  ...item,
                  amount: Number(
                    item.amount + parseInt(data, 10) <= 0
                      ? 0
                      : item.amount + parseInt(data, 10)
                  ),
                }
              : item
          )
        );
      }
      e.target.value = null;
      if (index > tab.length - 1) return;
      tab[index + 1].focus();
    }
  };

  const onLaundryClassification = (id: number) => {
    const classificationItems = newItems!.map((items: ClassificationItem) => ({
      id: items.id,
      amount: items.amount,
      relaundering: items.relaundering ?? null,
    }));
    if (!deliveryDate) return alert("날짜를 입력해주세요");
    const payload: LaundryClassification = {
      items: classificationItems,
      id,
      outBagCount: outBagCount,
      complete: DateFormatUtils.dateToFormat(deliveryDate) ?? "",
    };
    if (detailType === false) laundryClassification(payload);
    if (detailType === true) modifyLaundryClassification(payload);
  };

  const EnterToTab = (e: any, index: number) => {
    if (e.keyCode === 13) {
      const tab: any = document.querySelectorAll(".amountNumber");
      if (index > tab.length - 1) return;
      tab[index + 1].focus();
    }
  };

  const EnterToTabRelaundrying = (e: any, index: number) => {
    if (e.keyCode === 13) {
      const tab: any = document.querySelectorAll(".relaunderingNumber");
      if (index > tab.length - 1) return;
      tab[index + 1].focus();
    }
  };

  const onDeliveryDateChange = (e: any) => {
    setDeliveryDate(e.target.value);
  };

  return (
    <FactoryLaundryStatusUploadBlock type={detailType}>
      <div className="popupLayout">
        <div className="top">
          <h2>세탁물 등록</h2>
          <div className="topData">
            <div>
              {factoryLaundryDetail?.laundryResponse.franchise.companyName}
            </div>
            <span>|</span>
            <div>
              {DateFormatUtils.dateToFormat(
                factoryLaundryDetail?.laundryResponse.applicationDate
              )}
            </div>
            <span>|</span>
            <div className="calender">
              {/* {DateFormatUtils.dateToFormat(
                factoryLaundryDetail?.laundryResponse.completedDate
              )} */}
              <input
                type="date"
                value={moment(deliveryDate).format("YYYY-MM-DD") ?? ""}
                onChange={(e) => onDeliveryDateChange(e)}
              />
            </div>
            <span>|</span>
            <div>
              {NumberUtils.numberToComma(
                factoryLaundryDetail?.laundryResponse.bagCount ?? 0
              )}{" "}
              포대/카트
            </div>
          </div>
        </div>
        <article className="popupInput">
          <div>
            <div className="inputField">
              <div className="header">
                <span>대분류</span>
                <span>소분류</span>
                <span>품명</span>
                <span>사이즈</span>
                <span>비고</span>

                <span>세탁신청(개)</span>

                <span>세탁완료(개)</span>
                {detailType && (
                  <>
                    <span>수량입력</span>
                    <span>재세탁</span>
                  </>
                )}
                <span>중량(kg)</span>
                <span>단가(원)</span>
              </div>
              <div className="list">
                {newItems?.map((item, index) => (
                  <div className="field" key={index}>
                    <span>{item.largeCategory}</span>
                    <span>{item.subCategory}</span>
                    <span>{item.name}</span>
                    <span>{item.size ?? "-"}</span>
                    <span>{item.content ?? "-"}</span>
                    <span>{item.beforeAmount ?? "-"}</span>
                    <input
                      type="number"
                      name="amount"
                      value={item.amount !== 0 ? item.amount : 0}
                      className="amountNumber"
                      onKeyUp={(e) => EnterToTab(e, index)}
                      onChange={(e) => amountHandler(e, item.id)}
                      // disabled={detailType}
                      onFocus={(e) => {
                        if (item.amount <= 0) {
                          e.target.value = "";
                        }
                      }}
                      onBlur={(e) => {
                        if (item.amount <= 0) {
                          e.target.value = "0";
                        }
                      }}
                    />
                    {detailType && (
                      <>
                        <input
                          type="number"
                          className="calculationNumber"
                          onKeyUp={(e) =>
                            CalculationEnterToTab(e, index, item.id)
                          }
                          onFocus={(e) => {
                            if (Number(e.target.value) <= 0) {
                              e.target.value = "";
                            }
                          }}
                          onBlur={(e) => {
                            if (Number(e.target.value) <= 0) {
                              e.target.value = "";
                            }
                          }}
                        />
                        <input
                          type="number"
                          name="relaundering"
                          className="relaunderingNumber"
                          value={item.relaundering ? item.relaundering : 0}
                          onKeyUp={(e) => EnterToTabRelaundrying(e, index)}
                          onChange={(e) =>
                            relaunderingAmountHandler(e, item.id)
                          }
                          onFocus={(e) => {
                            if (Number(item.relaundering) <= 0) {
                              e.target.value = "";
                            }
                          }}
                          onBlur={(e) => {
                            if (Number(item.relaundering) <= 0) {
                              e.target.value = "0";
                            }
                          }}
                        />
                      </>
                    )}
                    <span>{item.weight ?? "-"}</span>
                    <span>{item.unitPrice ?? "-"}</span>
                  </div>
                ))}
                <div className="field">
                  <span>포대/카트</span>
                  <span>-</span>
                  <span>-</span>
                  <span>-</span>
                  <span>-</span>
                  <span>{factoryLaundryDetail?.laundryResponse.bagCount}</span>
                  <input
                    type="number"
                    name="bagCoung"
                    className="amountNumber"
                    value={outBagCount ? outBagCount : ""}
                    onChange={(e) =>
                      setOutBagCount(parseInt(e.target.value, 10))
                    }
                    // disabled={detailType}
                  />
                  {detailType && (
                    <>
                      <input type="number" className="calculationNumber" />
                      <input disabled />
                    </>
                  )}
                  <span>-</span>
                  <span>-</span>
                </div>
              </div>
            </div>
          </div>
        </article>
        <div className="buttonWrapper">
          <Button
            theme={ThemeColor.primary}
            buttonSize="m"
            onClick={() => onEnrollToggle(false)}
          >
            취소
          </Button>
          <Button
            theme={ThemeColor.primary}
            buttonSize="m"
            onClick={() =>
              onLaundryClassification(factoryLaundryDetail!.laundryResponse.id)
            }
          >
            저장
          </Button>
        </div>
      </div>
    </FactoryLaundryStatusUploadBlock>
  );
};

export default FactoryLaundryStatusUpload;

const FactoryLaundryStatusUploadBlock = styled.div<FactoryLaundryStatusUploadBlockProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 3;
  .top {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    h2 {
      display: inline;
      margin: 0;
    }
    .topData {
      width: 800px;
      height: 40px;
      margin-top: 10px;
      border-radius: 17px;
      display: flex;
      justify-content: space-around;
      padding: 0 1.5rem;
      box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.19);
      background-color: ${Palette.white};
      div {
        /* width: 119px; */
        height: 100%;
        display: inline-block;
        text-align: center;
        line-height: 40px;
      }
      .calender {
        & > input {
          width: 170px;
          height: 90%;
          border: 1px solid #dee2e6;
        }
      }
      span {
        line-height: 40px;
      }
    }
  }
  .popupLayout {
    width: 80rem;
    height: 43rem;
    background-color: #f7f8f9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2.6rem;
    display: flex;
    flex-direction: column;
    padding: 40px;
    font-size: 12px;

    .popupInput {
      flex: 1;
      /* background-color: royalblue; */
      & > div {
        //width: 816px;
        width: 1186px;
        height: 402px;
        margin: 0 auto;
        margin-top: 40px;
        .inputField {
          width: 100%;
          height: 100%;
          border: 1px solid #eeeeee;
          box-sizing: border-box;
          border-radius: 20px;
          background-color: white;
          text-align: center;
          .header {
            display: grid;
            grid-template-columns: ${(props) =>
              props.type ? "repeat(11, 1fr)" : "repeat(9, 1fr)"};
            grid-gap: ${(props) => (props.type ? "1rem 1rem" : "1rem 2rem")};
            padding: 0 1rem;
            margin-right: 1rem;
            border-bottom: 1px solid #eeeeee;
            height: 10%;
            span {
              padding: 10px;
            }
          }
          .list {
            overflow-y: scroll;
            height: 90%;
            padding: 0 1rem;
            .field {
              display: grid;
              grid-template-columns: ${(props) =>
                props.type ? "repeat(11, 1fr)" : "repeat(9, 1fr)"};
              grid-gap: ${(props) => (props.type ? "1rem 1rem" : "1rem 2rem")};
              align-items: center;
              span {
                display: inline-block;
                text-align: center;
                height: 100%;
                /* width: 100%; */
                line-height: 2rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              input {
                width: 90px;
                height: 2rem;
                border: 0;
                box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.1);
              }
            }
            .field:nth-child(2n) {
              background-color: #eee;
              input {
                background-color: transparent;
              }
            }
            .field:nth-child(3n + 1) {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  .buttonWrapper {
    margin: 0 auto;
    padding: 1rem;
  }
`;
