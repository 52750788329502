import BoardStatus from "components/common/BoardStatus";
import BoardStatusDetail from "components/common/BoardStatusDetail";
import BoardWrite from "components/common/BoardWrite";
import { IRootState } from "modules";
import {
  BoardDetailData,
  BoardGetList,
  BoardList,
  BoardPostData,
} from "modules/user/types";
import {
  deleteBoardCommentAction,
  deleteBoardDataAction,
  getBoardAlarmAction,
  getBoardDetailDataAction,
  getBoardListAction,
  getBoardToUserListAction,
  getDeliveryAlarmAction,
  getLaundryCompleteAlarmAction,
  getLaundryRequestAlarmAction,
  getNoticeAlarmAction,
  getStatementAlarmAction,
  patchBoardCommentAction,
  patchBoardDataAction,
  postBoardCommentAction,
  postBoardDataAction,
} from "modules/user/user";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import qs from "query-string";
import { Route, match, useHistory } from "react-router-dom";
import RoleUtils from "utils/RoleUtils";

interface BoardContainerProps {
  match: any;
}

const BoardContainer: React.FC<BoardContainerProps> = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const profile = useSelector((state: IRootState) => state.user.profile);
  const role = useSelector((state: IRootState) => state.user.profile.type);

  const [boardType, setBoardType] = useState<string>("list");
  const [boardDetailId, setBoardDetailId] = useState<number | null>(null); // 선택한 디테일 페이지 아이디 저장
  const [boardDetailStatus, setBoardDetailStatus] =
    useState<BoardDetailData | null>(null);
  const [auth, setAuth] = useState<string>("");
  const [searchData, setSearchData] = useState<BoardGetList | null>({
    query: "",
    dropBox: "",
    startDate: "",
    endDate: "",
    size: 10,
  });
  const [page, setPage] = useState<number>(1);
  const [boardListContent, setBoardListContent] = useState<BoardList | null>();

  const boardList = useSelector((state: IRootState) => state.user.boardList);
  const boardToUserList = useSelector(
    (state: IRootState) => state.user.boardToUserList
  );
  const boardDetailData = useSelector(
    (state: IRootState) => state.user.boardDetail
  );

  // useEffect(() => {
  //   setInterval(() => {
  //     dispatch(getBoardListAction(page!, searchData ?? null));
  //   }, 5 * 60 * 1000);
  // }, []);

  useEffect(() => {
    if (match.params.id && Number(match.params.id)) {
      setBoardDetailId(match.params.id);
      setBoardType("detail");
      dispatch(getBoardDetailDataAction(match.params.id));
    } else {
      setBoardType("list");
    }
  }, [match.params.id]);

  useEffect(() => {
    dispatch(getNoticeAlarmAction());
    dispatch(getBoardAlarmAction());

    if (RoleUtils.isFactory(role)) {
      dispatch(getDeliveryAlarmAction());
      dispatch(getLaundryRequestAlarmAction());
    }
    if (RoleUtils.isStore(role)) {
      dispatch(getStatementAlarmAction());
      dispatch(getLaundryCompleteAlarmAction());
    }
  }, [dispatch, role]);

  useEffect(() => {
    setBoardListContent(boardList);
  }, [boardList]);

  useEffect(() => {
    setBoardDetailStatus(boardDetailData ?? null);
  }, [dispatch, boardDetailData]);

  useEffect(() => {
    let authority = "";
    if (RoleUtils.isMasterAdmin(profile.type)) authority = "admin";
    if (RoleUtils.isMaster(profile.type)) authority = "master";
    if (RoleUtils.isStore(profile.type)) authority = "store";
    if (RoleUtils.isFactory(profile.type)) authority = "factory";
    setAuth(authority);
  }, [profile.type]);

  const query = window.location.pathname;

  useEffect(() => {
    if (query === `/${auth}/board/${match.params.id}`) setBoardType("detail");
    if (query === `/${auth}/board/modify`) setBoardType("modify");
    if (query === `/${auth}/board/add`) setBoardType("write");
    if (query === `/${auth}/board`) setBoardType("list");
  }, [query, auth]);

  useEffect(() => {
    dispatch(getBoardListAction(1, null));
    dispatch(getBoardToUserListAction());
  }, []);

  const selectBoardType = (type: string) => {
    setBoardType(type);
  };

  const boardListData = (page: number, data: BoardGetList | null) => {
    setPage(page);
    setSearchData(data);
    dispatch(getBoardListAction(page, data));
  };

  useEffect(() => {
    dispatch(getBoardListAction(page!, searchData));
  }, [boardType, dispatch, page, searchData]);

  const detailSelect = (id: number) => {
    setBoardDetailStatus(null);
    setBoardDetailId(id);
    dispatch(getBoardDetailDataAction(id));
    selectBoardType("detail");
  };

  const deleteBoard = (id: number) => {
    dispatch(deleteBoardDataAction(id));
  };

  const boardDataSubmit = (boardCommand: BoardPostData) => {
    dispatch(postBoardDataAction(boardCommand));
  };

  const boardDataModify = (boardCommand: BoardPostData) => {
    dispatch(
      patchBoardDataAction(boardCommand, boardDetailId!, page!, searchData)
    );
  };

  const boardCommentSubmit = (data: any) => {
    dispatch(postBoardCommentAction(data, boardDetailId!));
  };

  const boardCommentModify = (data: any, id: number) => {
    dispatch(patchBoardCommentAction(data, id!, boardDetailId!));
  };

  const deleteBoardComment = (id: number) => {
    dispatch(deleteBoardCommentAction(id, boardDetailId!));
  };

  return (
    <>
      {boardType === "list" && (
        <BoardStatus
          role={profile.type}
          boardList={boardListContent!}
          boardListData={boardListData}
          selectBoardType={selectBoardType}
          detailSelect={detailSelect}
          auth={auth}
        />
      )}
      {boardType === "detail" && (
        <BoardStatusDetail
          role={profile.type}
          user={profile}
          detailId={boardDetailId}
          boardDetail={boardDetailStatus}
          boardCommentSubmit={boardCommentSubmit}
          boardCommentModify={boardCommentModify}
          selectBoardType={selectBoardType}
          deleteContent={deleteBoard}
          deleteBoardComment={deleteBoardComment}
          auth={auth}
        />
      )}
      {boardType === "write" && (
        <BoardWrite
          boardToUserList={boardToUserList!}
          selectBoardType={selectBoardType}
          boardDataSubmit={boardDataSubmit}
          type="write"
          auth={auth}
          role={role}
        />
      )}
      {boardType === "modify" && (
        <BoardWrite
          detailId={boardDetailId}
          boardToUserList={boardToUserList!}
          selectBoardType={selectBoardType}
          type="modify"
          content={boardDetailStatus}
          boardDataSubmit={boardDataModify}
          auth={auth}
          role={role}
        />
      )}
    </>
  );
};

export default BoardContainer;
