import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { Palette, ThemeColor } from "styles/palette";
import Button from "components/common/Button";
import { NavLink } from "react-router-dom";
import Paging from "components/common/Paging";
import PopupLargeLayout from "components/common/PopupLargeLayout";
import InventoryStaffPropertySearch from "components/common/InventoryStaffPropertySearch";
import {
  LabelStaffDepartmentList,
  LabelStaffDepartmentRequest,
  StoreStaffDepartmentListRequest,
  StoreStaffDepartmentName,
  StoreStaffDepartmentUserName,
} from "modules/store/types";
import NumberUtils from "utils/NumberUtils";
import moment from "moment";

interface StoreStaffInventroyProps {
  storeStaffDepartmentName: StoreStaffDepartmentName[] | null;
  storeStaffDepartmentUserName: StoreStaffDepartmentUserName[] | null;
  getStoreStaffDepartmentUserName: (id: number) => void;
  labelStaffDepartmentList: LabelStaffDepartmentList | null;
  selectShowEntries: (limit: number) => void;
  onPageMove: (page: number) => void;
  staffDepartmentListSearch: (data: StoreStaffDepartmentListRequest) => void;
  patchLabelStaffDepartment: (data: LabelStaffDepartmentRequest) => void;
}

type staffInventoryDetail = {
  id: number | null;
  item: string;
  num: string;
  tag: string;
  date: string | Date;
  departmentName: string;
  userName: string;
  modal: boolean;
};

const StoreStaffInventory: React.FC<StoreStaffInventroyProps> = (props) => {
  const [selectSize, setSelectSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  const [modalItem, setModalItem] = useState<staffInventoryDetail>({
    id: null,
    item: "",
    num: "",
    tag: "",
    date: "",
    departmentName: "",
    userName: "",
    modal: false,
  });

  const [listNumber, setListNumber] = useState<number[]>([]);
  useEffect(() => {
    setListNumber(
      NumberUtils.numberToList(
        props.labelStaffDepartmentList?.currentPage ?? 0,
        props.labelStaffDepartmentList?.totalCount ?? 0,
        selectSize
      )
    );
  }, [props.labelStaffDepartmentList, selectSize]);

  const selectShowEntries = (size: number) => {
    setSelectSize(size);
    props.selectShowEntries(size);
  };

  const onDetail = (data: staffInventoryDetail) => {
    if (data.departmentName)
      props.getStoreStaffDepartmentUserName(Number(data.departmentName));
    setModalItem(data);
  };

  const onSubmit = () => {
    if (!modalItem.departmentName && !modalItem.userName && !modalItem.id)
      return;
    let item = {
      departmentId: Number(modalItem.departmentName),
      id: modalItem.id!,
      userId: Number(modalItem.userName),
    };
    props.patchLabelStaffDepartment(item);
    onClose();
  };

  const onClose = () => {
    setModalItem({
      id: null,
      item: "",
      num: "",
      tag: "",
      date: "",
      departmentName: "",
      userName: "",
      modal: false,
    });
  };

  const onSearch = (query: string, type: string) => {
    let item = {
      page: 1,
      limit: selectSize,
      query,
      type,
    };
    props.staffDepartmentListSearch(item);
  };

  const onPageMove = (page: number) => {
    setPage(page);
    props.onPageMove(page);
  };

  const onSelectChange = (data: string, type: string) => {
    if (type === "departmentName" && data !== "default") {
      props.getStoreStaffDepartmentUserName(Number(data));
      setModalItem((modalItem) => ({ ...modalItem, [type]: data }));
    }
    if (type === "userName" && data !== "default") {
      let item = props.storeStaffDepartmentUserName?.find(
        (a) => a.id === Number(data)
      );

      setModalItem((modalItem) => ({
        ...modalItem,
        [type]: data,
        num: item!.userNum,
      }));
    }
  };

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      fontWeight: 700,
    },
  });
  const classes = useStyles();

  return (
    <>
      <StoreStaffInventoryBlock>
        {modalItem.modal && (
          <PopupLargeLayout>
            <div className="popupLarge">
              <h3>관계자 자산</h3>
              <div className="inputWrapper">
                <div>
                  <div>
                    <span>품명</span>
                    <input value={modalItem.item} disabled />
                  </div>
                  <div>
                    <span>번호</span>
                    <input value={modalItem.num ?? ""} disabled />
                  </div>
                  <div>
                    <span>부서명</span>
                    <select
                      name="departmentName"
                      value={modalItem.departmentName}
                      onChange={(e) =>
                        onSelectChange(e.target.value, e.target.name)
                      }
                    >
                      <option value="default">선택하세요</option>
                      {props.storeStaffDepartmentName &&
                        props.storeStaffDepartmentName.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.departmentName}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div>
                    <span>이름</span>
                    <select
                      name="userName"
                      value={modalItem.userName}
                      onChange={(e) =>
                        onSelectChange(e.target.value, e.target.name)
                      }
                    >
                      <option value="default">선택하세요</option>
                      {props.storeStaffDepartmentUserName &&
                        props.storeStaffDepartmentUserName.map(
                          (item, index) => (
                            <option key={index} value={item.id}>
                              {item.userName}
                            </option>
                          )
                        )}
                    </select>
                  </div>
                </div>
                <div>
                  <div>
                    <span>Tag</span>
                    <input value={modalItem.tag} disabled />
                  </div>
                  <div>
                    <span>등록일시</span>
                    <input value={String(modalItem.date)} disabled />
                  </div>
                </div>
              </div>
              <div className="buttonWrapper">
                <Button
                  theme={ThemeColor.primary}
                  buttonSize="m"
                  onClick={onClose}
                >
                  취소
                </Button>
                <Button
                  theme={ThemeColor.primary}
                  buttonSize="m"
                  onClick={onSubmit}
                >
                  저장
                </Button>
              </div>
            </div>
          </PopupLargeLayout>
        )}
        <div className="headers">
          <h3>자산 관리</h3>
          <div>
            <span className="selectClick">
              <NavLink to="/store/inventory">기본 자산</NavLink>
            </span>
            <span>|</span>
            <span className="selectClick on">
              <NavLink to="/store/inventory/staff/property">
                관계자 자산
              </NavLink>
            </span>
            <span>|</span>
            <span className="selectClick">
              <NavLink to="/store/inventory/staff/info">직원 정보 관리</NavLink>
            </span>
          </div>
        </div>
        <div className="tableSelect">
          <div>
            <span>Show </span>
            <select
              onChange={(e) => selectShowEntries(parseInt(e.target.value, 10))}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
            <span> entries</span>
          </div>
          <div className="topWrapper">
            <InventoryStaffPropertySearch
              role={""}
              onSearch={onSearch}
              size={selectSize}
            />
          </div>
        </div>
        <div className="tableWrapper">
          <TableContainer component={Paper} className="tables">
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ fontWeight: 900, width: "70px" }}
                  >
                    순번
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 900 }}>
                    품명
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: 900, width: "20%" }}
                  >
                    번호
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: 900, width: "20%" }}
                  >
                    부서명
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: 900, width: "20%" }}
                  >
                    이름
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="tableBody">
                {props.labelStaffDepartmentList &&
                  props.labelStaffDepartmentList.list.map((item, index) => (
                    <TableRow
                      className="tableHover"
                      key={index}
                      onClick={() =>
                        onDetail({
                          id: item.id,
                          item: item.assetName,
                          num: item.userNum,
                          tag: item.code,
                          date: moment(item.createdDate).format(
                            "YYYY-MM-DD HH:mm:ss"
                          ),
                          departmentName: String(item.departmentId),
                          userName: String(item.userId),
                          modal: true,
                        })
                      }
                    >
                      <TableCell component="th" scope="row" align="center">
                        {listNumber[index]}
                      </TableCell>
                      <TableCell align="center">{item.assetName}</TableCell>
                      <TableCell align="center">{item.userNum}</TableCell>
                      <TableCell align="center">
                        {item.departmentName}
                      </TableCell>
                      <TableCell align="center">{item.userName}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Paging
            listAction={onPageMove}
            listCount={props.labelStaffDepartmentList?.totalPage ?? 0}
            currentPage={props.labelStaffDepartmentList?.currentPage}
            totalCount={props.labelStaffDepartmentList?.totalCount}
            size={selectSize ?? 10}
          ></Paging>
        </div>
      </StoreStaffInventoryBlock>
    </>
  );
};

export default StoreStaffInventory;

const StoreStaffInventoryBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);

  .popupLarge {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & > h3 {
      margin: 0;
    }
    & > .inputWrapper {
      width: 90%;
      min-height: 172px;
      flex: 1;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      margin-top: 50px;
      margin-bottom: 50px;
      & > div {
        width: 343px;
        min-height: 172px;
        & > div {
          width: 100%;
          height: auto;
          margin-bottom: 7px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          & > span {
            width: 100px;
            display: inline-block;
          }
          & > input,
          select {
            flex: 1;
            border: 0;
            height: 30px;
            padding: 0;
            padding-left: 10px;
          }
          & > input {
            background-color: #eee;
            color: #aaa;
          }
        }
      }
    }
    & > .buttonWrapper {
      height: auto;
      background-color: seashell;

      display: flex;
      justify-content: center;
    }
  }

  .tables {
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
    & > div {
      width: auto;
      height: 20px;
      display: flex;
      justify-content: space-between;
      margin-left: 20px;
      padding-top: 5px;

      & > span {
        color: #dee2e6;
      }
      & > .selectClick {
        cursor: pointer;
        font-size: 14px;
        & > a {
          color: #dee2e6;
        }
        & > a:hover {
          color: ${Palette.primary};
          font-weight: bold;
        }
      }
      & > .on {
        & > a {
          color: ${Palette.primary};
          font-weight: bold;
        }
      }
      & > .selectClick:hover {
        color: ${Palette.primary};
        font-weight: bold;
      }
      & > span:nth-child(2n) {
        font-size: 10px;
        margin: 0 10px;
        color: #333;
      }
    }
  }
  .tableSelect {
    height: 70px;
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: space-between;
    & > div {
      color: #999;
      height: 40px;
      /* background-color: red; */
      overflow: hidden;
      & > span {
        font-size: 14px;
        line-height: 37px;
      }
      & > select {
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        transition: ease-in-out;
        transition-duration: 0.1s;
      }
      & > select:hover {
        border-bottom: 2px solid ${Palette.primary};
      }
    }
  }
  .topWrapper {
    display: flex;
    justify-content: flex-end;
    /* background-color: red; */
    & > form {
      padding-top: 2.5px;
    }
    & > span {
      margin: 0 10px;
    }
    & > div {
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > .excelBtn {
        /* margin-left: 3px; */
        height: 35px;
        & > label {
          display: inline-block;
          width: 75px;
          padding: 0.56rem 0;
          text-align: center;
          font-size: 14px;
          color: #fff;
          border-radius: 5px;
          height: 35px;
          background-color: ${Palette.tertiary};
          cursor: pointer;
          & > input {
            display: none;
          }
        }
      }
      & > div {
        margin: 0;
        margin-right: 3px;
      }
      & > div:last-child {
        margin-right: 0;
      }
    }
  }
  .tableWrapper {
    min-width: 51rem;
    padding-bottom: 1.5rem;
  }
  .tableHover:hover {
    background-color: #eee;
    cursor: pointer;
  }
  .tableBody {
    tr {
      height: 53px !important;
      td {
        padding: 0;
      }
    }
  }
`;
