import {
  BoardDetailData,
  BoardPostData,
  BoardToUserList,
} from "modules/user/types";
import React from "react";
import TextEditor from "./board/TextEditor";

interface BoardWriteProps {
  detailId?: number | null;
  role: string;
  type: string;
  auth: string;
  boardToUserList: BoardToUserList;
  selectBoardType: (type: string) => void;
  content?: BoardDetailData | null;
  boardDataSubmit: (boardCommand: BoardPostData) => void;
}

const BoardWrite: React.FC<BoardWriteProps> = ({
  selectBoardType,
  boardDataSubmit,
  boardToUserList,
  type,
  auth,
  content,
  role,
  detailId,
}) => {
  return (
    <TextEditor
      detailId={detailId}
      role={role}
      title={"게시판"}
      boardToUserList={boardToUserList}
      selectType={selectBoardType}
      dataSubmit={boardDataSubmit}
      type={type}
      content={content}
      editorType={"board"}
      auth={auth}
    />
  );
};

export default BoardWrite;
