import Button from "components/common/Button";
import { StatusDetailBlock } from "components/common/LaundaryStatusDetail";
import { CenterAligned } from "components/common/layouts/CenterAligned";
import { FactoryLaundryDetail, Item } from "modules/factory/types";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { ThemeColor } from "styles/palette";
import DateFormatUtils from "utils/DateFormatUtils";
import NumberUtils from "utils/NumberUtils";
import ScrollUtils from "utils/ScrollUtils";

interface FactoryLaundaryStatusDetailProps {
  onClose: () => void;
  factoryLaundryDetail: FactoryLaundryDetail | null;
  onDeleteLaundryData: (id: number) => void;
}

const FactoryLaundaryStatusDetail: React.FC<
  FactoryLaundaryStatusDetailProps
> = ({ onClose, factoryLaundryDetail, onDeleteLaundryData }) => {
  const history = useHistory();
  //   if (!franchiseDetailLaundry) return <div>로딩중</div>;
  // useEffect(() => {
  //   ScrollUtils.onPopupScrollStop(window.scrollY);
  //   return () => {
  //     const scrollY = document.body.style.top;
  //     ScrollUtils.closePopupScrollRun(scrollY);
  //   };
  // }, []);

  // const testArray: Item[] | null = factoryLaundryDetail?.items ?? [];
  // const [laundryDetailItems, setLaundryDetailItems] = useState<Item[]>([]);

  // useEffect(() => {
  //   setLaundryDetailItems(factoryLaundryDetail?.items ?? []);
  // }, []);

  const onDelete = () => {
    // const data = laundryDetailItems.map((item, index) =>
    //   item.amount !== 0 ? true : false
    // );
    // if (data.includes(true))
    //   return alert("수량이 하나라도 0이 아니면 삭제할 수 없습니다.");
    if (factoryLaundryDetail?.laundryResponse.completedDate) return;
    if (window.confirm("삭제하시겠습니까?")) {
      onDeleteLaundryData(factoryLaundryDetail?.laundryResponse.id!);
    }
    // history.push(`/store/laundry`);
  };

  return (
    <LaundaryStatusDetailBlock>
      <div className="headers">
        <h3>세탁현황 상세정보</h3>
        <Button theme={ThemeColor.primary} buttonSize="m" onClick={onClose}>
          뒤로가기
        </Button>
      </div>
      <div className="contentWrapper">
        <div className="detailInfo">
          <div className="field">
            <span>세탁 신청일</span>
            <p>
              {DateFormatUtils.dateToFormat(
                factoryLaundryDetail?.laundryResponse.applicationDate
              )}
            </p>
          </div>
          <div className="field">
            <span>납품일</span>
            <p>
              {DateFormatUtils.dateToFormat(
                factoryLaundryDetail?.laundryResponse.completedDate
              )}
            </p>
          </div>
          <div className="field">
            <span>지점 담당</span>
            <p>
              {factoryLaundryDetail?.laundryResponse.franchise.manager.nickname}
            </p>
            {/* <span></span>
                  <p>
                    {
                      factoryLaundryDetail?.laundryResponse.franchise.manager
                        .phone
                    }
                  </p> */}
          </div>
          <div className="field">
            <span></span>
            <p>
              {factoryLaundryDetail?.laundryResponse.franchise.manager.phone}
            </p>
          </div>
          <div className="field">
            <span>입고 수량(포대/카트)</span>
            <p>
              {NumberUtils.numberToComma(
                factoryLaundryDetail?.laundryResponse.bagCount ?? 0
              )}
            </p>
          </div>

          <Button
            className={
              factoryLaundryDetail?.laundryResponse.completedDate
                ? "notDelete"
                : ""
            }
            theme={ThemeColor.primary}
            buttonSize="m"
            onClick={onDelete}
            style={{ float: "right" }}
          >
            삭제
          </Button>
        </div>
        <div className="stockStatus">
          {factoryLaundryDetail && (
            <div>
              <h3>세탁물 종류</h3>
              <div className="stockInField">
                <div>
                  <div className="stockHeader">
                    <span>대분류</span>
                    <span>소분류</span>
                    <span>품명</span>
                    <span>사이즈</span>
                    <span>비고</span>
                    <span>세탁신청(개)</span>
                    <span>세탁완료(개)</span>
                    <span>재세탁(반품)</span>
                    <span>기타(미납,수선)</span>
                  </div>
                  <div className="stockList">
                    {factoryLaundryDetail?.items.map((laundryItem, idx) => (
                      <div className="field" key={idx}>
                        <span>{laundryItem.largeCategory}</span>
                        <span>{laundryItem.subCategory}</span>
                        <span>{laundryItem.name}</span>
                        <span>{laundryItem.size}</span>
                        <span>{laundryItem.content}</span>
                        <span>
                          {laundryItem.beforeAmount
                            ? NumberUtils.numberToComma(
                                laundryItem.beforeAmount ?? 0
                              )
                            : "-"}
                        </span>
                        <span>
                          {laundryItem.amount
                            ? NumberUtils.numberToComma(laundryItem.amount ?? 0)
                            : "-"}
                        </span>
                        <span>
                          {laundryItem.relaundering
                            ? NumberUtils.numberToComma(
                                laundryItem.relaundering
                              )
                            : "-"}
                        </span>
                        <span>-</span>
                      </div>
                    ))}
                    <div className="field">
                      <span>포대/카트</span>
                      <span>-</span>
                      <span>-</span>
                      <span>-</span>
                      <span>-</span>
                      <span>
                        {NumberUtils.numberToComma(
                          factoryLaundryDetail?.laundryResponse.bagCount ?? 0
                        )}
                      </span>
                      <span>
                        {NumberUtils.numberToComma(
                          factoryLaundryDetail.laundryResponse.outBagCount ?? 0
                        )}
                      </span>
                      <span>-</span>
                      <span>-</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </LaundaryStatusDetailBlock>
  );
};

export default FactoryLaundaryStatusDetail;

export const LaundaryStatusDetailBlock = styled(StatusDetailBlock)`
  .notDelete {
    cursor: default;
    background-color: #aaa;
    border: 0;
  }
`;
