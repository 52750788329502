import Button from "components/common/Button";
import Paging from "components/common/Paging";
import PaymentSearch from "components/common/PaymentSearch";
import {
  MasterBillingDetail,
  MasterBillingList,
  MasterGetList,
} from "modules/master/types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Palette, ThemeColor } from "styles/palette";
import DateFormatUtils from "utils/DateFormatUtils";
import NumberUtils from "utils/NumberUtils";
import PriceUtils from "utils/PriceUtils";
import MasterPaymentDetail from "./MasterPaymentDetail";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

interface MasterPaymentStatusProps {
  role: string;
  masterBillingList: MasterBillingList | null;
  billingList: (page: number, data: MasterGetList | null) => void;
  masterBillingDetail: MasterBillingDetail | null;
  billingDetail: (id: number) => void;
}

const MasterPaymentStatus: React.FC<MasterPaymentStatusProps> = ({
  role,
  masterBillingList,
  billingList,
  masterBillingDetail,
  billingDetail,
}) => {
  const [searchData, setSearchData] = useState<MasterGetList>({
    query: "",
    dropBox: "",
    startDate: "",
    endDate: "",
    size: 10,
  });
  const [paymentDetail, setPaymentDetail] = useState(false);
  const onDetail = (id: number) => {
    setPaymentDetail(true);
    billingDetail(id);
  };

  const onClose = () => {
    setPaymentDetail(false);
  };

  const onSearch = (page: number, data: MasterGetList) => {
    setSearchData(data);
    billingList(page, data);
  };

  const pageMove = (page: number) => {
    billingList(page, searchData ?? null);
  };

  const selectShowEntries = (size: number) => {
    searchData.size = size;
    billingList(1, searchData ?? null);
  };

  const [listNumber, setListNumber] = useState<number[]>([]);
  useEffect(() => {
    setListNumber(
      NumberUtils.numberToList(
        masterBillingList?.currentPage ?? 0,
        masterBillingList?.totalCount ?? 0,
        searchData.size ?? 10
      )
    );
  }, [masterBillingList, searchData]);
  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      fontWeight: 700,
    },
  });

  const classes = useStyles();
  return (
    <MasterPaymentStatusBlock>
      {/* {paymentDetail && (
        <MasterPaymentDetail
          masterBillingDetail={masterBillingDetail}
          onClose={onClose}
        />
      )} */}
      <div className="headers">
        <h3>정산 현황</h3>
      </div>
      <div className="tableSelect">
        <div>
          <span>Show </span>
          <select
            onChange={(e) => selectShowEntries(parseInt(e.target.value, 10))}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
          <span> entries</span>
        </div>
        <div className="topWrapper">
          <span>Search: </span>
          <PaymentSearch role={role} onSearch={onSearch} />
        </div>
      </div>
      <div className="tableWrapper">
        <TableContainer component={Paper} className="tables">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "70px" }}
                >
                  순번
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900 }}>
                  지점명
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "15%" }}
                >
                  세탁공장명
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "15%" }}
                >
                  세탁신청일
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "15%" }}
                >
                  납품일
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "15%" }}
                >
                  세탁물 총 수량(개)
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "15%" }}
                >
                  결제금액(원)
                </TableCell>
                {/* <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "15%" }}
                >
                  결제정보
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {masterBillingList?.list.map((payment, index) => (
                <TableRow
                  key={index}
                  className="tableHover"
                  onClick={() => onDetail(payment.id)}
                >
                  <TableCell component="th" scope="row" align="center">
                    {listNumber[index]}
                  </TableCell>
                  <TableCell align="center">
                    {payment.franchiseCompanyName}
                  </TableCell>
                  <TableCell align="center">
                    {payment.factoryCompanyName}
                  </TableCell>
                  <TableCell align="center">
                    {DateFormatUtils.dateToFormat(payment.applicationDate)}
                  </TableCell>
                  <TableCell align="center">
                    {DateFormatUtils.dateToFormat(payment.completedDate)}
                  </TableCell>
                  <TableCell align="center">
                    {NumberUtils.numberToComma(payment.totalAmount ?? 0)}
                  </TableCell>
                  <TableCell align="center">
                    {NumberUtils.numberToComma(payment.price ?? 0)}
                  </TableCell>
                  {/* <TableCell align="center">
                    {PriceUtils.priceStatusToStringColor(payment.billingStatus)}
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Paging
          listAction={pageMove}
          listCount={masterBillingList?.totalPage ?? 0}
          currentPage={masterBillingList?.currentPage}
          totalCount={masterBillingList?.totalCount}
          size={searchData.size ?? 10}
        ></Paging>
      </div>
      {/* <PaymentStatusListContainer /> */}
    </MasterPaymentStatusBlock>
  );
};

export default MasterPaymentStatus;

const MasterPaymentStatusBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);

  .tables {
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  .tableSelect {
    height: 70px;
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: space-between;
    & > div {
      color: #999;
      height: 40px;
      /* background-color: red; */
      overflow: hidden;
      & > span {
        font-size: 14px;
        line-height: 37px;
      }
      & > select {
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        transition: ease-in-out;
        transition-duration: 0.1s;
      }
      & > select:hover {
        border-bottom: 2px solid ${Palette.primary};
      }
    }
  }
  .topWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    & > span {
      font-size: 14px;
      line-height: 37px;
      margin-right: 5px;
    }
    & > div {
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div {
        margin: 0;
        margin-left: 1rem;
      }
    }
  }
  .tableWrapper {
    min-width: 51rem;
    padding-bottom: 1.5rem;
  }
  .tableHover:hover {
    background-color: #eee;
    cursor: pointer;
  }
`;
