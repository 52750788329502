import React from "react";
import styled from "styled-components";
import Button from "components/common/Button";
import { Palette, ThemeColor } from "styles/palette";
import FactoryUtils from "utils/FactoryUtils";
import DateFormatUtils from "utils/DateFormatUtils";
import NumberUtils from "utils/NumberUtils";
import { MasterFactoryDetail } from "modules/master/types";

interface MasterFactoryStatusDetailProps {
  masterFactoryDetail: MasterFactoryDetail | null;
  onClose: () => void;
}

const MasterFactoryStatusDetail: React.FC<MasterFactoryStatusDetailProps> = ({
  masterFactoryDetail,
  onClose,
}) => {
  // if (!masterFactoryDetail) return <div></div>;

  return (
    <MasterFactoryStatusDetailBlock>
      <div className="headers">
        <h3>세탁공장 상세정보</h3>
        <Button theme={ThemeColor.primary} buttonSize="m" onClick={onClose}>
          뒤로가기
        </Button>
      </div>
      <section className="contentWrapper">
        <div className="detailInfo">
          <div className="field">
            <span>세탁공장명</span>
            <p>{masterFactoryDetail?.companyName ?? ""}</p>
          </div>
          <div className="field">
            <span>연락처</span>
            <p>{masterFactoryDetail?.companyPhone}</p>
          </div>
          <div className="field secondField">
            <span>주소</span>
            <p>{masterFactoryDetail?.companyAddress?.postCode}</p>
          </div>
          <div className="field">
            <span></span>
            <p>{masterFactoryDetail?.companyAddress?.street}</p>
          </div>
          <div className="field">
            <span></span>
            <p>{masterFactoryDetail?.companyAddress?.streetDetails}</p>
          </div>
          <div className="field">
            <span>가입일</span>
            <p>{DateFormatUtils.dateToFormat(masterFactoryDetail?.joinDate)}</p>
          </div>

          <div className="field">
            <span>누적 세탁건수</span>
            <p>
              {NumberUtils.numberToComma(
                masterFactoryDetail?.totalLaundryAmount ?? 0
              )}
            </p>
          </div>
          {/* <div className="field">
                <span>관리지역</span>
                <p></p>
              </div> */}
          <div className="field">
            <span>상태</span>
            <p>
              {masterFactoryDetail?.status
                ? FactoryUtils.operationStatusToString(
                    masterFactoryDetail?.status
                  )
                : "-"}
            </p>
          </div>
          <div className="field">
            <span>총 결제금액(원)</span>
            <p>
              {NumberUtils.numberToComma(
                masterFactoryDetail?.totalBillingPrice ?? 0
              )}{" "}
            </p>
          </div>
          <div className="field managerField">
            <span>담당자</span>
            <p>고길동</p>
          </div>
        </div>
        <div className="stockStatus">
          <div>
            <h3>매칭지점</h3>
            <div className="stockInField">
              <div>
                <div className="stockList">
                  {masterFactoryDetail?.franchiseList.map((item, idx) => (
                    <p key={idx}>{item}</p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MasterFactoryStatusDetailBlock>
  );
};

export default MasterFactoryStatusDetail;

const MasterFactoryStatusDetailBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  & > .contentWrapper {
    width: 100%;
    min-height: 760px;

    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    & > div {
      width: calc(100% / 2);
    }
    & > div:nth-child(2) {
      border-left: 1px solid black;
    }
    & > .detailInfo {
      width: auto;
      margin: 0 auto;

      & > .field {
        width: 100%;
        min-height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
        & > span {
          width: 140px;
        }
        & > p,
        input {
          border: 1px solid #ddd;
          font-size: 14px;
          border-radius: 5px;
          height: 100%;
          width: 260px;
          line-height: 35px;
          margin: 0;
          padding-left: 10px;
        }

        & > div {
          & > .changeId {
            width: 179px;
            border: 1px solid #ddd;
            margin-right: 5px;
          }
        }
        & > .PWBtn {
          width: 260px;
        }
      }
      & > .secoundField {
        & > p,
        input {
          width: 174px;
          margin-right: 10px;
        }
      }
      & > .buttonWrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 50px;
      }
    }
    & > .stockStatus {
      height: 760px;
      & > div {
        width: 500px;
        height: auto;
        margin: 0 auto;
        & > h3 {
          margin: 0;
        }
        & > .stockInField {
          margin-top: 20px;
          & > div {
            width: 100%;
            height: 690px;
            border-radius: 10px;
            background-color: #ecf0f5;
            padding: 20px 5px;
            overflow: hidden;
            & > .stockList {
              overflow-y: scroll;
              height: 95%;
              /* margin-top: 20px; */
              padding-left: 20px;
              & > div {
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #fff;
                padding: 2px 0;
                & > span {
                  width: calc(100% / 6);
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }
`;
