import Button from "components/common/Button";
import React from "react";
import styled from "styled-components";
import { Palette, ThemeColor } from "styles/palette";

interface ModalTableProps {
  onClose: () => void;
  setModal: (data: boolean) => void;
  uploadChangeIdData: () => void;
}

const ModalTable: React.FC<ModalTableProps> = ({
  onClose,
  children,
  uploadChangeIdData,
  setModal,
}) => {
  const onCloseModal = () => {
    uploadChangeIdData();
    setModal(false);
  };
  return (
    <ModalTableBlock>
      <div className="content">{children}</div>
      <div className="closeBtn">
        <Button
          theme={ThemeColor.primary}
          buttonSize="l"
          onClick={onCloseModal}
        >
          저장
        </Button>
        <Button theme={ThemeColor.primary} buttonSize="l" onClick={onClose}>
          닫기
        </Button>
      </div>
    </ModalTableBlock>
  );
};

const ModalTableBlock = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .content {
    width: 800px;
    height: 600px;
    background-color: ${Palette.primary};
    border-radius: 7px;
    border: 2px solid #fff;
    padding: 20px;
    overflow: hidden;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      & > div {
        width: auto;
        height: auto;
        align-self: flex-end;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;

        & > div {
          width: 50px;
          padding: 1px 0;
          cursor: pointer;
          text-align: center;
          margin-left: 5px;
          background-color: ${Palette.primary};
          border: 1px solid #fff;
          color: #fff;
          border-radius: 5px;
        }
        & > .arrowBox:hover {
          color: ${Palette.primary};
          background-color: #fff;
        }
        & > .noSelect {
          background-color: #aaa;
        }
        & > .noSelect:hover {
          background-color: #aaa;
          color: #fff;
        }
      }
    }

    .paper {
      width: 100%;
      height: 500px;
      background-color: white;
      overflow-y: scroll;
      padding: 20px;
      display: flex;
      justify-content: center;
      table {
        border-collapse: collapse;
        tbody {
          tr:nth-child(odd) {
            background-color: #bbb;
          }
          tr:nth-child(even) {
            background-color: white;
          }
          tr {
            text-align: center;
          }
          .selected {
            border: 4px solid red;
            background-color: #ffffcc !important;
          }
          td {
            width: 120px;
            padding: 2px 0;
          }
          .arrowBox {
            font-size: 12px;
            cursor: pointer;
          }
          .arrowBox:hover {
            background-color: ${Palette.primary};
            color: #fff;
          }
        }
      }
    }
  }
  .closeBtn {
    margin-top: 20px;
    & > div {
      border: 2px solid #fff;
    }
  }
`;

export default ModalTable;
