import Prepared from "components/common/Prepared";
import AppLayout from "components/layouts/AppLayout";
import BoardContainer from "containers/common/BoardContainer";
import NoticeContainer from "containers/common/NoticeContainer";
import FactoryDashBoardContainer from "containers/factory/FactoryDashBoardContainer";
import FactoryItemizedAccountContainer from "containers/factory/FactoryItemizedAccountContainer";
import FactoryLaundryStatusContainer from "containers/factory/FactoryLaundryStatusContainer";
import FactoryLaundrySignUpContainer from "containers/factory/FactoryLaundrySignUpContainer";
import FactoryPaymentStatusContainer from "containers/factory/FactoryPaymentStatusContainer";
import FactoryProfileContainer from "containers/factory/FactoryProfileContainer";
import React from "react";
import { Route, Switch } from "react-router";
import CheckLocationContainer from "containers/common/CheckLocationContainer";
import FactoryTableReaderContainer from "containers/factory/FactoryTableReaderContainer";
import FactoryLabelContainer from "containers/factory/FactoryLabelContainer";

interface FactoryPageProps {}

const FactoryPage: React.FC<FactoryPageProps> = () => {
  return (
    <AppLayout>
      <Switch>
        <Route exact path="/factory/label" component={FactoryLabelContainer} />
        <Route
          exact
          path="/factory/tablereader"
          component={FactoryTableReaderContainer}
        />
        <Route
          exact
          path="/factory/itemizedAccount/:id"
          component={FactoryItemizedAccountContainer}
        />
        <Route
          exact
          path="/factory/itemizedAccount/list"
          component={FactoryItemizedAccountContainer}
        />
        <Route
          exact
          path="/factory/itemizedAccount"
          component={FactoryItemizedAccountContainer}
        />

        <Route
          exact
          path="/factory/notice/detail"
          component={NoticeContainer}
        />
        <Route exact path="/factory/notice/:id" component={NoticeContainer} />
        <Route exact path="/factory/notice" component={NoticeContainer} />

        <Route exact path="/factory/board/:id" component={BoardContainer} />
        <Route
          exact
          path="/factory/board/modify/:id"
          component={BoardContainer}
        />
        <Route exact path="/factory/board/add" component={BoardContainer} />
        <Route exact path="/factory/board" component={BoardContainer} />

        <Route
          exact
          path="/factory/payment/:id"
          component={FactoryPaymentStatusContainer}
        />
        <Route
          exact
          path="/factory/payment"
          component={FactoryPaymentStatusContainer}
        />
        <Route path="/factory/profile" component={FactoryProfileContainer} />
        <Route path="/factory/self" component={FactoryLaundrySignUpContainer} />
        <Route
          exact
          path="/factory/laundry/:id"
          component={FactoryLaundryStatusContainer}
        />
        <Route
          exact
          path="/factory/laundry"
          component={FactoryLaundryStatusContainer}
        />
        <Route
          path="/factory/dashboard"
          component={FactoryDashBoardContainer}
        />
        <Route
          path="/factory/checkLocation"
          component={CheckLocationContainer}
        />
        <Route component={Prepared} />
      </Switch>
    </AppLayout>
  );
};

export default FactoryPage;
