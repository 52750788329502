import Button from "components/common/Button";
import Paging from "components/common/Paging";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Palette, ThemeColor } from "styles/palette";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { BrandSpecificationsData, MasterGetList } from "modules/master/types";
import NumberUtils from "utils/NumberUtils";
import SpecificationsSearch from "components/common/SpecificationsSearch";
import DateFormatUtils from "utils/DateFormatUtils";
import TypeUtils from "utils/TypeUtils";
import { useHistory } from "react-router";
import {
  FactoryGetList,
  FactoryMatchingFranchiseList,
  FactorySpecificationsList,
} from "modules/factory/types";
import { SpecificationsFranchiseList } from "modules/user/types";
import info from "assets/icons/info.png";

import nonSelect from "assets/arrow/non_select.svg";
import downSelect from "assets/arrow/down_select.svg";
import upSelect from "assets/arrow/up_select.svg";

interface FactoryItemizedAccountListProps {
  role?: string;
  specificationsDetail: (
    id: number,
    title: string,
    name: string,
    date: Date,
    chartType: string
  ) => void;
  setSelectType?: (type: string) => void;
  specificationsListSearch: (id: number | null, page: number, data: any, sort?: string) => void;
  onSelectType: (type: string) => void;
  factorySpecificationsList: FactorySpecificationsList | null;
  factoryMatchingFranchiseList: FactoryMatchingFranchiseList | null;
  specificationsFranchiseList: SpecificationsFranchiseList | null;
  searchPage: number;
}

const FactoryItemizedAccountList: React.FC<FactoryItemizedAccountListProps> = ({
  role,
  setSelectType,
  specificationsDetail,
  specificationsListSearch,
  onSelectType,
  factorySpecificationsList,
  factoryMatchingFranchiseList,
  specificationsFranchiseList,
  searchPage,
}) => {
  const [searchData, setSearchData] = useState<MasterGetList>({
    query: "",
    dropBox: "",
    startDate: "",
    endDate: "",
    size: 10,
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchId, setSearchId] = useState<number | null>(null);
  const [sortCheck, setSortCheck] = useState<string>("");
  const [moveSortType, setMoveSortType] = useState<string>("");

  useEffect(() => {
    setCurrentPage(searchPage);
  }, [searchPage]);

  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (params.get("sort")) {
      setMoveSortType(String(params.get("sort")));
      if (params.get("sort") === "frasc") setSortCheck("ASC");
      if (params.get("sort") === "frdesc") setSortCheck("DESC");
    }
  }, []);

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      fontWeight: 700,
    },
  });
  const classes = useStyles();

  const [listNumber, setListNumber] = useState<number[]>([]);
  useEffect(() => {
    setListNumber(
      NumberUtils.numberToList(
        factorySpecificationsList?.currentPage ?? 0,
        factorySpecificationsList?.totalCount ?? 0,
        searchData.size ?? 10
      )
    );
  }, [factorySpecificationsList, searchData]);

  const onSearch = (id: number | null, page: number, data: MasterGetList) => {
    setSearchId(id);
    setSearchData(data);
    specificationsListSearch(id, page, data, moveSortType);
  };

  const pageMove = (page: number) => {
    setCurrentPage(page);
    specificationsListSearch(searchId ?? null, page, searchData ?? null, moveSortType);
  };

  const selectShowEntries = (size: number) => {
    searchData.size = size;
    specificationsListSearch(searchId!, 1, searchData ?? null, moveSortType);
  };

  const history = useHistory();

  const onDetail = (id: number, title: string, name: string, date: Date, chartType: string) => {
    onSelectType("detail");
    specificationsDetail(id, title, name, date, chartType);
    history.push(`/factory/itemizedAccount/${id}`);
  };

  const onSelect = () => {
    onSelectType("inquiry");
    history.push(`/factory/itemizedAccount`);
  };

  const onSortList = (sortType: string, sortCheck1: string) => {
    let typeCheck = "";

    if (sortCheck1 === "" || sortCheck1 === "DESC") {
      setSortCheck("ASC");
      typeCheck = "ASC";
    }
    if (sortCheck1 === "ASC") {
      setSortCheck("DESC");
      typeCheck = "DESC";
    }

    let sort = "";

    if (typeCheck === "") {
      sort = `${sortType}asc`;
    } else {
      sort = typeCheck === "DESC" ? `${sortType}desc` : `${sortType}asc`;
    }

    setMoveSortType(sort);
    specificationsListSearch(searchId ?? null, currentPage, searchData ?? null, sort);
    history.push(`/factory/itemizedAccount/list?sort=${sort}`);
  };

  return (
    <FactoryItemizedAccountListBlock>
      <div className="headers">
        <h3>거래명세표</h3>
        <div>
          <span className="selectClick" onClick={onSelect}>
            조회
          </span>
          <span>|</span>
          <span className="selectClick on">목록</span>
        </div>
        <div className="infoBox">
          <div className="info">
            <span>서비스가이드</span>
            <span>
              <img src={info} alt="" />
            </span>
          </div>
          <div className="infoGuide">
            확인 및 수정요청을 진행하지 않더라도 전송일을 기준으로
            <br />
            아래의 기한이 지나면 납품확인 완료로 자동 변경됩니다.
            <br />
            거래명세표: 전송 2일 후 / 세탁집계표: 전송 10일 후
          </div>
        </div>
      </div>
      <div className="tableSelect">
        <div>
          <span>Show </span>
          <select onChange={(e) => selectShowEntries(parseInt(e.target.value, 10))}>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
          <span> entries</span>
        </div>
        <div className="topWrapper">
          <span>Search: </span>
          <div>
            <SpecificationsSearch
              role={role!}
              onSearch={onSearch}
              specificationsFranchiseList={specificationsFranchiseList}
              factoryMatchingFranchiseList={factoryMatchingFranchiseList}
            />
          </div>
        </div>
      </div>
      <div className="tableWrapper">
        <TableContainer component={Paper} className="tables">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: 900, width: "70px" }}>
                  순번
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900 }}>
                  제목
                </TableCell>

                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "20%" }}
                  className="sortList"
                  onClick={() => onSortList("fr", sortCheck)}
                >
                  지점명
                  <img
                    className="sortSelect"
                    src={sortCheck === "" ? nonSelect : sortCheck === "ASC" ? downSelect : upSelect}
                    alt=""
                  />
                  {/* {sortCheck === "ASC" && <span className="sortDown">▼</span>}
                  {sortCheck === "DESC" && <span className="sortUp">▲</span>} */}
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900, width: "20%" }}>
                  세탁 납품일
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900, width: "13%" }}>
                  상태
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {factorySpecificationsList?.list.map((list, index) => (
                <TableRow
                  className="tableHover"
                  onClick={() =>
                    onDetail(list.id, list.title, list.franchise, list.createdDate, list.chartType)
                  }
                >
                  <TableCell component="th" scope="row" align="center">
                    {listNumber[index]}
                  </TableCell>
                  <TableCell align="left" style={{ padding: "0 50px" }}>
                    {list.title}
                  </TableCell>
                  <TableCell align="center">{list.franchise}</TableCell>
                  <TableCell align="center">
                    {DateFormatUtils.dateToFormat(list.createdDate)}
                  </TableCell>
                  <TableCell align="center">
                    {TypeUtils.statusTypeText(list.confirmation)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Paging
          listAction={pageMove}
          listCount={factorySpecificationsList?.totalPage ?? 1}
          currentPage={currentPage}
          totalCount={factorySpecificationsList?.totalCount ?? 1}
          size={searchData.size ?? 0}
        ></Paging>
      </div>
    </FactoryItemizedAccountListBlock>
  );
};

export default FactoryItemizedAccountList;

const FactoryItemizedAccountListBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  .tables {
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .tableHover > td {
    padding: 10px;
  }
  .tableHover:hover {
    background-color: #eee;
    cursor: pointer;
  }
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
    & > div {
      width: auto;
      height: 20px;
      display: flex;
      justify-content: space-between;
      margin-left: 20px;
      padding-top: 5px;
      & > span {
        color: #dee2e6;
      }
      & > .selectClick {
        cursor: pointer;
        font-size: 14px;
      }
      & > .on {
        color: ${Palette.primary};
        font-weight: bold;
      }
      & > .selectClick:hover {
        color: ${Palette.primary};
        font-weight: bold;
      }
      & > span:nth-child(2) {
        font-size: 10px;
        margin: 0 10px;
        color: #333;
      }
    }
  }
  .tableSelect {
    height: 70px;
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: space-between;
    & > div {
      color: #999;
      height: 40px;
      /* overflow: hidden; */
      & > span {
        font-size: 14px;
        line-height: 37px;
      }
      & > select {
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        transition: ease-in-out;
        transition-duration: 0.1s;
        border-radius: 3px;
      }
      & > select:hover {
        border-bottom: 2px solid ${Palette.primary};
      }
    }
  }
  .topWrapper {
    display: flex;
    justify-content: flex-end;
    & > span {
      font-size: 14px;
      line-height: 37px;
      margin-right: 5px;
    }
    & > div {
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div {
        margin: 0;
        margin-left: 1rem;
      }
    }
  }
  .tableWrapper {
    min-width: 51rem;
    padding-bottom: 1.5rem;
  }

  .infoBox {
    width: auto !important;
    height: 16px;
    position: absolute;
    top: 15px;
    z-index: 2;
    right: 20px;
    & > .info {
      width: 90px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: default;
      & > span:nth-child(1) {
        font-size: 12px;
        color: ${Palette.primary};
      }
      & > span:nth-child(2) {
        width: 16px;
        height: 16px;
        & > img {
          width: 100%;
          height: 100%;
        }
      }
    }
    & > .infoGuide {
      position: absolute;
      width: 320px;
      height: auto;
      background-color: #ffffcc;
      border: 1px solid black;
      border-radius: 7px;
      font-size: 12px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 10px;
      top: 20px;
      right: 0;
      display: none;
    }
    & > .infoCenter {
      width: 240px;
      line-height: 30px;
    }
  }
  .infoBox:hover {
    & > .infoGuide {
      display: block;
    }
  }
  .sortList {
    cursor: pointer;
  }
  .sortSelect {
    width: 13px;
    height: 13px;
    margin-left: 5px;
    position: relative;
    top: 2px;
  }
  .sortUp {
    color: red;
  }
  .sortDown {
    color: blue;
  }
`;
