import PaymentSearch from "components/common/PaymentSearch";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import NumberUtils from "utils/NumberUtils";
import { Palette } from "styles/palette";
import Paging from "components/common/Paging";
import LabelSearch from "components/common/LabelSearch";
import {
  LabelExportDataListType,
  LabelSearchType,
} from "modules/factory/types";
import { SpecificationsFranchiseList } from "modules/user/types";
import moment from "moment";

import nonSelect from "assets/arrow/non_select.svg";
import downSelect from "assets/arrow/down_select.svg";
import upSelect from "assets/arrow/up_select.svg";

interface FactoryLabelProps {
  labelExportDataList: LabelExportDataListType | null;
  specificationsFranchiseList: SpecificationsFranchiseList | null;
  selectShowEntries: (limit: number) => void;
  onSearch: (data: LabelSearchType) => void;
  onSortList: (sort: string) => void;
  onPageMove: (page: number) => void;
  currentPageState: LabelSearchType;
}

const FactoryLabel: React.FC<FactoryLabelProps> = (props) => {
  const [selectSize, setSelectSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [sort, setSort] = useState<string>("");
  const [moveSortType, setMoveSortType] = useState<string>("");

  const [listNumber, setListNumber] = useState<number[]>([]);
  useEffect(() => {
    setListNumber(
      NumberUtils.numberToList(
        props.labelExportDataList?.currentPage ?? 0,
        props.labelExportDataList?.totalCount ?? 0,
        props.currentPageState.limit ?? 10
      )
    );
  }, [props.labelExportDataList, selectSize]);

  // console.log("num", listNumber);

  const onSortList = (sortType: string) => {
    if (sort === "" || sort === "ASC") setSort("DESC");
    if (sort === "DESC") setSort("ASC");

    let sortItem = sort === "DESC" ? `${sortType}desc` : `${sortType}asc`;

    setMoveSortType(sortItem);
    props.onSortList(sortItem);
  };

  const onSearch = (query: string, type: string) => {
    let item = {
      page: 1,
      limit: selectSize,
      query,
      type,
      sort: moveSortType,
    };
    props.onSearch(item);
  };

  const onPageMove = (page: number) => {
    setPage(page);
    props.onPageMove(page);
  };

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      fontWeight: 700,
    },
  });
  const classes = useStyles();

  return (
    <FactoryLabelBlock>
      <div className="headers">
        <h3>라벨 출력 현황</h3>
      </div>
      <div className="tableSelect">
        <div>
          <span>Show </span>
          <select
            onChange={(e) =>
              props.selectShowEntries(parseInt(e.target.value, 10))
            }
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
          <span> entries</span>
        </div>
        <div className="topWrapper">
          <span>Search: </span>
          <div>
            <LabelSearch
              role={""}
              onSearch={onSearch}
              size={10}
              selectList={props.specificationsFranchiseList}
            />
          </div>
        </div>
      </div>
      <div className="tableWrapper">
        <TableContainer component={Paper} className="tables">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "70px" }}
                >
                  순번
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900 }}
                  className="sortList"
                  onClick={() => onSortList("fr")}
                >
                  지점명
                  <img
                    className="sortSelect"
                    src={
                      sort === ""
                        ? nonSelect
                        : sort === "DESC"
                        ? downSelect
                        : upSelect
                    }
                    alt=""
                  />
                  {/* {sort === "ASC" && <span className="sortDown">▼</span>}
                  {sort === "DESC" && <span className="sortUp">▲</span>} */}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "13%" }}
                >
                  품목명
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "13%" }}
                >
                  번호
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "17%" }}
                >
                  부서명
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "15%" }}
                >
                  이름
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "16%" }}
                >
                  출력일시
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.labelExportDataList &&
                props.labelExportDataList.list.map((item, index) => (
                  <TableRow key={index} className="tableHover">
                    <TableCell component="th" scope="row" align="center">
                      {listNumber[index]}
                    </TableCell>
                    <TableCell align="center">{item.franchiseName}</TableCell>
                    <TableCell align="center">{item.assetName}</TableCell>
                    <TableCell align="center">{item.userNum}</TableCell>
                    <TableCell align="center">{item.departmentName}</TableCell>
                    <TableCell align="center">{item.userName}</TableCell>
                    <TableCell align="center">
                      {moment(item.labelCreatedDate).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Paging
          listAction={onPageMove}
          listCount={props.labelExportDataList?.totalPage ?? 0}
          currentPage={props.labelExportDataList?.currentPage}
          totalCount={props.labelExportDataList?.totalCount}
          size={selectSize ?? 10}
        ></Paging>
      </div>
    </FactoryLabelBlock>
  );
};

export default FactoryLabel;

const FactoryLabelBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);

  .tables {
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  .topWrapper {
    display: flex;
    justify-content: flex-end;
    & > span {
      font-size: 14px;
      line-height: 37px;
      margin-right: 5px;
    }
    & > div {
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div {
        margin: 0;
        margin-left: 1rem;
      }
    }
  }
  .tableWrapper {
    min-width: 51rem;
    padding-bottom: 1.5rem;
  }
  .tableHover:hover {
    background-color: #eee;
    cursor: pointer;
  }
  .tableSelect {
    height: 70px;
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: space-between;
    & > div {
      color: #999;
      height: 40px;
      /* background-color: red; */
      overflow: hidden;
      & > span {
        font-size: 14px;
        line-height: 37px;
      }
      & > select {
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        transition: ease-in-out;
        transition-duration: 0.1s;
      }
      & > select:hover {
        border-bottom: 2px solid ${Palette.primary};
      }
    }
  }
  .sortList {
    cursor: pointer;
  }
  .sortSelect {
    width: 13px;
    height: 13px;
    margin-left: 5px;
    position: relative;
    top: 2px;
  }
  .sortUp {
    color: red;
  }
  .sortDown {
    color: blue;
  }
  /* .paymentInfo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & > div {
      align-self: flex-end;
    }
    p {
      span + span {
        margin-left: 3rem;
      }
      strong {
        font-weight: normal;
        text-decoration: underline;
      }
    }
  } */
`;
