import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Palette } from "styles/palette";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import NumberUtils from "utils/NumberUtils";
import Paging from "components/common/Paging";
import { AdminGetList, AdminPayManagement } from "modules/admin/types";

import TypeUtils from "utils/TypeUtils";
import PayManagementSearch from "components/common/PayManagementSearch";
import moment from "moment";

interface AdminPayManagementCompleteStatusProps {
  drop: string;
  setDrop: (type: string) => void;
  completeCheck: string;
  searchData: AdminGetList;
  listType: (type: string) => void;
  adminPayManagementList: AdminPayManagement | null;
  getAdminPayManagementList: (page: number, data: AdminGetList | null) => void;
  setCurrentPage: (page: number) => void;
  patchAdminPayManagementData: (id: number, status: string) => void;
}

const AdminPayManagementCompleteStatus: React.FC<AdminPayManagementCompleteStatusProps> =
  (props) => {
    const [listNumber, setListNumber] = useState<number[]>([]);

    useEffect(() => {
      setListNumber(
        NumberUtils.numberToList(
          props.adminPayManagementList?.currentPage ?? 0,
          props.adminPayManagementList?.totalCount ?? 0,
          props.searchData.size ?? 10
        )
      );
    }, [props.adminPayManagementList, props.searchData]);

    const useStyles = makeStyles({
      table: {
        minWidth: 650,
        fontWeight: 700,
      },
    });
    const classes = useStyles();

    const selectShowEntries = (size: number) => {
      props.searchData.size = size;
      props.getAdminPayManagementList(1, props.searchData ?? null);
    };

    const pageMove = (page: number) => {
      props.setCurrentPage(page);
      props.getAdminPayManagementList(page, props.searchData);
    };

    const onSearch = (page: number, data: AdminGetList) => {
      props.getAdminPayManagementList(page, data);
    };
    return (
      <AdminPayManagementCompleteStatusBlock>
        <div className="headers">
          <h3>결제 관리</h3>
          <div>
            <span
              className={
                props.completeCheck === "notComplete"
                  ? "selectClick on"
                  : "selectClick"
              }
              onClick={() => props.listType("notComplete")}
            >
              미완료
            </span>
            <span>|</span>
            <span
              className={
                props.completeCheck === "notComplete"
                  ? "selectClick"
                  : "selectClick on"
              }
            >
              완료
            </span>
          </div>
        </div>
        <div className="tableSelect">
          <div>
            <span>Show </span>
            <select
              onChange={(e) => selectShowEntries(parseInt(e.target.value, 10))}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
            <span> entries</span>
          </div>
          <div className="topWrapper">
            <span>Search: </span>
            <div>
              <PayManagementSearch
                drop={props.drop}
                setDrop={props.setDrop}
                completeCheck={props.completeCheck}
                onSearch={onSearch}
                size={props.searchData.size ?? 10}
              />
            </div>
          </div>
        </div>
        <div className="tableWrapper">
          <TableContainer component={Paper} className="tables">
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ fontWeight: 900, width: "70px" }}
                  >
                    순번
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 900 }}>
                    지점명
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: 900, width: "20%" }}
                  >
                    정산기간
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: 900, width: "15%" }}
                  >
                    계약방식
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: 900, width: "15%" }}
                  >
                    정산월
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: 900, width: "15%" }}
                  >
                    정산금액
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontWeight: 900, width: "15%" }}
                  >
                    정산상태
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.adminPayManagementList?.list.map((pay, index) => (
                  <TableRow key={index} className="tableHover">
                    <TableCell component="th" scope="row" align="center">
                      {listNumber[index]}
                    </TableCell>
                    <TableCell align="center">
                      {pay.franchiseName ?? "-"}
                    </TableCell>
                    <TableCell align="center">{pay.billingDate}</TableCell>
                    <TableCell align="center">
                      {TypeUtils.payManagementContactType(pay.contractStatus)}
                    </TableCell>
                    <TableCell align="center">
                      {pay.paymentMonth
                        ? moment(pay.paymentMonth).format("YYYY-MM")
                        : "-"}
                    </TableCell>
                    <TableCell align="center">
                      {pay.price
                        ? NumberUtils.numberToComma(Number(pay.price))
                        : "-"}
                    </TableCell>
                    <TableCell align="center">
                      <select
                        value={pay.contractStatus}
                        onChange={(e) =>
                          props.patchAdminPayManagementData(
                            pay.id,
                            e.target.value
                          )
                        }
                      >
                        <option value="COMPLETE">결제완료</option>
                        <option value="WAITING">대기</option>
                        <option value="TAX_INVOICE">세금계산서발행</option>
                      </select>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Paging
            listAction={pageMove}
            listCount={props.adminPayManagementList?.totalPage ?? 1}
            currentPage={props.adminPayManagementList?.currentPage}
            totalCount={props.adminPayManagementList?.totalCount ?? 1}
            size={props.searchData.size ?? 10}
          ></Paging>
        </div>
      </AdminPayManagementCompleteStatusBlock>
    );
  };

export default AdminPayManagementCompleteStatus;

const AdminPayManagementCompleteStatusBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  .tables {
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .tableHover > td {
    padding: 10px;
  }
  .tableHover:hover {
    background-color: #eee;
    cursor: pointer;
  }
  & > .headers {
    height: 70px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid black;
    position: relative;

    & > div {
      width: 95px;
      height: 20px;
      display: flex;
      justify-content: space-between;
      margin-left: 20px;
      padding-top: 5px;
      /* background-color: red; */
      & > span {
        color: #dee2e6;
        font-size: 14px;
      }
      & > .selectClick {
        cursor: pointer;
      }
      & > .on {
        color: ${Palette.primary};
        font-weight: bold;
      }
      & > .selectClick:hover {
        color: ${Palette.primary};
        font-weight: bold;
      }
      & > span:nth-child(2) {
        font-size: 12px;
        align-self: center;
        cursor: default;
        margin: 0 10px;
        /* padding-top: 5px; */
        color: #333;
      }
    }
  }
  .tableSelect {
    height: 70px;
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: space-between;
    & > div {
      color: #999;
      height: 40px;
      overflow: hidden;
      & > span {
        font-size: 14px;
        line-height: 37px;
      }
      & > select {
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        transition: ease-in-out;
        transition-duration: 0.1s;
        border-radius: 3px;
        /* padding: 4px; */
      }
      & > select:hover {
        border-bottom: 2px solid ${Palette.primary};
      }
    }
  }
  .topWrapper {
    display: flex;
    justify-content: flex-end;
    & > span {
      font-size: 14px;
      line-height: 37px;
      margin-right: 5px;
    }
    & > div {
      width: auto;
      display: inline-block;

      & > div {
        margin: 0;
        margin-left: 5px;
      }
    }
  }
  .tableWrapper {
    min-width: 51rem;
    padding-bottom: 1.5rem;
  }
`;
