import React, { useState } from "react";
import styled from "styled-components";
import { ThemeColor } from "styles/palette";
import RoleUtils from "utils/RoleUtils";
import Button from "./Button";
import { LaundrySearchBlock } from "./LaundrySearch";

interface InventoryStaffInfoSearchProps {
  role: string;
  onSearch: (query: string, type: string) => void;
  size?: number | null;
}

const InventoryStaffInfoSearch: React.FC<InventoryStaffInfoSearchProps> = (
  props
) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchType, setSearchType] = useState<string>("department");

  const selectHandleChange = (e: string) => {
    setSearchType(e);
  };

  const onSearch = (e: any) => {
    e.preventDefault();
    props.onSearch(searchQuery, searchType);
  };

  return (
    <InventoryStaffInfoSearchBlock onSubmit={(e) => onSearch(e)}>
      <select
        className="selectBox"
        value={searchType!}
        onChange={(e) => {
          selectHandleChange(e.target.value);
        }}
      >
        <option value="department">부서명</option>
        <option value="user">이름</option>
      </select>

      <input
        className="textBox"
        value={searchQuery ?? ""}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      <Button
        theme={ThemeColor.primary}
        buttonSize="m"
        // onClick={submitSearch}
        className="buttonSize"
      >
        검색
      </Button>
    </InventoryStaffInfoSearchBlock>
  );
};

export default InventoryStaffInfoSearch;

const InventoryStaffInfoSearchBlock = styled(LaundrySearchBlock)``;
