import Button from "components/common/Button";
import DaumPostCode from "components/common/DaumPostCode";
import { CenterAligned } from "components/common/layouts/CenterAligned";
import { AdminBrandDetail } from "modules/admin/types";
import { ProfileModify } from "modules/user/types";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Palette, ThemeColor } from "styles/palette";
import DateFormatUtils from "utils/DateFormatUtils";

interface AdminBrandDetailStatusProps {
  adminBrandDetail: AdminBrandDetail | null;
  onClose?: () => void;
  modifyBrandUserName?: (id: number, userName: string) => void;
  postPWInitialization: (id: number) => void;
  historyBack?: () => void;
  brandInfoModify: (payload: ProfileModify) => void;
  adminIdDuplicateCheck: string | null;
  duplicateCheckRequest: (id: string) => void;
  duplicateCheckCancel: () => void;
  onDelete: () => void;
}

const AdminBrandStatusDetail: React.FC<AdminBrandDetailStatusProps> = ({
  adminBrandDetail,
  onClose,
  modifyBrandUserName,
  postPWInitialization,
  historyBack,
  brandInfoModify,
  adminIdDuplicateCheck,
  duplicateCheckRequest,
  duplicateCheckCancel,
  onDelete,
}) => {
  const MasterStoreStatusDetailHeader: string[] = ["대분류", "소분류", "품명", "사이즈", "비고"];

  const [duplicateCheck, setDuplicateCheck] = useState<string>("N");

  const [onInfoModi, setOnInfoModi] = useState<boolean>(false);
  const [postCodeCheck, setPostCodeCheck] = useState(false);
  const [newUserName, setNewUserName] = useState<string | null>(null);

  const [companyName, setCompanyName] = useState<string>("");
  const [companyPhone, setCompanyPhone] = useState<string>();
  const [companyStreetDetail, setCompanyStreetDetail] = useState<string>("");
  const [companyManagerName, setCompanyManagerName] = useState<string>("");
  const [companyManagerPhone, setCompanyManagerPhone] = useState<string>("");

  const [zoneCode, setZoneCode] = useState<string>("");
  const [fullAddress, setFullAddress] = useState<string>("");

  const [defaultState, setDefaultState] = useState({
    newUserName: "",
    companyName: "",
    companyPhone: "",
    zoneCode: "",
    fullAddress: "",
    companyStreetDetail: "",
    companyManagerName: "",
    companyManagerPhone: "",
  });

  useEffect(() => {
    setNewUserName(adminBrandDetail?.brandResponse.manager.username ?? "");
    setCompanyName(adminBrandDetail?.brandResponse.companyName ?? "-");
    setCompanyPhone(adminBrandDetail?.brandResponse.companyPhone ?? "-");
    setZoneCode(adminBrandDetail?.brandResponse.companyAddress.postCode ?? "-");
    setFullAddress(adminBrandDetail?.brandResponse.companyAddress.street ?? "-");
    setCompanyStreetDetail(adminBrandDetail?.brandResponse.companyAddress.streetDetails ?? "-");
    setCompanyManagerName(adminBrandDetail?.brandResponse.manager.nickname ?? "-");
    setCompanyManagerPhone(adminBrandDetail?.brandResponse.manager.phone ?? "-");

    setDefaultState({
      newUserName: adminBrandDetail?.brandResponse.manager.username ?? "",
      companyName: adminBrandDetail?.brandResponse.companyName ?? "-",
      companyPhone: adminBrandDetail?.brandResponse.companyPhone ?? "-",
      zoneCode: adminBrandDetail?.brandResponse.companyAddress.postCode ?? "-",
      fullAddress: adminBrandDetail?.brandResponse.companyAddress.street ?? "-",
      companyStreetDetail: adminBrandDetail?.brandResponse.companyAddress.streetDetails ?? "-",
      companyManagerName: adminBrandDetail?.brandResponse.manager.nickname ?? "-",
      companyManagerPhone: adminBrandDetail?.brandResponse.manager.phone ?? "-",
    });
  }, [adminBrandDetail]);

  // const [onModi, setOnModi] = useState<boolean>(false);

  // const onSubmit = (id: number) => {
  //   if (!newUserName) return alert("빈값은 입력할 수 없습니다");
  //   if (window.confirm("아이디를 변경하싰겠습니까?")) {
  //     modifyBrandUserName!(id, newUserName!);
  //     historyBack!();
  //   }
  // };

  const onAddress = (zoneCode: string, fullAddress: string) => {
    setZoneCode(zoneCode);
    setFullAddress(fullAddress);
    setPostCodeCheck(false);
  };

  const onCloseModify = () => {
    setOnInfoModi(false);
    setNewUserName(defaultState.newUserName);
    setCompanyName(defaultState.companyName);
    setCompanyPhone(defaultState.companyPhone);
    setZoneCode(defaultState.zoneCode);
    setFullAddress(defaultState.fullAddress);
    setCompanyStreetDetail(defaultState.companyStreetDetail);
    setCompanyManagerName(defaultState.companyManagerName);
    setCompanyManagerPhone(defaultState.companyManagerPhone);
  };

  const modifySubmit = () => {
    if (defaultState.newUserName !== newUserName && duplicateCheck === "N")
      return alert("중복된 아이디 입니다. 확인해주세요.");
    let address = {
      postCode: zoneCode!,
      street: fullAddress!,
      streetDetails: companyStreetDetail!,
      isLoadName: true,
    };

    let payload = {
      id: newUserName!,
      companyName: companyName!,
      phone: companyPhone!,
      address: address!,
      managerName: companyManagerName!,
      managerPhone: companyManagerPhone!,
    };
    if (window.confirm("정보를 수정하시겠습니까?")) {
      brandInfoModify(payload);
      setOnInfoModi(false);
    }
  };

  useEffect(() => {
    duplicateCheckCancel();
  }, []);

  useEffect(() => {
    setDuplicateCheck(adminIdDuplicateCheck ?? "N");
  }, [adminIdDuplicateCheck]);

  const onBrandDelete = () => {
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      onDelete();
    }
  };

  return (
    <AdminBrandStatusDetailBlock>
      {postCodeCheck && (
        <div className="postCode">
          <div>
            <DaumPostCode onAddress={onAddress} />
            <CenterAligned>
              <Button
                theme={ThemeColor.tertiary}
                buttonSize="m"
                onClick={() => setPostCodeCheck(false)}
                className="postCodeCloseBtn"
              >
                닫기
              </Button>
            </CenterAligned>
          </div>
        </div>
      )}
      <div className="headers">
        <h3>브랜드 상세</h3>
        <Button theme={ThemeColor.primary} buttonSize="m" onClick={historyBack}>
          뒤로가기
        </Button>
      </div>

      <div className="contentWrapper">
        <div className="detailInfo">
          <div className="field">
            <span>아이디</span>
            <div>
              <input
                value={newUserName ?? ""}
                onChange={(e) => setNewUserName(e.target.value)}
                className="changeId"
                disabled={!onInfoModi}
              />
              {onInfoModi && (
                <Button
                  theme={ThemeColor.primary}
                  buttonSize="m"
                  className="changeIdBtn"
                  onClick={() => duplicateCheckRequest(newUserName!)}
                >
                  중복체크
                </Button>
              )}
              {/* {onModi ? (
                <Button
                  theme={ThemeColor.primary}
                  buttonSize="m"
                  className="changeIdBtn"
                  onClick={() =>
                    onSubmit(adminBrandDetail?.brandResponse.manager.id!)
                  }
                >
                  저장
                </Button>
              ) : (
                <Button
                  theme={ThemeColor.primary}
                  buttonSize="m"
                  className="changeIdBtn"
                  onClick={() => setOnModi(true)}
                >
                  변경
                </Button>
              )} */}
            </div>
          </div>
          <div className="field">
            <span>{""}</span>
            <Button
              theme={ThemeColor.primary}
              buttonSize="m"
              className="PWBtn"
              onClick={() =>
                postPWInitialization(Number(adminBrandDetail?.brandResponse.manager.id))
              }
            >
              비밀번호초기화
            </Button>
          </div>
          <div className="field">
            <span>브랜드명</span>
            {onInfoModi ? (
              <input value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
            ) : (
              <p>{companyName}</p>
            )}
          </div>
          <div className="field">
            <span>연락처</span>
            {onInfoModi ? (
              <input value={companyPhone} onChange={(e) => setCompanyPhone(e.target.value)} />
            ) : (
              <p>{companyPhone}</p>
            )}
          </div>
          <div className="field secoundField">
            <span>주소</span>
            {onInfoModi ? (
              <>
                <input value={zoneCode} disabled />
                <Button
                  theme={ThemeColor.primary}
                  buttonSize="m"
                  onClick={() => setPostCodeCheck(true)}
                >
                  찾기
                </Button>
              </>
            ) : (
              <p>{zoneCode}</p>
            )}
          </div>
          <div className="field">
            <span></span>
            {onInfoModi ? <input value={fullAddress} disabled /> : <p>{fullAddress}</p>}
          </div>
          <div className="field">
            <span></span>
            {onInfoModi ? (
              <input
                value={companyStreetDetail}
                onChange={(e) => setCompanyStreetDetail(e.target.value)}
              />
            ) : (
              <p>{companyStreetDetail}</p>
            )}
          </div>

          <div className="field">
            <span>담당자명</span>
            {onInfoModi ? (
              <input
                value={companyManagerName}
                onChange={(e) => setCompanyManagerName(e.target.value)}
              />
            ) : (
              <p>{companyManagerName}</p>
            )}
          </div>
          <div className="field">
            <span>담당자연락처</span>
            {onInfoModi ? (
              <input
                value={companyManagerPhone}
                onChange={(e) => setCompanyManagerPhone(e.target.value)}
              />
            ) : (
              <p>{companyManagerPhone}</p>
            )}
          </div>
          <div className="field">
            <span>가입일</span>
            <p>{DateFormatUtils.dateToFormat(adminBrandDetail?.brandResponse.joinDate)}</p>
          </div>
          <div className="buttonWrapper">
            {!onInfoModi ? (
              <>
                <Button theme={ThemeColor.warning} buttonSize="m" onClick={onBrandDelete}>
                  삭제
                </Button>
                <Button
                  theme={ThemeColor.primary}
                  buttonSize="m"
                  onClick={() => setOnInfoModi(true)}
                >
                  수정
                </Button>
              </>
            ) : (
              <>
                <Button theme={ThemeColor.primary} buttonSize="m" onClick={modifySubmit}>
                  저장
                </Button>
                <Button theme={ThemeColor.primary} buttonSize="m" onClick={() => onCloseModify()}>
                  취소
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="stockStatus">
          <div>
            <h3>재고현황</h3>
            <div className="stockInField">
              <div>
                <div className="stockHeader">
                  {MasterStoreStatusDetailHeader.map((item, key) => (
                    <span key={key}>{item}</span>
                  ))}
                </div>
                <div className="stockList">
                  {adminBrandDetail?.assetDetailsResponse.map((item, index) => (
                    <div className="stockField" key={index}>
                      <span>{item.largeCategory}</span>
                      <span>{item.subCategory}</span>
                      <span>{item.name}</span>
                      <span>{item.size ?? "-"}</span>
                      <span>{item.content ?? "-"}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="buttonWrapper">
          <Button theme={ThemeColor.primary} buttonSize="m" onClick={onClose}>
            확인
          </Button>
       
        </div> */}
      </div>
    </AdminBrandStatusDetailBlock>
  );
};

export default AdminBrandStatusDetail;

const AdminBrandStatusDetailBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  & > .contentWrapper {
    width: 100%;
    height: 820px;

    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    & > div {
      width: 35%;
    }
    & > div:nth-child(2) {
      width: 65%;
      border-left: 1px solid black;
    }
    & > .detailInfo {
      width: auto;
      margin: 0 auto;

      & > .field {
        width: 100%;
        min-height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
        & > span {
          width: 140px;
        }
        & > p,
        input {
          border: 1px solid #ddd;
          font-size: 14px;
          border-radius: 5px;
          height: 100%;
          width: 260px;
          line-height: 35px;
          margin: 0;
          padding-left: 10px;
        }
        & > input {
          height: 37px;
        }
        & > div {
          & > .changeId {
            width: 179px;
            border: 1px solid #ddd;
            height: 37px;
            margin-right: 5px;
          }
        }
        & > .PWBtn {
          width: 260px;
        }
      }
      & > .secoundField {
        & > p,
        input {
          width: 174px;
          margin-right: 10px;
        }
      }
      & > .buttonWrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 50px;
      }
    }
    & > .stockStatus {
      height: 740px;
      & > div {
        width: 90%;
        min-width: 690px;
        height: auto;
        margin: 0 auto;
        & > h3 {
          margin: 0;
        }
        & > .stockInField {
          margin-top: 20px;
          & > div {
            width: 100%;
            height: 690px;
            border-radius: 10px;
            background-color: #ecf0f5;
            padding: 20px 5px;
            overflow: hidden;
            & > .stockHeader {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-right: 15px;
              & > span {
                width: calc(100% / 6);
                text-align: center;
                font-size: 13px;
                word-wrap: break-word;
              }
            }
            & > .stockList {
              overflow-y: scroll;
              height: 90%;
              margin-top: 20px;
              & > div {
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #fff;
                padding: 2px 0;
                & > span {
                  width: calc(100% / 6);
                  text-align: center;
                  font-size: 13px;
                  word-wrap: break-word;
                }
              }
            }
          }
        }
      }
    }
  }
  .postCode {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    & > div {
      width: 30rem;
      & > div {
        & > .postCodeCloseBtn {
          margin-top: 1rem;
        }
      }
    }
  }
  /* .stockStatus {
    height: 140px;
    margin-top: 100px;
    display: grid;
    grid-template-columns: 6rem 1fr;
    column-gap: 1rem;
    .stockInCategory {
      background-color: #fff;
      overflow-y: scroll;
      padding: 10px;
      .categoryField {
        height: 30px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .stockInField {
      width: 100%;
      min-height: 140px;
      background-color: ${Palette.white};
      .stockHeader {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        text-align: center;
        align-items: center;
        padding: 5px;
        border-bottom: 1px solid #eeeeee;
      }

      .stockList {
        overflow-y: scroll;
        height: 110px;
      }
      .stockField {
        display: grid;
        padding: 5px 0;
        grid-template-columns: repeat(5, 1fr);
        text-align: center;
        align-items: center;
      }
      .stockField:nth-child(2n) {
        background-color: #eee;
      }
    }
  }
  .changeId {

    border: 1px solid #bebebe;
  }
  .changeIdBtn {
    width: 50px;
    
    padding: 8.7px 0;
  } */
`;
