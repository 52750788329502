import StoreDashboard from "components/store/StoreDashboard";
import { IRootState } from "modules";
import { getFranchiseDashboardAction } from "modules/store/store";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DateFormatUtils from "utils/DateFormatUtils";

interface StoreDashboardContainerProps {}

const StoreDashboardContainer: React.FC<StoreDashboardContainerProps> = () => {
  const [timeNow, setTimeNow] = useState<Date>(new Date());
  const [selectDay, setSelectDay] = useState<string>();
  const dispatch = useDispatch();

  const franchiseDashboard = useSelector(
    (state: IRootState) => state.store.franchiseDashBoard
  );
  useEffect(() => {
    dispatch(
      getFranchiseDashboardAction(DateFormatUtils.dateToFormat(new Date()))
    );
  }, [dispatch]);

  // useEffect(() => {
  //   setInterval(() => {
  //     dispatch(
  //       getFranchiseDashboardAction(
  //         selectDay ?? DateFormatUtils.dateToFormat(new Date())
  //       )
  //     );
  //     setTimeNow(new Date());
  //   }, 5 * 60 * 1000);
  // }, []);

  const getDashboard = (day: string) => {
    setSelectDay(day);
    dispatch(getFranchiseDashboardAction(day));
    setTimeNow(new Date());
  };

  return (
    <StoreDashboard
      franchiseDashboard={franchiseDashboard}
      getDashboard={getDashboard}
      timeNow={timeNow}
    />
  );
};

export default StoreDashboardContainer;
