import AdminLaundry from "components/admin/AdminLaundryStatus";
import AdminLaundryStatusDetail from "components/admin/AdminLaundryStatusDetail";
import Loading from "components/common/Loading";
import { IRootState } from "modules";
import {
  getAdminLaundryDetailAction,
  getAdminLaundryListAction,
} from "modules/admin/admin";
import { AdminGetList } from "modules/admin/types";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

interface AdminLaundryContainerProps {
  match: any;
}

const AdminLaundryContainer: React.FC<AdminLaundryContainerProps> = ({
  match,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const type = useSelector((state: IRootState) => state.user.profile?.type);
  const adminLaundryList = useSelector(
    (state: IRootState) => state.admin.adminLaundryList
  );
  const adminLaundryDetail = useSelector(
    (state: IRootState) => state.admin.adminLaundryDetail
  );

  const [page, setPage] = useState<number>(1);
  const [searchData, setSearchData] = useState<AdminGetList | null>();
  const [detailId, setDetailId] = useState<number | null>(null);
  const [selectType, setSelectType] = useState<string>("list");
  const [flag, setFlag] = useState<boolean>(true);

  // useEffect(() => {
  //   let time = setTimeout(function reList() {
  //     dispatch(getAdminLaundryListAction(page, searchData ?? null));
  //     console.log(page);
  //     time = setTimeout(reList, 1 * 60 * 1000);
  //   }, 1 * 60 * 1000);
  // }, []);

  useEffect(() => {
    dispatch(getAdminLaundryListAction(1, null));
  }, [dispatch]);

  useEffect(() => {
    if (match.params.id && Number(match.params.id)) {
      setDetailId(match.params.id);
      setSelectType("detail");
      dispatch(getAdminLaundryDetailAction(match.params.id));
    } else {
      setSelectType("list");
    }
  }, [match.params.id]);

  const query = window.location.pathname;

  useEffect(() => {
    setFlag(true);
  }, [adminLaundryList]);

  useEffect(() => {
    if (query === `/admin/laundry`) {
      setSelectType("list");
      setDetailId(null);
    } else {
      setSelectType("detail");
    }
  }, [query]);

  const laundryList = (page: number, data: AdminGetList | null) => {

    setPage(page);
    setSearchData(data);
    if(flag){
      setFlag(false);
      dispatch(getAdminLaundryListAction(page, data));
    }
  };

  const laundryDetail = (id: number) => {
    setDetailId(id);
    dispatch(getAdminLaundryDetailAction(id));
    setSelectType("detail");
    history.push(`/admin/laundry/${id}`);
  };

  const onClose = () => {
    setDetailId(null);
    setSelectType("list");
    history.push(`/admin/laundry`);
  };

  return (
    <>
      {
        !flag && (
          <Loading />
        )
      }
      {selectType === "list" && (
        <AdminLaundry
          role={type}
          adminLaundryList={adminLaundryList}
          laundryList={laundryList}
          laundryDetail={laundryDetail}
        />
      )}
      {selectType === "detail" && (
        <AdminLaundryStatusDetail
          adminLaundryDetail={adminLaundryDetail}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default AdminLaundryContainer;
