import ProfileStatus from "components/common/ProfileStatus";
import React from "react";
import { IRootState } from "modules";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getProfileDetailAction,
  patchPasswordChangeAction,
  patchProfileAdminEditAction,
  patchProfileBrandEditAction,
  patchProfileFactoryEditAction,
  patchProfileStoreEditAction,
} from "modules/user/user";
import { PasswordChangeForm, ProfileModify } from "modules/user/types";
import RoleUtils from "utils/RoleUtils";

interface ProfileStatusContainerProps {}

const ProfileStatusContainer: React.FC<ProfileStatusContainerProps> = () => {
  const type = useSelector((state: IRootState) => state.user.profile?.type);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProfileDetailAction(type));
  }, [dispatch, type]);

  const user = useSelector((state: IRootState) => state.user.profile);
  const profileDetail = useSelector(
    (state: IRootState) => state.user.profileDetail
  );

  const patchProfileEdit = (payload: ProfileModify) => {
    if (RoleUtils.isFactory(type))
      dispatch(patchProfileFactoryEditAction(payload));
    if (RoleUtils.isStore(type)) dispatch(patchProfileStoreEditAction(payload));
    if (RoleUtils.isMaster(type))
      dispatch(patchProfileBrandEditAction(payload));
    if (RoleUtils.isMasterAdmin(type))
      dispatch(patchProfileAdminEditAction(payload));
  };

  const patchPasswordChange = (payload: PasswordChangeForm) => {
    dispatch(patchPasswordChangeAction(payload));
  };

  if (type === "GUEST") return null;
  return (
    <ProfileStatus
      profileDetail={profileDetail}
      role={type!}
      user={user}
      patchProfileEdit={patchProfileEdit}
      patchPasswordChange={patchPasswordChange}
    />
  );
};

export default ProfileStatusContainer;
