import Button from "components/common/Button";
import { AdminFranchiseContract, StoreContract } from "modules/admin/types";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { Palette, ThemeColor } from "styles/palette";
import NumberUtils from "utils/NumberUtils";

interface AdminStoreContractProps {
  setContractModal: (status: boolean) => void;
  postfranchiseContract: (payload: AdminFranchiseContract) => void;
  detailId: number | null;
  storeContract: StoreContract | null;
}

type AdminStoreContractBlockProps = {
  criteria: string;
};

const AdminStoreContract: React.FC<AdminStoreContractProps> = (props) => {
  const [criteria, setCriteria] = useState<string>("UNIT_PRICE");
  const [amountType, setAmountType] = useState<string>("QUANTITY");
  const [amountPrice, setAmountPrice] = useState<string>("");
  const [amountTaxType, setAmountTaxType] = useState<string>("TAX_INCLUDED");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [periodCheck, setPeriodCheck] = useState<boolean>(false);
  const [amountFeeType, setAmountFeeType] = useState<string>("DELIVERY_CHARGE");
  const [addShippingFee, setAddShippingFee] = useState<string>("");

  useEffect(() => {
    if (props.storeContract) {
      setCriteria(props.storeContract.basis);
      setAmountType(props.storeContract.type);
      setAmountPrice(String(props.storeContract.price));
      setStartDate(String(props.storeContract.startDay));
      setAmountTaxType(props.storeContract.vatStatus ? "TAX_INCLUDED" : "TAX_UNINCLUDED");
      setAmountFeeType(
        props.storeContract.deliveryStatus ? "DELIVERY_CHARGE" : "UNDELIVERY_CHARGE"
      );
      setAddShippingFee(String(props.storeContract.deliveryFee));
      if (props.storeContract.startDay === 1) {
        setPeriodCheck(true);
      } else {
        setEndDate(String(props.storeContract.lastDay));
      }
    }
  }, [props.storeContract]);

  const selectCriteria = (type: string) => {
    if (type === "UNIT_PRICE") setAmountType("QUANTITY");
    if (type === "MONTHLY") setAmountType("MONTH");
    setCriteria(type);
  };

  const numberToComma = (data: string) => {
    let test = data.replace(/[^0-9]/g, "");
    test.replace(/(^0+)/, "");
    test.replace(/,/g, "");
    let item = NumberUtils.numberToComma(test);
    let price = item !== 0 ? item : "";
    setAmountPrice(String(price));
  };

  const dateInput = (date: string, type: string) => {
    let item = date.replace(/[^0-9]/g, "");
    item.replace(/(^0+)/, "");

    if (Number(item) > 31) return alert("1개월 이상의 숫자는 입력할 수 없습니다");
    if (type === "start") {
      setStartDate(item);
      if (Number(item) === 1) {
        setPeriodCheck(true);
      } else {
        setPeriodCheck(false);
      }
    }
    if (type === "end") setEndDate(item);
  };

  const onSubmit = () => {
    let data = {
      basis: criteria,
      id: props.detailId!,
      lastDay: Number(endDate) ?? "",
      price: Number(amountPrice) ?? "",
      startDay: Number(startDate) ?? "",
      deliveryFee: Number(addShippingFee),
      vatStatus: amountTaxType === "TAX_INCLUDED" ? true : false,
      deliveryStatus: amountFeeType === "DELIVERY_CHARGE" ? true : false,
      type: amountType,
    };
    props.postfranchiseContract(data);
    props.setContractModal(false);
  };

  const addShippingFeeInput = (num: string) => {
    let fee = NumberUtils.numberOnlyToString(num);

    setAddShippingFee(String(NumberUtils.firstNotZero(fee)));
  };

  return (
    <AdminStoreContractBlock criteria={criteria}>
      <div className="contractWrapper">
        <h2>계약설정</h2>
        <div className="selectWrapper">
          <div>
            <span>계약기준</span>
            <div>
              <div
                className={criteria === "UNIT_PRICE" ? "active" : ""}
                onClick={() => {
                  selectCriteria("UNIT_PRICE");
                }}
              >
                단가제
              </div>
              <div
                className={criteria === "MONTHLY" ? "active" : ""}
                onClick={() => {
                  selectCriteria("MONTHLY");
                }}
              >
                월정액
              </div>
            </div>
          </div>
          <div>
            <span>부가세</span>
            <div>
              <div
                className={amountTaxType === "TAX_INCLUDED" ? "active" : ""}
                onClick={() => {
                  setAmountTaxType("TAX_INCLUDED");
                }}
              >
                부가세 포함
              </div>
              <div
                className={amountTaxType === "TAX_UNINCLUDED" ? "active" : ""}
                onClick={() => {
                  setAmountTaxType("TAX_UNINCLUDED");
                }}
              >
                부가세 별도
              </div>
            </div>
          </div>
          <div>
            <span>{criteria === "UNIT_PRICE" ? "산출방식" : "계약금액"}</span>
            {criteria === "UNIT_PRICE" && (
              <div>
                <div
                  className={amountType === "QUANTITY" ? "active" : ""}
                  onClick={() => {
                    setAmountType("QUANTITY");
                  }}
                >
                  수량
                </div>
                <div
                  className={amountType === "WEIGHT" ? "active" : ""}
                  onClick={() => {
                    setAmountType("WEIGHT");
                  }}
                >
                  중량
                </div>
                <div
                  className={amountType === "BAG" ? "active" : ""}
                  onClick={() => {
                    setAmountType("BAG");
                  }}
                >
                  포대/카트
                </div>
              </div>
            )}
            {criteria === "MONTHLY" && (
              <div>
                <div
                  className={amountType === "MONTH" ? "active" : ""}
                  onClick={() => {
                    setAmountType("MONTH");
                  }}
                >
                  <input
                    value={amountPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    onChange={(e) => numberToComma(e.target.value)}
                  />
                </div>
                <div className="active">1개월/원</div>
              </div>
            )}
          </div>
          <div className="calculate">
            <span>정산기간</span>
            <div>
              <div className="active">
                <input value={startDate} onChange={(e) => dateInput(e.target.value, "start")} />일
              </div>
              <span> ~ </span>
              <div className="active">
                {periodCheck ? (
                  <span className="textCenter">이달 말일</span>
                ) : (
                  <>
                    <input value={endDate} onChange={(e) => dateInput(e.target.value, "end")} />일
                  </>
                )}
              </div>
            </div>
          </div>
          <div>
            <span>추가 배송비 여부</span>
            <div>
              <div
                className={amountFeeType === "DELIVERY_CHARGE" ? "active" : ""}
                onClick={() => {
                  setAmountFeeType("DELIVERY_CHARGE");
                }}
              >
                설정
              </div>
              <div
                className={amountFeeType === "UNDELIVERY_CHARGE" ? "active" : ""}
                onClick={() => {
                  setAmountFeeType("UNDELIVERY_CHARGE");
                }}
              >
                미설정
              </div>
            </div>
          </div>
          {amountFeeType === "DELIVERY_CHARGE" && (
            <div className="">
              <span>추가 배송비(원)</span>
              <div>
                <div className="active">
                  <input
                    value={NumberUtils.numberToComma(Number(addShippingFee))}
                    onChange={(e) => addShippingFeeInput(e.target.value)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="btnBox">
          <Button theme={ThemeColor.primary} buttonSize="m" onClick={onSubmit}>
            저장
          </Button>
          <Button
            theme={ThemeColor.primary}
            buttonSize="m"
            onClick={() => props.setContractModal(false)}
          >
            취소
          </Button>
        </div>
      </div>
    </AdminStoreContractBlock>
  );
};

export default AdminStoreContract;

const AdminStoreContractBlock = styled.div<AdminStoreContractBlockProps>`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  & > .contractWrapper {
    width: 34rem;
    height: auto;
    background-color: #f0f0f0;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    & > h2 {
      padding-left: 1rem;
      align-self: flex-start;
      color: ${Palette.primary};
    }
    & > .selectWrapper {
      width: 360px;
      height: auto;
      display: flex;
      flex-direction: column;
      & > div {
        width: 230px;
        height: 70px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        & > span {
          font-weight: bold;
          font-size: 18px;
        }
        & > div {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          & > div {
            width: 100px;
            height: 40px;
            background-color: #adabab;
            color: #fff;
            border-radius: 5px;
            text-align: center;
            line-height: 40px;
            cursor: pointer;
            & > input {
              width: 100%;
              height: 100%;
              background-color: rgba(255, 255, 255, 0.5);
              border: 0;
            }
          }
          & > .active {
            background-color: ${Palette.tertiary};
          }
        }
      }
      & > .calculate {
        & > div {
          & > div {
            text-align: left;
            & > input {
              margin-right: 5px;
              width: 70px;
            }
            & > .textCenter {
              width: 100%;
              display: block;
              text-align: center;
            }
          }
        }
      }
      & > div:nth-child(3) {
        width: ${(props) => (props.criteria === "UNIT_PRICE" ? "360px" : "230px")};
      }
      & > div:nth-child(6) {
      }
    }
    & > .btnBox {
      padding: 15px 0;
    }
  }
`;
