import Button from "components/common/Button";
import { StatusDetailBlock } from "components/common/LaundaryStatusDetail";
import { CenterAligned } from "components/common/layouts/CenterAligned";
import { Item, MasterLaundryDetail } from "modules/master/types";
import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { ThemeColor } from "styles/palette";
import DateFormatUtils from "utils/DateFormatUtils";
import NumberUtils from "utils/NumberUtils";
import ScrollUtils from "utils/ScrollUtils";

interface MasterLaundaryStatusDetailProps {
  masterLaundryDetail: MasterLaundryDetail | null;
  onClose: () => void;
}

const MasterLaundaryStatusDetail: React.FC<MasterLaundaryStatusDetailProps> = ({
  masterLaundryDetail,
  onClose,
}) => {
  useEffect(() => {
    ScrollUtils.onPopupScrollStop(window.scrollY);
    return () => {
      const scrollY = document.body.style.top;
      ScrollUtils.closePopupScrollRun(scrollY);
    };
  }, []);

  const [laundryDetailItems, setLaundryDetailItems] = useState<Item[]>([]);
  useEffect(() => {
    setLaundryDetailItems(masterLaundryDetail?.franchiseAssetList ?? []);
  }, []);

  // console.log(masterLaundryDetail);

  return (
    <LaundaryStatusDetailBlock>
      <div className="headers">
        <h3>세탁현황 상세정보</h3>
        <Button theme={ThemeColor.primary} buttonSize="m" onClick={onClose}>
          뒤로가기
        </Button>
      </div>
      <div className="contentWrapper">
        <div className="detailInfo">
          <div className="field">
            <span>세탁 신청일</span>
            <p>
              {DateFormatUtils.dateToFormat(
                masterLaundryDetail?.applicationDate
              )}
            </p>
          </div>
          <div className="field">
            <span>납품일</span>
            <p>
              {DateFormatUtils.dateToFormat(masterLaundryDetail?.completedDate)}
            </p>
          </div>
          <div className="field">
            <span>세탁공장 담당</span>
            <p>{masterLaundryDetail?.factoryManager.nickname}</p>
          </div>
          <div className="field">
            <span></span>
            <p>{masterLaundryDetail?.factoryManager.phone}</p>
          </div>
          <div className="field">
            <span>입고 수량(포대/카트)</span>
            <p>
              {NumberUtils.numberToComma(masterLaundryDetail?.bagCount ?? 0)}
            </p>
          </div>
        </div>
        <div className="stockStatus">
          <div>
            <h3>세탁물 종류</h3>
            <div className="stockInField">
              <div>
                <div className="stockHeader">
                  <span>대분류</span>
                  <span>소분류</span>
                  <span>품명</span>
                  <span>사이즈</span>
                  <span>비고</span>
                  <span>세탁신청(개)</span>
                  <span>세탁완료(개)</span>
                  <span>재세탁(반품)</span>
                  <span>기타(미납,수선)</span>
                </div>
                <div className="stockList">
                  {masterLaundryDetail?.franchiseAssetList.map(
                    (laundryItem, idx) => (
                      <div className="field" key={idx}>
                        <span>{laundryItem.asset.details.largeCategory}</span>
                        <span>{laundryItem.asset.details.subCategory}</span>
                        <span>{laundryItem.asset.details.name}</span>
                        <span>{laundryItem.asset.details.size ?? "-"}</span>
                        <span>{laundryItem.asset.details.content ?? "-"}</span>
                        <span>
                          {laundryItem.beforeAmount
                            ? NumberUtils.numberToComma(
                                laundryItem.beforeAmount ?? 0
                              )
                            : "-"}
                        </span>
                        <span>
                          {laundryItem.amount
                            ? NumberUtils.numberToComma(laundryItem.amount ?? 0)
                            : "-"}
                        </span>
                        <span>
                          {laundryItem.relaundering
                            ? NumberUtils.numberToComma(
                                laundryItem.relaundering
                              )
                            : "-"}
                        </span>
                        <span>-</span>
                      </div>
                    )
                  )}
                  <div className="field">
                    <span>포대/카트</span>
                    <span>-</span>
                    <span>-</span>
                    <span>-</span>
                    <span>-</span>
                    <span>
                      {NumberUtils.numberToComma(
                        masterLaundryDetail?.bagCount ?? 0
                      )}
                    </span>
                    <span>
                      {NumberUtils.numberToComma(
                        masterLaundryDetail?.outBagCount ?? 0
                      )}
                    </span>

                    <span>-</span>
                    <span>-</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LaundaryStatusDetailBlock>
  );
};

export default MasterLaundaryStatusDetail;

export const LaundaryStatusDetailBlock = styled(StatusDetailBlock)``;
