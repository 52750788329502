import { getStaffDepartmentList } from "api/storeApi";
import StoreStaffInfo from "components/store/StoreStaffInfo";
import { IRootState } from "modules";
import {
  deleteStaffDepartmentDataAction,
  getStaffDepartmentListAction,
  postStoreStaffInfoAction,
  postStoreStaffInfoExcelAction,
} from "modules/store/store";
import {
  DeleteStaffInfoData,
  PersonalInfoList,
  StoreStaffDepartmentListRequest,
} from "modules/store/types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DateFormatUtils from "utils/DateFormatUtils";
import XLSX from "xlsx";

const StoreStaffInfoContainer = () => {
  const dispatch = useDispatch();

  const [currentPageState, setCurrentPageState] =
    useState<StoreStaffDepartmentListRequest>({
      page: 1,
      limit: 10,
      query: "",
      type: "",
    });

  const storeStaffDepartmentList = useSelector(
    (state: IRootState) => state.store.storeStaffDepartmentList
  );

  const userProfile = useSelector((state: IRootState) => state.user.profile);

  useEffect(() => {
    dispatch(getStaffDepartmentListAction(currentPageState));
  }, [dispatch, currentPageState]);

  const staffDepartmentListSearch = (data: StoreStaffDepartmentListRequest) => {
    setCurrentPageState(data);
  };

  const onPageMove = (page: number) => {
    setCurrentPageState((currentPageState) => ({
      ...currentPageState,
      page: page,
    }));
  };

  const selectShowEntries = (limit: number) => {
    setCurrentPageState((currentPageState) => ({
      ...currentPageState,
      page: 1,
      limit,
    }));
  };

  const onExcelDown = () => {
    let item = {
      page: currentPageState.page,
      limit: storeStaffDepartmentList?.totalCount ?? 1, // 전체 갯수로 가져오기
      query: currentPageState.query,
      type: currentPageState.type,
    };

    getStaffDepartmentList(item).then(function (res) {
      let excelData = res.data.data.list;

      if (!excelData) return;
      let excelDataList: any[] = [];
      excelData.forEach((data: any) =>
        excelDataList.push({
          번호: data.userNum,
          부서명: data.departmentName,
          이름: data.userName,
        })
      );
      const ws = XLSX.utils.json_to_sheet(excelDataList);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(
        wb,
        `${userProfile.nickname}_직원정보관리_${DateFormatUtils.dateToFormat(
          new Date()
        )}.xlsx`
      );
    });
  };

  const storeStaffInfoExcelSubmit = (
    data: PersonalInfoList[],
    page: number,
    size: number
  ) => {
    const item = {
      personalInfoList: data,
    };
    dispatch(postStoreStaffInfoExcelAction(item, currentPageState));
  };

  const storeStaffInfoSubmit = (
    data: PersonalInfoList,
    page: number,
    limit: number
  ) => {
    dispatch(postStoreStaffInfoAction(data, currentPageState));
  };

  const storeStaffInfoDelete = (data: number[]) => {
    let item: DeleteStaffInfoData = {
      ids: data,
    };
    dispatch(deleteStaffDepartmentDataAction(item, currentPageState));
  };

  return (
    <StoreStaffInfo
      storeStaffInfoExcelSubmit={storeStaffInfoExcelSubmit}
      storeStaffInfoSubmit={storeStaffInfoSubmit}
      staffDepartmentListSearch={staffDepartmentListSearch}
      storeStaffDepartmentList={storeStaffDepartmentList}
      onPageMove={onPageMove}
      selectShowEntries={selectShowEntries}
      onExcelDown={onExcelDown}
      userProfile={userProfile}
      storeStaffInfoDelete={storeStaffInfoDelete}
    />
  );
};

export default StoreStaffInfoContainer;
