// import { FactoryData } from "./DummyUtils";

class FactoryUtils {
  operationStatusToString(type: string) {
    if (type === "CLOSED") return "운영중단";
    if (type === "OPEN") return "운영중";
  }
}

export default new FactoryUtils();
