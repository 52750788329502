class ScrollUtils {
  onPopupScrollStop(scrollY: number) {
    document.body.style.cssText = `
    position: fixed;
    top: -${scrollY}px;
    overflow-y: scroll;
    width: 100%;
  `;
  }

  closePopupScrollRun(scrollY: string) {
    document.body.style.cssText = "";
    window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
  }
}

export default new ScrollUtils();
