import axios from "axios";
import "dotenv/config";

// const { createProxyMiddleware } = require("http-proxy-middleware");

// createProxyMiddleware({
//   target: "http://localhost:5000",
//   changeOrigin: true,
// });

const baseURL: string = process.env.REACT_APP_BASE_URL ?? "";

const defaultClient = axios.create({
  baseURL,
});

export default defaultClient;
