import Button from "components/common/Button";
import LaundrySearch from "components/common/LaundrySearch";
import Paging from "components/common/Paging";
import {
  MasterGetList,
  MasterLaundryDetail,
  MasterLaundryList,
} from "modules/master/types";
import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { Palette, ThemeColor } from "styles/palette";
import DateFormatUtils from "utils/DateFormatUtils";
import NumberUtils from "utils/NumberUtils";
import MasterLaundaryStatusDetail from "./MasterLaundryStatusDetail";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TypeUtils from "utils/TypeUtils";

interface MasterLaundryStatusProps {
  role: string;
  masterLaundryList: MasterLaundryList | null;
  laundryList: (page: number, data: MasterGetList | null) => void;
  laundryDetail: (id: number) => void;
}

const MasterLaundryStatus: React.FC<MasterLaundryStatusProps> = ({
  role,
  masterLaundryList,
  laundryList,
  laundryDetail,
}) => {
  const [detail, setDetail] = useState(false);
  const [searchData, setSearchData] = useState<MasterGetList>({
    query: "",
    dropBox: "",
    startDate: "",
    endDate: "",
    size: 10,
  });

  const onDetial = (id: number) => {
    laundryDetail(id);
    setDetail(true);
  };

  const onSearch = (page: number, data: MasterGetList) => {
    setSearchData(data);
    laundryList(page, data);
  };

  const pageMove = (page: number) => {
    laundryList(page, searchData ?? null);
  };

  const selectShowEntries = (size: number) => {
    searchData.size = size;
    laundryList(1, searchData ?? null);
  };

  const [listNumber, setListNumber] = useState<number[]>([]);
  useEffect(() => {
    setListNumber(
      NumberUtils.numberToList(
        masterLaundryList?.currentPage ?? 0,
        masterLaundryList?.totalCount ?? 0,
        searchData.size ?? 10
      )
    );
  }, [masterLaundryList, searchData]);

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      fontWeight: 700,
    },
  });
  const classes = useStyles();

  return (
    <MasterLaundryStatusBlock>
      {/* {detail && (
        <MasterLaundaryStatusDetail
          onClose={onClose}
          masterLaundryDetail={masterLaundryDetail}
        />
      )} */}
      <div className="headers">
        <h3>세탁 현황</h3>
      </div>
      <div className="tableSelect">
        <div>
          <span>Show </span>
          <select
            onChange={(e) => selectShowEntries(parseInt(e.target.value, 10))}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
          <span> entries</span>
        </div>
        <div className="topWrapper">
          <span>Search: </span>
          <LaundrySearch role={role} onSearch={onSearch} />
        </div>
      </div>
      <div className="tableWrapper">
        <TableContainer component={Paper} className="tables">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "70px" }}
                >
                  순번
                </TableCell>
                {/* <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "70px" }}
                >
                  구분
                </TableCell> */}
                <TableCell align="center" style={{ fontWeight: 900 }}>
                  세탁신청일
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "15%" }}
                >
                  납품일
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "15%" }}
                >
                  의뢰지점
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "15%" }}
                >
                  신청 포대/카트
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: 900, width: "20%" }}
                >
                  세탁물 수량
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {masterLaundryList?.list.map((list, index) => (
                <TableRow
                  key={index}
                  className="tableHover"
                  onClick={() => onDetial(list.id)}
                >
                  <TableCell component="th" scope="row" align="center">
                    {listNumber[index]}
                  </TableCell>
                  {/* <TableCell component="th" scope="row" align="center">
                    {TypeUtils.laundryRequestType(list.type)}
                  </TableCell> */}
                  <TableCell align="center">
                    {DateFormatUtils.dateToFormat(list.applicationDate)}
                  </TableCell>
                  <TableCell align="center">
                    {DateFormatUtils.dateToFormat(list.completedDate)}
                  </TableCell>
                  <TableCell align="center">{list.franchiseName}</TableCell>
                  <TableCell align="center">
                    {NumberUtils.numberToComma(list.bagCount ?? 0)} 포대/카트
                  </TableCell>
                  <TableCell align="center">
                    {list.laundryLineItemSupports.name} 외{" "}
                    {list.laundryLineItemSupports.categoryTotal}건
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Paging
          listAction={pageMove}
          listCount={masterLaundryList?.totalPage ?? 0}
          currentPage={masterLaundryList?.currentPage}
          totalCount={masterLaundryList?.totalCount}
          size={searchData.size ?? 10}
        ></Paging>
      </div>
      {/* <LaundryStatusListContainer /> */}
    </MasterLaundryStatusBlock>
  );
};

export default MasterLaundryStatus;

const MasterLaundryStatusBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);

  .tables {
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  .tableSelect {
    height: 70px;
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: space-between;
    & > div {
      color: #999;
      height: 40px;

      & > span {
        font-size: 14px;
        line-height: 37px;
      }
      & > select {
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        transition: ease-in-out;
        transition-duration: 0.1s;
      }
      & > select:hover {
        border-bottom: 2px solid ${Palette.primary};
      }
    }
  }
  .topWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    & > span {
      font-size: 14px;
      line-height: 37px;
      margin-right: 5px;
    }
    & > div {
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div {
        margin: 0;
        margin-left: 1rem;
      }
    }
  }
  .tableWrapper {
    min-width: 51rem;
    padding-bottom: 1.5rem;
  }
  .tableHover:hover {
    background-color: #eee;
    cursor: pointer;
  }
`;
