import React, { useState } from "react";
import styled from "styled-components";
import Button from "components/common/Button";
import { Palette, ThemeColor } from "styles/palette";
import RoleUtils from "utils/RoleUtils";
import TypeUtils from "utils/TypeUtils";
import {
  IExcelData,
  ITransExcelData,
  ProfileDetail,
  SpecificationsDetail,
  StoreContract,
} from "modules/user/types";
import { useHistory } from "react-router";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "modules";
import DateFormatUtils from "utils/DateFormatUtils";
import XLSX from "xlsx";
import PriceUtils from "utils/PriceUtils";
import NumberUtils from "utils/NumberUtils";

interface TransactionTableProps {
  role: string;

  selectDetailTitle?: {
    title: string;
    name: string;
    date: string;
    chartType: string;
  };
  specificationsDetailData?: SpecificationsDetail | null;
  onConfirm?: (id: number) => void;
  patchSujungRequest?: (id: number) => void;
  factoryProfileDetail?: ProfileDetail | null;
  onDeleteStatement?: (id: number, type: string) => void;
  historyBack?: () => void;

  excelFile: IExcelData | null;
  transExcelFile: ITransExcelData | null;
  getExcelData: () => void;
  getTransExcelData: () => void;
}

type TransactionTableBlockProps = {
  chartDataCheck: string;
};

const TransactionTable: React.FC<TransactionTableProps> = (props) => {
  const [chartType, setChartType] = useState<string>("specifi");
  const [searchDate, setSearchDate] = useState<string>("");
  const [sujungModal, setSujungModal] = useState<boolean>(false);
  const history = useHistory();

  // const name = useSelector((state: IRootState) => state.user.profile.name);

  useEffect(() => {
    if (props.selectDetailTitle?.title.match("세탁집계표")) setChartType("aggregation");
    if (props.selectDetailTitle?.title.match("거래명세표")) setChartType("specifi");

    let data = props.selectDetailTitle?.title.substr(5, props.selectDetailTitle?.title.length);
    setSearchDate(data ?? "");
  }, [props.selectDetailTitle]);

  // 엑셀 데이터 가져오기 작업
  useEffect(() => {
    if (props.selectDetailTitle?.title.match("세탁집계표")) {
      props.getExcelData();
    }
    if (props.selectDetailTitle?.title.match("거래명세표")) {
      props.getTransExcelData();
    }
  }, [props.selectDetailTitle?.title]);

  ////////////테스트용 Selector 지우는게 좋을듯?////////////
  const test = useSelector((state: IRootState) => state.user.profileDetail);
  // console.log(test);
  ////////////////////////////////////////////////////////////

  const handleSave = async () => {
    const pages: any = document.querySelectorAll(".pdfWrapper");

    let canvases: any[] = [];
    for (let i = 0; i < pages.length; i++) {
      const canvas = await html2canvas(pages[i], {
        scale: 2,
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
      });
      canvases.push(canvas);
    }

    let pdf = new jsPDF({
      orientation: chartType === "aggregation" ? "landscape" : "portrait",
      unit: "mm",
      format: "a4",
    });
    const margin = 0;
    const position = 0;
    const width = chartType === "aggregation" ? 297 : 210;
    const height = chartType === "aggregation" ? 210 : 297;

    for (let i = 0; i < canvases.length; i++) {
      const imgData = canvases[i].toDataURL("image/jpeg");
      pdf.addImage(imgData, "png", margin, position, width, height, undefined, "FAST");
      i < canvases.length - 1 && pdf.addPage();
    }

    let saveKind = "거래명세표";
    if (chartType === "aggregation") saveKind = "세탁집계표";

    pdf.save(`${props.selectDetailTitle?.name}_${props.selectDetailTitle?.title}`);
  };

  const boardRegistration = (id: number) => {
    props.patchSujungRequest!(id);
    history.push(`/store/board`);
  };

  const onConfirmCheck = (id: number) => {
    if (window.confirm("확인하시겠습니까?")) {
      let saveKind = "거래명세표";
      if (chartType === "aggregation") saveKind = "세탁집계표";
      props.onConfirm!(id);
      alert(`${saveKind}에 납품 확인하였습니다.`);
    }
  };

  const onModal = () => {
    setSujungModal(!sujungModal);
  };

  const onDeleteStatement = (id: number) => {
    if (window.confirm("삭제하시겠습니까?")) {
      props.onDeleteStatement!(id, "list");
    }
  };

  const excelDown = () => {
    //excelList => 총 엑셀 결과문 if else문으로 분류되서 입력됨

    let excelList = [];

    if (chartType !== "aggregation") {
      if (!props.transExcelFile) return;

      //거래명세표 Excel
      let datas = props.transExcelFile?.assetDetailsList
        ? props.transExcelFile?.assetDetailsList
        : [];

      let totalCount = 0;
      let totalWeight = 0;
      let totalRelaundry = 0;
      let totalPrice = 0;
      let count = 1;
      for (let i = 0; i < datas.length; i++) {
        for (let j = 0; j < datas[i].subcategory.length; j++) {
          let data = {};
          if (props.selectDetailTitle?.chartType === "specifi3") {
            data = {
              대분류: datas[i].largeCategory,
              품목명: datas[i].subcategory[j].name,
              "납품수량(개)": datas[i].subcategory[j].amount,
              "납품중량(kg)": datas[i].subcategory[j].deliveryWeight,
              "비고(개)": datas[i].subcategory[j].reLaundry,
              세탁비:
                props.transExcelFile?.franchiseResponse.contract.basis === "UNIT_PRICE"
                  ? datas[i].subcategory[j].price
                  : 0,
            };
          } else {
            data = {
              대분류: datas[i].largeCategory,
              품목명: datas[i].subcategory[j].name,
              "중량(kg)": datas[i].subcategory[j].weight,
              "납품수량(개)": datas[i].subcategory[j].amount,
              "납품중량(kg)": datas[i].subcategory[j].deliveryWeight,
              "비고(개)": datas[i].subcategory[j].reLaundry,
            };
          }
          excelList.push(data);
          count++;
        }

        let totalData = {};
        if (props.selectDetailTitle?.chartType === "specifi3") {
          totalData = {
            대분류: datas[i].largeCategory,
            품목명: "소계",
            "납품수량(개)": datas[i].count,
            "납품중량(kg)": datas[i].deliveryWeight,
            "비고(개)": datas[i].reLaundry,
            세탁비:
              props.transExcelFile?.franchiseResponse.contract.basis === "UNIT_PRICE"
                ? datas[i].price
                : 0,
          };
        } else {
          totalData = {
            대분류: datas[i].largeCategory,
            품목명: "소계",
            "납품수량(개)": datas[i].count,
            "납품중량(kg)": datas[i].deliveryWeight,
            "비고(개)": datas[i].reLaundry,
          };
        }

        totalCount += datas[i].count;
        totalWeight += datas[i].deliveryWeight;
        totalRelaundry += datas[i].reLaundry;
        totalPrice += datas[i].price;
        excelList.push(totalData);
      }
      let finalData = {};
      if (props.selectDetailTitle?.chartType === "specifi3") {
        finalData = {
          대분류: "총 합계",
          "납품수량(개)": totalCount,
          "납품중량(kg)": totalWeight,
          "비고(개)": totalRelaundry,
          세탁비:
            props.transExcelFile?.franchiseResponse.contract.basis === "UNIT_PRICE"
              ? totalPrice
              : props.transExcelFile?.franchiseResponse.contract.price,
        };
      } else {
        finalData = {
          대분류: "총 합계",
          "납품수량(개)": totalCount,
          "납품중량(kg)": totalWeight,
          "비고(개)": totalRelaundry,
        };
      }

      excelList.push(finalData);
    } else {
      console.log(props.excelFile);
      if (!props.excelFile) return;
      //세탁표 Excel
      let datas = props.excelFile?.list ? props.excelFile?.list : [];

      let totalCountData = 0;
      let totalPriceData = 0; //전체 데이터 배열

      //엑셀 헤더 부분
      let dateHeader = [];
      for (let i = 0; i < datas[0].list.length; i++) {
        dateHeader.push(DateFormatUtils.dateSimple(datas[0].list[i].date));
      }

      //엑셀 데이터 입력
      for (let i = 0; i < datas.length; i++) {
        let data: any = {
          No: i + 1,
          품목: datas[i].title,
        };
        let totalCount = 0;
        for (let j = 0; j < datas[0].list.length; j++) {
          data[dateHeader[j]] = datas[i].list[j].count;
          totalCount += datas[i].list[j].count;
        }
        data["총 수량"] = totalCount;
        totalCountData += totalCount;

        data["단가"] = NumberUtils.numberToComma(datas[i].price);
        totalPriceData += datas[i].price * totalCount;
        data["총 발생액"] = NumberUtils.numberToComma(totalCount * datas[i].price);

        excelList.push(data);
      }

      //엑셀 첫번째 줄 총합 데이터 입력
      let totalData: any = {
        No: "",
        품목: "합계",
      };

      for (let i = 0; i < dateHeader.length; i++) {
        let total = 0;
        for (let j = 0; j < datas.length; j++) {
          total = total + datas[j].list[i].count;
        }

        totalData[dateHeader[i]] = total;
      }

      totalData["총 수량"] = NumberUtils.numberToComma(totalCountData);

      totalData["단가"] = "-";

      totalData["총 발생액"] =
        props.excelFile.contract.basis !== "UNIT_PRICE"
          ? NumberUtils.numberToComma(props.excelFile.contract.price)
          : NumberUtils.numberToComma(totalPriceData);

      excelList.splice(0, 0, totalData);
    }

    let saveKind = "거래명세표";
    if (chartType === "aggregation") saveKind = "세탁집계표";

    const ws = XLSX.utils.json_to_sheet(excelList);

    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    XLSX.writeFile(wb, `${props.selectDetailTitle?.name}_${props.selectDetailTitle?.title}.xlsx`);
  };

  return (
    <>
      <TransactionTableBlock chartDataCheck={chartType}>
        <header className="headers">
          <h3>거래명세표</h3>{" "}
          <Button theme={ThemeColor.primary} buttonSize="m" onClick={props.historyBack}>
            뒤로가기
          </Button>
        </header>
        <section>
          <figure className="pdfWrapper">
            {props.specificationsDetailData?.image && (
              <img src={props.specificationsDetailData?.image} alt="transaction statement" />
            )}
            {props.specificationsDetailData?.imagePath && (
              <img
                src={
                  process.env.REACT_APP_BASE_URL_IMAGE + props.specificationsDetailData?.imagePath
                }
                alt="transaction statement"
              />
            )}
          </figure>
          <div className={"btnWrap"}>
            <div className={"status"}>
              <span>
                납품확인 : {TypeUtils.statusTypeText(props.specificationsDetailData?.confirmation!)}
              </span>
              <strong></strong>
            </div>

            <div className={"btn"}>
              <Button theme={ThemeColor.primary} buttonSize="m" onClick={excelDown}>
                엑셀저장
              </Button>
              <Button theme={ThemeColor.primary} buttonSize="m" onClick={handleSave}>
                PDF저장
              </Button>
              {RoleUtils.isStore(props.role) && (
                <>
                  <Button theme={ThemeColor.primary} buttonSize="m" onClick={onModal}>
                    수정요청
                  </Button>
                  <Button
                    theme={ThemeColor.primary}
                    buttonSize="m"
                    onClick={() => onConfirmCheck(Number(props.specificationsDetailData?.id))}
                  >
                    확인
                  </Button>
                </>
              )}
              {RoleUtils.isFactory(props.role) && (
                <Button
                  theme={ThemeColor.primary}
                  buttonSize="m"
                  onClick={() => onDeleteStatement(props.specificationsDetailData?.id!)}
                >
                  삭제/회수
                </Button>
              )}
            </div>
          </div>
        </section>
      </TransactionTableBlock>
      {sujungModal && (
        <TablePopupBlock>
          <div>
            <div className="info">
              <div className="title">수정 요청 안내</div>
              <div>
                <span>수정에 대한 자세한 내용은</span>
                <span>세탁 게시판에 작성해주세요.</span>
              </div>
            </div>
            <div>
              <div onClick={onModal}>취소</div>
              <div onClick={() => boardRegistration(props.specificationsDetailData?.id!)}>
                게시판으로 이동
              </div>
            </div>
          </div>
        </TablePopupBlock>
      )}
    </>
  );
};

export default TransactionTable;

const TablePopupBlock = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  & > div {
    width: 400px;
    height: 300px;
    background-color: #fff;
    border-radius: 7px;
    position: relative;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    & > div:nth-child(1) {
      width: 100%;
      flex: 1;
      & > .title {
        width: 100%;
        height: 50px;
        line-height: 50px;
        margin-top: 40px;
        font-size: 22px;
        font-weight: bold;
        text-align: center;
      }
      & > div {
        margin-top: 30px;
        text-align: center;
        line-height: 30px;
        & > span {
          display: block;
        }
      }
    }
    & > div:nth-child(2) {
      width: 100%;
      height: 60px;
      background-color: ${Palette.primary};
      & > div {
        width: 50%;
        height: 100%;
        display: inline-block;
        text-align: center;
        line-height: 60px;
        font-size: 18px;
        background-color: #ddd;
        cursor: pointer;
      }
      & > div:nth-child(2) {
        background-color: ${Palette.primary};
        color: #fff;
      }
    }
  }
`;

export const TransactionTableBlock = styled.div<TransactionTableBlockProps>`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  & > .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  & > section {
    width: 100%;
    position: relative;
    margin-top: 75px;
    padding-bottom: 40px;
    & > figure {
      width: auto;
      min-height: 600px;
      margin: 0 auto;
      border: 1px solid #000;

      & > img {
        width: 100%;
        /* object-fit: contain; */
      }
    }
    & > .btnWrap {
      width: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: fit-content;
      position: absolute;
      top: -60px;
      right: 0;
      & > .status {
        width: 150px;
        text-align: right;
        margin-right: 20px;
      }
      & > .btn {
        width: auto;

        & > div {
          /* margin-top: 20px; */
          /* display: flex; */
        }
      }
    }
  }
`;
