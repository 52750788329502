import React, { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Palette } from "styles/palette";

interface MonthCalendarTypes {
  dateChange: (e: moment.Moment) => void;
  date: moment.Moment;
}

function MonthCalendar(props: MonthCalendarTypes) {
  const [date, setDate] = useState<moment.Moment>(() => moment());

  useEffect(() => {
    setDate(props.date);
  }, [props.date]);

  const jumpToYear = (num: number) =>
    num
      ? props.dateChange(date.clone().add(1, "year"))
      : props.dateChange(date.clone().subtract(1, "year"));

  const onSelectMonth = (month: number) => {
    let year = date.clone().get("year");
    let data = moment(`${year}-${month}-01`);

    props.dateChange(data);
  };

  const onSelectMonthCheck = (num: number) => {
    if (moment(date).get("month") + 1 === num) return true;
    return false;
  };

  return (
    <MonthCalendarBlock>
      <CalendarHead>
        <div className="head">
          <span className="title">{date.format("YYYY")}</span>
          <div className="util-button">
            <button onClick={() => jumpToYear(0)}>
              <NavigateBeforeIcon />
            </button>
            {/* <button onClick={returnToday}>Today</button> */}
            <button onClick={() => jumpToYear(1)}>
              <NavigateNextIcon />
            </button>
          </div>
        </div>
      </CalendarHead>
      <CalendarBody>
        <div>
          <div
            className={`${onSelectMonthCheck(1) && "selectMonth"}`}
            onClick={() => onSelectMonth(1)}
          >
            1월
          </div>
          <div
            className={`${onSelectMonthCheck(2) && "selectMonth"}`}
            onClick={() => onSelectMonth(2)}
          >
            2월
          </div>
          <div
            className={`${onSelectMonthCheck(3) && "selectMonth"}`}
            onClick={() => onSelectMonth(3)}
          >
            3월
          </div>
        </div>
        <div>
          <div
            className={`${onSelectMonthCheck(4) && "selectMonth"}`}
            onClick={() => onSelectMonth(4)}
          >
            4월
          </div>
          <div
            className={`${onSelectMonthCheck(5) && "selectMonth"}`}
            onClick={() => onSelectMonth(5)}
          >
            5월
          </div>
          <div
            className={`${onSelectMonthCheck(6) && "selectMonth"}`}
            onClick={() => onSelectMonth(6)}
          >
            6월
          </div>
        </div>
        <div>
          <div
            className={`${onSelectMonthCheck(7) && "selectMonth"}`}
            onClick={() => onSelectMonth(7)}
          >
            7월
          </div>
          <div
            className={`${onSelectMonthCheck(8) && "selectMonth"}`}
            onClick={() => onSelectMonth(8)}
          >
            8월
          </div>
          <div
            className={`${onSelectMonthCheck(9) && "selectMonth"}`}
            onClick={() => onSelectMonth(9)}
          >
            9월
          </div>
        </div>
        <div>
          <div
            className={`${onSelectMonthCheck(10) && "selectMonth"}`}
            onClick={() => onSelectMonth(10)}
          >
            10월
          </div>
          <div
            className={`${onSelectMonthCheck(11) && "selectMonth"}`}
            onClick={() => onSelectMonth(11)}
          >
            11월
          </div>
          <div
            className={`${onSelectMonthCheck(12) && "selectMonth"}`}
            onClick={() => onSelectMonth(12)}
          >
            12월
          </div>
        </div>
      </CalendarBody>
    </MonthCalendarBlock>
  );
}

export default MonthCalendar;

const MonthCalendarBlock = styled.div`
  width: 100%;
`;

const CalendarHead = styled.div`
  & > .head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    & > span {
      line-height: 40px;
      font-weight: bold;
    }
    & > div {
      display: flex;
      line-height: 40px;
      & > button {
        height: 40px;
        border: 0;
        background-color: transparent;
        cursor: pointer;
      }
    }
  }
`;

const CalendarBody = styled.div`
  width: 100%;
  height: auto;
  & > div {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    & > div {
      width: calc(100% / 3 - 6px);
      background-color: #fff;
      padding: 7px 0;
      border-radius: 8px;
      font-weight: 500;
      font-size: 16px;
      color: ${Palette.primary};
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: 1px solid ${Palette.primary};
      &:hover {
        background-color: ${Palette.primary};
        color: #fff;
        font-weight: 800;
      }
    }
  }
  .selectMonth {
    background-color: ${Palette.primary};
    color: #fff;
    font-weight: 800;
  }
`;
