import MasterExcelUpload from "components/master/MasterExcelUpload";
import { IRootState } from "modules";
// import {
//     getMasterDashBoardBagCountLineChartAction,
//     getMasterDashBoardItemDountChartAction,
//     getMasterDashBoardLaundryDountChartAction,
//     getMasterDashBoardLaundryLineChartAction,
// } from "modules/master/master";
import React from "react";
import { useSelector } from "react-redux";

interface MasterDashBoardContainerProps {}

const MasterDashBoardContainer: React.FC<MasterDashBoardContainerProps> =
  () => {
    const role = useSelector((state: IRootState) => state.user.profile?.type);

    return <MasterExcelUpload role={role} />;
  };

export default MasterDashBoardContainer;
