import AdminDashBoard from "components/admin/AdminDashBoard";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "modules";
import { getAdminDashboardAction } from "modules/admin/admin";
import { useState } from "react";
import DateFormatUtils from "utils/DateFormatUtils";
import { GraphKindTapType } from "interface/Admin/Dashboard/DashboardTypes";

interface AdminDashBoardContainerProps {}

const AdminDashBoardContainer: React.FC<AdminDashBoardContainerProps> = () => {
  const dispatch = useDispatch();
  const [timeNow, setTimeNow] = useState<Date>(new Date());
  const [selectDay, setSelectDay] = useState<string>();
  const [kindTap, setKindTap] = useState<GraphKindTapType>({
    keyType: "laundry",
    graphName: "예상매출",
    unit: "원",
  });

  const adminDashboard = useSelector((state: IRootState) => state.admin.adminDashBoard);

  // useEffect(() => {
  //   setInterval(() => {
  //     dispatch(
  //       getAdminDashboardAction(
  //         selectDay ?? DateFormatUtils.dateToFormat(new Date())
  //       )
  //     );
  //     setTimeNow(new Date());
  //   }, 5 * 60 * 1000);
  // }, []);

  useEffect(() => {
    dispatch(getAdminDashboardAction(DateFormatUtils.dateToFormat(new Date()), kindTap.keyType));
    setTimeNow(new Date());
  }, [dispatch]);

  const getDashboard = (day: string) => {
    setSelectDay(day);
    dispatch(getAdminDashboardAction(day, kindTap.keyType));
    setTimeNow(new Date());
  };

  const onKindTap = (data: GraphKindTapType) => {
    setKindTap(data);
    dispatch(
      getAdminDashboardAction(selectDay ?? DateFormatUtils.dateToFormat(new Date()), data.keyType)
    );
  };

  console.log(adminDashboard);

  return (
    <AdminDashBoard
      adminDashboard={adminDashboard}
      timeNow={timeNow}
      getDashboard={getDashboard}
      kindTap={kindTap}
      onKindTap={onKindTap}
    />
  );
};

export default AdminDashBoardContainer;
