import MasterDashBoard from "components/master/MasterDashBoard";
import { IRootState } from "modules";
import { getMasterDashboardAction } from "modules/master/master";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DateFormatUtils from "utils/DateFormatUtils";

interface MasterDashBoardContainerProps {}

const MasterDashBoardContainer: React.FC<MasterDashBoardContainerProps> =
  () => {
    const [timeNow, setTimeNow] = useState<Date>(new Date());
    const [selectDay, setSelectDay] = useState<string>();
    const masterDashboard = useSelector(
      (state: IRootState) => state.master.masterDashBoard
    );
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(
        getMasterDashboardAction(DateFormatUtils.dateToFormat(new Date()))
      );
      setTimeNow(new Date());
    }, []);

    // useEffect(() => {
    //   setInterval(() => {
    //     dispatch(
    //       getMasterDashboardAction(
    //         selectDay ?? DateFormatUtils.dateToFormat(new Date())
    //       )
    //     );
    //     setTimeNow(new Date());
    //   }, 5 * 60 * 1000);
    // }, []);

    const getDashboard = (day: string) => {
      setSelectDay(day);
      dispatch(getMasterDashboardAction(day));
      setTimeNow(new Date());
    };

    return (
      <MasterDashBoard
        masterDashboard={masterDashboard}
        getDashboard={getDashboard}
        timeNow={timeNow}
      />
    );
  };

export default MasterDashBoardContainer;
