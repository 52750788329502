import AdminBrand from "components/admin/AdminBrand";
import AdminBrandStatusDetail from "components/admin/AdminBrandDetail";
import AdminBrandRegistrationLayout from "components/admin/AdminBrandRegistrationLayouy";
import { IRootState } from "modules";
import {
  deleteAdminBrandAction,
  getAdminBrandDetailAction,
  // getAdminBrandDetailAction,
  getAdminBrandListAction,
  getIdDuplicateCheckAction,
  getIdDuplicationCheckCancelAction,
  patchAdminUserNameModifyAction,
  patchBrandInfoAction,
  postAdminBrandRegistrationAction,
  postPWInitializationAction,
} from "modules/admin/admin";
import { AdminGetList, AdminRegistration } from "modules/admin/types";
import { ProfileModify } from "modules/user/types";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import RoleUtils from "utils/RoleUtils";

interface AdminBrandContainerProps {
  match: any;
}

const AdminBrandContainer: React.FC<AdminBrandContainerProps> = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectType, setSelectType] = useState<string>("list");
  const [detailId, setDetailId] = useState<number | null>(null);
  const [auth, setAuth] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchData, setSearchData] = useState<AdminGetList>({
    query: "",
    dropBox: "",
    startDate: "",
    endDate: "",
    size: 10,
  });

  const adminBrandList = useSelector((state: IRootState) => state.admin.adminBrandList);
  const adminBrandDetail = useSelector((state: IRootState) => state.admin.adminBrandDetail);
  const adminIdDuplicateCheck = useSelector(
    (state: IRootState) => state.admin.adminIdDuplicateCheck
  );

  const type = useSelector((state: IRootState) => state.user.profile.type);
  const brandRegistration = (payload: AdminRegistration, data: AdminGetList) => {
    dispatch(postAdminBrandRegistrationAction(payload, data));
  };

  const brandDetailStatus = (id: number) => {
    setDetailId(id);
    setSelectType("detail");
    dispatch(getAdminBrandDetailAction(id));
    history.push(`/admin/brand/${id}`);
  };

  const brandRegistrationStatus = () => {
    setSelectType("add");
    history.push(`/admin/brand/add`);
  };

  const brandListPageMove = (page: number, data: AdminGetList | null) => {
    if (data) setSearchData(data);
    setCurrentPage(page);
    dispatch(getAdminBrandListAction(page, data));
  };

  useEffect(() => {
    let authority = "";
    if (RoleUtils.isMasterAdmin(type)) authority = "admin";
    if (RoleUtils.isMaster(type)) authority = "master";
    if (RoleUtils.isStore(type)) authority = "store";
    if (RoleUtils.isFactory(type)) authority = "factory";
    setAuth(authority);
  }, [type]);

  useEffect(() => {
    if (match.params.id && Number(match.params.id)) {
      setDetailId(match.params.id);
      setSelectType("detail");
      dispatch(getAdminBrandDetailAction(match.params.id));
    } else {
      setSelectType("list");
    }
  }, [match.params.id]);

  const query = window.location.pathname;

  useEffect(() => {
    if (query === `/${auth}/brand/${match.params.id}`) setSelectType("detail");
    if (query === `/${auth}/brand`) setSelectType("list");
  }, [query, auth]);

  useEffect(() => {
    dispatch(getAdminBrandListAction(1, null));
  }, [dispatch]);

  const modifyBrandUserName = (id: number, userName: string) => {
    dispatch(patchAdminUserNameModifyAction(id, userName, currentPage, searchData, "brand"));
  };

  const postPWInitialization = (id: number) => {
    if (window.confirm("비밀번호를 초기화 하시겠습니까?")) dispatch(postPWInitializationAction(id));
  };

  const historyBack = () => {
    setDetailId(null);
    // setSelectType("list");
    history.push(`/admin/brand`);
  };

  const adminBrandRegistration = (payload: AdminRegistration) => {
    brandRegistration(payload, searchData ?? null);
  };

  const brandInfoModify = (payload: ProfileModify) => {
    dispatch(patchBrandInfoAction(detailId!, payload, currentPage, searchData));
  };

  const duplicateCheck = (id: string) => {
    dispatch(getIdDuplicateCheckAction(id));
  };

  const duplicateCheckCancel = () => {
    dispatch(getIdDuplicationCheckCancelAction());
  };

  /** 브랜드 삭제 */
  const onDelete = () => {
    if (detailId) dispatch(deleteAdminBrandAction(detailId, currentPage, searchData));
    history.goBack();
  };

  return (
    <>
      {selectType === "list" && (
        <AdminBrand
          role={type}
          brandRegistrationStatus={brandRegistrationStatus}
          brandRegistration={brandRegistration}
          adminBrandList={adminBrandList}
          adminBrandDetail={adminBrandDetail}
          brandListPageMove={brandListPageMove}
          brandDetailStatus={brandDetailStatus}
          modifyBrandUserName={modifyBrandUserName}
          postPWInitialization={postPWInitialization}
        />
      )}
      {selectType === "detail" && (
        <AdminBrandStatusDetail
          adminBrandDetail={adminBrandDetail}
          // onClose={onClose}
          brandInfoModify={brandInfoModify}
          modifyBrandUserName={modifyBrandUserName}
          postPWInitialization={postPWInitialization}
          historyBack={historyBack}
          adminIdDuplicateCheck={adminIdDuplicateCheck}
          duplicateCheckRequest={duplicateCheck}
          duplicateCheckCancel={duplicateCheckCancel}
          onDelete={onDelete}
        />
      )}
      {selectType === "add" && (
        <AdminBrandRegistrationLayout
          brandRegistration={adminBrandRegistration}
          historyBack={historyBack}
          adminIdDuplicateCheck={adminIdDuplicateCheck}
          duplicateCheckRequest={duplicateCheck}
          duplicateCheckCancel={duplicateCheckCancel}
        />
      )}
    </>
  );
};

export default AdminBrandContainer;
