import React, { useState } from "react";
import styled from "styled-components";
import { ThemeColor } from "styles/palette";
import { LaundrySearchBlock } from "./LaundrySearch";
import Button from "./Button";

interface AccessHistorySearchProps {
  onSearch: (query: string) => void;
}

const AccessHistorySearch: React.FC<AccessHistorySearchProps> = (props) => {
  const [searchQuery, setSearchQuery] = useState<string>("");

  const submitSearch = (e: any) => {
    e.preventDefault();
    props.onSearch(searchQuery);
  };

  const onQueryChange = (e: any) => {
    e.preventDefault();
    setSearchQuery(e.target.value);
  };

  return (
    <AccessHistorySearchBlock onSubmit={(e) => submitSearch(e)}>
      <input
        className="textBox"
        value={searchQuery ?? ""}
        onChange={(e) => onQueryChange(e)}
      />
      <Button
        theme={ThemeColor.primary}
        buttonSize="m"
        onKeyUp={(e) => submitSearch(e)}
        className="buttonSize"
      >
        검색
      </Button>
    </AccessHistorySearchBlock>
  );
};

export default AccessHistorySearch;

const AccessHistorySearchBlock = styled(LaundrySearchBlock)``;
