import Sidebar from "components/common/sideBar/Sidebar";
import HeaderContainer from "containers/common/header/HeaderContainer";
import React, { useRef, useState } from "react";
import { layoutHeightAction, logoutAction } from "modules/user/user";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  factorySubNavigationList,
  masterAdminSubNavigationList,
  masterSubNavigationList,
  storeSubNavigationList,
} from "constants/subNavigation";
import RoleUtils from "utils/RoleUtils";
import { IRootState } from "modules";
import { useEffect } from "react";

interface AppLayoutProps {}

const AppLayout: React.FC<AppLayoutProps> = ({ children }, props) => {
  const dispatch = useDispatch();
  const username = useSelector(
    (state: IRootState) => state.user.profile.username
  );
  const type = useSelector((state: IRootState) => state.user.profile.type);
  const name = useSelector((state: IRootState) => state.user.profile.name);

  const onLogout = () => {
    dispatch(logoutAction());
  };

  const [testType, setTestType] = useState<string>("large");
  const [userInfo, setUserInfo] = useState<boolean>(false);
  const [alarmInfo, setAlarmInfo] = useState<boolean>(false);

  const sizeButton = () => {
    let size = "large";
    if (testType === "large") size = "small";
    setTestType(size);
  };

  const userInfoBtn = () => {
    setUserInfo(!userInfo);
  };

  const alarmInfoBtn = () => {
    setAlarmInfo(!alarmInfo);
  };

  const placeHeight = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(layoutHeightAction(Number(placeHeight.current?.offsetHeight)));
  }, [placeHeight.current?.offsetHeight, dispatch]);

  return (
    <AppLayoutBlock
      onClick={() => {
        userInfo && setUserInfo(false);
        alarmInfo && setAlarmInfo(false);
      }}
    >
      {RoleUtils.isMasterAdmin(type) && (
        <Sidebar
          testType={testType}
          role={type}
          name={name}
          username={username!}
          onLogout={onLogout}
          navigationList={masterAdminSubNavigationList}
        />
      )}
      {RoleUtils.isMaster(type) && (
        <Sidebar
          testType={testType}
          role={type}
          name={name}
          username={username!}
          onLogout={onLogout}
          navigationList={masterSubNavigationList}
        />
      )}
      {RoleUtils.isStore(type) && (
        <Sidebar
          testType={testType}
          role={type}
          name={name}
          username={username!}
          onLogout={onLogout}
          navigationList={storeSubNavigationList}
        />
      )}
      {RoleUtils.isFactory(type) && (
        <Sidebar
          testType={testType}
          role={type}
          name={name}
          username={username!}
          onLogout={onLogout}
          navigationList={factorySubNavigationList}
        />
      )}
      {type === "GUEST" && <div></div>}
      <article ref={placeHeight}>
        <div className="contentWrapper">{children}</div>
      </article>{" "}
      <HeaderContainer
        sizeButton={sizeButton}
        userInfoBtn={userInfoBtn}
        userInfo={userInfo}
        alarmInfoBtn={alarmInfoBtn}
        alarmInfo={alarmInfo}
      />
    </AppLayoutBlock>
  );
};

export default AppLayout;

const AppLayoutBlock = styled.div`
  display: flex;
  height: inherit;
  min-width: 1300px;
  width: auto;
  background-color: #ecf0f5;
  /* top: 0; */
  // contentWrapper 부분이 헤더를 뺀 나머지를 잡아야 하는데 전체 높이를 잡고 있어서
  // 그 높이 만큼 밑으로 밀려떨어진 것으로 보입니다.
  // 대략 헤더부분 만큼을 제외한 수치를 입력하니 상세보기랑 admin 페이지가 정상적으로 보입니다.

  & > article {
    /* display: flex; */
    z-index: 2;
    width: 100%;
    height: 100vh;
    padding: 0 22px;
    overflow-y: auto;
    //-ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none; /* Firefox */
    //&::-webkit-scrollbar {
    //   display: none; /* Chrome, Safari, Opera*/
    //}
    & > .contentWrapper {
      position: relative;
      /* padding: 1rem; */
      width: 100%;
      top: 60px;
      height: auto;
      padding-bottom: 26px;

      /* background-color: lightgreen; */
      //overflow-y: scroll;
      //-ms-overflow-style: none; /* IE and Edge */
      //scrollbar-width: none; /* Firefox */
    }
    .contentWrapper::-webkit-scrollbar {
      // display: none; /* Chrome, Safari, Opera*/
    }
  }
`;
