import axios from "api/defaultClient";
import {
  DeleteStaffInfoData,
  FranchiseAddBagCount,
  FranchiseAddItem,
  FranchiseGetItem,
  FranchiseGetList,
  // FranchiseItem,
  FranchiseItemModify,
  LabelStaffDepartmentRequest,
  PersonalInfoList,
  StoreItemExcelUpload,
  StoreStaffDepartmentList,
  StoreStaffDepartmentListRequest,
  StoreStaffInfoExcelUpload,
} from "modules/store/types";
import { ProfileModify } from "modules/user/types";

export const getDashBoard = (day: string) => {
  return axios.get(`/franchise/dashboard?date=${day}`);
};
export const getDashBoardLaundryDountChart = () => {
  return axios.get(`/franchise/dashboard/laundry`);
};
export const getDashBoardItemDountChart = () => {
  return axios.get(`/franchise/dashboard/asset`);
};
export const getDashBoardBagCountLineChart = () => {
  return axios.get(`/franchise/dashboard/bagcount`);
};
export const getDashBoardLaundryLineChart = () => {
  return axios.get(`/franchise/dashboard/weeklundry`);
};

export const getBillingDetail = async (id: number) => {
  return await axios.get(`/franchise/billing/${id}`);
};

export const billingList = (page: number, data: FranchiseGetList | null) => {
  if (data)
    return axios.get(
      `/franchise/billings?page=${page}&query=${data?.query}&dropBox=${data?.dropBox}&size=${data.size}`
    );
  if (!data) return axios.get(`/franchise/billings?page=${page}`);
};

export const laundryList = (page: number, data: FranchiseGetList | null) => {
  if (data)
    return axios.get(
      `/franchise/laundry?page=${page}&query=${data?.query}&startDate=${data?.startDate}&endDate=${data?.endDate}&dropBox=${data?.dropBox}&size=${data.size}`
    );
  if (!data) return axios.get(`/franchise/laundry?page=${page}`);
};

export const getFranchiseDetailLaundry = (id: number) => {
  return axios.get(`/franchise/laundry/${id}`);
};

export const getFranchiseLaundryFactory = () => {
  return axios.get(`/franchise/factory`);
};

export const addFranchiseLaundryOrder = (bagCount: FranchiseAddBagCount) => {
  return axios.post(`/franchise/laundry`, bagCount);
};

export const getFranchiseItemList = (page: number, size: number) => {
  return axios.get(`/franchise/asset?page=${page}&size=${size}`);
};

interface FranchiseItemData {
  type: string;
  dataLink: string;
}

export const getFranchiseItemData = (payload: FranchiseGetItem) => {
  let requestData = "";

  const FranchiseItemDataList: FranchiseItemData[] = [
    { type: "large", dataLink: `&large=${payload.large}` },
    { type: "sub", dataLink: `&sub=${payload.sub}` },
    { type: "name", dataLink: `&detailId=${payload.name}` },
  ];

  const isType = (data: FranchiseItemData, type: string | undefined) => {
    if (data.type === type) return true;
  };

  for (var i = 0; i < FranchiseItemDataList.length; i++) {
    if (isType(FranchiseItemDataList[i], payload.type)) break;
    requestData += FranchiseItemDataList[i].dataLink;
  }

  return axios.get(`/franchise/asset-details-two?type=${payload.type}${requestData}`);
};

export const patchFranchiseItemModify = (payload: FranchiseItemModify) => {
  return axios.patch(`/franchise/asset/${payload.id}`, {
    plusAmount: payload.plusAmount,
    minusAmount: payload.minusAmount,
  });
};

export const deleteFranchiseItem = (id: number) => {
  return axios.delete(`/franchise/asset/${id}`);
};

export const addFranchiseItem = (franchiseAddItem: FranchiseAddItem) => {
  return axios.post(`/franchise/asset`, franchiseAddItem);
};

export const getFranchiseMe = () => {
  return axios.get(`/franchise/me`);
};

export const patchFranchiseProfileModify = (payload: ProfileModify) => {
  return axios.patch(`/franchise/updateme`, payload);
};

export const getFranchiseSpecificationsList = (
  id: number | null,
  page: number,
  data: FranchiseGetList | null
) => {
  if (data) {
    return axios.get(
      `/franchise/history?page=${page}&limit=${data.size}&startDate=${data.startDate}&endDate=${
        data.endDate
      }&frId=${id ?? ""}`
    );
  }
  return axios.get(`/franchise/history?page=${page}&limit=10`);
};

export const postFranchiseSpecificationsConfirm = (id: number) => {
  return axios.post(`/franchise/history/confirm/${id}`);
};

export const patchSujungRequest = (id: number) => {
  return axios.patch(`/franchise/billing/history/${id}`);
};

export const patchAmountExcel = (payload: StoreItemExcelUpload[]) => {
  return axios.patch(`/franchise/updateAmount-excel`, payload);
};

// 지점 품명 가져오기
export const getFranchiseProgressList = (id: number) => {
  return axios.get(`/tag/franchise/asset/${id}`);
};

// 라벨 관계자 자산 부서명 정보
export const getStaffDepartment = () => {
  return axios.get(`/personal/department`);
};

// 라벨 관계자 자산 부서 소속 직원 정보
export const getStaffDepartmentMember = (id: number) => {
  return axios.get(`/personal/department/${id}`);
};

// 라벨 관계자 자산 리스트 가져오기
export const getLabelStaffDepartmentList = (data: StoreStaffDepartmentListRequest) => {
  return axios.get(
    `/label?page=${data.page}&query=${data.query}&type=${data.type}&limit=${data.limit}`
  );
};

// 라벨 관계자 자산 데이터 변경
export const patchLabelStaffDepartment = (data: LabelStaffDepartmentRequest) => {
  return axios.patch(`/label`, data);
};

// 직원 정보 리스트 가져오기
export const getStaffDepartmentList = (data: StoreStaffDepartmentListRequest) => {
  return axios.get(
    `/personal?page=${data.page}&query=${data.query}&type=${data.type}&limit=${data.limit}`
  );
};

// 직원 정보 관리 등록
export const postStaffInfoData = (data: PersonalInfoList) => {
  if (!data.id) return axios.post(`/personal`, data);
  return axios.patch(`/personal`, data);
};

// 직원 정보 관리 엑셀등록
export const postStaffInfoDataExcel = (data: StoreStaffInfoExcelUpload) => {
  return axios.post(`/personal/excel`, data);
};

// 직원 정보 관리 데이터 삭제
export const deleteStaffInfoData = (data: DeleteStaffInfoData) => {
  return axios.delete(`/personal`, { data });
};

/** 자산 삭제하기 */
export const deleteAssetDataApi = (id: number) => {
  return axios.delete(`/asset/franchise/${id}`);
};
