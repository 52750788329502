import FactoryDashBoard from "components/factory/FactoryDashBoard";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "modules";
import { getFactoryDashboardAction } from "modules/factory/factory";
import DateFormatUtils from "utils/DateFormatUtils";

interface FactoryDashBoardContainerProps {}

const FactoryDashBoardContainer: React.FC<FactoryDashBoardContainerProps> =
  () => {
    const [timeNow, setTimeNow] = useState<Date>(new Date());
    const [selectDay, setSelectDay] = useState<string>();
    const factoryDashboard = useSelector(
      (state: IRootState) => state.factory.factoryDashBoard
    );
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(
        getFactoryDashboardAction(DateFormatUtils.dateToFormat(new Date()))
      );
      setTimeNow(new Date());
    }, [dispatch]);

    // setInterval(() => {
    //   dispatch(
    //     getFactoryDashboardAction(
    //       selectDay ?? DateFormatUtils.dateToFormat(new Date())
    //     )
    //   );
    //   setTimeNow(new Date());
    // }, 5 * 60 * 1000);

    const getDashboard = (day: string) => {
      setSelectDay(day);
      dispatch(getFactoryDashboardAction(day));
      setTimeNow(new Date());
    };

    return (
      <FactoryDashBoard
        factoryDashboard={factoryDashboard}
        getDashboard={getDashboard}
        timeNow={timeNow}
      />
    );
  };

export default FactoryDashBoardContainer;
