import {
  FactoryExcelMonth,
  FactoryGetList,
  FactoryMatchingFranchiseList,
} from "modules/factory/types";
import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { Palette, ThemeColor } from "styles/palette";
import Button from "./Button";

import "date-fns";
import DateFormatUtils from "utils/DateFormatUtils";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import DateApp from "./datePickerTest";

interface ItemizedAccountSearchProps {
  searchRequestCheck: (data: boolean) => void;
  setItemizedArray: (type: any) => void;
  role: string;
  onSearch: (id: number, data: FactoryGetList | null) => void;
  // onSearch: (data: FactoryItemizedAccountSearch) => void;
  getFactoryLaundryTableYearSearch: (data: FactoryExcelMonth) => void;
  getFactoryLaundryTableSearch: (
    id: number,
    data: FactoryGetList | null,
    chartType: string
  ) => void;
  setChartType: (type: string | null) => void;
  searchDate: (startDate: string, endDate: string, franchiseName: string) => void;
  searchMonth: (startMonth: string, endMonth: string, franchiseName: string) => void;
  factoryMatchingFranchiseList: FactoryMatchingFranchiseList | null;
  setContractType: (type: string) => void;
  setContractPrice: (price: number) => void;
  contractDateData: (start: string, end: string) => void;
  setMonthData: (month: string) => void;
  monthSelector: (month: string, start: string, end: string) => void;
  searchQuery: any;
}

const ItemizedAccountSearch: React.FC<ItemizedAccountSearchProps> = ({
  searchRequestCheck,
  setItemizedArray,
  onSearch,
  searchDate,
  searchMonth,
  getFactoryLaundryTableSearch,
  getFactoryLaundryTableYearSearch,
  setChartType,
  factoryMatchingFranchiseList,
  setContractType,
  setContractPrice,
  contractDateData,
  setMonthData,
  monthSelector,
  searchQuery,
}) => {
  const [searchType, setSearchType] = useState<string | null>("defaultName");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [startMonth, setStartMonth] = useState<string>("");
  const [endMonth, setEndMonth] = useState<string>("");
  const [month, setMonth] = useState<string | null>(null);
  const [selectChart, setSelectChart] = useState<string | null>();
  const [franchiseName, setFranchiseName] = useState<string>("");
  const [contractCheck, setContractCheck] = useState<boolean>(false);
  const [contractStart, setContractStart] = useState<string | null>(null);
  const [contractEnd, setContractEnd] = useState<string | null>(null);

  useEffect(() => {
    if (searchQuery) {
      setSearchType(searchQuery.franchiseId);
      setSelectChart(searchQuery.accountType);
      setStartDate(searchQuery.accountDate);
      setEndDate(searchQuery.accountDate);
      setFranchiseName(searchQuery.franchiseName);
      setContractCheck(true);
    } else {
      setSearchType("defaultName");
      setStartDate(new Date());
      setEndDate(new Date());
    }
  }, []);

  const submitSearch = (e: any) => {
    e.preventDefault();
    searchRequestCheck(true);

    if (contractCheck === false) return alert("계약설정이 되지않은 지점입니다.");
    if (DateFormatUtils.dateTerm(startDate, endDate) > 30 && selectChart === "aggregation2")
      return alert("최대 30일 미만으로 검색 가능합니다.");
    if (!startDate) return alert("검색 조건을 다시 확인해주세요");
    if (!endDate && (selectChart === "specifi2" || selectChart === "aggregation2"))
      return alert("검색 조건을 다시 확인해주세요");
    if (searchType === "defaultName") return alert("지점을 선택해주세요.");

    if (!selectChart) return alert("명세표 타입을 선택해주세요");

    setChartType(selectChart ?? null);

    let endDateData: string = DateFormatUtils.dateToFormat(endDate);
    if (selectChart === "specifi1" || selectChart === "specifi3")
      endDateData = DateFormatUtils.dateToFormat(startDate);

    let startDateData: string = DateFormatUtils.dateToFormat(startDate);

    if (selectChart === "aggregation1") {
      startDateData = startDateData.substring(0, 7);
    }

    const data = {
      query: null,
      startDate: startDateData,
      endDate: endDateData,
      dropBox: null,
    };

    if (selectChart === "aggregation1" || selectChart === "aggregation2") {
      getFactoryLaundryTableSearch(Number(searchType), data, selectChart);
    } else if (selectChart === "aggregation3") {
      let dataYear: FactoryExcelMonth = {
        id: Number(searchType),
        startMonth: startMonth,
        endMonth: endMonth,
      };
      getFactoryLaundryTableYearSearch(dataYear);
    } else {
      onSearch(Number(searchType), data);
    }

    // const data = {
    //   dropBox: searchType,
    //   startDate: DateFormatUtils.dateToFormat(startDate),
    //   endDate: DateFormatUtils.dateToFormat(endDate),
    // };
    // onSearch(data);
    monthSelector(month!, contractStart!, contractEnd!);
    searchDate(
      DateFormatUtils.dateToFormat(startDate),
      selectChart === "specifi1" || selectChart === "specifi3"
        ? endDateData
        : DateFormatUtils.dateToFormat(endDate),
      franchiseName
    );

    searchMonth(startMonth, endMonth, franchiseName);
  };

  const selectHandleChange = (e: string) => {
    if (factoryMatchingFranchiseList) {
      let franchisrName = "";
      // let month = moment(e, "YYYY-MM").daysInMonth();
      for (let i = 0; i < factoryMatchingFranchiseList.franchiseList.length; i++) {
        if (factoryMatchingFranchiseList?.franchiseList[i].franchiseId === Number(e)) {
          if (factoryMatchingFranchiseList?.franchiseList[i].contract) {
            setContractCheck(true);
            setContractType(factoryMatchingFranchiseList?.franchiseList[i].contract.basis);
            setContractPrice(factoryMatchingFranchiseList?.franchiseList[i].contract.price);
            let startDay = String(factoryMatchingFranchiseList?.franchiseList[i].contract.startDay);
            let lastDay = String(factoryMatchingFranchiseList?.franchiseList[i].contract.lastDay);

            setContractStart(startDay);
            setContractEnd(lastDay);
            // contractDateData(startDay, lastDay);
          } else {
            setContractCheck(false);
          }

          franchisrName = factoryMatchingFranchiseList?.franchiseList[i].franchiseName;
        }
      }

      setFranchiseName(franchisrName);
      setSearchType(e);
    }
  };

  const selectChartTypeChange = (e: string) => {
    // console.log(e);
    setSelectChart(e);
    //명세표 타입
  };

  const selectMonthChage = (e: string) => {
    const monthDateStart = `${e}-01`;
    const monthDataEnd = `${e}-${moment(e, "YYYY-MM").daysInMonth()}`;

    setMonth(e);
    setStartDate(new Date(monthDateStart));
    setEndDate(new Date(monthDataEnd));
  };

  const startDateSelector = (date: string) => {
    if (selectChart !== "aggregation3") {
      setStartDate(new Date(date));
    } else {
      setStartMonth(moment(date).format("YYYY-MM"));
    }
  };

  const endDateSelector = (date: string) => {
    if (selectChart !== "aggregation3") {
      setEndDate(new Date(date));
    } else {
      setEndMonth(moment(date).format("YYYY-MM"));
    }
  };

  return (
    <ItemizedAccountSearchBlock>
      <select
        className="selectBox"
        value={selectChart!}
        onChange={(e) => {
          selectChartTypeChange(e.target.value);
        }}
      >
        <option value="defaultName">종류 선택</option>
        <option value="specifi1">거래명세표(금액미포함,납품일 선택)</option>
        <option value="specifi2">거래명세표(금액미포함,기간선택)</option>
        <option value="specifi3">거래명세표(금액포함,납품일 선택)</option>
        <option value="aggregation1">세탁집계표(금액포함,1개월,정산기간)</option>
        <option value="aggregation2">세탁집계표(금액포함,기간선택)</option>
        <option value="aggregation3">세탁집계표(금액포함,월선택)</option>
      </select>
      <select
        className="selectBox"
        value={searchType!}
        onChange={(e) => {
          selectHandleChange(e.target.value);
        }}
      >
        <option value="defaultName">선택</option>
        {factoryMatchingFranchiseList?.franchiseList.map((data, index) => (
          <option key={index} value={data.franchiseId}>
            {data.franchiseName}
          </option>
        ))}
      </select>
      {selectChart === "aggregation1" && (
        <div className="monthPicker">
          <input type="month" onChange={(e) => selectMonthChage(e.target.value)} />
        </div>
      )}

      {selectChart !== "aggregation1" && selectChart !== "aggregation3" && (
        <>
          <div className="datePicker">
            {/* <DatePickerBlock
              selected={startDate}
              dateFormat="yyyy년 MM월 dd일"
              onChange={(e: any) => {
                setStartDate(e);
              }}
            /> */}
            <DateApp dateItem={startDateSelector} today={String(startDate)} />
          </div>

          {(selectChart === "specifi2" || selectChart === "aggregation2") && (
            <>
              ~{" "}
              <div className="datePicker">
                {/* <DatePickerBlock
                  selected={endDate}
                  dateFormat="yyyy년 MM월 dd일"
                  onChange={(e: any) => {
                    setEndDate(e);
                  }}
                /> */}
                <DateApp dateItem={endDateSelector} today={String(endDate)} />
              </div>
            </>
          )}
        </>
      )}
      {selectChart === "aggregation3" && (
        <>
          <div className="monthPicker">
            <input
              name="startMonth"
              type="month"
              onChange={(e: any) => {
                startDateSelector(e.target.value);
              }}
            />
          </div>
          <div className="monthPicker">
            <input
              name="endMonth"
              type="month"
              onChange={(e: any) => {
                endDateSelector(e.target.value);
              }}
            />
          </div>
        </>
      )}

      <Button
        theme={ThemeColor.primary}
        buttonSize="m"
        onClick={(e) => submitSearch(e)}
        className="buttonSize"
      >
        검색
      </Button>
    </ItemizedAccountSearchBlock>
  );
};

export default ItemizedAccountSearch;

const ItemizedAccountSearchBlock = styled.form`
  display: inline-block;
  height: auto;
  /* width: 500px; */
  /* display: flex; */
  position: relative;
  & > .testWrapper {
    position: absolute;
    background-color: lightblue;
    width: 300px;
    height: auto;
    top: 0;
    /* overflow: hidden; */
  }
  & > select {
    border: 0;
    height: 100%;
  }
  & > input {
    /* align-self: center; */
    border: 0;
    height: 100%;
  }

  & > .selectBox,
  .textBox {
    border: 1px solid #dee2e6;
    box-sizing: border-box;
    transition: ease-in-out;
    transition-duration: 0.1s;
    margin-right: 5px;
    border-radius: 3px;
  }
  & > .datePicker {
    display: inline-block;
    /* height: 37.5px; */
    padding: 5px 0;
    /* background-color: red; */
    margin-right: 5px;
    color: black;
    /* box-sizing: border-box;
    transition: ease-in-out;
    transition-duration: 0.1s;
    cursor: default; */
  }
  & > .selectBox:hover,
  .textBox:hover {
    border-bottom: 2px solid ${Palette.primary};
  }

  & > .monthPicker {
    display: inline-block;
    margin-right: 5px;
    & > input {
      height: 37.5px;
      border-radius: 3px;
      border: 1px solid #dee2e6;
    }
  }
`;

// const DatePickerBlock = styled(DatePicker)`
//   width: 140px;
//   height: 100%;
//   border: 1px solid #dee2e6;
//   border-radius: 3px;
//   transition: ease-in-out;
//   transition-duration: 0.1s;
//   margin-right: 5px;
//   cursor: default;
//   &:hover {
//     border-bottom: 2px solid ${Palette.primary};
//   }
// `;
