import { BoardDetailData, BoardGetList } from "modules/user/types";
import React from "react";
import styled from "styled-components";
import { ThemeColor } from "styles/palette";
import RoleUtils from "utils/RoleUtils";
import Button from "./Button";
import ReactHtmlParser from "react-html-parser";
import { Link, useHistory } from "react-router-dom";

interface NoticeStatusDetailProps {
  role: string;
  auth: string;
  noticeDetailId: number;
  noticeDetail: BoardDetailData | null;
  selectNoticeType: (type: string) => void;
  deleteNotice: (id: number) => void;
}

const NoticeStatusDetail: React.FC<NoticeStatusDetailProps> = ({
  role,
  auth,
  noticeDetailId,
  noticeDetail,
  selectNoticeType,
  deleteNotice,
}) => {
  let history = useHistory();

  const onReversePage = () => {
    selectNoticeType("list");
    history.push(`/${auth}/notice`);
  };

  const onDelete = () => {
    if (auth !== "admin") return alert("권한이 없습니다");
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      deleteNotice(noticeDetailId!);
      onReversePage();
    }
  };

  const options = {
    decodeEntities: true,
  };

  return (
    <NoticeStatusDetailBlock>
      <div className="headers">
        <h3>공지사항</h3>
      </div>
      <div className="submitBtnWrapper">
        <label>
          <span>제목: </span>
          <input value={noticeDetail?.title} disabled />
        </label>

        <div>
          <Link to={`/${auth}/notice`}>
            <Button
              theme={ThemeColor.primary}
              buttonSize="s"
              onClick={onReversePage}
            >
              뒤로가기
            </Button>
          </Link>

          {RoleUtils.isMasterAdmin(role) && (
            <>
              <Link to="#">
                <Button
                  theme={ThemeColor.primary}
                  buttonSize="s"
                  onClick={onDelete}
                >
                  삭제
                </Button>
              </Link>
              <Link to={`/admin/notice/modify`}>
                <Button
                  theme={ThemeColor.primary}
                  buttonSize="s"
                  onClick={() => selectNoticeType("modify")}
                >
                  수정
                </Button>
              </Link>
            </>
          )}
        </div>
      </div>
      <div className="textWrapper">
        {ReactHtmlParser(noticeDetail?.content ?? "", options)}
      </div>
    </NoticeStatusDetailBlock>
  );
};

export default NoticeStatusDetail;

const NoticeStatusDetailBlock = styled.div`
  min-height: 500px;
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  padding-bottom: 20px;
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }

  .submitBtnWrapper {
    height: 70px;
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: space-between;
    & > label {
      & > span {
        color: #999;
        font-size: 14px;
        line-height: 37px;
      }
      & > input {
        width: 700px;
        border-radius: 3px;
        border: 1px solid #dee2e6;
        box-sizing: border-box;
      }
    }
    & > div {
      & > a {
        margin-left: 5px;
      }
    }
  }
  .textWrapper {
    width: 100%;
    height: auto;
    min-height: 450px;
    margin-top: 8px;
    border-radius: 5px;
    padding: 20px;
    border: 1px solid #dee2e6;
  }
`;
