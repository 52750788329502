import AdminItemizedAccount from "components/admin/AdminItemizedAccount";
import TransactionTable from "components/common/TransactionTable";
import { IRootState } from "modules";
import { getAdminSpecificationsListAction } from "modules/admin/admin";
import { AdminGetList } from "modules/admin/types";
import {
  getExcelDataAction,
  getSpecificationsDetailAction,
  getSpecificationsFranchiseListAction,
  getTransExcelDataAction,
} from "modules/user/user";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import DateFormatUtils from "utils/DateFormatUtils";

interface AdminItemizedAccountContainerProps {
  match: any;
}

const AdminItemizedAccountContainer: React.FC<AdminItemizedAccountContainerProps> =
  ({ match }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [selectType, setSelectType] = useState<string>("list");
    const [selectDetailTitle, setSelectDetailTitle] = useState({
      title: "",
      name: "",
      date: "",
      chartType: "",
    });
    const [searchData, setSearchData] = useState<AdminGetList | null>({
      query: "",
      dropBox: "",
      startDate: "",
      endDate: "",
      size: 10,
    });
    const [searchPage, setSearchPage] = useState<number>(1);
    const [searchId, setSearchId] = useState<number | null>(null);

    const [detailId, setDetailId] = useState<number | null>(null);

    const role = useSelector((state: IRootState) => state.user.profile.type);
    const adminSpecificationsList = useSelector(
      (state: IRootState) => state.admin.adminSpecificationsData
    );
    const specificationsDetailData = useSelector(
      (state: IRootState) => state.user.specificationsDetail
    );
    const specificationsFranchiseList = useSelector(
      (state: IRootState) => state.user.specificationsFranchiseList
    );
    const excelFile = useSelector((state: IRootState) => state.user.excelData);
    const transExcelFile = useSelector(
      (state: IRootState) => state.user.transExcelData
    );

    // 집계표
    const getExcelData = () => {
      dispatch(getExcelDataAction(match.params.id));
    };

    // 명세표
    const getTransExcelData = () => {
      dispatch(getTransExcelDataAction(match.params.id));
    };

    const query = window.location.pathname;

    useEffect(() => {
      if (query === `/admin/itemizedAccount/${match.params.id}`)
        setSelectType("detail");
      if (query === `/admin/itemizedAccount`) setSelectType("list");
    }, [query]);

    useEffect(() => {
      history.push(`/admin/itemizedAccount`);
      setSelectType("list");
    }, []);

    useEffect(() => {
      dispatch(getSpecificationsFranchiseListAction());
      dispatch(getAdminSpecificationsListAction(null, 1, null));
      if (match.params.id) dispatch(getExcelDataAction(match.params.id));
    }, [dispatch]);

    const specificationsListSearch = (
      id: number | null,
      page: number,
      data: AdminGetList | null
    ) => {
      setSearchId(id);
      setSearchData(data ?? null);
      setSearchPage(page);
      dispatch(getAdminSpecificationsListAction(id, page, data));
    };

    const specificationsDetail = (
      id: number,
      title: string,
      name: string,
      date: Date,
      chartType: string
    ) => {
      const selectDate = DateFormatUtils.dateToFormat(date);
      setSelectDetailTitle({ title, name, date: selectDate, chartType });
      dispatch(getSpecificationsDetailAction(id));
      setDetailId(id);
    };

    useEffect(() => {
      if (match.params.id && Number(match.params.id)) {
        setSearchId(match.params.id);
        setSelectType("detail");
        dispatch(getSpecificationsDetailAction(match.params.id));
      } else {
        setSelectType("list");
      }
    }, [dispatch, match.params.id]);

    const historyBack = () => {
      setSelectDetailTitle({
        title: "",
        name: "",
        date: "",
        chartType: "",
      });
      setSelectType("list");
    };

    return (
      <>
        {selectType === "list" && (
          <AdminItemizedAccount
            role={role}
            specificationsDetail={specificationsDetail}
            setSelectType={setSelectType}
            adminSpecificationsList={adminSpecificationsList}
            specificationsListSearch={specificationsListSearch}
            specificationsFranchiseList={specificationsFranchiseList}
          />
        )}
        {selectType === "detail" && (
          <TransactionTable
            role={role}
            selectDetailTitle={selectDetailTitle}
            specificationsDetailData={specificationsDetailData}
            historyBack={historyBack}
            excelFile={excelFile}
            transExcelFile={transExcelFile}
            getExcelData={getExcelData}
            getTransExcelData={getTransExcelData}
          />
        )}
      </>
    );
  };

export default AdminItemizedAccountContainer;
