import LaundaryStatusDetail from "components/common/LaundaryStatusDetail";
import StoreLaundry from "components/store/StoreLaundry";
import useInputs from "hooks/useInputs";
import { IRootState } from "modules";
import {
  addFranchiseLaundryOrderAction,
  getFranchiseDetailLaundryAction,
  getFranchiseLaundryFactoryAction,
  getFranchiseLaundryList,
} from "modules/store/store";
import { FranchiseAddBagCount, FranchiseGetList } from "modules/store/types";

import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import RoleUtils from "utils/RoleUtils";

interface StoreLaundryContainerProps {
  match: any;
}

const StoreLaundryContainer: React.FC<StoreLaundryContainerProps> = ({
  match,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state: IRootState) => state.user.profile);

  const franchiseLaundryFactory = useSelector(
    (state: IRootState) => state.store.franchiseLaundryFactory
  );

  const type = useSelector((state: IRootState) => state.user.profile?.type);
  const franchiseLaundryPage = useSelector(
    (state: IRootState) => state.store.franchiseLaundryPage
  );
  const franchiseDetailLaundry = useSelector(
    (state: IRootState) => state.store.franchiseDetailLaundry
  );

  const [inputs, onChange] = useInputs<FranchiseAddBagCount>({
    bagCount: undefined,
  });
  const [toggle, setToggle] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [searchData, setSearchData] = useState<FranchiseGetList | null>();
  // const [detailId, setDetailId] = useState<number | null>(null);
  const [selectType, setSelectType] = useState<string>("list");

  // useEffect(() => {
  //   setInterval(() => {
  //     dispatch(getFranchiseLaundryList(page, searchData ?? null));
  //   }, 5 * 60 * 1000);
  // }, []);

  useEffect(() => {
    if (match.params.id && Number(match.params.id)) {
      // setDetailId(match.params.id);
      setSelectType("detail");
      dispatch(getFranchiseDetailLaundryAction(match.params.id));
    } else {
      setSelectType("list");
    }
  }, [match.params.id]);

  const query = window.location.pathname;

  useEffect(() => {
    if (query === `/store/laundry`) {
      setSelectType("list");
      // setDetailId(null);
    } else {
      setSelectType("detail");
    }
  }, [query]);

  useEffect(() => {
    if (RoleUtils.isStore(type)) dispatch(getFranchiseLaundryList(1, null));
    dispatch(getFranchiseLaundryFactoryAction());
  }, [dispatch, type]);

  const addLaundryOrder = () => {
    if (!inputs.bagCount) {
      alert("수량을 입력해주세요.");
      return;
    }
    dispatch(addFranchiseLaundryOrderAction({ bagCount: inputs.bagCount }));
    inputs.bagCount = 0;
  };

  const onDetail = (id: number) => {
    // setDetailId(id);
    dispatch(getFranchiseDetailLaundryAction(id));
    setSelectType("detail");
    history.push(`/store/laundry/${id}`);
  };

  const onToggle = (toggle: boolean) => {
    setToggle(toggle);
  };

  const onClose = () => {
    // setDetailId(null);
    setSelectType("list");
    history.push(`/store/laundry`);
  };

  const laundryList = (page: number, data: FranchiseGetList | null) => {
    setPage(page);
    setSearchData(data);
    dispatch(getFranchiseLaundryList(page, data));
  };

  // const historyBack = () => {
  //   setDetailId(null);
  //   history.push(`/store/laundry`);
  // };

  if (type === "GUEST") return null;
  if (!franchiseLaundryPage) return <h1>로딩중</h1>;

  return (
    <>
      {selectType === "list" && (
        <StoreLaundry
          inputs={inputs}
          onChange={onChange}
          addLaundryOrder={addLaundryOrder}
          franchiseLaundryFactory={franchiseLaundryFactory}
          user={user}
          role={type!}
          franchiseLaundryPage={franchiseLaundryPage!}
          laundryList={laundryList}
          onDetail={onDetail}
          toggle={toggle}
          onToggle={onToggle}
        />
      )}
      {selectType === "detail" && (
        <LaundaryStatusDetail
          franchiseDetailLaundry={franchiseDetailLaundry!}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default StoreLaundryContainer;
