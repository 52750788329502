import AdminLaundryRfidCountList from "components/admin/AdminLaundryRfidCountList";
import { IRootState } from "modules";
import {
  deleteLaundryItemAction,
  getAdminLaundryItemListAction,
  getAdminLaundryTagListAction,
  patchModifyLaundryItemAction,
  postLaundryItemAction,
} from "modules/admin/admin";
import { AdminTagGetList } from "modules/admin/types";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NumberUtils from "utils/NumberUtils";

interface AdminLaundryRfidCountContainerProps {
  match: any;
}

export type AdminLaundrySelectItemInfoType = {
  largeCategory: string;
  subCategory: string;
  name: string;
  amount: number;
  size: string;
  etc: string;
  unitPrice: number;
  weight: number;
  id: number | null;
};

const AdminLaundryRfidCountContainer: React.FC<AdminLaundryRfidCountContainerProps> = ({
  match,
}) => {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentTagPage, setCurrentTagPage] = useState<number>(1);
  const [searchData, setSearchData] = useState({
    query: "",
    assetId: "",
    frId: "",
    limit: "10",
    sort: "ASC",
  });

  /** 선택한 지점 자산 정보 */
  const [selItemInfo, setSelItemInfo] = useState<AdminLaundrySelectItemInfoType>({
    largeCategory: "",
    subCategory: "",
    name: "",
    amount: 0,
    size: "",
    etc: "",
    unitPrice: 0,
    weight: 0,
    id: null,
  });

  const adminLaundryTagList = useSelector((state: IRootState) => state.admin.adminLaundryTagList);
  const adminLaundryItemList = useSelector((state: IRootState) => state.admin.adminLaundryItemList);

  useEffect(() => {
    dispatch(getAdminLaundryItemListAction(1, match.params.id, 10));
  }, [dispatch]);

  const getLaundryTagData = (page: number, data: AdminTagGetList) => {
    setSearchData(data);
    setCurrentTagPage(page);
    dispatch(getAdminLaundryTagListAction(page, data));
  };

  const getSelectLaundryTagData = (
    page: number,
    assetId: string,
    query: string | null,
    sort: string | null
  ) => {
    let data = {
      query: query ?? "",
      assetId: assetId,
      frId: `${match.params.id}`,
      limit: "10",
      sort: sort ?? "ASC",
    };
    setSearchData(data);
    dispatch(getAdminLaundryTagListAction(page, data));
  };

  const getLaundryItemData = (page: number, size: number) => {
    setCurrentPage(page);
    dispatch(getAdminLaundryItemListAction(page, match.params.id, size));
  };

  const onSelItemInfo = (data: AdminLaundrySelectItemInfoType) => {
    setSelItemInfo(data);
  };

  /** 자산 등록, 수정 데이터 반영 */
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    let data = value;

    if (name === "unitPrice") {
      let fee = NumberUtils.numberOnlyToString(value);
      data = String(NumberUtils.firstNotZero(fee));
    }

    setSelItemInfo({ ...selItemInfo, [name]: data });
  };

  /** 자산 삭제 */
  const onDelete = () => {
    dispatch(
      deleteLaundryItemAction(
        selItemInfo.id!,
        currentPage,
        Number(match.params.id),
        Number(searchData.limit)
      )
    );
  };

  /** 자산 수정 */
  const onModify = () => {
    let item = { ...selItemInfo };

    let data = {
      assetId: Number(item.id),
      content: item.etc,
      franchiseId: match.params.id,
      largeCategory: item.largeCategory,
      name: item.name,
      price: item.unitPrice,
      size: item.size,
      subCategory: item.subCategory,
      weight: item.weight,
    };

    dispatch(
      patchModifyLaundryItemAction(
        data,
        currentPage,
        Number(match.params.id),
        Number(searchData.limit)
      )
    );
  };

  /** 자산 등록 */
  const onAdd = () => {
    let item = { ...selItemInfo };

    let data = {
      content: item.etc,
      franchiseId: match.params.id,
      largeCategory: item.largeCategory,
      name: item.name,
      price: item.unitPrice,
      size: item.size,
      subCategory: item.subCategory,
      weight: item.weight,
    };

    dispatch(
      postLaundryItemAction(data, currentPage, Number(match.params.id), Number(searchData.limit))
    );
  };

  return (
    <AdminLaundryRfidCountList
      adminLaundryItemList={adminLaundryItemList}
      adminLaundryTagList={adminLaundryTagList}
      getLaundryItemData={getLaundryItemData}
      getLaundryTagData={getLaundryTagData}
      getSelectLaundryTagData={getSelectLaundryTagData}
      selItemInfo={selItemInfo}
      onSelItemInfo={onSelItemInfo}
      onChange={onChange}
      onDelete={onDelete}
      onAdd={onAdd}
      onModify={onModify}
    />
  );
};

export default AdminLaundryRfidCountContainer;
