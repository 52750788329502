import Button from "components/common/Button";
import PopupSmallLayout from "components/common/PopupSmallLayout";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  FranchiseAddItem,
  FranchiseGetItem,
  FranchiseItem,
  FranchiseItemList,
  FranchiseItemModify,
  itemList,
  StoreItemExcelUpload,
} from "modules/store/types";
import { Palette, ThemeColor } from "styles/palette";
import ScrollUtils from "utils/ScrollUtils";
import NumberUtils from "utils/NumberUtils";
import Paging from "components/common/Paging";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import XLSX from "xlsx";
import { NavLink } from "react-router-dom";

interface StoreInventoryStatusProps {
  inputs: FranchiseAddItem;
  itemList: (page: number, size: number) => void;
  onChange: (e: any) => void;
  onReset: () => void;
  onClose: () => void;
  // franchiseItemData: FranchiseItem[] | null;
  franchiseItemList: FranchiseItemList | null;
  addItemCount: (size: number) => void;
  modifyItem: (payload: FranchiseItemModify, page: number, size: number) => void;
  deleteItem: (id: number) => void;
  getItemListData: (payload: FranchiseGetItem) => void;
  patchAmountExcel: (payload: StoreItemExcelUpload[], list: number, size: number) => void;
  itemLargeList: FranchiseItem[] | null;
  itemSubList: FranchiseItem[] | null;
  itemNameList: FranchiseItem[] | null;
  itemSizeData: { size: string; content: string };
  franchiseItemListAll: itemList[] | null;
  storeName: string;
  onDelete: (id: number, page: number, size: number) => void;
}

const StoreInventoryStatus: React.FC<StoreInventoryStatusProps> = ({
  inputs,
  itemList,
  onChange,
  onReset,
  onClose,
  addItemCount,
  // franchiseItemData,
  franchiseItemList,
  modifyItem,
  deleteItem,
  getItemListData,
  patchAmountExcel,
  itemLargeList,
  itemSubList,
  itemNameList,
  itemSizeData,
  franchiseItemListAll,
  storeName,
  onDelete,
}) => {
  const [stockPopupCheck, setStockPopupCheck] = useState(false);
  const [typePopupCheck, setTypePopupCheck] = useState<string | null>("");
  const [quantity, setQuantity] = useState<number | null>(null);
  const [itemId, setItemId] = useState<number | null>(null);
  const [discard, setDiscard] = useState<number | null>(null);
  const [additional, setAdditional] = useState<number | null>(null);
  const [itemSize, setItemSize] = useState<string | null>("");
  const [itemContent, setItemContent] = useState<string | null>("");

  const [itemLarge, setItemLarge] = useState<string | null>("");
  const [itemSub, setItemSub] = useState<string | null>("");
  const [itemName, setItemName] = useState<string | null>("");

  const [selectSize, setSelectSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [selItemInfoModal, setSelItemInfoModal] = useState<{
    itemId: number | null;
    check: boolean;
  }>({
    itemId: null,
    check: false,
  });

  const addItem = () => {
    const scrollY = document.body.style.top;
    if (stockPopupCheck === false) ScrollUtils.onPopupScrollStop(window.scrollY);
    if (stockPopupCheck === true) ScrollUtils.closePopupScrollRun(scrollY);
    addItemCount(selectSize);
    setStockPopupCheck(!stockPopupCheck);
    resetData();
  };

  useEffect(() => {
    setItemSize(itemSizeData.size);
    setItemContent(itemSizeData.content);
  }, [itemSizeData]);

  const popupCheck = (type: string) => {
    const scrollY = document.body.style.top;
    if (stockPopupCheck === false) ScrollUtils.onPopupScrollStop(window.scrollY);
    if (stockPopupCheck === true) ScrollUtils.closePopupScrollRun(scrollY);
    setTypePopupCheck(type);
    setStockPopupCheck(!stockPopupCheck);
  };

  const popupClose = () => {
    const scrollY = document.body.style.top;

    if (stockPopupCheck === true) ScrollUtils.closePopupScrollRun(scrollY);
    setStockPopupCheck(!stockPopupCheck);
    onClose();
    resetData();
  };

  const modifyItemData = () => {
    if (
      discard !== null &&
      !isNaN(Number(discard)) &&
      additional !== null &&
      !isNaN(Number(additional))
    ) {
      setAdditional(null);
      setDiscard(null);
      return alert("폐기와 추가는 동시에 진행할 수 없습니다.");
    }

    const payload = {
      id: itemId,
      plusAmount: additional,
      minusAmount: discard,
    };
    if (!additional && !discard) return alert("값이 올바르지 않거나, 빈값을 채워주세요.");
    modifyItem(payload, currentPage, selectSize);
    setStockPopupCheck(!stockPopupCheck);
    resetData();
  };

  // const deleteItemData = (id: number) => {
  //   if (window.confirm("정말로 삭제하시겠습니까?")) deleteItem(id);
  // };

  const [itemDetailItems, setItemDetailItems] = useState<itemList[]>([]);

  useEffect(() => {
    setItemDetailItems(franchiseItemList?.list ?? []);
  }, [franchiseItemList]);

  const resetData = () => {
    setItemLarge(null);
    setItemSub(null);
    setItemName(null);
    setQuantity(null);
    setItemId(null);
    setItemSize(null);
    setItemContent(null);
    setDiscard(null);
    setAdditional(null);
    onReset();
    const scrollY = document.body.style.top;
    if (stockPopupCheck === false) ScrollUtils.onPopupScrollStop(window.scrollY);
    if (stockPopupCheck === true) ScrollUtils.closePopupScrollRun(scrollY);
  };

  const modifyPopup = (
    type: string,
    large: string,
    sub: string,
    name: string,
    quantity: number,
    size: string,
    content: string,
    id: number
  ) => {
    popupCheck(type);
    setItemLarge(large);
    setItemSub(sub);
    setItemName(name);
    setQuantity(quantity);
    setItemSize(size);
    setItemContent(content);
    setItemId(id);
  };

  const [listNumber, setListNumber] = useState<number[]>([]);
  useEffect(() => {
    setListNumber(
      NumberUtils.numberToList(
        franchiseItemList?.currentPage ?? 0,
        franchiseItemList?.totalCount ?? 0,
        selectSize
      )
    );
  }, [selectSize, franchiseItemList]);

  const pageMove = (page: number) => {
    setCurrentPage(page);
    itemList(page, selectSize);
  };

  const selectShowEntries = (size: number) => {
    setSelectSize(size);
    itemList(1, size ?? null);
  };

  const onItemSelect = (data: string, type: string) => {
    let large: string | null = null;
    let sub: string | null = null;
    let name: string | null = null;
    if (type === "sub") large = data;
    if (type === "name") sub = data;
    if (type === "size") name = data;
    const payload = {
      type,
      large,
      sub,
      name,
    };
    getItemListData(payload);
  };

  const [excelData, setExcelData] = useState<itemList[]>([]);
  useEffect(() => {
    setExcelData(franchiseItemListAll ? franchiseItemListAll : []);
  }, [franchiseItemListAll]);

  const excelDown = () => {
    if (!excelData) return;
    let excelDataList: any[] = [];
    excelData.forEach((data) =>
      excelDataList.push({
        대분류: data.details.largeCategory,
        소분류: data.details.subCategory,
        품명: data.details.name,
        사이즈: data.details.size,
        비고: data.details.content,
        단가: data.unitPrice,
        중량: data.weight,
        수량: data.assetAmount,
      })
    );
    const ws = XLSX.utils.json_to_sheet(excelDataList);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${storeName}_자산관리.xlsx`);
  };

  const excelUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    var input = e.target.files;

    if (!input) return;
    var reader = new FileReader();
    reader.onload = async function () {
      var fileData = reader.result;
      var wb = XLSX.read(fileData, { type: "binary" });
      wb.SheetNames.forEach(function (sheetName) {
        var rowObj: any[] = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);

        // setExcelFile(rowObj);
        let list = [];
        for (let i = 0; i < rowObj.length; i++) {
          if (
            !Object.keys(rowObj[i]).includes("대분류") ||
            !Object.keys(rowObj[i]).includes("소분류") ||
            !Object.keys(rowObj[i]).includes("품명") ||
            !Object.keys(rowObj[i]).includes("사이즈") ||
            !Object.keys(rowObj[i]).includes("비고")
            // !Object.keys(rowObj[i]).includes("단가") ||
            // !Object.keys(rowObj[i]).includes("중량") ||
            // !Object.keys(rowObj[i]).includes("수량")
          ) {
            alert("데이터 형식이 맞지 않습니다.");
            return;
          }
          let item = {
            amount: rowObj[i]["수량"],
            name: rowObj[i]["품명"],
            price: rowObj[i]["단가"],
            subCategory: rowObj[i]["소분류"],
            largeCategory: rowObj[i]["대분류"],
            weight: rowObj[i]["중량"],
          };
          list.push(item);
        }

        patchAmountExcel(list, currentPage, selectSize);
        // setInputList([...list]);
        // masterItemRefExcel(list);
        e.target.value = "";
      });
    };

    reader.readAsBinaryString(input[0]);
  };

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      fontWeight: 700,
    },
  });
  const classes = useStyles();

  const onSelect = (id: number) => {
    let data: { itemId: number | null; check: boolean } = {
      itemId: null,
      check: false,
    };

    if (!selItemInfoModal.itemId || selItemInfoModal.itemId !== id) {
      data = {
        itemId: id,
        check: true,
      };
    }

    setSelItemInfoModal(data);
  };

  const onSelDelete = () => {
    if (window.confirm("등록된 태그 정보까지 삭제 됩니다.\n정말로 삭제하시겠습니까?")) {
      if (selItemInfoModal.itemId) onDelete(selItemInfoModal.itemId, currentPage, selectSize);
    }
  };

  return (
    <StoreInventoryStatusBlock>
      {stockPopupCheck && (
        <PopupSmallLayout>
          <h2>{typePopupCheck === "modify" ? "재고수정" : "재고등록"}</h2>
          <article className="popupInput">
            <div className="inputField">
              <div className="field">
                <span>대분류</span>
                {typePopupCheck === "modify" ? (
                  <input value={itemLarge!} disabled />
                ) : (
                  <select onChange={(e) => onItemSelect(e.target.value, "sub")}>
                    <option value="">선택</option>
                    {itemLargeList?.map((large, index) => (
                      <option key={index} value={large.title}>
                        {large.title}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <div className="field">
                <span>소분류</span>
                {typePopupCheck === "modify" ? (
                  <input value={itemSub!} disabled />
                ) : (
                  <select onChange={(e) => onItemSelect(e.target.value, "name")}>
                    <option value="">선택</option>
                    {itemSubList?.map((sub, index) => (
                      <option key={index} value={sub.title}>
                        {sub.title}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <div className="field">
                <span className="letter_spacing">품목</span>
                {typePopupCheck === "modify" ? (
                  <input value={itemName!} disabled />
                ) : (
                  <select
                    name="assetDetailsId"
                    value={inputs.assetDetailsId}
                    onChange={(e) => {
                      onChange(e);
                      onItemSelect(e.target.value, "size");
                    }}
                  >
                    <option value="">선택</option>
                    {itemNameList?.map((name) => (
                      <option key={name.id} value={name.id}>
                        {name.title}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <div className="field">
                <span>사이즈</span>
                <input name="size" value={itemSize ?? ""} disabled />
              </div>
              <div className="field">
                <span className="letter_spacing">비고</span>
                <input name="content" value={itemContent ?? ""} disabled />
              </div>
              {/* <div className="field">
                <span>카테고리</span>
                {typePopupCheck === "modify" ? (
                  <input value={category!} disabled />
                ) : (
                  <select
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                  >
                    <option>선택</option>
                    {franchiseItemData?.map((item, index) => (
                      <option
                        key={item.largeCategory}
                        value={item.largeCategory}
                      >
                        {item.largeCategory}
                      </option>
                    ))}
                  </select>
                )}
              </div> */}
              {/* <div className="field">
                <span>사이즈</span>
                {typePopupCheck === "modify" ? (
                  <input value={size!} disabled />
                ) : (
                  <select
                    name="assetDetailsId"
                    value={inputs.assetDetailsId}
                    onChange={onChange}
                  >
                    <option>선택</option>
                    {franchiseItemData
                      ?.filter(
                        (item) => item.largeCategory === selectedCategory
                      )
                      .map((item: FranchiseItem) =>
                        item.sizeList?.map((size) => (
                          <option value={size.id} key={size.id}>
                            {size.subCategory}
                          </option>
                        ))
                      )}
                  </select>
                )}
              </div> */}
              <div className="field">
                <span>수량(개)</span>
                {typePopupCheck === "modify" ? (
                  <input type="number" name="amount_modify" value={quantity ?? 0} disabled />
                ) : (
                  <input
                    type="number"
                    name="amount"
                    value={inputs.amount}
                    onChange={(e) => onChange(e)}
                  />
                )}
              </div>
              {typePopupCheck === "modify" && (
                <>
                  <div className="field">
                    <span>폐기(개)</span>
                    <input
                      type="number"
                      name="discard"
                      value={discard ?? ""}
                      onChange={(e) => setDiscard(NumberUtils.firstNotZero(e.target.value))}
                    />
                  </div>
                  <div className="field">
                    <span>추가(개)</span>
                    <input
                      type="number"
                      name="additional"
                      value={additional ?? ""}
                      onChange={(e) => setAdditional(NumberUtils.firstNotZero(e.target.value))}
                    />
                  </div>
                </>
              )}
            </div>
          </article>
          <div className="buttonWrapper">
            <Button theme={ThemeColor.primary} buttonSize="m" onClick={popupClose}>
              취소
            </Button>
            {typePopupCheck === "enroll" && (
              <Button theme={ThemeColor.primary} buttonSize="m" onClick={addItem}>
                등록
              </Button>
            )}
            {typePopupCheck === "modify" && (
              <Button theme={ThemeColor.primary} buttonSize="m" onClick={modifyItemData}>
                수정
              </Button>
            )}
          </div>
        </PopupSmallLayout>
      )}
      <div className="headers">
        <h3>자산 관리</h3>
        <div>
          <span className="selectClick on">
            <NavLink to="/store/inventory">기본 자산</NavLink>
          </span>
          <span>|</span>
          <span className="selectClick">
            <NavLink to="/store/inventory/staff/property">관계자 자산</NavLink>
          </span>
          <span>|</span>
          <span className="selectClick">
            <NavLink to="/store/inventory/staff/info">직원 정보 관리</NavLink>
          </span>
        </div>
      </div>
      <div className="tableSelect">
        <div>
          <span>Show </span>
          <select onChange={(e) => selectShowEntries(parseInt(e.target.value, 10))}>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
          <span> entries</span>
        </div>
        <div className="topWrapper">
          <div>
            <Button theme={ThemeColor.primary} buttonSize="m" onClick={excelDown}>
              엑셀저장
            </Button>
            <div className={"excelBtn"}>
              <label>
                엑셀업로드
                <input
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  name="excelInput"
                  id="excel_upload_input"
                  onChange={(e) => {
                    excelUpload(e);
                  }}
                />
              </label>
            </div>
            <Button theme={ThemeColor.primary} buttonSize="m" onClick={() => popupCheck("enroll")}>
              등록
            </Button>
            {selItemInfoModal.check && (
              <Button theme={ThemeColor.warning} buttonSize="m" onClick={onSelDelete}>
                삭제
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="tableWrapper">
        <TableContainer component={Paper} className="tables">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: 900, width: "70px" }}>
                  순번
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900 }}>
                  대분류
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900, width: "13.5%" }}>
                  소분류
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900, width: "13.5%" }}>
                  품명
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900, width: "13.5%" }}>
                  사이즈
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900, width: "13.5%" }}>
                  비고
                </TableCell>
                <TableCell align="center" style={{ fontWeight: 900, width: "13.5%" }}>
                  수량
                </TableCell>
                <TableCell style={{ width: "13.5%" }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="tableBody">
              {franchiseItemList?.list.map((item, index) => (
                <TableRow
                  key={index}
                  className={`tableHover ${selItemInfoModal.itemId === item.id && "selItemInfo"}`}
                  onClick={() => onSelect(item.id)}
                >
                  <TableCell component="th" scope="row" align="center">
                    {listNumber[index]}
                  </TableCell>
                  <TableCell align="center">{item.details.largeCategory}</TableCell>
                  <TableCell align="center">{item.details.subCategory}</TableCell>
                  <TableCell align="center">{item.details.name}</TableCell>
                  <TableCell align="center">{item.details.size}</TableCell>
                  <TableCell align="center">{item.details.content}</TableCell>
                  <TableCell align="center">
                    {NumberUtils.numberToComma(item.assetAmount ?? 0)}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      theme={ThemeColor.primary}
                      buttonSize="s"
                      onClick={(e) => {
                        e.stopPropagation();
                        modifyPopup(
                          "modify",
                          item.details.largeCategory ?? "",
                          item.details.subCategory ?? "",
                          item.details.name ?? "",
                          item.assetAmount,
                          item.details.size,
                          item.details.content ?? "",
                          item.id
                        );
                      }}
                    >
                      수정
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Paging
          listAction={pageMove}
          listCount={franchiseItemList?.totalPage ?? 0}
          currentPage={franchiseItemList?.currentPage}
          totalCount={franchiseItemList?.totalCount}
          size={selectSize ?? 10}
        ></Paging>
      </div>
    </StoreInventoryStatusBlock>
  );
};

export default StoreInventoryStatus;

export const StoreInventoryStatusBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);

  .topButtonWrapper {
    height: 36.9px;
    display: flex;
    justify-content: flex-end;
  }
  h2 {
    margin-top: 2.5rem;
    margin-left: 3rem;
  }
  .popupInput {
    flex: 1;
    width: 21.5rem;
    margin: 0 auto;
    .inputField {
      width: 100%;
      margin-top: 7rem;
      .field {
        /* height: 2.1rem; */
        display: grid;
        grid-template-columns: 8rem 1fr;
        align-items: center;
        select,
        input {
          height: 2.1rem;
        }
        .letter_spacing {
          letter-spacing: 13px;
        }
      }
      .field + .field {
        margin-top: 0.9rem;
      }
    }
  }
  .buttonWrapper {
    margin: 0 auto;
    padding: 2rem;
  }
  .tables {
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
    & > div {
      width: auto;
      height: 20px;
      display: flex;
      justify-content: space-between;
      margin-left: 20px;
      padding-top: 5px;

      & > span {
        color: #dee2e6;
      }
      & > .selectClick {
        cursor: pointer;
        font-size: 14px;
        & > a {
          color: #dee2e6;
        }
        & > a:hover {
          color: ${Palette.primary};
          font-weight: bold;
        }
      }
      & > .on {
        & > a {
          color: ${Palette.primary};
          font-weight: bold;
        }
      }
      & > .selectClick:hover {
        color: ${Palette.primary};
        font-weight: bold;
      }
      & > span:nth-child(2n) {
        font-size: 10px;
        margin: 0 10px;
        color: #333;
      }
    }
  }
  .tableSelect {
    height: 70px;
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: space-between;
    & > div {
      color: #999;
      height: 40px;
      /* background-color: red; */
      overflow: hidden;
      & > span {
        font-size: 14px;
        line-height: 37px;
      }
      & > select {
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        transition: ease-in-out;
        transition-duration: 0.1s;
      }
      & > select:hover {
        border-bottom: 2px solid ${Palette.primary};
      }
    }
  }
  .topWrapper {
    display: flex;
    justify-content: flex-end;
    & > div {
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > .excelBtn {
        /* margin-left: 3px; */
        height: 35px;
        & > label {
          display: inline-block;
          width: 75px;
          padding: 0.56rem 0;
          text-align: center;
          font-size: 14px;
          color: #fff;
          border-radius: 5px;
          height: 35px;
          background-color: ${Palette.tertiary};
          cursor: pointer;
          & > input {
            display: none;
          }
        }
      }
      & > div {
        margin: 0;
        margin-left: 3px;
      }
    }
  }
  .tableWrapper {
    min-width: 51rem;
    padding-bottom: 1.5rem;
  }
  .tableHover:hover {
    background-color: #eee;
    cursor: pointer;
  }
  .tableBody {
    tr {
      height: 53px !important;
      td {
        padding: 0;
      }
    }
  }

  .selItemInfo {
    background-color: pink;
  }
`;
