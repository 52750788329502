import { MasterGetList } from "modules/master/types";
import React, { useState } from "react";
import styled from "styled-components";
import { ThemeColor } from "styles/palette";
import Button from "./Button";
import { LaundrySearchBlock } from "./LaundrySearch";

import "date-fns";
import DateFormatUtils from "utils/DateFormatUtils";
// import Grid from "@material-ui/core/Grid";
// import DateFnsUtils from "@date-io/date-fns";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";

// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// import InputLabel from "@material-ui/core/InputLabel";
// import MenuItem from "@material-ui/core/MenuItem";
// import FormHelperText from "@material-ui/core/FormHelperText";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
// import TextField from "@material-ui/core/TextField";
// import RoleUtils from "utils/RoleUtils";

interface StoreSearchProps {
  role: string;
  onSearch: (page: number, data: MasterGetList) => void;
  size?: number;
}

const StoreSearch: React.FC<StoreSearchProps> = ({ onSearch, size }) => {
  const [searchQuery, setSearchQuery] = useState<string | null>("");
  const [searchType, setSearchType] = useState<string | null>("franchiseName");
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  // const useStyles = makeStyles((theme: Theme) =>
  //   createStyles({
  //     formControl: {
  //       margin: theme.spacing(1),
  //       minWidth: 120,
  //     },
  //     selectEmpty: {
  //       marginTop: theme.spacing(2),
  //     },
  //   })
  // );

  // const classes = useStyles();

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
  };

  const submitSearch = (e: any) => {
    e.preventDefault();
    const data = {
      query: searchQuery,
      dropBox: searchType,
      startDate: DateFormatUtils.dateToFormat(startDate),
      endDate: DateFormatUtils.dateToFormat(endDate),
      size: size ?? 10,
    };
    onSearch(1, data);
  };

  const selectHandleChange = (e: string) => {
    setSearchType(e);
  };

  // const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   setSearchType(event.target.value as string);
  // };

  return (
    <StoreSearchBlock onSubmit={(e) => submitSearch(e)}>
      {/* <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Select</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={searchType}
          style={{ fontSize: "15px" }}
          onChange={handleChange}
        >
          <MenuItem value="franchiseName">지점명</MenuItem>
          <MenuItem value="userName">아이디</MenuItem>
        </Select>
      </FormControl> */}

      <select
        className="selectBox"
        value={searchType!}
        onChange={(e) => {
          selectHandleChange(e.target.value);
        }}
      >
        <option value="franchiseName">지점명</option>
        <option value="userName">아이디</option>
      </select>
      <input
        className="textBox"
        value={searchQuery ?? ""}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justifyContent="space-around">
          <KeyboardDatePicker
            className="interval"
            disableToolbar
            variant="inline"
            format="yyyy / MM / dd"
            margin="normal"
            id="date-picker-inline"
            label="Start Date"
            value={startDate}
            onChange={handleStartDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="yyyy / MM / dd"
            margin="normal"
            id="date-picker-inline"
            label="End Date"
            value={endDate}
            onChange={handleEndDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider> */}
      {/* <TextField
        id="standard-basic"
        label="Search"
        className="textField"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      /> */}
      <Button
        theme={ThemeColor.primary}
        buttonSize="m"
        onClick={submitSearch}
        className="buttonSize"
      >
        검색
      </Button>
    </StoreSearchBlock>
  );
};

export default StoreSearch;

export const StoreSearchBlock = styled(LaundrySearchBlock)``;
