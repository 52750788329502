import { LaundryTableBlock } from "components/common/Table/LaundryTable";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import NumberUtils from "utils/NumberUtils";
import { ProfileDetail } from "modules/user/types";
import { FactoryData } from "modules/factory/types";

interface AdminAggregationDetailProps {
  startDate: string;
  endDate: string;
  dataList: dataList[] | null;
  chartType?: string;
  franchiseName?: string;
  factory?: FactoryData | null;
  factoryProfileDetail?: ProfileDetail | null;
  contractType: string;
  contractPrice: number;
}

interface dataList {
  isOrder: number;
  title: string;
  list: laundryList[];
  price: number;
}

interface laundryList {
  name: string;
  date: string;
  count: number;
}

const AdminAggregationDetail: React.FC<AdminAggregationDetailProps> = (
  props
) => {
  // const [startDate, setStartDate] = useState<moment.Moment>(() => moment());
  // const [endDate, setEndDate] = useState<moment.Moment>(() => moment());
  const [dateArr, setDateArr] = useState<string[]>([]);
  const [sumCount, setSumCount] = useState<number[]>([]);
  const [sumPrice, setSumPrice] = useState<number[]>([]);
  const [dateSum, setDateSum] = useState<number[]>([]);
  const [price, setPrice] = useState<number>(0);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    if (!props.startDate || !props.endDate || !props.dataList) return;
    dateCreate(props.startDate, props.endDate);
    if (props.dataList.length > 0) {
      let dataList = props.dataList;
      let sumList = [];
      let priceSumList = [];
      let countList = [];
      let price = 0;
      let count = 0;
      for (let i = 0; i < dataList.length; i++) {
        let sum = 0;

        for (let n = 0; n < dataList[i].list.length; n++) {
          sum = sum + dataList[i].list[n].count;
        }
        sumList.push(sum);
        priceSumList.push(dataList[i].price * sum);
        price = price + dataList[i].price * sum;
        count = count + sum;
      }
      for (let n = 0; n < dataList[0].list.length; n++) {
        let dayCount = 0;
        for (let i = 0; i < dataList.length; i++) {
          dayCount = dayCount + dataList[i].list[n].count;
        }
        countList.push(dayCount);
      }
      setDateSum(countList);
      setCount(count);
      setPrice(price);
      setSumCount(sumList);
      setSumPrice(priceSumList);
    }
  }, [props.startDate, props.endDate, props.dataList]);

  // const useStyles = makeStyles({
  //   table: {
  //     minWidth: 650,
  //     fontWeight: 700,
  //   },
  // });
  // const classes = useStyles();

  // const submitSearch = () => {
  //   let term = DateFormatUtils.dateTerm(startDate, endDate);
  //   if (term > 31) {
  //     alert("1개월을 초과하여 선택 할 수 없습니다");
  //     return;
  //   }
  // };

  const dateCreate = (sd?: string, ed?: string) => {
    if (!sd) sd = "2021-08-01";
    if (!ed) ed = "2021-08-30";
    var regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
    if (!(regex.test(sd) && regex.test(ed))) return "Not Date Format";
    var result = [];
    var curDate = new Date(sd);
    while (curDate <= new Date(ed)) {
      result.push(curDate.toISOString().split("T")[0]);
      curDate.setDate(curDate.getDate() + 1);
    }
    setDateArr(result);
  };

  return (
    <AdminAggregationDetailBlock>
      {props.startDate && props.dataList && (
        <>
          <div className="headers">
            <h3>{props.franchiseName}</h3>
          </div>
          <div>
            <AdminLaundryTableBlock>
              <table>
                <thead>
                  {props.dataList.length > 0 && (
                    <>
                      <tr className={"title"}>
                        <th>No.</th>
                        <th>품목</th>
                        {dateArr.map((date, index) => (
                          <th key={index}>{moment(date).format("M/D")}</th>
                        ))}
                        <th>총 수량</th>
                        <th>단가</th>
                        <th>총 발생액</th>
                      </tr>
                      <tr>
                        <th colSpan={2}>합계</th>
                        {dateSum.map((val, index) => (
                          <th key={index}>
                            {val ? NumberUtils.numberToComma(val) : "-"}
                          </th>
                        ))}
                        <th>{NumberUtils.numberToComma(count)}</th>
                        <th></th>
                        <th>
                          {" "}
                          {props.contractType === "UNIT_PRICE"
                            ? NumberUtils.numberToComma(price)
                            : NumberUtils.numberToComma(props.contractPrice)}
                        </th>
                      </tr>
                    </>
                  )}
                </thead>

                <tbody>
                  {props.dataList &&
                    props.dataList.map((val, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td className={"title"}>{val.title}</td>
                        {val.list.map((item, idx) => (
                          <td key={item.date}>
                            {item.count
                              ? NumberUtils.numberToComma(item.count)
                              : "-"}
                          </td>
                        ))}
                        <td className={"price"}>
                          {props.contractType === "UNIT_PRICE"
                            ? NumberUtils.numberToComma(sumCount[index])
                            : "-"}
                        </td>
                        <td className={"price"}>{val.price}</td>
                        <td className={"price"}>
                          {sumPrice[index] &&
                          props.contractType === "UNIT_PRICE"
                            ? NumberUtils.numberToComma(sumPrice[index])
                            : "-"}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {/* <div className={"footer"}>
              공급자 ㈜크린코리아 | (대표자) 김웅기 | (등록번호) 812-86-01992 |
              (주소) 경기도 김포시 하성면 원산로 45-19 | (TEL) 031-997-9090 |
              (FAX) 031-997-9030
            </div> */}
            </AdminLaundryTableBlock>
          </div>
        </>
      )}
    </AdminAggregationDetailBlock>
  );
};

export default AdminAggregationDetail;

const AdminAggregationDetailBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 22px;
  overflow-x: scroll;
  min-height: 400px;
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
    margin-bottom: 20px;
  }
  & > div:nth-child(2) {
    padding-left: 10px;
  }
  & > div {
    width: 100%;
    height: auto;
  }
`;

const AdminLaundryTableBlock = styled(LaundryTableBlock)``;
