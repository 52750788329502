import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "./Button";
import RoleUtile from "utils/RoleUtils";
import { Palette, ThemeColor } from "styles/palette";
import { FranchiseGetList } from "modules/store/types";

import "date-fns";

import DateFormatUtils from "utils/DateFormatUtils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RoleUtils from "utils/RoleUtils";
import { SpecificationsFranchiseList } from "modules/user/types";
import DateApp from "./datePickerTest";
import { FactoryMatchingFranchiseList } from "modules/factory/types";

interface SpecificationsSearchProps {
  role: string;
  onSearch: (id: number | null, page: number, data: any) => void;
  size?: number;
  specificationsFranchiseList?: SpecificationsFranchiseList | null;
  factoryMatchingFranchiseList?: FactoryMatchingFranchiseList | null;
}

const SpecificationsSearch: React.FC<SpecificationsSearchProps> = ({
  role,
  onSearch,
  size,
  specificationsFranchiseList,
  factoryMatchingFranchiseList,
}) => {
  const [searchQuery, setSearchQuery] = useState<string | null>("");
  const [searchType, setSearchType] = useState<string | null>("");
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [selectId, setSelectId] = useState<number | null>(null);

  const [searchList, setSearchList] = useState<
    SpecificationsFranchiseList | FactoryMatchingFranchiseList | null
  >();

  useEffect(() => {
    if (role && (role === "ROLE_MASTER_ADMIN" || role === "ROLE_MASTER")) {
      setSearchList(specificationsFranchiseList);
    } else {
      setSearchList(factoryMatchingFranchiseList);
    }
  }, [role, specificationsFranchiseList, factoryMatchingFranchiseList]);

  const submitSearch = (e: any) => {
    e.preventDefault();
    if (searchType === "" && role !== "ROLE_FRANCHISE")
      return alert("지점을 선택해주세요.");
    const data = {
      query: searchQuery,
      dropBox: searchType,
      startDate: DateFormatUtils.dateToFormat(startDate),
      endDate: DateFormatUtils.dateToFormat(endDate),
      size: size ?? 10,
    };

    if(!data.startDate || data.startDate === "Invalid date")
        return alert("날짜를 확인 및 입력해주세요.");
    if(!data.endDate || data.endDate === "Invalid date")
      return alert("날짜를 확인 및 입력해주세요.");
      
    onSearch(Number(searchType), 1, data);
  };

  const selectHandleChange = (e: string) => {
    setSearchType(e);
  };

  const startDateSelector = (date: string) => {
    setStartDate(new Date(date));
  };

  const endDateSelector = (date: string) => {
    setEndDate(new Date(date));
  };

  return (
    <SpecificationsSearchBlock
      onSubmit={(e) => {
        submitSearch(e);
      }}
    >
      {!RoleUtile.isStore(role) && (
        <select
          className="selectBox"
          value={searchType!}
          onChange={(e) => {
            selectHandleChange(e.target.value);
          }}
        >
          <option value="">지점선택</option>
          {searchList?.franchiseList.map((data, index) => (
            <option key={index} value={data.franchiseId}>
              {data.franchiseName}
            </option>
          ))}
        </select>
      )}
      <div className="datePicker">
        {/* <DatePickerBlock
          selected={startDate}
          dateFormat="yyyy년 MM월 dd일"
          onChange={(e: any) => {
            setStartDate(e);
          }}
        /> */}
        <DateApp dateItem={startDateSelector} />
      </div>
      ~{" "}
      <div className="datePicker">
        {/* <DatePickerBlock
          selected={endDate}
          dateFormat="yyyy년 MM월 dd일"
          onChange={(e: any) => {
            setEndDate(e);
          }}
        /> */}
        <DateApp dateItem={endDateSelector} />
      </div>
      <Button
        theme={ThemeColor.primary}
        buttonSize="m"
        onClick={submitSearch}
        className="buttonSize"
      >
        검색
      </Button>
    </SpecificationsSearchBlock>
  );
};

export default SpecificationsSearch;

export const SpecificationsSearchBlock = styled.form`
  display: inline-block;
  height: auto;

  & > select {
    border: 0;
    height: 100%;
  }
  & > input {
    border: 0;
    height: 100%;
  }

  & > .selectBox,
  .textBox {
    border: 1px solid #dee2e6;
    box-sizing: border-box;
    transition: ease-in-out;
    transition-duration: 0.1s;
    margin-right: 5px;
    border-radius: 3px;
  }
  & > .selectBox:hover,
  .textBox:hover {
    border-bottom: 2px solid ${Palette.primary};
  }
  & > .datePicker {
    display: inline-block;
    margin-right: 5px;
    color: black;
  }
`;
const DatePickerBlock = styled(DatePicker)`
  width: 140px;
  height: 100%;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  transition: ease-in-out;
  transition-duration: 0.1s;
  margin-right: 5px;
  cursor: default;
  &:hover {
    border-bottom: 2px solid ${Palette.primary};
  }
`;
