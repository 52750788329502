import React, { useEffect } from "react";
import styled from "styled-components";

import Button from "components/common/Button";
import { ThemeColor } from "styles/palette";
import { AdminLaundryDetail } from "modules/admin/types";
import { StatusDetailBlock } from "components/common/LaundaryStatusDetail";
import DateFormatUtils from "utils/DateFormatUtils";
import NumberUtils from "utils/NumberUtils";
import ScrollUtils from "utils/ScrollUtils";

interface AdminLaundryStatusDetailProps {
  adminLaundryDetail: AdminLaundryDetail | null;
  onClose: () => void;
}

const AdminLaundryStatusDetail: React.FC<AdminLaundryStatusDetailProps> = ({
  adminLaundryDetail,
  onClose,
}) => {
  useEffect(() => {
    ScrollUtils.onPopupScrollStop(window.scrollY);
    return () => {
      const scrollY = document.body.style.top;
      ScrollUtils.closePopupScrollRun(scrollY);
    };
  }, []);

  // const [laundryDetailItems, setLaundryDetailItems] = useState<Items[]>([]);

  // useEffect(() => {
  //   setLaundryDetailItems(adminLaundryDetail?.laundryItems ?? []);
  // }, []);

  return (
    <AdminLaundryStatusDetailBlock>
      <div className="headers">
        <h3>세탁현황 상세정보</h3>
        <Button theme={ThemeColor.primary} buttonSize="m" onClick={onClose}>
          뒤로가기
        </Button>
      </div>
      <div className="contentWrapper">
        <div className="detailInfo">
          <div className="field">
            <span>세탁 신청일</span>
            <p>
              {DateFormatUtils.dateToFormat(
                adminLaundryDetail?.applicationDate
              ) ?? "-"}
            </p>
          </div>
          <div className="field">
            <span>납품일</span>
            <p>
              {DateFormatUtils.dateToFormat(
                adminLaundryDetail?.completedDate
              ) ?? "-"}
            </p>
          </div>
          <div className="field">
            <span>세탁공장 담당</span>
            <p>{adminLaundryDetail?.factoryManager.nickname}</p>
          </div>
          <div className="field">
            <span></span>
            <p>{adminLaundryDetail?.factoryManager.phone}</p>
          </div>
          <div className="field">
            <span>지점 담당</span>
            <p>{adminLaundryDetail?.franchiseManager.nickname}</p>
          </div>
          <div className="field">
            <span></span>
            <p>{adminLaundryDetail?.franchiseManager.phone}</p>
          </div>
        </div>
        <div className="stockStatus">
          <div>
            <h3>세탁물 종류</h3>
            <div className="stockInField">
              <div>
                <div className="stockHeader">
                  <span>대분류</span>
                  <span>소분류</span>
                  <span>품명</span>
                  <span>사이즈</span>
                  <span>비고</span>
                  <span>세탁신청(개)</span>
                  <span>세탁완료(개)</span>
                  <span>재세탁(반품)</span>
                  <span>기타(미납,수선)</span>
                </div>
                <div className="stockList">
                  {adminLaundryDetail?.laundryItems.map(
                    (laundryItem, index) => (
                      <div className="field" key={index}>
                        <span>{laundryItem.asset.details.largeCategory}</span>
                        <span>{laundryItem.asset.details.subCategory}</span>
                        <span>{laundryItem.asset.details.name}</span>
                        <span>{laundryItem.asset.details.size ?? "-"}</span>
                        <span>{laundryItem.asset.details.content ?? "-"}</span>
                        <span>
                          {laundryItem.beforeAmount
                            ? NumberUtils.numberToComma(
                                laundryItem.beforeAmount ?? 0
                              )
                            : "-"}
                        </span>
                        <span>
                          {laundryItem.amount
                            ? NumberUtils.numberToComma(laundryItem.amount ?? 0)
                            : "-"}
                        </span>
                        <span>
                          {laundryItem.relaundering
                            ? NumberUtils.numberToComma(
                                laundryItem.relaundering
                              )
                            : "-"}
                        </span>
                        <span>-</span>
                      </div>
                    )
                  )}
                  <div className="field">
                    <span>포대/카트</span>
                    <span>-</span>
                    <span>-</span>
                    <span>-</span>
                    <span>-</span>
                    <span>
                      {NumberUtils.numberToComma(
                        adminLaundryDetail?.bagCount ?? 0
                      )}
                    </span>{" "}
                    <span>
                      {NumberUtils.numberToComma(
                        adminLaundryDetail?.outBagCount ?? 0
                      )}
                    </span>
                    <span>-</span>
                    <span>-</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLaundryStatusDetailBlock>
  );
};

export default AdminLaundryStatusDetail;

const AdminLaundryStatusDetailBlock = styled(StatusDetailBlock)``;
