import React from "react";
import styled from "styled-components";
import { Palette } from "styles/palette";

interface DashBoardWrapProps {}

const DashBoardWrap: React.FC<DashBoardWrapProps> = (props) => {
  return <DashBoardWrapBlock>{props.children}</DashBoardWrapBlock>;
};

export default DashBoardWrap;

const DashBoardWrapBlock = styled.div`
  /* padding: 25px 95px 40px; */
  /* min-height: calc(100vh - 80px); */
  height: calc(100% - 50px);
  & > .top {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 7px;

    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3);
    & > .graphWrap {
      width: 70%;
      display: flex;
      justify-content: space-between;
      /* background-color: #fff; */
      padding: 20px;
      border-radius: 7px;
      /* box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3); */
      & > .graph {
        width: 70%;

        & > div {
          display: flex;
          & > div {
            font-size: 20px;
            /* margin-left: 20px; */
            font-weight: bold;
            &.color {
              width: 24px;
              height: 24px;
              border-radius: 12px;
            }
          }
        }
        & > .graphKindTab {
          display: flex;
          justify-content: space-between;
          align-items: center;
          & > div {
            width: 200px;
            display: flex;
            justify-content: space-between;
            /* background-color: pink; */
            & > div {
              color: ${Palette.lightGray};
              cursor: pointer;
              font-weight: 400;
            }
            & > span {
              color: #1b2253;
              font-weight: bold;
              line-height: 22px;
            }
            & > .select {
              color: #1b2253 !important;
              font-weight: 700;
            }
          }
          & > span {
            font-size: 12px;
            color: ${Palette.lightGray};
          }
        }
        & > .timeNow {
          font-size: 12px;
          color: #aaa;
          text-align: center;
        }
      }
      & > .count {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 25%;
        margin-left: 5%;
        &.store {
          justify-content: space-around;
        }
        & > div {
          background-color: #e5e5e5;
          border-radius: 10px;
          padding: 20px;
          & > h4 {
            font-size: 18px;
            line-height: 30px;
            margin: 0;
          }
          & > h3 {
            font-size: 24px;
            line-height: 40px;
            margin: 0;
            color: #1b2253;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            & > span {
              margin-left: 8px;
              font-size: 12px;
              font-weight: 400;
              color: ${Palette.lightGray};
            }
          }
        }
      }
    }
    & > .calendar {
      width: 27%;
      /* background-color: #fff; */
      padding: 20px;
      /* border-radius: 7px; */
      border-left: 1px solid #e5e5e5;
      position: relative;
      /* box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3); */

      & > h3 {
        font-size: 20px;
        color: #1b2253;
        margin: 0;
      }
      & > div {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        & > .react-calendar {
          border: 0;
        }
      }
    }
  }
  & > .bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    & > div {
      border-radius: 7px;
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3);
      padding: 20px;
      width: 30%;
      background-color: #fff;
      & > .more {
        display: flex;
        justify-content: flex-end;

        & > button {
          background-color: #1b2253;
          color: #ffffff;
          border: 0;
          border-radius: 5px;
          cursor: pointer;
        }
      }
      &.laundry {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      &.count {
        width: 43.1%;
        background-color: transparent;
        padding: 0;
        box-shadow: 0 0 0;
        background-color: #fff;
        border-radius: 7px;
        box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3);
        position: relative;

        & > div {
          margin-top: 21px;
          /* padding: 0 20px; */
          /* background-color: #fff; */
          /* border-radius: 7px; */
          /* box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3); */
          &.nums {
            padding: 20px;
            display: flex;
            & > div {
              width: 50%;
              margin-top: 65px;
              padding: 10px;
              &:last-child {
                border-left: 1px solid #eee;
              }
              & > h3 {
                margin: 0;
                font-size: 18px;
                color: #1b2253;
                line-height: 60px;
              }
              & > .today {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                margin-top: 40px;
                color: #1b2253;
                padding-left: 20px;
                & > span {
                  font-size: 12px;
                  line-height: 20px;
                }
                & > strong {
                  font-size: 32px;
                  line-height: 40px;
                }
              }
              & > .accumulate {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                margin-top: 40px;
                & > span {
                  font-size: 12px;
                  line-height: 20px;
                  color: #979daa;
                  padding: 0 10px;
                }
                & > strong {
                  font-size: 18px;
                  line-height: 30px;
                }
              }
            }
          }
        }
      }
      &.laundryBoard {
        width: 27%;
        position: relative;
        & > h3 {
          margin: 0;
          line-height: 40px;
          color: #1b2253;
        }
        & > div {
          margin-top: 20px;
          &.dataWrap {
            min-height: 310px;
          }
          & > .listWrap {
            /* display: flex; */
            width: 100%;
            /* height: 70px; */
            padding: 10px;
            border-radius: 8px;
            cursor: pointer;
            overflow: hidden;
            &:nth-child(2n) {
              background-color: #f2f2f2;
            }
            & > div {
              line-height: 40px;
              /* margin-left: 10px; */
              font-size: 14px;
              text-overflow: ellipsis;
              &.role {
                margin-left: 0;
                display: flex;
                & > h4 {
                  width: auto;
                  margin: 0;
                  line-height: 20px;
                  font-size: 16px;
                }
                & > p {
                  margin: 0;
                  margin-left: 5px;
                  font-size: 12px;
                  line-height: 20px;
                }
              }
            }
            & > .boardTitle {
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
      &.noticeBoard {
        width: 27%;
        position: relative;
        & > h3 {
          margin: 0;
          line-height: 40px;
          color: #1b2253;
        }
        & > div {
          margin-top: 20px;
          &.dataWrap {
            min-height: 310px;
          }
          & > .listWrap {
            display: flex;
            justify-content: space-between;
            padding: 10px;
            border-radius: 8px;
            border-bottom: 1px solid #eee;
            cursor: pointer;
            &.notice {
              &:nth-child(2n) {
                background-color: #f2f2f2;
              }
            }
            &:last-child {
              border-bottom: 0;
            }
            & > div {
              line-height: 40px;
              margin-left: 20px;
              font-size: 14px;
              &.role {
                width: 70%;
                margin-left: 0;
                color: #1b2253;
                & > h4 {
                  margin: 0;
                  line-height: 20px;
                  font-size: 16px;
                }
                & > p {
                  width: 100%;
                  margin: 0;
                  font-size: 10px;
                  line-height: 20px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }
      }
    }
  }
  .infoBox {
    height: 16px;
    position: absolute;
    top: -10px;
    right: 10px;

    & > .info {
      width: 90px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: default;
      & > span:nth-child(1) {
        font-size: 12px;
        color: ${Palette.primary};
      }
      & > span:nth-child(2) {
        width: 16px;
        height: 16px;
        & > img {
          width: 100%;
          height: 100%;
        }
      }
    }
    & > .infoGuide {
      position: absolute;
      width: 320px;
      height: 40px;
      background-color: #ffffcc;
      border: 1px solid black;
      border-radius: 7px;
      font-size: 12px;
      padding-top: 5px;
      padding-left: 10px;
      top: 20px;
      right: 0;
      display: none;
    }
    & > .infoCenter {
      width: 280px;
      line-height: 30px;
      text-align: center;
    }
  }
  .infoBox:hover {
    & > .infoGuide {
      display: block;
    }
  }
  .comparison {
    padding: 10px;
    padding-left: 20px;
    margin-top: 20px;
    display: flex;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    /* border-radius: 7px;
            box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3); */
    background-color: #1b2253;
    & > h3 {
      width: 25%;
      color: #ffffff;
      font-size: 18px;
    }
    & > div {
      width: 75%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.store {
        justify-content: space-around;
      }
      & > div {
        display: flex;
        justify-content: space-between;
        width: calc(100% / 2);
        /* border: 1px solid white; */
        padding-left: 10px;
        & > figure {
          width: 30%;
          margin: 0;
          display: flex;
          align-items: center;
          & > img {
            width: 100%;
            object-fit: contain;
          }
        }
        & > div {
          display: flex;
          flex-direction: column;
          width: 60%;
          justify-content: center;
          & > span {
            font-size: 12px;
            color: #ffffff;
            line-height: 20px;
          }
          & > strong {
            color: #ffffff;
            font-size: 16px;
            line-height: 20px;
            white-space: nowrap;
          }
        }
      }
    }
  }
`;
