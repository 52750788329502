import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Palette, ThemeColor } from "styles/palette";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Paging from "components/common/Paging";
import Button from "components/common/Button";
import AdminLaundryRfidCountModal from "./AdminLaundryRfidCountModal";
import {
  AdminLaundryItem,
  AdminLaundryItemList,
  AdminLaundryTagList,
  AdminTagGetList,
} from "modules/admin/types";
import NumberUtils from "utils/NumberUtils";
import { AdminLaundrySelectItemInfoType } from "containers/admin/AdminLaundryRfidCountContainer";
import { useHistory } from "react-router";
// import { useHistory } from "react-router";

interface AdminLaundryRfidCountListProps {
  adminLaundryItemList: AdminLaundryItemList | null;
  adminLaundryTagList: AdminLaundryTagList | null;
  getLaundryItemData: (page: number, size: number) => void;
  getLaundryTagData: (page: number, data: AdminTagGetList) => void;
  getSelectLaundryTagData: (
    page: number,
    assetId: string,
    query: string | null,
    sort: string | null
  ) => void;
  selItemInfo: AdminLaundrySelectItemInfoType;
  onSelItemInfo: (data: AdminLaundrySelectItemInfoType) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDelete: () => void;
  onAdd: () => void;
  onModify: () => void;
}

const AdminLaundryRfidCountList: React.FC<AdminLaundryRfidCountListProps> = (props) => {
  const history = useHistory();

  const [tagModalCheck, setTagModalCheck] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentSize, setCurrentSize] = useState<number>(10);
  const [currentItemId, setCurrentItemId] = useState<number | null>(null);

  const [selItemInfoModal, setSelItemInfoModal] = useState<boolean>(false);

  const [listNumber, setListNumber] = useState<number[]>([]);
  useEffect(() => {
    setListNumber(
      NumberUtils.numberToList(
        props.adminLaundryItemList?.currentPage ?? 0,
        props.adminLaundryItemList?.totalCount ?? 0,
        currentSize ?? 10
      )
    );
  }, [props.adminLaundryItemList, currentSize]);

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      fontWeight: 700,
    },
  });
  const classes = useStyles();

  const onModal = (id: number) => {
    setTagModalCheck(true);
    setCurrentItemId(id);
    props.getSelectLaundryTagData(1, String(id), null, null);
  };

  const onClose = () => {
    setTagModalCheck(false);
    setCurrentItemId(null);
  };

  const pageMove = (page: number) => {
    setCurrentPage(page);
    props.getLaundryItemData(page, currentSize);
  };

  const selectShowEntries = (size: number) => {
    setCurrentSize(size);
    props.getLaundryItemData(currentPage, size);
  };

  const searchPageMove = (page: number, query?: string | null, sort?: string | null) => {
    props.getSelectLaundryTagData(page, String(currentItemId), query ?? null, sort ?? null);
  };

  const historyBack = () => {
    // history.push(`/admin/store`);
    history.goBack();
  };

  /** 선택할 자산 정보 세팅
   * @large 대분류
   * @sub 소분류
   * @name 품목명
   * @amount 수량
   * @id 자산 id
   */
  const onSelItemInfo = (item: AdminLaundryItem) => {
    let data: AdminLaundrySelectItemInfoType = {
      largeCategory: "",
      subCategory: "",
      name: "",
      amount: 0,
      size: "",
      etc: "",
      unitPrice: 0,
      weight: 0,
      id: null,
    };

    if (!props.selItemInfo.id || props.selItemInfo.id !== item.id) {
      data = {
        largeCategory: item.largeCategory,
        subCategory: item.subCategory,
        name: item.name,
        amount: item.amount,
        size: String(item.size),
        etc: "",
        unitPrice: item.price,
        weight: item.weight,
        id: item.id,
      };
    }

    props.onSelItemInfo(data);
  };

  const onCancel = () => {
    setSelItemInfoModal(false);
    if (!props.selItemInfo.id) {
      let data = {
        largeCategory: "",
        subCategory: "",
        name: "",
        amount: 0,
        size: "",
        etc: "",
        unitPrice: 0,
        weight: 0,
        id: null,
      };

      props.onSelItemInfo(data);
    }
  };

  /** 자산 삭제 */
  const onDelete = () => {
    if (window.confirm("등록된 태그 정보까지 삭제 됩니다.\n정말로 삭제하시겠습니까?")) {
      props.onDelete();
    }
  };

  /** 자산 수정 */
  const onModify = () => {
    if (window.confirm("정말로 수정하시겠습니까?")) {
      props.onModify();
      setTimeout(() => {
        onCancel();
      }, 10);
    }
  };

  const onAdd = () => {
    props.onAdd();
    setTimeout(() => {
      onCancel();
    }, 10);
  };

  return (
    <>
      <AdminLaundryRfidCountListBlock>
        {tagModalCheck && props.adminLaundryTagList && (
          <AdminLaundryRfidCountModal
            searchPageMove={searchPageMove}
            adminLaundryTagList={props.adminLaundryTagList!}
            onClose={onClose}
          />
        )}
        <div className="headers">
          <h3>지점 자산 정보 관리</h3>
          <Button theme={ThemeColor.primary} buttonSize="m" onClick={historyBack}>
            뒤로가기
          </Button>
        </div>
        <div className="tableSelect">
          <div>
            <span>Show </span>
            <select onChange={(e) => selectShowEntries(parseInt(e.target.value, 10))}>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
            <span> entries</span>
          </div>
          <div className="topWrapper">
            {props.selItemInfo.id && (
              <>
                {" "}
                <Button
                  theme={ThemeColor.primary}
                  buttonSize="m"
                  onClick={() => setSelItemInfoModal(true)}
                >
                  수정
                </Button>
                <Button theme={ThemeColor.warning} buttonSize="m" onClick={onDelete}>
                  삭제
                </Button>
              </>
            )}
            {!props.selItemInfo.id && (
              <Button
                theme={ThemeColor.primary}
                buttonSize="m"
                onClick={() => setSelItemInfoModal(true)}
              >
                등록
              </Button>
            )}
          </div>
        </div>
        <div className="tableWrapper">
          <TableContainer component={Paper} className="tables">
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ fontWeight: 900, width: "70px" }}>
                    순번
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 900, width: "15%" }}>
                    대분류
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      fontWeight: 900,
                      width: "15%",
                    }}
                  >
                    소분류
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 900 }}>
                    품목명
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 900, width: "10%" }}>
                    수량
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 900, width: "10%" }}>
                    단가(원)
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 900, width: "10%" }}>
                    중량(kg)
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 900, width: "10%" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="tableBody">
                {props.adminLaundryItemList?.list.map((item, index) => (
                  <TableRow
                    className={`tableHover ${props.selItemInfo.id === item.id && "selItemInfo"}`}
                    onClick={() => onSelItemInfo(item)}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {listNumber[index]}
                    </TableCell>
                    <TableCell align="center">{item.largeCategory}</TableCell>
                    <TableCell align="center">{item.subCategory}</TableCell>
                    <TableCell align="center">{item.name}</TableCell>
                    <TableCell align="center">{item.amount}</TableCell>
                    <TableCell align="center">{NumberUtils.numberToComma(item.price)}</TableCell>
                    <TableCell align="center">{item.weight}</TableCell>
                    <TableCell align="center">
                      <Button
                        theme={ThemeColor.primary}
                        buttonSize="m"
                        onClick={(e) => {
                          e.stopPropagation();
                          onModal(item.id);
                        }}
                      >
                        TAG
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Paging
            listAction={pageMove}
            listCount={props.adminLaundryItemList?.totalPage ?? 1}
            currentPage={props.adminLaundryItemList?.currentPage}
            totalCount={props.adminLaundryItemList?.totalCount ?? 1}
            size={currentSize ?? 10}
          ></Paging>
        </div>
      </AdminLaundryRfidCountListBlock>
      {selItemInfoModal && (
        <AdminLaundrySelectItemInfoBlock>
          <section>
            <h3>{props.selItemInfo.id ? "자산 수정" : "자산 등록"}</h3>
            <div className="inputField">
              <div className="field">
                <span>대분류</span>
                <input
                  name="largeCategory"
                  value={props.selItemInfo.largeCategory}
                  onChange={(e) => props.onChange(e)}
                />
              </div>
              <div className="field">
                <span>소분류</span>
                <input
                  name="subCategory"
                  value={props.selItemInfo.subCategory}
                  onChange={(e) => props.onChange(e)}
                />
              </div>
              <div className="field">
                <span>품목명</span>
                <input
                  name="name"
                  value={props.selItemInfo.name}
                  onChange={(e) => props.onChange(e)}
                />
              </div>
              <div className="field">
                <span>사이즈</span>
                <input
                  name="size"
                  value={props.selItemInfo.size}
                  onChange={(e) => props.onChange(e)}
                />
              </div>
              <div className="field">
                <span>비고</span>
                <input
                  name="etc"
                  value={props.selItemInfo.etc}
                  onChange={(e) => props.onChange(e)}
                />
              </div>
              <div className="field">
                <span>단가(원)</span>
                <input
                  name="unitPrice"
                  value={NumberUtils.numberToComma(Number(props.selItemInfo.unitPrice))}
                  onChange={(e) => props.onChange(e)}
                />
              </div>
              <div className="field">
                <span>중량(kg)</span>
                <input
                  name="weight"
                  value={props.selItemInfo.weight}
                  onChange={(e) => props.onChange(e)}
                />
              </div>
            </div>
            <div className="btnWrap">
              <Button theme={ThemeColor.quadratic} buttonSize="s" onClick={onCancel}>
                취소
              </Button>
              <Button
                theme={ThemeColor.tertiary}
                buttonSize="s"
                onClick={() => {
                  if (!props.selItemInfo.id) onAdd();
                  if (props.selItemInfo.id) onModify();
                }}
              >
                {!props.selItemInfo.id ? "등록" : "수정"}
              </Button>
            </div>
          </section>
        </AdminLaundrySelectItemInfoBlock>
      )}
    </>
  );
};

export default AdminLaundryRfidCountList;

const AdminLaundryRfidCountListBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  .tables {
    margin-top: 8px;
    margin-bottom: 20px;
  }

  .tableHover > td {
    padding: 10px;
  }
  .tableHover:hover {
    background-color: #eee;
    cursor: pointer;
  }
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  .tableSelect {
    height: 70px;
    display: flex;
    padding-top: 30px;
    align-items: center;
    justify-content: space-between;
    & > div {
      color: #999;
      height: 40px;
      overflow: hidden;
      & > span {
        font-size: 14px;
        line-height: 37px;
      }
      & > select {
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        transition: ease-in-out;
        transition-duration: 0.1s;
        border-radius: 3px;
      }
      & > select:hover {
        border-bottom: 2px solid ${Palette.primary};
      }
    }
  }
  .tableWrapper {
    min-width: 51rem;
    padding-bottom: 1.5rem;
  }
  .tableBody {
    tr {
      height: 53px !important;
      td {
        padding: 0;
      }
    }
  }
  .selItemInfo {
    background-color: pink;
  }
`;

const AdminLaundrySelectItemInfoBlock = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  & > section {
    width: 500px;
    height: 500px;
    background-color: ${Palette.white};
    border-radius: 17px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    & > h3 {
      width: 100%;
      padding: 0;
      margin: 0;
    }
    .inputField {
      width: 100%;
      /* margin-top: 10rem; */
      .field {
        display: grid;
        grid-template-columns: 8rem 1fr;
        align-items: center;
        select,
        input {
          height: 2.1rem;
        }
      }
      .field + .field {
        margin-top: 0.9rem;
      }
    }
  }
`;
