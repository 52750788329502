import { MasterGetList } from "modules/master/types";
import React, { useState } from "react";
import styled from "styled-components";
import { ThemeColor } from "styles/palette";
import Button from "./Button";
import { LaundrySearchBlock } from "./LaundrySearch";

import "date-fns";

interface PayManagementSearchProps {
  drop: string;
  setDrop: (type: string) => void;
  completeCheck: string;
  onSearch: (page: number, data: any) => void;
  size?: number;
}

const PayManagementSearch: React.FC<PayManagementSearchProps> = ({
  completeCheck,
  onSearch,
  size,
  setDrop,
}) => {
  const [searchQuery, setSearchQuery] = useState<string | null>("");
  const [searchType, setSearchType] = useState<string | null>("writer");

  const submitSearch = (e: any) => {
    e.preventDefault();
    const data = {
      query: searchQuery,
      dropBox: searchType,
      size: size ?? 10,
    };
    onSearch(1, data);
  };

  const selectHandleChange = (e: string) => {
    setSearchType(e);
    setDrop(e);
  };

  return (
    <PayManagementSearchBlock onSubmit={(e) => submitSearch(e)}>
      {completeCheck === "notComplete" && (
        <select
          className="selectBox"
          value={searchType!}
          onChange={(e) => {
            selectHandleChange(e.target.value);
          }}
        >
          <option value="">결제상태선택</option>
          <option value="WAITING">대기</option>
          <option value="TAX_INVOICE">세금계산서발행</option>
        </select>
      )}
      <select className="selectBox" disabled>
        <option value="">지점명</option>
      </select>
      {/* <input
        className="textBox"
        style={{ width: "100px" }}
        value="지점명"
        disabled
      /> */}
      <input
        className="textBox"
        value={searchQuery ?? ""}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <Button
        theme={ThemeColor.primary}
        buttonSize="m"
        onClick={submitSearch}
        className="buttonSize"
      >
        검색
      </Button>
    </PayManagementSearchBlock>
  );
};

export default PayManagementSearch;

export const PayManagementSearchBlock = styled(LaundrySearchBlock)``;
