import moment from "moment";

const branchName = [
  "종각점",
  "용산점",
  "판교점",
  "여의도점",
  "사당점",
  "오목교역점",
  "을지로점",
  "시청점",
];

const factoryList = [
  "명품세탁",
  "믿음세탁",
  "구로세탁",
  "조광세탁",
  "현대향균세탁",
  "현진세탁",
  "김이정클린업세탁",
  "크린보이",
  "아트종합세탁",
];

const factoryId = [
  "member1",
  "member2",
  "member3",
  "member4",
  "member5",
  "member6",
  "member7",
  "member8",
  "member9",
];

const managerList = [
  "김만규",
  "주종수",
  "조인호",
  "나인원",
  "주명돈",
  "노성진",
];

const addressData = ["서울 금천구 독산동", "서울 구로구"];

const categoryList = ["상의", "하의", "수건", "기타"];

const sizeList = ["3XL", "2XL", "L", "M", "S"];

function makeRandom(min: number, max: number) {
  var RandVal = Math.floor(Math.random() * (max - min + 1)) + min;
  return RandVal;
}

function makeEndDate() {
  let day: string | number = makeRandom(1, 31);
  day = day < 10 ? "0" + day : "" + day;
  return `2021-07-${day}`;
}

function makeStartDate() {
  let month: string | number = makeRandom(1, 12);
  month = month < 10 ? "0" + month : month;
  let day: string | number = makeRandom(1, 31);
  day = day < 10 ? "0" + day : day;
  return `2020-${month}-${day}`;
}

function makePhone() {
  const middleNumber = makeRandom(1000, 9999);
  const lastNumber = makeRandom(1000, 9999);
  return `010-${middleNumber}-${lastNumber}`;
}

export function getTotalQuantity(categoryList: Category[]) {
  let totalQuantity = 0;
  for (let i = 0; i < categoryList.length; i++) {
    const category = categoryList[i];
    if (typeof category.quantity === "number")
      totalQuantity += category.quantity;
  }
  return totalQuantity;
}

class DummyUtils {
  getLaundryList() {
    return Array.from({ length: 10 }, (_, idx) => new LaundryApply(idx));
  }
  getStoreList() {
    return branchName.map((branch, id) => new Store(id, branch));
  }
  getInventoryList() {
    return Array.from({ length: 8 }, (_, idx) => new Inventory(idx));
  }
  getFactoryList() {
    return Array.from({ length: 8 }, (_, idx) => new FactoryData(idx));
  }
  getProfileData(data: string) {
    return;
  }
}

interface Category {
  type: string;
  size: string;
  quantity: string | number;
}

export class LaundryApply {
  constructor(id: number) {
    this.id = id;
  }
  id;
  storeName = branchName[makeRandom(0, branchName.length - 1)];
  startDate = moment(new Date()).format("YYYY-MM-DD");
  endDate = makeEndDate();
  quantity = makeRandom(1, 5);
  status = makeRandom(0, 1);
  factory = factoryList[makeRandom(0, factoryList.length - 1)];
  payDay = makeEndDate();
  detailTotalQuantity = makeRandom(50, 400);
  price = makeRandom(20, 120) * 10000;
  priceStatus = makeRandom(0, 2);
  category = [
    {
      type: "상의",
      size: "3XL",
      quantity: this.status ? makeRandom(0, 40) : "-",
    },
    {
      type: "상의",
      size: "2XL",
      quantity: this.status ? makeRandom(0, 40) : "-",
    },
    {
      type: "상의",
      size: "XL",
      quantity: this.status ? makeRandom(0, 40) : "-",
    },
    {
      type: "상의",
      size: "L",
      quantity: this.status ? makeRandom(0, 40) : "-",
    },
    {
      type: "상의",
      size: "M",
      quantity: this.status ? makeRandom(0, 40) : "-",
    },
    {
      type: "하의",
      size: "3XL",
      quantity: this.status ? makeRandom(0, 40) : "-",
    },
    {
      type: "하의",
      size: "2XL",
      quantity: this.status ? makeRandom(0, 40) : "-",
    },
    {
      type: "하의",
      size: "XL",
      quantity: this.status ? makeRandom(0, 40) : "-",
    },
    {
      type: "하의",
      size: "L",
      quantity: this.status ? makeRandom(0, 40) : "-",
    },
    {
      type: "하의",
      size: "M",
      quantity: this.status ? makeRandom(0, 40) : "-",
    },
    {
      type: "수건",
      size: "대",
      quantity: this.status ? makeRandom(0, 40) : "-",
    },
    {
      type: "수건",
      size: "소",
      quantity: this.status ? makeRandom(0, 40) : "-",
    },
    {
      type: "기타",
      size: "",
      quantity: this.status ? makeRandom(0, 2) : "-",
    },
  ];
}

export class Store {
  constructor(id: number, branchName: string) {
    this.id = id;
    this.username = `member${this.id}`;
    this.branchName = branchName;
  }
  id;
  username: string;
  branchName: string;
  phone = makePhone();
  createdAt = makeStartDate();
  currentApplyDate = moment(new Date()).format("YYYY-MM-DD");
  totalPayment = makeRandom(100, 2000) * 10000;
  totalApplyCount = makeRandom(1, 400);
  managerName = managerList[makeRandom(0, managerList.length - 1)];
  managerPhone = makePhone();
  factory = factoryList[makeRandom(0, factoryList.length - 1)];
  categoryList = [
    {
      type: "상의",
      size: "3XL",
      quantity: makeRandom(0, 40),
    },
    {
      type: "상의",
      size: "2XL",
      quantity: makeRandom(0, 40),
    },
    {
      type: "상의",
      size: "XL",
      quantity: makeRandom(0, 40),
    },
    {
      type: "상의",
      size: "L",
      quantity: makeRandom(0, 40),
    },
    {
      type: "상의",
      size: "M",
      quantity: makeRandom(0, 40),
    },
    {
      type: "하의",
      size: "3XL",
      quantity: makeRandom(0, 40),
    },
    {
      type: "하의",
      size: "2XL",
      quantity: makeRandom(0, 40),
    },
    {
      type: "하의",
      size: "XL",
      quantity: makeRandom(0, 40),
    },
    {
      type: "하의",
      size: "L",
      quantity: makeRandom(0, 40),
    },
    {
      type: "하의",
      size: "M",
      quantity: makeRandom(0, 40),
    },
    {
      type: "수건",
      size: "대",
      quantity: makeRandom(0, 40),
    },
    {
      type: "수건",
      size: "소",
      quantity: makeRandom(0, 40),
    },
    {
      type: "기타",
      size: "",
      quantity: makeRandom(0, 2),
    },
  ];
}

export class Inventory {
  constructor(id: number) {
    this.id = id;
  }
  id;
  category = categoryList[makeRandom(0, 3)];
  size = sizeList[makeRandom(0, 4)];
  quantity = makeRandom(0, 50);
}

export class FactoryData {
  constructor(id: number) {
    this.id = id;
  }
  id;
  userId = factoryId[makeRandom(0, 8)];
  factoryName = factoryList[makeRandom(0, 8)];
  phone = makePhone();
  subscriptionDate = moment(new Date()).format("YYYY-MM-DD");
  cumulativeLaundryCount = makeRandom(0, 100);
  operationStatus = makeRandom(0, 1);
}

export class UserData {
  constructor(id: number) {
    this.id = id;
    this.username = `member${this.id}`;
  }
  id;
  username: string;
  phone = makePhone();
  postalCode = makeRandom(100000, 999999);
  address = addressData[makeRandom(0, 1)];
  addressDetail = `${makeRandom(0, 110)}번지`;
  branch = branchName[makeRandom(0, 7)];
  manager = managerList[makeRandom(0, 5)];
  managerPhone = makePhone();
}

export default new DummyUtils();
