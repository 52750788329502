import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

interface propTypes {
  dateChange: (e: moment.Moment) => void;
  date: moment.Moment;
}

const Calendar: React.FC<propTypes> = (props) => {
  const [date, setDate] = useState<moment.Moment>(() => moment());

  useEffect(() => {
    setDate(props.date);
  }, [props.date]);

  const handleDayClick = (current: moment.Moment) => {
    props.dateChange(current);
  };
  // const returnToday = () => props.dateChange(moment());
  const jumpToMonth = (num: number) =>
    num
      ? props.dateChange(date.clone().add(30, "day"))
      : props.dateChange(date.clone().subtract(30, "day"));

  function generate() {
    const today = date;

    const startWeek = today.clone().startOf("month").week();

    const endWeek =
      today.clone().endOf("month").week() === 1
        ? 53
        : today.clone().endOf("month").week();

    let calendar = [];

    for (let week = startWeek; week <= endWeek; week++) {
      calendar.push(
        <div className="row" key={week}>
          {Array(7)
            .fill(0)
            .map((n, i) => {
              let current = today
                .clone()
                .week(week)
                .startOf("week")
                .add(n + i, "day");

              let isSelected =
                today.format("YYYYMMDD") === current.format("YYYYMMDD")
                  ? "selected"
                  : "";

              let isGrayed =
                current.format("MM") !== today.format("MM") ? "grayed" : "";
              return (
                <div
                  className={`box ${isSelected} ${isGrayed}`}
                  key={i}
                  onClick={() => handleDayClick(current)}
                >
                  <span className="text">{current.format("D")}</span>
                </div>
              );
            })}
        </div>
      );
    }
    return calendar;
  }

  return (
    <Wrapper>
      <CalendarHead>
        <div className="head">
          <span className="title">{date.format("YYYY.MM")}</span>
          <div className="util-button">
            <button onClick={() => jumpToMonth(0)}>
              <NavigateBeforeIcon />
            </button>
            {/* <button onClick={returnToday}>Today</button> */}
            <button onClick={() => jumpToMonth(1)}>
              <NavigateNextIcon />
            </button>
          </div>
        </div>
      </CalendarHead>
      <CalendarBody>
        <div className="row day">
          {["S", "M", "T", "W", "T", "F", "S"].map((el, index) => (
            <div className={"box"} key={index}>
              <span className="text">{el}</span>
            </div>
          ))}
        </div>
        {generate()}
      </CalendarBody>
    </Wrapper>
  );
};
export default Calendar;

const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
`;
const CalendarHead = styled.div`
  & > .head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    & > span {
      line-height: 40px;
      font-weight: bold;
    }
    & > div {
      display: flex;
      line-height: 40px;
      & > button {
        height: 40px;
        border: 0;
        background-color: #fff;
        cursor: pointer;
      }
    }
  }
`;
const CalendarBody = styled.div`
  margin-top: 20px;
  & > .row {
    display: flex;
    justify-content: space-between;
    &.day {
      font-weight: bold;
    }
    & > div {
      display: flex;
      justify-content: center;
      width: calc(100% / 7);
      text-align: center;
      line-height: 30px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 12px;
      &.selected {
        background-color: #1b2253;
        color: #ffffff;
      }
      &.grayed {
        color: #cccccc;
      }
    }
  }
`;
