import { FranchiseDetailLaundry } from "modules/store/types";
import React, { useEffect } from "react";
import styled from "styled-components";
import { Palette, ThemeColor } from "styles/palette";
import DateFormatUtils from "utils/DateFormatUtils";
import NumberUtils from "utils/NumberUtils";
import ScrollUtils from "utils/ScrollUtils";
import Button from "./Button";

interface LaundaryStatusDetailProps {
  franchiseDetailLaundry: FranchiseDetailLaundry;
  onClose: () => void;
}

const LaundaryStatusDetail: React.FC<LaundaryStatusDetailProps> = ({
  franchiseDetailLaundry,
  onClose,
}) => {
  useEffect(() => {
    ScrollUtils.onPopupScrollStop(window.scrollY);
    return () => {
      const scrollY = document.body.style.top;
      ScrollUtils.closePopupScrollRun(scrollY);
    };
  }, []);

  if (!franchiseDetailLaundry) return <div>로딩중</div>;
  return (
    <StatusDetailBlock>
      <div className="headers">
        <h3>세탁현황 상세정보</h3>
        <Button theme={ThemeColor.primary} buttonSize="m" onClick={onClose}>
          뒤로가기
        </Button>
      </div>
      <div className="contentWrapper">
        <div className="detailInfo">
          <div className="field">
            <span>세탁 신청일</span>
            <p>{DateFormatUtils.dateToFormat(franchiseDetailLaundry.applicationDate)}</p>
          </div>
          <div className="field">
            <span>납품일</span>
            <p>{DateFormatUtils.dateToFormat(franchiseDetailLaundry.completedDate)}</p>
          </div>
          <div className="field">
            <span>세탁공장 담당</span>
            <div className="fieldDetail">
              <p>{franchiseDetailLaundry.factoryManager.nickname}</p>
            </div>
          </div>
          <div className="field">
            <span></span>
            <p>{franchiseDetailLaundry.factoryManager.phone}</p>
          </div>
          <div className="field">
            <span>지점 담당</span>
            <p>{franchiseDetailLaundry.franchiseManager.nickname}</p>
          </div>
          <div className="field">
            <span></span>
            <p>{franchiseDetailLaundry.franchiseManager.phone}</p>
          </div>
          <div className="field">
            <span>입고 수량(포대/카트)</span>
            <p>{NumberUtils.numberToComma(franchiseDetailLaundry.bagCount ?? 0)}</p>
          </div>
        </div>
        <div className="stockStatus">
          <div>
            <h3>세탁물 종류</h3>
            <div className="stockInField">
              <div>
                <div className="stockHeader">
                  <span>대분류</span>
                  <span>소분류</span>
                  <span>품명</span>
                  <span>사이즈</span>
                  <span>비고</span>
                  <span>세탁신청(개)</span>
                  <span>세탁완료(개)</span>
                  <span>재세탁(반품)</span>
                  <span>기타(미납,수선)</span>
                </div>
                <div className="stockList">
                  {franchiseDetailLaundry.laundryItems.map((laundryItem, idx) => (
                    <div className="field" key={idx}>
                      <span>{laundryItem.asset.details.largeCategory}</span>
                      <span>{laundryItem.asset.details.subCategory}</span>
                      <span>{laundryItem.asset.details.name}</span>
                      <span>{laundryItem.asset.details.size}</span>
                      <span>{laundryItem.asset.details.content}</span>
                      <span>
                        {laundryItem.beforeAmount
                          ? NumberUtils.numberToComma(laundryItem.beforeAmount ?? 0)
                          : "-"}
                      </span>
                      <span>
                        {laundryItem.amount
                          ? NumberUtils.numberToComma(laundryItem.amount ?? 0)
                          : "-"}
                      </span>
                      <span>
                        {laundryItem.relaundering
                          ? NumberUtils.numberToComma(laundryItem.relaundering)
                          : "-"}
                      </span>
                      <span>-</span>
                    </div>
                  ))}
                  <div className="field">
                    <span>포대/카트</span>
                    <span>-</span>
                    <span>-</span>
                    <span>-</span>
                    <span>-</span>
                    <span>{NumberUtils.numberToComma(franchiseDetailLaundry.bagCount ?? 0)}</span>
                    <span>
                      {NumberUtils.numberToComma(franchiseDetailLaundry.outBagCount ?? 0)}
                    </span>
                    <span>-</span>
                    <span>-</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StatusDetailBlock>
  );
};

export default LaundaryStatusDetail;

export const StatusDetailBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  & > .contentWrapper {
    width: 100%;
    height: 820px;

    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    & > div {
      width: 35%;
    }
    & > div:nth-child(2) {
      width: 65%;
      border-left: 1px solid black;
    }
    & > .detailInfo {
      width: auto;
      margin: 0 auto;

      & > .field {
        width: 100%;
        /* height: 37px; */
        min-height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
        & > span {
          width: 140px;
        }
        & > p,
        input {
          border: 1px solid #ddd;
          font-size: 14px;
          border-radius: 5px;
          height: 100%;
          width: 220px;
          line-height: 35px;
          margin: 0;
          padding: 0;
          padding-left: 10px;
        }

        & > .PWBtn {
          width: 260px;
        }
      }
      .changeId {
        width: 179px;
        border: 1px solid #ddd;
        margin-right: 5px;
        height: 37px;
      }
      & > .secoundField {
        & > p,
        input {
          width: 174px;
          margin-right: 10px;
        }
      }
      & > .buttonWrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 50px;
      }
    }
    & > .stockStatus {
      height: 740px;
      & > div {
        width: 90%;
        min-width: 690px;
        /* min-width: 500px;
        max-width: 540px; */
        height: auto;
        margin: 0 auto;
        & > h3 {
          margin: 0;
        }
        & > .stockInField {
          margin-top: 20px;
          & > div {
            width: 100%;
            height: 690px;
            border-radius: 10px;
            background-color: #ecf0f5;
            padding: 20px 5px;
            overflow: hidden;
            & > .stockHeader {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-right: 15px;

              & > span {
                width: calc(100% / 9);
                text-align: center;
                font-size: 13px;
                word-wrap: break-word;
              }
            }
            & > .stockList {
              overflow-y: scroll;
              height: 90%;
              margin-top: 20px;
              & > div {
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #fff;
                padding: 2px 0;
                & > span {
                  width: calc(100% / 9);
                  text-align: center;
                  font-size: 13px;
                  word-wrap: break-word;
                }
              }
            }
          }
        }
      }
    }
  }
  /* position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  & > .contentWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 80rem;
    width: 80%;
    height: 80%;
    background-color: #eee;

    display: flex;
    flex-direction: column;
    padding: 1rem;
    h2 {
      color: #016241;
      margin-left: 20px;
    }
    .content {
      flex: 1;
      display: flex;
      justify-content: space-around;
      align-items: center;

      margin-top: 30px;
      & > div {
        width: 55%;
      }
      & > div:nth-child(1) {
        width: 10%;
        min-width: 25rem;
      }

      .field {
        display: grid;
        grid-template-columns: 8rem 1fr;
        align-items: center;
        margin-top: 1rem;
        p {
          margin: 0;
          background-color: ${Palette.white};
          padding: 0.5rem 1rem;
        }

        .fieldDetail {
          display: grid;
          grid-template-columns: 1fr;
          column-gap: 1rem;
        }
      }
      .field:first-child {
        margin-top: 0;
      }

    .categoryWrapper {
      & > span {
        font-size: 1.125rem;
        color: ${Palette.gray};
      }

      .categoryContentWrapper {
        margin-top: 1rem;
        background-color: ${Palette.white};
        padding: 1rem;
        max-height: 27rem;
        overflow-y: auto;

        .field {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          text-align: center;
          width: 100%;
          margin: 0;
          padding: 0.3rem 0;
          & > span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .field:nth-child(2n) {
          background-color: #eee;
        }
      }
    }
  } */
`;
