import Button from "components/common/Button";
import React, { useState } from "react";
import styled from "styled-components";
import { FactoryStatusDetailBlock } from "components/common/FactoryStatusDetail";
import { Palette, ThemeColor } from "styles/palette";
import { Asset, MasterStoreDetail } from "modules/master/types";
import DateFormatUtils from "utils/DateFormatUtils";
import { useEffect } from "react";
import ScrollUtils from "utils/ScrollUtils";
import NumberUtils from "utils/NumberUtils";
import TypeUtils from "utils/TypeUtils";

interface MasterStoreStatusDetailProps {
  onClose: () => void;
  onDelete: (id: number) => void;
  masterStoreDetail: MasterStoreDetail | null;
  historyBack: () => void;
}

// interface categoryDataProps {
//   itemName: string | null;
//   itemAmount: number | null;
// }

const MasterStoreStatusDetailHeader: string[] = [
  "대분류",
  "소분류",
  "품명",
  "사이즈",
  "비고",
  "수량(개)",
];
const MasterStoreStatusDetail: React.FC<MasterStoreStatusDetailProps> = ({
  onClose,
  onDelete,
  masterStoreDetail,
  historyBack,
}) => {
  // useEffect(() => {
  //   ScrollUtils.onPopupScrollStop(window.scrollY);
  //   return () => {
  //     const scrollY = document.body.style.top;
  //     ScrollUtils.closePopupScrollRun(scrollY);
  //   };
  // }, []);

  const [storeDetailItems, setStoreDetailItems] = useState<Asset[]>([]);
  const [payMethod, setPayMethod] = useState<string>("unit");

  useEffect(() => {
    setStoreDetailItems(masterStoreDetail?.assetResponseList ?? []);
    if (masterStoreDetail) setPayMethod(masterStoreDetail.franchiseResponse.payment);
  }, [masterStoreDetail]);

  // const [categoryState, setCategoryState] = useState<categoryDataProps[]>([]);

  // const categoryData = (list: string[] | null, amount: number[] | null) => {
  //   const categoryList: string[] = list ?? [];
  //   const categoryAmount: number[] = amount ?? [];
  //   const category: categoryDataProps[] = [];
  //   for (let i = 0; i < categoryList.length; i++) {
  //     const data = {
  //       itemName: categoryList[i] ?? "",
  //       itemAmount: categoryAmount[i] ?? 0,
  //     };
  //     category.push(data);
  //   }
  //   setCategoryState(category);
  // };

  // useEffect(() => {
  //   categoryData(
  //     masterStoreDetail?.categoryList ?? [],
  //     masterStoreDetail?.categoryAmount ?? []
  //   );
  // }, [masterStoreDetail]);

  return (
    <MasterStoreStatusDetailBlock>
      <div className="headers">
        <h3>지점 상세정보</h3>
        <Button theme={ThemeColor.primary} buttonSize="m" onClick={historyBack}>
          뒤로가기
        </Button>
      </div>
      <div className="contentWrapper">
        <div className="detailInfo">
          <div className="field">
            <span>아이디</span>
            <p>{masterStoreDetail?.franchiseResponse.manager.username}</p>
          </div>
          <div className="field">
            <span>지점명</span>
            <p>{masterStoreDetail?.franchiseResponse.companyName}</p>
          </div>
          <div className="field">
            <span>연락처</span>
            <p>{masterStoreDetail?.franchiseResponse.companyPhone}</p>
          </div>
          <div className="field secoundField">
            <span>주소</span>
            <p>{masterStoreDetail?.franchiseResponse.companyAddress.postCode}</p>
          </div>
          <div className="field">
            <span>주소</span>
            <p>{masterStoreDetail?.franchiseResponse.companyAddress.street}</p>
          </div>
          <div className="field">
            <span></span>
            <p>{masterStoreDetail?.franchiseResponse.companyAddress.streetDetails}</p>
          </div>
          <div className="field">
            <span>가입일</span>
            <p>{DateFormatUtils.dateToFormat(masterStoreDetail?.franchiseResponse.joinDate)}</p>
          </div>

          {/* <div className="field">
            <span>최근 신청일</span>
            <p>
              {DateFormatUtils.dateToFormat(
                masterStoreDetail?.franchiseResponse.lastOrderDate
              )}
            </p>
          </div> */}
          {/* <div className="paymentMethod field">
            <span>결제방식</span>
            <div>
              <label className={payMethod === "unit" ? "active" : ""}>
                <input
                  type="radio"
                  value="unit"
                  name="paymentMethod"
                  checked={payMethod === "unit"}
                  onChange={(e) => {
                    // setPayMethod(e.target.value);
                  }}
                />
                <span>단가제</span>
              </label>
              <label className={payMethod === "month" ? "active" : ""}>
                <input
                  type="radio"
                  value="month"
                  name="paymentMethod"
                  checked={payMethod === "month"}
                  onChange={(e) => {
                    // setPayMethod(e.target.value);
                  }}
                />
                <span>월정액</span>
              </label>
            </div>
          </div> */}
          {/* <div className="field">
            <span>누적 세탁신청</span>
            <p>
              {NumberUtils.numberToComma(
                masterStoreDetail?.franchiseResponse.totalLaundryCount ?? 0
              )}
            </p>
          </div>
          <div className="field">
            <span>총 결제금액(원)</span>
            <p>
              {masterStoreDetail?.franchiseResponse.totalPrice
                ? NumberUtils.numberToComma(
                    masterStoreDetail.franchiseResponse.totalPrice ?? 0
                  )
                : "-"}
            </p>
          </div> */}
          <div className="field">
            <span>담당자명</span>
            <p>{masterStoreDetail?.franchiseResponse.manager.nickname}</p>
          </div>
          <div className="field">
            <span>담당자연락처</span>
            <p>{masterStoreDetail?.franchiseResponse.manager.phone}</p>
          </div>
          <div className="field">
            <span>계약기준</span>
            <p>
              {masterStoreDetail?.franchiseResponse.contract
                ? TypeUtils.payManagementContactType(
                    masterStoreDetail?.franchiseResponse.contract.basis
                  )
                : "-"}
            </p>
          </div>
          {masterStoreDetail?.franchiseResponse.contract &&
          masterStoreDetail?.franchiseResponse.contract.basis === "UNIT_PRICE" ? (
            <div className="field">
              <span>산출방식</span>
              <p>
                {masterStoreDetail?.franchiseResponse.contract
                  ? TypeUtils.payManagementSubType(
                      masterStoreDetail?.franchiseResponse.contract.type
                    )
                  : "-"}
              </p>
            </div>
          ) : (
            <div className="field">
              <span>계약금액(원)</span>
              <p>
                {masterStoreDetail?.franchiseResponse.contract
                  ? NumberUtils.numberToComma(masterStoreDetail?.franchiseResponse.contract.price)
                  : "-"}
              </p>
            </div>
          )}
          <div className="field">
            <span>정산기간</span>
            <p>
              {masterStoreDetail?.franchiseResponse.contract
                ? `${masterStoreDetail?.franchiseResponse.contract.startDay} 일 ~ ${
                    masterStoreDetail?.franchiseResponse.contract.lastDay !== 0
                      ? "익월 " + masterStoreDetail?.franchiseResponse.contract.lastDay
                      : "이달 말"
                  } 일`
                : "-"}
            </p>
          </div>
          <div className="buttonWrapper">
            <Button theme={ThemeColor.primary} buttonSize="m" onClick={historyBack}>
              확인
            </Button>
          </div>
        </div>
        <div className="stockStatus">
          <div>
            <h3>재고현황</h3>
            <div className="stockInField">
              <div>
                <div className="stockHeader">
                  {MasterStoreStatusDetailHeader.map((item, key) => (
                    <span key={key}>{item}</span>
                  ))}
                </div>
                <div className="stockList">
                  {masterStoreDetail?.assetResponseList.map((item) => (
                    <div className="stockField" key={item.id}>
                      <span>{item.details.largeCategory}</span>
                      <span>{item.details.subCategory}</span>
                      <span>{item.details.name}</span>
                      <span>{item.details.size ?? "-"}</span>
                      <span>{item.details.content ?? "-"}</span>
                      <span>{NumberUtils.numberToComma(item.assetAmount ?? 0)}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MasterStoreStatusDetailBlock>
  );
};

export default MasterStoreStatusDetail;

const MasterStoreStatusDetailBlock = styled.div`
  background-color: #fff;
  border-radius: 7px;
  padding: 0 22px;
  box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.3);
  .headers {
    height: 70px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
  }
  & > .contentWrapper {
    width: 100%;
    height: 820px;

    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    & > div {
      width: 35%;
    }
    & > div:nth-child(2) {
      width: 65%;
      border-left: 1px solid black;
    }
    & > .detailInfo {
      width: auto;
      margin: 0 auto;

      & > .field {
        width: 100%;
        min-height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
        & > span {
          width: 140px;
        }
        & > p,
        input {
          border: 1px solid #ddd;
          font-size: 14px;
          border-radius: 5px;
          height: 100%;
          width: 260px;
          line-height: 35px;
          margin: 0;
          padding-left: 10px;
        }
        & > div {
          & > .changeId {
            width: 179px;
            border: 1px solid #ddd;
            margin-right: 5px;
          }
        }
        & > .PWBtn {
          width: 260px;
        }
      }
      & > .secoundField {
        & > p,
        input {
          width: 174px;
          margin-right: 10px;
        }
      }
      & > .buttonWrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 50px;
      }
    }
    & > .stockStatus {
      height: 740px;
      & > div {
        width: 90%;
        min-width: 690px;
        height: auto;
        margin: 0 auto;
        & > h3 {
          margin: 0;
        }
        & > .stockInField {
          margin-top: 20px;
          & > div {
            width: 100%;
            height: 690px;
            border-radius: 10px;
            background-color: #ecf0f5;
            padding: 20px 5px;
            overflow: hidden;
            & > .stockHeader {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-right: 15px;
              & > span {
                width: calc(100% / 6);
                text-align: center;
                font-size: 13px;
                word-wrap: break-word;
              }
            }
            & > .stockList {
              overflow-y: scroll;
              height: 90%;
              margin-top: 20px;
              & > div {
                margin-top: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #fff;
                padding: 2px 0;
                & > span {
                  width: calc(100% / 6);
                  text-align: center;
                  font-size: 13px;
                  word-wrap: break-word;
                }
              }
            }
          }
        }
      }
    }
  }
  /* .stockStatus {
    height: 140px;
    margin-top: 80px;
    display: grid;
    grid-template-columns: 6rem 1fr;
    column-gap: 1rem;
    .stockInCategory {
      background-color: #fff;
      overflow-y: scroll;
      padding: 10px;
      border-radius: 5px;
      .categoryField {
        height: 30px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .stockInField {
      width: 100%;
      min-height: 140px;
      background-color: ${Palette.white};
      border-radius: 5px;
      .stockHeader {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        text-align: center;
        align-items: center;
        padding: 5px;
        border-bottom: 1px solid #eeeeee;
      }

      .stockList {
        overflow-y: scroll;
        height: 110px;
      }
      .stockField {
        display: grid;
        padding: 5px 0;
        grid-template-columns: repeat(6, 1fr);
        text-align: center;
        align-items: center;
      }
      .stockField:nth-child(2n) {
        background-color: #eee;
      }
    }
  }
  .paymentMethod {
    display: grid;
    & > div {
      display: flex;
      justify-content: space-between;
      & > label {
        width: 45%;
        padding: 0 0.5rem;
        margin: 0;
        border-radius: 5px;
        border: 1px solid #ccc;
        color: #000000;
        background-color: #f0f0f0;
        & > input {
          display: none;
        }
        & > span {
          display: block;
          text-align: center;
          line-height: 2.1rem;
        }
        &.active {
          color: #ffffff;
          background-color: #296043;
        }
      }
    }
  } */
`;
