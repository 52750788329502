import { Pane } from "./Pane";
import { SpinButtons } from "./SpinButtons";
import React, { useState, useRef } from "react";
import { todaysDayNumber, DAY, twoDigit, isIsoDateValid } from "./util";

export function DatePicker({
  value,
  defaultValue,
  onChange,
  setSearchDate,
  clickCheck,
  check,
}: any) {
  const [isOpen, setIsOpen] = useState(false);
  const defaultDayNumber = isIsoDateValid(defaultValue)
    ? new Date(defaultValue).getTime() / DAY
    : undefined;
  const [dayNumber, setDayNumber] = useState<any>(defaultDayNumber);
  const spinButtonsRef = useRef<any>();
  const prevChangeValue = useRef<any>();

  function onSpin(e: any) {
    const _date = new Date(todaysDayNumber() * DAY);
    // populate and undefined parts with todays values,
    let { date = _date.getUTCDate(), year = _date.getUTCFullYear() } = e;
    let month = e.month ? e.month - 1 : _date.getUTCMonth();
    const _dayNumber = new Date(Date.UTC(year, month, date)).getTime() / DAY;
    setDayNumber(_dayNumber);
    const iso = `${e.year}-${twoDigit(e.month)}-${twoDigit(e.date)}`;

    const changeValue = isIsoDateValid(iso) ? iso : "";
    if (typeof onChange === "function" && changeValue !== prevChangeValue.current) {
      onChange(changeValue);
    }
    prevChangeValue.current = changeValue;
  }

  function onSelect(dayNumber: any) {
    setIsOpen(false);
    spinButtonsRef.current.setDayNumber(dayNumber);
    spinButtonsRef.current.focus();
    setDayNumber(dayNumber);
  }

  return (
    <div
      className="test121"
      style={{
        position: "relative",
        fontFamily: "Roboto",
      }}
      onKeyDown={(e) => {
        if (e.keyCode === 27) {
          setIsOpen(false);
          spinButtonsRef.current.focus();
        }
      }}
    >
      <SpinButtons
        value={check ? defaultValue : ""}
        defaultValue={check ? defaultValue : ""}
        clickCheck={clickCheck}
        setIsOpen={setIsOpen}
        ref={spinButtonsRef}
        onChange={onSpin}
        setSearchDate={setSearchDate}
      />
      <div
        className="test1"
        style={{
          position: "absolute",
          top: 40,
          backgroundColor: "#fff",
          width: "auto",
          zIndex: 3,
        }}
      >
        {isOpen && (
          <Pane onSelect={onSelect} defaultValue={dayNumber} autoFocus setIsOpen={setIsOpen} />
        )}
      </div>
    </div>
  );
}
