import React, { useState } from "react";
import styled from "styled-components";
import { ThemeColor } from "styles/palette";

import Button from "./Button";
import { LaundrySearchBlock } from "./LaundrySearch";
import "date-fns";

import DateFormatUtils from "utils/DateFormatUtils";

import { AdminGetList } from "modules/admin/types";
import { SpecificationsFranchiseList } from "modules/user/types";
import moment from "moment";
import DateApp from "./datePickerTest";

interface AdminPaymentSearchProps {
  role: string;
  contractDateData: (start: string, end: string) => void;
  setContractType: (type: string) => void;
  onSearch: (data: AdminGetList) => void;
  onSearchDate: (start: string, end: string) => void;
  setMonthData: (month: string) => void;
  setChartType: (data: string) => void;
  paymentType: string;
  setContractPrice: (price: number) => void;
  getAdminLaundryTable?: (
    id: number,
    name: string,
    data: AdminGetList | null
  ) => void;
  specificationsFranchiseList?: SpecificationsFranchiseList | null;
  monthSelector: (month: string, start: string, end: string) => void;
}

const AdminPaymentSearch: React.FC<AdminPaymentSearchProps> = ({
  role,
  onSearch,
  setContractType,
  contractDateData,
  onSearchDate,
  setMonthData,
  paymentType,
  setChartType,
  setContractPrice,
  getAdminLaundryTable,
  specificationsFranchiseList,
  monthSelector,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [selectId, setSelectId] = useState<number | null>();
  const [franchiseName, setFranchiseName] = useState<string | null>("");

  const [selectChart, setSelectChart] = useState<string | null>(null);
  const [contractCheck, setContractCheck] = useState<boolean>(false);

  const [contractStart, setContractStart] = useState<string | null>(null);
  const [contractEnd, setContractEnd] = useState<string | null>(null);
  const [month, setMonth] = useState<string | null>(null);

  const submitSearch = (e: any) => {
    e.preventDefault();

    if (selectChart === null && paymentType === "aggregation")
      return alert("검색할 종류를 선택해주세요.");
    if (contractCheck === false && selectChart !== null)
      return alert("계약설정이 되지않은 지점입니다.");

    const data = {
      startDate: DateFormatUtils.dateToFormat(startDate),
      endDate: DateFormatUtils.dateToFormat(endDate),
    };

    if (data?.startDate && data?.endDate) {
      if(!data?.startDate || data?.startDate === "Invalid date")
        return alert("날짜를 확인 및 입력해주세요.");
      if(!data?.endDate || data?.endDate === "Invalid date")
        return alert("날짜를 확인 및 입력해주세요.");
    }

    setChartType(selectChart!);
    onSearchDate(data.startDate, data.endDate);
    // if (selectChart === "aggregation1") {
    //   startDateData = startDateData.substring(0, 7);
    // }

    if (paymentType === "aggregation") {
      monthSelector(month!, contractStart!, contractEnd!);
      getAdminLaundryTable!(selectId!, franchiseName!, data);
    } else {
      onSearch(data);
    }
  };

  const onChange = (e: number) => {
    if (specificationsFranchiseList) {
      for (
        let i = 0;
        i < specificationsFranchiseList?.franchiseList.length;
        i++
      ) {
        if (specificationsFranchiseList.franchiseList[i].franchiseId === e) {
          setFranchiseName(
            specificationsFranchiseList.franchiseList[i].franchiseName
          );
          // console.log(specificationsFranchiseList?.franchiseList[i].contract);
          if (specificationsFranchiseList?.franchiseList[i].contract) {
            setContractType(
              specificationsFranchiseList?.franchiseList[i].contract.basis
            );
            setContractPrice(
              specificationsFranchiseList?.franchiseList[i].contract.price
            );
            let startDay = String(
              specificationsFranchiseList?.franchiseList[i].contract.startDay
            );
            let lastDay = String(
              specificationsFranchiseList?.franchiseList[i].contract.lastDay
            );

            setContractStart(startDay);
            setContractEnd(lastDay);

            // contractDateData(startDay, lastDay);
            setContractCheck(true);
          } else {
            setContractCheck(false);
          }
        }
      }
    }
    specificationsFranchiseList?.franchiseList.map(
      (list, index) =>
        list.franchiseId === e && setFranchiseName(list.franchiseName)
    );
    setSelectId(e);
  };

  const selectChartTypeChange = (e: string) => {
    // console.log(e);
    setSelectChart(e);
    //명세표 타입
  };

  const selectMonthChage = (e: string) => {
    const monthDateStart = `${e}-01`;
    const monthDataEnd = `${e}-${moment(e, "YYYY-MM").daysInMonth()}`;
    // setMonthData(e);
    setMonth(e);
    setStartDate(new Date(monthDateStart));
    setEndDate(new Date(monthDataEnd));
    onSearchDate(monthDateStart, monthDataEnd);
  };

  const startDateSelector = (date: string) => {
    setStartDate(new Date(date));
  };

  const endDateSelector = (date: string) => {
    setEndDate(new Date(date));
  };

  return (
    <AdminPaymentSearchBlock
      onSubmit={(e) => {
        submitSearch(e);
      }}
    >
      {" "}
      {paymentType === "aggregation" && (
        <>
          <select
            className="selectBox"
            value={selectChart!}
            onChange={(e) => {
              selectChartTypeChange(e.target.value);
            }}
          >
            <option value="defaultName">종류 선택</option>
            {/* <option value="specifi1">거래명세표(금액미포함,납품일 선택)</option>
            <option value="specifi2">거래명세표(금액미포함,기간선택)</option>
            <option value="specifi3">거래명세표(금액포함,납품일 선택)</option> */}
            <option value="aggregation1">
              세탁집계표(금액포함,1개월,정산기간)
            </option>
            <option value="aggregation2">세탁집계표(금액포함,기간선택)</option>
          </select>
          <select
            className="selectBox"
            onChange={(e) => onChange(Number(e.target.value))}
          >
            <option value="default">선택하세요</option>
            {specificationsFranchiseList?.franchiseList.map((list, index) => (
              <option key={index} value={list.franchiseId}>
                {list.franchiseName}
              </option>
            ))}
          </select>
        </>
      )}
      {selectChart === "aggregation1" && (
        <div className="monthPicker">
          <input
            type="month"
            onChange={(e) => selectMonthChage(e.target.value)}
          />
        </div>
      )}
      {selectChart !== "aggregation1" && (
        <>
          <div className="datePicker">
            {/* <DatePickerBlock
              selected={startDate}
              dateFormat="yyyy년 MM월 dd일"
              onChange={(e: any) => {
                setStartDate(e);
              }}
            /> */}
            <DateApp dateItem={startDateSelector} />
          </div>

          {(selectChart === "specifi2" ||
            selectChart === "aggregation2" ||
            selectChart === null) && (
            <>
              ~{" "}
              <div className="datePicker">
                {/* <DatePickerBlock
                  selected={endDate}
                  dateFormat="yyyy년 MM월 dd일"
                  onChange={(e: any) => {
                    setEndDate(e);
                  }}
                /> */}
                <DateApp dateItem={endDateSelector} />
              </div>
            </>
          )}
        </>
      )}
      <Button
        theme={ThemeColor.primary}
        buttonSize="m"
        onClick={submitSearch}
        className="buttonSize"
      >
        검색
      </Button>
    </AdminPaymentSearchBlock>
  );
};

export default AdminPaymentSearch;

export const AdminPaymentSearchBlock = styled(LaundrySearchBlock)`
  .selectBox {
    width: 130px;
  }
`;

// const DatePickerBlock = styled(DatePicker)`
//   width: 140px;
//   height: 100%;
//   border: 1px solid #dee2e6;
//   border-radius: 3px;
//   transition: ease-in-out;
//   transition-duration: 0.1s;
//   margin-right: 5px;
//   cursor: default;
//   &:hover {
//     border-bottom: 2px solid ${Palette.primary};
//   }
// `;
